<i2a-profile-alert-base-modal *ngIf="group"
  [manualDescriptionKey]="'applyCorrection.providers.microsoft.sharePoint.visibility.default.manual.description'"
  [manualLinkKey]="'applyCorrection.providers.microsoft.sharePoint.visibility.default.manual.link'"
  [automaticDescriptionKey]="'applyCorrection.providers.microsoft.sharePoint.visibility.default.automatic.description'"
  [ignoreCommentToSend]="session.IgnoreCommentToSend" [displayComment]="session.DisplayComment"
  [validation]="validation" [canSend]="true"
  [modalTitle]="'profile.provider.microsoft.sharePoint.confirmationOfInformationTitle.visibility' | i18next "
  [informationKey]="isVisible ? 'profile.provider.microsoft.sharePoint.visibility.modal.privateInformation':  'profile.provider.microsoft.sharePoint.visibility.modal.publicInformation' "
  [loading]="loading" [displaySend]="canChangeVisibility" [displayComment]="false" [displayHelp]="!canChangeVisibility"
  [resourceProfile]="resourceProfile" [headerTitle]="headerTitle">

  <ng-container message>
    <div class="row pt-3" *ngIf="!canChangeVisibility && !loading">
      <div class="col-12">
        <i2a-message>
          <ng-container message-content>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 13.125 13.125"
              style="vertical-align: sub;margin-right: 5px;">
              <path
                d="M363.563,108.875L357,122h13.125Zm0.437,5.25V118.5h-0.875v-4.375H364Zm-0.875,5.25H364v0.875h-0.875v-0.875Z"
                fill="#ffcf64" transform="translate(-357 -108.875)" />
            </svg>

            {{'profile.provider.microsoft.sharePoint.visibility.modal.hasNotFullControl' | i18next}}
          </ng-container>
        </i2a-message>
      </div>
    </div>
  </ng-container>

  <ng-container header-picto>
    <i2a-badge-icon>
      <ng-container icon>
        <fa-icon [icon]="['fas', 'eye']" size="lg"></fa-icon>
      </ng-container>
    </i2a-badge-icon>
  </ng-container>

  <ng-container header-title>
    {{ 'profile.provider.microsoft.sharePoint.visibility.modal.information'  | i18next  }}
  </ng-container>

  <ng-container body>
    <div class="container-fluid">
      <div class="row pt-3" *ngIf="farmSummaryItems && farmSummaryItems.length > 0">
        <div class="col-12">
          <i2a-share-point-subsite-node [node]="site" [allSites]="farmSummaryItems">
          </i2a-share-point-subsite-node>
        </div>
      </div>
    </div>
  </ng-container>

</i2a-profile-alert-base-modal>
<i2a-profile-card [id]="id"
  [viewMoreDetails]="configuration.viewDetailKinds && this.delegate.resourceKind > 0 || configuration.viewDetailFolders && this.delegate.resourceFolders.length > 0 || configuration.viewDetailHumanExpressions"
  *ngIf="delegate" [title]="title" [hasAlert]="hasAlert" [hasAlertRecentlyClosed]="hasAlertRecentlyClosed"
  [warningLevel]="warningLevel" [noContent]="!configuration.viewIconBox" (onViewMoreDetails)="openDetails($event)"
  (onWarnClick)="warnClick($event)">

  <ng-container picto>
    <i2a-delegate-photo [isAnonymous]="delegate.logonUserId == 'Anonymous'"
      [isDefault]="delegate.logonUserId == 'Default'" [isApplication]="delegate.logonUserId == 'Application'"
      [pir]="delegate.providerInstanceResource" [piu]="delegate.providerInstanceUser" [isExternalUser]="false">
    </i2a-delegate-photo>
  </ng-container>

  <ng-container content>
    <i2a-delegate-configuration-box *ngIf="configuration.viewIconBox" [isInboxEnabled]="IsInbox"
      [displayIsInbox]="displayIsInbox" [inboxInfo]="'profile.rulesComputedDelegates.accessAuthorized' | i18next "
      [isCalendarEnabled]="IsCalendar" [displayIsCalendar]="displayIsCalendar"
      [calendarInfo]="'profile.rulesComputedDelegates.accessAuthorized' | i18next " [isFoldersEnabled]="IsFolders"
      [displayIsFolders]="displayIsFolders" [foldersInfo]="'profile.rulesComputedDelegates.accessAuthorized' | i18next "
      [isInstantMessagingEnabled]="IsInstantMessaging" [displayIsInstantMessaging]="displayIsInstantMessaging"
      [instantMessagingInfo]="'profile.rulesComputedDelegates.accessAuthorized' | i18next "
      [displayInfo]="configuration.displayIconBoxInfo">
    </i2a-delegate-configuration-box>
    <i2a-delegate-device [deviceModels]="delegate.registeredDevices"
      *ngIf="configuration && configuration.displayRegisteredDevices"></i2a-delegate-device>
  </ng-container>

</i2a-profile-card>
import { Component, OnInit, Input, ViewChild, ElementRef, Inject } from '@angular/core';
import { NavBarLogoPosition } from '../../../../models/nav-bar-logo-position';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';
  
@Component({
  selector: 'i2a-nav-bar-base',
  templateUrl: './nav-bar-base.component.html',
  styleUrls: ['./nav-bar-base.component.scss']
})
export class NavBarBaseComponent implements OnInit {
  @Input() public displayMenu: boolean;
  @Input() public logoPosition: NavBarLogoPosition;
  public isCollapsed: boolean;
  public isIE: boolean;
  
  @ViewChild('navBarUser') navBarUser: ElementRef;
  
  public get logoAtLeft() : boolean {
    return this.logoPosition == NavBarLogoPosition.Left;
  }
  
  public get logoAtCenter() : boolean {
    return this.logoPosition == NavBarLogoPosition.Center;
  }

  constructor(
    @Inject(I18NEXT_SERVICE) private translateI18Next: ITranslationService,
    private deviceService: DeviceDetectorService) {
    this.isCollapsed = true;
    this.displayMenu = true;
    this.logoPosition = NavBarLogoPosition.Left;
    //https://www.geeksforgeeks.org/how-to-detect-the-user-browser-safari-chrome-ie-firefox-and-opera-using-javascript/
    this.isIE = this.deviceService.userAgent.indexOf("MSIE") > -1 || this.deviceService.userAgent.indexOf("rv:") > -1; 
  }

  ngOnInit(): void {

    if (this.isIE) {
      const color = this.translateI18Next.t('style.ie.color');
      if (this.navBarUser) {
        this.navBarUser.nativeElement.style.color = color;
      }
    }
  }

  

  onLeaveUser(): void {
    if (this.navBarUser && this.isIE) {
      const color = this.translateI18Next.t('style.ie.color');
      this.navBarUser.nativeElement.style.color = color;
    }
  }

  onEnterUser(): void {
    if (this.navBarUser && this.isIE) {
      const colorHover = this.translateI18Next.t('style.ie.colorHover');
      this.navBarUser.nativeElement.style.color = colorHover;
    }
  }
}

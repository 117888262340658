import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, ViewChild, Inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { InformationModalComponent } from '../../../shared/modal/information-modal/information-modal.component';
import { SharePointDelegate } from 'projects/web-user-profile/src/app/models/providers/microsoft/share-point/share-point-delegate';
import { ReportProfileResource, ReportProfileResourceBase } from '@i2a/web-api-client';

@Component({
  selector: 'i2a-external-delegates',
  templateUrl: './external-delegates.component.html',
  styleUrls: ['./external-delegates.component.scss']
})
export class ExternalDelegatesComponent implements OnInit {
  @Input() public title: string;
  @Input() public subTitle: string;
  @Input() public hasAlert: boolean;
  @Input() public hasAlertRecentlyClosed: boolean;
  @Input() public isAlert: boolean;
  @Input() public delegates: SharePointDelegate[];
  @Input() public viewWarnButton: boolean = true;
  @Input() public resourceProfile: ReportProfileResourceBase<any>;


  @Output() public onWarnClick = new EventEmitter();

  public isCollapsed: boolean;
  public id: string;

  constructor(
    private modalService: NgbModal,
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService) {
    this.isCollapsed = true;
  }

  ngOnInit() {
    if (this.title) {
      this.id = this.title.replaceAll(' ', '-');
    }
    if (this.delegates) {
      this.delegates = this.delegates.sort((a,b) => {
        let aName = a.accesses?.name ?? a.permissions?.name;
        let bName = b.accesses?.name ?? b.permissions?.name;
        return aName.localeCompare(bName, undefined, { sensitivity: 'base' })
      });
    }
  }



  @ViewChild('viewDetailBodyTemplate') viewDetailBodyTemplate: TemplateRef<any>;

  @ViewChild('viewDetailButtonsTemplate') viewDetailButtonsTemplate: TemplateRef<any>;

  openDetails(event): void {
    const modalRef = this.modalService.open(InformationModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.title = this.i18Next.t("profile.provider.microsoft.oneDrive.external");
    modalRef.componentInstance.activateFullScreen = true;
    modalRef.componentInstance.displayWarnClick = this.viewWarnButton;
    modalRef.componentInstance.bodyTemplate = this.viewDetailBodyTemplate;
    modalRef.componentInstance.buttonsTemplate = this.viewDetailButtonsTemplate;
    modalRef.componentInstance.ok = this.i18Next.t("button.ok");
    modalRef.result.then((model: any) => {
      if (model == true) {
        this.warnClick(null);
      }
    }, (reason) => {
      //Dismiss
    });
  }

  expandAllAccounts(event): void {
    this.isCollapsed = !this.isCollapsed;
  }

  warnClick(event): void {
    this.onWarnClick.emit(event);
  }

}

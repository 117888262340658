import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { StringHelper } from 'projects/web-core/src/lib/helpers/string-helper';
import { GuidedTourService } from '../../../services/guided-tour.service';

@Component({
  selector: 'i2a-start-tour',
  templateUrl: './start-tour.component.html',
  styleUrls: ['./start-tour.component.scss']
})
export class StartTourComponent implements OnInit, AfterViewInit {

  @Input()
  public tour: string;

  ngAfterViewInit(): void {
    if (!StringHelper.isNullOrWhitespace(this.tour)) {
      switch (this.tour) {
        case "profile":
          this.guidedTour.startProfileTour();
          break;
        case "modal":
          this.guidedTour.startModalTour();
          break;

        default:
          break;
      }
    }
  }

  constructor(private guidedTour: GuidedTourService) { }

  ngOnInit() {
  }

}

import { Component, OnInit, OnDestroy, Input, Inject, ViewChild, TemplateRef } from '@angular/core';
import { SharePointGroupMemberModel, MicrosoftOfficeProfileProviderDatasourceService, SharePointReportProfileAlertAdministratorsModel, AlertConstants, ProviderInstanceResourceSummaryModel, SharePointReportProfile, SharePointSiteReportProfile, MicrosoftOfficeReportProfileModel, BaseProviderInstanceResourceModel } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { InformationModalComponent } from 'projects/web-user-profile/src/app/components/shared/modal/information-modal/information-modal.component';
import { SharePointAdministratorsModalComponent } from '../share-point-administrators-modal/share-point-administrators-modal.component';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { SharePointGroupDelegateWithAlert } from 'projects/web-user-profile/src/app/models/providers/microsoft/share-point/share-point-group-delegate-with-alert';
import { WarningHelper } from 'projects/web-user-profile/src/app/helpers/warning-helper';
import { ProviderHelper } from 'projects/web-user-profile/src/app/helpers/provider-helper';
import { ActivatedRoute, Router } from '@angular/router';
import { SharePointPermissionComponent } from '../share-point-permission/share-point-permission.component';

@Component({
  selector: 'i2a-share-point-administrators',
  templateUrl: './share-point-administrators.component.html',
  styleUrls: ['./share-point-administrators.component.scss']
})
export class SharePointAdministratorsComponent extends ProfileItemBaseClassComponent<SharePointReportProfile> implements OnInit, OnDestroy {

  @Input() administrators: SharePointGroupMemberModel[];
  @Input() isGroupBySite: boolean;
  @Input() public resources: BaseProviderInstanceResourceModel[];

  public members: SharePointGroupDelegateWithAlert[];
  public isEveryone: boolean;
  public isEveryoneExceptExternalUsers: boolean;

  public headerTitle: string;
  public site: SharePointSiteReportProfile;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    modalService: NgbModal,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.isGroupBySite) {
      this.site = (<MicrosoftOfficeReportProfileModel>this.profile).sharePointSites.find(s => s.id.toLocaleLowerCase() === this.resourceProfile.siteId.toLocaleLowerCase());
      this.headerTitle = this.site?.title;
    }

    this.setHasWarning();
  }

  setItems() {
    if (this.administrators) {
      super.setItems();
      this.hasAlert = this.hasAlertInAlerts(false);
      this.hasAlertRecentlyClosed = this.hasAlertInAlerts(true);
      this.members = [];
      this.setDelegates(this.members, this.administrators, AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_ADMIN_ID);
      this.isEveryone = this.members.some((m) => m.isEveryone);
      this.isEveryoneExceptExternalUsers = this.members.some((m) => m.isEveryoneExceptExternalUsers);

      this.id = `${this.componentId}-${this.providerInstanceResource.providerTypeId}-${this.providerInstanceResource.providerInstanceResourceId}-SharePointAdministrators`;

    }
  }

  private hasAlertInAlerts(done: boolean): boolean {
  return this.administrators.findIndex(administrator => AlertReasonHelper.alertsContainsKeyValuePair(this.alerts, AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_ADMIN_ID, administrator.id.toString(), done)) >= 0;
  }

  private setHasWarning(): void {
    if (this.isEveryone || this.isEveryoneExceptExternalUsers) {
      let warningInfoType = WarningHelper.GetWarningInfoTypeCaseGroupMember(this.isEveryone, this.isEveryoneExceptExternalUsers);
      this.warningLevel = WarningHelper.GetWarningLevel(warningInfoType);
      this.hasWarnings([{
        id: this.id,
        resourceId: 'application' + this.resourceProfile.providerInstanceResource.providerTypeId.toLocaleLowerCase() + this.resourceProfile.providerInstanceResource.providerInstanceResourceId.toLocaleLowerCase(),
        resourceProfile: this.resourceProfile,
        warningInfoType: warningInfoType,
        warningLevel: this.warningLevel,
        label: `${this.i18Next.t(ProviderHelper.GetSharePointProviderTypeLabel(this.resourceProfile, this.site != null || this.site != undefined))} > ${(<ProviderInstanceResourceSummaryModel>this.providerInstanceResource).name}`,
        hasAlert: this.hasAlert,
        hasAlertRecentlyClosed: this.hasAlertRecentlyClosed,
        warningInfo: SharePointPermissionComponent.GetWarningInfoTypeLabelByType(warningInfoType),
        viewMoreDetails: true,
        viewDissmiss: true,
        viewResource: false
      }]);

      this.subscriptions.add(WarningHelper.openModalOnRoute(this.router, this.route, this.id, this.openDetails.bind(this), this.warnClick.bind(this)));
    }
  }

  setDelegates(dest: SharePointGroupDelegateWithAlert[], source: SharePointGroupMemberModel[], key: string) {
    let delegatesWithAlerts = AlertReasonHelper.GetIds(this.alerts, key, false);
    let delegatesWithAlertsRecentlyClosed = AlertReasonHelper.GetIds(this.alerts, key, true);

    source.forEach(d => {
      var dWithAlert = {
        "id": d.id,
        "title": d.title,
        "userPrincipalName": d.userPrincipalName,
        "email": d.email,
        "loginName": d.loginName,
        "isGroup": d.isGroup,
        "isO365Group": d.isO365Group,
        "hasAlert": delegatesWithAlerts[d.id],
        "hasAlertRecentlyClosed": delegatesWithAlertsRecentlyClosed[d.id],
        "isEveryone": d.isEveryone,
        "isEveryoneExceptExternalUsers": d.isEveryoneExceptExternalUsers
      };
      dest.push(dWithAlert);
    });
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(SharePointAdministratorsModalComponent, { windowClass: 'id-modal', centered: true, size: 'lg' });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.administrators = this.administrators;
    modalRef.componentInstance.headerTitle = this.headerTitle;
    modalRef.result.then((model: SharePointReportProfileAlertAdministratorsModel) => {
      this.initAlertModel(model);
      if (this.isGroupBySite) {
        model.providerInstanceResources = this.resources;
      } else {
        model.providerInstanceResources = [this.providerInstanceResource];
      }
      this.subscriptions.add(this.microsoftDatasource.alertSharePointAdministrators(this.profile.providerInstanceId, this.profile.userId, model).subscribe(model => {
        this.setAlertProcessed(model);
      }));
    }).catch((error) => {
      // console.log(error);
    });
  }

  @ViewChild('viewDetailTemplate') viewDetailTemplate: TemplateRef<any>;

  openDetails(event): void {
    const modalRef = this.modalService.open(InformationModalComponent, { windowClass: 'id-modal', centered: true, size: 'lg' });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.title = this.i18Next.t("modal.detail.title");
    modalRef.componentInstance.headerTitle = this.headerTitle;
    modalRef.componentInstance.bodyTemplate = this.viewDetailTemplate;
    modalRef.componentInstance.ok = this.i18Next.t("button.close");
    modalRef.result.then((model: any) => {
      if (model == true) {
        this.warnClick(null);
      }
    }, (reason) => {
      //Dismiss
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { SharePointReportProfileDriveItemRole, SharePointReportProfilePermissionDetail } from '@i2a/web-api-client';
import { EnumHelper } from 'projects/web-user-profile/src/app/helpers/enum-helper';

@Component({
  selector: 'i2a-share-point-permission-detail',
  templateUrl: './share-point-permission-detail.component.html',
  styleUrls: ['./share-point-permission-detail.component.scss']
})
export class SharePointPermissionDetailComponent implements OnInit {
  
  public rootPathValue: string = '/';

  @Input()
  public driveItems: {
    [key: string]: SharePointReportProfilePermissionDetail;
  };
  @Input() displayLegend: boolean = true;
  public paths: string[];
  public hasSensitivityLabel: boolean;
  
  public displayReadLegend: boolean;
  public displayUpdateLegend: boolean;
  public displayOwnerLegend: boolean;
  public displayUnknownLegend: boolean;

  constructor() { 
    this.displayReadLegend = false;
    this.displayUpdateLegend = false;
    this.displayOwnerLegend = false;
    this.displayUnknownLegend = false;
  }

  ngOnInit() {
    if (this.driveItems) {
      this.hasSensitivityLabel = Object.values(this.driveItems).some(d => d.hasSensitivityLabel);
      this.paths = [];
      for (let key in this.driveItems) {
        this.paths.push(key);
        this.displayReadLegend = this.displayReadLegend || EnumHelper.HasFlag(this.driveItems[key].rights, SharePointReportProfileDriveItemRole.Read) || EnumHelper.HasFlag(this.driveItems[key].rights, SharePointReportProfileDriveItemRole.Owner);
        this.displayUpdateLegend = this.displayUpdateLegend || EnumHelper.HasFlag(this.driveItems[key].rights, SharePointReportProfileDriveItemRole.Write) || EnumHelper.HasFlag(this.driveItems[key].rights, SharePointReportProfileDriveItemRole.Owner);
        this.displayOwnerLegend = this.displayOwnerLegend || EnumHelper.HasFlag(this.driveItems[key].rights, SharePointReportProfileDriveItemRole.Owner);
        this.displayUnknownLegend = this.driveItems[key].rights == SharePointReportProfileDriveItemRole.Unknown;
      }
      this.paths = this.paths.sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
    }
  }

  hasRead(path: string): boolean {
    return EnumHelper.HasFlag(this.driveItems[path].rights, SharePointReportProfileDriveItemRole.Read);
  }


  hasUpdate(path: string): boolean {
    return EnumHelper.HasFlag(this.driveItems[path].rights, SharePointReportProfileDriveItemRole.Write);
  }

  hasOwner(path: string): boolean {
    return EnumHelper.HasFlag(this.driveItems[path].rights, SharePointReportProfileDriveItemRole.Owner);
  }

  hasUnknown(path: string): boolean {
    return this.driveItems[path].rights == SharePointReportProfileDriveItemRole.Unknown;
  }



}

<i2a-profile-alert-base-modal
  [manualDescriptionKey]="'applyCorrection.providers.microsoft.oneDrive.delegate.external.manual.description'"
  [manualLinkKey]="'applyCorrection.providers.microsoft.oneDrive.delegate.external.manual.link'"
  [automaticDescriptionKey]="'applyCorrection.providers.microsoft.oneDrive.delegate.external.automatic.description'"
  [ignoreCommentToSend]="session.IgnoreCommentToSend" [displayComment]="session.DisplayComment"
  [validation]="validation" [canSend]="canSend" [modalTitle]="'profile.confirmationOfInformationTitle' | i18next"
  [resourceProfile]="resourceProfile">

  <ng-container header-picto>
    <i2a-badge-icon>
      <ng-container icon>
        <fa-icon [icon]="['fas', 'users']" size="lg"></fa-icon>
      </ng-container>
    </i2a-badge-icon>
  </ng-container>

  <ng-container header-title>
    {{'profile.provider.microsoft.oneDrive.external' | i18next}}<br>
  </ng-container>

  <ng-container body>
    <div class="container-fluid">
      <div class="row pt-3">
        <div class="col-12">

          <div class="form-group delegate-container">
            <table class="table-sm id-table mt-2">
              <tbody>
                <tr>
                  <td scope="row text-center" class="cell-checkbox">
                    <i2a-input-checkbox [(model)]="allDelegates" (modelChange)="allDelegatesChecked($event)"
                      [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                      [valueUnChecked]="false">
                    </i2a-input-checkbox>
                  </td>
                  <td scope="row" class="text-break w-100 delegate-title">
                    {{'profile.provider.microsoft.oneDrive.allAccounts' |i18next}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row pt-3">
        <ng-container *ngFor="let delegateValidation of validation.delegates; let i = index">
          <div class="col-12">
            <i2a-share-point-access-permission-form [(validation)]="validation.delegates[i]"
              [folders]="getFolders(delegateValidation.delegate.logonUserId)"
              [driveItemsPermissions]="getPermissions(delegateValidation.delegate.logonUserId)"
              (setCanSend)="setCanSend()" [allAccessAndPermissionsTitle]="delegateValidation.delegate.name">
            </i2a-share-point-access-permission-form>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</i2a-profile-alert-base-modal>
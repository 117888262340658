import { Component, OnInit, Input } from '@angular/core';
import { StringHelper } from '@i2a/web-core';

@Component({
  selector: 'i2a-sub-title',
  templateUrl: './sub-title.component.html',
  styleUrls: ['./sub-title.component.scss']
})
export class SubTitleComponent implements OnInit {
  @Input() public subTitle: string;
  @Input() public description: string;
  @Input() customClass: string;

  public classes: string;

  constructor() { }

  ngOnInit() {
    this.classes = "col-12 id-subtitle";
    if (!StringHelper.isNullOrWhitespace(this.customClass)) {
      this.classes = `${this.classes} ${this.customClass}`;
    }
  }

}

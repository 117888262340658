<table class="table table-sm table-bordered id-table" *ngIf="folderPaths.length > 0">
  <thead>
    <tr>
      <th scope="col" class="w-100">
        {{ "profile.provider.microsoft.exchange.folder" | i18next }}
      </th>
      <th scope="col">
        {{ "profile.provider.microsoft.exchange.permissions" | i18next }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let folderPath of folderPaths">
      <td scope="row">
        {{folderPath}}
      </td>
        <td class="text-nowrap">
        <span class="exchange-right" *ngIf="hasRead(folderPath)" [title]="'profile.exchangeRight.read' | i18next ">
          <fa-icon [icon]="['fas', 'eye' ]"></fa-icon>
        </span>
        <span class="exchange-right" *ngIf="hasLimitedRead(folderPath)"
          [title]="'profile.exchangeRight.limitedRead' | i18next ">
          <fa-layers>
            <fa-icon [icon]="['fas', 'eye']" transform="shrink-3 down-3 left-4"></fa-icon>
            <fa-icon [icon]="['fas', 'filter']" transform="shrink-5 up-3 right-6"></fa-icon>
          </fa-layers>
        </span>

        <span class="exchange-right" *ngIf="hasCreate(folderPath)" [title]="'profile.exchangeRight.create' | i18next ">
          <fa-icon [icon]="['fal', 'plus-circle']"></fa-icon>
        </span>

        <span class="exchange-right" *ngIf="hasUpdate(folderPath)" [title]="'profile.exchangeRight.update' | i18next ">
          <fa-icon [icon]="['fal', 'pen']"></fa-icon>
        </span>
        <span class="exchange-right" *ngIf="hasLimitedUpdate(folderPath)"
          [title]="'profile.exchangeRight.limitedUpdate' | i18next ">
          <fa-layers>
            <fa-icon [icon]="['fal', 'pen']" transform="shrink-3 down-3 left-4"></fa-icon>
            <fa-icon [icon]="['fas', 'filter']" transform="shrink-5 up-3 right-6"></fa-icon>
          </fa-layers>
        </span>

        <span class="exchange-right" *ngIf="hasDelete(folderPath)" [title]="'profile.exchangeRight.delete' | i18next ">
          <fa-icon [icon]="['fal', 'trash-alt']"></fa-icon>
        </span>
        <span class="exchange-right" *ngIf="hasLimitedDelete(folderPath)"
          [title]="'profile.exchangeRight.limitedDelete' | i18next ">
          <fa-layers>
            <fa-icon [icon]="['fal', 'trash-alt']" transform="shrink-3 down-3 left-4"></fa-icon>
            <fa-icon [icon]="['fas', 'filter']" transform="shrink-5 up-3 right-6"></fa-icon>
          </fa-layers>
        </span>
        <span class="exchange-right" *ngIf="hasFolderVisible(folderPath)"
        [title]="'profile.exchangeRight.folderVisible' | i18next ">
        <fa-layers>
          <fa-icon [icon]="['fal', 'folder']" transform="shrink-3 down-3 left-4"></fa-icon>
          <fa-icon [icon]="['fas', 'eye']" transform="shrink-5 up-3 right-6"></fa-icon>
        </fa-layers>
      </span>

      </td>
    </tr>
  </tbody>
</table>

<table class="table table-sm id-table legend" *ngIf="folderPaths.length > 0">
  <tbody>
    <tr>
      <td scope="row" class="text-nowrap" *ngIf="displayReadLegend" >
        <span class="exchange-right" [title]="'profile.exchangeRight.read' | i18next ">
          <fa-icon [icon]="['fas', 'eye' ]"></fa-icon>
        </span>
        {{'profile.exchangeRight.read' | i18next }}
      </td>
      <td scope="row" class="text-nowrap" *ngIf="displayLimitedReadLegend" >
        <span class="exchange-right" [title]="'profile.exchangeRight.limitedRead' | i18next ">
          <fa-layers>
            <fa-icon [icon]="['fas', 'eye']" transform="shrink-3 down-3 left-4"></fa-icon>
            <fa-icon [icon]="['fas', 'filter']" transform="shrink-5 up-3 right-6"></fa-icon>
          </fa-layers>
        </span>
        {{'profile.exchangeRight.limitedRead' | i18next }}
      </td>
    </tr>
    <tr *ngIf="displayFolderVisibleLegend">
      <td scope="row" class="text-nowrap">
        <span class="exchange-right" [title]="'profile.exchangeRight.folderVisible' | i18next ">
          <fa-layers>
            <fa-icon [icon]="['fal', 'folder']" transform="shrink-3 down-3 left-4"></fa-icon>
            <fa-icon [icon]="['fas', 'eye']" transform="shrink-5 up-3 right-6"></fa-icon>
          </fa-layers>
        </span>
        {{'profile.exchangeRight.folderVisible' | i18next }}
      </td>
    </tr>
    <tr>
      <td scope="row" class="text-nowrap" *ngIf="displayCreateLegend" >
        <span class="exchange-right" [title]="'profile.exchangeRight.create' | i18next ">
          <fa-icon [icon]="['fal', 'plus-circle']"></fa-icon>
        </span>
        {{'profile.exchangeRight.create' | i18next }}
      </td>
      <td></td>
    </tr>
    <tr>
      <td scope="row" class="text-nowrap" *ngIf="displayUpdateLegend" >

        <span class="exchange-right" [title]="'profile.exchangeRight.update' | i18next ">
          <fa-icon [icon]="['fal', 'pen']"></fa-icon>
        </span>
        {{'profile.exchangeRight.update' | i18next }}
      </td>
      <td class="text-nowrap" *ngIf="displayLimitedUpdateLegend" >
        <span class="exchange-right" [title]="'profile.exchangeRight.limitedUpdate' | i18next ">
          <fa-layers>
            <fa-icon [icon]="['fal', 'pen']" transform="shrink-3 down-3 left-4"></fa-icon>
            <fa-icon [icon]="['fas', 'filter']" transform="shrink-5 up-3 right-6"></fa-icon>
          </fa-layers>
        </span>
        {{'profile.exchangeRight.limitedUpdate' | i18next }}
      </td>
    </tr>
    <tr>
      <td scope="row" class="text-nowrap" *ngIf="displayDeleteLegend" >

        <span class="exchange-right" [title]="'profile.exchangeRight.delete' | i18next ">
          <fa-icon [icon]="['fal', 'trash-alt']"></fa-icon>
        </span>
        {{'profile.exchangeRight.delete' | i18next }}
      </td>
      <td class="text-nowrap" *ngIf="displayLimitedDeleteLegend" >
        <span class="exchange-right" [title]="'profile.exchangeRight.limitedDelete' | i18next ">
          <fa-layers>
            <fa-icon [icon]="['fal', 'trash-alt']" transform="shrink-3 down-3 left-4"></fa-icon>
            <fa-icon [icon]="['fas', 'filter']" transform="shrink-5 up-3 right-6"></fa-icon>
          </fa-layers>
        </span>
        {{'profile.exchangeRight.limitedDelete' | i18next }}
      </td>
    </tr>
  </tbody>
</table>

<span *ngIf="folderPaths.length == 0" class="no-partial-rights">
  {{"profile.provider.microsoft.exchange.noPartialGrants" | i18next  }}
</span>
<ng-container>
  <svg *ngIf="criticality == CriticalityEnum.Information" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 13.125 13.125">
    <path d="M363.563,108.875L357,122h13.125Zm0.437,5.25V118.5h-0.875v-4.375H364Zm-0.875,5.25H364v0.875h-0.875v-0.875Z"
      fill="#00aa7c" transform="translate(-357 -108.875)" /></svg>
  <svg *ngIf="criticality == CriticalityEnum.Warning" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 13.125 13.125">
    <path d="M363.563,108.875L357,122h13.125Zm0.437,5.25V118.5h-0.875v-4.375H364Zm-0.875,5.25H364v0.875h-0.875v-0.875Z"
      fill="#ffcf64" transform="translate(-357 -108.875)" /></svg>
  <svg *ngIf="criticality == CriticalityEnum.Severe" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 13.125 13.125">
    <path d="M363.563,108.875L357,122h13.125Zm0.437,5.25V118.5h-0.875v-4.375H364Zm-0.875,5.25H364v0.875h-0.875v-0.875Z"
      fill="#e68200" transform="translate(-357 -108.875)" /></svg>
  <svg *ngIf="criticality == CriticalityEnum.Critical" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 13.125 13.125">
    <path d="M363.563,108.875L357,122h13.125Zm0.437,5.25V118.5h-0.875v-4.375H364Zm-0.875,5.25H364v0.875h-0.875v-0.875Z"
      fill=" #aa0000" transform="translate(-357 -108.875)" /></svg>
</ng-container>
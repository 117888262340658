import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { RequestCachingService } from '../request-caching.service';
import { of, Observable } from 'rxjs';
import { share, tap } from 'rxjs/operators';

//https://blog.fullstacktraining.com/caching-http-requests-with-angular/
@Injectable()
export class CachingInterceptor implements HttpInterceptor {
    constructor(private cache: RequestCachingService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let request = req.clone();
        if (req.method == "GET" && req.headers.has("X-I2A-CLIENT-CACHE") && req.headers.get("X-I2A-CLIENT-CACHE") == String(true)) {
            const cachedResponse = this.cache.get(req);
            return cachedResponse ? of(cachedResponse.clone()) : this.sendRequest(request, next, this.cache);
        }
        else {
            return next.handle(request);
        }
    }

    sendRequest(
        req: HttpRequest<any>,
        next: HttpHandler,
        cache: RequestCachingService): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    cache.put(req, event.clone());
                }
            }),
            share()
        );
    }
}

export class ListSummaryModel {
  id: string;
  siteId: string;
  webId: string;
  title: string;
  baseTemplate: string;
  isPrivate: boolean;
  hidden: boolean;
  isSiteAssetsLibrary: boolean;
  isCatalog: boolean|null;
}

import { Injectable, InjectionToken } from '@angular/core';
import { I18NextModule } from 'angular-i18next';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalizationRequester } from '../models/localization-requester';
import { LocalizationUrlService } from '../data/localization-url.service';
import dayJS from 'dayjs';

export const I2A_CONFIG_SERVICE = new InjectionToken<any>("I2A_CONFIG_SERVICE");

export interface AuthConfig {
  clientId: string;
  authority: string;
  centralAuthority: string;
  scopes: string;
  changePasswordUrl: string;
  logoutUrl: string;
  logoutUrlForSupport: string;

}

export interface IWebConfig {
  defaultCulture: string;
  apiEndPoint: string;
  expertUrl: string;
  monitoringUrl: string;
  urlPrefix: string;
  oAuth: AuthConfig;
  localization: LocalizationRequester;
}

export interface IConfigService<CONFIG extends IWebConfig> {
  loadConfigFile(): Promise<boolean>;
  getI18NextConfig(): any;
  Config: CONFIG;
  Config$: Observable<CONFIG>;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService<CONFIG extends IWebConfig> implements IConfigService<CONFIG> {

  protected configSubject?: BehaviorSubject<CONFIG>;
  public Config$: Observable<CONFIG>;

  get Config(): CONFIG {
    return this.configSubject.value;
  }

  set Config(config: CONFIG) {
    this.configSubject.next(config);
  }


  constructor(
    protected localizationUrlService: LocalizationUrlService
  ) {
    console.log("Init config");
    this.configSubject = new BehaviorSubject<CONFIG>(null);
    this.Config$ = this.configSubject.asObservable();
  }

  public loadConfigFile(): Promise<boolean> {
    console.log("load config");
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', 'assets/config/config.json');

      xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          //console.debug(`==> loaded ${xhr.responseText}`);
          let data: CONFIG = JSON.parse(xhr.responseText);
          this.configSubject.next(data);
          resolve(true);
        } else if (xhr.readyState === XMLHttpRequest.DONE) {
          reject(false);
        }
      });

      xhr.send(null);
    });
  }

  public getI18NextConfig(): any {
    return {
      compatibilityJSON: 'v3',
      fallbackLng: this.Config.defaultCulture,
      lng: this.Config.defaultCulture,
      debug: false,
      returnEmptyString: true,
      ns: [
        'WebUserProfile'
      ],
      defaultNS: 'WebUserProfile',
      backend: {
        loadPath: this.localizationUrlService.getResourcesUrl(this.Config.localization, this, null),
        allowMultiLoading: true
      },
      load: 'currentOnly',
      interpolation: {
        skipOnVariables: false,
        escapeValue: false,
        format: I18NextModule.interpolationFormat((value, format, lng) => {
          if (format === 'uppercase') {
            return value.toUpperCase();
          }
          if (format === 'lowercase') {
            return value.toLowerCase();
          }
          if (value instanceof Date) {
            return dayJS(value).format(format);
          }
          return value;
        })
      }
    };
  }
}


import { Component, OnInit, EventEmitter, Inject, Input } from '@angular/core';
import { ReportProfileBaseModel, UserModel, BaseProviderInstanceResourceModel, FileShareReportProfile, AlertInvalidStateFeaturedModel, FileShareReportProfilePermissions, FileShareReportProfileDelegate, OneDriveReportProfile, OneDriveReportProfileDelegate, Constants, MicrosoftOfficeReportProfileModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ProfileHelper } from 'projects/web-user-profile/src/app/helpers/profile-helper';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';
import { FileSharePermissionConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/file-share-permission-configuration';

@RegisterMyProfileComponent(TemplateConstants.MODULE_FILESHARE_PERMISSION)
@Component({
  selector: 'i2a-file-share-permission-module',
  templateUrl: './file-share-permission-module.component.html',
  styleUrls: ['./file-share-permission-module.component.scss']
})
export class FileSharePermissionModuleComponent extends ProfileBaseClassComponent<FileSharePermissionConfiguration,FileShareReportProfile> implements OnInit, IModule<FileSharePermissionConfiguration> {
  private resource: BaseProviderInstanceResourceModel;

  @Input()
  public delegatesWithoutAccess: FileShareReportProfilePermissions[];

  public delegates: FileShareReportProfilePermissions[];

  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, null);
  }

  ngOnInit() {
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: FileSharePermissionConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel, resourceTemplate?: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.resource = resource;
    this.resourceProfile = (<MicrosoftOfficeReportProfileModel>profile).fileShareProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
    this.resourceProfile.permissions.sort((a, b) => { return ProfileHelper.compare(a, b, this.i18Next); });
    this.delegatesWithoutAccess = FileSharePermissionModuleComponent.SetDelegates(this.resourceProfile.permissions, this.resourceProfile.delegates, this.configuration);
    this.delegates = FileSharePermissionModuleComponent.SetDelegates(this.resourceProfile.permissions, this.resourceProfile.delegates, this.configuration);
  }

  public static SetDelegates(permissions: FileShareReportProfilePermissions[], FileShareProfileDelegates: (FileShareReportProfileDelegate | OneDriveReportProfileDelegate)[], configuration: FileSharePermissionConfiguration): FileShareReportProfilePermissions[] {
    
    return permissions;
  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }

}

<i2a-profile-alert-base-modal [manualDescriptionKey]="'applyCorrection.user.accessMode.manual.description'"
  [manualLinkKey]="'applyCorrection.user.accessMode.manual.link'"
  [automaticDescriptionKey]="'applyCorrection.user.accessMode.automatic.description'" [validation]="validation"
  [ignoreCommentToSend]="session.IgnoreCommentToSend" [displayComment]="session.DisplayComment" [canSend]="canSend"
  [modalTitle]="'profile.confirmationOfInformationTitle' | i18next " [user]="user"
  [disableManualRemediation]="disableManualRemediation">


  <ng-container header-picto>
    <i2a-badge-icon>
      <ng-container icon>
        <fa-icon [icon]="['fal', 'globe']" size="lg"></fa-icon>
      </ng-container>
    </i2a-badge-icon>
  </ng-container>

  <ng-container header-title>
    {{'profile.user.accessMode.title' | i18next }}<br>
  </ng-container>

  <ng-container body>
    <div class="container-fluid">
      <div class="row pt-3">
        <div class="col">
          <table class="table-sm id-table">
            <tbody>
              <tr *ngFor="let accessMode of items">
                <td scope="row">
                  <i2a-input-checkbox [(model)]="accessMode.checked" (modelChange)="checked($event, accessMode)"
                    [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                    [valueUnChecked]="false"> </i2a-input-checkbox>
                </td>
                <td scope="row" class="text-break w-100">
                  {{ accessMode.label}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-container>

</i2a-profile-alert-base-modal>
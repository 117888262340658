import { Component, OnInit, Inject, Input } from '@angular/core';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { ReportProfileBaseModel, UserModel, BaseProviderInstanceResourceModel, ZimbraMailingReportProfile, ZimbraReportProfileModel, AlertInvalidStateFeaturedModel, ZimbraMailingReportProfileDelegate, ZimbraMailingReportProfilePermissions, ReportProfileResourceBase } from '@i2a/web-api-client';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ProfileHelper } from 'projects/web-user-profile/src/app/helpers/profile-helper';
import { ZimbraMailingDelegate } from 'projects/web-user-profile/src/app/models/providers/zimbra/mailing/zimbra-mailing-delegate';
import { ZimbraMailingDelegateConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/zimbra-mailing-delegate-configuration';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';

@RegisterMyProfileComponent(TemplateConstants.MODULE_ZIMBRA_MAILING_DELEGATE)
@Component({
  selector: 'i2a-zimbra-mailing-delegate-module',
  templateUrl: './zimbra-mailing-delegate-module.component.html',
  styleUrls: ['./zimbra-mailing-delegate-module.component.scss']
})
export class ZimbraMailingDelegateModuleComponent extends ProfileBaseClassComponent<ZimbraMailingDelegateConfiguration,ZimbraMailingReportProfile> implements OnInit, IModule<ZimbraMailingDelegateConfiguration> {
  private resource: BaseProviderInstanceResourceModel;

  @Input()
  public delegatesWithAccessAndPermissions: ZimbraMailingDelegate[] = [];

  public showMore: boolean;
  public showMoreAfter: number;
  public maxShowMore: number;
  public isVisibleButtonsShowMore: boolean;
  private isActiveShowMoreOption: boolean;

  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, null);
  }

  ngOnInit() {
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: ZimbraMailingDelegateConfiguration,  alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel, resourceTemplate?: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.resource = resource;
    this.resourceProfile = (<ZimbraReportProfileModel>profile).mailingProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
    this.resourceProfile.delegates.sort((a, b) => { return ProfileHelper.compare(a, b, this.i18Next); });
    let delegatesWithAccessAndPermissions = ZimbraMailingDelegateModuleComponent.SetDelegates(this.resourceProfile.delegates, this.resourceProfile.permissions);
    delegatesWithAccessAndPermissions?.forEach((d: ZimbraMailingDelegate) => {
      if (d.accesses?.isNameKey || d.permissions?.isNameKey) {
        this.delegatesWithAccessAndPermissions.unshift(d);        
      } else {
        this.delegatesWithAccessAndPermissions.push(d);
      }
    });
    
    this.showMoreAfter = configuration.showMoreAfter ?? 0;
    this.isActiveShowMoreOption = this.showMoreAfter > 0;
    this.isVisibleButtonsShowMore = this.isActiveShowMoreOption && this.delegatesWithAccessAndPermissions?.length > this.showMoreAfter;
    this.setShowMoreValue();
  }

  public static SetDelegates(zimbraMailingProfileDelegates: ZimbraMailingReportProfileDelegate[], permissions: ZimbraMailingReportProfilePermissions[]): ZimbraMailingDelegate[] {
    var delegates = [];
    zimbraMailingProfileDelegates.forEach(item => {
        var delegate = new ZimbraMailingDelegate();
        delegate.accesses = item;
        var idx = -1;
        if (item.userId != null) {
          idx = permissions.findIndex(d => d.userId != null && d.userId == item.userId);
        }
        else if (item.providerInstanceUser != null) {
          idx = permissions.findIndex(d => d.providerInstanceUser != null && d.providerInstanceUser.providerInstanceResourceId == item.providerInstanceUser.providerInstanceResourceId);
        }
        else {
          idx = permissions.findIndex(p => p.logonUserId == item.logonUserId);
        }
        if (idx >= 0) {
          delegate.permissions = permissions[idx];
        }
        delegates.push(delegate);
      });
    return delegates;
  }
  
  public onShowMore() {
    this.showMore = !this.showMore;
    this.setShowMoreValue();
  }
  
  private setShowMoreValue() {
    this.maxShowMore = (!this.showMore && this.isActiveShowMoreOption) ? this.showMoreAfter : this.delegatesWithAccessAndPermissions?.length;
  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }
}

import { BaseProviderInstanceResourceModel } from '../base-provider-instance-resource-model';
import { BaseProviderInstanceUserModel } from '../base-provider-instance-user-model';

export class ReportProfileDelegate {
  providerInstanceUser: BaseProviderInstanceUserModel;
  providerInstanceResource: BaseProviderInstanceResourceModel;
  userId: string|null;
  logonUserId: string;
  isNameKey: boolean;
  name: string;
  userIsDeactivated: boolean;
  unreconcileAccount: boolean;
}

<i2a-base-modal [modalTitle]="'profile.title.rulesComputedDelegateDetails' | i18next ">
  <ng-container body>
    <div class="information-modal">
      <div id="modal-header" class="row id-header">
        <table class="table-sm id-table">
          <tbody>
            <tr>
              <td scope="row text-center" class="p-0 id-header-picto">
                <img class="id-provider-picture" *ngIf="providerPhotoUrl" [src]="providerPhotoUrl" width="28"
                  height="28">
              </td>
              <td scope="row" class="text-break w-100 align-self-center" #headertitle>
                <span class="provider-type" [title]="providerTypeLabel">{{ providerTypeLabel }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 mt-3 mb-3">
            <i2a-modal-profile-header-title>
              <ng-container picto>
                <i2a-delegate-photo [isAnonymous]="delegate.logonUserId == 'Anonymous'"
                  [isDefault]="delegate.logonUserId == 'Default'"
                  [isApplication]="delegate.logonUserId == 'Application'" [pir]="delegate.providerInstanceResource"
                  [piu]="delegate.providerInstanceUser" [isExternalUser]="false">
                </i2a-delegate-photo>
              </ng-container>
              <ng-container title>
                <span [title]="name">{{name}}</span>
              </ng-container>
            </i2a-modal-profile-header-title>

            <div class="container-fluid rules-computed-delegate-details-modal">
              <div class="row pt-3 no-gutters" id="modal-body">
                <ng-container *ngIf="configuration.viewDetailKinds">
                  <div class="col-12 mt-2 mb-1" *ngIf="kindNames.length > 0"> <span
                      class="detail-title">{{'profile.rulesComputedDelegates.providerAuditResourceKind' | i18next }}</span>
                  </div>
                  <div class="col-sm-12 col-md-6 mb-2" *ngFor="let kindName of kindNames; let index = index">
                    <div class="container-fluid">
                      <div class="row no-gutters">
                        <div class=" col-2">
                          <i2a-input-checkbox [model]="hasFlag(delegate.resourceKind,kindIds[index])"
                            [classNameChecked]="'btn-outline-dark'" [className]="'btn-outline-light'"
                            [valueChecked]="true" [valueUnChecked]="false" [disabled]=true>
                          </i2a-input-checkbox>
                        </div>
                        <div
                          [ngClass]="{'selected': hasFlag(delegate.resourceKind,kindIds[index]), 'un-selected': !hasFlag(delegate.resourceKind,kindIds[index])}">
                          {{ "common.providerAuditResourceKind." + kindName | i18next}}
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="configuration.viewDetailFolders">
                  <div class="col-12 mt-2 mb-1" *ngIf="folders.length > 0"> <span
                      class="detail-title">{{'profile.rulesComputedDelegates.specificFolders' | i18next }}</span></div>
                  <div class="col-12">
                    <table class="table table-sm table-bordered id-table">
                      <tbody>
                        <tr *ngFor="let folder of folders">
                          <td scope="row" class="text-break">
                            {{folder}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ng-container>
                <ng-container *ngIf="configuration.viewDetailHumanExpressions">
                  <div class="col-12 mt-2 mb-1" *ngIf="delegate.ruleIds.length > 0"> <span
                      class="detail-title">{{'profile.rulesComputedDelegates.permissionRules' | i18next }}</span></div>
                  <div class="clo-12">
                    <ul *ngIf="delegate.ruleIds">
                      <li *ngFor="let ruleId of delegate.ruleIds" class="col-12 mb-2">
                        <i2a-rule-human-expression [ruleId]=ruleId></i2a-rule-human-expression>
                      </li>
                    </ul>

                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container footer>
    <div class="col-centered">
      <button (click)="close(true)" type="button"
        class="btn btn-red ml-2">{{'button.openAlertModal' | i18next }}</button>

      <button (click)="close(false)" ngbAutofocus type="submit"
        class="btn btn-dark ml-2">{{'button.ok' | i18next }}</button>
    </div>
  </ng-container>
</i2a-base-modal>
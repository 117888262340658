<i2a-profile-alert-base-modal
  [manualDescriptionKey]="'applyCorrection.providers.microsoft.fileshare.delegate.default.description'"
  [manualLinkKey]="'applyCorrection.providers.microsoft.fileshare.delegate.default.manual.link'"
  [automaticDescriptionKey]="'applyCorrection.providers.microsoft.fileshare.delegate.default.automatic.description'"
  [ignoreCommentToSend]="session.IgnoreCommentToSend" [validation]="validation" [canSend]="canSend"
  [modalTitle]="'profile.confirmationOfInformationTitle' | i18next " [resourceProfile]="resourceProfile">

  <ng-container header-picto>
    <i2a-delegate-photo [isAnonymous]="delegate.isAnonymousUser" [isDefault]="delegate.logonUserId == 'Default'"
      [isApplication]="delegate.isApplication" [isExternalUser]="false" [pir]="delegate.providerInstanceResource"
      [piu]="delegate.providerInstanceUser"></i2a-delegate-photo>
  </ng-container>

  <ng-container header-title>
    <span [title]="name">
      {{name}}<br>
      <ng-container *ngIf="!delegate.isAnonymousUser">
        {{delegate.lastAccess | amDateFormat: 'LLL'}}
      </ng-container>
    </span>
  </ng-container>

  <ng-container body>
    <div class="container-fluid">
      <i2a-share-point-access-permission-form [(validation)]="validation" [folders]="folders"
        [driveItemsPermissions]="driveItemsPermissions" (setCanSend)="setCanSend"
        [allAccessAndPermissionsTitle]="'profile.provider.microsoft.fileShare.allAccesses' | i18next">
      </i2a-share-point-access-permission-form>
    </div>
  </ng-container>

</i2a-profile-alert-base-modal>
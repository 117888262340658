import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AzureADUserSummaryModel, ReportProfileResourceBase, SharePointGraphGroupModel, SharePointReportProfileAlertO365GroupModel } from '@i2a/web-api-client';
import { StringHelper } from 'projects/web-user-profile/src/app/helpers/string-helper';
import { SharePointO365GroupDelegateWithAlert } from 'projects/web-user-profile/src/app/models/providers/microsoft/share-point/share-point-o365-group-delegate-with-alert';
import { MemberFilterService } from 'projects/web-user-profile/src/app/services/member-filter.service';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-share-point-o365-group-modal',
  templateUrl: './share-point-o365-group-modal.component.html',
  styleUrls: ['./share-point-o365-group-modal.component.scss'], 
  providers: [MemberFilterService]
})
export class SharePointO365GroupModalComponent implements OnInit, OnChanges {
  @Input() public group: SharePointGraphGroupModel;
  @Input() public deduplicateOwnerMembers: boolean;
  @Input() public disableCheckAllOwnersWithOwnersFiltered: boolean;
  @Input() public resourceProfile: ReportProfileResourceBase<any>;
  @Input() public ownersMinusProfileUser: SharePointO365GroupDelegateWithAlert[] | null;
  @Input() public membersMinusProfileUser: SharePointO365GroupDelegateWithAlert[] | null;
  @Input() public doNotDeleteCurrentUserFromSiteOwnerGroup: boolean;
  @Input() public headerTitle: string; 

  public validation: SharePointReportProfileAlertO365GroupModel;
  public canSend: boolean;

  public searchText: string = "";

  public members: any[];
  public owners: any[];
  public guests: any[];

  public informationKey: string;
  public allOwners: boolean;
  public allMembers: boolean;
  public description: string;
  public title = 'profile.confirmationOfInformationTitle';
  public hasPermissionsToRemove: boolean;
  public displayOwners: boolean;
  public hasToShowMessage: boolean;

  constructor(
      public session: SessionService
    , public membersFilterService: MemberFilterService
    , public ownersFilterService: MemberFilterService
    , public guestsFilterService: MemberFilterService
  ) {
    this.canSend = false;

    this.membersFilterService = new MemberFilterService();
    this.ownersFilterService = new MemberFilterService();
    this.guestsFilterService = new MemberFilterService();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.group || changes.ownersMinusProfileUser || changes.allOwners) {
      if (this.doNotDeleteCurrentUserFromSiteOwnerGroup) {
        this.hasToShowMessage = !(this.group.ownersFiltered || this.ownersMinusProfileUser) && this.allOwners;
      } else {
        this.hasToShowMessage = !this.group.ownersFiltered && this.allOwners;
      }
    }
  }

  private setCanSend() {
    setTimeout(() => {
      this.canSend = this.hasOneOrManyOwnersMembersOrGuestsSelected() &&
        (
          (!this.disableCheckAllOwnersWithOwnersFiltered && ((!this.group.ownersFiltered && !this.allOwners) || (this.group.ownersFiltered)))
          ||
          (this.doNotDeleteCurrentUserFromSiteOwnerGroup && !this.disableCheckAllOwnersWithOwnersFiltered && ((!(this.ownersMinusProfileUser.length > 0) && !this.allOwners) || (this.ownersMinusProfileUser.length > 0)))
          ||
          (this.disableCheckAllOwnersWithOwnersFiltered && !this.allOwners)
        );
    });
  }

  private hasOneOrManyOwnersMembersOrGuestsSelected(): boolean {
    return (this.validation.invalidMemberList.length > 0 ||
      this.validation.invalidOwnerList.length > 0 ||
      this.validation.invalidGuestList.length > 0 ||
      (!this.disableCheckAllOwnersWithOwnersFiltered && (this.group.ownersFiltered && this.allOwners)) ||
      this.validation.allGuests ||
      this.allMembers
    );
  }

  ngOnInit() {
    this.validation = new SharePointReportProfileAlertO365GroupModel();
    this.validation.invalidMemberList = [];
    this.validation.invalidOwnerList = [];
    this.allOwners = false;
    this.allMembers = false;
    this.validation.invalidGuestList = [];
    this.validation.allGuests = false;
    this.validation.group = this.group;
    this.members = [];
    this.owners = [];
    this.guests = [];

    if (this.group) {
      this.setMembers();
      this.setOwners();
      this.guests = SharePointO365GroupModalComponent.GetOwnersOrGuests(this.group.guests);
      if (this.group.displayName != this.group.description) {
        this.description = this.group.description;
      }
      this.hasPermissionsToRemove = this.members.length > 0 || this.owners.length > 1 || this.guests.length > 0;
      if (this.hasPermissionsToRemove) {
        this.informationKey = this.members.length + this.guests.length + this.owners.length > 1 ? 'modal.reportAnomaly.o365.checkMembers' : 'modal.reportAnomaly.o365.checkMember';
        this.title = 'profile.provider.microsoft.sharePoint.confirmationOfInformationTitle.members';
      }

      if (this.disableCheckAllOwnersWithOwnersFiltered) {
        this.displayOwners = this.owners && this.owners.length > 1;
      } else {
        this.displayOwners = this.owners && ((!this.group.ownersFiltered && this.owners.length > 1) || (this.group.ownersFiltered && this.owners.length >= 1) || (this.ownersMinusProfileUser?.length > 0 && this.owners.length >= 1));
      }

      this.setCanSend();
    }
  }

  //#region Filter
  
  onKeyUpSearchText(): void {    
    if (!StringHelper.isNullOrWhitespace(this.searchText)) {
     
      this.membersFilterService.setSearchTerm(this.searchText);
      this.ownersFilterService.setSearchTerm(this.searchText);
      this.guestsFilterService.setSearchTerm(this.searchText);
      
    } else {
      this.clearFilter();
    }
  }
  
  isSearchTermNotEmpty() : boolean {
    return this.membersFilterService.getSearchTerm().length > 0 
        || this.ownersFilterService.getSearchTerm().length > 0 
        || this.guestsFilterService.getSearchTerm().length > 0;
  }
  
  clearFilter(): void {
    this.searchText = "";

    this.membersFilterService.clearFilter();
    this.ownersFilterService.clearFilter();
    this.guestsFilterService.clearFilter();
  }

  //#endregion

  //#region Initialize members owners and guest

  private setOwners(): void {
    if (this.ownersMinusProfileUser?.length > 0) {
      this.owners = SharePointO365GroupModalComponent.GetOwnersOrGuests(this.ownersMinusProfileUser);
    } else {
      this.owners = SharePointO365GroupModalComponent.GetOwnersOrGuests(this.group.owners);
    }
  }

  public static GetOwnersOrGuests(members: AzureADUserSummaryModel[]): any[] {
    let result: any[] = [];
    members.forEach(member => {
      result.push({
        "id": member.id,
        "name": member.displayName,
        "checked": false
      });
    });
    return result;
  }

  private setMembers(): void {
    if (this.membersMinusProfileUser?.length > 0) {
      this.members = SharePointO365GroupModalComponent.SetMembers(this.membersMinusProfileUser, this.group.owners, this.deduplicateOwnerMembers);
    } else {
      this.members = SharePointO365GroupModalComponent.SetMembers(this.group.members, this.group.owners, this.deduplicateOwnerMembers);
    }
  }

  public static SetMembers(members: AzureADUserSummaryModel[], owners: AzureADUserSummaryModel[], deduplicateOwnerMembers: boolean): any[] {
    let result: any[] = [];
    members.filter(m => deduplicateOwnerMembers && !owners.some(o => o.id == m.id) || !deduplicateOwnerMembers)
      .forEach(member => {
        result.push({
          "id": member.id,
          "name": member.displayName,
          "checked": false
        });
      });
    return result;
  }

  //#endregion

  //#region owners

  checkedOwner(isChecked: boolean, owner: any) {
    this.checkOwnerItem(owner, isChecked);
    if (this.group.ownersFiltered) {
      if (this.owners.some(o => !o.checked)) {
        this.allOwners = false;
      } else {
        this.allOwners = true;
      }
    } else {
      this.allOwners = this.owners.length == this.validation.invalidOwnerList.length;
    }

    this.setCanSend();
  }

  private checkOwnerItem(owner: any, isChecked: boolean): void {
    const index: number = this.validation.invalidOwnerList.indexOf(owner.id);

    if (isChecked && index === -1) {
      this.validation.invalidOwnerList.push(owner.id);
      
      if (this.deduplicateOwnerMembers && this.group.members.findIndex(m => m.id == owner.id) >= 0) {
        this.validation.invalidMemberList.push(owner.id);
      }
    }  else if (!isChecked && index !== -1) {
      this.validation.invalidOwnerList.splice(index, 1);
     
      const indexMember: number = this.validation.invalidMemberList.indexOf(owner.id);
      if (this.deduplicateOwnerMembers && indexMember != -1) {        
        this.validation.invalidMemberList.splice(indexMember, 1);
      }
    }
  }

  allOwnersChecked(isChecked: boolean) {
    this.owners.forEach((owner) => {
      owner.checked = isChecked;
      this.checkOwnerItem(owner, isChecked);
    });
    this.setCanSend();
  }

  //#endregion

  //#region members

  checkedMember(isChecked: boolean, member: any) {
    this.checkMemberItem(member, isChecked);
    if (this.members.some(m => !m.checked)) {
      this.allMembers = false;
    } else {
      this.allMembers = true;
    }

    this.setCanSend();
  }

  private checkMemberItem(member: any, isChecked: boolean) {
    const index: number = this.validation.invalidMemberList.indexOf(member.id);
    if (isChecked && index === -1) {
      this.validation.invalidMemberList.push(member.id);
    }
    else if (!isChecked && index !== -1) {
      this.validation.invalidMemberList.splice(index, 1);
    }
  }

  allMembersChecked(isChecked: boolean) {
    this.members.forEach((member) => {
      member.checked = isChecked;
      this.checkMemberItem(member, isChecked);
    });

    this.setCanSend();
  }

  //#endregion

  //#region guests

  checkedGuest(isChecked: boolean, guest: any) {
    this.checkGuestMember(guest, isChecked);
    this.validation.allGuests = this.guests.length == this.validation.invalidGuestList.length;

    this.setCanSend();
  }

  private checkGuestMember(guest: any, isChecked: boolean) {
    const index: number = this.validation.invalidGuestList.indexOf(guest.id);
    if (isChecked && index === -1) {
      this.validation.invalidGuestList.push(guest.id);
    }
    else if (!isChecked && index !== -1) {
      this.validation.invalidGuestList.splice(index, 1);
    }
  }

  allGuestsChecked(isChecked: boolean) {
    this.guests.forEach(f => {
      f.checked = isChecked;
      this.checkGuestMember(f, isChecked);
    });

    this.setCanSend();
  }

  //#endregion
}

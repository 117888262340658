<!-- <i2a-no-collected-resources *ngIf="start && noCollectedResources"></i2a-no-collected-resources> -->

<div *ngIf="!start || noCollectedResources" id="collectedResourcesLoading" #collectedResourcesLoading class="collected-resources-loading">

  <i2a-error-pending-collect *ngIf="errorPendingCollect" [errorPendingCollectObject]="errorPendingCollectObject"></i2a-error-pending-collect>
    
  <div class="container get-started">

    <div class="headings headings-get-started">
      <h1>{{ 'profile.getStarted.welcome.title' | i18next}}</h1>
      <div class="subtitle">
        {{ 'profile.getStarted.welcome.description1' | i18next}}<br>
        {{ 'profile.getStarted.welcome.description2' | i18next}}
      </div>
    </div>

    <i2a-no-collected-resources *ngIf="start && noCollectedResources"></i2a-no-collected-resources>
    
    <!-- loading components -->

    <div *ngIf="!start || !noCollectedResources" class="user-container">
      <div class="user-container">

        <div style="text-align: center;">
          <div style="margin:0.5rem" *ngIf="loading">
            {{('profile.computingTheProfile' | i18next : { nbOfResources: collectedResourcesCount})}}
            <span *ngIf="loadingRes">
              - {{loadingRes.name}}
            </span>
          </div>
          <div style="margin:0.5rem" *ngIf="!loading && !errorPendingCollect">
            {{ 'profile.getStarted.welcome.redirection' | i18next}}
          </div>
          <ngb-progressbar type="primaryDarker" [value]="progressValue" height="0.5rem">
          </ngb-progressbar>
        </div>

      </div>
    </div>
      
    <div *ngIf="!start || !noCollectedResources" class="d-flex justify-content-center flex-wrap">

      <div *ngFor="let item of collectedResourcesLoaded | keyvalue" class="m-1">
        <i2a-badge-icon>
          <ng-container icon>
            <img class="id-provider-picture" [src]="providerPhotoUrl(item.value)" width="24" height="24">
          </ng-container>
        </i2a-badge-icon>
      </div>
      
      <div class="m-1" *ngIf="loading">
        <i2a-badge-icon [customClass]="'id-bagde-loading'">
          <ng-container icon>
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true" size="lg"></fa-icon>
          </ng-container>
        </i2a-badge-icon>
      </div>

      <div class="m-1" *ngIf="errorPendingCollect">
        <i2a-badge-icon [customClass]="'id-bagde-error'">
          <ng-container icon>
            <fa-icon [icon]="['fas', 'times-circle']" size="lg"></fa-icon>
          </ng-container>
        </i2a-badge-icon>
      </div>

    </div>
    
    <div class="row mb-5 mt-5" *ngIf="!errorPendingCollect && (!noCollectedResources || !start)">
      <div class="col-sm-1 col-md-4 "></div>
      <div class="col-sm-1 col-md-4 ">
        <i2a-spinner *ngIf="start" ></i2a-spinner>
        <button (click)="displayProfile()"
          [ngClass]="{'btn':true, 'btn-success':true, 'btn-get-started':true, 'w-100':true, 'btn-disabled':loading || noCollectedResources, 'd-none': start}"
          [disabled]="loading">{{ 'profile.getStarted.getStarted' | i18next}}</button>
    
      </div>
      <div class="col-sm-1 col-md-4 "></div>
    </div>
    
    <!-- ILLUSTRATIONS -->
    <div class="get-started-container" *ngIf="configuration && !configuration.hideGetStartedIllustrations">
      
      <div class="row">
        <div class="col-4">
          <div class="card card-get-started">
            <img src="/assets/pictos/illustration-get-started-1.svg">
            <h2>{{ 'profile.getStarted.checkYourActivity.title' | i18next}}</h2>
            <div class="text-default-light">{{ 'profile.getStarted.checkYourActivity.description' | i18next}}</div>
          </div>
        </div>

        <div class="col-4">
          <div class="card card-get-started">
            <img src="/assets/pictos/illustration-get-started-2.svg">
            <h2>{{ 'profile.getStarted.seeWhoAccessed.title' | i18next}}</h2>
            <div class="text-default-light">{{ 'profile.getStarted.seeWhoAccessed.description' | i18next}}</div>
          </div>
        </div>

        <div class="col-4">
          <div class="card card-get-started">
            <img src="/assets/pictos/illustration-get-started-3.svg">
            <h2>{{ 'profile.getStarted.reportAnomaly.title' | i18next}}</h2>
            <div class="text-default-light">{{ 'profile.getStarted.reportAnomaly.description' | i18next}}</div>
          </div>
        </div>
      </div>

    </div>

    <div class="get-started-container-mobile-container">
      <ngb-carousel #carousel [interval]="3000" [pauseOnHover]="true" [showNavigationArrows]="false" [showNavigationIndicators]="false">

        <ng-template ngbSlide>
          <div id="getStartedCarousel" class="get-started-container-mobile">
            <div class="carousel-item-mobile">
              <div class="row no-gutters">
                <div class="col-2 align-top m-auto">
                  <button class="btn" (click)="prev()">
                    <fa-icon [icon]="['fal', 'chevron-left']" size="2x"></fa-icon>
                  </button>
                </div>
                <div class="col-8">

                  <img src="/assets/pictos/illustration-get-started-1.svg" class="slide-img">
                  <div class="carousel-caption d-none d-md-block">
                    <h3>{{ 'profile.getStarted.checkYourActivity.title' | i18next}}</h3>
                    <p class="text-default-light">{{ 'profile.getStarted.checkYourActivity.description' | i18next}}</p>
                  </div>

                </div>
                <div class="col-2 align-top m-auto">
                  <button ng="btn" (click)="next()">
                    <fa-icon [icon]="['fal', 'chevron-right']" size="2x"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template ngbSlide>
          <div id="getStartedCarousel" class="get-started-container-mobile">
            <div class="carousel-item-mobile">
              <div class="row no-gutters">
                <div class="col-2 align-top m-auto">
                  <button class="btn" (click)="prev()">
                    <fa-icon [icon]="['fal', 'chevron-left']" size="2x"></fa-icon>
                  </button>
                </div>
                <div class="col-8">

                  <img src=" /assets/pictos/illustration-get-started-2.svg" class="slide-img">
                  <div class="carousel-caption d-none d-md-block">
                    <h3>{{ 'profile.getStarted.seeWhoAccessed.title' | i18next}}</h3>
                    <p class="text-default-light">{{ 'profile.getStarted.seeWhoAccessed.description' | i18next}}</p>
                  </div>

                </div>
                <div class="col-2 align-top m-auto">
                  <button class="btn" (click)="next()">
                    <fa-icon [icon]="['fal', 'chevron-right']" size="2x"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template ngbSlide>
          <div id="getStartedCarousel" class="get-started-container-mobile">
            <div class="carousel-item-mobile">
              <div class="row no-gutters">
                <div class="col-2 align-top m-auto">
                  <button class="btn" (click)="prev()">
                    <fa-icon [icon]="['fal', 'chevron-left']" size="2x"></fa-icon>
                  </button>
                </div>
                <div class="col-8">
                  <img src=" /assets/pictos/illustration-get-started-3.svg" class="slide-img">
                  <div class="carousel-caption d-none d-md-block">
                    <h3>{{ 'profile.getStarted.reportAnomaly.title' | i18next}}</h3>
                    <p class="text-default-light">{{ 'profile.getStarted.reportAnomaly.description' | i18next}}</p>
                  </div>

                </div>
                <div class="col-2 align-top m-auto">
                  <button class="btn" (click)="next()">
                    <fa-icon [icon]="['fal', 'chevron-right']" size="2x"></fa-icon>
                  </button>
                </div>
              </div>

            </div>
          </div>
        </ng-template>

      </ngb-carousel>
    </div>

  </div>
</div>

import { Component, OnInit, Inject } from '@angular/core';
import { SessionService } from '../../services/session-service';
import { ContextService } from '@i2a/web-core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'i2a-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public headerStyle: any;

  constructor(
    public context: ContextService,
    private sanitizer: DomSanitizer,
    @Inject(I18NEXT_SERVICE) private translateI18Next: ITranslationService
  ) { }

  ngOnInit() {
    if (this.translateI18Next.exists('style.header', {})) {
      this.headerStyle = this.sanitizer.bypassSecurityTrustStyle(this.translateI18Next.t('style.header'));
    }
    else { 
      this.headerStyle = null;
    }
  }

}

<!-- RULES -->
<div class="module">
  <div class="container-fluid module-container"
    *ngIf="resourceProfile.rules.hasTransportRules || resourceProfile.rules.hasJournalRules || inboxRules.length > 0 || resourceProfile.rules.forwardMailBoxes.length > 0 ">
    <div class="row no-gutters">
      <i2a-sub-title class="col-12" [subTitle]="'profile.provider.microsoft.exchange.title.rules' | i18next "
        [description]="'profile.provider.microsoft.exchange.description.rules' | i18next ">
      </i2a-sub-title>
    </div>
    <div class="row no-gutters">
      <div class="col-sm-12 col-md-6 col-lg-4 align-items-start"
        *ngIf="resourceProfile.rules.hasTransportRules || resourceProfile.rules.hasJournalRules || resourceProfile.rules.forwardMailBoxes.length > 0 ">
        <i2a-exchange-rule-config [resourceProfile]="resourceProfile" [componentId]="componentId" [isAlert]="isAlert" [alerts]="alerts" [profile]="profile"
          [user]="user" [providerInstanceResource]="resourceProfile.providerInstanceResource"
          [rules]="resourceProfile.rules" (onAlertSent)="alertSent($event)" (onHasWarnings)="hasWarnings($event)">
        </i2a-exchange-rule-config>
      </div>
      <div *ngFor="let inboxRule of inboxRules | slice:0:maxShowMore"
        class="col-sm-12 col-md-6 col-lg-4 align-items-start">
        <i2a-exchange-inbox-rule [resourceProfile]="resourceProfile" [componentId]="componentId" [isAlert]="isAlert" [alerts]="alerts"
          [providerInstanceResource]="resourceProfile.providerInstanceResource" [profile]="profile" [user]="user"
          [inboxRuleWithWarnings]="inboxRule" (onAlertSent)="alertSent($event)" (onHasWarnings)="hasWarnings($event)">
        </i2a-exchange-inbox-rule>
      </div>

      <div *ngIf="isVisibleButtonsShowMore" class="col-12 p-2 text-center">
        <button class="btn btn-outline-primary" (click)="onShowMore()" *ngIf="!showMore">{{ 'button.showMore' | i18next }}</button>
        <button class="btn btn-outline-primary" (click)="onShowMore()" *ngIf="showMore">{{ 'button.showLess' | i18next }}</button>
      </div>
    </div>
  </div>
</div>

import { BaseOperationModel } from '../base-operation-model';
import { BaseProviderInstanceResourceModel } from '../base-provider-instance-resource-model';

export class MicrosoftOfficeImportUsersModel extends BaseOperationModel {
  orgUnitId: string;
  defaultCulture: string;
  defaultTimeZone: string;
  forceOrgUnitIdOnExistingUsers: boolean;
  sendPasswordEmailToUsers: boolean;
  providerInstanceResourceToRebuild: BaseProviderInstanceResourceModel;
}

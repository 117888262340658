import { CollectedResourceBaseCountersModel } from './collected-resource-base-counters-model';
import { GlobalResourcesStateModel } from './global-resources-state-model';

export class ProviderInstanceGlobalResourceStatesModel {
  providerId: string;
  providerTypeId: string;
  providerInstanceId: string;
  global: GlobalResourcesStateModel;
  audit: CollectedResourceBaseCountersModel;
  permanentAudit: CollectedResourceBaseCountersModel;
  realTimeProtect: CollectedResourceBaseCountersModel;
  endOfAuditCount: number;
  endOfLearningCount: number;
}

<span class="share-point-right" *ngIf="hasRead" [title]="'profile.sharePointRight.read' | i18next ">
    <fa-icon [icon]="['fas', 'eye' ]"></fa-icon>
</span>
<span class="share-point-right" *ngIf="hasUpdate" [title]="'profile.sharePointRight.update' | i18next ">
    <fa-icon [icon]="['fal', 'pen']"></fa-icon>
</span>
<span class="share-point-right" *ngIf="hasOwner" [title]="'profile.sharePointRight.owner' | i18next ">
    <fa-layers>
        <fa-icon [icon]="['fal', 'user']"></fa-icon>
    </fa-layers>
</span>
<span class="share-point-right" *ngIf="hasUnknown" [title]="'profile.sharePointRight.unknown' | i18next ">
    <fa-layers>
        <fa-icon [icon]="['fas', 'question']"></fa-icon>
    </fa-layers>
</span>
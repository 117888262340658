import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'i2a-collapse-container',
  templateUrl: './collapse-container.component.html',
  styleUrls: ['./collapse-container.component.scss']
})
export class CollapseContainerComponent implements OnInit {
  public isCollapsed: boolean = true;
  @Input() public collapseText: string;
  @Input() public expandText: string;

  constructor() { 
    this.collapseText = null;
    this.expandText = null;
  }

  ngOnInit() {
  }

}


export enum DriveItemSharingType {
  Unknown = 0,
  Internal = 2,
  InternalGuest = 4,
  External = 8,
  EveryOneInCompany = 16,
  EveryOne = 32,
  EveryOneWithPassword = 64
}

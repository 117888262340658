import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { SessionService } from '../../services/session-service';
import { Subscription } from 'rxjs';
import { UsersDatasourceService, ProviderInstanceModel, UserKind, Constants } from '@i2a/web-api-client';
import { ActivatedRoute, Router } from '@angular/router';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'i2a-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  public providerInstances: ProviderInstanceModel[];
  public userId: string;
  public displayInstances: boolean;
  public headerStyle: any;

  constructor(
    private router: Router,
    private session: SessionService,
    private route: ActivatedRoute,
    private usersDatasource: UsersDatasourceService,
    private sanitizer: DomSanitizer,
    @Inject(I18NEXT_SERVICE) private translateI18Next: ITranslationService
  ) {
    this.displayInstances = false;
  }

  ngOnInit() {
    if (this.translateI18Next.exists('style.header', {})) {
      this.headerStyle = this.sanitizer.bypassSecurityTrustStyle(this.translateI18Next.t('style.header'));
    }
    else { 
      this.headerStyle = null;
    }

    this.subscriptions.add(this.route.params.subscribe(params => {
      this.userId = params["userId"];
      this.subscriptions.add(this.usersDatasource.loadProviderInstances(this.userId)
        .subscribe(providerInstances => {
          this.providerInstances = providerInstances;
          
          if (providerInstances.length == 1) {
            this.router.navigate([this.providerInstances[0].providerId, this.providerInstances[0].providerInstanceId], { queryParamsHandling: 'preserve' ,relativeTo: this.route, replaceUrl: true });
          }
          else {
            let defaultInstances = providerInstances.filter(p=> p.annotations.findIndex(a => a.key == Constants.ANNOTATION_DEFAULT_PROVIDERINSTANCE) >= 0);
            if (defaultInstances.length > 0){
              this.router.navigate([defaultInstances[0].providerId, defaultInstances[0].providerInstanceId], { queryParamsHandling: 'preserve' ,relativeTo: this.route, replaceUrl: true });
            }
            else
            {
              this.displayInstances = true;
            }
          }
        }));
    }));
  }

  hasAdminRole(): boolean {
    if (this.session.SecurityInfos.userKind != null) {
      return (this.session.SecurityInfos.userKind & UserKind.Admin) == UserKind.Admin;
    }
    else {
      return this.session.SecurityInfos.isSupport;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}

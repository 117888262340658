<i2a-page-content [hasPadding]="false"
[headerStyle]="headerStyle"
    *ngIf="displayInstances">
    <ng-container navbar>
        <i2a-nav-bar></i2a-nav-bar>
    </ng-container>
    <ng-container content-body>
        <i2a-spinner *ngIf="!providerInstances"></i2a-spinner>
        <i2a-sub-title class="col-12" *ngIf="providerInstances" [title]="'Providers'"></i2a-sub-title>
        <div *ngIf="providerInstances">
            <ul>
                <li *ngFor="let providerInstance of providerInstances">
                    <a [routerLink]="['..', providerInstance.providerId, providerInstance.providerInstanceId]">
                        {{providerInstance.name}}
                    </a>
                    <a *ngIf="hasAdminRole()"  [routerLink]="['..', providerInstance.providerId, providerInstance.providerInstanceId]"
                        [queryParams]="{userView:true}">
                        <fa-icon [icon]="['fas', 'eye']" [title]="'profile.viewAsUserTitle' | i18next"></fa-icon>
                    </a>

                </li>
            </ul>
        </div>
        <router-outlet></router-outlet>
    </ng-container>
</i2a-page-content>
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../authentication/authentication.service';
import { LoadingHelper } from '../../helpers/loading-helper';

@Component({
  selector: 'i2a-auth-callback',
  templateUrl: '../auth-loading.component.html'
})
export class AuthCallbackComponent implements OnInit {

  constructor(
    private authService: AuthenticationService
  ) { }

  public loadingText: string;

  async ngOnInit() {
    try {
      this.loadingText = LoadingHelper.getLoadingText();
    } catch (error) {
      this.loadingText = "Loading in progress, please wait...";
    }
    await this.authService.completeAuthentication();
  }


}

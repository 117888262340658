<!-- PERMISSIONS -->
<i2a-sub-title *ngIf="delegatesWithoutAccess != null && delegatesWithoutAccess.length > 0"
  [subTitle]="'profile.provider.microsoft.fileShare.title.permissions' | i18next "></i2a-sub-title>
<div class="container-fluid p-0">
  <div class="row no-gutters">

  <ng-container *ngIf="delegates && delegates.length > 0">
    <div *ngFor="let permissions of delegates" class="col-sm-12 col-md-6 col-lg-4 align-items-start">
      <i2a-file-share-permission [resourceProfile]="resourceProfile" [componentId]="componentId" [isAlert]="isAlert" [alerts]="alerts" [providerInstanceResource]="resourceProfile.providerInstanceResource"
      [profile]="profile" [user]="user" [permissions]="permissions"
        (onAlertSent)="alertSent($event)" (onHasWarnings)="hasWarnings($event)">
      </i2a-file-share-permission>
    </div>
  </ng-container>

</div>

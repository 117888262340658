import { Component, Inject, OnInit } from '@angular/core';
import { BaseProviderInstanceResourceModel, ReportProfileBaseModel, MicrosoftOfficeReportProfileModel, UserModel, AlertInvalidStateFeaturedModel, SharePointReportProfile } from '@i2a/web-api-client';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ToastrService } from 'ngx-toastr';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';
import { SharePointResourceActionsConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/share-point-resource-actions-configuration';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';

@RegisterMyProfileComponent(TemplateConstants.MODULE_SHAREPOINT_ACTIONS)
@Component({
  selector: 'i2a-share-point-actions-module',
  templateUrl: './share-point-actions-module.component.html',
  styleUrls: ['./share-point-actions-module.component.scss']
})
export class SharePointActionsModuleComponent extends ProfileBaseClassComponent<SharePointResourceActionsConfiguration,SharePointReportProfile> implements OnInit, IModule<SharePointResourceActionsConfiguration> {
  public resource: BaseProviderInstanceResourceModel;
  public resourceProfile: SharePointReportProfile;

  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, null);
  }

  ngOnInit() {
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: SharePointResourceActionsConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel, resourceTemplate?: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.resource = resource;
    this.resourceProfile = (<MicrosoftOfficeReportProfileModel>profile).sharePointProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
  }
     
  alertSent(event): void {
    this.onAlertSent.emit(event);
  }

}

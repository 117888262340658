import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertConstants, AlertInvalidStateFeaturedModel, BaseProviderInstanceResourceModel, MicrosoftOfficeProfileProviderDatasourceService, MicrosoftOfficeReportProfileModel, ProviderInstanceResourceSummaryModel, SharePointGraphGroupModel, SharePointReportProfile, SharePointReportProfileAlertVisibilityModel, SharePointSite, SharePointSiteReportProfile } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ToastrService } from 'ngx-toastr';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { ProviderHelper } from 'projects/web-user-profile/src/app/helpers/provider-helper';
import { WarningHelper } from 'projects/web-user-profile/src/app/helpers/warning-helper';
import { SharePointO365GroupConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/sharepoint-o365-group-configuration';
import { WarningInfoType } from 'projects/web-user-profile/src/app/models/warnings/warning-info-type';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { SharePointVisibilityModalComponent } from '../share-point-visibility-modal/share-point-visibility-modal.component';

@Component({
  selector: 'i2a-share-point-visibility',
  templateUrl: './share-point-visibility.component.html',
  styleUrls: ['./share-point-visibility.component.scss']
})
export class SharePointVisibilityComponent extends ProfileItemBaseClassComponent<SharePointReportProfile> implements OnInit, OnDestroy {
  @Input() public group: SharePointGraphGroupModel;
  @Input() public configuration: SharePointO365GroupConfiguration;
  @Input() public relatedFarmSummaryItemId: string;
  @Input() public isGroupBySite: boolean;
  @Input() public resources: [BaseProviderInstanceResourceModel];

  public hasSubItems: boolean;
  public hasSubSites: boolean;

  public subItems: SharePointSite[];
  public isVisible: boolean;

  public headerTitle: string;
  public site: SharePointSiteReportProfile;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    modalService: NgbModal,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.isGroupBySite) {
      this.site = (<MicrosoftOfficeReportProfileModel>this.profile).sharePointSites.find(s => s.id.toLocaleLowerCase() === this.resourceProfile.siteId.toLocaleLowerCase());
      this.headerTitle = this.site?.title;
    }

    this.setHasWarning();
  }

  private setHasWarning(): void {
    if (this.group && this.group.visibility == 'Public') {
      let warningInfoType = WarningInfoType.Company;
      this.warningLevel = WarningHelper.GetWarningLevel(warningInfoType);
      this.hasWarnings([{
        id: this.id,
        resourceId: 'application' + this.resourceProfile.providerInstanceResource.providerTypeId.toLocaleLowerCase() + this.resourceProfile.providerInstanceResource.providerInstanceResourceId.toLocaleLowerCase(),
        resourceProfile: this.resourceProfile,
        warningInfoType: warningInfoType,
        warningLevel: this.warningLevel,
        label: `${this.i18Next.t(ProviderHelper.GetSharePointProviderTypeLabel(this.resourceProfile, this.site != null || this.site != undefined))} > ${(<ProviderInstanceResourceSummaryModel>this.providerInstanceResource).name}`,
        hasAlert: this.hasAlert,
        hasAlertRecentlyClosed: this.hasAlertRecentlyClosed,
        warningInfo: 'warning.info.type.provider.microsoft.sharePoint.visibility',
        viewMoreDetails: false,
        viewDissmiss: true,
        viewResource: false
      }]);

      this.subscriptions.add(WarningHelper.openModalOnRoute(this.router, this.route, this.id, null, this.warnClick.bind(this)));
    }
  }

  setItems() {
    if (this.group && this.alerts) {
      super.setItems();
      this.isVisible = this.group.visibility == 'Public';

      this.hasAlert = this.hasAlertInAlerts(false);
      this.hasAlertRecentlyClosed = this.hasAlertInAlerts(true);

      this.id = `${this.componentId}-${this.providerInstanceResource.providerTypeId}-${this.providerInstanceResource.providerInstanceResourceId}-SharePointVisibilityComponent`;
    }
  }

  private hasAlertInAlerts(done: boolean): boolean {
    var visibilityAlerts = AlertReasonHelper.GetalertsContainsReason(visibilityAlerts, AlertConstants.ALERT_INVALID_STATE_REASON_SHAREPOINT_VISIBILITY, done);
    return visibilityAlerts.some(a => a.reasons.some(r => AlertReasonHelper.containsValue(r.reasonContext, AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_O365_GROUP_ID, this.group.id)));
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(SharePointVisibilityModalComponent, { windowClass: 'id-modal', centered: true, size: 'lg' });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.providerInstanceResource = this.providerInstanceResource;
    modalRef.componentInstance.isVisible = this.isVisible;
    modalRef.componentInstance.configuration = this.configuration;
    modalRef.componentInstance.relatedFarmSummaryItemId = this.relatedFarmSummaryItemId;
    modalRef.componentInstance.group = this.group;
    modalRef.componentInstance.headerTitle = this.headerTitle;
    modalRef.result.then((model: SharePointReportProfileAlertVisibilityModel) => {
      if (model) {
        this.initAlertModel(model);
        model.group = this.group;
        if (this.isGroupBySite) {
          model.providerInstanceResources = this.resources;
        } else {
          model.providerInstanceResources = [this.providerInstanceResource];
        }
        this.subscriptions.add(this.microsoftDatasource.alertSharePointVisibility(this.profile.providerInstanceId, this.profile.userId, model).subscribe(model => {
          this.setAlertProcessed(model);
        }));
      }
    }).catch((error) => {
      // console.log(error);
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

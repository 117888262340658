import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatasourceService } from './datasource.service';

@Injectable({
  providedIn: 'root'
})
export class LocalizationDatasourceService {

  private route: string = "localizations";

  constructor(private datasource: DatasourceService) {
    console.log("Init LocalizationDatasourceService");
  }

  public getLanguages(): Observable<string[]> {
    let partialUrl = `${this.route}/languages`;

    return this.datasource.get<string[]>(partialUrl, null);
  }

  public clearCache(): Observable<any> {
    let partialUrl = `${this.route}/clear-cache`;

    return this.datasource.put(partialUrl, null, null);
  }

}

import { Injectable } from '@angular/core';
import { DatasourceService } from './datasource.service';

@Injectable({
	providedIn: 'root'
})
export class UsersDatasourceUrlService {

private route: string = "users";

	constructor(private datasource: DatasourceService) {
		console.log("Init UsersDatasourceUrlService");
	}

	public PhotoUrl(userId: string, defaultImageifNotFound: boolean): string {
		let partialUrl = `${this.route}/${userId}/photo?defaultImageifNotFound=${defaultImageifNotFound}`;
	
		return this.datasource.getUrl(partialUrl);
	}
}

import { Injectable, Inject, OnDestroy } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { UserProfileConstants } from '../models/user-profile-constants';
import { Subscription } from 'rxjs';
import { GuidedToursDatasourceService, GuidedTourStopModel, Constants } from '@i2a/web-api-client';
import { StringHelper } from '../helpers/string-helper';

declare var introJs: any;

@Injectable({
  providedIn: 'root'
})
export class GuidedTourService implements OnDestroy {
  private subscriptions = new Subscription();
  private introJS;
  private userProfileConstants: UserProfileConstants;
  private currentTour: string;
  private currentTourVersion: number;
  private currentStep: number;
  private steps: any[];
  private started: boolean;
  private stopped: boolean;

  readonly guidedTourKey: string = "data-guidedTourKey";

  constructor(
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService,
    private guidedTourDataSource: GuidedToursDatasourceService
  ) {
    console.log("Init GuidedTour service");
    this.introJS = introJs();
    let self = this;
    this.introJS.onchange(function () {
      self.changeStep();
    });

    this.introJS.onexit(function () {
      self.ExitTour();
    });
    this.userProfileConstants = new UserProfileConstants();
  }

  private formatStep(stepKey: string): string {
    if (!this.i18Next.exists(`${stepKey}.header`, {})) {
      return `<div class="gd-step"><div class="gd-header text-nowrap"></div><div class="gd-content">${this.i18Next.t(`${stepKey}.content`)}</div></div>`
    }
    else {
      return `<div class="gd-step"><div class="gd-header text-nowrap">${this.i18Next.t(`${stepKey}.header`)}</div><div class="gd-content">${this.i18Next.t(`${stepKey}.content`)}</div></div>`
    }
  }

  private SetStepFirstElement(steps: any[], elementId: string, defaultKey: string) {
    let elements = document.querySelectorAll(elementId);
    if (elements.length > 0) {
      let element = elements[0];

      steps.push({
        element: element,
        intro: this.formatStep(this.getStepKey(element, defaultKey))
      })
    }
  }

  private getStepKey(element: Element, defaultKey: string) {
    let guidedTourKey = element.getAttribute(this.guidedTourKey);
    return StringHelper.isNullOrWhitespace(guidedTourKey) ? defaultKey : guidedTourKey;
  }

  public startProfileTour(auto: boolean = true) {
    let profileElement = document.querySelector('#profileContent');
    let userElement = document.querySelector('#user');
    let validationElement = document.querySelector('#validation');

    let steps: any[] = [];

    if (profileElement) {
      steps.push({
        intro: this.formatStep(this.getStepKey(profileElement, "guidedTour.steps.default.profile.start"))
      });
    }

    if (document.querySelectorAll('#warnClick').length > 0) {
      steps.push({
        element: document.querySelectorAll('#warnClick')[0],
        intro: this.formatStep('guidedTour.steps.default.profile.warnClick'),
        position: "left",
        tooltipPosition: "left"
      })
    }

    if (validationElement) {
      steps.push({
        element: validationElement,
        intro: this.formatStep(this.getStepKey(validationElement, "guidedTour.steps.default.profile.validation"))
      });
    }

    if (userElement) {
      steps.push({
        element: userElement,
        intro: this.formatStep(this.getStepKey(userElement, "guidedTour.steps.default.profile.user"))
      });
    }
    
    this.SetStepFirstElement(steps, `#resource_${Constants.PROVIDER_ID_MICROSOFT}_${Constants.PROVIDER_TYPE_MAILING}`, "guidedTour.steps.default.profile.exchange");

    this.SetStepFirstElement(steps, `#resource_${Constants.PROVIDER_ID_MICROSOFT}_${Constants.PROVIDER_TYPE_PERSONAL_STORAGE}`, "guidedTour.steps.default.profile.oneDrive");

    this.SetStepFirstElement(steps, `#site_${Constants.PROVIDER_ID_MICROSOFT}_${Constants.PROVIDER_TYPE_INTRANET}`, "guidedTour.steps.default.profile.sharePointSite");

    this.SetStepFirstElement(steps, `#resource_${Constants.PROVIDER_ID_MICROSOFT}_${Constants.PROVIDER_TYPE_INTRANET}`, "guidedTour.steps.default.profile.sharePoint");

    this.SetStepFirstElement(steps, `#resource_${Constants.PROVIDER_ID_MICROSOFT}_${Constants.PROVIDER_TYPE_FILE_SHARE}`, "guidedTour.steps.default.profile.fileShare");

    let validationEndElement = document.querySelector('#validationEnd');

    if (validationEndElement) {
      steps.push({
        intro: this.formatStep(this.getStepKey(validationElement, "guidedTour.steps.default.profile.end"))
      })
    }

    if (document.querySelectorAll(`#profile_${Constants.PROVIDER_ID_MICROSOFT}`).length > 0) {
      this.startTour(this.userProfileConstants.MS_PROFILE_TOUR, this.userProfileConstants.MS_PROFILE_TOUR_VERSION, auto, steps, true);
    }
  }

  private startTour(tour: string, version: number, auto: boolean, steps: any[], showProgress: boolean) {
    this.subscriptions.add(this.guidedTourDataSource.load(tour, version).subscribe((guidedTour) => {
      if ((auto && !guidedTour.alreadySeen) || !auto) {
        this.subscriptions.add(this.guidedTourDataSource.startTour(tour, version).subscribe(() => {
          this.currentTour = tour;
          this.currentTourVersion = version;
          this.currentStep = -1;
          this.steps = steps;
          this.started = true;
          this.stopped = false;
          this.startIntroJS(steps, showProgress);
        }));
      }
    }));
  }

  private changeStep() {
    if (this.currentStep < this.introJS._currentStep) {
      this.currentStep = this.introJS._currentStep;
    }
  }


  private ExitTour() {
    if (this.started && !this.stopped) {
      console.log(`intro.js => on exit ${JSON.stringify(arguments)} : ${JSON.stringify(this.introJS._currentStep + 1)}/${JSON.stringify(this.introJS._introItems.length)}`);
      this.stopped = true;
      var model = new GuidedTourStopModel;
      model.lastStep = this.currentStep + 1;
      model.numberOfSteps = this.steps.length;
      this.subscriptions.add(this.guidedTourDataSource.stopTour(this.currentTour, this.currentTourVersion, model).subscribe(() => {
      }));
    }
  }



  public startModalTour(auto: boolean = true) {
    let steps: any[] = [{
      intro: this.formatStep("guidedTour.steps.modal.start")
    }];

    this.startTour(this.userProfileConstants.MS_PROFILE_MODAL_TOUR, this.userProfileConstants.MS_PROFILE_MODAL_TOUR_VERSION, auto, steps, false);
  }

  private startIntroJS(steps: any[], showProgress: boolean) {

    this.introJS.setOptions({
      disableInteraction: true,
      showProgress: showProgress,
      showStepNumbers: false,
      showBullets: false,
      steps: steps,
      skipLabel: this.i18Next.t("guidedTour.exit"),
      doneLabel: this.i18Next.t("guidedTour.exit"),
      nextLabel: this.i18Next.t("guidedTour.next"),
      prevLabel: this.i18Next.t("guidedTour.previous"),
      hidePrev: false,
      hideNext: true,
      exitOnOverlayClick: false,
      overlayOpacity: 0.4,
      tooltipPosition: "auto",
      exitOnEsc: false,
      scrollTo: "tooltip"
    });

    this.introJS.start();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}

import { Criticality } from './criticality';
import { KeyValuePair } from '../../models/key-value-pair';

export class ProviderAdminObjectThreatReasonModel {
  key: string;
  reasonResourceKey: string;
  reasonResourceArguments: { [key: string]: string };
  reasonContext: KeyValuePair[];
  criticality: Criticality;
  creationDate: Date;
}

import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import { MicrosoftOfficeProfileProviderDatasourceService, SharePointReportProfileAlertExternalDelegatesModel, SharePointReportProfile } from '@i2a/web-api-client';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { SharePointExternalDelegatesModalComponent } from '../share-point-external-delegates-modal/share-point-external-delegates-modal.component';
import { SharePointReportProfileDelegateWithAlert } from 'projects/web-user-profile/src/app/models/providers/microsoft/share-point/share-point-report-profile-delegate-with-alert';
import dayJS from 'dayjs';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { SharePointDelegate } from 'projects/web-user-profile/src/app/models/providers/microsoft/share-point/share-point-delegate';

@Component({
  selector: 'i2a-share-point-external-delegates',
  templateUrl: './share-point-external-delegates.component.html',
  styleUrls: ['./share-point-external-delegates.component.scss']
})
export class SharePointExternalDelegatesComponent extends ProfileItemBaseClassComponent<SharePointReportProfile> implements OnInit, OnDestroy {
  @Input() public delegates: SharePointDelegate[];
  @Input() public viewWarnButton: boolean;


  public delegatesWithAlerts: SharePointReportProfileDelegateWithAlert[];
  public name: string;
  public lastAccess: Date;

  constructor(
    modalService: NgbModal,
    toastr: ToastrService,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
  }

  setItems() {
    if (this.delegates && this.alerts) {
      this.lastAccess = null;
      super.setItems();
      this.hasAlert = false;
      this.delegatesWithAlerts = this.delegates.map(d => {
        var dWithAlert = {
          accesses: d.accesses, permissions: d.permissions,
          hasAlert: AlertReasonHelper.alertsContainsDelegate(this.alerts, d.accesses, false),
          hasAlertRecentlyClosed: AlertReasonHelper.alertsContainsDelegate(this.alerts, d.accesses, true)
        };
        this.hasAlert = this.hasAlert || dWithAlert.hasAlert;
        this.hasAlertRecentlyClosed = this.hasAlertRecentlyClosed || dWithAlert.hasAlertRecentlyClosed;
        if (this.lastAccess == null || dayJS(d.accesses.lastAccess).isAfter(dayJS(this.lastAccess))) {
          this.lastAccess = d.accesses.lastAccess;
        }
        return dWithAlert;
      });
    }
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(SharePointExternalDelegatesModalComponent, { windowClass: 'id-modal', centered: true, size: 'lg' });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.delegates = this.delegates;
    modalRef.result.then((model: SharePointReportProfileAlertExternalDelegatesModel) => {
      this.initAlertModel(model);
      model.providerInstanceResource = this.providerInstanceResource;
      this.subscriptions.add(this.microsoftDatasource.alertSharePointExternalDelegates(this.profile.providerInstanceId, this.profile.userId, model).subscribe(model => {
        this.setAlertsProcessed(model);
      }));
    }).catch((error) => {
      // console.log(error);
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

import { BaseProviderInstanceResourceModel } from '../../../base-provider-instance-resource-model';
import { ChangeResult } from '../../../change-result';
import { OneDriveReportProfileDelegate } from '../../../microsoft/onedrive/profile/one-drive-report-profile-delegate';
import { OneDriveReportProfileRulesComputedDelegate } from '../../../microsoft/onedrive/profile/one-drive-report-profile-rules-computed-delegate';
import { ReportProfileResourceChangesBaseModel } from '../../../reports/profile/report-profile-resource-changes-base-model';
import { SharePointReportProfilePermissions } from '../../../microsoft/sharepoint/profile/share-point-report-profile-permissions';

export class OneDriveReportProfileChangesModel extends ReportProfileResourceChangesBaseModel<OneDriveReportProfileRulesComputedDelegate> {
  delegates: ChangeResult<OneDriveReportProfileDelegate>;
  isEqual: boolean;
  providerInstanceResource: BaseProviderInstanceResourceModel;
  permissions: ChangeResult<SharePointReportProfilePermissions>;
}

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { UsersDatasourceService, ProviderInstanceModel, Constants } from '@i2a/web-api-client';
import { ContextService } from '@i2a/web-core';

@Component({
  selector: 'i2a-instance-switcher',
  templateUrl: './instance-switcher.component.html',
  styleUrls: ['./instance-switcher.component.scss']
})
export class InstanceSwitcherComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  @Input() public userId: string;
  @Input() public providerInstanceId: string;
  public providerInstances: ProviderInstanceModel[];
  public tenantCode: string;
  public defaultInstances: ProviderInstanceModel[];

  constructor(
    private usersDatasource: UsersDatasourceService,
    public context: ContextService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  
  public isMicrosoft(providerInstance: ProviderInstanceModel): boolean {
    return providerInstance.providerId == Constants.PROVIDER_ID_MICROSOFT;
  }

  public isZimbra(providerInstance: ProviderInstanceModel): boolean {
    return providerInstance.providerId == Constants.PROVIDER_ID_ZIMBRA;
  }

  ngOnInit() {
    if (this.userId) {
      this.subscriptions.add(this.context.TenantCode$.subscribe(tenantCode => {
        this.tenantCode = tenantCode;
      }));
      this.subscriptions.add(this.usersDatasource.loadProviderInstances(this.userId)
        .subscribe(providerInstances => {
          this.defaultInstances = providerInstances.filter(p=> p.annotations.findIndex(a => a.key == Constants.ANNOTATION_DEFAULT_PROVIDERINSTANCE) >= 0);

          this.providerInstances = providerInstances;
        }));
    }
  }

}

<i2a-profile-alert-base-modal
  [manualDescriptionKey]="'applyCorrection.providers.microsoft.exchange.permission.manual.description'"
  [manualLinkKey]="'applyCorrection.providers.microsoft.exchange.permission.manual.link'"
  [automaticDescriptionKey]="'applyCorrection.providers.microsoft.exchange.permission.automatic.description'"
  [ignoreCommentToSend]="session.IgnoreCommentToSend" [displayComment]="session.DisplayComment"
  [validation]="validation" [canSend]="canSend" [modalTitle]="'profile.confirmationOfInformationTitle' | i18next "
  [resourceProfile]="resourceProfile">

  <ng-container header-picto>
    <i2a-delegate-photo [isAnonymous]="permissions.logonUserId == 'Anonymous'"
      [isDefault]="permissions.logonUserId == 'Default'" [isApplication]="false"
      [pir]="permissions.providerInstanceResource" [piu]="permissions.providerInstanceUser" [isExternalUser]="false">
    </i2a-delegate-photo>
  </ng-container>

  <ng-container header-title>
    {{title}}<br>
  </ng-container>

  <ng-container body>
    <div class="container-fluid">
      <div class="row pt-2" *ngIf="permissions">
        <div class="col-12 pb-1 id-detail">{{ "profile.provider.microsoft.exchange.grantedRights" | i18next  }} </div>
        <div class="form-group col-6 col-sm-4" *ngIf="permissions.hasFullAccessRights">
          <div class="permission">
            <div class="permission-type">
              {{"profile.provider.microsoft.exchange.rights.fullAccess" | i18next }}
            </div>
            <div class="permission-checkbox ml-1">
              <i2a-input-checkbox [(model)]="validation.hasFullAccessRightsIsValid"
                [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="false"
                [valueUnChecked]="true" (modelChange)="setCanSend()"> </i2a-input-checkbox>
            </div>
          </div>
        </div>

        <div class="form-group col-6 col-sm-4" *ngIf="permissions.hasSendAsRights">
          <div class="permission">
            <div class="permission-type">
              {{"profile.provider.microsoft.exchange.rights.sendAs" | i18next }}
            </div>
            <div class="permission-checkbox ml-1">
              <i2a-input-checkbox [(model)]="validation.hasSendAsRightsIsValid"
                [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="false"
                [valueUnChecked]="true" (modelChange)="setCanSend()"> </i2a-input-checkbox>
            </div>
          </div>
        </div>

        <div class="form-group col-6 col-sm-4" *ngIf="permissions.hasCalendarRights">
          <div class="permission">
            <div class="permission-type">
              {{"profile.provider.microsoft.exchange.rights.calendar" | i18next }}
            </div>
            <div class="permission-checkbox ml-1">
              <i2a-input-checkbox [(model)]="validation.hasCalendarRightsIsValid"
                [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="false"
                [valueUnChecked]="true" (modelChange)="setCanSend()"> </i2a-input-checkbox>
            </div>
          </div>
        </div>

        <div class="form-group col-6 col-sm-4" *ngIf="permissions.hasFoldersRights">
          <div class="permission">
            <div class="permission-type">
              {{"profile.provider.microsoft.exchange.rights.folders" | i18next }}
            </div>
            <div class="permission-checkbox ml-1">
              <i2a-input-checkbox [(model)]="validation.hasFoldersRightsIsValid"
                [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="false"
                [valueUnChecked]="true" (modelChange)="setCanSend()"> </i2a-input-checkbox>
            </div>
          </div>
        </div>

      </div>
    </div>
  </ng-container>

</i2a-profile-alert-base-modal>
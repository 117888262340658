import { Component } from '@angular/core';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ApplicationContainerComponent } from '../../../../shared/application-container/application-container.component';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { BaseProviderInstanceResourceModel, MicrosoftOfficeReportProfileModel } from '@i2a/web-api-client';

@RegisterMyProfileComponent(TemplateConstants.MODULE_AZUREAD_NODATA)
@Component({
  selector: 'azure-ad-nodata-container-module',
  templateUrl: './../../../../shared/application-container/application-container.component.html',
  styleUrls: ['./../../../../shared/application-container/application-container.component.scss']
})
export class AzureADNodataContainerModuleComponent extends ApplicationContainerComponent {

  showResourceIfNodData(resource: BaseProviderInstanceResourceModel): boolean {
    var azureADProfile = (<MicrosoftOfficeReportProfileModel>this.profile).azureADProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
    return ApplicationContainerComponent.showResourceIfNodData(resource) && azureADProfile.azureADAppPermissions.azureADAppPermissionGrants.length > 0;
  }

}

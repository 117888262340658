
export class FileShareReportProfilePermissionRights {
  path: string;
  enrichedFileSystemRights: number;
  read: boolean;
  write: boolean;
  execute: boolean;
  modify: boolean;
  fullControl: boolean;
  hasSensitivityLabel: boolean;
  sensitivityLabels: string[];
}

<div [ngClass]="classes" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseDetails">
  <div class="card-body p-1 id-card-body id-title-bar-header" [attr.data-guidedTourKey]="guidedTourKey" [attr.id]="id">
    <div class="container-fluid no-gutters p-0">
      <div class="row id-title-bar no-gutters">
        <div class="id-col-picture" (click)="collapse()">
          <div [ngClass]="{'id-title-bar-radius': rounded, 'id-title-bar-picture' : true, 'align-middle': true, 'mr-2':true }">
            <ng-content select="[picture]"></ng-content>
          </div>
        </div>
        <div class="id-col-title" (click)="collapse()" [ngClass]="{'id-col-title': colbuttons.childNodes.length == 0, 'id-col-title-buttons':colbuttons.childNodes.length > 0}">
          <div class="id-title-bar-title">
            <ng-content select="[title]"></ng-content>
          </div>
        </div>
        <div #colbuttons [ngClass]="{'id-col-buttons-none': colbuttons.childNodes.length == 0, 'id-col-buttons':colbuttons.childNodes.length > 0}">
          <ng-content select="[buttons]"></ng-content>
        </div>
        <div class="id-col-collapse">
          <div class="id-collapse px-2 my-1" (click)="collapse()">
            <ng-container *ngIf="isCollapsed">
              <fa-icon [icon]="['fal', 'chevron-down']" size="lg"></fa-icon>
            </ng-container>
            <ng-container *ngIf="!isCollapsed">
              <fa-icon [icon]="['fal', 'chevron-up']" size="lg"></fa-icon>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row id-title-bar-description no-gutters" (click)="collapse()">
        <div class="id-col-description">
          <ng-content select="[description]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="id-title-bar-content pb-4" id="collapseDetails" [ngbCollapse]="isCollapsed">
  <ng-content select="[body]"></ng-content>
</div>

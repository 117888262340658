import { AlertStatesAccessCountersModel } from './alert-states-access-counters-model';
import { AlertStatesBaseGlobalConfigurationCountersModel } from './alert-states-base-global-configuration-counters-model';
import { AlertStatesConfigurationCountersModel } from './alert-states-configuration-counters-model';
import { AlertStatesInvalidStateReportCountersModel } from './alert-states-invalid-state-report-counters-model';

export class AlertStatesModel {
  globalConfiguration: AlertStatesBaseGlobalConfigurationCountersModel;
  configuration: AlertStatesConfigurationCountersModel;
  invalidStateReport: AlertStatesInvalidStateReportCountersModel;
  access: AlertStatesAccessCountersModel;
}

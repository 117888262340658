import { Component, OnInit, OnDestroy, Input, Inject, ViewChild, TemplateRef } from '@angular/core';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { MicrosoftOfficeProfileProviderDatasourceService, SharePointGraphGroupModel, AzureADUserSummaryModel, AlertConstants, SharePointReportProfilePermissionDetail, SharePointReportProfile, SharePointReportProfilePermissions, SharePointReportProfileAlertO365GroupModel, SharePointSiteReportProfile, MicrosoftOfficeReportProfileModel, BaseProviderInstanceResourceModel, AlertInvalidStateFeaturedModel } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharePointO365GroupModalComponent } from '../share-point-o365-group-modal/share-point-o365-group-modal.component';
import { ToastrService } from 'ngx-toastr';
import { SharePointO365GroupDelegateWithAlert } from 'projects/web-user-profile/src/app/models/providers/microsoft/share-point/share-point-o365-group-delegate-with-alert';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { InformationModalComponent } from 'projects/web-user-profile/src/app/components/shared/modal/information-modal/information-modal.component';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';


@Component({
  selector: 'i2a-share-point-o365-group',
  templateUrl: './share-point-o365-group.component.html',
  styleUrls: ['./share-point-o365-group.component.scss']
})
export class SharePointO365GroupComponent extends ProfileItemBaseClassComponent<SharePointReportProfile> implements OnInit, OnDestroy {
  @Input() public group: SharePointGraphGroupModel;
  @Input() public permissions: SharePointReportProfilePermissions[];
  @Input() public displaySecurity: boolean;
  @Input() public deduplicateOwnerMembers: boolean;
  @Input() public disableCheckAllOwnersWithOwnersFiltered: boolean;
  @Input() public doNotDeleteCurrentUserFromSiteOwnerGroup: boolean;
  @Input() public isGroupBySite: boolean;
  @Input() public resources: BaseProviderInstanceResourceModel[];

  public name: string;
  public security: string;
  public visibility: string;
  public members: SharePointO365GroupDelegateWithAlert[];
  public owners: SharePointO365GroupDelegateWithAlert[];
  public guests: SharePointO365GroupDelegateWithAlert[];

  public ownersMinusProfileUser: SharePointO365GroupDelegateWithAlert[];
  public membersMinusProfileUser: SharePointO365GroupDelegateWithAlert[];

  public ownerDriveItems: { [key: string]: SharePointReportProfilePermissionDetail };
  public memberDriveItems: { [key: string]: SharePointReportProfilePermissionDetail };
  public guestDriveItems: { [key: string]: SharePointReportProfilePermissionDetail };

  public hasOwnerDriveItems: boolean = false;
  public hasMemberDriveItems: boolean = false;
  public hasGuestDriveItems: boolean = false;

  public totalOwners: Number;
  public totalMembers: Number;
  public totalGuests: Number;

  public headerTitle: string;
  public site: SharePointSiteReportProfile;

  constructor(
    modalService: NgbModal,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.isGroupBySite) {
      this.site = (<MicrosoftOfficeReportProfileModel>this.profile).sharePointSites.find(s => s.id.toLocaleLowerCase() === this.resourceProfile.siteId.toLocaleLowerCase());
      this.headerTitle = this.site?.title;
    }
  }

  setItems() {
    if (this.group && this.alerts && this.permissions) {
      super.setItems();
      this.name = this.group.displayName;
      this.security = this.group.securityEnabled ? this.i18Next.t("profile.provider.microsoft.sharePoint.group.security.enabled") : this.i18Next.t("profile.provider.microsoft.sharePoint.group.security.disabled");
      this.visibility = this.i18Next.t(`profile.provider.microsoft.sharePoint.group.visibility.${this.group.visibility.toLowerCase()}`);

      this.hasAlert = this.hasAlertInAlerts(false);
      this.hasAlertRecentlyClosed = this.hasAlertInAlerts(true);

      var computedGroupNames = this.computeMemberGroupName([], [" Members", " Membres"]);
      this.members = [];
      this.memberDriveItems = this.setDelegates(computedGroupNames, this.members, this.group.members.filter(m => this.deduplicateOwnerMembers && !this.group.owners.some(o => o.id == m.id) || !this.deduplicateOwnerMembers), AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_MEMBER);
      this.hasMemberDriveItems = this.memberDriveItems == null ? false : Object.keys(this.memberDriveItems).length > 0;

      var computedGroupNames = this.computeMemberGroupName(["Propriétaires de "], [" Owners"]);
      this.owners = [];
      this.ownerDriveItems = this.setDelegates(computedGroupNames, this.owners, this.group.owners, AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_OWNER);
      this.hasOwnerDriveItems = this.ownerDriveItems == null ? false : Object.keys(this.ownerDriveItems).length > 0;

      if (this.doNotDeleteCurrentUserFromSiteOwnerGroup) {
        this.ownersMinusProfileUser = this.owners.filter(o => !this.user.providerEmails.includes(o.mail) && !this.user.providerEmails.includes(o.userPrincipalName));
        this.membersMinusProfileUser = this.members.filter(o => !this.user.providerEmails.includes(o.mail) && !this.user.providerEmails.includes(o.userPrincipalName));
      }

      var computedGroupNames = this.computeMemberGroupName([], [" Visitors", " Visiteurs"]);
      this.guests = [];
      this.guestDriveItems = this.setDelegates(computedGroupNames, this.guests, this.group.guests, AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_GUEST);
      this.hasGuestDriveItems = this.guestDriveItems == null ? false : Object.keys(this.guestDriveItems).length > 0;

      this.totalMembers = this.members.length;
      this.totalOwners = this.owners.length;
      this.totalGuests = this.guests.length;

      this.id = `${this.componentId}-${this.providerInstanceResource.providerTypeId}-${this.providerInstanceResource.providerInstanceResourceId}-${this.group.displayName.replaceAll(' ', '-')}-SharePointO365Group`;
    }
  }

  private hasAlertInAlerts(done: boolean): boolean {
    var alerts = AlertReasonHelper.GetalertsContainsReason(this.alerts, AlertConstants.ALERT_INVALID_STATE_REASON_SHAREPOINT_O365_GROUP, done);
    return alerts.some(a => a.reasons.some(r => AlertReasonHelper.containsValue(r.reasonContext, AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_O365_GROUP_ID, this.group.id)));
  }

  private computeMemberGroupName(namePrefix: string[], nameSuffix: string[]) {

    var computedGroupNames = nameSuffix.map(suffix => `${this.group.displayName}${suffix}`);
    var prefix = namePrefix.map(prefix => `${prefix}${this.group.displayName}`);
    if (prefix != null && prefix.length > 0) {
      computedGroupNames = computedGroupNames.concat(prefix);
    }
    return computedGroupNames;
  }

  setDelegates(groupNames: Array<string>, dest: SharePointO365GroupDelegateWithAlert[], source: AzureADUserSummaryModel[], key: string): { [key: string]: SharePointReportProfilePermissionDetail } {
    let delegatesWithAlerts = AlertReasonHelper.GetIds(this.alerts, key, false);
    let delegatesWithAlertsRecentlyClosed = AlertReasonHelper.GetIds(this.alerts, key, true);

    source.forEach(d => {
      var dWithAlert = {
        "id": d.id,
        "displayName": d.displayName,
        "userPrincipalName": d.userPrincipalName,
        "userType": d.userType,
        "mail": d.mail,
        "hasAlert": delegatesWithAlerts[d.id],
        "hasAlertRecentlyClosed": delegatesWithAlertsRecentlyClosed[d.id]
      };
      dest.push(dWithAlert);
    });

    let permissions = this.permissions.find(permission => permission.isO365Group && groupNames.findIndex(groupName => groupName == permission.name) >= 0);

    if (permissions != null) {
      if (Object.keys(permissions.driveItems).indexOf('/') > -1) {
        delete permissions.driveItems["/"];
      }
      return permissions.driveItems;
    } else {
      return null;
    }
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(SharePointO365GroupModalComponent, { windowClass: 'id-modal', centered: true, size: 'lg' });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.deduplicateOwnerMembers = this.deduplicateOwnerMembers;
    modalRef.componentInstance.disableCheckAllOwnersWithOwnersFiltered = this.disableCheckAllOwnersWithOwnersFiltered;
    modalRef.componentInstance.group = this.group;
    modalRef.componentInstance.headerTitle = this.headerTitle;
    if (this.doNotDeleteCurrentUserFromSiteOwnerGroup) {
      modalRef.componentInstance.ownersMinusProfileUser = this.ownersMinusProfileUser;
      modalRef.componentInstance.membersMinusProfileUser = this.membersMinusProfileUser;
    }
    modalRef.componentInstance.doNotDeleteCurrentUserFromSiteOwnerGroup = this.doNotDeleteCurrentUserFromSiteOwnerGroup;
    modalRef.result.then((model: SharePointReportProfileAlertO365GroupModel) => {
      this.initAlertModel(model);
      if (model.allGuests) {
        model.invalidGuestList = [];
      }
      if (model.invalidMemberList.length == this.group.members.length) {
        model.allMembers = true;
        model.invalidMemberList = [];
      }
      if (this.isGroupBySite) {
        model.providerInstanceResources = this.resources;
      } else {
        model.providerInstanceResources = [this.providerInstanceResource];
      }
      this.subscriptions.add(this.microsoftDatasource.alertSharePointO365Group(this.profile.providerInstanceId, this.profile.userId, model).subscribe(model => {
        this.setAlertProcessed(model);
      }));
    }).catch((error) => {
      // console.log(error);
    });
  }

  @ViewChild('viewDetailTemplate') viewDetailTemplate: TemplateRef<any>;

  openDetails(event): void {
    let members = SharePointO365GroupModalComponent.SetMembers(this.group.members, this.group.owners, this.deduplicateOwnerMembers);
    let owners = SharePointO365GroupModalComponent.GetOwnersOrGuests(this.group.owners);
    let guests = SharePointO365GroupModalComponent.GetOwnersOrGuests(this.group.guests);
    const modalRef = this.modalService.open(InformationModalComponent, { windowClass: 'id-modal', centered: true, size: 'lg' });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.title = this.i18Next.t("modal.detail.title");
    modalRef.componentInstance.headerTitle = this.headerTitle;
    modalRef.componentInstance.displayWarnClick = members.length > 0 || guests.length > 0 ||
      (
        !this.disableCheckAllOwnersWithOwnersFiltered && ((this.group.ownersFiltered && owners.length >= 1) || (!this.group.ownersFiltered && owners.length >= 2))
        ||
        this.disableCheckAllOwnersWithOwnersFiltered && owners.length >= 2
      );
    modalRef.componentInstance.bodyTemplate = this.viewDetailTemplate;
    modalRef.componentInstance.ok = this.i18Next.t("button.close");
    modalRef.componentInstance.activateFullScreen = true;
    modalRef.result.then((model: any) => {
      if (model == true) {
        this.warnClick(null);
      }
    }, (reason) => {
      //Dismiss
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

import { Component, OnInit, Input, Inject, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { AzureADAppPermissionGrantModel, AzureADServicePrincipalSummaryWithScopesModel, MicrosoftOfficeProfileProviderDatasourceService, AzureADReportProfileAlertGrantsModel, BaseProviderInstanceResourceModel, AlertInvalidStateFeaturedModel, AlertConstants, AzureADReportProfile } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AzureAdPermissionGrantsModalComponent } from '../azure-ad-permission-grants-modal/azure-ad-permission-grants-modal.component';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { ListHelper } from 'projects/web-user-profile/src/app/helpers/list-helper';
import { ToastrService } from 'ngx-toastr';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { InformationModalComponent } from 'projects/web-user-profile/src/app/components/shared/modal/information-modal/information-modal.component';

@Component({
  selector: 'i2a-azure-ad-permission-grants',
  templateUrl: './azure-ad-permission-grants.component.html',
  styleUrls: ['./azure-ad-permission-grants.component.scss']
})
export class AzureAdPermissionGrantsComponent extends ProfileItemBaseClassComponent<AzureADReportProfile> implements OnInit, OnDestroy {
  @Input() public grants: AzureADAppPermissionGrantModel;
  @Input() public showDetails: boolean;

  public resources: AzureADServicePrincipalSummaryWithScopesModel[][];
  public subTitle: string;
  public title: string;
  public scopes: string[];

  constructor(
    modalService: NgbModal,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
    this.scopes = [];
  }

  setItems() {
    if (this.grants && this.alerts) {
      this.subTitle = this.grants.client.appPublisherName;
      this.title = this.grants.client.appDisplayName;
      this.grants.resources.forEach(resource => {
        ListHelper.distinct(resource.scopes, "description");
      });
      this.hasAlert = this.hasAlertInAlerts(false);
      this.hasAlertRecentlyClosed = this.hasAlertInAlerts(true);
      this.grants.resources.forEach(r => {
        r.scopes.forEach(s => {
          if (this.scopes.findIndex(v => v == s.description) == -1) {
            this.scopes.push(s.description);
          }
        });
      });
      
      this.id = `${this.componentId}-${this.providerInstanceResource.providerTypeId}-${this.providerInstanceResource.providerInstanceResourceId}-${this.title.replaceAll(' ', '-')}-AzureAdPermissionGrants`;
    }
  }

  private hasAlertInAlerts(done: boolean): boolean {
    return AlertReasonHelper.alertsContainsKeyValuePair(this.alerts, AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_AD_GRANTS_CLIENT_ID, this.grants.client.id, done);
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(AzureAdPermissionGrantsModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.grants = this.grants;
    modalRef.result.then((model: AzureADReportProfileAlertGrantsModel) => {
      this.initAlertModel(model);
      model.providerInstanceResource = this.providerInstanceResource;
      model.grants = this.grants;
      this.subscriptions.add(this.microsoftDatasource.alertAzureADGrants(this.providerInstanceResource.providerInstanceId, this.profile.userId, model).subscribe(model => {
        this.setAlertProcessed(model);
      }));
    }).catch((error) => {
      //console.log(error);
    });
  }

  @ViewChild('viewDetailTemplate') viewDetailTemplate: TemplateRef<any>;

  openDetails(event): void {
    const modalRef = this.modalService.open(InformationModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.title = this.i18Next.t("modal.detail.title");
    modalRef.componentInstance.bodyTemplate = this.viewDetailTemplate;
    modalRef.componentInstance.ok = this.i18Next.t("button.close");
    modalRef.result.then((model: any) => {
      if (model == true) {
        this.warnClick(null);
      }
    }, (reason) => {
      //Dismiss
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

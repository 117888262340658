import { Component, OnInit, EventEmitter, Inject, Input } from '@angular/core';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { ReportProfileBaseModel, UserModel, BaseProviderInstanceResourceModel, MicrosoftOfficeReportProfileModel, ExchangeReportProfile, AlertInvalidStateFeaturedModel } from '@i2a/web-api-client';
import { ExchangeDelegate } from 'projects/web-user-profile/src/app/models/providers/microsoft/exchange/exchange-delegate';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ExchangeRuleConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/exchange-rule-configuration';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';
import { WarningInfo } from 'projects/web-user-profile/src/app/models/warnings/warning-info';
import { ExchangeInboxRuleComponent } from '../exchange-inbox-rule/exchange-inbox-rule.component';
import { ExchangeReportProfileInboxRuleWithWarrnings } from 'projects/web-user-profile/src/app/models/providers/microsoft/exchange/exchange-report-profile-inbox-rule-with-warnings';

@RegisterMyProfileComponent(TemplateConstants.MODULE_EXCHANGE_RULE)
@Component({
  selector: 'i2a-exchange-rule-module',
  templateUrl: './exchange-rule-module.component.html',
  styleUrls: ['./exchange-rule-module.component.scss']
})
export class ExchangeRuleModuleComponent extends ProfileBaseClassComponent<ExchangeRuleConfiguration, ExchangeReportProfile> implements OnInit, IModule<ExchangeRuleConfiguration> {
  public inboxRules: ExchangeReportProfileInboxRuleWithWarrnings[] = [];

  public showMore: boolean;
  public showMoreAfter: number;
  public maxShowMore: number;
  public isVisibleButtonsShowMore: boolean;
  private isActiveShowMoreOption: boolean;


  @Input()
  public delegatesWithAccessAndPermissions: ExchangeDelegate[];

  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, null);
  }

  ngOnInit() {
    super.ngOnInit();

    let warnings: Array<WarningInfo> = [];

    this.setInboxRules(this.configuration, warnings);

    if (warnings.length > 0) {
      this.hasWarnings(warnings);
    }

  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: ExchangeRuleConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel, resourceTemplate?: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.user = user;
    this.profile = profile;
    this.resourceProfile = (<MicrosoftOfficeReportProfileModel>profile).exchangeProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
    this.resourceProfile.rules.inboxRules.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
  }

  private setInboxRules(configuration: ExchangeRuleConfiguration, warnings: Array<WarningInfo>) {
    let inboxRulesWithWarnings = this.resourceProfile.rules?.inboxRules?.map(r => ExchangeInboxRuleComponent.getInboxRuleWithWarning(this.i18Next, this.componentId, this.resourceProfile, r, this.alerts));
    
    inboxRulesWithWarnings.forEach((inboxRule: ExchangeReportProfileInboxRuleWithWarrnings) => {
      if ((inboxRule.warnings != null && inboxRule.warnings != undefined)) {
        warnings.push(...inboxRule.warnings);
        this.inboxRules.unshift(inboxRule);
      } else {
        this.inboxRules.push(inboxRule);
      }
    });

    this.showMoreAfter = configuration.showMoreAfter ?? 0;

    let minShowMore = this.inboxRules.filter(d => d.warnings?.length > 0).length;
    if (this.showMoreAfter != 0 && this.showMoreAfter < minShowMore) {
      this.showMoreAfter = minShowMore;
    }


    this.isActiveShowMoreOption = this.showMoreAfter > 0;
    this.isVisibleButtonsShowMore = this.isActiveShowMoreOption && (this.inboxRules?.length > this.showMoreAfter);
    this.setShowMoreValue();
  }

  public onShowMore() {
    this.showMore = !this.showMore;
    this.setShowMoreValue();
  }

  private setShowMoreValue() {
    this.maxShowMore = (!this.showMore && this.isActiveShowMoreOption) ? this.showMoreAfter : this.inboxRules?.length;
  }



  alertSent(event): void {
    this.onAlertSent.emit(event);
  }

}

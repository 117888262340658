import { Criticality } from './criticality';
import { RuleGroup } from './rule-group';
import { RuleInheritance } from './rule-inheritance';

export class RuleCreateModel {
  inheritance: RuleInheritance|null;
  name: string;
  criticality: Criticality|null;
  group: RuleGroup;
  startDate: Date|null;
  endDate: Date|null;
  conditions: { [key: string]: string };
  exceptions: { [key: string]: string };
  rebuildAffectedElements: boolean|null;
  isTechnical: boolean;
  isInTestMode: boolean;
}

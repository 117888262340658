import { Component, OnInit, Input, Inject } from '@angular/core';
import { ZimbraMailingReportProfilePermissions, ZimbraMailingReportProfileAlertPermissionsModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-zimbra-mailing-permission-modal',
  templateUrl: './zimbra-mailing-permission-modal.component.html',
  styleUrls: ['./zimbra-mailing-permission-modal.component.scss']
})
export class ZimbraMailingPermissionModalComponent implements OnInit {
  @Input() public permissions: ZimbraMailingReportProfilePermissions;

  @Input() public resourceProfile: ReportProfileResourceBase<any>;
  
  public validation: ZimbraMailingReportProfileAlertPermissionsModel;
  public canSend: boolean;
  public title: string;

  constructor(
    private modalService: NgbModal,
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService,
    public session: SessionService) {
    this.canSend = false;
  }

  ngOnInit() {
    this.validation = new ZimbraMailingReportProfileAlertPermissionsModel();
    this.validation.hasSendAsRightsIsValid = this.permissions.hasSendAsRights ||
      this.permissions.hasCalendarRights ||
      this.permissions.hasContactsRights ||
      this.permissions.hasFoldersRights ||
      this.permissions.hasInboxRights;

    this.validation.hasCalendarRightsIsValid = this.permissions.hasSendAsRights ||
      this.permissions.hasSendOnBehalfRights ||
      this.permissions.hasContactsRights ||
      this.permissions.hasFoldersRights ||
      this.permissions.hasInboxRights;

    this.validation.hasFoldersRightsIsValid = this.permissions.hasSendAsRights ||
      this.permissions.hasSendOnBehalfRights ||
      this.permissions.hasCalendarRights ||
      this.permissions.hasContactsRights ||
      this.permissions.hasInboxRights;

    this.validation.hasSendOnBehalfRightsIsValid = this.permissions.hasSendAsRights ||
      this.permissions.hasCalendarRights ||
      this.permissions.hasContactsRights ||
      this.permissions.hasFoldersRights ||
      this.permissions.hasInboxRights;

    this.validation.hasContactsRightsIsValid = this.permissions.hasSendAsRights ||
      this.permissions.hasSendOnBehalfRights ||
      this.permissions.hasCalendarRights ||
      this.permissions.hasFoldersRights ||
      this.permissions.hasInboxRights;

    this.validation.hasInboxRightsIsValid = this.permissions.hasSendAsRights ||
      this.permissions.hasSendOnBehalfRights ||
      this.permissions.hasCalendarRights ||
      this.permissions.hasContactsRights ||
      this.permissions.hasFoldersRights;

    if (this.permissions) {
      if (this.permissions.isNameKey) {
        this.title = this.permissions.name;
      }
      else {
        this.title = this.i18Next.t(this.permissions.name);
      }
    }
    this.setCanSend();
  }

  private setCanSend() {
    setTimeout(() => {
      this.canSend = !this.validation.hasSendAsRightsIsValid ||
        !this.validation.hasSendOnBehalfRightsIsValid ||
        !this.validation.hasCalendarRightsIsValid ||
        !this.validation.hasContactsRightsIsValid ||
        !this.validation.hasFoldersRightsIsValid ||
        !this.validation.hasInboxRightsIsValid;
    });
  }
}

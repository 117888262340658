<!-- INSTANT MESSAGING -->
<div class="module">
  <div class="container-fluid module-container"
    *ngIf="instantMessagingDelegates && instantMessagingDelegates.length > 0">
    <div class="row no-gutters">
      <i2a-sub-title class="col-12" [subTitle]="'profile.provider.microsoft.oneDrive.title.delegateInstantMessaging' | i18next "
        [description]="'profile.provider.microsoft.oneDrive.description.delegateInstantMessaging' | i18next ">
      </i2a-sub-title>
    </div>
    <div class="row no-gutters">
      <div *ngFor="let delegate of instantMessagingDelegates" class="col-sm-12 col-md-6 col-lg-4 align-items-start">
        <i2a-one-drive-instant-messaging [resourceProfile]="resourceProfile" [componentId]="componentId" [isAlert]="isAlert"
          [providerInstanceResource]="oneDriveProfile.providerInstanceResource" [profile]="profile" [user]="user"
          [delegate]="delegate" [alerts]="alerts" (onAlertSent)="alertSent($event)" (onHasWarnings)="hasWarnings($event)">
        </i2a-one-drive-instant-messaging>
      </div>
    </div>
  </div>
</div>
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from 'rxjs';
import { ContextService, CultureManagerService } from '@i2a/web-core';
import { SessionService } from '../services/session-service';
import { UsersDatasourceService } from '@i2a/web-api-client';

@Injectable()
export class TenantCodeResolver implements Resolve<Observable<any>> {
  constructor(
    private router: Router,
    private context: ContextService,
    private session: SessionService,
    private usersDataSource: UsersDatasourceService,
    private cultureManager: CultureManagerService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Observable<any> | Promise<any> | any> {
    let tenantCode: string = route.params["tenantCode"];

    if (!!tenantCode) {
      let tenant = this.session.Account.tenants.find(t => t.code === tenantCode);
      // tenant found is valid for user
      if (!!tenant) {
        await this.initServices(tenant.code);
        return;
      }

      // no valid tenant found for user
      this.invalidTenantManagement(tenantCode);
      return;
    }

    // used in tenant selection pages
    await this.initServices(tenantCode);
  }

  private invalidTenantManagement(tenantCode: string) {
    // get the first and only valid fallback tenant for user and redirect
    if (this.session.Account.tenants.length == 1) {
      const validTenantCode = this.session.Account.tenants[0].code;
      this.router.navigate(["/tenants", validTenantCode]);
      return;
    }

    // no fallback tenant found for user
    this.context.TenantCode = tenantCode;
    this.router.navigate(["/tenants"]);
  }

  private async initServices(tenantCode: string) {
    this.context.TenantCode = tenantCode;

    this.session.SecurityInfos = await this.usersDataSource.getSecurityInfos().toPromise();

    this.cultureManager.changeNamespace(tenantCode);
    await this.cultureManager.Use(this.context.Culture);
  }
}
import { Component, OnInit, Input } from '@angular/core';
import { UsersDatasourceUrlService } from '@i2a/web-api-client';

@Component({
  selector: 'i2a-user-photo',
  templateUrl: './user-photo.component.html',
  styleUrls: ['./user-photo.component.scss']
})
export class UserPhotoComponent implements OnInit {
  @Input() public userId: string;
  @Input() public size: number;
  public userPhotoUrl:string;
  
  constructor(
    private UsersDatasourceUrl: UsersDatasourceUrlService
  ) { }

  ngOnInit() {
    if (this.userId)
    {
      this.userPhotoUrl = this.UsersDatasourceUrl.PhotoUrl(this.userId, true);
    }
    else {
      this.userPhotoUrl = null;
    }
  }

}

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { RulesDatasourceService } from '@i2a/web-api-client';
import { Subscription } from 'rxjs';

@Component({
  selector: 'i2a-rule-human-expression',
  templateUrl: './rule-human-expression.component.html',
  styleUrls: ['./rule-human-expression.component.scss']
})
export class RuleHumanExpressionComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  @Input() public ruleId: string;

  public humanExpression: string;

  constructor(
    private rulesDatasource: RulesDatasourceService
  ) { }

  ngOnInit() {
    if (this.ruleId) {
      this.subscriptions.add(this.rulesDatasource.load(this.ruleId, true).subscribe(rule => {
        this.humanExpression = rule.humanExpression;
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}

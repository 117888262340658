<div class="row pl-2">
  <div [ngClass]="classes">
    <ng-container *ngIf="subTitle">
      {{subTitle}}
    </ng-container>
  </div>
  <div class="col-12 id-description">
    <ng-container *ngIf="description">
      {{ description }}
    </ng-container>
  </div>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'i2a-no-collected-resources',
  templateUrl: './no-collected-resources.component.html',
  styleUrls: ['./no-collected-resources.component.scss']
})
export class NoCollectedResourcesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

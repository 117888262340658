<span *ngIf="items && items.length > 0" class="location-name ml-2">{{ 'profile.user.origin.usual' | i18next}}</span>
<div *ngIf="items && items.length > 0" class="container p-0 usual-countries">
  <div class="row no-gutters">
    <div class="col m-2">
      <table *ngIf="items" class="table table-sm table-bordered id-table">
        <tbody>
          <tr *ngFor="let item of items">
            <td scope="row" [ngClass]="{'has-alert': item.hasAlert, 'has-alert-recently-closed': item.hasAlertRecentlyClosed}">
              <div class="id-item-background align-bottom mr-1"
                [ngStyle]="{'background-image': 'url(' + item.image + ')'}">
              </div>
              {{item.name}} <span *ngIf="item.expiration">( {{item.expiration | amDateFormat: 'LLL'}} )</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-container *ngIf="!user">
    <i2a-spinner ></i2a-spinner>
</ng-container>
<i2a-base-modal *ngIf="user" [modalTitle]="'myAccount.edit.title' | i18next ">
    <ng-container body>
        <ng-container >
            <i2a-spinner *ngIf="submiting"></i2a-spinner>
            <div *ngIf="!submiting" class="panel panel-primary" #scrollMe>
                <div class="panel-body pt-2 mt-sm-3">

                    <form [formGroup]="myForm" (ngSubmit)="submitForm()">
                        <div class="container">

                            <div class="form-group row">
                                <label for="firstName"
                                    class="col-sm-4 col-form-label">{{ "models.user.firstName" | i18next }}</label>
                                <div class="col-sm-8">
                                    <input [ngClass]="{'form-control': true, 'is-invalid': firstNameInput.invalid }" formControlName="firstName" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="lastName"
                                    class="col-sm-4 col-form-label">{{ "models.user.lastName" | i18next }}</label>
                                <div class="col-sm-8">
                                    <input [ngClass]="{'form-control': true, 'is-invalid': lastNameInput.invalid }" formControlName="lastName" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="eMail"
                                    class="col-sm-4 col-form-label">{{ "models.user.email" | i18next }}</label>
                                <div class="col-sm-8">
                                    <input [ngClass]="{'form-control': true, 'is-invalid': eMailInput.invalid }" formControlName="eMail" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="phone"
                                    class="col-sm-4 col-form-label">{{ "models.user.phone" | i18next }}</label>
                                <div class="col-sm-8">
                                    <input [ngClass]="{'form-control': true, 'is-invalid': phoneInput.invalid }" formControlName="phone" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="timeZoneId"
                                    class="col-sm-4 col-form-label">{{ "models.user.timeZone" | i18next }}</label>
                                <div class="col-sm-8">
                                    <select class="custom-select" formControlName="timeZoneId">
                                        <option *ngFor="let timeZone of timeZones" [ngValue]="timeZone">{{timeZone}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="culture"
                                    class="col-sm-4 col-form-label">{{ "models.user.culture" | i18next }}</label>
                                <div class="col-sm-8">
                                    <select class="custom-select" formControlName="culture">
                                        <option *ngFor="let culture of cultures" [ngValue]="culture">{{"culture." + culture | i18next}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-container footer>
        <div class="col-centered">
            <button *ngIf="!submiting" (click)="submitForm()" ngbAutofocus type="submit" [disabled]="myForm.invalid"
                class="btn btn-red ml-2">{{'button.saveMyAccount' | i18next }}</button>
        </div>
    </ng-container>
</i2a-base-modal>
import { ProviderAdminObjectThreatReasonModel } from '../../../provider-admin-object-threat-reason-model';

export class ExchangeReportProfileInboxRule {
  identity: string;
  name: string;
  description: string;
  reasonObjects: ProviderAdminObjectThreatReasonModel[];
  redirectOrForwardEmails: string[];
  isEnabled: boolean;
  isExternalForward: boolean;
}

import { Component, Inject, Input, OnChanges, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BaseProviderInstanceResourceModel, MicrosoftOfficeProfileProviderDatasourceService, MicrosoftOfficeReportProfileModel, ProviderInstanceResourceSummaryModel, SharePointDatasourceService, SharePointReportProfile, SharePointReportProfileAlertDeleteResourceModel, SharePointSite, SharePointSiteReportProfile } from '@i2a/web-api-client';
import { ContextService } from '@i2a/web-core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ToastrService } from 'ngx-toastr';
import { ConfirmModalComponent } from 'projects/web-user-profile/src/app/components/shared/modal/confirm-modal/confirm-modal.component';
import { ProviderHelper } from 'projects/web-user-profile/src/app/helpers/provider-helper';
import { SharePointResourceActionsConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/share-point-resource-actions-configuration';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';

@Component({
  selector: 'i2a-share-point-actions',
  templateUrl: './share-point-actions.component.html',
  styleUrls: ['./share-point-actions.component.scss']
})
export class SharePointActionsComponent extends ProfileBaseClassComponent<any,any> implements OnInit, OnChanges {

  @Input() public configuration: SharePointResourceActionsConfiguration;
  @Input() public resources: BaseProviderInstanceResourceModel[];
  @Input() public resourceProfiles: SharePointReportProfile[];
  @Input() public resource: BaseProviderInstanceResourceModel;
  @Input() public site: SharePointSiteReportProfile;
  @Input() public hasDeleteAlertOrHasNotBelongToMe: boolean = false;
  public deleteComments: string;
  public providerTypeLabel: string;
  public providerPhotoUrl: string;
  public loading: boolean = true;
  public teamWebUrl: string;
  public yammerWebUrl: string;
  public siteUrl: string;

  @ViewChild('deleteConfirmationTemplate') deleteConfirmationTemplate: TemplateRef<any>;
  @ViewChild('deleteConfirmationTemplateHeader') deleteConfirmationTemplateHeader: TemplateRef<any>;
  public deleteWarningKeys: string[];

  public deleteLibrary: boolean = true;
  public deleteSite: boolean = true;
  public hasSubItems: boolean;
  public hasSubSites: boolean;

  public subItems: SharePointSite[];
  public siteExtended: { id: string; name: string; parentId: any; };
  public canDelete: boolean;
  public canDeleteSite: boolean;
  private modalRef: NgbModalRef;
  public canDeleteLibrary: boolean;
  public openSite: boolean;

  public viewSiteAction: boolean = false;
  public viewListAction: boolean = false;

  public headerTitle: string;
  public isSharePointSiteGroupBySite: boolean;

  public checkboxSiteClassNames: string;
  public checkboxSiteClassNamesChecked: string;

  constructor(modalService: NgbModal,
    public microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    public sharepointDatasource: SharePointDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService,
    private context: ContextService,
    public session: SessionService) {
    super(toastr, i18Next, modalService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.checkboxSiteClassNames = 'btn-outline-dark';
    this.checkboxSiteClassNamesChecked = 'btn-outline-danger';
    if (this.resources && this.resourceProfiles) {
      this.resourceProfile = this.resourceProfiles[0]
      this.resource = this.resources[0]
      this.viewSiteAction = true;
    }

    if (this.resource && this.resourceProfile) {
      this.viewListAction = true && !this.viewSiteAction;

      if (this.session.SharePointGroupBySite) {
        this.checkboxSiteClassNames = this.checkboxSiteClassNames + ' d-none';
        this.checkboxSiteClassNamesChecked = this.checkboxSiteClassNamesChecked + ' d-none';
      }

      if (this.site && this.session.SharePointGroupBySite) {
        this.headerTitle = this.site.title;      
        this.isSharePointSiteGroupBySite = true;
        this.providerTypeLabel = this.site.title;
        this.siteUrl = this.site.url;
      } else {
        this.headerTitle = null;
        this.isSharePointSiteGroupBySite = false;
        this.providerTypeLabel = `${this.i18Next.t(ProviderHelper.GetProviderTypeLabel(this.resourceProfile.providerInstanceResource.providerId, this.resourceProfile.providerInstanceResource.providerTypeId))} ${this.resourceProfile.providerInstanceResource.name}`;
        this.siteUrl = this.resourceProfile.siteUrl;
      }

      this.providerPhotoUrl = ProviderHelper.GetProviderImage(this.resourceProfile.providerInstanceResource.providerId, this.resourceProfile.providerInstanceResource.providerTypeId, this.resourceProfile);
  
      this.canDelete = this.configuration.deleteResource 
        ? (this.configuration.canDeleteWithHubsite || !this.resourceProfile.isHubSite) && (this.configuration.canDeleteWithAssociatedToHubsite || !this.resourceProfile.isAssociatedToHubSite) 
        : false;
        
      var o365Group = this.resourceProfile.o365Groups.find(g => g.teamWebUrl != null);
      this.teamWebUrl = o365Group?.teamWebUrl;
      o365Group = this.resourceProfile.o365Groups.find(g => g.yammerWebUrl != null);
      this.yammerWebUrl = o365Group?.yammerWebUrl;
      
      if (this.teamWebUrl != null ?? false) {
        this.openSite = this.configuration.openSiteIfTeam;
      } else if (this.yammerWebUrl != null ?? false) {
        this.openSite = this.configuration.openSiteIfYammer;
      } else {
        this.openSite = this.configuration.openSite;
      }
    }
    
    this.setItems();
  }


  setItems() {
    super.setItems();
  }

  getSharePointWarningDeleteWarnings(): string[] {
    var result = [];

    result.push("modal.deleteConfirmation.warning.sharePoint.other");

    if (this.resourceProfile.o365Groups.length > 0) {
      result.push("modal.deleteConfirmation.warning.sharePoint.hasO365Group");
    }
    if (this.hasSubSites) {
      result.push("modal.deleteConfirmation.warning.sharePoint.hasSubSites");
    }

    return result;
  }

  public onDeleteResource(deleteFromSite: boolean) {
    this.modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: 'id-modal', centered: true, 'size': 'lg' });
    this.modalRef.componentInstance.resourceProfile = this.resourceProfile;

    this.loading = true;
    
    this.modalRef.componentInstance.disableButtons = true;
    
    var resource = deleteFromSite ? this.resources[0] : this.resource;

    this.subscriptions.add(this.sharepointDatasource.getSharePointSiteInformation(resource.providerInstanceId, resource.providerInstanceResourceId, true)
      .subscribe(deleteInformation => {
        deleteInformation.farmSummaryItems = deleteInformation.farmSummaryItems.sort((a, b) => { return a.name.localeCompare(b.name) });
        if (this.configuration.hideLists) {
          this.subItems = deleteInformation.farmSummaryItems.filter(s => !s.isList);
        } else {
          this.subItems = deleteInformation.farmSummaryItems;
        }
        this.siteExtended = {
          id: this.resourceProfile.relatedFarmSummaryItemId,
          name: this.i18Next.t("modal.deleteConfirmation.siteChildren"),
          parentId: null
        };
        this.hasSubItems = deleteInformation.farmSummaryItems.length >= 0;
        this.hasSubSites = deleteInformation.farmSummaryItems.filter(item => !item.isList).length > 0;

        let deleteSiteAvailable = this.site && this.session.SharePointGroupBySite || !this.session.SharePointGroupBySite;

        this.canDeleteSite = this.configuration.checkOwnerFullControlToDisableDeleteSite ? deleteInformation.ownerHasFullControl && deleteSiteAvailable : deleteSiteAvailable;
        this.canDeleteLibrary = (this.site == null || this.site == undefined) && (this.configuration.deleteNotAllowedForLibraries == null || 
                                (this.configuration.deleteNotAllowedForLibraries != null && 
                                  !this.configuration.deleteNotAllowedForLibraries.some(d => this.resourceProfile.siteUrl.endsWith(d))));
        if (!this.canDeleteSite) {
          this.deleteSite = false;
        }
        this.deleteWarningKeys = this.getSharePointWarningDeleteWarnings();
        this.loading = false;
        this.modalRef.componentInstance.disableButtons = false;
      }))

    this.modalRef.componentInstance.title = this.i18Next.t("modal.deleteConfirmation.title");
    this.modalRef.componentInstance.headerTitle = this.headerTitle;
    this.modalRef.componentInstance.bodyTemplate = this.deleteConfirmationTemplate;
    this.modalRef.componentInstance.headerTemplate = this.deleteConfirmationTemplateHeader;
    this.modalRef.componentInstance.cancel = this.i18Next.t("button.cancel");
    this.modalRef.componentInstance.ok = this.i18Next.t("button.ok");
    this.modalRef.componentInstance.buttonOkClass = "btn-danger";
    this.modalRef.result.then(() => {
      if (this.canDeleteSite || this.canDeleteLibrary) {
        const confirmModal = this.modalService.open(ConfirmModalComponent, { windowClass: 'id-modal', centered: true });
        confirmModal.componentInstance.title = this.i18Next.t("modal.deleteConfirmation.areYouSure");
        if (this.isSharePointSiteGroupBySite && this.deleteSite) {
          confirmModal.componentInstance.question = this.i18Next.t(`modal.deleteConfirmation.site.confirmDeleteSite`)
        } else if (this.deleteSite) {
          confirmModal.componentInstance.question = this.i18Next.t(`modal.deleteConfirmation.confirmDeleteSite`);
        }else {
          confirmModal.componentInstance.question = this.i18Next.t(`modal.deleteConfirmation.confirmDeleteLibrary`);
        }
        confirmModal.componentInstance.cancel = this.i18Next.t("button.cancel");
        confirmModal.componentInstance.ok = this.i18Next.t("button.ok");
        confirmModal.componentInstance.buttonOkClass = "btn-danger";
        confirmModal.result.then(() => {

          let model = new SharePointReportProfileAlertDeleteResourceModel();
          this.initAlertModel(model);
          model.comments = this.deleteComments;
          model.deleteSite = this.deleteSite;
          model.site = this.site;
          if (this.isSharePointSiteGroupBySite) {
            model.providerInstanceResources = this.resources;
          } else {
            model.providerInstanceResources = [this.resourceProfile.providerInstanceResource];
          }
          this.subscriptions.add(this.microsoftDatasource.alertSharePointDelete(this.resourceProfile.providerInstanceResource.providerInstanceId, this.profile.userId, model).subscribe(model => {
            this.setAlertProcessed(model);
          }));
        }, (reason) => {
          //Dismiss
        });
      }

    }, () => {
      //Dismiss
    });
  }

  public deleteSiteChange(value: boolean) {
    setTimeout(() => {
      if (value) {
        this.deleteLibrary = true;
      }
      this.modalRef.componentInstance.disableButtons = !((this.canDeleteLibrary && this.deleteLibrary) || this.deleteSite);
    });
  }

  public deleteLibraryChange(value: boolean) {
    setTimeout(() => {
      if (!value) {
        this.deleteSite = false;
      }
      this.modalRef.componentInstance.disableButtons = !((this.canDeleteLibrary && this.deleteLibrary) || this.deleteSite);

    });
  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { ProviderAuditResourceKind, ExchangeReportProfileFolderRight } from '@i2a/web-api-client';

@Component({
  selector: 'i2a-exchange-permission-detail',
  templateUrl: './exchange-permission-detail.component.html',
  styleUrls: ['./exchange-permission-detail.component.scss']
})
export class ExchangePermissionDetailComponent implements OnInit {
  @Input()
  public ExchangeFoldersRights: {
    [key: string]: ExchangeReportProfileFolderRight;
  };
  public folderPaths: string[];
  
  public displayCreateLegend: boolean;
  public displayReadLegend: boolean;
  public displayLimitedReadLegend: boolean;
  public displayUpdateLegend: boolean;
  public displayLimitedUpdateLegend: boolean;
  public displayDeleteLegend: boolean;
  public displayLimitedDeleteLegend: boolean;
  public displayFolderVisibleLegend: boolean;

  constructor() { 
    this.displayCreateLegend = false;
    this.displayReadLegend = false;
    this.displayUpdateLegend = false;
    this.displayDeleteLegend = false;
  }

  ngOnInit() {
    if (this.ExchangeFoldersRights) {
      this.folderPaths = [];
      for (let key in this.ExchangeFoldersRights) {
        this.folderPaths.push(key);
        this.displayCreateLegend = this.displayCreateLegend || this.hasFlag(this.ExchangeFoldersRights[key], ExchangeReportProfileFolderRight.Create);
        this.displayReadLegend = this.displayReadLegend || this.hasFlag(this.ExchangeFoldersRights[key], ExchangeReportProfileFolderRight.Read);
        this.displayUpdateLegend = this.displayUpdateLegend || this.hasFlag(this.ExchangeFoldersRights[key], ExchangeReportProfileFolderRight.LimitedUpdate);
        this.displayDeleteLegend = this.displayDeleteLegend || this.hasFlag(this.ExchangeFoldersRights[key], ExchangeReportProfileFolderRight.LimitedDelete);
        this.displayLimitedReadLegend = this.displayLimitedReadLegend || (this.hasFlag(this.ExchangeFoldersRights[key], ExchangeReportProfileFolderRight.LimitedRead) && !this.hasFlag(this.ExchangeFoldersRights[key], ExchangeReportProfileFolderRight.Read));
        this.displayLimitedUpdateLegend = this.displayLimitedUpdateLegend || (this.hasFlag(this.ExchangeFoldersRights[key], ExchangeReportProfileFolderRight.LimitedUpdate) && !this.hasFlag(this.ExchangeFoldersRights[key], ExchangeReportProfileFolderRight.Update));
        this.displayLimitedDeleteLegend = this.displayLimitedDeleteLegend || (this.hasFlag(this.ExchangeFoldersRights[key], ExchangeReportProfileFolderRight.LimitedDelete) && !this.hasFlag(this.ExchangeFoldersRights[key], ExchangeReportProfileFolderRight.Delete));
        this.displayFolderVisibleLegend = this.displayFolderVisibleLegend || (this.hasFlag(this.ExchangeFoldersRights[key], ExchangeReportProfileFolderRight.FolderVisible) && !this.hasFlag(this.ExchangeFoldersRights[key], ExchangeReportProfileFolderRight.Read));
      }
      this.folderPaths = this.folderPaths.sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
    }
  }

  private hasFlag(exchangeRight: ExchangeReportProfileFolderRight, flag: ExchangeReportProfileFolderRight): boolean {
    return (exchangeRight & flag) == flag;
  }

  hasCreate(path: string): boolean {
    return this.hasFlag(this.ExchangeFoldersRights[path], ExchangeReportProfileFolderRight.Create);
  }

  hasRead(path: string): boolean {
    return this.hasFlag(this.ExchangeFoldersRights[path], ExchangeReportProfileFolderRight.Read);
  }

  hasLimitedRead(path: string): boolean {
    return this.hasFlag(this.ExchangeFoldersRights[path], ExchangeReportProfileFolderRight.LimitedRead) && !this.hasRead(path);
  }

  hasFolderVisible(path: string): boolean {
    return this.hasFlag(this.ExchangeFoldersRights[path], ExchangeReportProfileFolderRight.FolderVisible) && !this.hasRead(path);
  }

  hasUpdate(path: string): boolean {
    return this.hasFlag(this.ExchangeFoldersRights[path], ExchangeReportProfileFolderRight.Update);
  }

  hasLimitedUpdate(path: string): boolean {
    return this.hasFlag(this.ExchangeFoldersRights[path], ExchangeReportProfileFolderRight.LimitedUpdate) && !this.hasUpdate(path);
  }

  hasDelete(path: string): boolean {
    return this.hasFlag(this.ExchangeFoldersRights[path], ExchangeReportProfileFolderRight.Delete);
  }
  hasLimitedDelete(path: string): boolean {
    return this.hasFlag(this.ExchangeFoldersRights[path], ExchangeReportProfileFolderRight.LimitedDelete) && !this.hasDelete(path);
  }



}

import { Component, OnInit, Input, ViewContainerRef, AfterViewInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StringHelper } from '../../../../helpers/string-helper';
import { ContainerDirective } from '../../../../directives/container-directive';

@Component({
  selector: 'i2a-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit, AfterViewInit {
  @Input() public title: string;
  @Input() bodyTemplate;
  @Input() headerTemplate;
  @Input() public question: string;
  @Input() public information: string;
  @Input() public cancel: string;
  @Input() public ok: string;
  @Input() public buttonOkClass: string;
  @Input() public activateFullScreen: boolean
  @Input() public disableButtons: boolean

  public btnOKClasses: string;

  @ViewChild('body', { static: true, read: ContainerDirective }) bodyContainer: ContainerDirective;
  @ViewChild('header', { static: true, read: ContainerDirective }) headerContainer: ContainerDirective;

  constructor(
    public modal: NgbActiveModal,
    private viewContainerRef: ViewContainerRef
  ) { 
  }

  ngOnInit() {
    this.btnOKClasses = 'btn ml-2 ';
    if (!StringHelper.isNullOrWhitespace(this.buttonOkClass)) {
      this.btnOKClasses = this.btnOKClasses + this.buttonOkClass
    } else
    {
      this.btnOKClasses = this.btnOKClasses + 'btn-danger';
    }
    if (this.bodyTemplate && this.bodyContainer) {
      this.bodyContainer.viewContainerRef.createEmbeddedView(this.bodyTemplate);
    }
    if (this.headerTemplate && this.headerContainer) {
      this.headerContainer.viewContainerRef.createEmbeddedView(this.headerTemplate);
    }
  }

  ngAfterViewInit() {
  }

}

<i2a-profile-card [id]="id" [title]="title" (onWarnClick)="warnClick($event)" [hasAlert]="hasAlert"
  [hasAlertRecentlyClosed]="hasAlertRecentlyClosed" [warningLevel]="warningLevel" [viewMoreDetails]="true"
  (onViewMoreDetails)="openDetails($event)">

  <ng-container picto>
    <i2a-badge-icon>
      <ng-container icon>
        <fa-layers [fixedWidth]="true">
          <fa-icon [icon]="['fal', 'envelope']" transform="left-5"></fa-icon>
          <fa-icon [icon]="['fal', 'arrow-from-left']" transform="right-9"></fa-icon>
        </fa-layers>
      </ng-container>
    </i2a-badge-icon>
  </ng-container>
</i2a-profile-card>

<ng-template #viewDetailTemplate>
  <i2a-modal-profile-header-title>
    <ng-container picto>
      <i2a-badge-icon>
        <ng-container icon>
          <fa-layers [fixedWidth]="true">
            <fa-icon [icon]="['fal', 'envelope']" transform="left-5"></fa-icon>
            <fa-icon [icon]="['fal', 'arrow-from-left']" transform="right-9"></fa-icon>
          </fa-layers>
        </ng-container>
      </i2a-badge-icon>
    </ng-container>
    <ng-container title>
      <span [title]="title">{{title}}</span>
    </ng-container>
  </i2a-modal-profile-header-title>

  <div class="container p-0">
    <div class="row no-gutters">
      <div class="col m-2">
        <ul>
          <li *ngFor="let line of description">{{line}}<br></li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>
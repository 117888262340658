import { Component, OnInit, Input, Inject, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { FileShareReportProfilePermissions, FileShareReportProfileAlertPermissionsModel, MicrosoftOfficeProfileProviderDatasourceService, FileShareReportProfilePermissionRights, FileShareReportProfile } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileSharePermissionModalComponent } from '../file-share-permission-modal/file-share-permission-modal.component';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { InformationModalComponent } from 'projects/web-user-profile/src/app/components/shared/modal/information-modal/information-modal.component';

@Component({
  selector: 'i2a-file-share-permission',
  templateUrl: './file-share-permission.component.html',
  styleUrls: ['./file-share-permission.component.scss']
})
export class FileSharePermissionComponent extends ProfileItemBaseClassComponent<FileShareReportProfile> implements OnInit, OnDestroy {
  @Input() public permissions: FileShareReportProfilePermissions;


  public title: string;
  public viewMoreDetails: boolean;
  public maxPermission: string;

  constructor(
    modalService: NgbModal,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
    this.viewMoreDetails = false;
    }

    ngOnInit() {
      super.ngOnInit();
      if (this.permissions) {
        var result = 0;
        this.permissions.rights.forEach((right: FileShareReportProfilePermissionRights) => {

            if (right.fullControl) {
              result = 5;
            } else if (right.modify && result < 5) {
              result = 4;
            } else if (right.execute && result < 4) {
              result = 3;
            } else if (right.write && result < 3) {
              result = 2;
            } else if (right.read && result < 2) {
              result = 1;
            }
          });

        switch (result) {
          case 1:
            this.maxPermission = this.i18Next.t("profile.provider.microsoft.fileShare.maxPermission", { "permission": "profile.provider.microsoft.fileShare.rights.read" });
            break;
          case 2:
            this.maxPermission = this.i18Next.t("profile.provider.microsoft.fileShare.maxPermission", { "permission": "profile.provider.microsoft.fileShare.rights.write" });
            break;
          case 3:
            this.maxPermission = this.i18Next.t("profile.provider.microsoft.fileShare.maxPermission", { "permission": "profile.provider.microsoft.fileShare.rights.execute" });
            break;
          case 4:
            this.maxPermission = this.i18Next.t("profile.provider.microsoft.fileShare.maxPermission", { "permission": "profile.provider.microsoft.fileShare.rights.modify" });
            break;
          case 5:
            this.maxPermission = this.i18Next.t("profile.provider.microsoft.fileShare.maxPermission", { "permission": "profile.provider.microsoft.fileShare.rights.fullControl" });
            break;
          case 0:
          default:
            this.maxPermission = this.i18Next.t("profile.provider.microsoft.fileShare.maxPermission", { "permission": "profile.provider.microsoft.fileShare.rights.unknown" });
            break;
        }
      }
    }

  setItems() {
    if (this.permissions && this.alerts) {
      super.setItems();
      if (!this.permissions.isNameKey) {
        this.title = this.permissions.name;
      }
      else {
        this.title = this.i18Next.t(this.permissions.name);
      }

      this.hasAlert = this.hasAlertInAlerts(false);
      this.hasAlertRecentlyClosed = this.hasAlertInAlerts(true);

      this.viewMoreDetails = this.permissions != null && this.permissions.rights.length > 0

      this.id = `${this.componentId}-${this.providerInstanceResource.providerTypeId}-${this.providerInstanceResource.providerInstanceResourceId}-${this.title.replaceAll(' ', '-')}-FileSharePermission`;
    }
  }

  private hasAlertInAlerts(done: boolean): boolean {
    return AlertReasonHelper.alertsContainsDelegate(this.alerts, this.permissions, done);
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(FileSharePermissionModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.permissions = this.permissions;

    modalRef.result.then((model: FileShareReportProfileAlertPermissionsModel) => {
      this.initAlertModel(model);
      model.permissions = this.permissions;
      model.providerInstanceResource = this.providerInstanceResource;
      this.subscriptions.add(this.microsoftDatasource.alertFileSharePermissions(this.profile.providerInstanceId, this.profile.userId, model).subscribe(model => {
        this.setAlertProcessed(model);
      }));
    }).catch((error) => {
      // console.log(error);
    });
  }

  @ViewChild('viewDetailTemplate') viewDetailTemplate: TemplateRef<any>;

  openDetails(event): void {
    const modalRef = this.modalService.open(InformationModalComponent, { windowClass: 'id-modal', centered: true, size: 'lg' });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.title = this.i18Next.t("modal.detail.title");
    modalRef.componentInstance.bodyTemplate = this.viewDetailTemplate;
    modalRef.componentInstance.ok = this.i18Next.t("button.close");
    modalRef.result.then((model: any) => {
      if (model == true) {
        this.warnClick(null);
      }
    }, (reason) => {
      //Dismiss
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

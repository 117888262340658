import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BaseProviderInstanceResourceModel, ProviderInstanceResourcesDatasourceService, ProviderInstanceResourceSummaryModel } from '@i2a/web-api-client';
import { Subscription } from 'rxjs';
import { ProviderHelper } from '../../../helpers/provider-helper';

@Component({
  selector: 'i2a-resource-name',
  templateUrl: './resource-name.component.html',
  styleUrls: ['./resource-name.component.scss']
})
export class ResourceNameComponent implements OnInit, OnDestroy {
  protected subscriptions = new Subscription();
  @Input() public providerId: string;
  @Input() public providerTypeId: string;
  @Input() public providerInstanceId: string;
  @Input() public providerInstanceResourceId: string;
  @Input() public providerInstanceResource: BaseProviderInstanceResourceModel;

  public resource: ProviderInstanceResourceSummaryModel;

  constructor(    
    private providerInstanceResourcesDatasource: ProviderInstanceResourcesDatasourceService
    ) { }

  ngOnInit() {
    if (this.providerId && this.providerTypeId && this.providerInstanceId && this.providerInstanceResourceId) {
      this.loadResource(this.providerId, this.providerTypeId, this.providerInstanceId, this.providerInstanceResourceId);
    }
    else if (this.providerInstanceResource) {
      this.loadResource(this.providerInstanceResource.providerId, this.providerInstanceResource.providerTypeId, this.providerInstanceResource.providerInstanceId, this.providerInstanceResource.providerInstanceResourceId);
    }
  }

  private loadResource(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string) {
    this.subscriptions.add(
      this.providerInstanceResourcesDatasource.loadCollectedResourceSummary(providerId, providerTypeId, providerInstanceId, providerInstanceResourceId, true)
        .subscribe(resource => {
          this.resource = resource;
        }));
  }

  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}

<i2a-profile-card [id]="id" [title]="title" [viewMoreDetails]="viewMoreDetails"
  (onViewMoreDetails)="openDetails($event)" (onWarnClick)="warnClick($event)" [hasAlert]="hasAlert"
  [hasAlertRecentlyClosed]="hasAlertRecentlyClosed" [warningLevel]="warningLevel">

  <ng-container picto>
    <i2a-delegate-photo [isAnonymous]="permissions.logonUserId == 'Anonymous'"
      [isDefault]="permissions.logonUserId == 'Default'" [isApplication]="false"
      [pir]="permissions.providerInstanceResource" [piu]="permissions.providerInstanceUser" [isExternalUser]="false">
    </i2a-delegate-photo>
  </ng-container>

  <ng-container subTitle>
    <i2a-exchange-permission-rights [displayTitle]="false" [permissions]="permissions"></i2a-exchange-permission-rights>
  </ng-container>

  <ng-container content>
    <div class="container p-0 exchange-permission">
      <div class="row no-gutters">
        <div class="col m-2">
          <p class="warn-account mb-0" *ngIf="permissions.isNameKey">
            {{ ("profile.provider.microsoft.exchange.warnAccount." + permissions.name)  | i18next  }}
          </p>
        </div>
      </div>
    </div>
  </ng-container>

</i2a-profile-card>

<ng-template #viewDetailTemplate>
  <i2a-modal-profile-header-title>
    <ng-container picto>
      <i2a-delegate-photo [isAnonymous]="permissions.logonUserId == 'Anonymous'"
        [isDefault]="permissions.logonUserId == 'Default'" [isApplication]="false"
        [pir]="permissions.providerInstanceResource" [piu]="permissions.providerInstanceUser" [isExternalUser]="false">
      </i2a-delegate-photo>
    </ng-container>
    <ng-container title>
      <span [title]="title">{{title}}</span>
    </ng-container>
  </i2a-modal-profile-header-title>

  <div class="ml-2 mb-3">
    <i2a-exchange-permission-rights [permissions]="permissions"></i2a-exchange-permission-rights><br>
  </div>
  <i2a-exchange-permission-detail [ExchangeFoldersRights]="permissions.exchangeFoldersRights">
  </i2a-exchange-permission-detail>
</ng-template>
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

import { I18NextModule, I18NEXT_SERVICE } from 'angular-i18next';

import { AppComponent } from './app.component';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProfileComponent } from './pages/profile/profile.component';

import { NotFoundComponent } from './pages/not-found/not-found.component';
import { TenantsComponent } from './pages/tenants/tenants.component';
import { WebCoreModule, ToasterHttpErrorInterceptor, AuthenticationService, AuthenticationHttpInterceptor, CachingInterceptor, RequestCachingService, I2A_DISPLAY_ERROR_MESSAGE, I2A_CONFIG_SERVICE } from '@i2a/web-core';
import { environment } from '../environments/environment';
import { WebApiClientModule, Constants } from '@i2a/web-api-client';
import { SessionService } from './services/session-service';
import { NavBarComponent } from './components/shared/page/nav-bar/nav-bar.component';
import { ApiEndPointResolver } from './resolvers/api-end-point.resolver';
import { CommonModule } from '@angular/common';
import { TenantListComponent } from './pages/tenant-list/tenant-list.component';
import { ConnectedAccountResolver } from './resolvers/connected-account.resolver';
import { TenantCodeResolver } from './resolvers/tenant-code.resolver';
import { UserPhotoComponent } from './components/users/user-photo/user-photo.component';
import { OriginComponent } from './components/profile/user/origin/origin.component';
import { AccessModeComponent } from './components/profile/user/access-mode/access-mode.component';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faChevronLeft, faChevronRight, faMapMarked, faGlobe, faMobile, faChevronUp, faChevronDown, faChevronDoubleUp, faChevronDoubleDown, faEnvelope,
  faCalendarAlt, faFolder, faMapMarkerAlt, faBell, faArrowFromLeft, faCheck, faTimes, faLock, faFilePdf,
  faCogs, faSignOut, faIdCard, faHome, faHomeAlt, faFileExcel, faKey, faUserShield, faBuilding,
  faSync, faUndo, faExclamationTriangle, faUserSecret, faUserCog, faServer, faShield, faRobot, faIdCardAlt,
  faCommentAlt, faCopy, faCompressAlt, faExpandAlt, faUserClock, faExternalLink as faExternalLinkLight, faUser as faUserLight,
  faUsers as faUsersLight, faExternalLinkSquare, faUsersClass, faClock, faList,
  faFileWord, faFilePowerpoint, faFileImage, faFileMusic, faFileVideo, faFileArchive, faFile, faFileAlt, faFileCode,
  faPen, faTrashAlt, faPlusCircle, faDownload, faInfoSquare, faArrowAltUp
} from '@fortawesome/pro-light-svg-icons';
import { faUser, faUsers, faInfo, faExclamation, faQuestion, faCheckCircle, faQuestionCircle, faTimesCircle, faEye, faPencilAlt, 
  faFilter, faShareAlt, faUsersCog, faSlash, faSpinner, faChevronUp as faChevronUpSolid, faChevronDown as faChevronDownSolid, } from '@fortawesome/pro-solid-svg-icons';
import { faShieldAlt, faExternalLink, faGlobe as globeRegular, faBriefcase, faCheckCircle as regularfaCheckCircle,
   faExclamationTriangle as regularfaExclamationTriangle, faSirenOn, faLockAlt } from '@fortawesome/pro-regular-svg-icons';
import { faList as duotoneFaList, faBooks  } from '@fortawesome/pro-duotone-svg-icons';
import { DeviceComponent } from './components/profile/user/device/device.component';
import { ProviderInstanceProfileComponent } from './pages/provider-instance-profile/provider-instance-profile.component';
import { MicrosoftProfileComponent } from './components/profile/providers/microsoft/microsoft-profile/microsoft-profile.component';
import { ExchangeDelegateComponent } from './components/profile/providers/microsoft/exchange/exchange-delegate/exchange-delegate.component';
import { ResourcePhotoComponent } from './components/resources/resource-photo/resource-photo.component';
import { OneDriveDelegateComponent } from './components/profile/providers/microsoft/one-drive/one-drive-delegate/one-drive-delegate.component';
import { ExchangePermissionComponent } from './components/profile/providers/microsoft/exchange/exchange-permission/exchange-permission.component';
import { ProfileCardComponent } from './components/profile/shared/profile-card/profile-card.component';
import { ExchangeInboxRuleComponent } from './components/profile/providers/microsoft/exchange/exchange-inbox-rule/exchange-inbox-rule.component';
import { ToastrService } from 'ngx-toastr';
import { ExchangeDelegateModalComponent } from './components/profile/providers/microsoft/exchange/exchange-delegate-modal/exchange-delegate-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExchangePermissionModalComponent } from './components/profile/providers/microsoft/exchange/exchange-permission-modal/exchange-permission-modal.component';
import { ExchangeInboxruleModalComponent } from './components/profile/providers/microsoft/exchange/exchange-inboxrule-modal/exchange-inboxrule-modal.component';
import { UserListComponent } from './components/users/user-list/user-list.component';
import { ProfileValidationComponent } from './components/profile/shared/profile-validation/profile-validation.component';
import { CriticalityToIconComponent } from './components/shared/criticality-to-icon/criticality-to-icon.component';
import { ProfilePageComponent } from './components/profile/shared/profile-page/profile-page.component';
import { AzureAdPermissionGrantsComponent } from './components/profile/providers/microsoft/azure-ad/azure-ad-permission-grants/azure-ad-permission-grants.component';
import { AzureAdPermissionGrantsModalComponent } from './components/profile/providers/microsoft/azure-ad/azure-ad-permission-grants-modal/azure-ad-permission-grants-modal.component';
import { ProfileAlertBaseModalComponent } from './components/profile/shared/modals/profile-alert-base-modal/profile-alert-base-modal.component';
import { ProfileItemBaseClassComponent } from './components/profile/shared/modals/profile-item-base-class-component';
import { ExchangeRuleConfigComponent } from './components/profile/providers/microsoft/exchange/exchange-rule-config/exchange-rule-config.component';
import { ExchangeRuleConfigModalComponent } from './components/profile/providers/microsoft/exchange/exchange-rule-config-modal/exchange-rule-config-modal.component';
import { ProfileBaseClassComponent } from './components/profile/shared/profile-base-class-component';
import { DeviceModalComponent } from './components/profile/user/device-modal/device-modal.component';
import { OriginModalComponent } from './components/profile/user/origin-modal/origin-modal.component';
import { AccessModeModalComponent } from './components/profile/user/access-mode-modal/access-mode-modal.component';
import { SharePointDelegateComponent } from './components/profile/providers/microsoft/share-point/share-point-delegate/share-point-delegate.component';
import { SharePointDelegateModalComponent } from './components/profile/providers/microsoft/share-point/share-point-delegate-modal/share-point-delegate-modal.component';
import { SharePointO365GroupComponent } from './components/profile/providers/microsoft/share-point/share-point-o365-group/share-point-o365-group.component';
import { SharePointO365GroupModalComponent } from './components/profile/providers/microsoft/share-point/share-point-o365-group-modal/share-point-o365-group-modal.component';
import { SharePointGroupComponent } from './components/profile/providers/microsoft/share-point/share-point-group/share-point-group.component';
import { SharePointGroupModalComponent } from './components/profile/providers/microsoft/share-point/share-point-group-modal/share-point-group-modal.component';
import { OneDriveInstantMessagingComponent } from './components/profile/providers/microsoft/one-drive/one-drive-instant-messaging/one-drive-instant-messaging.component';
import { BaseOneDriveDelegateModalComponent } from './components/profile/providers/microsoft/one-drive/base-one-drive-delegate-modal/base-one-drive-delegate-modal.component';
import { BaseOneDriveDelegateComponent } from './components/profile/providers/microsoft/one-drive/base-one-drive-delegate/base-one-drive-delegate.component';
import { ResourceNodataComponent } from './components/profile/shared/resource-nodata/resource-nodata.component';
import { ExchangeUserDeviceComponent } from './components/profile/providers/microsoft/exchange/exchange-user-device/exchange-user-device.component';
import { ZimbraProfileComponent } from './components/profile/providers/zimbra/zimbra-profile/zimbra-profile.component';
import { ZimbraMailingDelegateComponent } from './components/profile/providers/zimbra/mailing/zimbra-mailing-delegate/zimbra-mailing-delegate.component';
import { ZimbraMailingDelegateModalComponent } from './components/profile/providers/zimbra/mailing/zimbra-mailing-delegate-modal/zimbra-mailing-delegate-modal.component';
import { ZimbraMailingPermissionComponent } from './components/profile/providers/zimbra/mailing/zimbra-mailing-permission/zimbra-mailing-permission.component';
import { ZimbraMailingPermissionModalComponent } from './components/profile/providers/zimbra/mailing/zimbra-mailing-permission-modal/zimbra-mailing-permission-modal.component';
import { ZimbraFilterRuleComponent } from './components/profile/providers/zimbra/mailing/zimbra-filter-rule/zimbra-filter-rule.component';
import { ZimbraFilterRuleModalComponent } from './components/profile/providers/zimbra/mailing/zimbra-filter-rule-modal/zimbra-filter-rule-modal.component';
import { ExchangePermissionDetailComponent } from './components/profile/providers/microsoft/exchange/exchange-permission-detail/exchange-permission-detail.component';
import { ExchangePermissionRightsComponent } from './components/profile/providers/microsoft/exchange/exchange-permission-rights/exchange-permission-rights.component';
import { MailingPermissionRightsComponent } from './components/profile/providers/zimbra/mailing/mailing-permission-rights/mailing-permission-rights.component';
import { OneDriveExternalDelegatesComponent } from './components/profile/providers/microsoft/one-drive/one-drive-external-delegates/one-drive-external-delegates.component';
import { OneDriveExternalDelegatesModalComponent } from './components/profile/providers/microsoft/one-drive/one-drive-external-delegates-modal/one-drive-external-delegates-modal.component';
import { ShortTitleBarComponent } from './components/shared/short-title-bar/short-title-bar.component';
import { StartTourComponent } from './components/shared/start-tour/start-tour.component';
import { SharePointExternalDelegatesComponent } from './components/profile/providers/microsoft/share-point/share-point-external-delegates/share-point-external-delegates.component';
import { SharePointExternalDelegatesModalComponent } from './components/profile/providers/microsoft/share-point/share-point-external-delegates-modal/share-point-external-delegates-modal.component';
import { ExchangeDelegateModuleComponent } from './components/profile/providers/microsoft/exchange/exchange-delegate-module/exchange-delegate-module.component';
import { MyProfileTemplateComponent } from './components/profile/shared/my-profile-template/my-profile-template.component';
import { ExchangePermissionModuleComponent } from './components/profile/providers/microsoft/exchange/exchange-permission-module/exchange-permission-module.component';
import { ExchangeRuleModuleComponent } from './components/profile/providers/microsoft/exchange/exchange-rule-module/exchange-rule-module.component';
import { ExchangeRulesComputedDelegateModuleComponent } from './components/profile/providers/microsoft/exchange/exchange-rules-computed-delegate-module/exchange-rules-computed-delegate-module.component';
import { ExchangeNodataModuleComponent } from './components/profile/providers/microsoft/exchange/exchange-nodata-module/exchange-nodata-module.component';
import { AzureAdPermissionGrantsModuleComponent } from './components/profile/providers/microsoft/azure-ad/azure-ad-permission-grants-module/azure-ad-permission-grants-module.component';
import { AzureADNodataContainerModuleComponent } from './components/profile/providers/microsoft/azure-ad/azure-ad-nodata-container-module/azure-ad-nodata-container-module.component';
import { AzureADRulesComputedDelegateModuleComponent } from './components/profile/providers/microsoft/azure-ad/azure-ad-rules-computed-delegate-module/azure-ad-rules-computed-delegate-module.component';
import { SharePointDelegateModuleComponent } from './components/profile/providers/microsoft/share-point/share-point-delegate-module/share-point-delegate-module.component';
import { SharePointNodataModuleComponent } from './components/profile/providers/microsoft/share-point/share-point-nodata-module/share-point-nodata-module.component';
import { SharePointO365GroupModuleComponent } from './components/profile/providers/microsoft/share-point/share-point-o365-group-module/share-point-o365-group-module.component';
import { SharePointRulesComputedDelegateModuleComponent } from './components/profile/providers/microsoft/share-point/share-point-rules-computed-delegate-module/share-point-rules-computed-delegate-module.component';
import { OneDriveDelegateModuleComponent } from './components/profile/providers/microsoft/one-drive/one-drive-delegate-module/one-drive-delegate-module.component';
import { OneDriveNodataModuleComponent } from './components/profile/providers/microsoft/one-drive/one-drive-nodata-module/one-drive-nodata-module.component';
import { OneDriveInstantMessagingModuleComponent } from './components/profile/providers/microsoft/one-drive/one-drive-instant-messaging-module/one-drive-instant-messaging-module.component';
import { OneDriveRulesComputedDelegateModuleComponent } from './components/profile/providers/microsoft/one-drive/one-drive-rules-computed-delegate-module/one-drive-rules-computed-delegate-module.component';
import { ZimbraMailingDelegateModuleComponent } from './components/profile/providers/zimbra/mailing/zimbra-mailing-delegate-module/zimbra-mailing-delegate-module.component';
import { ZimbraMailingNodataModuleComponent } from './components/profile/providers/zimbra/mailing/zimbra-mailing-nodata-module/zimbra-mailing-nodata-module.component';
import { ZimbraMailingPermissionModuleComponent } from './components/profile/providers/zimbra/mailing/zimbra-mailing-permission-module/zimbra-mailing-permission-module.component';
import { ZimbraMailingFilterRuleModuleComponent } from './components/profile/providers/zimbra/mailing/zimbra-mailing-filter-rule-module/zimbra-mailing-filter-rule-module.component';
import { ZimbraMailingRulesComputedDelegateModuleComponent } from './components/profile/providers/zimbra/mailing/zimbra-mailing-rules-computed-delegate-module/zimbra-mailing-rules-computed-delegate-module.component';
import { ResourceContainerComponent } from './components/profile/shared/resource-container/resource-container.component';
import { UserContainerComponent } from './components/profile/user/user-container/user-container.component';
import { TemplateContainerComponent } from './components/profile/shared/template-container/template-container.component';
import { ApplicationContainerComponent } from './components/profile/shared/application-container/application-container.component';
import { SharePointApplicationContainerComponent } from './components/profile/providers/microsoft/share-point/share-point-application-container/share-point-application-container.component';
import { ProviderContainerComponent } from './components/profile/shared/provider-container/provider-container.component';
import { DisplayAlertsReasonsModalComponent } from './components/alerts/display-alerts-reasons-modal/display-alerts-reasons-modal.component';
import { UserNoDataComponent } from './components/profile/user/user-nodata/user-nodata.component';
import { NoCollectedResourcesComponent } from './components/profile/shared/no-collected-resources/no-collected-resources.component';
import { ErrorPendingCollectComponent } from './components/profile/shared/error-pending-collect/error-pending-collect.component';
import { UsualCountriesComponent } from './components/profile/user/usual-countries/usual-countries.component';
import { DelegateConfigurationBoxComponent } from './components/profile/shared/delegate-configuration-box/delegate-configuration-box.component';
import { RuleHumanExpressionComponent } from './components/rules/rule-human-expression/rule-human-expression.component';
import { RulesComputedDelegateModuleComponent } from './components/profile/rules-computed-delegate/rules-computed-delegate-module/rules-computed-delegate-module.component';
import { RulesComputedDelegateComponent } from './components/profile/rules-computed-delegate/rules-computed-delegate/rules-computed-delegate.component';
import { RulesComputedDelegateDetailsModalComponent } from './components/profile/rules-computed-delegate/rules-computed-delegate-details-modal/rules-computed-delegate-details-modal.component';
import { DelegatePhotoComponent } from './components/profile/shared/delegate-photo/delegate-photo.component';
import { PiuPhotoComponent } from './components/piu/piu-photo/piu-photo.component';
import { RulesComputedDelegateModalComponent } from './components/profile/rules-computed-delegate/rules-computed-delegate-modal/rules-computed-delegate-modal.component';
import { RulesComputedDelegateUserDeviceComponent } from './components/profile/rules-computed-delegate/rules-computed-delegate-user-device/rules-computed-delegate-user-device.component';
import { ProfileInformationComponent } from './components/profile/shared/profile-information/profile-information.component';
import { ResourceNameComponent } from './components/resources/resource-name/resource-name.component';
import { SharePointSharingsComponent } from './components/profile/providers/microsoft/share-point/share-point-sharings/share-point-sharings.component';
import { ResourceLastAccessComponent } from './components/profile/shared/resource-last-access/resource-last-access.component';
import { DelegateDeviceComponent } from './components/profile/shared/delegate-device/delegate-device.component';
import { DelegateFoldersComponent } from './components/profile/shared/delegate-folders/delegate-folders.component';
import { ExternalDelegatesComponent } from './components/profile/shared/external-delegates/external-delegates.component';
import { DeviceDetectorService } from 'ngx-device-detector';

import { BadgeIconComponent } from './components/shared/badge-icon/badge-icon.component';
import { CollapseContainerComponent } from './components/shared/collapse-container/collapse-container.component';
import { InputCheckboxComponent } from './components/shared/form/input/input-checkbox/input-checkbox.component';
import { MessageComponent } from './components/shared/message/message.component';
import { BaseModalComponent } from './components/shared/modal/base-modal/base-modal.component';
import { HeaderComponent } from './components/shared/page/header/header.component';
import { PageContainerComponent } from './components/shared/page/page-container/page-container.component';
import { PageContentComponent } from './components/shared/page/page-content/page-content.component';
import { SpinnerComponent } from './components/shared/spinner/spinner.component';
import { SubTitleComponent } from './components/shared/sub-title/sub-title.component';
import { TitleBarComponent } from './components/shared/title-bar/title-bar.component';
import { NavBarBaseComponent } from './components/shared/page/nav-bar-base/nav-bar-base.component';
import { ConfirmModalComponent } from './components/shared/modal/confirm-modal/confirm-modal.component';
import { ContainerDirective } from './directives/container-directive';
import { InformationModalComponent } from './components/shared/modal/information-modal/information-modal.component';
import { ResourceActionsComponent } from './components/profile/shared/resource-actions/resource-actions.component';
import { ResourceNotBelongToMeModalComponent } from './components/profile/shared/resource-not-belong-to-me-modal/resource-not-belong-to-me-modal.component';
import { EditUserModalComponent } from './components/users/edit-user-modal/edit-user-modal.component';
import { FileShareNodataModuleComponent } from './components/profile/providers/microsoft/file-share/file-share-nodata-module/file-share-nodata-module.component';

import { SharePointPermissionComponent } from './components/profile/providers/microsoft/share-point/share-point-permission/share-point-permission.component';
import { SharePointPermissionModalComponent } from './components/profile/providers/microsoft/share-point/share-point-permission-modal/share-point-permission-modal.component';
import { SharePointPermissionDetailComponent } from './components/profile/providers/microsoft/share-point/share-point-permission-detail/share-point-permission-detail.component';
import { SharePointPermissionModuleComponent } from './components/profile/providers/microsoft/share-point/share-point-permission-module/share-point-permission-module.component';
import { SharePointExternalPermissionsComponent } from './components/profile/providers/microsoft/share-point/share-point-external-permissions/share-point-external-permissions.component';
import { SharePointExternalPermissionsModalComponent } from './components/profile/providers/microsoft/share-point/share-point-external-permissions-modal/share-point-external-permissions-modal.component';
import { SharePointExternalPermissionsDetailComponent } from './components/profile/providers/microsoft/share-point/share-point-external-permissions-detail/share-point-external-permissions-detail.component';
import { SharePointPermissionLegendComponent } from './components/profile/providers/microsoft/share-point/share-point-permission-legend/share-point-permission-legend.component';
import { SharePointAccessPermissionFormComponent } from './components/profile/providers/microsoft/share-point/share-point-access-permission-form/share-point-access-permission-form.component';
import { SharePointPermissionFormComponent } from './components/profile/providers/microsoft/share-point/share-point-permission-form/share-point-permission-form.component';
import { SharePointPermissionsRolesComponent } from './components/profile/providers/microsoft/share-point/share-point-permissions-roles/share-point-permissions-roles.component';
import { ProfileValidatedComponent } from './components/profile/shared/profile-validated/profile-validated.component';
import { ConfigService } from '@i2a/web-core';
import { BaseFileShareDelegateModalComponent } from './components/profile/providers/microsoft/file-share/base-file-share-delegate-modal/base-file-share-delegate-modal.component';
import { BaseFileShareDelegateComponent } from './components/profile/providers/microsoft/file-share/base-file-share-delegate/base-file-share-delegate.component';
import { FileShareDelegateModuleComponent } from './components/profile/providers/microsoft/file-share/file-share-delegate-module/file-share-delegate-module.component';
import { FileShareDelegateComponent } from './components/profile/providers/microsoft/file-share/file-share-delegate/file-share-delegate.component';
import { InstanceSwitcherComponent } from './components/profile/shared/instance-switcher/instance-switcher.component';
import { FileSharePermissionComponent } from './components/profile/providers/microsoft/file-share/file-share-permission/file-share-permission.component';
import { FileSharePermissionDetailComponent } from './components/profile/providers/microsoft/file-share/file-share-permission-detail/file-share-permission-detail.component';
import { FileSharePermissionModalComponent } from './components/profile/providers/microsoft/file-share/file-share-permission-modal/file-share-permission-modal.component';
import { FileSharePermissionLegendComponent } from './components/profile/providers/microsoft/file-share/file-share-permission-legend/file-share-permission-legend.component';
import { FileSharePermissionModuleComponent } from './components/profile/providers/microsoft/file-share/file-share-permission-module/file-share-permission-module.component';
import { SharePointAdministratorsComponent } from './components/profile/providers/microsoft/share-point/share-point-administrators/share-point-administrators.component';
import { SharePointAdministratorsModalComponent } from './components/profile/providers/microsoft/share-point/share-point-administrators-modal/share-point-administrators-modal.component';
import { ResourceActionsModuleComponent } from './components/profile/shared/resource-actions-module/resource-actions-module.component';
import { GetStartedComponent } from './components/profile/shared/get-started/get-started.component';
import { SharePointSubsiteNodeComponent } from './components/profile/providers/microsoft/share-point/share-point-subsite-node/share-point-subsite-node.component';
import { SharePointActionsComponent } from './components/profile/providers/microsoft/share-point/share-point-actions/share-point-actions.component';
import { SharePointActionsModuleComponent } from './components/profile/providers/microsoft/share-point/share-point-actions-module/share-point-actions-module.component';
import { SharePointVisibilityComponent } from './components/profile/providers/microsoft/share-point/share-point-visibility/share-point-visibility.component';
import { SharePointVisibilityModalComponent } from './components/profile/providers/microsoft/share-point/share-point-visibility-modal/share-point-visibility-modal.component';
import { ModalProfileHeaderTitleComponent } from './components/profile/shared/modals/modal-profile-header-title/modal-profile-header-title.component';
import { WarningContainerComponent } from './components/profile/shared/warning-container/warning-container.component';
import { WarningNotificationComponent } from './components/profile/shared/warning-notification/warning-notification.component';
import { NavBarWarningCounterComponent } from './components/profile/shared/nav-bar-warning-counter/nav-bar-warning-counter.component';
import { SafeHtmlPipe } from './pipes/safeHtmlPipe';
import { FilterPipe } from './pipes/filter.pipe';
import { SharePointResourceHasSensitivityLabelComponent } from './components/profile/providers/microsoft/share-point/share-point-resource-has-sensitivity-label/share-point-resource-has-sensitivity-label.component';
import { SharePointSiteContainerComponent } from './components/profile/providers/microsoft/share-point/share-point-site-container/share-point-site-container.component';
import { DateFormatPipe } from './pipes/date-format.pipe';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ProfileComponent,
    NavBarComponent,
    NotFoundComponent,
    TenantsComponent,
    TenantListComponent,
    UserPhotoComponent,
    OriginComponent,
    AccessModeComponent,
    DeviceComponent,
    ProviderInstanceProfileComponent,
    MicrosoftProfileComponent,
    ExchangeDelegateComponent,
    ResourcePhotoComponent,
    OneDriveDelegateComponent,
    ExchangePermissionComponent,
    ProfileCardComponent,
    ExchangeInboxRuleComponent,
    ExchangeDelegateModalComponent,
    ExchangePermissionModalComponent,
    ExchangeInboxruleModalComponent,
    UserListComponent,
    ProfileValidationComponent,
    CriticalityToIconComponent,
    ProfilePageComponent,
    AzureAdPermissionGrantsComponent,
    AzureAdPermissionGrantsModalComponent,
    ProfileAlertBaseModalComponent,
    ProfileBaseClassComponent,
    ProfileItemBaseClassComponent,
    ExchangeRuleConfigComponent,
    ExchangeRuleConfigModalComponent,
    DeviceModalComponent,
    OriginModalComponent,
    AccessModeModalComponent,
    SharePointDelegateComponent,
    SharePointDelegateModalComponent,
    SharePointO365GroupComponent,
    SharePointO365GroupModalComponent,
    SharePointGroupComponent,
    SharePointGroupModalComponent,
    SharePointExternalDelegatesComponent,
    SharePointExternalDelegatesModalComponent,
    OneDriveInstantMessagingComponent,
    BaseOneDriveDelegateModalComponent,
    BaseOneDriveDelegateComponent,
    BaseFileShareDelegateModalComponent,
    BaseFileShareDelegateComponent,
    ResourceNodataComponent,
    ExchangeUserDeviceComponent,
    ZimbraProfileComponent,
    ZimbraMailingDelegateComponent,
    ZimbraMailingDelegateModalComponent,
    ZimbraMailingPermissionComponent,
    ZimbraMailingPermissionModalComponent,
    ZimbraFilterRuleComponent,
    ZimbraFilterRuleModalComponent,
    ExchangePermissionDetailComponent,
    ExchangePermissionRightsComponent,
    MailingPermissionRightsComponent,
    OneDriveExternalDelegatesComponent,
    OneDriveExternalDelegatesModalComponent,
    ShortTitleBarComponent,
    StartTourComponent,
    MyProfileTemplateComponent,
    ExchangeDelegateModuleComponent,
    ExchangePermissionModuleComponent,
    ExchangeRuleModuleComponent,
    ExchangeNodataModuleComponent,
    ExchangeRulesComputedDelegateModuleComponent,
    AzureAdPermissionGrantsModuleComponent,
    AzureADNodataContainerModuleComponent,
    AzureADRulesComputedDelegateModuleComponent,
    SharePointDelegateModuleComponent,
    SharePointNodataModuleComponent,
    SharePointO365GroupModuleComponent,
    SharePointRulesComputedDelegateModuleComponent,
    OneDriveDelegateModuleComponent,
    OneDriveNodataModuleComponent,
    OneDriveInstantMessagingModuleComponent,
    OneDriveRulesComputedDelegateModuleComponent,
    ZimbraMailingDelegateModuleComponent,
    ZimbraMailingNodataModuleComponent,
    ZimbraMailingPermissionModuleComponent,
    ZimbraMailingFilterRuleModuleComponent,
    ZimbraMailingRulesComputedDelegateModuleComponent,
    TemplateContainerComponent,
    ResourceContainerComponent,
    UserContainerComponent,
    ApplicationContainerComponent, 
    SharePointApplicationContainerComponent,
    ProviderContainerComponent,
    DisplayAlertsReasonsModalComponent,
    UserNoDataComponent,
    NoCollectedResourcesComponent,
    ErrorPendingCollectComponent,
    UsualCountriesComponent,
    RulesComputedDelegateModuleComponent,
    RulesComputedDelegateComponent,
    DelegateConfigurationBoxComponent,
    RulesComputedDelegateDetailsModalComponent,
    RuleHumanExpressionComponent,
    RulesComputedDelegateModalComponent,
    RulesComputedDelegateUserDeviceComponent,
    DelegatePhotoComponent,
    PiuPhotoComponent,
    ProfileInformationComponent,
    ResourceNameComponent,
    SharePointSharingsComponent,
    ResourceLastAccessComponent,
    SharePointResourceHasSensitivityLabelComponent,
    DelegateDeviceComponent,
    DelegateFoldersComponent,
    ExternalDelegatesComponent,
    ContainerDirective,
    BadgeIconComponent,
    CollapseContainerComponent,
    InputCheckboxComponent,
    MessageComponent,
    BaseModalComponent,
    HeaderComponent,
    PageContainerComponent,
    PageContentComponent,
    SpinnerComponent,
    SubTitleComponent,
    TitleBarComponent,
    NavBarBaseComponent,
    ConfirmModalComponent,
    ResourceActionsComponent,
    ResourceNotBelongToMeModalComponent,
    InformationModalComponent,
    EditUserModalComponent,
    FileShareNodataModuleComponent,
    EditUserModalComponent,
    SharePointPermissionComponent,
    SharePointPermissionModalComponent,
    SharePointPermissionDetailComponent,
    SharePointPermissionModuleComponent,
    SharePointExternalPermissionsComponent,
    SharePointExternalPermissionsModalComponent,
    SharePointExternalPermissionsDetailComponent,
    SharePointPermissionLegendComponent,
    SharePointAccessPermissionFormComponent,
    SharePointPermissionFormComponent,
    SharePointPermissionsRolesComponent,
    ProfileValidatedComponent,
    FileShareDelegateModuleComponent,
    FileShareDelegateComponent,
    InstanceSwitcherComponent,
    FileSharePermissionComponent,
    FileSharePermissionDetailComponent,
    FileSharePermissionModalComponent,
    FileSharePermissionLegendComponent,
    FileSharePermissionModuleComponent,
    SharePointAdministratorsComponent,
    SharePointAdministratorsModalComponent,
    ResourceActionsModuleComponent,
    GetStartedComponent,
    SharePointSubsiteNodeComponent,
    SharePointActionsComponent,
    SharePointActionsModuleComponent,
    SharePointVisibilityComponent,
    SharePointVisibilityModalComponent,
    ModalProfileHeaderTitleComponent,
    WarningContainerComponent,
    WarningNotificationComponent,
    NavBarWarningCounterComponent,
    SafeHtmlPipe,
    FilterPipe,
    SharePointSiteContainerComponent,
    DateFormatPipe
  ],
  imports: [
    CommonModule,
    BrowserModule,
    WebCoreModule.forRoot(environment, false),
    AppRoutingModule,
    HttpClientModule,
    LoadingBarHttpClientModule,
    BrowserAnimationsModule,
    I18NextModule.forRoot(),
    WebApiClientModule,
    NgbModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule
  ],
  providers: [
    SessionService,
    TenantCodeResolver,
    ApiEndPointResolver,
    ConnectedAccountResolver,
    Constants,
    { provide: HTTP_INTERCEPTORS, useClass: ToasterHttpErrorInterceptor, deps: [ToastrService, I18NEXT_SERVICE, I2A_DISPLAY_ERROR_MESSAGE], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationHttpInterceptor, deps: [AuthenticationService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, deps: [RequestCachingService], multi: true },
    { provide: I2A_CONFIG_SERVICE, useClass: ConfigService },
    {
      provide: DeviceDetectorService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    //library.addIconPacks(fas);
    library.addIcons(
      faChevronLeft, faChevronRight, faChevronUp, faChevronDown, faChevronDoubleUp, faChevronDoubleDown, faChevronUpSolid, faChevronDownSolid, faMapMarked, faGlobe, faMobile, faEnvelope,
      faCalendarAlt, faFolder, faMapMarkerAlt, faBell, faArrowFromLeft, faCheck, faTimes, faLock, faFilePdf,
      faCogs, faSignOut, faIdCard, faHome, faHomeAlt, faUser, faUsers, faFileExcel, faKey, faUserShield, faShieldAlt,
      faBuilding, faSync, faUndo, faExclamationTriangle, faUserSecret, faInfo, faUserCog, faServer, faShield,
      faExclamation, faQuestion, faShieldAlt, faRobot, faIdCardAlt, faExternalLink, faCommentAlt, faCopy, faCheckCircle,
      faQuestionCircle, faTimesCircle, faEye, faCompressAlt, faExpandAlt, faUserClock, faExternalLinkSquare, faExternalLinkLight, faUserLight, faUsersClass, faClock,
      faList, faPencilAlt, faUsersLight,
      faFileWord, faFilePowerpoint, faFileImage, faFileMusic, faFileVideo, faFileArchive, faFile, faFileAlt, faFileCode,
      faPen, faTrashAlt, faPlusCircle, faFilter, faShareAlt, faUsersCog, faDownload, faBriefcase, globeRegular, faSlash,
      faSpinner, faInfoSquare, faArrowAltUp, regularfaCheckCircle, regularfaExclamationTriangle, faSirenOn,
      faLockAlt,
      duotoneFaList, faBooks);
  }
}

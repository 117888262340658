import { ReportProfileResourceBase } from '../../../profile/report-profile-resource-base';
import { ReportProfileRulesComputedDelegate } from '../../../profile/report-profile-rules-computed-delegate';
import { ZimbraMailingReportProfileDelegate } from '../../../zimbra/mailing/profile/zimbra-mailing-report-profile-delegate';
import { ZimbraMailingReportProfileFilterRule } from '../../../zimbra/mailing/profile/zimbra-mailing-report-profile-filter-rule';
import { ZimbraMailingReportProfilePermissions } from '../../../zimbra/mailing/profile/zimbra-mailing-report-profile-permissions';

export class ZimbraMailingReportProfile extends ReportProfileResourceBase<ReportProfileRulesComputedDelegate> {
  delegates: ZimbraMailingReportProfileDelegate[];
  permissions: ZimbraMailingReportProfilePermissions[];
  filterRules: ZimbraMailingReportProfileFilterRule[];
}

<div class="container no-gutters resource-last-access" *ngIf="resourceProfile.hasSensitivityLabel">
  <div class="row no-gutters info-container">
    <div class="col-sm-12 col-md-12 sensitivity-label">
      <span>
        <fa-icon [icon]="['far', 'lock-alt']" size="lg"></fa-icon>
      </span>
      <span>
        {{"profile.provider.microsoft.sharePoint.sensitivityLabelonResource" | i18next }}
      </span>
    </div>
  </div>
</div>
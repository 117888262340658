<a href="#" id="id"></a>
<div class="container p-0">
  <div class="row no-gutters">
    <div class="col">
      <div [ngClass]="{'p-0': true, 'profile-card': true, 'profile-card-red': hasAlert, 'profile-card-brown': hasAlertRecentlyClosed && !hasAlert, 'profile-card-warning-high-level': isWarningHigh, 'profile-card-warning-medium-level': isWarningMiddle, 'profile-card-warning-low-level': isWarningLow }">
        <div [ngClass]="{'profile-card-summary': true, 'no-profile-card-content' : noContent || contentContainer && contentContainer.childNodes.length == 0}">
          <div class="profile-card-picto">
            <ng-content select="[picto]"></ng-content>
          </div>
          <div class="profile-card-title" [title]="title">
            {{title}}
            <ng-container *ngIf="!title">&nbsp;</ng-container>
          </div>
          <div class="profile-card-subtitle">
            {{subTitle}}
            <ng-container *ngIf="!subTitle && subTitleContentContainer.childNodes.length == 0">
              <ng-content select="[subTitle]"></ng-content>&nbsp;
            </ng-container>
          </div>
          <div #subTitleContentContainer class="profile-card-subtitle-content">
            <ng-content select="[subTitleContent]"></ng-content>
          </div>
          <div *ngIf="hasAlert" class="profile-card-status profile-card-status-has-alert m-1" [title]="'profile.alertReportedHint' | i18next ">
            <fa-icon [icon]="['fal', 'exclamation-triangle']" size="lg"></fa-icon>
          </div>
          <div *ngIf="hasAlertRecentlyClosed && !hasAlert" class="profile-card-status profile-card-status-alert-recently-closed m-1" [title]="'profile.alertReportedDoneHint' | i18next ">
            <fa-icon [icon]="['fal', 'check']" size="lg"></fa-icon>
          </div>
        </div>
        <div>
          <div class="row no-gutters">
            <div #contentContainer class="col-12 profile-card-content">
              <ng-content select="[content]"></ng-content>
            </div>
            <ng-container *ngIf="loading">
              <div class="col-centered">
                <span> {{ 'profile.loading' | i18next }}</span>
                <br />
                <i2a-spinner></i2a-spinner>
              </div>
            </ng-container>
            <ng-container *ngIf="!loading">
              <div class="col-12 text-center pb-2 pt-1">
                <ng-content select="[buttons]"></ng-content>
                <button *ngIf="viewMoreDetails" (click)="viewMoreDetailsClick()" id="viewMoreDetails"
                  class="btn btn-outline-light btn-outline-gray btn-outline-details mr-1">
                  {{buttonVviewMoreDetailbuttonKey | i18next}}
                </button>
                <button *ngIf="viewWarnButton" (click)="warnClick()" id="warnClick"
                  class="btn btn-outline-light btn-outline-alert">
                  {{buttonOpenAlertModalKey | i18next}}
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

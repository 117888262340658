
export class GuidedTourModel {
  guidedTourId: string;
  userId: string;
  version: number;
  viewCount: number;
  completed: boolean;
  alreadySeen: boolean;
  numberOfSteps: number|null;
  lastStep: number|null;
  firstTimeStart: Date|null;
  firstTimeEnd: Date|null;
  lastTimeStart: Date|null;
  lastTimeEnd: Date|null;
}

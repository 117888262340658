import { Component, OnInit, Input, Inject } from '@angular/core';
import { UserReportProfileAlertOriginsModel, ReportProfileOriginModel, UserModel } from '@i2a/web-api-client';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { OriginWithAlert } from 'projects/web-user-profile/src/app/models/users/origin-with-alert';
import { OriginWithChecked } from 'projects/web-user-profile/src/app/models/users/origin-with-checked';
import { UsualCountryWithAlert } from 'projects/web-user-profile/src/app/models/users/usual-country-with-alert';
import { UsualCountryWithChecked } from 'projects/web-user-profile/src/app/models/users/usual-country-with-checked';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-origin-modal',
  templateUrl: './origin-modal.component.html',
  styleUrls: ['./origin-modal.component.scss']
})
export class OriginModalComponent implements OnInit {
  @Input() public origins: OriginWithAlert[];
  @Input() public usualCountries: UsualCountryWithAlert[];
  @Input() public user: UserModel;
  @Input() public disableManualRemediation:boolean;

  public selectedOrigins: OriginWithChecked[];
  public selectedUsualCountries: UsualCountryWithChecked[];

  public validation: UserReportProfileAlertOriginsModel;
  public canSend: boolean;
  public informationKey: string;

  constructor(
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService,
    public session: SessionService
  ) {
    this.canSend = false;
  }

  ngOnInit() {
    this.validation = new UserReportProfileAlertOriginsModel();
    this.validation.invalidOrigins = [];
    this.validation.invalidUsualCountries = [];
    this.selectedOrigins = [];
    this.selectedUsualCountries = [];
    if (this.origins) {
      this.informationKey = this.origins.length > 1 ? 'modal.reportAnomaly.checkItems' : 'modal.reportAnomaly.checkItem';

      this.origins.forEach(origin => {
        this.selectedOrigins.push({
          "name": origin.name,
          "checked": false,
          "image": origin.image,
          "model": origin.model
        });
      });
    }
    if (this.usualCountries) {
        this.usualCountries.forEach(origin => {
        this.selectedUsualCountries.push({
          "name": origin.name,
          "checked": false,
          "image": origin.image,
          "model": origin.model
        });
      });
      this.setCanSend();
    }
  }

  private setCanSend() {
    setTimeout(() => {
      this.canSend = this.validation.invalidOrigins.length > 0 || this.validation.invalidUsualCountries.length > 0;
    });
  }

  checked(isChecked: boolean, origin: any, isUsual: boolean) {
    if (isUsual) {
    this.checkedOrigins(isChecked, origin, this.validation.invalidUsualCountries);
    } else {
      this.checkedOrigins(isChecked, origin, this.validation.invalidOrigins);
    }
  }

  checkedOrigins(isChecked: boolean, origin: any, origins: any[]) {
    const index: number = origins.indexOf(origin.model);
    if (isChecked && index === -1) {
      origins.push(origin.model);
    }
    else if (!isChecked && index !== -1) {
      origins.splice(index, 1);
    }
    this.setCanSend();
  }  
}

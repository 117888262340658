import { ReportProfileAlertResourceBaseModel } from '../../../profile/report-profile-alert-resource-base-model';
import { ZimbraMailingReportProfilePermissions } from '../../../zimbra/mailing/profile/zimbra-mailing-report-profile-permissions';

export class ZimbraMailingReportProfileAlertPermissionsModel extends ReportProfileAlertResourceBaseModel {
  permissions: ZimbraMailingReportProfilePermissions;
  hasSendOnBehalfRightsIsValid: boolean;
  hasSendAsRightsIsValid: boolean;
  hasCalendarRightsIsValid: boolean;
  hasFoldersRightsIsValid: boolean;
  hasContactsRightsIsValid: boolean;
  hasInboxRightsIsValid: boolean;
}

<div class="container" *ngIf="shareNodes && shareNodes.length > 0">
  <div class="row">
    <div class="col">
      <span class="share-title">{{'profile.provider.microsoft.sharePoint.lastShares' | i18next }}</span><br>
      <span *ngIf="resource && isOneDrive"><a class="link shared-by-me" target="i2ashare"
          [href]="webUrl + '/_layouts/15/onedrive.aspx?view=20'">
          {{'profile.provider.microsoft.oneDrive.sharedByMe' | i18next}}
          <fa-icon [icon]="['fal', 'external-link']"></fa-icon>
        </a>
      </span>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="col m-2">
      <ng-container>
        <div>
          <div class="container">
            <div class="row no-gutters" *ngFor="let share of shareNodes">
              <div class="col-12">
                <i2a-short-title-bar>
                  <ng-container title>
                    <fa-icon [icon]="['fal', share.icon]" size="lg" [fixedWidth]="true">
                    </fa-icon>
                    {{ share.name }}
                  </ng-container>
                  <ng-container body>
                    <div class="container">
                      <div class="row no-gutters">
                        <div class="col-sm-12 col-md-6 col-lg-4 align-items-start" *ngIf="share.theWholeCompany">
                          <span>
                            <fa-icon [icon]="['fal', 'building']" size="lg"></fa-icon>
                            {{"profile.provider.microsoft.sharePoint.allCompany" | i18next}}
                          </span>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4 align-items-start" *ngIf="share.everyone">
                          <span>
                            <fa-icon [icon]="['fas', 'users']" size="lg"></fa-icon>
                            {{"profile.provider.microsoft.sharePoint.everyone" | i18next}}
                          </span>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4 align-items-start"
                          *ngFor="let child of share.children">

                          <div class="wrapped-container">
                            <div class="id-infos wrapped" [title]="child.email">
                              <fa-icon *ngIf="child.isGroup" [icon]="['fal', 'users-class']"
                                size="lg" [fixedWidth]="true"
                                [title]="'profile.provider.microsoft.sharePoint.share.group' | i18next">
                              </fa-icon>
                              <fa-icon
                                *ngIf="child.isExternal"
                                [icon]="['fal', 'id-card-alt']" size="lg"></fa-icon>
                              <fa-icon
                                *ngIf="child.isUser"
                                [icon]="['fal', 'user']" size="lg"></fa-icon>

                              {{child.name}}
                            </div>
                          </div>
                        </div>

                        <div class="col-12 text-center">
                          <a *ngIf="share.path" target="i2ashare"
                            [href]="share.url + share.detail.listItemId"
                            class="btn btn-outline-light btn-outline-gray btn-outline-details m-3">
                            {{'profile.provider.microsoft.sharePoint.editPermission' | i18next}}
                            <fa-icon [icon]="['fal', 'external-link']"></fa-icon>
                          </a>
                        </div>
                      </div>
                    </div>

                  </ng-container>
                </i2a-short-title-bar>
              </div>
            </div>
          </div>
        </div>

      </ng-container>

    </div>
  </div>
</div>
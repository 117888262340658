<i2a-nav-bar-base [displayMenu]="displayMenu" [logoPosition]="logoPosition">
  <ng-container menuLogo *ngIf="tenant">
    <div class="logoContainer" [attr.style]="logoContainerStyle">
      <a class="menuBrand" [routerLink]="['/tenants',tenant.code,'dashboard']"><img [src]="logo"
          [attr.style]="logoStyle" />
      </a>
    </div>
  </ng-container>
  <ng-container menuLogo *ngIf="!tenant">
    <div class="logoContainer" [attr.style]="logoContainerStyle">
      <a class="menuBrand" [routerLink]="['/tenants']"><img [src]="logo" [attr.style]="logoStyle" />
      </a>
    </div>
  </ng-container>

  <ng-container menuBrand>
    <div>
      <a [routerLink]="['/tenants']">
        <div class="title" #navbarTitle (mouseout)="onLeave()" (mouseover)="onEnter()" >
          {{"navigationBar.title" | i18next }}<br>
        </div>
        <div class="sub-title" #navbarSubTitle (mouseout)="onLeave()" (mouseover)="onEnter()">
          {{"navigationBar.subTitle" | i18next }}
        </div>
      </a>
    </div>
  </ng-container>

  <ng-container menuUser>
    <ng-container *ngIf="connectedAccount">
      <i2a-user-photo class="pr-1" *ngIf="connectedAccount" [userId]="connectedAccount.userId" [size]="36">
      </i2a-user-photo> <span class="connected-account">{{connectedAccount.fullName}}</span>
    </ng-container>
  </ng-container>

  <ng-container menuItems>
    <ng-container *ngIf="connectedAccount">
      <button ngbDropdownItem *ngIf="connectedAccount.isSupport" class="dropdown-item" [routerLink]="['/tenants']">
        <span class="navbar-icon">
          <fa-icon [icon]="['fal', 'building']" size="lg"></fa-icon>
        </span>
        <span class="navbar-label">{{"navigationBar.tenants" | i18next }}</span>
      </button>

      <button ngbDropdownItem *ngIf="tenant" class="dropdown-item" [routerLink]="['/tenants',tenant.code,'dashboard']">
        <span class="navbar-icon">
          <fa-icon [icon]="['fal', 'home']" size="lg"></fa-icon>
        </span>
        <span class="navbar-label">{{"navigationBar.home" | i18next }}</span>
      </button>

      <button ngbDropdownItem *ngIf="!connectedAccount.isSupport && connectedAccount.accountType != 'i2a_central'"
        class="dropdown-item" (click)="UpdateAccount()">
        <span class="navbar-icon">
          <fa-icon [icon]="['fal', 'user']" size="lg"></fa-icon>
        </span>
        <span class="navbar-label">{{"navigationBar.myAccount" | i18next }}</span>
      </button>

      <div class="dropdown-divider"></div>

      <button ngbDropdownItem *ngIf="!connectedAccount.isSupport && !connectedAccount.isFederated"
        class="dropdown-item" (click)="ChangePassword()">
        <span class="navbar-icon">
          <fa-icon [icon]="['fal', 'key']" size="lg"></fa-icon>
        </span>
        <span class="navbar-label">{{"auth.changepwd" | i18next }}</span>
      </button>

      <button ngbDropdownItem class="dropdown-item" (click)="Logout()">
        <span class="navbar-icon">
          <fa-icon [icon]="['fal', 'sign-out']" size="lg"></fa-icon>
        </span>
        <span class="navbar-label">{{"auth.signout" | i18next }}</span>
      </button>
    </ng-container>
  </ng-container>

</i2a-nav-bar-base>
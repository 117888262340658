export class TemplateConstants {

    //#region MODULES

    public static readonly MODULE_TEMPLATE_CONTAINER = "10000000-0000-0000-0000-000000000000";

    public static readonly MODULE_TEMPLATE_WARNING_CONTAINER = "10000000-1000-0000-0000-000000000000";

    //#region USER
    public static readonly MODULE_USER_CONTAINER = "01000000-0000-0000-0000-000000000000";
    public static readonly MODULE_USER_ACCESSMODE = "01000000-1000-0000-0000-000000000000";
    public static readonly MODULE_USER_ORIGIN = "01000000-2000-0000-0000-000000000000";
    public static readonly MODULE_USER_DEVICE = "01000000-3000-0000-0000-000000000000";
    //#endregion

    public static readonly MODULE_PROVIDER_CONTAINER = "00100000-0000-0000-0000-000000000000";

    public static readonly MODULE_APPLICATION_CONTAINER = "00010000-0000-0000-0000-000000000000";

    public static readonly MODULE_SHAREPOINT_APPLICATION_CONTAINER = "D94F0E1A-B6A4-0000-0000-000000000000";

    public static readonly MODULE_RESOURCE_CONTAINER = "00001000-0000-0000-0000-000000000000";


    //#region MICROSOFT

    //#region AzureAD

    public static readonly MODULE_AZUREAD_NODATA = "D94F0E1A-D6EE-0000-0000-000000000001";
    public static readonly MODULE_AZUREAD_PERMISSION_GRANTS = "D94F0E1A-D6EE-0000-0000-000000000002";
    public static readonly MODULE_AZUREAD_RULES_COMPUTED_DELEGATE_I2A = "D94F0E1A-D6EE-0000-0000-000000000011";

    //#endregion

    //#region Exchange

    public static readonly MODULE_EXCHANGE_NODATA = "D94F0E1A-5DDE-0000-0000-000000000001"
    public static readonly MODULE_EXCHANGE_DELEGATE = "D94F0E1A-5DDE-0000-0000-000000000002"
    public static readonly MODULE_EXCHANGE_PERMISSION = "D94F0E1A-5DDE-0000-0000-000000000003";
    public static readonly MODULE_EXCHANGE_RULE = "D94F0E1A-5DDE-0000-0000-000000000004";
    public static readonly MODULE_EXCHANGE_RULES_COMPUTED_DELEGATE_I2A = "D94F0E1A-5DDE-0000-0000-000000000011";

    //#endregion

    //#region OneDrive

    public static readonly MODULE_ONEDRIVE_NODATA = "D94F0E1A-19A9-0000-0000-000000000001";
    public static readonly MODULE_ONEDRIVE_DELEGATE = "D94F0E1A-19A9-0000-0000-000000000002";
    public static readonly MODULE_ONEDRIVE_INSTANT_MESSAGING = "D94F0E1A-19A9-0000-0000-000000000003";
    public static readonly MODULE_ONEDRIVE_RULES_COMPUTED_DELEGATE_I2A = "D94F0E1A-19A9-0000-0000-000000000011";

    //#endregion

    //#region FileSahre

    public static readonly MODULE_FILESHARE_NODATA = "D94F0E1A-F029-0000-0000-000000000001";
    public static readonly MODULE_FILESHARE_DELEGATE = "D94F0E1A-F029-0000-0000-000000000002";
    public static readonly MODULE_FILESHARE_PERMISSION = "D94F0E1A-F029-0000-0000-000000000003";
    
    //#endregion

    //#region SharePoint

    public static readonly MODULE_SHAREPOINT_NODATA = "D94F0E1A-B6A4-0000-0000-000000000001";
    public static readonly MODULE_SHAREPOINT_DELEGATE = "D94F0E1A-B6A4-0000-0000-000000000002";
    public static readonly MODULE_SHAREPOINT_O365_GROUP = "D94F0E1A-B6A4-0000-0000-000000000003";
    public static readonly MODULE_SHAREPOINT_PERMISSION = "D94F0E1A-B6A4-0000-0000-000000000004";
    public static readonly MODULE_SHAREPOINT_RULES_COMPUTED_DELEGATE_I2A = "D94F0E1A-B6A4-0000-0000-000000000011";
    public static readonly MODULE_SHAREPOINT_ACTIONS = "D94F0E1A-B6A4-0000-0000-000000000005"
    public static readonly MODULE_SHAREPOINT_RESOURCE_HAS_SENSITIVITY_LABEL = "D94F0E1A-B6A4-0000-0000-000000000006"


    //#endregion

    public static readonly MODULE_SHAREPOINT_SHARING = "D94F0E1A-1111-0000-0000-000000000001";
    
    //#endregion

    //#region zimbra

    //#region zimbra

    public static readonly MODULE_ZIMBRA_MAILING_NODATA = "6F2203C5-5DDE-0000-0000-000000000001";
    public static readonly MODULE_ZIMBRA_MAILING_DELEGATE = "6F2203C5-5DDE-0000-0000-000000000002";
    public static readonly MODULE_ZIMBRA_MAILING_PERMISSION = "6F2203C5-5DDE-0000-0000-000000000003";
    public static readonly MODULE_ZIMBRA_MAILING_FILTER_RULE = "6F2203C5-5DDE-0000-0000-000000000004";
    public static readonly MODULE_ZIMBRA_RULES_COMPUTED_DELEGATE_I2A = "6F2203C5-5DDE-0000-0000-000000000011";

    //#endregion

    //#endregion

    //#region SHARED

    public static readonly MODULE_RULES_COMPUTED_DELEGATE_I2A = "11111111-1111-0000-0000-000000000001"
    public static readonly MODULE_DELEGATE_LAST_ACCESS = "11111111-1111-0000-0000-000000000002"
    public static readonly MODULE_DELEGATE_RESOURCE_ACTIONS = "11111111-1111-0000-0000-000000000003"

    //#endregion

    //#endregion
}
import { SharePointReportProfileDriveItemRole } from '../../../microsoft/sharepoint/profile/share-point-report-profile-drive-item-role';

export class SharePointReportProfilePermissionDetail {
  driveItemId: string;
  listItemId: string;
  rights: SharePointReportProfileDriveItemRole;
  permissionIds: string[];
  isSpecificLink: boolean;
  hasSensitivityLabel: boolean;
  sensitivityLabel: string;
  sensitivityLabels: string[];
  hasPassword: boolean;
  expirationDate: Date|null;
}

import { BaseProviderInstanceResourceModel } from '../../../base-provider-instance-resource-model';
import { ChangeResult } from '../../../change-result';
import { ExchangeReportProfileDelegate } from '../../../microsoft/exchange/profile/exchange-report-profile-delegate';
import { ExchangeReportProfilePermissions } from '../../../microsoft/exchange/profile/exchange-report-profile-permissions';
import { ExchangeReportProfileRulesChangesModel } from '../../../microsoft/exchange/profile/exchange-report-profile-rules-changes-model';
import { ReportProfileDeviceSyncModel } from '../../../profile/report-profile-device-sync-model';
import { ReportProfileResourceChangesBaseModel } from '../../../reports/profile/report-profile-resource-changes-base-model';
import { ReportProfileRulesComputedDelegate } from '../../../profile/report-profile-rules-computed-delegate';

export class ExchangeReportProfileChangesModel extends ReportProfileResourceChangesBaseModel<ReportProfileRulesComputedDelegate> {
  providerInstanceResource: BaseProviderInstanceResourceModel;
  delegates: ChangeResult<ExchangeReportProfileDelegate>;
  rulesChanges: ExchangeReportProfileRulesChangesModel;
  permissions: ChangeResult<ExchangeReportProfilePermissions>;
  unRegisteredDevices: ChangeResult<ReportProfileDeviceSyncModel>;
  isEqual: boolean;
}

import { Injectable } from "@angular/core";
import { ConfigService, IWebConfig, IConfigService } from '../infrastructure/config.service';

@Injectable()
export class UrlBuilderHelperService {
    constructor() {
    }

    public BuildUrl(partialURL: string, configService: IConfigService<IWebConfig>, tenantCode: string): string {
        return this.GetWSTenantizedBaseUrl(configService.Config, tenantCode) + "/" + partialURL;
    }

    public GetWSTenantizedBaseUrl(config: IWebConfig, tenantCode: string): string {
        let result = "";
        result = config.apiEndPoint;
        if (!!config.urlPrefix) {
            result = result + "/" + config.urlPrefix;
        }

        if (!!tenantCode) {
            result = result + "/tenants/" + tenantCode;
        }
        return result;
    }

}
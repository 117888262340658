import { Component, ComponentRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MicrosoftOfficeReportProfileModel, ConnectedAccountModel, ReportProfileResource, SharePointReportProfile, ProviderInstanceResourceModel, SharePointSiteReportProfile, AlertConstants, Constants, AlertInvalidStateFeaturedModel, ReportProfileBaseModel, UserModel } from '@i2a/web-api-client';
import { StringHelper } from '@i2a/web-core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import differenceWith from 'lodash-es/differenceWith';
import dayJS from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import { ContainerDirective } from 'projects/web-user-profile/src/app/directives/container-directive';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { ProviderHelper } from 'projects/web-user-profile/src/app/helpers/provider-helper';
import { AlertRemediationTerminatedActionStatus } from 'projects/web-user-profile/src/app/models/alert-remediation-terminated-action-status';
import { SharePointApplicationConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/share-point-application-configuration';
import { SharePointResourceActionsConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/share-point-resource-actions-configuration';
import { SharePointO365GroupConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/sharepoint-o365-group-configuration';
import { IBaseModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-base-module';
import { IModuleApplicationContainer } from 'projects/web-user-profile/src/app/models/templates/modules/i-module-application-container';
import { IModuleResourcesContainer } from 'projects/web-user-profile/src/app/models/templates/modules/i-module-resources-container';
import { TemplateApplication } from 'projects/web-user-profile/src/app/models/templates/template-application';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';
import { TemplateService } from 'projects/web-user-profile/src/app/services/template.service';
import { ApplicationContainerComponent } from '../../../../shared/application-container/application-container.component';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';

@Component({
  selector: 'i2a-share-point-site-container',
  templateUrl: './share-point-site-container.component.html',
  styleUrls: ['./share-point-site-container.component.scss']
})
export class SharePointSiteContainerComponent extends ProfileBaseClassComponent<SharePointApplicationConfiguration,SharePointReportProfile> 
                                              implements OnInit, OnChanges, OnDestroy, IModuleApplicationContainer<SharePointApplicationConfiguration> { 

  @Input() public configuration: SharePointApplicationConfiguration;
  @Input() public profile: MicrosoftOfficeReportProfileModel;
  @Input() public site: SharePointSiteReportProfile;
  @Input() public template: TemplateApplication;
  
  public className: string;
  public connectedAccount: ConnectedAccountModel;
  public debug: boolean;
  public deleteAlertDate: Date;
  public guidedTourKey: string;
  public hasDeleteAlert: boolean;
  public hasDeleteAlertRecentlyClosed: boolean;
  public hasDeleteAlertFailed: boolean;
  public hasNotBelongToMe: boolean;
  public hasNotBelongToMeRecentlyClosed: boolean;
  public hasNotBelongToMeDate: Date;
  public hideResource: boolean;
  public isCollapsed: boolean;
  public moduleActionConfiguration: SharePointResourceActionsConfiguration;
  public moduleSharePointO365GroupConfiguration: SharePointO365GroupConfiguration;
  public notSharepoint: boolean = false;
  public providerPhotoUrl: string;

  private modules: IBaseModule[];
  private resourceComponents: ComponentRef<IModuleResourcesContainer<any>>[];

  public resourceProfiles: SharePointReportProfile[];
  public providerInstanceResources: ProviderInstanceResourceModel[];
  public firstResource: ProviderInstanceResourceModel;

  @ViewChild('resources', { static: true, read: ContainerDirective }) resourcesContainer: ContainerDirective;

  @Output() public onResourceToValidate: EventEmitter<ReportProfileResource> = new EventEmitter<ReportProfileResource>();
  
  constructor(modalService: NgbModal
            , toastr: ToastrService
            , @Inject(I18NEXT_SERVICE) i18Next: ITranslationService
            , protected templateService: TemplateService
            , private activatedRoute: ActivatedRoute
            , private session: SessionService){    
      super(toastr, i18Next, modalService);
      this.modules = [];    
      this.resourceComponents = []; 
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: SharePointApplicationConfiguration, alerts: AlertInvalidStateFeaturedModel[], template: TemplateApplication): void {
    super.baseInitialize(user, profile, configuration, alerts);
    this.template = template;
  }
  
  ngOnInit(): void {
    this.isAlert = true;
    super.ngOnInit();
    if (this.profile && this.configuration && this.template && this.site) {
      this.setDebug();
      this.setInSessionGroupBySite();
      this.setSubscriptionForConnectedAccount();
      
      if (StringHelper.isNullOrWhitespace(this.site.title)) {
        this.site.title = this.i18Next.t('profile.provider.microsoft.sharePoint.title.site.default');
      } 
      this.resourceProfiles = this.profile.sharePointProfiles.filter((resourceProfile: SharePointReportProfile) => resourceProfile.siteId === this.site.id);
      this.providerInstanceResources = this.resourceProfiles.map(r => r.providerInstanceResource);

      this.firstResource = this.providerInstanceResources[0];
      
      this.id = `site_${this.profile.providerId}_${Constants.PROVIDER_TYPE_INTRANET}`;
      this.guidedTourKey = this.configuration.guidedTourKey;
      this.setDeleteOrHasNotBelongToMe(); 
      this.setClassName();
      this.setIsCollapsed(); 

      this.providerPhotoUrl = ProviderHelper.GetProviderImage(Constants.PROVIDER_ID_MICROSOFT, Constants.PROVIDER_TYPE_INTRANET, this.resourceProfiles[0]);

      this.notSharepoint = false;          

      if (this.template.resourceTemplate) {
        let moduleActionTemplate = this.template.resourceTemplate.modules.find(m => m.id === TemplateConstants.MODULE_SHAREPOINT_ACTIONS);
        if (moduleActionTemplate) {
          this.moduleActionConfiguration = moduleActionTemplate.configuration;
        }
        let moduleSharePointO365GroupTemplate = this.template.resourceTemplate.modules.find(m => m.id === TemplateConstants.MODULE_SHAREPOINT_O365_GROUP);
        if (moduleSharePointO365GroupTemplate) {
          this.moduleSharePointO365GroupConfiguration = <SharePointO365GroupConfiguration>moduleSharePointO365GroupTemplate.configuration;
        }
      }
      
      ApplicationContainerComponent.loadResources(this.template
                                                , this.profile
                                                , this.resourceProfiles.map((resourceProfile: SharePointReportProfile) => resourceProfile.providerInstanceResource)
                                                , this.user
                                                , this.alerts
                                                , this.templateService
                                                , this.resourcesContainer
                                                , this.resourceComponents
                                                , this.modules
                                                , this.subscriptions
                                                , this.resourceToValidate.bind(this)
                                                , this.alertSent.bind(this)
                                                , this.warnings
                                                , this.hasWarnings.bind(this));
      this.updateAlerts(this.alerts);

      this.subscriptions.add(
        this.session.isCollapsedAll$.subscribe(isCollapsedAll => {
          if (isCollapsedAll !== null && isCollapsedAll !== undefined) {
            this.isCollapsed = isCollapsedAll;
          }
        })
      );
    }
  }

  resourceToValidate(report: ReportProfileResource) {
    this.onResourceToValidate.emit(report);
  }

  /**
   * @summary set value of className property. 
   *          This method need to set before values of hasDeleteAlert, hasNotBelongToMe, resource and resourceProfile properties
   */
  private setClassName(): void {
    this.className = "title-deleted";

    if (!this.hasDeleteAlert && !this.hasNotBelongToMe) {
      this.className = ProviderHelper.GetProviderClassName(Constants.PROVIDER_ID_MICROSOFT, Constants.PROVIDER_TYPE_INTRANET, this.resourceProfiles[0]);
    } 
  }
  
  private setIsCollapsed(): void {
    if (this.configuration.isCollapsed !== null && this.configuration.isCollapsed !== undefined) {
      this.isCollapsed = this.configuration.isCollapsed
    }   
  }

  private setDebug(): void {
    if (this.activatedRoute && this.activatedRoute.queryParams) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.debug = params['debug'];
      });
    }
  }

  private setSubscriptionForConnectedAccount(): void {
    this.subscriptions.add(
      this.session.Account$.subscribe(account => {
        if (account != null) {
          this.connectedAccount = account;
        }
      })
    );
  }


  private setInSessionGroupBySite(): void {
    this.session.SharePointGroupBySite = this.site && this.configuration.groupBySite;
  }

  private setDeleteOrHasNotBelongToMe() {
    var resourceAlerts = this.alerts.filter(a => a.resources.findIndex(r => this.providerInstanceResources.findIndex(r2 => r.providerInstanceResourceId == r2.providerInstanceResourceId && r.providerTypeId == r2.providerTypeId) >= 0) >= 0);
    if (resourceAlerts.length > 0) {
      var alerts = AlertReasonHelper.GetalertsContainsReason(resourceAlerts, AlertConstants.ALERT_INVALID_STATE_REASON_RESOURCE_DELETE, false);
      var alertsRecentlyClosed = AlertReasonHelper.GetalertsContainsReason(resourceAlerts, AlertConstants.ALERT_INVALID_STATE_REASON_RESOURCE_DELETE, true);
      if (alerts.length > 0 || alertsRecentlyClosed.length > 0) {
        this.hasDeleteAlert = AlertReasonHelper.alertsContainsKeyValuePair(alerts, AlertConstants.ALERT_INVALID_STATE_CONTEXT_SHAREPOINT_DELETE_SITE, "True", false );
        this.hasDeleteAlertRecentlyClosed = AlertReasonHelper.alertsContainsKeyValuePair(alertsRecentlyClosed, AlertConstants.ALERT_INVALID_STATE_CONTEXT_SHAREPOINT_DELETE_SITE, "True", true );
        alerts.push(...alertsRecentlyClosed);
        if (this.hasDeleteAlert || this.hasDeleteAlertRecentlyClosed) {
          this.hasDeleteAlert = true;
          this.deleteAlertDate = dayJS(alerts[0].reasons[0].creationDate).toDate();
          let terminatedUnderTakenActions = alerts[0].undertakenActions.filter(action => action.kind == Constants.UNDERTAKEN_ACTION_ALERT_REMEDIATION_TERMINATED);
          let failedCount = 0;
          terminatedUnderTakenActions.forEach(terminatedUnderTakenAction => {
            let deserializedAction = JSON.parse(terminatedUnderTakenAction.action);
            if (deserializedAction.Status != AlertRemediationTerminatedActionStatus.Succeed) {
              failedCount += 1;
            }
          });
          this.hasDeleteAlertFailed = failedCount > 0;
        }
      }
      alerts = AlertReasonHelper.GetalertsContainsReason(resourceAlerts, AlertConstants.ALERT_INVALID_STATE_REASON_RESOURCE_NOT_BELONG_TO_ME, false);
      alertsRecentlyClosed = AlertReasonHelper.GetalertsContainsReason(resourceAlerts, AlertConstants.ALERT_INVALID_STATE_REASON_RESOURCE_NOT_BELONG_TO_ME, true);
      if (alerts.length > 0 || alertsRecentlyClosed.length > 0) {
        var resourcesWithAlert = alerts.flatMap(a => a.resources);
        var resourcesWithAlertRecentClosed = alertsRecentlyClosed.flatMap(a => a.resources);
        var diff = differenceWith(this.providerInstanceResources.map(r=>r.providerInstanceResourceId), resourcesWithAlert.map(r =>r.providerInstanceResourceId));
        var diffRecentClosed = differenceWith(this.providerInstanceResources.map(r=>r.providerInstanceResourceId), resourcesWithAlertRecentClosed.map(r =>r.providerInstanceResourceId));
        //console.log( `${this.site.title} =>  ${JSON.stringify(diff)}`);
        if (diff.length == 0) {
          this.hasNotBelongToMeDate = dayJS(alerts[0].reasons[0].creationDate).toDate();
          this.hasNotBelongToMe = true;
        } else if (diffRecentClosed.length == 0) {
          this.hasNotBelongToMeDate = dayJS(alertsRecentlyClosed[0].reasons[0].creationDate).toDate();
          this.hasNotBelongToMeRecentlyClosed = true;
        } else {
          this.hasNotBelongToMe = false;
          this.hasNotBelongToMeRecentlyClosed = false
        }
      } else {
        this.hasNotBelongToMe = false;
        this.hasNotBelongToMeRecentlyClosed = false
      }
    } else {
      this.hasDeleteAlert = false;
      this.hasNotBelongToMe = false;
      this.hasNotBelongToMeRecentlyClosed = false
    }
    if (this.hasDeleteAlert || this.hasNotBelongToMe || this.hasDeleteAlertRecentlyClosed || this.hasNotBelongToMeRecentlyClosed) {
      this.className = "title-deleted";
      this.id = `site_${this.profile.providerId}_${Constants.PROVIDER_TYPE_INTRANET}Disabled`;
    }
  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }

  hasWarnings(event): void {
    setTimeout(() => {
      this.isCollapsed = false; 
    });
    this.onHasWarnings.emit(event);    
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setIsAlert(isAlert: boolean) {
    super.setIsAlert(isAlert);

    this.modules.forEach(m => {
      m.setIsAlert(this.isAlert);
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'alerts': {
            if (this.resourceComponents.length > 0) {
              this.updateAlerts(this.alerts);
            }
          }
        }
      }
    }
  }

  updateAlerts(alerts: AlertInvalidStateFeaturedModel[]) {

    let filteredAlerts: AlertInvalidStateFeaturedModel[] = [];
    this.resourceComponents.forEach(c => {
      if (c.instance.onAlertSent) {
        let resourceFilteredAlerts = ApplicationContainerComponent.getResourceAlert(c.instance.providerInstanceResource, alerts, this.resourceToValidate.bind(this));

        resourceFilteredAlerts.forEach( a => {
          if (filteredAlerts.findIndex( (af) => af.id.toLowerCase() == a.id.toLowerCase()) == -1) {
            filteredAlerts.push(a);
          }
        });
        c.instance.updateAlerts(resourceFilteredAlerts);
      }
    });

    super.updateAlerts(filteredAlerts);
    this.setDeleteOrHasNotBelongToMe();
    this.setClassName();
  }

}

import { Injectable, OnInit, OnDestroy, Inject } from '@angular/core';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { ConnectedAccountModel, SecurityInfosModel, UsersDatasourceService } from '@i2a/web-api-client';
import { ContextService } from '@i2a/web-core';
import { DOCUMENT } from '@angular/common';
import { Md5 } from 'ts-md5/dist/md5';
import { StringHelper } from '../helpers/string-helper';
import { WarningContainerConfiguration } from '../models/templates/modules/configuration/warning-container-configuration';

@Injectable({
  providedIn: 'root'
})
export class SessionService implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  private accountSubject?: BehaviorSubject<ConnectedAccountModel>;
  public Account$: Observable<ConnectedAccountModel>;

  get Account(): ConnectedAccountModel {
    return this.accountSubject.value;
  }

  set Account(connectedAccount: ConnectedAccountModel) {
    this.accountSubject.next(connectedAccount);
  }

  private userViewSubject?: BehaviorSubject<boolean>;
  public UserView$: Observable<boolean>;

  get UserView(): boolean {
    return this.userViewSubject.value;
  }

  set UserView(userView: boolean) {
    this.userViewSubject.next(userView);
  }

  private warningConfigurationSubject?: BehaviorSubject<WarningContainerConfiguration>;
  public warningConfiguration$: Observable<WarningContainerConfiguration>;

  get WarningConfiguration(): WarningContainerConfiguration {
    return this.warningConfigurationSubject.value;
  }

  set WarningConfiguration(value: WarningContainerConfiguration) {
    this.warningConfigurationSubject.next(value);
  }

  private isCollapsedAllSubject?: BehaviorSubject<boolean>;
  public isCollapsedAll$: Observable<boolean>;

  get IsCollapsedAll(): boolean {
    return this.isCollapsedAllSubject.value;
  }

  set IsCollapsedAll(isCollapsedAll: boolean) {
    this.isCollapsedAllSubject.next(isCollapsedAll);
  }

  private securityInfosSubject?: BehaviorSubject<SecurityInfosModel>;
  public SecurityInfos$: Observable<SecurityInfosModel>;
  get SecurityInfos(): SecurityInfosModel {
    return this.securityInfosSubject.value;
  }

  set SecurityInfos(infos: SecurityInfosModel) {
    this.securityInfosSubject.next(infos);
  }

  private displayCommentSubject?: BehaviorSubject<boolean>;
  public displayCommentSubject$: Observable<boolean>;

  get DisplayComment(): boolean {
    return this.displayCommentSubject.value;
  }

  set DisplayComment(displayComment: boolean) {
    this.displayCommentSubject.next(displayComment);
  }

  private manualRemediationSubject?: BehaviorSubject<boolean>;
  public manualRemediation$: Observable<boolean>;

  get ManualRemediation(): boolean {
    return this.manualRemediationSubject.value;
  }

  set ManualRemediation(manualRemediation: boolean) {
    this.manualRemediationSubject.next(manualRemediation);
  }

  private sharepointGroupBySiteSubject?: BehaviorSubject<boolean>;
  public sharepointGroupBySiteSubject$: Observable<boolean>;
  get SharePointGroupBySite(): boolean {
    return this.sharepointGroupBySiteSubject.value;
  }
  set SharePointGroupBySite(sharepointGroupBySite: boolean) {
    this.sharepointGroupBySiteSubject.next(sharepointGroupBySite);
  }

  private ignoreCommentToSendSubject?: BehaviorSubject<boolean> 
  public ignoreCommentToSend$?: Observable<boolean>;
  get IgnoreCommentToSend(): boolean {
    return this.ignoreCommentToSendSubject.value;
  }
  set IgnoreCommentToSend(ignoreCommentToSend: boolean) {
    this.ignoreCommentToSendSubject.next(ignoreCommentToSend);
  }

  private isEnableEscalationDirectUserSubject?: BehaviorSubject<boolean>;
  public isEnableEscalationDirectUser$: Observable<boolean>;
  get IsEnableEscalationDirectUser(): boolean {
    return this.isEnableEscalationDirectUserSubject.value;
  }
  set IsEnableEscalationDirectUser(value: boolean) {
    this.isEnableEscalationDirectUserSubject.next(value);
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public context: ContextService
  ) {
    console.log("Init SessionService");

    this.accountSubject = new BehaviorSubject<ConnectedAccountModel>(null);
    this.Account$ = this.accountSubject.asObservable();

    this.userViewSubject = new BehaviorSubject<boolean>(false);
    this.UserView$ = this.userViewSubject.asObservable();

    this.warningConfigurationSubject = new BehaviorSubject<WarningContainerConfiguration>(null);
    this.warningConfiguration$ = this.warningConfigurationSubject.asObservable();

    this.isCollapsedAllSubject = new BehaviorSubject<boolean>(null);
    this.isCollapsedAll$ = this.isCollapsedAllSubject.asObservable();
    
    this.securityInfosSubject = new BehaviorSubject<SecurityInfosModel>(null);
    this.SecurityInfos$ = this.securityInfosSubject.asObservable();

    this.displayCommentSubject = new BehaviorSubject<boolean>(true);
    this.displayCommentSubject$ = this.displayCommentSubject.asObservable();

    this.manualRemediationSubject = new BehaviorSubject<boolean>(null);
    this.manualRemediation$ = this.manualRemediationSubject.asObservable();

    this.sharepointGroupBySiteSubject = new BehaviorSubject<boolean>(false);
    this.sharepointGroupBySiteSubject$ = this.sharepointGroupBySiteSubject.asObservable();

    this.ignoreCommentToSendSubject = new BehaviorSubject<boolean>(true);
    this.ignoreCommentToSend$ = this.ignoreCommentToSendSubject.asObservable();

    this.isEnableEscalationDirectUserSubject = new BehaviorSubject<boolean>(true);
    this.isEnableEscalationDirectUser$ = this.isEnableEscalationDirectUserSubject.asObservable();

    this.initTheme();
    this.subscriptions.add(context.TenantCode$.subscribe(tenantCode => {
      this.changeTheme(tenantCode);
    }));
  }

  ngOnInit() {
  }

  
  initTheme() {
    const head = this.document.getElementsByTagName('head')[0];
    let links = head.getElementsByTagName("link");
    let themeLink: HTMLLinkElement | undefined = undefined;
    for (var i = 0, length = links.length; i < length; i++) {
      if (links[i].href.endsWith("styles.css")) {
        console.log("Init theme");
        themeLink = links[i];
        themeLink.id = 'client-theme';
        break;
      }
    }
  }

  changeTheme(tenantCode: string) {

    var styleName: any = "styles";

    if (!StringHelper.isNullOrWhitespace(tenantCode)) {
      let hash = Md5.hashStr(tenantCode);
      switch (hash) {
        case 'a59b5cd47df3610d5c31b7b544c3caaa'://qaidecsi
          styleName = hash;
          break;

        default:
          break;
      }
    }

    var css = `${styleName}.css`;
    let themeLink = this.document.getElementById('client-theme') as HTMLLinkElement;
    if (themeLink && !themeLink.href.endsWith(css)) {
      console.log("Loading " + styleName);
      themeLink.href = css;
    }
  }


  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public resetConfiguration() {
    this.SharePointGroupBySite = false;//reset configuration
    this.IsCollapsedAll = null;
  }

}

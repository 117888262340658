<div class="col form-group pl-1">
    <div class="no-gutters">
        <div class="row no-gutters delegate-container">
            <table class="table-sm id-table mt-2">
                <tbody>
                    <tr>
                        <td scope="row text-center" class="cell-checkbox">
                            <i2a-input-checkbox [(model)]="validation.allPaths" (modelChange)="allPathsChecked($event)"
                                [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'"
                                [valueChecked]="true" [valueUnChecked]="false">
                            </i2a-input-checkbox>
                        </td>
                        <td scope="row" class="text-break w-100 delegate-title">
                            {{ allPathsTitle }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="form-group col-12 pl-4" *ngIf="driveItemsPermissions && !validation.allPaths">
    <i2a-short-title-bar [isCollapsed]="pathCollapsed" [hasAlert]="false">
        <ng-container title>{{'profile.provider.microsoft.sharePoint.driveItem.paths' | i18next }}</ng-container>
        <ng-container body>
            <table class="table-sm id-table">
                <tbody>
                    <tr *ngFor="let driveItem of driveItemsPermissions ">
                        <td scope="row text-center" class="cell-checkbox">
                            <i2a-input-checkbox [(model)]="driveItem.checked"
                                (modelChange)="checkedPath($event, driveItem)" [classNameChecked]="'btn-outline-danger'"
                                [className]="'btn-outline-dark'" [valueChecked]="true" [valueUnChecked]="false">
                            </i2a-input-checkbox>
                        </td>
                        <td scope="row" class="text-break w-100">
                            {{driveItem.path}}
                            <span class="pl-1" *ngIf="driveItem.hasSensitivityLabel" [title]="'profile.provider.microsoft.sharePoint.driveItem.sensitivityLabel' | i18next : { label: driveItem.sensitivityLabel} ">    
                                <fa-icon [icon]="['far', 'lock-alt']"></fa-icon>
                            </span>
                            <i2a-share-point-permissions-roles [roles]="driveItem.roles">
                            </i2a-share-point-permissions-roles>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
    </i2a-short-title-bar>
</div>
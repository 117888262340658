import { Component, OnInit, EventEmitter, Inject, Input } from '@angular/core';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { ReportProfileBaseModel, UserModel, BaseProviderInstanceResourceModel, MicrosoftOfficeReportProfileModel, FileShareReportProfileDelegate, FileShareReportProfile, AlertInvalidStateFeaturedModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ProfileHelper } from 'projects/web-user-profile/src/app/helpers/profile-helper';
import { FileShareDelegateConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/file-share-delegate-configuration';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import dayJS from 'dayjs';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';

@RegisterMyProfileComponent(TemplateConstants.MODULE_FILESHARE_DELEGATE)
@Component({
  selector: 'i2a-file-share-delegate-module',
  templateUrl: './file-share-delegate-module.component.html',
  styleUrls: ['./file-share-delegate-module.component.scss']
})
export class FileShareDelegateModuleComponent extends ProfileBaseClassComponent<FileShareDelegateConfiguration,FileShareReportProfile> implements OnInit, IModule<FileShareDelegateConfiguration> {
  private resource: BaseProviderInstanceResourceModel;

  public delegates: FileShareReportProfileDelegate[];

  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, null);
  }

  ngOnInit() {
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: FileShareDelegateConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel, resourceTemplate?: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.resource = resource;
    this.resourceProfile = (<MicrosoftOfficeReportProfileModel>profile).fileShareProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
    this.delegates = FileShareDelegateModuleComponent.SetDelegates(this.resourceProfile.delegates, this.configuration, this.i18Next);
  }

  public static SetDelegates(FileShareProfileDelegates: FileShareReportProfileDelegate[], configuration: FileShareDelegateConfiguration, i18Next: ITranslationService): FileShareReportProfileDelegate[] {
    var delegates: FileShareReportProfileDelegate[] = [];

    if (FileShareProfileDelegates) {

      delegates = FileShareProfileDelegates.filter(d => d.foldersAccessed.length > 0 && !d.isAnonymousUser);
      let anonymousUsers = FileShareProfileDelegates.filter(d => d.foldersAccessed.length > 0 && d.isAnonymousUser);
      FileShareDelegateModuleComponent.ProcessDelegates(anonymousUsers, delegates);
      delegates.sort((a, b) => { return ProfileHelper.compare(a, b, i18Next); });
    }
    return delegates;
  }


  private static ProcessDelegates(delegateToAdd: FileShareReportProfileDelegate[], dest: FileShareReportProfileDelegate[]) {
    if (delegateToAdd.length > 0) {
      let delegate = delegateToAdd.shift();
      delegateToAdd.forEach(user => {
        user.foldersAccessed.forEach(f => {
          if (delegate.foldersAccessed.indexOf(f) == -1) {
            delegate.foldersAccessed.push(f);
          }
          if (dayJS(user.lastAccess).isAfter(dayJS(delegate.lastAccess))) {
            delegate.lastAccess = user.lastAccess;
          }
        })
      });
      delegate.foldersAccessed.sort();
      dest.push(delegate);
    }

  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }

}

import { Component, OnInit, Inject, Input } from '@angular/core';
import { ReportProfileBaseModel, UserModel, BaseProviderInstanceResourceModel, MicrosoftOfficeReportProfileModel, ExchangeReportProfile, AlertInvalidStateFeaturedModel, ExchangeReportProfileDelegate, ExchangeReportProfilePermissions } from '@i2a/web-api-client';
import { ExchangeDelegate } from 'projects/web-user-profile/src/app/models/providers/microsoft/exchange/exchange-delegate';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ExchangeDelegateConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/exchange-delegate-configuration';
import { ProfileHelper } from 'projects/web-user-profile/src/app/helpers/profile-helper';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';

@RegisterMyProfileComponent(TemplateConstants.MODULE_EXCHANGE_DELEGATE)
@Component({
  selector: 'i2a-exchange-delegate-module',
  templateUrl: './exchange-delegate-module.component.html',
  styleUrls: ['./exchange-delegate-module.component.scss']
})
export class ExchangeDelegateModuleComponent extends ProfileBaseClassComponent<ExchangeDelegateConfiguration,ExchangeReportProfile> implements OnInit, IModule<ExchangeDelegateConfiguration> {
  private resource: BaseProviderInstanceResourceModel;
  
  @Input() 
  public delegatesWithAccessAndPermissions: ExchangeDelegate[] = [];
  
  public showMore: boolean;
  public showMoreAfter: number;
  public maxShowMore: number;
  public isVisibleButtonsShowMore: boolean;
  private isActiveShowMoreOption: boolean;

  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, null);
  }

  
initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: ExchangeDelegateConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel, resourceTemplate?: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.resource = resource;
    this.resourceProfile = (<MicrosoftOfficeReportProfileModel>profile).exchangeProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
    this.resourceProfile.delegates.sort((a, b) => { return ProfileHelper.compare(a, b, this.i18Next); });
    this.resourceProfile.unRegisteredDevices.sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity:'base'}));
    let delegatesWithAccessAndPermissions: ExchangeDelegate[] = ExchangeDelegateModuleComponent.SetDelegates(this.resourceProfile.delegates, this.resourceProfile.permissions);
    delegatesWithAccessAndPermissions?.forEach((d: ExchangeDelegate) => {
      if (d.accesses?.isNameKey || d.permissions?.isNameKey) {
        this.delegatesWithAccessAndPermissions.unshift(d);        
      } else {
        this.delegatesWithAccessAndPermissions.push(d);
      }
    });
    
    this.showMoreAfter = configuration.showMoreAfter ?? 0;
    this.isActiveShowMoreOption = this.showMoreAfter > 0;
    this.isVisibleButtonsShowMore = this.isActiveShowMoreOption && (this.delegatesWithAccessAndPermissions?.length + ((configuration.displayUnregisteredDevices && this.resourceProfile.unRegisteredDevices?.length > 0) ? 1 : 0)) > this.showMoreAfter;
    this.setShowMoreValue();
  }

  public static SetDelegates(exchangeProfileDelegates: ExchangeReportProfileDelegate[], exchangeProfilePermissions: ExchangeReportProfilePermissions[]): ExchangeDelegate[] {
    var delegates = [];
    exchangeProfileDelegates.forEach(item => {
      var delegate = new ExchangeDelegate();
      delegate.accesses = item;
      var idx = -1;
      if (item.userId != null) {
        idx = exchangeProfilePermissions.findIndex(d => d.userId != null && d.userId == item.userId);
      }
      else if (item.providerInstanceUser != null) {
        idx = exchangeProfilePermissions.findIndex(d => d.providerInstanceUser != null && d.providerInstanceUser.providerInstanceResourceId == item.providerInstanceUser.providerInstanceResourceId);
      }
      else {
        idx = exchangeProfilePermissions.findIndex(p => p.logonUserId == item.logonUserId);
      }
      if (idx >= 0) {
        delegate.permissions = exchangeProfilePermissions[idx];
      }
      delegates.push(delegate);
    });
    return delegates;
  }

  public onShowMore() {
    this.showMore = !this.showMore;
    this.setShowMoreValue();
  }

  private setShowMoreValue() {
    this.maxShowMore = (!this.showMore && this.isActiveShowMoreOption) ? (this.showMoreAfter - (this.resourceProfile.unRegisteredDevices?.length > 0 ? 1 : 0)) : this.delegatesWithAccessAndPermissions?.length;
  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }
}

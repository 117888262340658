import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import { MicrosoftOfficeProfileProviderDatasourceService, Constants, SharePointReportProfileShares, UserModel, ReportProfileBaseModel, AlertInvalidStateFeaturedModel, BaseProviderInstanceResourceModel, MicrosoftOfficeReportProfileModel, SharePointDatasourceService, OneDriveDatasourceService, DriveItemSharingType, SharePointReportProfile, OneDriveReportProfile, ReportProfileResourceBase } from '@i2a/web-api-client';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { StringHelper } from 'projects/web-user-profile/src/app/helpers/string-helper';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { SharePointSharingsConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/share-point-sharings-configuration';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { FileHelper } from 'projects/web-user-profile/src/app/helpers/file-helper';
import { EnumHelper } from 'projects/web-user-profile/src/app/helpers/enum-helper';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';

@RegisterMyProfileComponent(TemplateConstants.MODULE_SHAREPOINT_SHARING)
@Component({
  selector: 'i2a-share-point-sharings',
  templateUrl: './share-point-sharings.component.html',
  styleUrls: ['./share-point-sharings.component.scss']
})
export class SharePointSharingsComponent extends ProfileBaseClassComponent<SharePointSharingsConfiguration,SharePointReportProfile> implements OnInit, IModule<SharePointSharingsConfiguration> {
  public resource: BaseProviderInstanceResourceModel;
  public oneDriveProfile: OneDriveReportProfile;
  public shares: SharePointReportProfileShares;

  public shareNodes: any[];
  public webUrl: string;

  get isOneDrive(): boolean {
    return this.resource.providerTypeId == Constants.PROVIDER_TYPE_PERSONAL_STORAGE;
  }

  constructor(modalService: NgbModal,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    private sharePointDatasource: SharePointDatasourceService,
    private oneDriveDatasource: OneDriveDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
    this.shareNodes = [];
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.shares) {
      this.processShares();
    }
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: SharePointSharingsConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource?: BaseProviderInstanceResourceModel, resourceTemplate?: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.resource = resource;
    
    if (resource.providerTypeId == Constants.PROVIDER_TYPE_PERSONAL_STORAGE) {
      this.oneDriveProfile = (<MicrosoftOfficeReportProfileModel>profile).oneDriveProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
      this.subscriptions.add(this.oneDriveDatasource.profileShares(resource.providerInstanceId, resource.providerInstanceResourceId, false, false)
        .subscribe(shares => {
          this.shares = shares;
          this.processShares();
        }));
    }
    else if (resource.providerTypeId == Constants.PROVIDER_TYPE_INTRANET) {
      this.resourceProfile = (<MicrosoftOfficeReportProfileModel>profile).sharePointProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
      this.subscriptions.add(this.sharePointDatasource.profileShares(resource.providerInstanceId, resource.providerInstanceResourceId, false, false)
        .subscribe(shares => {
          this.shares = shares;
          this.processShares();
        }));
    }

  }

  processShares() {
    this.shareNodes = [];
    this.shares.shares.forEach((share) => {
      var fileIndex = share.absoluteItemPath.lastIndexOf("/");
      var path = share.isDirectory ? share.absoluteItemPath : share.absoluteItemPath.substring(0, fileIndex);
      if (StringHelper.isNullOrWhitespace(path))
      {
        path = "/";
      }
      var icon = FileHelper.folderIcon;
      if (!share.isDirectory) {
        var idx = share.absoluteItemPath.lastIndexOf(".")
        icon = idx > 0 ? FileHelper.getFileIcon(share.absoluteItemPath.substring(idx + 1)) : FileHelper.fileIcon;
      }
      var oneDrivePrefix = this.shares.webUrl.substring(this.shares.webUrl.indexOf("sharepoint.com/") + 14);
      
      var idx = path.indexOf('/', 1);

      this.webUrl = this.isOneDrive ? this.shares.webUrl.replace("/Documents", "") : this.shares.webUrl;

      let node = {
        name: share.absoluteItemPath,
        path: path,
        url: this.isOneDrive ? `${this.webUrl}/_layouts/15/onedrive.aspx?tc=Link&id=${encodeURIComponent(oneDrivePrefix + path)}&managePermissionsForListItemId=`
          : `${this.webUrl}/Forms/AllItems.aspx?tc=Link&RootFolder=${encodeURIComponent(oneDrivePrefix + path)}&managePermissionsForListItemId=`,
        detail: share,
        icon: icon,
        children: [],
        everyone: share.isEveryone,
        theWholeCompany: share.theWholeCompany,
        share: share
      };
      let everyoneDetailIndex = share.detail.findIndex(a => EnumHelper.HasFlag(a.sharingType, DriveItemSharingType.EveryOne));
      if (everyoneDetailIndex >= 0) {
        let everyoneDetail = share.detail[everyoneDetailIndex];
        node.children.push({
          name: everyoneDetail.name,
          email: everyoneDetail.email,
          isGroup: everyoneDetail.isGroup && everyoneDetail.sharingType != DriveItemSharingType.Unknown,
          isExternal: EnumHelper.HasFlag(everyoneDetail.sharingType, DriveItemSharingType.External) && everyoneDetail.sharingType != DriveItemSharingType.Unknown,
          isUser: !EnumHelper.HasFlag(everyoneDetail.sharingType, DriveItemSharingType.External) && !everyoneDetail.isGroup && everyoneDetail.sharingType != DriveItemSharingType.Unknown,
          detail: everyoneDetail
        });
      } 
      else 
      {   
        let everyoneInCompanyDetailIndex = share.detail.findIndex(a => EnumHelper.HasFlag(a.sharingType, DriveItemSharingType.EveryOneInCompany));
        let everyoneInCompanyPresent = everyoneInCompanyDetailIndex >= 0;
        if (everyoneInCompanyPresent) {
          let everyoneInCompanyDetail = share.detail[everyoneInCompanyDetailIndex];
          node.children.push({
            name: everyoneInCompanyDetail.name,
            email: everyoneInCompanyDetail.email,
            isGroup: everyoneInCompanyDetail.isGroup && everyoneInCompanyDetail.sharingType != DriveItemSharingType.Unknown,
            isExternal: EnumHelper.HasFlag(everyoneInCompanyDetail.sharingType, DriveItemSharingType.External) && everyoneInCompanyDetail.sharingType != DriveItemSharingType.Unknown,
            isUser: !EnumHelper.HasFlag(everyoneInCompanyDetail.sharingType, DriveItemSharingType.External) && !everyoneInCompanyDetail.isGroup && everyoneInCompanyDetail.sharingType != DriveItemSharingType.Unknown,
            detail: everyoneInCompanyDetail
          });
        }     
        let trustedOwnerPIRIds = [];
        let trustedOwnerEmails = [];
        if (this.oneDriveProfile != null && this.oneDriveProfile != null)
        {
          trustedOwnerPIRIds.push(this.oneDriveProfile.providerInstanceResource.providerInstanceResourceId);
        }
        let trustedOwnerDisplayNames = [];
        let trustedMemberEmails = [];
        if (this.resourceProfile != null && this.resourceProfile.o365Groups != null)
        {
          this.resourceProfile.o365Groups.forEach(g => {
            trustedOwnerDisplayNames.push(`${g.displayName} Owners`);
            trustedOwnerDisplayNames.push(`Propriétaires de ${g.displayName}`);
            trustedOwnerDisplayNames.push(`${g.displayName} Members`);
            trustedOwnerDisplayNames.push(`${g.displayName} Membres`);
            trustedOwnerDisplayNames.push(g.displayName);
            if (g.owners != null)
            {
              g.owners.forEach(o => trustedOwnerEmails.push(o.mail));
            }
            if (g.members != null)
            {
              g.members.forEach(o => trustedMemberEmails.push(o.mail));
            }
          });
        }
        share.detail.forEach((detail) => {
          let nodeDetail = {
            name: detail.name,
            email: detail.email,
            isGroup: detail.isGroup && detail.sharingType != DriveItemSharingType.Unknown,
            isExternal: EnumHelper.HasFlag(detail.sharingType, DriveItemSharingType.External) && detail.sharingType != DriveItemSharingType.Unknown,
            isUser: !EnumHelper.HasFlag(detail.sharingType, DriveItemSharingType.External) && !detail.isGroup && detail.sharingType != DriveItemSharingType.Unknown,
            detail: detail
          }
          if (!(detail.email == null && detail.logonUserId == null && !detail.isGroup) // Filter Permissions where only DisplayName is present
            && (!everyoneInCompanyPresent || (everyoneInCompanyPresent && !nodeDetail.isExternal)) // Filter internal delegates if EveryOneInCompany is present
            && !(trustedOwnerDisplayNames.findIndex(g => g == detail.name && detail.isGroup && detail.sharingType == 2) >=0 // Filter Group that are Owners of this resource
                  || trustedOwnerPIRIds.findIndex(g => detail.providerInstanceUser != null && detail.sharingType == 2 && g == detail.providerInstanceUser.providerInstanceResourceId) >= 0 // Filter User that are Owners of this resource
                  || trustedOwnerEmails.findIndex(g => detail.email != null && g == detail.email && detail.sharingType == 2) >= 0 // Filter User that are Members of this resource
                  || trustedMemberEmails.findIndex(g => detail.email != null && g == detail.email && detail.sharingType == 2) >= 0 // Filter User that are Members of this resource
                  ))
          {
            if (node.children.findIndex(c => c.name == nodeDetail.name && c.email == nodeDetail.email 
                                      && c.isGroup == nodeDetail.isGroup && c.isExternal == nodeDetail.isExternal
                                      && c.isUser == nodeDetail.isUser) < 0) // Avoid duplicates
            {
              node.children.push(nodeDetail);
            }          
          }
        });
      }
      if (node.children.length > 0)
      {
        this.shareNodes.push(node);
      }
    });
  }

  warnClick(event): void {
    // const modalRef = this.modalService.open(SharePointDelegateModalComponent, { windowClass: 'id-modal', centered: true, size: 'lg' });
    // modalRef.componentInstance.delegate = this.delegate;
    // modalRef.result.then((model: SharePointReportProfileAlertDelegateModel) => {
    //   model.endDate = this.endDate;
    //   model.providerInstanceResource = this.providerInstanceResource;
    //   this.subscriptions.add(this.microsoftDatasource.alertSharePointDelegate(this.providerInstanceId, this.userId, model).subscribe(model => {
    //     this.isProcessed = true;
    //   }));
    // }).catch((error) => {
    //   console.log(error);
    // });

  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}

import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import { MicrosoftOfficeProfileProviderDatasourceService, SharePointReportProfile, SharePointReportProfilePermissions } from '@i2a/web-api-client';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import dayJS from 'dayjs';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { SharePointReportProfilePermissionsWithAlert } from 'projects/web-user-profile/src/app/models/providers/microsoft/share-point/share-point-report-profile-permissions-with-alert';
import { SharePointExternalPermissionsModalComponent } from '../share-point-external-permissions-modal/share-point-external-permissions-modal.component';
import { SharePointReportProfileAlertExternalPermissionsModel } from '@i2a/web-api-client';
import { SharePointReportProfilePermissionsWithWarnings } from 'projects/web-user-profile/src/app/models/providers/microsoft/share-point/share-point-report-profile-permissions-with-warnings';

@Component({
  selector: 'i2a-share-point-external-permissions',
  templateUrl: './share-point-external-permissions.component.html',
  styleUrls: ['./share-point-external-permissions.component.scss']
})
export class SharePointExternalPermissionsComponent extends ProfileItemBaseClassComponent<SharePointReportProfile> implements OnInit, OnDestroy {
  @Input() public delegatesWithWarnings: SharePointReportProfilePermissionsWithWarnings[];
  @Input() public warnSensitivityLabel: boolean;

  public delegates: SharePointReportProfilePermissions[];
  public delegatesWithAlerts: SharePointReportProfilePermissionsWithAlert[];
  public name: string;
  public lastAccess: Date;

  constructor(
    modalService: NgbModal,
    toastr: ToastrService,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
  }

  setItems() {
    if (this.delegatesWithWarnings && this.alerts) {
      this.delegates = this.delegatesWithWarnings.map(d => d.model);
      this.lastAccess = null;
      super.setItems();
      this.hasAlert = false;
      this.delegatesWithAlerts = this.delegates.map(d => {
        var dWithAlert = {
          "providerInstanceResource": d.providerInstanceResource,
          "providerInstanceUser": d.providerInstanceUser,
          "userId": d.userId,
          "logonUserId": d.logonUserId,
          "isNameKey": d.isNameKey,
          "name": d.name,
          "userIsDeactivated": d.userIsDeactivated,
          "unreconcileAccount": d.unreconcileAccount,
          "lastAccess": d.lastAccess,
          "isAnonymousUser": d.isAnonymousUser,
          "isExternalUser": d.isExternalUser,
          "isApplication": d.isApplication,
          "isGroup": d.isGroup,
          "isTheWholeCompany": d.isTheWholeCompany,
          "foldersAccessed": d.foldersAccessed.sort(),
          "driveItems": d.driveItems,
          "hasAlert": AlertReasonHelper.alertsContainsDelegate(this.alerts, d, false),
          "hasAlertRecentlyClosed": AlertReasonHelper.alertsContainsDelegate(this.alerts, d, true),
          "isO365Group": d.isO365Group,
          "isSPGroup": d.isSPGroup,
          "isUnknown": d.isUnknown,
          "hasSensitivityLabel": d.hasSensitivityLabel,
          "hasPassword": d.hasPassword,
          "expirationDate": d.expirationDate
        };
        this.hasAlert = this.hasAlert || dWithAlert.hasAlert;
        this.hasAlertRecentlyClosed = this.hasAlertRecentlyClosed || dWithAlert.hasAlertRecentlyClosed;
        if (this.lastAccess == null || dayJS(d.lastAccess).isAfter(dayJS(this.lastAccess))) {
          this.lastAccess = d.lastAccess;
        }

        return dWithAlert;
      });
    }
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(SharePointExternalPermissionsModalComponent, { windowClass: 'id-modal', centered: true, size: 'lg' });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.permissions = this.delegates;
    modalRef.result.then((model: SharePointReportProfileAlertExternalPermissionsModel) => {
      this.initAlertModel(model);
      model.providerInstanceResource = this.providerInstanceResource;
      this.subscriptions.add(this.microsoftDatasource.alertSharePointExternalPermissions(this.profile.providerInstanceId, this.profile.userId, model).subscribe(model => {
        this.setAlertProcessed(model);
      }));
    }).catch((error) => {
      // console.log(error);
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

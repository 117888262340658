<i2a-profile-alert-base-modal [manualDescriptionKey]="'applyCorrection.providers.microsoft.fileshare.permission.manual.description'"
                              [manualLinkKey]="'applyCorrection.providers.microsoft.fileshare.permission.manual.link'"
                              [automaticDescriptionKey]="'applyCorrection.providers.microsoft.fileshare.permission.automatic.description'"
                              [validation]="validation" [canSend]="canSend"
                              [modalTitle]="'profile.confirmationOfInformationTitle' | i18next"
                              [resourceProfile]="resourceProfile">

  <ng-container header-picto>
    <i2a-delegate-photo [isAnonymous]="false" [isDefault]="false" [isApplication]="false" [isExternalUser]="false"
      [pir]="permissions.providerInstanceResource" [piu]="permissions.providerInstanceUser">
    </i2a-delegate-photo>
  </ng-container>

  <ng-container header-title>
    {{title}}<br>
  </ng-container>

  <ng-container body>
    <div class="container-fluid">
      <div class="row pt-3" *ngIf="paths && paths.length > 0">
        <div class="col-12">

          <div class="col form-group pl-1">
            <div class="no-gutters">
                <div class="row no-gutters delegate-container">
                    <div  class="col cell-checkbox">
                        <i2a-input-checkbox [(model)]="validation.allPaths" (modelChange)="allPathsChecked($event)"
                            [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                            [valueUnChecked]="false">
                        </i2a-input-checkbox>
                    </div>
                    <div class="col-11 delegate-title">{{ 'profile.provider.microsoft.fileShare.allPaths' | i18next }}</div>
                </div>
            </div>
        </div>
        <div class="form-group col-12" *ngIf="paths && !validation.allPaths">
            <table class="table table-sm id-table">
                <tbody>
                    <tr *ngFor="let path of paths ">
                        <td scope="row text-center" class="cell-checkbox">
                            <i2a-input-checkbox [(model)]="path.checked" (modelChange)="checkedPath($event, path)"
                                [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                                [valueUnChecked]="false"> </i2a-input-checkbox>
                        </td>
                        <td scope="row" class="text-break w-100">
                          <span *ngIf="path.path" >{{path.path}} </span>
                          <span *ngIf="!path.path">{{"profile.provider.microsoft.fileShare.emptyPath" |i18next}} </span>
                          <!-- <i2a-share-point-permissions-roles [roles]="driveItem.roles"></i2a-share-point-permissions-roles> -->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        </div>
      </div>
    </div>
  </ng-container>

</i2a-profile-alert-base-modal>
import { Component, OnInit, Input } from '@angular/core';
import { ExchangeReportProfileAlertDelegateModel, ExchangeReportProfileAlertPermissionsModel, ProviderAuditResourceKind, ReportProfileResourceBase } from '@i2a/web-api-client';
import { EnumHelper } from 'projects/web-user-profile/src/app/helpers/enum-helper';
import { ExchangeDelegate } from 'projects/web-user-profile/src/app/models/providers/microsoft/exchange/exchange-delegate';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-exchange-delegate-modal',
  templateUrl: './exchange-delegate-modal.component.html',
  styleUrls: ['./exchange-delegate-modal.component.scss']
})
export class ExchangeDelegateModalComponent implements OnInit {
  @Input() public delegate: ExchangeDelegate;
  @Input() public IsMailboxAccessed: boolean;
  @Input() public IsFoldersAccessed: boolean;
  @Input() public IsCalendarAccessed: boolean;

  @Input() public resourceProfile: ReportProfileResourceBase<any>;
  
  public validation: ExchangeReportProfileAlertDelegateModel;
  public canSend: boolean;
  public devices: any[];

  constructor(
    public session: SessionService
  ) {
    this.canSend = false;
  }

  private setCanSend() {
    setTimeout(() => {
      this.canSend = !this.validation.calendarAccessIsValid ||
        !this.validation.inboxAccessIsValid ||
        !this.validation.folderAccessIsValid ||
        this.validation.deviceSyncIds.length > 0 || (
          this.validation.permissions != null &&
          (!this.validation.permissions.hasCalendarRightsIsValid ||
            !this.validation.permissions.hasFoldersRightsIsValid ||
            !this.validation.permissions.hasFullAccessRightsIsValid ||
            !this.validation.permissions.hasOtherRightsIsValid ||
            !this.validation.permissions.hasSendAsRightsIsValid));
    });
  }

  ngOnInit() {
    this.validation = new ExchangeReportProfileAlertDelegateModel();
    this.devices = [];
    var accessChecked = false;
    var permissionsChecked = false;

    if (typeof this.delegate != 'undefined') {
      let numberOfPermissions = 0;
      if (typeof this.delegate.permissions != 'undefined') {
        if (this.delegate.permissions.hasFoldersRights) {
          numberOfPermissions++;
        }
        if (this.delegate.permissions.hasFullAccessRights) {
          numberOfPermissions++;
        }
        if (this.delegate.permissions.hasSendAsRights) {
          numberOfPermissions++;
        }
        if (this.delegate.permissions.hasOtherRights) {
          numberOfPermissions++;
        }
        if (this.delegate.permissions.hasCalendarRights) {
          numberOfPermissions++;
        }
      }

      if (!this.IsFoldersAccessed && !this.IsMailboxAccessed && !this.IsCalendarAccessed) {
        this.validation.calendarAccessIsValid = true;
        this.validation.folderAccessIsValid = true;
        this.validation.inboxAccessIsValid = true;
      }
      else {
        this.validation.calendarAccessIsValid = (this.IsFoldersAccessed || this.IsMailboxAccessed) || numberOfPermissions > 1;
        this.validation.folderAccessIsValid = this.IsCalendarAccessed || this.IsMailboxAccessed || numberOfPermissions > 1;
        this.validation.inboxAccessIsValid = this.IsFoldersAccessed || this.IsCalendarAccessed || numberOfPermissions > 1;
      }

      accessChecked = !this.validation.calendarAccessIsValid || !this.validation.folderAccessIsValid || !this.validation.inboxAccessIsValid;

      this.validation.delegate = this.delegate.accesses;
      if (typeof this.delegate.permissions != 'undefined') {
        this.validation.permissions = new ExchangeReportProfileAlertPermissionsModel();
        this.validation.permissions.invalidCalendars = [];
        this.validation.permissions.permissions = this.delegate.permissions;
        this.validation.permissions.hasCalendarRightsIsValid = ((this.delegate.permissions.hasFoldersRights ||
          this.delegate.permissions.hasFullAccessRights ||
          this.delegate.permissions.hasSendAsRights ||
          this.delegate.permissions.hasOtherRights) || !accessChecked) || !this.delegate.permissions.hasCalendarRights;
        Object.keys(this.delegate.permissions.foldersRights).forEach(path => {
          if (EnumHelper.HasFlag(this.delegate.permissions.foldersRights[path], ProviderAuditResourceKind.Calendar)) {
            this.validation.permissions.invalidCalendars.push(this.delegate.permissions.foldersIdentity[path]);
          }
        })


        this.validation.permissions.invalidFolders = [];
        this.validation.permissions.hasFoldersRightsIsValid = ((this.delegate.permissions.hasCalendarRights ||
          this.delegate.permissions.hasFullAccessRights ||
          this.delegate.permissions.hasSendAsRights ||
          this.delegate.permissions.hasOtherRights) || !accessChecked) || !this.delegate.permissions.hasFoldersRights;
        Object.keys(this.delegate.permissions.foldersRights).forEach(path => {
          if (!EnumHelper.HasFlag(this.delegate.permissions.foldersRights[path], ProviderAuditResourceKind.Calendar)) {
            this.validation.permissions.invalidFolders.push(this.delegate.permissions.foldersIdentity[path]);
          }
        })

        this.validation.permissions.hasFullAccessRightsIsValid = ((this.delegate.permissions.hasFoldersRights ||
          this.delegate.permissions.hasCalendarRights ||
          this.delegate.permissions.hasSendAsRights ||
          this.delegate.permissions.hasOtherRights) || !accessChecked) || !this.delegate.permissions.hasFullAccessRights;

        this.validation.permissions.hasOtherRightsIsValid = ((this.delegate.permissions.hasFoldersRights ||
          this.delegate.permissions.hasFullAccessRights ||
          this.delegate.permissions.hasSendAsRights ||
          this.delegate.permissions.hasCalendarRights) || !accessChecked) || !this.delegate.permissions.hasOtherRights;

        this.validation.permissions.hasSendAsRightsIsValid = ((this.delegate.permissions.hasFoldersRights ||
          this.delegate.permissions.hasFullAccessRights ||
          this.delegate.permissions.hasCalendarRights ||
          this.delegate.permissions.hasOtherRights) || !accessChecked) || !this.delegate.permissions.hasSendAsRights;

        if ((!this.validation.permissions.hasSendAsRightsIsValid && this.delegate.permissions.hasSendAsRights) ||
          (!this.validation.permissions.hasCalendarRightsIsValid && this.delegate.permissions.hasCalendarRights) ||
          (!this.validation.permissions.hasFoldersRightsIsValid && this.delegate.permissions.hasFoldersRights) ||
          (!this.validation.permissions.hasFullAccessRightsIsValid && this.delegate.permissions.hasFullAccessRights) ||
          (!this.validation.permissions.hasOtherRightsIsValid && this.delegate.permissions.hasOtherRights)) {
          permissionsChecked = true;
        }

      }

      this.validation.deviceSyncIds = [];
      this.delegate.accesses.devices.forEach(device => {
        this.devices.push({
          "deviceType": device.deviceType,
          "name": device.name,
          "syncId": device.syncId,
          "checked": false
        });
      });

      if ((this.devices.length > 1) || (accessChecked && !permissionsChecked)) {
        this.validation.calendarAccessIsValid = true;
        this.validation.folderAccessIsValid = true;
        this.validation.inboxAccessIsValid = true;
        this.validation.permissions.hasSendAsRightsIsValid = true;
        this.validation.permissions.hasCalendarRightsIsValid = true;
        this.validation.permissions.hasFoldersRightsIsValid = true;
        this.validation.permissions.hasFullAccessRightsIsValid = true;
        this.validation.permissions.hasOtherRightsIsValid = true;
      }
    }

    this.setCanSend();
  }

  checked(isChecked: boolean, device: any) {
    const index: number = this.validation.deviceSyncIds.indexOf(device.syncId);
    if (isChecked && index === -1) {
      this.validation.deviceSyncIds.push(device.syncId);
    }
    else if (!isChecked && index !== -1) {
      this.validation.deviceSyncIds.splice(index, 1);
    }
    this.setCanSend()
  }
}

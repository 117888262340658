import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { StringHelper } from '../../../../helpers/string-helper';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'i2a-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() public headerStyle: any;
  public useBackgroundImage:Boolean;
  public safeHeaderStyle: SafeStyle;
  constructor(
    private sanitizer: DomSanitizer
    ) {
    this.useBackgroundImage = true;
  }

  ngOnInit(): void {
    if (this.headerStyle != null)
    {
      this.useBackgroundImage = false;
      this.safeHeaderStyle = this.headerStyle;
    } else {
      this.safeHeaderStyle = this.sanitizer.bypassSecurityTrustStyle("--color: #2B3146;--colorHover:#9c9364");
    }
  }

  ngOnDestroy(): void {
  }

}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'i2a-short-title-bar',
  templateUrl: './short-title-bar.component.html',
  styleUrls: ['./short-title-bar.component.scss']
})
export class ShortTitleBarComponent implements OnInit {
  @Input() public right: boolean = true;
  @Input() public hasAlert: boolean;
  @Input() public hasAlertRecentlyClosed: boolean;
  @Input() public isCollapsed: boolean;
  @Input() public noBackground: boolean = false;

  constructor(
  ) {
    this.isCollapsed = true;
  }

  ngOnInit() {

  }

}

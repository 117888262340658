<i2a-spinner *ngIf="!profile"></i2a-spinner>
<a [id]="'user' + profile.userId">
</a>
<div class="user-container">
  <i2a-title-bar [rounded]="false" [id]="'user'" [linked]="true" [guidedTourKey]="guidedTourKey"
    [className]="'title-user'" [isCollapsed]="isCollapsed">
    
    <ng-container picture>
      <i2a-user-photo *ngIf="profile && profile.userProfile" [userId]="profile.userProfile.userId" [size]="40">
      </i2a-user-photo>
    </ng-container>

    <ng-container title>
      <span class="id-user-activity" *ngIf="user && connectedAccount">
        {{ 'profile.title.myActivity' | i18next }}
      </span>
    </ng-container>

    <ng-container buttons>
      <div *ngIf="configuration.viewAlerts && alerts && alerts.length > 0"
        class="float-right pr-2 id-collapse my-auto id-button-alert" [title]="'profile.openAlertHint' | i18next "
        (click)="showOpenAlerts()">
        <fa-icon [icon]="['far', 'shield-alt']" style="font-size: 1.5rem"></fa-icon>
      </div>
    </ng-container>

    <ng-container body>
      <i2a-user-nodata *ngIf="hasNoData">
      </i2a-user-nodata>
      <div class="module">
        <div class="container-fluid p-0">
          <div class="row no-gutters">
            <template i2a-container></template>
          </div>
        </div>
      </div>
    </ng-container>
    
  </i2a-title-bar>
</div>
import { BaseProviderInstanceUser } from '../base-provider-instance-user';
import { ReportProfileAccessModeModel } from '../profile/report-profile-access-mode-model';
import { ReportProfileDeviceModel } from '../profile/report-profile-device-model';
import { ReportProfileDeviceSyncModel } from '../profile/report-profile-device-sync-model';
import { ReportProfileOriginByProderTypeModel } from '../profile/report-profile-origin-by-proder-type-model';
import { ReportProfileUsualCountriesModel } from '../profile/report-profile-usual-countries-model';

export class ReportProfileUser {
  userId: string|null;
  providerInstanceUsers: BaseProviderInstanceUser[];
  accessModes: ReportProfileAccessModeModel[];
  origins: ReportProfileOriginByProderTypeModel[];
  usualCountries: ReportProfileUsualCountriesModel[];
  devices: ReportProfileDeviceModel[];
  notRegisteredDevices: ReportProfileDeviceSyncModel[];
}

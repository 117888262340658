import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ListHelper {

    constructor() {
    }

    public static distinct(list: any[], propertyName: string) {
        return list.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj[propertyName]).indexOf(obj[propertyName]) === pos;
        });
    }
}

import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

@Injectable()
@Pipe({ name: 'SafeHtml' })
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitized: DomSanitizer) { }
  
  transform(value: string): SafeHtml {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
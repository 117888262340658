import { Component, OnInit, Input, OnDestroy, Inject, ViewChild, TemplateRef } from '@angular/core';
import { ExchangeReportProfileInboxRule, ExchangeReportProfileAlertInboxRuleModel, MicrosoftOfficeProfileProviderDatasourceService, AlertConstants, ExchangeReportProfile, ProviderInstanceResourceSummaryModel, BaseProviderInstanceResourceModel, AlertInvalidStateFeaturedModel } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExchangeInboxruleModalComponent } from '../exchange-inboxrule-modal/exchange-inboxrule-modal.component';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { StringHelper } from 'projects/web-user-profile/src/app/helpers/string-helper';
import { ToastrService } from 'ngx-toastr';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { InformationModalComponent } from 'projects/web-user-profile/src/app/components/shared/modal/information-modal/information-modal.component';
import { WarningInfoType } from 'projects/web-user-profile/src/app/models/warnings/warning-info-type';
import { WarningHelper } from 'projects/web-user-profile/src/app/helpers/warning-helper';
import { ActivatedRoute, Router } from '@angular/router';
import { ProviderHelper } from 'projects/web-user-profile/src/app/helpers/provider-helper';
import { ExchangeReportProfileInboxRuleWithWarrnings } from 'projects/web-user-profile/src/app/models/providers/microsoft/exchange/exchange-report-profile-inbox-rule-with-warnings';

@Component({
  selector: 'i2a-exchange-inbox-rule',
  templateUrl: './exchange-inbox-rule.component.html',
  styleUrls: ['./exchange-inbox-rule.component.scss']
})
export class ExchangeInboxRuleComponent extends ProfileItemBaseClassComponent<ExchangeReportProfile> implements OnInit, OnDestroy {
  @Input() public inboxRuleWithWarnings: ExchangeReportProfileInboxRuleWithWarrnings;
  public inboxRule: ExchangeReportProfileInboxRule;

  public title: string;
  public description: string[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    modalService: NgbModal,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.inboxRuleWithWarnings) {
      this.inboxRule = this.inboxRuleWithWarnings.model;
      this.warnings = this.inboxRuleWithWarnings.warnings;
      this.warningLevel = this.inboxRuleWithWarnings.warnings ? this.inboxRuleWithWarnings.warnings[0].warningLevel : null;
      this.description = [];
      this.id = this.inboxRuleWithWarnings.id;
      this.title = this.inboxRule.name;
      this.inboxRule.description.split("\n").forEach(line => {
        if (!StringHelper.isNullOrWhitespace(line)) {
          this.description.push(line);
        }
      });
      this.subscriptions.add(WarningHelper.openModalOnRoute(this.router, this.route, this.id, this.openDetails.bind(this), this.warnClick.bind(this)));
    }
  }

  public static getId(componentId: string, providerInstanceResource: BaseProviderInstanceResourceModel, title: string): string {
    return `${componentId}-${providerInstanceResource.providerTypeId}-${providerInstanceResource.providerInstanceResourceId}-${title.replaceAll(' ', '-')}-ExchangeInboxRule`;
  }

  setItems() {
    if (this.inboxRuleWithWarnings?.model && this.alerts) {
      super.setItems();
      this.hasAlert = this.hasAlertInAlerts(false);
      this.hasAlertRecentlyClosed = this.hasAlertInAlerts(true);
    }
  }

  private hasAlertInAlerts(done: boolean): boolean {
    return AlertReasonHelper.alertsContainsKeyValuePair(this.alerts, AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_INBOX_RULE_IDENTITY, this.inboxRuleWithWarnings?.model?.identity, done);
  }

  public static getInboxRuleWithWarning(i18Next: ITranslationService, componentId: string, resourceProfile: ExchangeReportProfile, inboxRule: ExchangeReportProfileInboxRule, alerts: AlertInvalidStateFeaturedModel[]): ExchangeReportProfileInboxRuleWithWarrnings {
    let inboxRuleWithWarnings: ExchangeReportProfileInboxRuleWithWarrnings = {
      model: inboxRule,
      hasAlert: AlertReasonHelper.alertsContainsKeyValuePair(alerts, AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_INBOX_RULE_IDENTITY, inboxRule.identity, true),
      hasAlertRecentlyClosed: AlertReasonHelper.alertsContainsKeyValuePair(alerts, AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_INBOX_RULE_IDENTITY, inboxRule.identity, true),
      id: ExchangeInboxRuleComponent.getId(componentId, resourceProfile.providerInstanceResource, inboxRule.name),
      warnings: null
    };

    if (inboxRule.isExternalForward) {
      let warningInfoType = WarningInfoType.ExternalForward;
      let warningLevel = WarningHelper.GetWarningLevel(warningInfoType);
      inboxRuleWithWarnings.warnings = [{
        id: inboxRuleWithWarnings.id,
        resourceId: 'application' + resourceProfile.providerInstanceResource.providerTypeId.toLocaleLowerCase() + resourceProfile.providerInstanceResource.providerInstanceResourceId.toLocaleLowerCase(),
        resourceProfile: resourceProfile,
        warningInfoType: warningInfoType,
        warningLevel: warningLevel,
        label: `${i18Next.t(ProviderHelper.GetProviderTypeLabel(resourceProfile.providerInstanceResource.providerId, resourceProfile.providerInstanceResource.providerTypeId))} > ${(<ProviderInstanceResourceSummaryModel>resourceProfile.providerInstanceResource).name}`,
        hasAlert: inboxRuleWithWarnings.hasAlert,
        hasAlertRecentlyClosed: inboxRuleWithWarnings.hasAlertRecentlyClosed,
        warningInfo: 'warning.info.type.provider.microsoft.exchange.externalForward',
        viewMoreDetails: true,
        viewDissmiss: true,
        viewResource: false
      }];
    }

    return inboxRuleWithWarnings;
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(ExchangeInboxruleModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.inboxRule = this.inboxRule;
    modalRef.result.then((model: ExchangeReportProfileAlertInboxRuleModel) => {
      this.initAlertModel(model);
      model.providerInstanceResource = this.providerInstanceResource;
      model.inboxRule = this.inboxRule;
      this.subscriptions.add(this.microsoftDatasource.alertExchangeInboxRule(this.profile.providerInstanceId, this.profile.userId, model).subscribe(model => {
        this.setAlertProcessed(model);
      }));
    }).catch((error) => {
      // console.log(error);
    });

  }


  @ViewChild('viewDetailTemplate') viewDetailTemplate: TemplateRef<any>;

  openDetails(event): void {
    const modalRef = this.modalService.open(InformationModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.title = this.i18Next.t("modal.detail.title");
    modalRef.componentInstance.bodyTemplate = this.viewDetailTemplate;
    modalRef.componentInstance.ok = this.i18Next.t("button.close");
    modalRef.result.then((model: any) => {
      if (model == true) {
        this.warnClick(null);
      }
    }, (reason) => {
      //Dismiss
    });
  }


  ngOnDestroy() {
    super.ngOnDestroy();
  }
}

<div class="module">
  <div class="container-fluid module-container" *ngIf="resourceProfile && resourceProfile.filterRules.length > 0 ">
    <div class="row no-gutters">
      <i2a-sub-title class="col-12" [subTitle]="'profile.provider.zimbra.mailing.title.rules' | i18next "
        [description]="'profile.provider.zimbra.mailing.description.rules' | i18next ">
      </i2a-sub-title>
    </div>
    <div class="row no-gutters">
      <div *ngFor="let filterRule of resourceProfile.filterRules" class="col-sm-12 col-md-6 col-lg-4 align-items-start">
        <i2a-zimbra-filter-rule [resourceProfile]="resourceProfile" [componentId]="componentId" [isAlert]="isAlert" [alerts]="alerts"
          [providerInstanceResource]="resourceProfile.providerInstanceResource" [profile]="profile" [user]="user"
          [filterRule]="filterRule" (onAlertSent)="alertSent($event)" (onHasWarnings)="hasWarnings($event)">
        </i2a-zimbra-filter-rule>
      </div>
    </div>
  </div>
</div>

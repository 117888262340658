<i2a-badge-icon *ngIf="customIcon">
  <ng-container icon>
    <fa-icon [icon]="customIcon" size="lg"></fa-icon>
  </ng-container>
</i2a-badge-icon>

<i2a-badge-icon *ngIf="isAnonymous" [customClass]="'id-bagde-anonymous'">
  <ng-container icon>
    <fa-icon [icon]="['far', 'globe']" class="anonymous" size="lg"></fa-icon>
  </ng-container>
</i2a-badge-icon>

<i2a-badge-icon *ngIf="isDefault" [customClass]="'id-bagde-whole-company'">
  <ng-container icon>
    <fa-icon [icon]="['far', 'briefcase']" class="whole-company" size="lg"></fa-icon>
  </ng-container>
</i2a-badge-icon>

<i2a-badge-icon *ngIf="isApplication">
  <ng-container icon>
    <fa-icon [icon]="['fal', 'robot']" size="lg"></fa-icon>
  </ng-container>
</i2a-badge-icon>

<i2a-badge-icon *ngIf="isExternalUser">
  <ng-container icon>
    <fa-icon [icon]="['fal', 'id-card-alt']" size="lg"></fa-icon>
  </ng-container>
</i2a-badge-icon>

<i2a-badge-icon *ngIf="isUnknown">
  <ng-container icon>
    <fa-layers>
      <fa-icon [icon]="['fas', 'users']" transform="shrink-3 up-4 right-6"></fa-icon>
      <fa-icon [icon]="['fas', 'slash']" transform="left-4"></fa-icon>
      <fa-icon [icon]="['fas', 'user']" transform="shrink-5 down-4 left-9"></fa-icon>
    </fa-layers>
  </ng-container>
</i2a-badge-icon>

<i2a-resource-photo [providerInstanceResource]="pir" [size]="40"
  *ngIf="pir && !isAnonymous && !isDefault && !isApplication && !isExternalUser && !isUnknown && customIcon == null">
</i2a-resource-photo>

<i2a-piu-photo [providerInstanceUser]="piu" [size]="40"
  *ngIf="piu && !pir && !isAnonymous && !isDefault && !isApplication && !isExternalUser && !isUnknown && customIcon == null">
</i2a-piu-photo>

<img class="id-photo" *ngIf="defaultUrl != ''" src="{{defaultUrl}}" width="40" height="40">

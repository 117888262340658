import { Injectable, OnDestroy, Inject } from '@angular/core';
import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { ConfigService, IConfigService, I2A_CONFIG_SERVICE, IWebConfig } from '../infrastructure/config.service';
import { ContextService } from '../infrastructure/context.service';
import * as Oidc from 'oidc-client';
import { ToastrService } from 'ngx-toastr';
import { StringHelper } from '../helpers/string-helper';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {
  private subscriptions = new Subscription();

  private manager: UserManager;
  private centralManager: UserManager;

  //#region User
  private userSubject?: BehaviorSubject<User>;
  public User$: Observable<User>;

  get User(): User {
    return this.userSubject.value;
  }

  set User(user: User) {
    this.userSubject.next(user);
  }
  //#endregion

  private canActivateUrl: string;

  constructor(
    public toastr: ToastrService,
    @Inject(I2A_CONFIG_SERVICE) private configService: IConfigService<IWebConfig>,
    private context: ContextService,
    private router: Router) {


    this.userSubject = new BehaviorSubject<User>(null);
    this.User$ = this.userSubject.asObservable();

    let loginHint = this.router.getCurrentNavigation().finalUrl.queryParams["login_hint"];
    this.manager = this.InitManager(this.getClientSettings(loginHint));
    this.centralManager = this.InitManager(this.getClientCentralSettings(loginHint));
  }


  InitManager(settings: UserManagerSettings): UserManager {
    let manager = new UserManager(settings);
    manager.events.addUserLoaded((user) => {
      console.log("User loaded");
      this.User = user;
    });
    manager.events.addSilentRenewError(function (error) {
      console.log(error);
    });
    manager.events.addAccessTokenExpired((e) => {
      this.startAuthentication();
    });

    return manager;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  canActivate(url: string): boolean {
    let isLoggedIn = this.isLoggedIn()
    if (!isLoggedIn) {
      //used to save the current url needed in the redirection after the loggin
      this.canActivateUrl = url;
    }
    return isLoggedIn;
  }

  isLoggedIn(): boolean {
    return this.User != null && !this.User.expired;
  }

  getClaims(): any {
    return this.User.profile;
  }

  getAuthorizationHeaderValue(): string {
    return `${this.User.token_type} ${this.User.access_token}`;
  }

  startAuthentication(): Promise<void> {
    console.log("start authentication");
    let state = this.getI2AClientStateString({ url: this.canActivateUrl });
    return this.manager.signinRedirect({ state: state }).catch(error => {
      console.error("Problem with authentication endpoint: ", error);
      if (error.message == "Network Error") {
        this.toastr.error('An error occured', 'Check your network');
      }
      else {
        this.toastr.error('An error occured', error.message);
      }
    });
  }

  logout(): Promise<void> {
    console.log("Logout");
    if (this.User.profile.idp == "i2a_central") {
      return this.centralManager.signoutRedirect({ post_logout_redirect_uri: this.getClientCentralSettings().post_logout_redirect_uri });
    }
    else {
      return this.manager.signoutRedirect({ post_logout_redirect_uri: this.getClientSettings().post_logout_redirect_uri });
    }
  }

  getBaseUrl(): string {
    var bases = document.getElementsByTagName('base');
    var baseHref = null;

    if (bases.length > 0) {
      baseHref = document.getElementsByTagName('base')[0].href.replace(/\/$/g, '');
    } else {
      baseHref = window.location.origin;
    }
    return baseHref;
  }

  public changePassword() {
    let redirectURI = encodeURIComponent(this.getBaseUrl());
    let url = this.configService.Config.oAuth.changePasswordUrl +
      `tenants/${this.context.TenantCode}/account/ChangePassword?` +
      `userName=${this.User.profile.sub}` +
      `&returnUrl=${redirectURI}`;

    window.location.replace(url);
  }

  cleanStates() {
    //Removes stale state entries in storage for incomplete authorize requests.
    this.centralManager.clearStaleState()
    this.manager.clearStaleState()
  }

  async completeCentralAuthentication(snapshot: ActivatedRouteSnapshot): Promise<void> {
    console.log("Complete Central authentication");
    let stateStr: string = await this.centralManager.settings.stateStore.get(snapshot.queryParams.state);
    if (!!stateStr) {
      let state = JSON.parse(stateStr);
      state.authority = this.centralManager.settings.authority;
      state.redirect_uri = this.centralManager.settings.redirect_uri;
      await this.centralManager.settings.stateStore.set(snapshot.queryParams.state, JSON.stringify(state));
      let self = this;
      return new Promise(function (resolve, recject) {
        return self.centralManager.signinRedirectCallback().then(user => {
          console.log("signinRedirectCallback central")
          self.redirectToRouteAfterAuthentication();
          resolve();
        }).catch(function (err) {
          console.log(err);
          self.router.navigate(["/"]);
          resolve();
        });
      });
    }
    else {
      this.router.navigate(["/"]);
    }
  }
  async completeAuthentication(): Promise<void> {
    console.log("Complete authentication");
    let self = this;
    return new Promise(function (resolve, recject) {
      self.manager.signinRedirectCallback().then(user => {
        console.log("signinRedirectCallback local")
        self.redirectToRouteAfterAuthentication();
        resolve();
      }).catch(function (err) {
        console.log(err);
        self.router.navigate(["/"]);
        resolve();
      })
    });
  }

  redirectToRouteAfterAuthentication() {
    console.log("redirect after authentication");
    let state = this.getI2AClientStateObject(this.User.state);
    if (state === undefined || state.url === undefined || state.url === null || state.url.indexOf("/auth") > -1 || state.url === "/") {
      console.log('authenticated => redirect to index');
      this.router.navigate(["/"]);
    } else {
      console.log(`authenticated => location = ${state.url}`);
      this.router.navigateByUrl(state.url);
    }
  }

  getI2AClientStateString(myJsonState) {
    return encodeURIComponent(`i2a_client_state:${JSON.stringify(myJsonState)}`);
  }

  getI2AClientStateObject(fullStateString) {
    let res;

    if (fullStateString) {
      let decodedString = decodeURIComponent(fullStateString);
      let splitedArray = decodedString.split(';');
      for (var i = 0; i < splitedArray.length; i++) {
        if (splitedArray[i].indexOf('i2a_client_state:') > -1) {
          let json = splitedArray[i].substring(splitedArray[i].indexOf(":") + 1);
          res = JSON.parse(json);
        }
      }
    }
    return res;
  }

  getClientSettings(loginHint: string = null): UserManagerSettings {
    let baseUrl = this.getBaseUrl();
    let retValue = {
      authority: this.configService.Config.oAuth.authority,
      client_id: this.configService.Config.oAuth.clientId,
      redirect_uri: `${baseUrl}/auth-callback`,
      post_logout_redirect_uri: `${baseUrl}/sign-out`,
      response_type: "code",
      scope: this.configService.Config.oAuth.scopes,
      filterProtocolClaims: true,
      loadUserInfo: true,
      automaticSilentRenew: true,
      silent_redirect_uri: `${baseUrl}/silent-refresh.html`,
      extraQueryParams: {}
    };
    if (!StringHelper.isNullOrWhitespace(loginHint)) {
      retValue.extraQueryParams = { login_hint: loginHint };
    }
    return retValue;
  }

  getClientCentralSettings(loginHint: string = null): UserManagerSettings {
    let baseUrl = this.getBaseUrl();
    let retValue = {
      authority: this.configService.Config.oAuth.centralAuthority,
      client_id: this.configService.Config.oAuth.clientId,
      redirect_uri: `${baseUrl}/auth-callback-central`,
      post_logout_redirect_uri: `${baseUrl}/sign-out`,
      response_type: "code",
      scope: this.configService.Config.oAuth.scopes,
      filterProtocolClaims: true,
      loadUserInfo: true,
      automaticSilentRenew: true,
      silent_redirect_uri: `${baseUrl}/silent-refresh.html`,
      extraQueryParams: { }
    };
    if (!StringHelper.isNullOrWhitespace(loginHint)) {
      retValue.extraQueryParams = { login_hint: loginHint };
    }
    return retValue;
  }
}

import { AddressModel } from '../address-model';

export class ReportProfileOriginModel {
  ignoreGeocoding: boolean;
  displayName: string;
  originLabel: string;
  providerName: string;
  address: AddressModel;
  iPAddressConnectionType: string;
}

import { Component, OnInit, Inject } from '@angular/core';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { ReportProfileBaseModel, UserModel, BaseProviderInstanceResourceModel, MicrosoftOfficeReportProfileModel, AzureADReportProfile, AlertInvalidStateFeaturedModel, AzureADAppPermissionGrantModel, AzureADAppPermissionsModel } from '@i2a/web-api-client';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { AzureADPermissionGrantsConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/azure-ad-permission-grants-configuration';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';

@RegisterMyProfileComponent(TemplateConstants.MODULE_AZUREAD_PERMISSION_GRANTS)
@Component({
  selector: 'i2a-azure-ad-permission-grants-module',
  templateUrl: './azure-ad-permission-grants-module.component.html',
  styleUrls: ['./azure-ad-permission-grants-module.component.scss']
})
export class AzureAdPermissionGrantsModuleComponent extends ProfileBaseClassComponent<AzureADPermissionGrantsConfiguration,AzureADReportProfile> implements OnInit, IModule<AzureADPermissionGrantsConfiguration> {
  public grants: AzureADAppPermissionGrantModel[]
  
  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, null);
  }

  ngOnInit() {
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: AzureADPermissionGrantsConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel, resourceTemplate?: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.resourceProfile = (<MicrosoftOfficeReportProfileModel>profile).azureADProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
    this.grants = AzureAdPermissionGrantsModuleComponent.SetGrants(this.resourceProfile.azureADAppPermissions)
  }

  public static SetGrants(appPermissions: AzureADAppPermissionsModel): AzureADAppPermissionGrantModel[] {
    return appPermissions.azureADAppPermissionGrants;
  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }

  updateAlerts(alerts: AlertInvalidStateFeaturedModel[]) {
    super.updateAlerts(alerts);

    this.alerts = alerts;
    this.setItems();//SetAlerts instead of reset all
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'i2a-modal-profile-header-title',
  templateUrl: './modal-profile-header-title.component.html',
  styleUrls: ['./modal-profile-header-title.component.scss']
})
export class ModalProfileHeaderTitleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="container no-gutters resource-last-access">
  <div class="row no-gutters info-container">
    <div class="col-sm-12 col-md-4 last-access">
      <span>
        <fa-icon [icon]="['fal', 'user-clock']" size="lg"></fa-icon>
      </span>
      <span *ngIf="!visible">
        {{ "profile.noAccessFound" | i18next }}
      </span>
      <span *ngIf="visible">
        {{"profile.lastAccess" | i18next : {"lastAccess": resourceProfile.lastAccess | amDateFormat: 'LLL'} }}
      </span>
    </div>
    <div class="col-sm-12 col-md-4 alerts" *ngIf="alerts.length > 0">
      <div class="row">
        <div class="col-1">
          <span>
            <fa-icon [icon]="['far', 'shield-alt']" size="lg"></fa-icon>
          </span>
        </div>
        <div class="col-11">
          <div class="row">
            <div class="col-12">
              <span *ngIf="alertsNotClosed.length > 0">
                {{"profile.alertsReported" | i18next : {"alertNumber": alertsNotClosed.length } }}
              </span>
            </div>

            <div class="col-12">
              <span *ngIf="alertsClosed.length > 0">
                {{"profile.alertsDone" | i18next : {"alertNumber": alertsClosed.length } }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
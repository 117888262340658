<i2a-profile-card [id]="id" *ngIf="delegate" [title]="delegate.accesses.name" [viewMoreDetails]="viewMoreDetails"
  (onViewMoreDetails)="openDetails($event)" [subTitle]="delegate.accesses.lastAccess | amDateFormat: 'LLL'"
  (onWarnClick)="warnClick($event)" [hasAlert]="hasAlert" [hasAlertRecentlyClosed]="hasAlertRecentlyClosed"
  [warningLevel]="warningLevel"
  [buttonOpenAlertModalKey]="delegate.permissions != null && delegate.permissions.length > 0 ? 'button.openAlertModal' : 'button.openAlertModalAnomaly' ">

  <ng-container picto>
    <i2a-resource-photo [providerInstanceResource]="delegate.accesses.providerInstanceResource" [size]="40">
    </i2a-resource-photo>
  </ng-container>

  <ng-container content>

    <i2a-delegate-configuration-box [displayInfo]="true" [isInboxEnabled]="IsMailboxAccessed"
      [inboxInfo]="'profile.provider.microsoft.exchange.accessDetected' | i18next " [displayIsInbox]="true"
      [isCalendarEnabled]="IsCalendarAccessed"
      [calendarInfo]="'profile.provider.microsoft.exchange.accessDetected' | i18next " [displayIsCalendar]="true"
      [isFoldersEnabled]="IsFoldersAccessed"
      [foldersInfo]="'profile.provider.microsoft.exchange.accessDetected' | i18next " [displayIsFolders]="true">
    </i2a-delegate-configuration-box>

    <i2a-delegate-device [deviceSyncModels]="delegate.accesses.devices"
      *ngIf="configuration && configuration.displayUserRegisteredDevices">
    </i2a-delegate-device>

    <div class="ml-2 mb-3">
      <i2a-exchange-permission-rights [permissions]="delegate.permissions"></i2a-exchange-permission-rights>
    </div>

  </ng-container>

</i2a-profile-card>

<ng-template #viewDetailTemplate>
  <i2a-modal-profile-header-title>
    <ng-container picto>
      <i2a-resource-photo [providerInstanceResource]="delegate.accesses.providerInstanceResource" [size]="40">
      </i2a-resource-photo>
    </ng-container>
    <ng-container title>
      <span [title]="delegate.accesses.name">{{delegate.accesses.name}}</span>
    </ng-container>
  </i2a-modal-profile-header-title>

  <div class="ml-2 mb-3">
    <i2a-exchange-permission-rights [permissions]="delegate.permissions"></i2a-exchange-permission-rights>
  </div>
  <i2a-exchange-permission-detail *ngIf="delegate.permissions != null"
    [ExchangeFoldersRights]="delegate.permissions.exchangeFoldersRights"></i2a-exchange-permission-detail>
</ng-template>
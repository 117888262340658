import { Component, OnInit, Input, Inject } from '@angular/core';
import { FileShareReportProfileDelegate, FileShareReportProfileAlertDelegateModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-base-file-share-delegate-modal',
  templateUrl: './base-file-share-delegate-modal.component.html',
  styleUrls: ['./base-file-share-delegate-modal.component.scss']
})
export class BaseFileShareDelegateModalComponent implements OnInit {
  @Input() public delegate: FileShareReportProfileDelegate;

  @Input() public resourceProfile: ReportProfileResourceBase<any>;
  
  public validation: FileShareReportProfileAlertDelegateModel;
  public canSend: boolean;
  public folders: any[];//TODO CRADO
  public name: string;

  public driveItemsPermissions: any[];//TODO CRADO

  constructor(
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService,
    public session: SessionService
  ) {
    this.canSend = false;
  }

  public setCanSend() {
    setTimeout(() => {
      this.canSend = this.validation.folders.length > 0 || this.validation.allFolders;
    });
  }

  ngOnInit() {
    this.validation = new FileShareReportProfileAlertDelegateModel();
    this.validation.folders = [];
    this.validation.allFolders = true;
    this.validation.delegate = this.delegate;
    this.folders = [];
    this.driveItemsPermissions = [];
    if (this.delegate) {
      this.delegate.foldersAccessed.forEach(folder => {
        this.folders.push({
          "name": folder,
          "checked": false
        });
      });
      if (this.delegate.isAnonymousUser) {
        this.name = this.i18Next.t("profile.provider.microsoft.FileShare.anonymous");
      }
      else {
        this.name = this.delegate.name;
      }
      this.setCanSend();
    }
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  public headerStyle: any;

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(I18NEXT_SERVICE) private translateI18Next: ITranslationService,
  ) { }

  ngOnInit() {
    if (this.translateI18Next.exists('style.header', {})) {
      this.headerStyle = this.sanitizer.bypassSecurityTrustStyle(this.translateI18Next.t('style.header'));
    }
    else { 
      this.headerStyle = null;
    }
  }
}

import { OnInit, Input, OnDestroy, Inject, Component, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { Observable, of } from 'rxjs';
import { BaseFileShareDelegateModalComponent } from '../base-file-share-delegate-modal/base-file-share-delegate-modal.component';
import { FolderHelper } from 'projects/web-user-profile/src/app/helpers/folder-helper';
import { ToastrService } from 'ngx-toastr';
import { FileShareReportProfileDelegate, FileShareReportProfileAlertDelegateModel, SharePointReportProfile } from '@i2a/web-api-client';
import { DelegateFolders } from 'projects/web-user-profile/src/app/models/delegate-folders';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { InformationModalComponent } from 'projects/web-user-profile/src/app/components/shared/modal/information-modal/information-modal.component';
import { StringHelper } from 'projects/web-user-profile/src/app/helpers/string-helper';

@Component({
  selector: 'i2a-base-file-share-delegate',
  template: ''
})
export class BaseFileShareDelegateComponent extends ProfileItemBaseClassComponent<SharePointReportProfile> implements OnInit, OnDestroy {

  @Input() public delegate: FileShareReportProfileDelegate;

  public name: string;
  public baseFolders: string[];

  public delegateFolders: DelegateFolders;

  constructor(
    modalService: NgbModal,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
  }

  setItems() {
    if (this.delegate &&  this.alerts) {
      super.setItems();
      this.name = this.delegate.name;
      this.baseFolders = FolderHelper.RemoveFolderPartsAboveMaxDepthLevel(this.delegate.foldersAccessed, 2);
      this.baseFolders = FolderHelper.CompleteFolders(this.baseFolders, 1);
      this.hasAlert = AlertReasonHelper.alertsContainsDelegate(this.alerts, this.delegate, false);
      this.hasAlertRecentlyClosed = AlertReasonHelper.alertsContainsDelegate(this.alerts, this.delegate, true);
      this.delegate.foldersAccessed = this.delegate.foldersAccessed.map(m => {
        if (StringHelper.isNullOrWhitespace(m)) {
          return "/";
        } else {
          return m
        }
      }).sort(((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' })));

      this.delegateFolders = new DelegateFolders({
        folders: this.delegate.foldersAccessed,
        //driveItems: this.delegate.permissions != null ? this.delegate.permissions.driveItems : null,
        isAnonymous: this.delegate.isAnonymousUser,
        isApplication: this.delegate.isApplication,
        isExternalUser: false,
        isTheWholeCompany: false,
        logonUserId: this.delegate.logonUserId,
        name: this.delegate.name,
        providerInstanceResource: this.delegate.providerInstanceResource,
        providerInstanceUser: this.delegate.providerInstanceUser,
        userId: this.delegate.userId
      });
    }

  }

  protected AlertFileShareDelegate(model: FileShareReportProfileAlertDelegateModel): Observable<any> {
    return of();
  }

  protected AlertFileShareDelegates(model: FileShareReportProfileAlertDelegateModel): Observable<any[]> {
    return of();
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(BaseFileShareDelegateModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.delegate = this.delegate;
    modalRef.result.then((model: FileShareReportProfileAlertDelegateModel) => {
      this.initAlertModel(model);
      model.providerInstanceResource = this.providerInstanceResource;
      this.subscriptions.add(this.AlertFileShareDelegate(model).subscribe(model => {
        this.setAlertProcessed(model);
      }));
      this.subscriptions.add(this.AlertFileShareDelegates(model).subscribe(model => {
        this.setAlertsProcessed(model);
      }));
    }).catch((error) => {
      // console.log(error);
    });
  }

  @ViewChild('viewDetailTemplate') viewDetailTemplate: TemplateRef<any>;

  openDetails(event): void {
    const modalRef = this.modalService.open(InformationModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.title = this.i18Next.t("modal.detail.title");
    modalRef.componentInstance.bodyTemplate = this.viewDetailTemplate;
    modalRef.componentInstance.ok = this.i18Next.t("button.close");
    modalRef.result.then((model: any) => {
      //ok
    }, (reason) => {
      //Dismiss
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

<i2a-profile-alert-base-modal
  [manualDescriptionKey]="'applyCorrection.providers.microsoft.exchange.inboxRule.manual.description'"
  [manualLinkKey]="'applyCorrection.providers.microsoft.exchange.inboxRule.manual.link'"
  [automaticDescriptionKey]="'applyCorrection.providers.microsoft.exchange.inboxRule.automatic.description'"
  [ignoreCommentToSend]="session.IgnoreCommentToSend" [validation]="validation" [canSend]="canSend"
  [modalTitle]="'profile.confirmationOfInformationTitle' | i18next " [resourceProfile]="resourceProfile">

  <ng-container header-picto>
      <i2a-badge-icon>
          <ng-container icon>
            <fa-layers>
              <fa-icon size="lg" [icon]="['fal', 'envelope']" transform="left-5"></fa-icon>
              <fa-icon size="lg" [icon]="['fal', 'arrow-from-left']" transform="right-9"></fa-icon>
            </fa-layers>
          </ng-container>
        </i2a-badge-icon>
      </ng-container>

  <ng-container header-title>
    {{inboxRule.name}}<br>
  </ng-container>

  <ng-container body>

  </ng-container>

</i2a-profile-alert-base-modal>
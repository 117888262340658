import { Injectable } from '@angular/core';
import { StringHelper } from './string-helper';

@Injectable({
    providedIn: 'root'
})
export class FileHelper {

   
    static word = "file-word";
    static excel = "file-excel";
    static powerpoint = "file-powerpoint";
    static pdf = "file-pdf";
    static image = "file-image";
    static video = "file-video";
    static music = "file-music";
    static archive = "file-archive";
    static text = "file-alt";
    static code = "file-code";

    public static fileIcon = "file";
    
    public static folderIcon = "folder";

    public static iconByExtension = {
        "docx": FileHelper.word,
        "doc": FileHelper.word,
        "xlsx": FileHelper.excel,
        "xls": FileHelper.excel,
        "pptx": FileHelper.powerpoint,
        "ppt": FileHelper.powerpoint,
        "pdf": FileHelper.pdf,
        "jpg": FileHelper.image,
        "jpeg": FileHelper.image,
        "png": FileHelper.image,
        "svg": FileHelper.image,
        "raw": FileHelper.image,
        "gif": FileHelper.image,
        "tiff": FileHelper.image,
        "bmp": FileHelper.image,
        "bpg": FileHelper.image,
        "exif": FileHelper.image,
        "webp": FileHelper.image,
        "pcx": FileHelper.image,
        "psp": FileHelper.image,
        "psd": FileHelper.image,
        "vml": FileHelper.image,
        "avi": FileHelper.video,
        "mpeg": FileHelper.video,
        "mpg": FileHelper.video,
        "mp4": FileHelper.video,
        "mov": FileHelper.video,
        "wma": FileHelper.video,
        "flv": FileHelper.video,
        "mkv": FileHelper.video,
        "mp3": FileHelper.music,
        "wav": FileHelper.music,
        "au": FileHelper.music,
        "ogg": FileHelper.music,
        "aac": FileHelper.music,
        "zip": FileHelper.archive,
        "tar": FileHelper.archive,
        "tgz": FileHelper.archive,
        "gz": FileHelper.archive,
        "bz2": FileHelper.archive,
        "7z": FileHelper.archive,
        "ace": FileHelper.archive,
        "rar": FileHelper.archive,
        "bz": FileHelper.archive,
        "txt": FileHelper.text,
        "csv": FileHelper.text,
        "cs": FileHelper.code,
        "js": FileHelper.code,
        "ts": FileHelper.code,
        "html": FileHelper.code,
        "css": FileHelper.code,
        "c": FileHelper.code,
        "cpp": FileHelper.code,
        "vb": FileHelper.code,
        "h": FileHelper.code
    };

    constructor() {
    }

    public static getFileIcon(extension: string) : string {
        if (!StringHelper.isNullOrWhitespace(extension) && Object.keys(FileHelper.iconByExtension).indexOf(extension) >=0) {
            return FileHelper.iconByExtension[extension];
        } else {
            return FileHelper.fileIcon;
        }
    }
}

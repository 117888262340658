import { Component, OnInit, Input } from '@angular/core';
import { FileShareReportProfilePermissionRights, FileShareReportProfilePermissions } from '@i2a/web-api-client';
import { StringHelper } from 'projects/web-user-profile/src/app/helpers/string-helper';

@Component({
  selector: 'i2a-file-share-permission-detail',
  templateUrl: './file-share-permission-detail.component.html',
  styleUrls: ['./file-share-permission-detail.component.scss']
})
export class FileSharePermissionDetailComponent implements OnInit {
  
  @Input() public permissions: FileShareReportProfilePermissions;
  @Input() public displayLegend: boolean = true;
  
  public displayReadLegend: boolean;
  public displayWriteLegend: boolean;
  public displayExecuteLegend: boolean;
  public displayModifyLegend: boolean;
  public displayFullControlLegend: boolean;

  constructor() { 
    this.displayReadLegend = false;
    this.displayModifyLegend = false;
    this.displayWriteLegend = false;
    this.displayExecuteLegend = false;
    this.displayWriteLegend = false;
  }

  ngOnInit() {
    if (this.permissions.rights) {
      this.permissions.rights.forEach((right:FileShareReportProfilePermissionRights) => {
        this.displayReadLegend = this.displayReadLegend || right.read;
        this.displayModifyLegend = this.displayModifyLegend || right.modify;
        this.displayWriteLegend = this.displayWriteLegend || right.write;
        this.displayExecuteLegend = this.displayExecuteLegend || right.execute;
        this.displayFullControlLegend = this.displayFullControlLegend || right.fullControl;
      });
      this.permissions.rights = this.permissions.rights.sort(function (a, b) {
        var path1 = "";
        var path2 = "";
        if (!StringHelper.isNullOrWhitespace(a.path)) {
          path1 = a.path;
        }
        if (!StringHelper.isNullOrWhitespace(b.path)) {
          path2 = b.path;
        }
        return path1.toLowerCase().localeCompare(path2.toLowerCase());
      });
    }
  }

}

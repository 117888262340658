import { Component, Inject, OnInit } from '@angular/core';
import { BaseProviderInstanceResourceModel, ReportProfileResourceBase, ReportProfileRulesComputedDelegate, ReportProfileDeviceModel, ReportProfileBaseModel, MicrosoftOfficeReportProfileModel, ZimbraReportProfileModel, UserModel, AlertInvalidStateFeaturedModel, Constants } from '@i2a/web-api-client';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ToastrService } from 'ngx-toastr';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ProfileHelper } from 'projects/web-user-profile/src/app/helpers/profile-helper';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';
import { ResourceActionsConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/resource-actions-configuration';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { ProfileBaseClassComponent } from '../profile-base-class-component';

@RegisterMyProfileComponent(TemplateConstants.MODULE_DELEGATE_RESOURCE_ACTIONS)
@Component({
  selector: 'i2a-resource-actions-module',
  templateUrl: './resource-actions-module.component.html',
  styleUrls: ['./resource-actions-module.component.scss']
})
export class ResourceActionsModuleComponent extends ProfileBaseClassComponent<ResourceActionsConfiguration,ReportProfileResourceBase<any>> implements OnInit, IModule<ResourceActionsConfiguration> {
  public resource: BaseProviderInstanceResourceModel;
  public resourceProfile: ReportProfileResourceBase<ReportProfileRulesComputedDelegate>;

  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, null);
  }

  ngOnInit() {
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: ResourceActionsConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel, resourceTemplate?: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.resource = resource;
    this.resourceProfile = ProfileHelper.getResourceProfile(profile, resource);
  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }

}

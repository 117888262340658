<i2a-profile-alert-base-modal
  [manualDescriptionKey]="'applyCorrection.providers.microsoft.sharePoint.permission.default.manual.description'"
  [manualLinkKey]="'applyCorrection.providers.microsoft.sharePoint.permission.default.manual.link'"
  [automaticDescriptionKey]="'applyCorrection.providers.microsoft.sharePoint.permission.default.automatic.description'"
  [ignoreCommentToSend]="session.IgnoreCommentToSend" [displayComment]="session.DisplayComment"
  [validation]="validation" [canSend]="canSend"
  [modalTitle]="'profile.provider.microsoft.sharePoint.confirmationOfInformationTitle.permissions' | i18next "
  [informationKey]="informationKey" [resourceProfile]="resourceProfile">

  <ng-container header-picto>
    <i2a-delegate-photo [isAnonymous]="permissions.isAnonymousUser" [isDefault]="permissions.isTheWholeCompany"
      [isApplication]="false" [pir]="permissions.providerInstanceResource" [piu]="permissions.providerInstanceUser"
      [isExternalUser]="permissions.isExternalUser" [isUnknown]="permissions.isUnknown" [customIcon]="permissions.isGroup ? ['fas', 'users']: null">
    </i2a-delegate-photo>
  </ng-container>

  <ng-container header-title>
    {{title}}<br>
  </ng-container>

  <ng-container body>
    <div class="container-fluid">
      <div class="row pt-3" *ngIf="driveItemsPermissions && driveItemsPermissions.length > 0">
        <div class="col-12">
          <i2a-share-point-permission-form [(validation)]="validation" [driveItemsPermissions]="driveItemsPermissions"
            (setCanSend)="setCanSend()"
            [allPathsTitle]="'profile.provider.microsoft.sharePoint.driveItem.allPaths' | i18next">
          </i2a-share-point-permission-form>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container message>
    <div class="row pt-3" *ngIf="hasSpecificLink">
      <div class="col-12">
        <i2a-message>
          <ng-container message-content>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 13.125 13.125"
            style="vertical-align: sub;margin-right: 5px;">
            <path
              d="M363.563,108.875L357,122h13.125Zm0.437,5.25V118.5h-0.875v-4.375H364Zm-0.875,5.25H364v0.875h-0.875v-0.875Z"
              fill="#ffcf64" transform="translate(-357 -108.875)" /></svg>

        {{'profile.provider.microsoft.sharePoint.specificLinkWarning' | i18next}}
          </ng-container>
        </i2a-message>
      </div>
    </div>
  </ng-container>
</i2a-profile-alert-base-modal>
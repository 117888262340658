import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UsersDatasourceService, PageResultModel, UserModel, Role, UserKind } from '@i2a/web-api-client';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from '../../../services/session-service';

@Component({
  selector: 'i2a-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {
  public userName: string
  public onlyFavorites: boolean;

  private subscriptions = new Subscription();
  public users: UserModel[];
  public currentPage: PageResultModel<UserModel>;
  public noMoreData: boolean;
  private limit = 30;
  public init = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private usersDatasource: UsersDatasourceService,
    public session: SessionService
  ) {
    this.noMoreData = false;
    this.userName = "";
  }

  ngOnInit() {
    this.loadUsers();
  }

  search() {
    if (this.users != null) {
      this.users = null;
      this.currentPage = null;
      this.noMoreData = false;
      this.loadUsers();
    }
  }

  loadUsers() {
    if (!this.noMoreData) {
      var offset = this.currentPage == null ? 0 : this.currentPage.offset;
      this.subscriptions.add(this.usersDatasource.search(this.userName, null, null, null, this.limit, offset).subscribe(page => {
        if (page.items.length == 1 && this.init) {
          this.router.navigate(["..", "user", page.items[0].id], { relativeTo: this.route, replaceUrl: true });
        }
        else {
          this.noMoreData = page.items.length == 0;
          this.currentPage = page;
          if (this.users == null) {
            this.users = [];
          }
          this.users.push(...this.currentPage.items);
        }
        this.init = false;
      }
      ));
    }
  }

  hasAdminRole() : boolean
  {
     if (this.session.SecurityInfos.userKind != null) {
      return  (this.session.SecurityInfos.userKind & UserKind.Admin) == UserKind.Admin;
     }
     else {
      return this.session.SecurityInfos.isSupport;
     }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}

import { Component, OnInit, TemplateRef, ViewChild, Inject, Input, Output, EventEmitter } from '@angular/core';
import { ReportProfileResourceBase } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { FolderHelper } from 'projects/web-user-profile/src/app/helpers/folder-helper';
import { DelegateFolders } from 'projects/web-user-profile/src/app/models/delegate-folders';
import { WarningLevel } from 'projects/web-user-profile/src/app/models/warnings/warning-level';
import { InformationModalComponent } from '../../../shared/modal/information-modal/information-modal.component';
import { SharePointExternalPermissionsDetailComponent } from '../../providers/microsoft/share-point/share-point-external-permissions-detail/share-point-external-permissions-detail.component';

@Component({
  selector: 'i2a-delegate-folders',
  templateUrl: './delegate-folders.component.html',
  styleUrls: ['./delegate-folders.component.scss']
})
export class DelegateFoldersComponent implements OnInit {
  @Input() public title: string;
  @Input() public subTitle: string;
  @Input() public hasAlert: boolean;
  @Input() public hasAlertRecentlyClosed: boolean;
  @Input() public warningLevel?: WarningLevel;
  @Input() public isAlert: boolean;
  @Input() public delegate: DelegateFolders;
  @Input() public buttonOpenAlertModalKey: string;
  @Input() public viewWarnButton: boolean = true;
  @Input() public displayAccessTitle: boolean = true;
  @Input() public viewMoreDetailsKey: string;
  @Input() public resourceProfile: ReportProfileResourceBase<any>;


  @Output() public onWarnClick = new EventEmitter();


  public baseFolders: string[];

  public permissionsBaseFolders: string[];
  public viewMoreDetails: boolean;
  public maxPermission: string;

  constructor(
    private modalService: NgbModal,
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService) {
  }

  ngOnInit() {
    if (this.delegate) {
      this.baseFolders = FolderHelper.RemoveFolderPartsAboveMaxDepthLevel(this.delegate.folders, 2);
      this.baseFolders = FolderHelper.CompleteFolders(this.baseFolders, 1);

      if (this.delegate.driveItems != null) {
        this.permissionsBaseFolders = FolderHelper.RemoveFolderPartsAboveMaxDepthLevel(Object.keys(this.delegate.driveItems), 2);
        this.permissionsBaseFolders = FolderHelper.CompleteFolders(this.baseFolders, 1);
      }
      this.viewMoreDetails = this.delegate.folders.length > 1 
                            || this.delegate.driveItems != null && (Object.keys(this.delegate.driveItems).length >= 1);
     
      
      this.maxPermission = SharePointExternalPermissionsDetailComponent.GetMaxPermissionLabel(this.delegate.driveItems, this.i18Next);
      }
  }


  @ViewChild('viewDetailTemplate') viewDetailTemplate: TemplateRef<any>;

  openDetails(event): void {
    const modalRef = this.modalService.open(InformationModalComponent, { windowClass: 'id-modal', centered: true, size: 'lg' });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.title = this.i18Next.t("modal.detail.folderTitle");
    modalRef.componentInstance.displayWarnClick = this.viewWarnButton;
    modalRef.componentInstance.bodyTemplate = this.viewDetailTemplate;
    modalRef.componentInstance.ok = this.i18Next.t("button.close");
    modalRef.result.then((model: any) => {
      if (model == true) {
        this.warnClick(null);
      }
    }, (reason) => {
      //Dismiss
    });
  }

  warnClick(event): void {
    this.onWarnClick.emit(event);
  }
}

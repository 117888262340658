import { Component, OnInit, Input, Inject, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { ExchangeReportProfilePermissions, ExchangeReportProfileAlertPermissionsModel, BaseProviderInstanceResourceModel, MicrosoftOfficeProfileProviderDatasourceService, ProviderInstanceResourceSummaryModel, ExchangeReportProfile, AlertInvalidStateFeaturedModel } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExchangePermissionModalComponent } from '../exchange-permission-modal/exchange-permission-modal.component';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { InformationModalComponent } from 'projects/web-user-profile/src/app/components/shared/modal/information-modal/information-modal.component';
import { WarningHelper } from 'projects/web-user-profile/src/app/helpers/warning-helper';
import { ProviderHelper } from 'projects/web-user-profile/src/app/helpers/provider-helper';
import { ActivatedRoute, Router } from '@angular/router';
import { ExchangeReportProfilePermissionsWithWarnings } from 'projects/web-user-profile/src/app/models/providers/microsoft/exchange/exchange-report-profile-permissions-with-warnings';

@Component({
  selector: 'i2a-exchange-permission',
  templateUrl: './exchange-permission.component.html',
  styleUrls: ['./exchange-permission.component.scss']
})
export class ExchangePermissionComponent extends ProfileItemBaseClassComponent<ExchangeReportProfile> implements OnInit, OnDestroy {
  @Input() public permissionsWithWarnings: ExchangeReportProfilePermissionsWithWarnings;
  public permissions: ExchangeReportProfilePermissions;

  public title: string;
  public viewMoreDetails: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    modalService: NgbModal,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
    this.viewMoreDetails = false;
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.permissionsWithWarnings) {
      this.permissions = this.permissionsWithWarnings.model;
      this.warnings = this.permissionsWithWarnings.warnings;
      this.warningLevel = this.permissionsWithWarnings.warnings ? this.permissionsWithWarnings.warnings[0].warningLevel : null;
      this.title = this.permissionsWithWarnings.title;
      this.id = this.permissionsWithWarnings.id;
      this.viewMoreDetails = this.permissions != null && Object.keys(this.permissions.foldersRights).length > 0;
      this.subscriptions.add(WarningHelper.openModalOnRoute(this.router, this.route, this.id, this.openDetails.bind(this), this.warnClick.bind(this)));
    }
  }

  setItems() {
    if (this.permissionsWithWarnings.model && this.alerts) {
      super.setItems();
      this.hasAlert = this.hasAlertInAlerts(false);
      this.hasAlertRecentlyClosed = this.hasAlertInAlerts(true);
    }
  }

  private hasAlertInAlerts(done: boolean): boolean {
    return AlertReasonHelper.alertsContainsDelegate(this.alerts, this.permissionsWithWarnings.model, done);
  }


  private static getTitle(i18Next: ITranslationService, permissions: ExchangeReportProfilePermissions): string {
    if (!permissions.isNameKey) {
      return permissions.name;
    } else {
      return i18Next.t(permissions.name);
    }
  }


  public static getId(componentId: string, providerInstanceResource: BaseProviderInstanceResourceModel, title: string): string {
    return `${componentId}-${providerInstanceResource.providerTypeId}-${providerInstanceResource.providerInstanceResourceId}-${title.replaceAll(' ', '-')}-ExchangePermission`
  }

  public static getPermissionsWithWarning(i18Next: ITranslationService, componentId: string, resourceProfile: ExchangeReportProfile, permissions: ExchangeReportProfilePermissions, alerts: AlertInvalidStateFeaturedModel[]): ExchangeReportProfilePermissionsWithWarnings {
    let title = this.getTitle(i18Next, permissions);
    let permissionsWithWarnings: ExchangeReportProfilePermissionsWithWarnings = {
      model: permissions,
      title: title,
      hasAlert: AlertReasonHelper.alertsContainsDelegate(alerts, permissions, false),
      hasAlertRecentlyClosed: AlertReasonHelper.alertsContainsDelegate(alerts, permissions, true),
      id: ExchangePermissionComponent.getId(componentId, resourceProfile.providerInstanceResource, title),
      warnings: null
    };
    if (permissions.isNameKey) {
      let warningInfoType = WarningHelper.GetWarningInfoTypeCaseAccount(permissions.logonUserId);
      let warningLevel = WarningHelper.GetWarningLevel(warningInfoType);
      permissionsWithWarnings.warnings = [{
        id: permissionsWithWarnings.id,
        resourceId: 'application' + resourceProfile.providerInstanceResource.providerTypeId.toLocaleLowerCase() + resourceProfile.providerInstanceResource.providerInstanceResourceId.toLocaleLowerCase(),
        resourceProfile: resourceProfile,
        warningInfoType: warningInfoType,
        warningLevel: warningLevel,
        label: `${i18Next.t(ProviderHelper.GetProviderTypeLabel(resourceProfile.providerInstanceResource.providerId, resourceProfile.providerInstanceResource.providerTypeId))} > ${(<ProviderInstanceResourceSummaryModel>resourceProfile.providerInstanceResource).name}`,
        hasAlert: permissionsWithWarnings.hasAlert,
        hasAlertRecentlyClosed: permissionsWithWarnings.hasAlertRecentlyClosed,
        warningInfo: (permissions.logonUserId == 'Anonymous') ? 'warning.info.type.provider.microsoft.exchange.anonymousPermission' : 'warning.info.type.provider.microsoft.exchange.defaultPermission',
        viewMoreDetails: true,
        viewDissmiss: true,
        viewResource: false
      }];
    }

    return permissionsWithWarnings;
  }


  warnClick(event): void {
    const modalRef = this.modalService.open(ExchangePermissionModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.permissions = this.permissions;

    modalRef.result.then((model: ExchangeReportProfileAlertPermissionsModel) => {
      this.initAlertModel(model);
      model.permissions = this.permissions;
      model.providerInstanceResource = this.providerInstanceResource;
      this.subscriptions.add(this.microsoftDatasource.alertExchangePermissions(this.profile.providerInstanceId, this.profile.userId, model).subscribe(model => {
        this.setAlertProcessed(model);
      }));
    }).catch((error) => {
      // console.log(error);
    });

  }

  @ViewChild('viewDetailTemplate') viewDetailTemplate: TemplateRef<any>;

  openDetails(event): void {
    const modalRef = this.modalService.open(InformationModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.title = this.i18Next.t("modal.detail.title");
    modalRef.componentInstance.bodyTemplate = this.viewDetailTemplate;
    modalRef.componentInstance.ok = this.i18Next.t("button.close");
    modalRef.result.then((model: any) => {
      if (model == true) {
        this.warnClick(null);
      }
    }, (reason) => {
      //Dismiss
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

import { OriginLevel } from '../origin-level';
import { ReportProfileAlertBaseModel } from '../profile/report-profile-alert-base-model';
import { ReportProfileOriginModel } from '../profile/report-profile-origin-model';
import { ReportProfileUsualCountriesModel } from '../profile/report-profile-usual-countries-model';

export class UserReportProfileAlertOriginsModel extends ReportProfileAlertBaseModel {
  originLevel: OriginLevel;
  invalidOrigins: ReportProfileOriginModel[];
  invalidUsualCountries: ReportProfileUsualCountriesModel[];
}

import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { FileShareReportProfilePermissions, FileShareReportProfileAlertPermissionsModel, ReportProfileResourceBase } from '@i2a/web-api-client';

@Component({
  selector: 'i2a-file-share-permission-modal',
  templateUrl: './file-share-permission-modal.component.html',
  styleUrls: ['./file-share-permission-modal.component.scss']
})
export class FileSharePermissionModalComponent implements OnInit {
  @Input() public permissions: FileShareReportProfilePermissions;

  @Input() public resourceProfile: ReportProfileResourceBase<any>;
  
  public validation: FileShareReportProfileAlertPermissionsModel;
  public canSend: boolean;
  public title: string;
  public paths: any[];//TODO CRADO

  constructor(
    private modalService: NgbModal,
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService) {
    this.canSend = false;
  }

   ngOnInit() {
    this.validation = new FileShareReportProfileAlertPermissionsModel();
    this.paths = [];

    this.permissions.rights.forEach(right => {
      this.paths.push({
        "path": right.path,
        "rights": right,
        "checked": false
      });
    });

    this.validation.invalidPaths = [];
    this.validation.allPaths = this.paths.length>0;

    if (this.permissions) {
      if (!this.permissions.isNameKey) {
        this.title = this.permissions.name;
      }
      else {
        this.title = this.i18Next.t(this.permissions.name);
      }
    }
    this.setCanSend();
  }

  private setCanSend() {
    setTimeout(() => {
      this.canSend = this.validation.invalidPaths.length > 0 || this.validation.allPaths;
    });
  }
  
  //#region paths

  checkedPath(isChecked: boolean, driveItem: any) {
    const index: number = this.validation.invalidPaths.indexOf(driveItem.path);
    if (isChecked && index === -1) {
      this.validation.invalidPaths.push(driveItem.path);
    }
    else if (!isChecked && index !== -1) {
      this.validation.invalidPaths.splice(index, 1);
    }
    if (this.paths.length == this.validation.invalidPaths.length) {
      this.allPathsChecked(true);
      this.validation.allPaths = true;
    }
    this.setCanSend();
  }

  allPathsChecked(isChecked: boolean) {
    if (isChecked) {
      this.paths.forEach(f => f.checked = false);
      this.validation.invalidPaths = [];
    }
  }

  //#endregion
}

import { GuidedTourModel } from '../models/guided-tour-model';
import { GuidedTourStopModel } from '../models/guided-tour-stop-model';
import { PageResultModel } from '../models/page-result-model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatasourceService } from '../../data/datasource.service';

@Injectable({
	providedIn: 'root'
})
export class GuidedToursDatasourceService {

private route: string = "guided-tours";

	constructor(private datasource: DatasourceService) {
		console.log("Init GuidedToursDatasourceService");
	}

	public search(guidedTourId: string = null, version: number = null, completed: boolean = null, userId: string = null, limit: number = null, offset: number = null, useCache: boolean = false, throwError: boolean = true): Observable<PageResultModel<GuidedTourModel>> {
		let partialUrl = `${this.route}`;

		return this.datasource.get<PageResultModel<GuidedTourModel>>(partialUrl, {guidedTourId: String(guidedTourId),version: String(version),completed: String(completed),userId: String(userId),limit: String(limit),offset: String(offset) }, useCache, throwError);
	}

	public load(guidedTourId: string, version: number, useCache: boolean = false, throwError: boolean = true): Observable<GuidedTourModel> {
		let partialUrl = `${this.route}/${guidedTourId}/${version}`;

		return this.datasource.get<GuidedTourModel>(partialUrl, { }, useCache, throwError);
	}

	public startTour(guidedTourId: string, version: number, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${guidedTourId}/${version}/start`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public stopTour(guidedTourId: string, version: number, model: GuidedTourStopModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${guidedTourId}/${version}/stop`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

}

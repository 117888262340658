import { BaseProviderInstanceResourceModel } from './base-provider-instance-resource-model';
import { DeviceType } from './device-type';

export class DeviceMappedModel extends BaseProviderInstanceResourceModel {
  id: string|null;
  userId: string|null;
  existingDeviceId: string|null;
  syncId: string;
  name: string;
  model: string;
  deviceType: DeviceType;
  isActive: boolean;
  lastActionDate: Date|null;
}

<div class="nav-bar-base">
  <nav class="navbar navbar-expand-md">
    <ng-template #logo>
        <ng-content select="[menuLogo]"></ng-content>
    </ng-template>
    <div [ngClass]="{'navbar-logo-left': !isIE, 'float-left': true}">
        <ng-container *ngTemplateOutlet="logoAtLeft ? logo : null"></ng-container>
    </div>
    <div class='navbar-brand navbar-text p-0'>
      <ng-content select="[menuBrand]"></ng-content>
    </div>
    <div class='navbar-logo-center w-100'>
      <ng-container *ngTemplateOutlet="logoAtCenter ? logo : null"></ng-container>
    </div>
    <ng-container *ngIf="displayMenu">
      <div>
          <!-- class="w-100" -->
        <ul class="navbar-nav float-right">
          <li class="nav-item dropdown">

            <div ngbDropdown class="d-inline-block float-left" autoClose="true" #myDrop="ngbDropdown"
              placement="bottom-right">
              <button class="btn nav-link ml-auto" id="navbarDropdown" ngbDropdownToggle  #navBarUser (mouseout)="onLeaveUser()" (mouseover)="onEnterUser()">
                <ng-content select="[menuUser]"></ng-content>
              </button>
              <div ngbDropdownMenu aria-labelledby="navbarDropdown" class="dropdown-menu-right">
                <ng-content select="[menuItems]"></ng-content>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </ng-container>
  </nav>
</div>
import { BaseProviderInstanceResourceModel } from '../../../base-provider-instance-resource-model';
import { ChangeResult } from '../../../change-result';
import { ReportProfileResourceChangesBaseModel } from '../../../reports/profile/report-profile-resource-changes-base-model';
import { ReportProfileRulesComputedDelegate } from '../../../profile/report-profile-rules-computed-delegate';
import { ZimbraMailingReportProfileDelegate } from '../../../zimbra/mailing/profile/zimbra-mailing-report-profile-delegate';
import { ZimbraMailingReportProfileFilterRule } from '../../../zimbra/mailing/profile/zimbra-mailing-report-profile-filter-rule';
import { ZimbraMailingReportProfilePermissions } from '../../../zimbra/mailing/profile/zimbra-mailing-report-profile-permissions';

export class ZimbraMailingReportProfileChangesModel extends ReportProfileResourceChangesBaseModel<ReportProfileRulesComputedDelegate> {
  providerInstanceResource: BaseProviderInstanceResourceModel;
  delegates: ChangeResult<ZimbraMailingReportProfileDelegate>;
  permissions: ChangeResult<ZimbraMailingReportProfilePermissions>;
  filterRules: ChangeResult<ZimbraMailingReportProfileFilterRule>;
  isEqual: boolean;
}

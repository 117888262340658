import { Component, Input, OnInit } from '@angular/core';
import { StringHelper } from '@i2a/web-core';

@Component({
  selector: 'i2a-badge-icon',
  templateUrl: './badge-icon.component.html',
  styleUrls: ['./badge-icon.component.scss']
})
export class BadgeIconComponent implements OnInit {

  @Input() customClass: string;

  public classes: string;

  constructor() { }

  ngOnInit() {
    if (!StringHelper.isNullOrWhitespace(this.customClass)) {
      this.classes = `bagde-icon id-bagde-icon-radius ${this.customClass} id-bagde-icon-picture`;
    } else {
      this.classes = "bagde-icon id-bagde-icon-radius id-bagde-icon-picture";
    }
  }

}

export class MicrosoftConstants {

        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_ACCEPTED_DOMAIN_PROPERTY_MODIFIED = "alert.config.provider.microsoft.exchange.acceptedDomain.reasons.propertyModified";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_ACTIVE_SYNC_NEW_DEVICE = "alert.config.provider.microsoft.exchange.activeSync.reasons.newDevice";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_POLICY_MODIFIED = "alert.config.provider.microsoft.exchange.activeSyncMailboxPolicy.reasons.propertyModified";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_ADMIN_AUDIT_DISABLED = "alert.config.provider.microsoft.exchange.adminAudit.reasons.disabled";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_ADMIN_AUDIT_CMDLETS = "alert.config.provider.microsoft.exchange.adminAudit.reasons.cmdlets";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_ADMIN_AUDIT_PARAMETERS = "alert.config.provider.microsoft.exchange.adminAudit.reasons.parameters";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_ADMIN_AUDIT_EXCLUDED_CMDLETS = "alert.config.provider.microsoft.exchange.adminAudit.reasons.excludedCmdlets";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_ADMIN_AUDIT_LOG_AGE_LIMIT = "alert.config.provider.microsoft.exchange.adminAudit.reasons.logAgeLimit";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_RULE_DESCRIPTION = "alert.config.provider.microsoft.exchange.rule.description";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_RULE_ADD_MANAGER_AS_RECEPIENT_TYPE = "alert.config.provider.microsoft.exchange.rule.addManagerAsRecipientType";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_RULE_ADD_TO_RECIPIENTS = "alert.config.provider.microsoft.exchange.rule.addToRecipients";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_RULE_BLIND_COPY_TO = "alert.config.provider.microsoft.exchange.rule.blindCopyTo";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_RULE_COPY_TO = "alert.config.provider.microsoft.exchange.rule.copyTo";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_RULE_DELETE_MESSAGE = "alert.config.provider.microsoft.exchange.rule.deleteMessage";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_RULE_MODERATE_MESAGE_BY_MANAGER = "alert.config.provider.microsoft.exchange.rule.moderateMessageByManager";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_RULE_MODERATE_MESAGE_BY_USER = "alert.config.provider.microsoft.exchange.rule.moderateMessageByUser";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_RULE_QUARANTINE = "alert.config.provider.microsoft.exchange.rule.quarantine";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_RULE_REDIRECT_MESSAGE_TO = "alert.config.provider.microsoft.exchange.rule.redirectMessageTo";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_RULE_FORWARD_AS_ATTACHMENT_TO = "alert.config.provider.microsoft.exchange.rule.forwardAsAttachmentTo";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_RULE_FORWARD_TO = "alert.config.provider.microsoft.exchange.rule.forwardTo";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_RULE_REDIRECT_TO = "alert.config.provider.microsoft.exchange.rule.redirectTo";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_RULE_MOVE_TO_FOLDER = "alert.config.provider.microsoft.exchange.rule.moveToFolder";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_DATABASE_AVAILIBILITY_GROUP_PROPERTY_MODIFIED = "alert.config.provider.microsoft.exchange.databaseAvailabilityGroup.reasons.propertyModified";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_DATABASE_AVAILIBILITY_GROUP_NETWORK_PROPERTY_MODIFIED = "alert.config.provider.microsoft.exchange.databaseAvailabilityGroup.reasons.networkPropertyModified";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_DATABASE_AVAILIBILITY_GROUP_NETWORK_ADDED = "alert.config.provider.microsoft.exchange.databaseAvailabilityGroup.reasons.networkAdded";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_DISTRIBUTION_GROUP_MODERATION_ADDED = "alert.config.provider.microsoft.exchange.distributionGroup.moderation.reasons.added";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_INBOX_RULE_CLIENT_ONLY = "alert.config.provider.microsoft.exchange.inboxRule.reasons.clientOnly";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_JOUNRAL_RULE_SPECIFIC = "alert.config.provider.microsoft.exchange.journalRule.reasons.specific";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_JOURNAL_RULE_GLOBAL = "alert.config.provider.microsoft.exchange.journalRule.reasons.global";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_AUDIT_BYPASS_ASSOCIATION_ADDED = "alert.config.provider.microsoft.exchange.mailboxAuditByPassAssociation.reasons.added";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_DATABASE_JOURNAL_RECIPIENT_MODIFIED = "alert.config.provider.microsoft.exchange.mailboxDatabase.reasons.journalRecipientModified";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_DATABASE_MASTER_MODIFIED = "alert.config.provider.microsoft.exchange.mailboxDatabase.reasons.masterModified";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_DATABASE_COPIES_MODIFIED = "alert.config.provider.microsoft.exchange.mailboxDatabase.reasons.databaseCopiesModified";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_DATABASE_QUOTA_NOT_DEFINED = "alert.config.provider.microsoft.exchange.mailboxDatabase.reasons.quotaNotDefined";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_DATABASE_QUOTA_MODIFIED = "alert.config.provider.microsoft.exchange.mailboxDatabase.reasons.quotaModified";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_EXPORT_REQUEST_NEW_REQUEST = "alert.config.provider.microsoft.exchange.mailboxExportRequest.reasons.newRequest";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_FOLDERS_PERMISSION_RIGHTS_MODIFIED = "alert.config.provider.microsoft.exchange.mailboxFoldersPermission.reasons.rightsModified";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_FOLDERS_PERMISSION_RIGHTS_ADDED = "alert.config.provider.microsoft.exchange.mailboxFoldersPermission.reasons.rightsAdded";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_PERMISSIONS_INHERITED_ADDED = "alert.config.provider.microsoft.exchange.mailboxPermissions.inherited.reasons.added";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_PERMISSIONS_INHERITED_ADDED_AND_REMOVED = "alert.config.provider.microsoft.exchange.mailboxPermissions.inherited.reasons.addedAndRemoved";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_PERMISSIONS_DIRECT_ADDED = "alert.config.provider.microsoft.exchange.mailboxPermissions.direct.reasons.added";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_PERMISSIONS_DIRECT_ADDED_AND_REMOVED = MicrosoftConstants.ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_PERMISSIONS_DIRECT_ADDED + "AndRemoved";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_FORWARD_ENABLED = "alert.config.provider.microsoft.exchange.mailbox.forward.reasons.enabled";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_ADDRESSES_DELETED = "alert.config.provider.microsoft.exchange.mailbox.adresses.reasons.deleted";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_AUDIT_DISABLED = "alert.config.provider.microsoft.exchange.mailbox.audit.reasons.disabled";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_AUDIT_AUDIT_ADMIN = "alert.config.provider.microsoft.exchange.mailbox.audit.reasons.auditAdmin";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_AUDIT_AUDIT_DELEGATE = "alert.config.provider.microsoft.exchange.mailbox.audit.reasons.auditDelegate";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_AUDIT_AUDIT_OWNER = "alert.config.provider.microsoft.exchange.mailbox.audit.reasons.auditOwner";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_AUDIT_LOG_AGE_LIMIT = "alert.config.provider.microsoft.exchange.mailbox.audit.reasons.logAgeLimit";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_SEND_ON_HALF_ADDED = "alert.config.provider.microsoft.exchange.mailbox.sendOnBehalf.reasons.added";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_SEND_ON_HALF_ADDED_AND_REMOVED = MicrosoftConstants.ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_MAILBOX_SEND_ON_HALF_ADDED + "AndRemoved";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_REMOTE_DOMAIN_PROPERTY_MODIFIED = "alert.config.provider.microsoft.exchange.remoteDomain.reasons.propertyModified";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_SEND_CONNECTOR_NEW_CONNECTOR = "alert.config.provider.microsoft.exchange.sendConnector.reasons.newConnector";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_SEND_CONNECTOR_DOMAIN_ADDED = "alert.config.provider.microsoft.exchange.sendConnector.reasons.domainAdded";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_SEND_CONNECTOR_DOMAIN_REMOVED = "alert.config.provider.microsoft.exchange.sendConnector.reasons.domainRemoved";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_SEND_CONNECTOR_SMART_HOST_ADDED = "alert.config.provider.microsoft.exchange.sendConnector.reasons.smartHostAdded";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_EXCHANGE_SEND_CONNECTOR_SMART_HOST_REMOVED = "alert.config.provider.microsoft.exchange.sendConnector.reasons.smartHostRemoved";

        public static readonly SHAREPOINT_ALERT_BASEKEY = "alert.config.provider.microsoft.sharePoint.";
        public static readonly SHAREPOINT_ALERT_BASEKEY_SHARINGCONFIGURATION = MicrosoftConstants.SHAREPOINT_ALERT_BASEKEY + "adminAudit.sharingConfiguration";
        
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_I_EVENT_REVECEIVERS_ABLE_EVENT_RECEIVERS_ADDED = "alert.config.provider.microsoft.sharePoint.iEventReceiversAble.eventReceivers.reasons.added";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_I_WORKFLOWS_ABLE_WORKLOWS_ADDED = "alert.config.provider.microsoft.sharePoint.iWorkflowsAble.workFlows.reasons.added";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_I_WORKFLOWS_ABLE_WORKLOWS_ACTIVATED = "alert.config.provider.microsoft.sharePoint.iWorkflowsAble.workFlows.reasons.activated";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_I_WORKFLOWS_ABLE_WORKLOWS_MODIFIED = "alert.config.provider.microsoft.sharePoint.iWorkflowsAble.workFlows.reasons.modified";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_I_SECURABLE_OBJECT_HAS_UNIQUE_ROLE_ASSIGNMENTS_CHANGED = "alert.config.provider.microsoft.sharePoint.iSecurableObject.hasUniqueRoleAssignments.reasons.changed";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_I_SECURABLE_OBJECT_ROLE_ASSIGNMENTS_ADDED = "alert.config.provider.microsoft.sharePoint.iSecurableObject.roleAssignments.reasons.added";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_I_FEATURES_ABLE_FEATURE_ACTIVATED = "alert.config.provider.microsoft.sharePoint.iFeaturesAble.feature.reasons.activated";

        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_BASE_RESOURCE_KEY = "alert.config.provider.microsoft.sharePoint.permissions";

        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_SITE_SHARE_BY_EMAIL_ENABLED = "alert.config.provider.microsoft.sharePoint.site.shareByEmailEnabled.reasons.enabled";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_SITE_SHARE_BY_LINK_ENABLED = "alert.config.provider.microsoft.sharePoint.site.shareByLinkEnabled.reasons.enabled";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_SITE_ADMINISTRATORS_ADDED = "alert.config.provider.microsoft.sharePoint.site.administrators.reasons.added";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_SITE_ADMINISTRATORS_REMOVED = "alert.config.provider.microsoft.sharePoint.site.administrators.reasons.removed";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_WEB_APPILCATION_FILE_EXTENSIONS_BLOCKED_FILE_EXTENSIONS_ADDED = "alert.config.provider.microsoft.sharePoint.webApplication.fileExtensions.reasons.blockedFileExtensionsAdded";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_WEB_APPILCATION_FILE_EXTENSIONS_BLOCKED_FILE_EXTENSIONS_REMOVED = "alert.config.provider.microsoft.sharePoint.webApplication.fileExtensions.reasons.blockedFileExtensionsRemoved";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_WEB_APPILCATION_FILE_EXTENSIONS_WEB_FILE_EXTENSION_REMOVED = "alert.config.provider.microsoft.sharePoint.webApplication.fileExtensions.reasons.webFileExtensionsRemoved";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_WEB_APPLICATION_APPLICATION_PRINCIPAL_MAX_RIGHT_REMOVED = "alert.config.provider.microsoft.sharePoint.webApplication.applicationPrincipalMaxRights.reasons.removed";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_WEB_APPLICATION_APPLICATION_PRINCIPAL_MAX_RIGHT_ADDED = "alert.config.provider.microsoft.sharePoint.webApplication.applicationPrincipalMaxRights.reasons.added";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_WEB_APPLICATION_PERMISSIVE_BROWSER_FILE_HANDLING_ENABLED = "alert.config.provider.microsoft.sharePoint.webApplication.permissiveBrowserFileHandling.reasons.enabled";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_WEB_APPLICATION_POLICIES_ADDED = "alert.config.provider.microsoft.sharePoint.webApplication.policies.reasons.added";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_WEB_APPLICATION_POLICIES_HAS_SYSTEM_ACCOUNT = "alert.config.provider.microsoft.sharePoint.webApplication.policies.reasons.hasSystemAccount";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_WEB_APPLICATION_IIS_SETTINGS_ADDED_ZONE_ALLOW_ANONYMOUS_ENABLED = "alert.config.provider.microsoft.sharePoint.webApplication.iisSettings.addedZone.allowAnonymous.reasons.enabled";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_WEB_APPLICATION_IIS_SETTINGS_UPDATED_ZONE_ALLOW_ANONYMOUS_ENABLED = "alert.config.provider.microsoft.sharePoint.webApplication.iisSettings.updatedZone.allowAnonymous.reasons.enabled";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_WEB_APPLICATION_CLIENT_CALLABLE_SETTINGS_ANONYMOUS_RESTRICTED_TYPES_ENTITY_RESTRICTED_TYPES_REMOVED = "alert.config.provider.microsoft.sharePoint.webApplication.clientCallableSettings.anonymousRestrictedTypes.reasons.entityRestrictedTypesRemoved";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_WEB_APPLICATION_CLIENT_CALLABLE_SETTINGS_ANONYMOUS_RESTRICTED_TYPES_GET_PROPERTY_NAMES_REMOVED = "alert.config.provider.microsoft.sharePoint.webApplication.clientCallableSettings.anonymousRestrictedTypes.reasons.getPropertyNamesRemoved";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_WEB_APPLICATION_SEND_LOGIN_CREDENTIALS_BY_EMAIL_ENABLED = "alert.config.provider.microsoft.sharePoint.webApplication.sendLoginCredentialsByEmail.reasons.enabled";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_ADMIN_AUDIT_SHARING_OUTSIDE_ORGANIZATION_ALLOW_EXISTING_EXTERNAL_USERS_IN_DIRECTORY = MicrosoftConstants.SHAREPOINT_ALERT_BASEKEY_SHARINGCONFIGURATION + ".reasons.sharingOutsideOrganization.allowWithExistingExternalUsersInDirectory";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_ADMIN_AUDIT_SHARING_OUTSIDE_ORGANIZATION_ALLOW_USER_TO_INVITE_AND_SHARE_WITH_AUTHENTICATED_EXTERNAL_USERS = MicrosoftConstants.SHAREPOINT_ALERT_BASEKEY_SHARINGCONFIGURATION + ".reasons.sharingOutsideOrganization.allowUserToInviteAndShareWithAuthenticatedExternalUsers";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_ADMIN_AUDIT_SHARING_OUTSIDE_ORGANIZATION_ALLOW_SHARING_TO_AUTHENTICATED_EXTERNAL_USERS_AND_ANONYMOUS_LINKS = MicrosoftConstants.SHAREPOINT_ALERT_BASEKEY_SHARINGCONFIGURATION + ".reasons.sharingOutsideOrganizatio.allowSharingToAuthenticatedExternalUSersAndAnonymousLinksn";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_ADMIN_AUDIT_SHARING_OUTSIDE_ORGANIZATION_REQUIRE_ANONYMOUS_LINKS_EXPIRE_IN_DAYS = MicrosoftConstants.SHAREPOINT_ALERT_BASEKEY_SHARINGCONFIGURATION + ".reasons.sharingOutsideOrganization.requireAnonymousLinksExpireInDays";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_ADMIN_AUDIT_SHARING_OUTSIDE_ORGANIZATION_FILE_ANONYMOUS_LINK_PERMISSION = MicrosoftConstants.SHAREPOINT_ALERT_BASEKEY_SHARINGCONFIGURATION + ".reasons.sharingOutsideOrganization.fileAnonymousLinkPermission";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_ADMIN_AUDIT_SHARING_OUTSIDE_ORGANIZATION_FOLDER_ANONYMOUS_LINK_PERMISSION = MicrosoftConstants.SHAREPOINT_ALERT_BASEKEY_SHARINGCONFIGURATION + ".reasons.sharingOutsideOrganization.folderAnonymousLinkPermission";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_ADMIN_AUDIT_SHARING_OUTSIDE_ORGANIZATION_WHO_CAN_SHARE_OUTSIDE_SHARE_WITH_AUTHENTICATED_EXTERNAL_USERS_LIMITED_GROUPS_ADDED = MicrosoftConstants.SHAREPOINT_ALERT_BASEKEY_SHARINGCONFIGURATION + ".reasons.whoCanShareOutside.shareWithAuthenticatedExternalUsersLimitedGroupsAdded";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_ADMIN_AUDIT_SHARING_OUTSIDE_ORGANIZATION_WHO_CAN_SHARE_OUTSIDE_SHARE_WITH_AUTHENTICATED_EXTERNAL_USERS_AND_ANONYMOUS_LINKS_GROUPS_ADDED = MicrosoftConstants.SHAREPOINT_ALERT_BASEKEY_SHARINGCONFIGURATION + ".reasons.whoCanShareOutside.shareWithAuthenticatedExternalUsersAndAnonymousLinksGroupsAdded";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_ADMIN_AUDIT_SHARING_DEFAULT_LINK_DEFAULT_LINK_TYPE_ANONYMOUS = MicrosoftConstants.SHAREPOINT_ALERT_BASEKEY_SHARINGCONFIGURATION + ".reasons.defaultLink.defaultLinkTypeAnonymous";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_ADMIN_AUDIT_SHARING_DEFAULT_LINK_DEFAULT_LINK_PERMISSION_EDIT = MicrosoftConstants.SHAREPOINT_ALERT_BASEKEY_SHARINGCONFIGURATION + ".reasons.defaultLink.defaultLinkPermissionEdit";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_ADMIN_AUDIT_SHARING_ADDITIONAL_SETTINGS_SHARING_DOMAIN_RESTRICTION_MODE_DEACTIVATED = MicrosoftConstants.SHAREPOINT_ALERT_BASEKEY_SHARINGCONFIGURATION + ".reasons.additionalSettings.sharingDomainRestrictionModeDeactivated";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_ADMIN_AUDIT_SHARING_ADDITIONAL_SETTINGS_PREVENT_EXTERNAL_USERS_FROM_RESHARING_DEACTIVATED = MicrosoftConstants.SHAREPOINT_ALERT_BASEKEY_SHARINGCONFIGURATION + ".reasons.additionalSettings.preventExternalUsersFromResharingDeactivated";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_ADMIN_AUDIT_SHARING_ADDITIONAL_SETTINGS_REQUIRE_ACCEPTING_ACCOUNT_MATCH_INVITED_ACCOUNT_DEACTIVATED = MicrosoftConstants.SHAREPOINT_ALERT_BASEKEY_SHARINGCONFIGURATION + ".reasons.additionalSettings.requireAcceptingAccountMatchInvitedAccountDeactivated";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_ADMIN_AUDIT_SHARING_ADDITIONAL_SETTINGS_EMAIL_ATTESTATION_ENABLED_DEACTIVATED = MicrosoftConstants.SHAREPOINT_ALERT_BASEKEY_SHARINGCONFIGURATION + ".reasons.additionalSettings.emailAttestationEnabledDeactivated";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_ADMIN_AUDIT_SHARING_NOTIFICATION_SETTINGS_OWNER_NOTIFICATION_RESHARE = MicrosoftConstants.SHAREPOINT_ALERT_BASEKEY_SHARINGCONFIGURATION + ".reasons.notificationSettings.ownerNotificationReshare";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_ADMIN_AUDIT_SHARING_NOTIFICATION_SETTINGS_OWNER_NOTIFICATION_ACCEPT = MicrosoftConstants.SHAREPOINT_ALERT_BASEKEY_SHARINGCONFIGURATION + ".reasons.notificationSettings.ownerNotificationAccept";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_ADMIN_AUDIT_SHARING_NOTIFICATION_SETTINGS_OWNER_NOTIFICATION_ANONYMOUS = MicrosoftConstants.SHAREPOINT_ALERT_BASEKEY_SHARINGCONFIGURATION + ".reasons.notificationSettings.ownerNotificationAnonymous";

        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_LIST_ALLOW_EVERYONE_VIEW_ITEMS_ENABLED = "alert.config.provider.microsoft.sharePoint.list.allowEveryOneViewItems.reasons.enabled";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_LIST_ANONYMOUS_PERM_MASK_MODIFIED = "alert.config.provider.microsoft.sharePoint.list.anonymousPermMask.reasons.modified";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_LIST_ALLOW_RSS_FEEDS_ENABLED = "alert.config.provider.microsoft.sharePoint.list.allowRssFeeds.reasons.enabled";


        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_ADMIN_AUDIT_SHARING_ONEDRIVE_SHARING_LEVEL_EXTRANET_WITH_EXISTING_SHARE_BY_EMAIL_USER_ONLY = MicrosoftConstants.SHAREPOINT_ALERT_BASEKEY_SHARINGCONFIGURATION + ".reasons.oneDriveSharingLevel.extranetWithExistingShareByEmailUserOnly";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_ADMIN_AUDIT_SHARING_ONEDRIVE_SHARING_LEVEL_EXTRANET = MicrosoftConstants.SHAREPOINT_ALERT_BASEKEY_SHARINGCONFIGURATION + ".reasons.oneDriveSharingLevel.extranet";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_SHAREPOINT_ADMIN_AUDIT_SHARING_ONEDRIVE_SHARING_LEVEL_EXTRANET_WITH_SHARE_BY_LINK = MicrosoftConstants.SHAREPOINT_ALERT_BASEKEY_SHARINGCONFIGURATION + ".reasons.oneDriveSharingLevel.extranetWithShareByLink";
        
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_ONEDRIVE_BASE_RESOURCE_KEY = "alert.config.provider.microsoft.oneDrive.permissions";

        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_OD_SP_OUTSIDE_COMPANY_EVERYONE = ".outsideCompany.everyone";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_OD_SP_OUTSIDE_COMPANY_INDIVIDUAL = ".outsideCompany.individual";

        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_OD_SP_INSIDE_COMPANY_EVERYONE = ".insideCompany.everyone";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_OD_SP_INSIDE_COMPANY_GROUP = ".insideCompany.group";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_OD_SP_INSIDE_COMPANY_INDIVIDUAL = ".insideCompany.individual";

        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_OD_SP_WITH_EDIT_PERMISSION = ".common.withEditPermissions";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_OD_SP_WITH_READ_PERMISSION = ".common.witReadPermissions";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_OD_SP_WITHOUT_PERMISSION_DETAILS = ".common.withoutPermissionDetails";

        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_OD_SP_OUTSIDE_COMPANY_ANONYMOUS_LINK_EXPIRATION_CHANGED = ".outsideCompany.anonymousLinkExpirationChanged";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_OD_SP_OUTSIDE_COMPANY_ANONYMOUS_LINK_EXPIRATION_SET = ".outsideCompany.anonymousLinkExpirationSet";
        

        private static readonly AZUREAD_ALERT_BASEKEY = "alert.config.provider.microsoft.azureAD.";
        private static readonly AZUREAD_ALERT_BASEKEY_APPPERMISSIONS = MicrosoftConstants.AZUREAD_ALERT_BASEKEY + "appPermissions";

        private static readonly ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_BASE_RESOURCE_KEY = MicrosoftConstants.AZUREAD_ALERT_BASEKEY_APPPERMISSIONS + ".reasons";
        private static readonly ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_INTERNAL_APP = ".internalApp";
        private static readonly ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_EXTERNAL_APP = ".externalApp";
        private static readonly ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_ON_USER_ACCOUNT = ".onUserAccount";
        private static readonly ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_ON_TENANT_ACCOUNT = ".onTenantAccounts";

        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_INTERNAL_APP_ON_USER_ACCOUNT = MicrosoftConstants.ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_BASE_RESOURCE_KEY + MicrosoftConstants.ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_INTERNAL_APP + MicrosoftConstants.ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_ON_USER_ACCOUNT;
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_INTERNAL_APP_ON_TENANT_ACCOUNT = MicrosoftConstants.ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_BASE_RESOURCE_KEY + MicrosoftConstants.ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_INTERNAL_APP + MicrosoftConstants.ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_ON_TENANT_ACCOUNT;
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_EXTERNAL_APP_ON_USER_ACCOUNT = MicrosoftConstants.ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_BASE_RESOURCE_KEY + MicrosoftConstants.ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_EXTERNAL_APP + MicrosoftConstants.ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_ON_USER_ACCOUNT;
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_EXTERNAL_APP_ON_TENANT_ACCOUNT = MicrosoftConstants.ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_BASE_RESOURCE_KEY + MicrosoftConstants.ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_EXTERNAL_APP + MicrosoftConstants.ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_ON_TENANT_ACCOUNT;

        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_PERMISSION_DESCRIPTION = MicrosoftConstants.ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_BASE_RESOURCE_KEY + ".permissionDescription";

        public static readonly AZUREAD_ALERT_BASEKEY_0365GROUP = MicrosoftConstants.AZUREAD_ALERT_BASEKEY + "o365Group";

        public static readonly AZUREAD_ALERT_BASEKEY_CONTENTSEARCH = MicrosoftConstants.AZUREAD_ALERT_BASEKEY + "contentSearch";

        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_CONTENTSEARCH_UPDATED = MicrosoftConstants.AZUREAD_ALERT_BASEKEY_CONTENTSEARCH + ".reasons.updated";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_CONTENTSEARCH_CREATED = MicrosoftConstants.AZUREAD_ALERT_BASEKEY_CONTENTSEARCH + ".reasons.created";

        public static readonly AZUREAD_ALERT_BASEKEY_EDISCOVERYCASE = MicrosoftConstants.AZUREAD_ALERT_BASEKEY + "eDiscoveryCase";
        
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_EDISCOVERY_CASE_CREATED = MicrosoftConstants.AZUREAD_ALERT_BASEKEY_EDISCOVERYCASE + ".reasons.case.created";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_EDISCOVERY_CASE_UPDATED = MicrosoftConstants.AZUREAD_ALERT_BASEKEY_EDISCOVERYCASE + ".reasons.case.updated";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_EDISCOVERY_HOLD_CREATED = MicrosoftConstants.AZUREAD_ALERT_BASEKEY_EDISCOVERYCASE + ".reasons.hold.created";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_EDISCOVERY_HOLD_UPDATED = MicrosoftConstants.AZUREAD_ALERT_BASEKEY_EDISCOVERYCASE + ".reasons.hold.updated";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_EDISCOVERY_SEARCH_CREATED = MicrosoftConstants.AZUREAD_ALERT_BASEKEY_EDISCOVERYCASE + ".reasons.search.created";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_EDISCOVERY_SEARCH_UPDATED = MicrosoftConstants.AZUREAD_ALERT_BASEKEY_EDISCOVERYCASE + ".reasons.search.updated";

        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_0365GROUP_ADDED_MEMBER_AS_OWNER = MicrosoftConstants.AZUREAD_ALERT_BASEKEY_0365GROUP + ".reasons.addedMember.asOwner";
        public static readonly ALERT_SUSPICIOUS_CONFIG_REASON_AZUREAD_0365GROUP_ADDED_MEMBER_AS_MEMBER = MicrosoftConstants.AZUREAD_ALERT_BASEKEY_0365GROUP + ".reasons.addedMember.asMember";

}
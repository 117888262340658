import { OneDriveReportProfileDelegate } from '../../../microsoft/onedrive/profile/one-drive-report-profile-delegate';
import { OneDriveReportProfileRulesComputedDelegate } from '../../../microsoft/onedrive/profile/one-drive-report-profile-rules-computed-delegate';
import { ReportProfileResourceBase } from '../../../profile/report-profile-resource-base';
import { SharePointReportProfilePermissions } from '../../../microsoft/sharepoint/profile/share-point-report-profile-permissions';

export class OneDriveReportProfile extends ReportProfileResourceBase<OneDriveReportProfileRulesComputedDelegate> {
  delegates: OneDriveReportProfileDelegate[];
  sharedWithMeURL: string;
  permissions: SharePointReportProfilePermissions[];
  hasSensitivityLabel: boolean;
}

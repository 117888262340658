import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { StringHelper } from '../../../helpers/string-helper';
import { SessionService } from '../../../services/session-service';

@Component({
  selector: 'i2a-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.scss']
})
export class TitleBarComponent implements OnInit, OnChanges {
  @Input() public rounded: boolean;
  @Input() public id: string;
  @Input() public guidedTourKey: string;
  @Input() public linked: boolean;
  @Input() public className: string;
  @Input() public isCollapsed: boolean;
  
  public classes: any;

  constructor() {
    this.isCollapsed = false;
    this.rounded = true;
    this.id = "internalId"
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'isCollapsed': 
          case 'className': {
            this.setClasses();
          }
        }
      }
    }
  }

  ngOnInit() {    
    this.setClasses();
  }

  public collapse() {
    this.isCollapsed = !this.isCollapsed;
    this.setClasses();
  }

  private setClasses() {
    this.classes = null;
    this.classes = {};
    this.classes['card'] = true;
    this.classes['id-title-bar-card'] = true;
    this.classes['id-title-bar-linked'] = this.linked;
    this.classes['mt-3'] = true;
    this.classes['collapsed'] = this.isCollapsed;
    if (!StringHelper.isNullOrWhitespace(this.className)) {
      this.classes[this.className] = true;
    }
  }
}
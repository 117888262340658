import { Component, OnInit, Input, Inject, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../../../shared/modal/confirm-modal/confirm-modal.component';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';
import { BaseProviderInstanceResourceModel, ProviderInstancesDatasourceService, AlertConstants, ReportProfileAlertResourceNotBelongToMeModel, Constants, ReportProfileAlertDeleteResourceModel, ReportProfileResourceBase, ReportProfileAlertResourcesBaseModel, MicrosoftOfficeReportProfileModel, ProviderInstanceResourceSummaryModel } from '@i2a/web-api-client';
import { ProfileBaseClassComponent } from '../profile-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { ResourceNotBelongToMeModalComponent } from '../resource-not-belong-to-me-modal/resource-not-belong-to-me-modal.component';
import { ProviderHelper } from 'projects/web-user-profile/src/app/helpers/provider-helper';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-resource-actions',
  templateUrl: './resource-actions.component.html',
  styleUrls: ['./resource-actions.component.scss']
})
export class ResourceActionsComponent extends ProfileBaseClassComponent<any,ReportProfileResourceBase<any>> implements OnInit {
  @Input() public resource: BaseProviderInstanceResourceModel;
  @Input() public deleteResource: boolean;
  @Input() public commentResource: boolean;
  @Input() public resourceNotBelongToMe: boolean;
  @Input() public hideI2AOwners: boolean;
  @Input() public isSharePointSiteGroupBySite: boolean;
  @Input() public resources: BaseProviderInstanceResourceModel[];
  @Input() public openSite: boolean;
  @Input() public siteUrl: string;
  @Input() public headerTitle: string;
  @Input() public openTitleKey: string;
  
  public hasDeleteAlert: boolean;
  public hasNotBelongToMe: boolean;
  public deleteComments: string;
  public contactComments: string;
  public openSiteKey: string;
  public providerTypeLabel: string;
  public providerPhotoUrl: string;

  @ViewChild('deleteConfirmationTemplate') deleteConfirmationTemplate: TemplateRef<any>;
  @ViewChild('deleteConfirmationTemplateHeader') deleteConfirmationTemplateHeader: TemplateRef<any>;
  
  public deleteWarningKeys: string[];

  @ViewChild('commentConfirmationTemplate') commentConfirmationTemplate: TemplateRef<any>;

  @ViewChild('commentConfirmationTemplateHeader') commentConfirmationTemplateHeader: TemplateRef<any>;

  public isSharePoint: boolean;
  public hasSubSites: boolean;

  
  constructor(modalService: NgbModal,
    private providerInstancesDatasource: ProviderInstancesDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService, 
    private session: SessionService) {
    super(toastr, i18Next, modalService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.setItems();
    
    if (this.resourceProfile) {
      if (this.resourceProfile.providerInstanceResource.providerId === Constants.PROVIDER_ID_MICROSOFT && this.resourceProfile.providerInstanceResource.providerTypeId === Constants.PROVIDER_TYPE_INTRANET) {
        if (this.headerTitle) {
          this.providerTypeLabel = this.headerTitle
        } else {
          this.providerTypeLabel = `${this.i18Next.t(ProviderHelper.GetProviderTypeLabel(this.resourceProfile.providerInstanceResource.providerId, this.resourceProfile.providerInstanceResource.providerTypeId))} ${this.resourceProfile.providerInstanceResource.name}`;
        }
      } else {
        this.providerTypeLabel = this.i18Next.t(ProviderHelper.GetProviderTypeLabel(this.resourceProfile.providerInstanceResource.providerId, this.resourceProfile.providerInstanceResource.providerTypeId));
      }
      this.providerPhotoUrl = ProviderHelper.GetProviderImage(this.resourceProfile.providerInstanceResource.providerId, this.resourceProfile.providerInstanceResource.providerTypeId, this.resourceProfile);
    }

  }


  setItems() {
    if (this.alerts && this.resource) {
      super.setItems();

      this.hasDeleteAlert = AlertReasonHelper.alertsContainsReason(this.alerts, AlertConstants.ALERT_INVALID_STATE_REASON_RESOURCE_DELETE);
      this.hasNotBelongToMe = AlertReasonHelper.alertsContainsReason(this.alerts, AlertConstants.ALERT_INVALID_STATE_REASON_RESOURCE_NOT_BELONG_TO_ME);
      if (this.resource.providerId == Constants.PROVIDER_ID_MICROSOFT) {
        switch (this.resource.providerTypeId) {
          case Constants.PROVIDER_TYPE_MAILING:
            this.openSiteKey = "profile.provider.microsoft.exchange.openSite";
            this.deleteWarningKeys = null;
            break;
          case Constants.PROVIDER_TYPE_INTRANET:
            if (this.openTitleKey) {
              this.openSiteKey = this.openTitleKey;
            } else {
              let spProfile = (<MicrosoftOfficeReportProfileModel>this.profile)?.sharePointProfiles?.find(r => r.providerInstanceResource.id === (<ProviderInstanceResourceSummaryModel>this.resource).id);
              if (spProfile.driveId) {
                this.openSiteKey = "profile.provider.microsoft.sharePoint.openLibrary";
              } else {
                this.openSiteKey = "profile.provider.microsoft.sharePoint.openList";
              }
            }
            this.isSharePoint = true;
            this.deleteWarningKeys = null;
            break;
          case Constants.PROVIDER_TYPE_PERSONAL_STORAGE:
            this.openSiteKey = "profile.provider.microsoft.oneDrive.openSite";
            this.deleteWarningKeys = null;
            break;
          case Constants.PROVIDER_TYPE_GENERIC:
            this.openSiteKey = "profile.provider.microsoft.azureAD.openSite";
            this.deleteWarningKeys = null;
            break;
        }
      }
      else if (this.resource.providerId == Constants.PROVIDER_ID_ZIMBRA) {
        switch (this.resource.providerTypeId) {
          case Constants.PROVIDER_TYPE_MAILING:
            this.openSiteKey = "profile.provider.microsoft.exchange.openSite";
            this.deleteWarningKeys = null;
            break;
        }
      }
    }
  }

  public onDeleteResource() {
    const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: 'id-modal', centered: true, 'size': 'lg' });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.title = this.i18Next.t("modal.deleteConfirmation.title");
    modalRef.componentInstance.bodyTemplate = this.deleteConfirmationTemplate;
    modalRef.componentInstance.headerTemplate = this.deleteConfirmationTemplateHeader;
    modalRef.componentInstance.cancel = this.i18Next.t("button.cancel");
    modalRef.componentInstance.ok = this.i18Next.t("button.ok");
    modalRef.componentInstance.buttonOkClass = "btn-danger";
    modalRef.result.then(() => {
      let model = new ReportProfileAlertDeleteResourceModel();
      this.initAlertModel(model);
      model.comments = this.deleteComments;
      if (this.isSharePointSiteGroupBySite) {
        model.providerInstanceResources = this.resources;
      } else {
        model.providerInstanceResources = [this.resource];  
      }
      this.subscriptions.add(this.providerInstancesDatasource.invalidStateReportDeleteResource(this.profile.userId, model).subscribe(model => {
        this.setAlertProcessed(model);
      }));
    }, (reason) => {
      //Dismiss
    });
  }

  
  public onCommentResource() {
    const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.title = this.i18Next.t("modal.commentConfirmation.title");
    modalRef.componentInstance.headerTemplate = this.commentConfirmationTemplateHeader;
    modalRef.componentInstance.bodyTemplate = this.commentConfirmationTemplate;
    modalRef.componentInstance.cancel = this.i18Next.t("button.cancel");
    modalRef.componentInstance.ok = this.i18Next.t("button.ok");
    modalRef.componentInstance.buttonOkClass = "btn-danger";
    modalRef.result.then(() => {
      let model = new ReportProfileAlertResourcesBaseModel();
      this.initAlertModel(model);
      model.comments = this.contactComments;
      if (this.isSharePointSiteGroupBySite) {
        model.providerInstanceResources = this.resources;
      } else {
        model.providerInstanceResources = [this.resource];  
      }
      this.subscriptions.add(this.providerInstancesDatasource.invalidStateReportCommentResource(this.profile.userId, model).subscribe(model => {
        this.setAlertProcessed(model);
      }));
    }, (reason) => {
      //Dismiss
    });
  }

  public onResourceNotBelongToMe() {
    const modalRef = this.modalService.open(ResourceNotBelongToMeModalComponent, { windowClass: 'id-modal', centered: true, size: 'lg' });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.headerTitle = this.headerTitle;
    modalRef.componentInstance.hideI2AOwners = this.hideI2AOwners;
    modalRef.componentInstance.resource = this.resource;
    modalRef.componentInstance.owner = this.user;
    modalRef.result.then((model: ReportProfileAlertResourceNotBelongToMeModel) => {
      this.initAlertModel(model);
      if (this.isSharePointSiteGroupBySite) {
        model.providerInstanceResources = this.resources;
      } else {
        model.providerInstanceResources = [this.resource];  
      }
      this.subscriptions.add(this.providerInstancesDatasource.invalidStateReportResourceNotBelongToMe(this.profile.userId, model).subscribe(model => {
        this.setAlertProcessed(model);
      }));
    }).catch((error) => {
      // console.log(error);
    });

  }

  public deleteSiteChange(value: boolean) {
    if (value) {

    } else {

    }
  }

  

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }

}

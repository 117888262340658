import { Component, Inject, Input, OnInit } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ProviderHelper } from 'projects/web-user-profile/src/app/helpers/provider-helper';
import { WarningHelper } from 'projects/web-user-profile/src/app/helpers/warning-helper';
import { WarningInfo } from 'projects/web-user-profile/src/app/models/warnings/warning-info';

@Component({
  selector: 'i2a-warning-notification',
  templateUrl: './warning-notification.component.html',
  styleUrls: ['./warning-notification.component.scss']
})
export class WarningNotificationComponent implements OnInit {

  @Input() public warning: WarningInfo;

  public providerPhotoUrl: string;
  public buttonOpenAlertModalKey: string;
  public buttonVviewMoreDetailbuttonKey: string;

  constructor(@Inject(I18NEXT_SERVICE) private i18Next: ITranslationService) {
    this.buttonVviewMoreDetailbuttonKey = this.i18Next.t('button.viewMoreDetails');
    this.buttonOpenAlertModalKey = this.i18Next.t('button.openAlertModal');
  }

  ngOnInit(): void {
    this.providerPhotoUrl = ProviderHelper.GetProviderImage(this.warning.resourceProfile.providerInstanceResource.providerId, this.warning.resourceProfile.providerInstanceResource.providerTypeId, this.warning.resourceProfile);
  }
}

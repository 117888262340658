import { Pipe, PipeTransform } from '@angular/core';
import dayJS from 'dayjs';

@Pipe({ name: 'amDateFormat' })
export class DateFormatPipe implements PipeTransform {
  transform(value: any, ...args: any[]): string {
    if (!value) {
      return '';
    }
    return dayJS(value).format(args[0].toString());
  }
}
import { Component, OnInit, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { ReportProfileBaseModel, UserModel, BaseProviderInstanceResourceModel, MicrosoftOfficeReportProfileModel, SharePointReportProfileDelegate, SharePointReportProfile, AlertInvalidStateFeaturedModel, SharePointGroupModel } from '@i2a/web-api-client';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { SharePointO365GroupConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/sharepoint-o365-group-configuration';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@RegisterMyProfileComponent(TemplateConstants.MODULE_SHAREPOINT_O365_GROUP)
@Component({
  selector: 'i2a-share-point-o365-group-module',
  templateUrl: './share-point-o365-group-module.component.html',
  styleUrls: ['./share-point-o365-group-module.component.scss']
})
export class SharePointO365GroupModuleComponent extends ProfileBaseClassComponent<SharePointO365GroupConfiguration, SharePointReportProfile> implements OnInit, OnChanges, IModule<SharePointO365GroupConfiguration> {

  @Input() public configuration: SharePointO365GroupConfiguration;
  @Input() public resource: BaseProviderInstanceResourceModel;
  @Input() public resourceTemplate?: BaseTemplate;
  @Input() public site: boolean = false;
  @Input() public resources: BaseProviderInstanceResourceModel[];

  public delegates: SharePointReportProfileDelegate[];
  public externalDelegates: SharePointReportProfileDelegate[];
  public isVisible: boolean;
  public SPGroups: SharePointGroupModel[];
  public subTitle: string;
  public description: string;
  public customClass: string = "";
  constructor(
    modalService: NgbModal,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService,
    private session: SessionService) {
    super(toastr, i18Next, modalService);
  }

  ngOnInit() {
    if (this.site && this.resource && this.profile && this.configuration && this.resourceTemplate && this.alerts) {
      this.initialize(this.user, this.profile, this.configuration, this.alerts, this.resource, this.resourceTemplate);   
    }
    
    this.isVisible = this.setIsVisibleComponent();

    if (this.site && this.site === this.session.SharePointGroupBySite) {
      this.subTitle = this.i18Next.t('profile.provider.microsoft.sharePoint.title.site.o365Groups');
      this.description = this.i18Next.t('profile.provider.microsoft.sharePoint.description.o365Groups');
      this.customClass = 'id-subtitle-group-by-site';
    } else {
      this.subTitle = this.i18Next.t('profile.provider.microsoft.sharePoint.title.o365Groups');
      this.description = this.i18Next.t('profile.provider.microsoft.sharePoint.description.o365Groups');
    }
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: SharePointO365GroupConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel, resourceTemplate?: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.resource = resource;
    this.resourceProfile = (<MicrosoftOfficeReportProfileModel>profile).sharePointProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
    this.resourceProfile.groups.forEach(group => {
      if (group.members.findIndex(m => m.loginName == "SHAREPOINT\\system") >= 0) {
        group.membersFiltered = group.membersFiltered || true;
      }
      group.members = group.members.filter(m => m.loginName != "SHAREPOINT\\system");
    });
    this.SPGroups = this.resourceProfile.groups.filter(group => group.members.length > 0);

  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }

  private setIsVisibleComponent(): boolean {
    let result: boolean = false;

    if (this.site) {
      result = this.resourceProfile && (this.resourceProfile.o365Groups.length > 0 || this.resourceProfile.administrators.length > 0) && this.session.SharePointGroupBySite;
    } else if (this.session.SharePointGroupBySite) {
      result = this.resourceProfile && (this.SPGroups.length > 0);
    } else {
      result = this.resourceProfile && (this.resourceProfile.o365Groups.length > 0 || this.resourceProfile.groups.length > 0 || this.resourceProfile.administrators.length > 0);
    }
    return result;
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'alerts': {
            this.updateAlerts(this.alerts);
          }
        }
      }
    }
  }

}

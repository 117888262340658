import { Component, Input, OnInit } from '@angular/core';
import { SharePointSite } from '@i2a/web-api-client';

@Component({
  selector: 'i2a-share-point-subsite-node',
  templateUrl: './share-point-subsite-node.component.html',
  styleUrls: ['./share-point-subsite-node.component.scss']
})
export class SharePointSubsiteNodeComponent implements OnInit {

  @Input() public node: SharePointSite;
  @Input() public allSites: SharePointSite[];

  public subSites: SharePointSite[];
  public lists: SharePointSite[];

  constructor() { }

  ngOnInit() {
    if (this.allSites && this.node) {
      this.lists = this.allSites.filter(s=> s.parentId == this.node.id && s.isList).sort((l1, l2) => l1.name.localeCompare(l2.name));
      this.subSites = this.allSites.filter(s=> s.parentId == this.node.id && !s.isList).sort((s1, s2) => s1.name.localeCompare(s2.name));
    }
  }

}

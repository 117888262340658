import { Component, OnInit, Input } from '@angular/core';
import { ProviderInstanceResourceModel, BaseProviderInstanceUserModel } from '@i2a/web-api-client';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'i2a-delegate-photo',
  templateUrl: './delegate-photo.component.html',
  styleUrls: ['./delegate-photo.component.scss']
})
export class DelegatePhotoComponent implements OnInit {
  @Input() public customIcon: IconProp = null;
  @Input() public isAnonymous: boolean;
  @Input() public isDefault: boolean;
  @Input() public isApplication: boolean;
  @Input() public isExternalUser: boolean;
  @Input() public isUnknown: boolean = false;
  @Input() public pir: ProviderInstanceResourceModel;
  @Input() public piu: BaseProviderInstanceUserModel;
  @Input() public userId: string;

  public defaultUrl: string

  constructor() { 
    this.defaultUrl = '';
  }

  ngOnInit() {

    if (this.piu == null && this.pir == null && !this.isAnonymous && !this.isApplication && !this.isDefault && !this.isExternalUser && this.customIcon == null && !this.isUnknown) {
      this.defaultUrl = "/assets/pictos/resource.jpg";
    }

  }

}

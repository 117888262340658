import { Criticality } from './criticality';
import { RuleGroup } from './rule-group';
import { RulePredicateEvaluationResultModel } from './rule-predicate-evaluation-result-model';
import { RuleStatus } from './rule-status';

export class RuleEvaluationResultModel {
  id: string;
  userId: string|null;
  orgUnitId: string|null;
  providerId: string|null;
  providerTypeId: string|null;
  providerInstanceId: string|null;
  providerInstanceResourceId: string|null;
  name: string;
  group: RuleGroup;
  criticality: Criticality|null;
  result: boolean;
  weight: number;
  isRelevant: boolean;
  ruleStatus: RuleStatus;
  conditionResults: RulePredicateEvaluationResultModel[];
  exceptionResults: RulePredicateEvaluationResultModel[];
}

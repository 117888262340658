import { NgModule, ModuleWithProviders, Optional, SkipSelf, APP_INITIALIZER, LOCALE_ID, InjectionToken } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { appInit } from './infrastructure/appInit';

import { I2A_CONFIG_SERVICE } from './infrastructure/config.service';
import { ContextService } from './infrastructure/context.service';
import { AuthenticationService } from './authentication/authentication.service';
import { AuthenticationGuardService } from './authentication/authentication-guard.service';
import { UrlBuilderHelperService } from './helpers/url-builder-helper.service';
import { RequestCachingService } from './infrastructure/request-caching.service';
import { WebCoreRoutesService } from './infrastructure/web-core-routes.service';

import { LocalizationUrlService } from './data/localization-url.service';
import { localeIdFactory, CultureManagerService } from './infrastructure/culture-manager.service';


import { I18NEXT_SERVICE, I18NextModule } from 'angular-i18next';
import { existPipe } from './libs/i18next/existPipe';

import { ToastrModule } from 'ngx-toastr';

import { ProbesStatusComponent } from './components/probes-status/probes-status.component';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { AuthCallbackCentralComponent } from './components/auth-callback-central/auth-callback-central.component';
import { SignOutComponent } from './components/sign-out/sign-out.component';


export const I2A_DISPLAY_ERROR_MESSAGE = new InjectionToken<boolean>("I2A_DISPLAY_ERROR_MESSAGE");

@NgModule({
  declarations: [
    AuthCallbackComponent,
    AuthCallbackCentralComponent,
    ProbesStatusComponent,
    SignOutComponent,
    existPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    I18NextModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    })
  ],
  exports: [
    ProbesStatusComponent,
    AuthCallbackComponent,
    AuthCallbackCentralComponent,
    SignOutComponent,
    existPipe
  ]
})

export class WebCoreModule {

  constructor(@Optional() @SkipSelf() parentModule: WebCoreModule) {
    if (parentModule) {
      throw new Error(
        'WebCoreModule is already loaded. Import it in the AppModule only');
    }
  }
  public static forRoot(environment: any, displayErrorMessages: boolean): ModuleWithProviders<WebCoreModule> {

    return {
      ngModule: WebCoreModule,
      providers: [
        ContextService,
        UrlBuilderHelperService,
        LocalizationUrlService,
        AuthenticationGuardService,
        AuthenticationService,
        WebCoreRoutesService,
        I18NextModule,
        existPipe,
        CultureManagerService,
        RequestCachingService,
        { provide: 'env', useValue: environment },
        { provide: APP_INITIALIZER, useFactory: appInit, deps: [I2A_CONFIG_SERVICE, I18NEXT_SERVICE], multi: true },
        { provide: LOCALE_ID, deps: [I18NEXT_SERVICE], useFactory: localeIdFactory },
        { provide: I2A_DISPLAY_ERROR_MESSAGE, useValue: displayErrorMessages }
      ]
    };
  }
}

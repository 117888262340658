import { Component, OnInit, Input, Inject } from '@angular/core';
import { SharePointReportProfileDelegate, SharePointReportProfileAlertExternalDelegatesModel, SharePointReportProfileAlertDelegateModel, SharePointReportProfileAlertPermissionsModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';
import { SharePointDelegate } from 'projects/web-user-profile/src/app/models/providers/microsoft/share-point/share-point-delegate';
import * as _ from "lodash";
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-share-point-external-delegates-modal',
  templateUrl: './share-point-external-delegates-modal.component.html',
  styleUrls: ['./share-point-external-delegates-modal.component.scss']
})
export class SharePointExternalDelegatesModalComponent implements OnInit {
  @Input() public delegates: SharePointDelegate[];
  @Input() public resourceProfile: ReportProfileResourceBase<any>;

  public validation: SharePointReportProfileAlertExternalDelegatesModel;
  public canSend: boolean;
  public delegateFolders: any[];//TODO CRADO
  public driveItemsPermissions: any[];//TODO CRADO
  public name: string;
  public allDelegates: boolean;
  public hasSpecificLink: boolean = false;
  public informationKey: string;

  hasAccesses(logonUserId: string): boolean {
    return this.delegateFolders.find(d => d.logonUserId == logonUserId).folders.length > 0;
  }

  hasPermissions(logonUserId: string): boolean {
    return this.driveItemsPermissions.find(d => d.logonUserId == logonUserId).driveItems.length > 0;
  }

  getFolders(logonUserId: string): string {
    return this.delegateFolders.find(d => d.logonUserId == logonUserId).folders;
  }

  getPermissions(logonUserId: string): string {
    return this.driveItemsPermissions.find(d => d.logonUserId == logonUserId).driveItems;
  }

  allDelegatesChecked(isChecked: boolean) {
    this.validation.delegates.forEach(d => {
      if (this.hasAccesses(d.delegate.logonUserId)) {
        d.allFolders = isChecked;
      }
      if (this.hasPermissions(d.delegate.logonUserId)) {
        d.permissions.allPaths = isChecked;
      }
    })
  }

  constructor(
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService,
    public session: SessionService
  ) {
    this.canSend = false;
    this.allDelegates = false;
  }

  private setCanSend() {
    setTimeout(() => {
      for (let index = 0; index < this.validation.delegates.length; index++) {
        const delegate = this.validation.delegates[index];
        this.canSend = delegate.folders.length > 0 || delegate.allFolders || delegate.permissions.invalidPaths.length > 0 || delegate.permissions.allPaths;
        if (this.canSend) {
          break;
        }
      }
      this.hasSpecificLink = false;
      for (let index = 0; index < this.validation.delegates.length; index++) {
        const delegate = this.validation.delegates[index];
        if (delegate.permissions.allPaths) {
          var perms = Object.values(delegate.permissions.permissions.driveItems);
          this.hasSpecificLink = perms.findIndex(p => p.isSpecificLink) >= 0;
        } else if (delegate.permissions.invalidPaths.length > 0) {
          for (let pathIndex = 0; pathIndex < delegate.permissions.invalidPaths.length; pathIndex++) {
            const path = delegate.permissions.invalidPaths[pathIndex];
            var perm = delegate.permissions.permissions.driveItems[path];
            this.hasSpecificLink = perm.isSpecificLink;
            if (this.hasSpecificLink) {
              break;
            }
          }
        }
        if (this.hasSpecificLink) {
          break;
        }
      }
    });
  }

  ngOnInit() {
    this.validation = new SharePointReportProfileAlertExternalDelegatesModel();
    this.validation.delegates = [];

    if (this.delegates) {
      let items = this.delegates[0].accesses.foldersAccessed.length;
      items += this.delegates[0].permissions && Object.keys(this.delegates[0].permissions.driveItems).length > 0 ? Object.keys(this.delegates[0].permissions.driveItems).length : 0;
      this.informationKey = this.delegates.length > 1 || items > 1 ? 'modal.reportAnomaly.checkMembers' : 'modal.reportAnomaly.checkMember';

      this.delegateFolders = [];
      this.driveItemsPermissions = [];
      for (let index = 0; index < this.delegates.length; index++) {
        const delegate = this.delegates[index];
        let delegateValidation = new SharePointReportProfileAlertDelegateModel;
        delegateValidation.folders = [];
        delegateValidation.delegate = delegate.accesses;
        var foldersDelegate = {
          logonUserId: delegate.accesses.logonUserId,
          folders: []
        }
        let allFolders = false;
        SharePointExternalDelegatesModalComponent.FoldersAccessedWithoutPermissions(delegate).forEach(folder => {
          foldersDelegate.folders.push({
            "name": folder,
            "checked": false
          });
          allFolders = true;
        });
        delegateValidation.allFolders = allFolders;
        delegateValidation.permissions = new SharePointReportProfileAlertPermissionsModel();
        delegateValidation.permissions.invalidPaths = [];
        delegateValidation.permissions.permissions = delegate.permissions;
        var driveItemsPermissions = {
          logonUserId: delegate.accesses.logonUserId,
          driveItems: []
        }
        if (delegate.permissions != null) {
          Object.keys(delegate.permissions.driveItems).forEach(driveItemKey => {
            driveItemsPermissions.driveItems.push({
              "name": driveItemKey,
              "checked": false,
              "hasSensitivityLabel": delegate.permissions.driveItems[driveItemKey].hasSensitivityLabel,
              "sensitivityLabel": delegate.permissions.driveItems[driveItemKey].sensitivityLabel,
              "roles": delegate.permissions.driveItems[driveItemKey].rights
            });
          });
          delegateValidation.permissions.allPaths = true;
        }

        this.delegateFolders.push(foldersDelegate);
        this.driveItemsPermissions.push(driveItemsPermissions);

        this.validation.delegates.push(delegateValidation);
      }
      this.setCanSend();
    }
  }

  public static FoldersAccessedWithoutPermissions(delegate: SharePointDelegate): string[] {
    var folders = new Array<string>();

    if (delegate.permissions != null) {
      var permissionFolders = Object.keys(delegate.permissions.driveItems);
      delegate.accesses.foldersAccessed.forEach(a => {
        if (permissionFolders.findIndex(p => a.startsWith(p)) == -1) {
          folders.push(a);
        }
      });
    } else {
      folders = delegate.accesses.foldersAccessed;
    }

    return folders;
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class DatasourceService implements OnDestroy {
  private subscriptions = new Subscription();

  private apiEndPoint: string;
  public get ApiEndPoint(): string {
    return this.apiEndPoint;
  }
  public set ApiEndPoint(apiEndPoint: string) {
    this.apiEndPoint = apiEndPoint;
  }

  constructor(
    private http: HttpClient
  ) {
    console.log("Init datasource");
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getUrl(partialUrl: string): string {
    return `${this.apiEndPoint}/${partialUrl}`;
  }

  public get<T>(partialUrl: string, params: { [param: string]: string; }, useCache: boolean = false, throwError: boolean = false, responseType: string = null, observe: string = null): Observable<T> {
    let url: string = `${this.apiEndPoint}/${partialUrl}`;
    let options: { headers: any, params: any, responseType?: any, observe?: any } = {
      params: params,
      headers: new HttpHeaders().set('X-I2A-CLIENT-CACHE', String(useCache))
    };
    if (responseType != null) {
      options.responseType = responseType
    }
    if (observe != null) {
      options.observe = observe;
    }

    var getReturn = this.http.get<T>(url, options);
    if (throwError) {
      return getReturn;
    }
    else {
      return getReturn.pipe(
        catchError(this.handleError<T>(url))
      );
    }
  }

  public put(partialUrl: string, model: any, options: any = {}, throwError: boolean = false): Observable<any> {
    let url: string = `${this.apiEndPoint}/${partialUrl}`;

    var putReturn = this.http.put(url, model, options)
    if (throwError) {
      return putReturn;
    }
    else {
      return putReturn.pipe(
        catchError(this.handleError<any>(url))
      );
    }
  }

  public delete(partialUrl: string, params: { [param: string]: string; }, throwError: boolean = false): Observable<any> {
    let url: string = `${this.apiEndPoint}/${partialUrl}`;

    var deleteReturn = this.http.delete(url, { params: params })
    if (throwError) {
      return deleteReturn;
    }
    else {
      return deleteReturn.pipe(
        catchError(this.handleError<any>(url))
      );
    }
  }

  public post(partialUrl: string, model: any, options: any = {}, throwError: boolean = false, responseType: string = null, observe: string = null): Observable<any> {
    let url: string = `${this.apiEndPoint}/${partialUrl}`;
    if (responseType != null) {
      options.responseType = responseType
    }
    if (observe != null) {
      options.observe = observe;
    }

    var postReturn = this.http.post(url, model, options);
    if (throwError) {
      return postReturn;
    }
    else {
      return postReturn.pipe(
        catchError(this.handleError(url))
      );
    }
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }



}

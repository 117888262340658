import { AlertInvalidStateFeaturedModel } from '../../models/alert-invalid-state-featured-model';
import { AuditReportModel } from '../../models/audit-report-model';
import { AzureADReportProfileAlertGrantsModel } from '../../models/microsoft/azuread/profile/azure-adreport-profile-alert-grants-model';
import { ExchangeReportProfileAlertDelegateModel } from '../../models/microsoft/profile/exchange-report-profile-alert-delegate-model';
import { ExchangeReportProfileAlertForwardMailboxesModel } from '../../models/microsoft/profile/exchange-report-profile-alert-forward-mailboxes-model';
import { ExchangeReportProfileAlertInboxRuleModel } from '../../models/microsoft/profile/exchange-report-profile-alert-inbox-rule-model';
import { ExchangeReportProfileAlertPermissionsModel } from '../../models/microsoft/exchange/profile/exchange-report-profile-alert-permissions-model';
import { FileShareReportProfileAlertDelegateModel } from '../../models/microsoft/fileshare/profile/file-share-report-profile-alert-delegate-model';
import { FileShareReportProfileAlertPermissionsModel } from '../../models/microsoft/fileshare/profile/file-share-report-profile-alert-permissions-model';
import { MicrosoftOfficeReportProfileAlertRulesComputedDelegateModel } from '../../models/profile/microsoft-office-report-profile-alert-rules-computed-delegate-model';
import { MicrosoftOfficeReportProfileModel } from '../../models/microsoft/profile/microsoft-office-report-profile-model';
import { OneDriveReportProfileAlertDelegateModel } from '../../models/microsoft/onedrive/profile/one-drive-report-profile-alert-delegate-model';
import { OneDriveReportProfileAlertExternalDelegatesModel } from '../../models/microsoft/onedrive/profile/one-drive-report-profile-alert-external-delegates-model';
import { ReportProfileLoadedModel } from '../../models/profile/report-profile-loaded-model';
import { ReportProfileValidateModel } from '../../models/profile/report-profile-validate-model';
import { SharePointReportProfileAlertAdministratorsModel } from '../../models/microsoft/sharepoint/profile/share-point-report-profile-alert-administrators-model';
import { SharePointReportProfileAlertDelegateModel } from '../../models/microsoft/sharepoint/profile/share-point-report-profile-alert-delegate-model';
import { SharePointReportProfileAlertDeleteResourceModel } from '../../models/microsoft/sharepoint/profile/share-point-report-profile-alert-delete-resource-model';
import { SharePointReportProfileAlertExternalDelegatesModel } from '../../models/microsoft/sharepoint/profile/share-point-report-profile-alert-external-delegates-model';
import { SharePointReportProfileAlertExternalPermissionsModel } from '../../models/microsoft/sharepoint/profile/share-point-report-profile-alert-external-permissions-model';
import { SharePointReportProfileAlertGroupModel } from '../../models/microsoft/sharepoint/profile/share-point-report-profile-alert-group-model';
import { SharePointReportProfileAlertO365GroupModel } from '../../models/microsoft/sharepoint/profile/share-point-report-profile-alert-o365-group-model';
import { SharePointReportProfileAlertPermissionsModel } from '../../models/microsoft/sharepoint/profile/share-point-report-profile-alert-permissions-model';
import { SharePointReportProfileAlertVisibilityModel } from '../../models/microsoft/sharepoint/profile/share-point-report-profile-alert-visibility-model';
import { UserReportProfileAlertAccessModesModel } from '../../models/profile/user-report-profile-alert-access-modes-model';
import { UserReportProfileAlertDevicesModel } from '../../models/profile/user-report-profile-alert-devices-model';
import { UserReportProfileAlertOriginsModel } from '../../models/profile/user-report-profile-alert-origins-model';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatasourceService } from '../../../data/datasource.service';

@Injectable({
	providedIn: 'root'
})
export class MicrosoftOfficeProfileProviderDatasourceService {

private route: string = "providers/microsoft";

	constructor(private datasource: DatasourceService) {
		console.log("Init MicrosoftOfficeProfileProviderDatasourceService");
	}

	public auditReport(model: AuditReportModel, throwError: boolean = true): Observable<HttpResponse<Blob>> {
		let partialUrl = `${this.route}/audit-report`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError, 'blob','response');
	}

	public loadProfile(providerInstanceId: string, userId: string, useCache: boolean = false, throwError: boolean = true): Observable<MicrosoftOfficeReportProfileModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}`;

		return this.datasource.get<MicrosoftOfficeReportProfileModel>(partialUrl, { }, useCache, throwError);
	}

	public profileLoaded(providerInstanceId: string, userId: string, model: ReportProfileLoadedModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/loaded`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public validateProfile(providerInstanceId: string, userId: string, model: ReportProfileValidateModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/validate`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertUserAccessModes(providerInstanceId: string, userId: string, model: UserReportProfileAlertAccessModesModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/access-modes`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertUserOrigins(providerInstanceId: string, userId: string, model: UserReportProfileAlertOriginsModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel[]> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/origins`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertUserDevices(providerInstanceId: string, userId: string, model: UserReportProfileAlertDevicesModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel[]> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/devices`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertRulesComputedDelegate(providerInstanceId: string, userId: string, model: MicrosoftOfficeReportProfileAlertRulesComputedDelegateModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/rules-computed-delegate`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertExchangeDelegate(providerInstanceId: string, userId: string, model: ExchangeReportProfileAlertDelegateModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel[]> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/exchange/delegate`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertExchangePermissions(providerInstanceId: string, userId: string, model: ExchangeReportProfileAlertPermissionsModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/exchange/permissions`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertExchangeInboxRule(providerInstanceId: string, userId: string, model: ExchangeReportProfileAlertInboxRuleModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/exchange/inbox-rule`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertExchangeForwardMailboxes(providerInstanceId: string, userId: string, model: ExchangeReportProfileAlertForwardMailboxesModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/exchange/forward-mailboxes`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertOneDriveDelegate(providerInstanceId: string, userId: string, model: OneDriveReportProfileAlertDelegateModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel[]> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/one-drive/delegate`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertOneDriveExternalDelegates(providerInstanceId: string, userId: string, model: OneDriveReportProfileAlertExternalDelegatesModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel[]> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/one-drive/external-delegates`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertOneDrivePermissions(providerInstanceId: string, userId: string, model: SharePointReportProfileAlertPermissionsModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/one-drive/permissions`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertOneDriveExternalPermissions(providerInstanceId: string, userId: string, model: SharePointReportProfileAlertExternalPermissionsModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/one-drive/external-permissions`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertOneDriveDelegateShareInstantMessaging(providerInstanceId: string, userId: string, model: OneDriveReportProfileAlertDelegateModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/one-drive/share-instant-messaging`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertSharePointDelete(providerInstanceId: string, userId: string, model: SharePointReportProfileAlertDeleteResourceModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/share-point/delete`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertSharePointDelegate(providerInstanceId: string, userId: string, model: SharePointReportProfileAlertDelegateModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel[]> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/share-point/delegate`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertSharePointExternalDelegates(providerInstanceId: string, userId: string, model: SharePointReportProfileAlertExternalDelegatesModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel[]> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/share-point/external-delegates`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertSharePointPermissions(providerInstanceId: string, userId: string, model: SharePointReportProfileAlertPermissionsModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/share-point/permissions`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertSharePointExternalPermissions(providerInstanceId: string, userId: string, model: SharePointReportProfileAlertExternalPermissionsModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/share-point/external-permissions`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertSharePointO365Group(providerInstanceId: string, userId: string, model: SharePointReportProfileAlertO365GroupModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/share-point/o365-group`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertSharePointVisibility(providerInstanceId: string, userId: string, model: SharePointReportProfileAlertVisibilityModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/share-point/visibility`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertSharePointGroup(providerInstanceId: string, userId: string, model: SharePointReportProfileAlertGroupModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/share-point/group`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertSharePointAdministrators(providerInstanceId: string, userId: string, model: SharePointReportProfileAlertAdministratorsModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/share-point/administrators`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertAzureADGrants(providerInstanceId: string, userId: string, model: AzureADReportProfileAlertGrantsModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/azureAD/grants`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertFileShareDelegate(providerInstanceId: string, userId: string, model: FileShareReportProfileAlertDelegateModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/file-share/delegate`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertFileSharePermissions(providerInstanceId: string, userId: string, model: FileShareReportProfileAlertPermissionsModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/file-share/permissions`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

}

import { Injectable, Type, ViewContainerRef, ComponentFactoryResolver, ComponentRef, Injector } from '@angular/core';
import "reflect-metadata";
import { ComponentRegistry } from './component-registry';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  loadComponent<T>(viewContainerRef: ViewContainerRef, componentId: string): ComponentRef<T> {
    var component = ComponentRegistry.getComponent(componentId);
    if (component) {
      let componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
      return viewContainerRef.createComponent(componentFactory);
    }
    else {
      console.warn(`component ${componentId} is not found !`)
      return null;
    }
  }
}
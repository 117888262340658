import { AuthSessionInfoModel } from '../models/auth-session-info-model';
import { DeviceMappedModel } from '../models/device-mapped-model';
import { DeviceModel } from '../models/device-model';
import { IPRangeCreateOrUpdateModel } from '../models/iprange-create-or-update-model';
import { IPRangeModel } from '../models/iprange-model';
import { NotificationPushRegistrationInfoModel } from '../models/notification-push-registration-info-model';
import { NotificationPushRegistrationModel } from '../models/notification-push-registration-model';
import { PageResultModel } from '../models/page-result-model';
import { ProfileCountryCreateModel } from '../models/profile-country-create-model';
import { ProfileCountryDeleteModel } from '../models/profile-country-delete-model';
import { ProfileCountryModel } from '../models/profile-country-model';
import { ProfileCountryRebuildModel } from '../models/profile-country-rebuild-model';
import { ProviderAuditUsageStatisticsModel } from '../models/provider-audit-usage-statistics-model';
import { ProviderInstanceCoupleCollectedModel } from '../models/provider-instance-couple-collected-model';
import { ProviderInstanceModel } from '../models/provider-instance-model';
import { ResourceActivityModel } from '../models/resource-activity-model';
import { RoleGroupUserModel } from '../models/role-group-user-model';
import { RuleCreateModelWithDomainModel } from '../models/rule-create-model-with-domain-model';
import { RuleDomain } from '../models/rule-domain';
import { RuleSummaryModel } from '../models/rule-summary-model';
import { SecurityInfosModel } from '../models/security-infos-model';
import { StatisticsGranularity } from '../models/statistics-granularity';
import { UpdateDevieModel } from '../models/update-devie-model';
import { UserActivityModel } from '../models/user-activity-model';
import { UserCreateModel } from '../models/user-create-model';
import { UserDetailedModel } from '../models/user-detailed-model';
import { UserKind } from '../models/user-kind';
import { UserLinkModel } from '../models/user-link-model';
import { UserLinkType } from '../models/user-link-type';
import { UserModel } from '../models/user-model';
import { UserReportInformation } from '../models/user-report-information';
import { UserStatesModel } from '../models/user-states-model';
import { UserSummaryModel } from '../models/user-summary-model';
import { UserUpdateModel } from '../models/user-update-model';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatasourceService } from '../../data/datasource.service';

@Injectable({
	providedIn: 'root'
})
export class UsersDatasourceService {

private route: string = "users";

	constructor(private datasource: DatasourceService) {
		console.log("Init UsersDatasourceService");
	}

	public search(name: string, orgUnitId: string = null, isFavorite: boolean = false, securityCheck: boolean = true, limit: number = null, offset: number = null, useCache: boolean = false, throwError: boolean = true): Observable<PageResultModel<UserModel>> {
		let partialUrl = `${this.route}`;

		return this.datasource.get<PageResultModel<UserModel>>(partialUrl, {name: String(name),orgUnitId: String(orgUnitId),isFavorite: String(isFavorite),securityCheck: String(securityCheck),limit: String(limit),offset: String(offset) }, useCache, throwError);
	}

	public searchSummary(name: string, orgUnitId: string = null, limit: number = null, offset: number = null, useCache: boolean = false, throwError: boolean = true): Observable<PageResultModel<UserSummaryModel>> {
		let partialUrl = `${this.route}/summary`;

		return this.datasource.get<PageResultModel<UserSummaryModel>>(partialUrl, {name: String(name),orgUnitId: String(orgUnitId),limit: String(limit),offset: String(offset) }, useCache, throwError);
	}

	public export(name: string, informations: UserReportInformation, orgUnitId: string = null, isFavorite: boolean = false, useCache: boolean = false, throwError: boolean = true): Observable<HttpResponse<Blob>> {
		let partialUrl = `${this.route}/export`;

		return this.datasource.get<HttpResponse<Blob>>(partialUrl, {name: String(name),informations: String(informations),orgUnitId: String(orgUnitId),isFavorite: String(isFavorite) }, useCache, throwError, 'blob','response');
	}

	public loadTimeZones(useCache: boolean = false, throwError: boolean = true): Observable<string[]> {
		let partialUrl = `${this.route}/time-zones`;

		return this.datasource.get<string[]>(partialUrl, { }, useCache, throwError);
	}

	public loadCultures(useCache: boolean = false, throwError: boolean = true): Observable<string[]> {
		let partialUrl = `${this.route}/cultures`;

		return this.datasource.get<string[]>(partialUrl, { }, useCache, throwError);
	}

	public load(userId: string, useCache: boolean = false, throwError: boolean = true): Observable<UserModel> {
		let partialUrl = `${this.route}/${userId}`;

		return this.datasource.get<UserModel>(partialUrl, { }, useCache, throwError);
	}

	public create(model: UserCreateModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public update(userId: string, model: UserUpdateModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${userId}`;

		return this.datasource.put(partialUrl, model, { params: { } }, throwError);
	}

	public deactivate(userId: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${userId}`;

		return this.datasource.delete(partialUrl, null, throwError);
	}

	public hide(userId: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${userId}/hide`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public unHide(userId: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${userId}/un-hide`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public unlock(userId: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${userId}/unlock`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public loadSummary(userId: string, useCache: boolean = false, throwError: boolean = true): Observable<UserSummaryModel> {
		let partialUrl = `${this.route}/${userId}/summary`;

		return this.datasource.get<UserSummaryModel>(partialUrl, { }, useCache, throwError);
	}

	public getPhoto(userId: string, defaultImageifNotFound: boolean = false, useCache: boolean = false, throwError: boolean = true): Observable<HttpResponse<Blob>> {
		let partialUrl = `${this.route}/${userId}/photo`;

		return this.datasource.get<HttpResponse<Blob>>(partialUrl, {defaultImageifNotFound: String(defaultImageifNotFound) }, useCache, throwError, 'blob','response');
	}

	public hasRead(userId: string, useCache: boolean = false, throwError: boolean = true): Observable<boolean> {
		let partialUrl = `${this.route}/${userId}/has-read`;

		return this.datasource.get<boolean>(partialUrl, { }, useCache, throwError);
	}

	public hasWrite(userId: string, useCache: boolean = false, throwError: boolean = true): Observable<boolean> {
		let partialUrl = `${this.route}/${userId}/has-write`;

		return this.datasource.get<boolean>(partialUrl, { }, useCache, throwError);
	}

	public loadTrusteeFor(userId: string, withUser: boolean = true, useCache: boolean = false, throwError: boolean = true): Observable<UserModel[]> {
		let partialUrl = `${this.route}/${userId}/grantors`;

		return this.datasource.get<UserModel[]>(partialUrl, {withUser: String(withUser) }, useCache, throwError);
	}

	public loadTrusteeOf(userId: string, withUser: boolean = true, useCache: boolean = false, throwError: boolean = true): Observable<UserModel[]> {
		let partialUrl = `${this.route}/${userId}/trustees`;

		return this.datasource.get<UserModel[]>(partialUrl, {withUser: String(withUser) }, useCache, throwError);
	}

	public loadTrusteeSuggestions(userId: string, domain: RuleDomain, useCache: boolean = false, throwError: boolean = true): Observable<UserSummaryModel[]> {
		let partialUrl = `${this.route}/${userId}/trusteeSuggestions`;

		return this.datasource.get<UserSummaryModel[]>(partialUrl, {domain: String(domain) }, useCache, throwError);
	}

	public addTrustee(userId: string, trusteeUserIds: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${userId}/grant`;

		return this.datasource.put(partialUrl, trusteeUserIds, { params: { } }, throwError);
	}

	public removeTrustee(userId: string, trusteeUserId: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${userId}/revoke/${trusteeUserId}`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public clearCacheUserPermissions(throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/permissions/clear-cache`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public loadRoleGroupPermissions(userId: string, useCache: boolean = false, throwError: boolean = true): Observable<RoleGroupUserModel[]> {
		let partialUrl = `${this.route}/${userId}/rolegroup-permissions`;

		return this.datasource.get<RoleGroupUserModel[]>(partialUrl, { }, useCache, throwError);
	}

	public me(useCache: boolean = false, throwError: boolean = true): Observable<UserDetailedModel> {
		let partialUrl = `${this.route}/me`;

		return this.datasource.get<UserDetailedModel>(partialUrl, { }, useCache, throwError);
	}

	public kind(useCache: boolean = false, throwError: boolean = true): Observable<UserKind> {
		let partialUrl = `${this.route}/me/kind`;

		return this.datasource.get<UserKind>(partialUrl, { }, useCache, throwError);
	}

	public getSecurityInfos(useCache: boolean = false, throwError: boolean = true): Observable<SecurityInfosModel> {
		let partialUrl = `${this.route}/me/security-infos`;

		return this.datasource.get<SecurityInfosModel>(partialUrl, { }, useCache, throwError);
	}

	public getUserLink(userId: string, linkType: UserLinkType, useCache: boolean = false, throwError: boolean = true): Observable<string> {
		let partialUrl = `${this.route}/${userId}/link/${linkType}`;

		return this.datasource.get<string>(partialUrl, { }, useCache, throwError);
	}

	public getUserLinkByType(userId: string, linkType: UserLinkType, useCache: boolean = false, throwError: boolean = true): Observable<UserLinkModel> {
		let partialUrl = `${this.route}/${userId}/link-by-type/${linkType}`;

		return this.datasource.get<UserLinkModel>(partialUrl, { }, useCache, throwError);
	}

	public loadRoleGroupPermissions1(useCache: boolean = false, throwError: boolean = true): Observable<RoleGroupUserModel[]> {
		let partialUrl = `${this.route}/me/rolegroup-permissions`;

		return this.datasource.get<RoleGroupUserModel[]>(partialUrl, { }, useCache, throwError);
	}

	public loadTrusteeForMe(withMe: boolean = true, useCache: boolean = false, throwError: boolean = true): Observable<UserModel[]> {
		let partialUrl = `${this.route}/me/grantors`;

		return this.datasource.get<UserModel[]>(partialUrl, {withMe: String(withMe) }, useCache, throwError);
	}

	public loadTrusteeOfMe(withMe: boolean = true, useCache: boolean = false, throwError: boolean = true): Observable<UserModel[]> {
		let partialUrl = `${this.route}/me/trustees`;

		return this.datasource.get<UserModel[]>(partialUrl, {withMe: String(withMe) }, useCache, throwError);
	}

	public loadFavorites(useCache: boolean = false, throwError: boolean = true): Observable<UserSummaryModel[]> {
		let partialUrl = `${this.route}/me/favorites`;

		return this.datasource.get<UserSummaryModel[]>(partialUrl, { }, useCache, throwError);
	}

	public addToFavorites(userId: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/me/favorites/${userId}`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public removeFromFavorites(userId: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/me/favorites/${userId}`;

		return this.datasource.delete(partialUrl, null, throwError);
	}

	public getUserStates(useCache: boolean = false, throwError: boolean = true): Observable<UserStatesModel> {
		let partialUrl = `${this.route}/stats/user-states`;

		return this.datasource.get<UserStatesModel>(partialUrl, { }, useCache, throwError);
	}

	public sessionInfo(userId: string, useCache: boolean = false, throwError: boolean = true): Observable<AuthSessionInfoModel> {
		let partialUrl = `${this.route}/${userId}/auth`;

		return this.datasource.get<AuthSessionInfoModel>(partialUrl, { }, useCache, throwError);
	}

	public getUserProfileCountries(userId: string, useCache: boolean = false, throwError: boolean = true): Observable<ProfileCountryModel[]> {
		let partialUrl = `${this.route}/${userId}/profile-countries`;

		return this.datasource.get<ProfileCountryModel[]>(partialUrl, { }, useCache, throwError);
	}

	public clearCacheProfileCountries(throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/profile-countries/clear-cache`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public addUserProfileCountries(userId: string, createModel: ProfileCountryCreateModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${userId}/profile-countries/add`;

		return this.datasource.put(partialUrl, createModel, { params: { } }, throwError);
	}

	public rebuild(userId: string, rebuildModel: ProfileCountryRebuildModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${userId}/profile-countries/rebuild`;

		return this.datasource.put(partialUrl, rebuildModel, { params: { } }, throwError);
	}

	public deleteUserProfileCountries(userId: string, deleteModel: ProfileCountryDeleteModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${userId}/profile-countries/delete`;

		return this.datasource.delete(partialUrl, null, throwError);
	}

	public getUserSyncedDevices(userId: string, includeRelatedtoUserResources: boolean = true, isActiveBeginDate: Date = null, isActiveEndDate: Date = null, useCache: boolean = false, throwError: boolean = true): Observable<DeviceMappedModel[]> {
		let partialUrl = `${this.route}/${userId}/synced-devices`;

		return this.datasource.get<DeviceMappedModel[]>(partialUrl, {includeRelatedtoUserResources: String(includeRelatedtoUserResources),isActiveBeginDate: String(isActiveBeginDate),isActiveEndDate: String(isActiveEndDate) }, useCache, throwError);
	}

	public getUserRegisteredDevices(userId: string, useCache: boolean = false, throwError: boolean = true): Observable<DeviceModel[]> {
		let partialUrl = `${this.route}/${userId}/registered-devices`;

		return this.datasource.get<DeviceModel[]>(partialUrl, { }, useCache, throwError);
	}

	public getUserRegisteredDevice(userId: string, registeredDeviceId: string, useCache: boolean = false, throwError: boolean = true): Observable<DeviceModel> {
		let partialUrl = `${this.route}/${userId}/registered-devices/${registeredDeviceId}`;

		return this.datasource.get<DeviceModel>(partialUrl, { }, useCache, throwError);
	}

	public updateUserRegisteredDevice(userId: string, registeredDeviceId: string, updateDeviceModel: UpdateDevieModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${userId}/registered-devices/${registeredDeviceId}`;

		return this.datasource.put(partialUrl, updateDeviceModel, { params: { } }, throwError);
	}

	public loadByUser(userId: string, includeInherited: boolean, useCache: boolean = false, throwError: boolean = true): Observable<IPRangeModel[]> {
		let partialUrl = `${this.route}/${userId}/ip-ranges`;

		return this.datasource.get<IPRangeModel[]>(partialUrl, {includeInherited: String(includeInherited) }, useCache, throwError);
	}

	public createUserIPRange(userId: string, createModel: IPRangeCreateOrUpdateModel, throwError: boolean = true): Observable<IPRangeModel> {
		let partialUrl = `${this.route}/${userId}/ip-ranges`;

		return this.datasource.post(partialUrl, createModel, { params: { } }, throwError);
	}

	public registrations(userId: string, useCache: boolean = false, throwError: boolean = true): Observable<NotificationPushRegistrationInfoModel[]> {
		let partialUrl = `${this.route}/${userId}/notifications/push-registrations`;

		return this.datasource.get<NotificationPushRegistrationInfoModel[]>(partialUrl, { }, useCache, throwError);
	}

	public refreshPushRegistration(registration: NotificationPushRegistrationModel, throwError: boolean = true): Observable<string> {
		let partialUrl = `${this.route}/me/notifications/push-registrations/refresh`;

		return this.datasource.post(partialUrl, registration, { params: { } }, throwError);
	}

	public loadUserUsageUserPrefixed(providerId: string, providerInstanceId: string, userId: string, begin: Date, end: Date, granularity: StatisticsGranularity, useCache: boolean = false, throwError: boolean = true): Observable<ProviderAuditUsageStatisticsModel[]> {
		let partialUrl = `${this.route}/${userId}/provider-audits/usage-stats`;

		return this.datasource.get<ProviderAuditUsageStatisticsModel[]>(partialUrl, {providerId: String(providerId),providerInstanceId: String(providerInstanceId),begin: String(begin),end: String(end),granularity: String(granularity) }, useCache, throwError);
	}

	public loadUserActivityUserPrefixed(providerId: string, providerInstanceId: string, userId: string, begin: Date, end: Date, useCache: boolean = false, throwError: boolean = true): Observable<UserActivityModel[]> {
		let partialUrl = `${this.route}/${userId}/provider-audits/user-activities`;

		return this.datasource.get<UserActivityModel[]>(partialUrl, {providerId: String(providerId),providerInstanceId: String(providerInstanceId),begin: String(begin),end: String(end) }, useCache, throwError);
	}

	public loadResourceActivityByUser(userId: string, begin: Date, end: Date, filterAccounts: boolean = null, filterAllIPAndDelegatePosition: boolean = null, filterAllIPAndPosition: boolean = null, filterAlerts: boolean = null, hideAccessWithAccessModes: string = null, simplifyAccessModes: boolean = null, useCache: boolean = false, throwError: boolean = true): Observable<ResourceActivityModel[]> {
		let partialUrl = `${this.route}/${userId}/provider-audits/resource-activities`;

		return this.datasource.get<ResourceActivityModel[]>(partialUrl, {begin: String(begin),end: String(end),filterAccounts: String(filterAccounts),filterAllIPAndDelegatePosition: String(filterAllIPAndDelegatePosition),filterAllIPAndPosition: String(filterAllIPAndPosition),filterAlerts: String(filterAlerts),hideAccessWithAccessModes: String(hideAccessWithAccessModes),simplifyAccessModes: String(simplifyAccessModes) }, useCache, throwError);
	}

	public loadCollectedResources(userId: string, useCache: boolean = false, throwError: boolean = true): Observable<ProviderInstanceCoupleCollectedModel[]> {
		let partialUrl = `${this.route}/${userId}/provider-instance-resources`;

		return this.datasource.get<ProviderInstanceCoupleCollectedModel[]>(partialUrl, { }, useCache, throwError);
	}

	public loadProviderInstances(userId: string, useCache: boolean = false, throwError: boolean = true): Observable<ProviderInstanceModel[]> {
		let partialUrl = `${this.route}/${userId}/provider-instances`;

		return this.datasource.get<ProviderInstanceModel[]>(partialUrl, { }, useCache, throwError);
	}

	public loadByUser1(userId: string, domain: RuleDomain, includeInherited: boolean = false, includeRelatedToOthersResource: boolean = false, includeRelatedtoUserResources: boolean = false, useCache: boolean = false, throwError: boolean = true): Observable<RuleSummaryModel[]> {
		let partialUrl = `${this.route}/${userId}/rules`;

		return this.datasource.get<RuleSummaryModel[]>(partialUrl, {domain: String(domain),includeInherited: String(includeInherited),includeRelatedToOthersResource: String(includeRelatedToOthersResource),includeRelatedtoUserResources: String(includeRelatedtoUserResources) }, useCache, throwError);
	}

	public loadUserProfileRules(userId: string, domain: RuleDomain, includeInherited: boolean = false, withTestMode: boolean = null, useCache: boolean = false, throwError: boolean = true): Observable<RuleSummaryModel[]> {
		let partialUrl = `${this.route}/${userId}/rules/profile`;

		return this.datasource.get<RuleSummaryModel[]>(partialUrl, {domain: String(domain),includeInherited: String(includeInherited),withTestMode: String(withTestMode) }, useCache, throwError);
	}

	public loadUserPermissionRules(userId: string, domain: RuleDomain, includeInherited: boolean = false, includeRelatedtoUserResources: boolean = false, withTestMode: boolean = null, useCache: boolean = false, throwError: boolean = true): Observable<RuleSummaryModel[]> {
		let partialUrl = `${this.route}/${userId}/rules/permissions`;

		return this.datasource.get<RuleSummaryModel[]>(partialUrl, {domain: String(domain),includeInherited: String(includeInherited),includeRelatedtoUserResources: String(includeRelatedtoUserResources),withTestMode: String(withTestMode) }, useCache, throwError);
	}

	public loadUserPermissionRulesOnOtherResources(userId: string, domain: RuleDomain, withTestMode: boolean = null, useCache: boolean = false, throwError: boolean = true): Observable<RuleSummaryModel[]> {
		let partialUrl = `${this.route}/${userId}/rules/other-resources-permissions`;

		return this.datasource.get<RuleSummaryModel[]>(partialUrl, {domain: String(domain),withTestMode: String(withTestMode) }, useCache, throwError);
	}

	public createUserRule(userId: string, rule: RuleCreateModelWithDomainModel, throwError: boolean = true): Observable<RuleSummaryModel> {
		let partialUrl = `${this.route}/${userId}/rules`;

		return this.datasource.post(partialUrl, rule, { params: { } }, throwError);
	}

	public rebuildProfile(userId: string, providerId: string = null, providerTypeId: string = null, providerInstanceId: string = null, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${userId}/rebuild-profile`;

		return this.datasource.post(partialUrl, null, { params: {providerId: String(providerId),providerTypeId: String(providerTypeId),providerInstanceId: String(providerInstanceId) } }, throwError);
	}

	public clearCache(throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/clear-cache`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

}

import { Component, OnInit, Input } from '@angular/core';
import { ReportProfileDeviceModel, ReportProfileDeviceSyncModel } from '@i2a/web-api-client';
import { DeviceModel } from 'projects/web-user-profile/src/app/models/device-model';

@Component({
  selector: 'i2a-delegate-device',
  templateUrl: './delegate-device.component.html',
  styleUrls: ['./delegate-device.component.scss']
})
export class DelegateDeviceComponent implements OnInit {
  @Input() public deviceModels: ReportProfileDeviceModel[]
  @Input() public deviceSyncModels: ReportProfileDeviceSyncModel[];

  public devices: DeviceModel[];

  constructor() { 
    this.devices = [];
  }

  ngOnInit() {

    if (this.deviceModels) {
      this.deviceModels.forEach(d=> {
        this.devices.push( {
          'name' : d.name,
          'deviceType' : d.deviceType
        });
      })
    }

    if (this.deviceSyncModels) {
      this.deviceSyncModels.forEach(d=> {
        this.devices.push( {
          'name' : d.name,
          'deviceType' : d.deviceType
        });
      })
    }
  }

}

import { Injectable } from "@angular/core";
import { UrlBuilderHelperService } from '../helpers/url-builder-helper.service';
import { LocalizationRequester } from '../models/localization-requester';
import { ConfigService, IConfigService, IWebConfig } from '../infrastructure/config.service';

@Injectable()
export class LocalizationUrlService {

    constructor(private urlBuilderHelper: UrlBuilderHelperService) {

    }

    public getAvailableCulturesUrl(requester: LocalizationRequester, configService: IConfigService<IWebConfig>, tenantCode: string | null): string {
        let partialUrl = `localizations/languages?requester=${requester}`;

        let url: string = this.urlBuilderHelper.BuildUrl(partialUrl, configService, tenantCode);
        return url;
    }

    public getResourcesUrl(requester: LocalizationRequester, configService: IConfigService<IWebConfig>, tenantCode: string | null): string {
        let partialUrl = `localizations/resources?culture={{lng}}&requester=${requester}`;

        let url: string = this.urlBuilderHelper.BuildUrl(partialUrl, configService, tenantCode);
        return url;
    }


}
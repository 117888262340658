import { Component, OnInit, Input, Inject } from '@angular/core';
import { StringHelper } from 'projects/web-user-profile/src/app/helpers/string-helper';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ResourceLastAccessConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/resource-last-access-configuration';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { UserModel, ReportProfileBaseModel, BaseProviderInstanceResourceModel, AlertInvalidStateFeaturedModel, ReportProfileResourceBase, OneDriveReportProfile, SharePointReportProfile, ProviderInstanceResourceSummaryModel, Constants } from '@i2a/web-api-client';
import { ProviderHelper } from 'projects/web-user-profile/src/app/helpers/provider-helper';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';
import { SharePointResourceHasSensitivityLabelConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/share-point-resource-has-sensitivity-label-configuration';
import { WarningHelper } from 'projects/web-user-profile/src/app/helpers/warning-helper';
import { WarningInfoType } from 'projects/web-user-profile/src/app/models/warnings/warning-info-type';
import { SharePointPermissionComponent } from '../share-point-permission/share-point-permission.component';
import { faCloudSleet } from '@fortawesome/pro-light-svg-icons';

@RegisterMyProfileComponent(TemplateConstants.MODULE_SHAREPOINT_RESOURCE_HAS_SENSITIVITY_LABEL)
@Component({
  selector: 'i2a-share-point-resource-has-sensitivity-label',
  templateUrl: './share-point-resource-has-sensitivity-label.component.html',
  styleUrls: ['./share-point-resource-has-sensitivity-label.component.scss']
})
export class SharePointResourceHasSensitivityLabelComponent extends ProfileBaseClassComponent<SharePointResourceHasSensitivityLabelConfiguration,SharePointReportProfile | OneDriveReportProfile> implements OnInit, IModule<ResourceLastAccessConfiguration> {
  private resource: BaseProviderInstanceResourceModel;

  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, null);
  }
  
  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: SharePointResourceHasSensitivityLabelConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.resource = resource;
    this.resourceProfile = <SharePointReportProfile | OneDriveReportProfile>ProviderHelper.GetResource(profile, resource);
    this.id = `${this.componentId}-${this.resource.providerTypeId}-${this.resource.providerInstanceResourceId}-SensitivityLabel`;
    this.setHasWarning();
  }

  private setHasWarning(): void {
    if (this.configuration.warnSensitivityLabel && (<SharePointReportProfile | OneDriveReportProfile>this.resourceProfile).hasSensitivityLabel) {
      let warningInfoType = WarningInfoType.sensitivityLabel;
      this.warningLevel = WarningHelper.GetWarningLevel(warningInfoType);
      var providerTypeLabel;
      if (this.resourceProfile.providerInstanceResource.providerTypeId == Constants.PROVIDER_TYPE_INTRANET) {
        providerTypeLabel = ProviderHelper.GetSharePointProviderTypeLabel(<SharePointReportProfile>this.resourceProfile, false)
      } else {
        providerTypeLabel = ProviderHelper.GetProviderTypeLabel(this.resourceProfile.providerInstanceResource.providerId, this.resourceProfile.providerInstanceResource.providerTypeId);
      }
      this.hasWarnings([{
        id: this.id,
        resourceId: 'application' + this.resource.providerTypeId.toLocaleLowerCase() + this.resource.providerInstanceResourceId.toLocaleLowerCase(),
        resourceProfile: this.resourceProfile,
        warningInfoType: warningInfoType,
        warningLevel: this.warningLevel,
        label: `${this.i18Next.t(ProviderHelper.GetProviderTypeLabel(this.resource.providerId, this.resource.providerTypeId))} > ${(<ProviderInstanceResourceSummaryModel>this.resource).name}`,
        hasAlert: this.hasAlert,
        hasAlertRecentlyClosed: this.hasAlertRecentlyClosed,
        warningInfo: SharePointPermissionComponent.GetWarningInfoTypeLabelByType(warningInfoType),
        viewMoreDetails: false,
        viewDissmiss: false,
        viewResource: true
      }]);
    }
  }
  ngOnInit() {
  }

}

<!-- PERMISSIONS -->
<div class="module">
  <div class="container-fluid module-container"
    *ngIf="delegates != null && delegates.length > 0 || externalDelegates != null && externalDelegates.length > 0">
    <div class="row no-gutters">
      <i2a-sub-title class="col-12" [subTitle]=" titleKey | i18next " [description]=" descriptionKey | i18next "></i2a-sub-title>
    </div>
    <div class="row no-gutters">

        <!-- EXTERNAL DELEGATES -->
        <div *ngIf="externalDelegates?.length > 0" class="col-sm-12 col-md-6 col-lg-4 align-items-start">
            
          <i2a-share-point-external-permissions [resourceProfile]="resourceProfile" [componentId]="componentId" [isAlert]="isAlert"
            [providerInstanceResource]="resourceProfile.providerInstanceResource" [profile]="profile" [user]="user"
            [delegatesWithWarnings]="externalDelegates" (onAlertSent)="alertSent($event)" [alerts]="alerts" (onHasWarnings)="hasWarnings($event)"
            [warnSensitivityLabel]="configuration.warnSensitivityLabel">
          </i2a-share-point-external-permissions>

        </div>

        <div *ngFor="let permissions of delegates| slice:0:maxShowMore" class="col-sm-12 col-md-6 col-lg-4 align-items-start">   

          <i2a-share-point-permission [resourceProfile]="resourceProfile" [componentId]="componentId" [isAlert]="isAlert" [alerts]="alerts"
            [providerInstanceResource]="resourceProfile.providerInstanceResource" [profile]="profile" [user]="user"
            [permissionsWithWarnings]="permissions" (onAlertSent)="alertSent($event)" (onHasWarnings)="hasWarnings($event)" [warnSensitivityLabel]="configuration.warnSensitivityLabel"> 
          </i2a-share-point-permission>

        </div>

        <div *ngIf="isVisibleButtonsShowMore" class="col-12 p-2 text-center">
          <button class="btn btn-outline-primary" (click)="onShowMore()" *ngIf="!showMore">{{ 'button.showMore' | i18next }}</button>
          <button class="btn btn-outline-primary" (click)="onShowMore()" *ngIf="showMore">{{ 'button.showLess' | i18next }}</button>
        </div>

    </div>
  </div>
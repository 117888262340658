import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'i2a-file-share-permission-legend',
  templateUrl: './file-share-permission-legend.component.html',
  styleUrls: ['./file-share-permission-legend.component.scss']
})
export class FileSharePermissionLegendComponent implements OnInit {

  @Input() displayReadLegend: boolean;
  @Input() displayWriteLegend: boolean;
  @Input() displayExecuteLegend: boolean;
  @Input() displayModifyLegend: boolean;
  @Input() displayFullControlLegend: boolean;
  
  constructor() { }

  ngOnInit() {
  }

}

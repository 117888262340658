import { BaseProviderInstanceResourceModel } from './base-provider-instance-resource-model';
import { DeviceMappedModel } from './device-mapped-model';
import { GeoPositionModel } from './geo-position-model';
import { KeyValuePair } from '../../models/key-value-pair';

export class ResourceActivityModel extends BaseProviderInstanceResourceModel {
  isRelevant: boolean;
  isSucceeded: boolean;
  accessMode: string;
  logonUserName: string;
  logonUserId: string;
  accessKind: number;
  loggedUserId: string|null;
  loggedInstanceUserId: string|null;
  ownerUserId: string|null;
  device: DeviceMappedModel;
  clientIP: string;
  isClientIPRelevant: boolean;
  clientIPOrigin: string;
  clientIPPosition: GeoPositionModel;
  alertId: string|null;
  advancedProperties: KeyValuePair[];
  count: number;
  lastActionDate: Date;
}

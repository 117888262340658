import { Criticality } from './criticality';
import { KeyValuePair } from '../../models/key-value-pair';
import { RuleInheritance } from './rule-inheritance';

export class RuleUpdateModel {
  name: string;
  criticality: Criticality|null;
  endDate: Date|null;
  conditions: { [key: string]: string };
  exceptions: { [key: string]: string };
  inheritance: RuleInheritance|null;
  isRebuildable: boolean|null;
  rebuildAffectedElements: boolean|null;
  annotations: KeyValuePair[];
  isTechnical: boolean;
  isInTestMode: boolean;
}

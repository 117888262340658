import { SharePointReportProfileDelegate } from '../../../microsoft/sharepoint/profile/share-point-report-profile-delegate';
import { SharePointReportProfilePermissionDetail } from '../../../microsoft/sharepoint/profile/share-point-report-profile-permission-detail';

export class SharePointReportProfilePermissions extends SharePointReportProfileDelegate {
  isTheWholeCompany: boolean;
  isGroup: boolean;
  driveItems: { [key: string]: SharePointReportProfilePermissionDetail };
  isSPGroup: boolean;
  isO365Group: boolean;
  isUnknown: boolean;
  hasSensitivityLabel: boolean;
  hasPassword: boolean;
  expirationDate: Date|null;
}

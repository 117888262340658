<i2a-profile-card [title]="title" [viewMoreDetails]="viewMoreDetails" [viewMoreDetailsKey]="viewMoreDetailsKey"
  (onViewMoreDetails)="openDetails($event)" [subTitle]="subTitle" (onWarnClick)="warnClick($event)"
  [hasAlert]="hasAlert" [hasAlertRecentlyClosed]="hasAlertRecentlyClosed" [warningLevel]="warningLevel"
  [buttonOpenAlertModalKey]="buttonOpenAlertModalKey" [viewWarnButton]="viewWarnButton">

  <ng-container picto>
    <i2a-delegate-photo [isAnonymous]="delegate.isAnonymous" [isDefault]="delegate.isTheWholeCompany"
      [isApplication]="delegate.isApplication" [isExternalUser]="delegate.isExternalUser"
      [pir]="delegate.providerInstanceResource" [piu]="delegate.providerInstanceUser" [userId]="delegate.userId">
    </i2a-delegate-photo>
  </ng-container>

  <ng-container content>
    <div class="container p-0">
      <div class="row no-gutters">
        <div class="col ml-2 mr-2 mt-2">

          <ng-container *ngIf="delegate.folders.length > 0">
            <span *ngIf="displayAccessTitle">{{ "profile.accesses" | i18next }}</span>
            <table class="table table-sm id-table">
              <tbody>
                <tr *ngFor="let folder of baseFolders">
                  <td scope="row" class="text-break">
                    {{folder}}
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>
          <ng-container *ngIf="delegate.driveItems != null">
            <p>{{ maxPermission }}</p>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

</i2a-profile-card>

<ng-template #viewDetailTemplate>
  <i2a-modal-profile-header-title>
    <ng-container picto>
      <i2a-delegate-photo [isAnonymous]="delegate.isAnonymous" [isDefault]="delegate.isTheWholeCompany"
        [isApplication]="delegate.isApplication" [isExternalUser]="delegate.isExternalUser"
        [pir]="delegate.providerInstanceResource" [piu]="delegate.providerInstanceUser" [userId]="delegate.userId">
      </i2a-delegate-photo>
    </ng-container>
    <ng-container title>
      <span [title]="title">{{title}}</span>
    </ng-container>
  </i2a-modal-profile-header-title>

  <span>{{ "profile.accesses" | i18next }}</span>
  <table class="table table-sm table-bordered id-table">
    <tbody>
      <tr *ngFor="let folder of delegate.folders">
        <td scope="row" class="text-break">
          {{folder}}
        </td>
      </tr>
    </tbody>
  </table>
  <ng-container *ngIf="delegate.driveItems != null">
    <span>{{ "profile.permissions" | i18next }}</span>
    <i2a-share-point-permission-detail [driveItems]="delegate.driveItems"></i2a-share-point-permission-detail>
  </ng-container>
</ng-template>

<table class="table table-sm id-table legend">
  <tbody>
    <tr>
      <td scope="row" class="text-nowrap" *ngIf="displayReadLegend" >
        <span class="share-point-right" [title]="'fileShareRight.read' | i18next ">
          R
        </span>
        {{'fileShareRight.Read' | i18next }}
      </td>
      <td scope="row" class="text-nowrap" *ngIf="displayWriteLegend" >

        <span class="share-point-right" [title]="'fileShareRight.write' | i18next ">
          W
        </span>
        {{'fileShareRight.write' | i18next }}
      </td>
    </tr>
    <tr>
      <td scope="row" class="text-nowrap" *ngIf="displayExecuteLegend" >
        <span class="share-point-right" [title]="'fileShareRight.execute' | i18next ">
          E
        </span>
        {{'fileShareRight.execute' | i18next }}
      </td>
      <td scope="row" class="text-nowrap" *ngIf="displayModifyLegend" >

        <span class="share-point-right" [title]="'fileShareRight.modify' | i18next ">
          M
        </span>
        {{'fileShareRight.modify' | i18next }}
      </td>
    </tr>
    <tr>
      <td scope="row" class="text-nowrap" *ngIf="displayFullControlLegend" >
        <span class="share-point-right" [title]="'fileShareRight.fullControl' | i18next ">
          F
        </span>
        {{'fileShareRight.fullControl' | i18next }}
      </td>
      <td scope="row" class="text-nowrap"  >

      </td>
    </tr>
  </tbody>
</table>
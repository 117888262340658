
export enum ProviderAuditResourceKind {
  NULL = 1,
  Unknown = 2,
  Technical = 4,
  Trash = 8,
  Content = 16,
  ShareableContent = 32,
  Inbox = 512,
  Outbox = 1024,
  Calendar = 2048,
  Contact = 4096,
  InstantMessagingContent = 8192
}

import { Criticality } from './criticality';
import { KeyValuePair } from '../../models/key-value-pair';
import { RuleDomain } from './rule-domain';
import { RuleGroup } from './rule-group';
import { RuleInheritance } from './rule-inheritance';
import { RuleStatus } from './rule-status';

export class RuleSummaryModel {
  id: string;
  userId: string|null;
  orgUnitId: string|null;
  domain: RuleDomain;
  providerId: string|null;
  providerTypeId: string|null;
  providerInstanceId: string|null;
  providerInstanceResourceId: string|null;
  name: string;
  group: RuleGroup;
  criticality: Criticality|null;
  inheritance: RuleInheritance|null;
  status: RuleStatus;
  startDate: Date;
  endDate: Date|null;
  humanExpression: string;
  modificationUserId: string|null;
  isTechnical: boolean;
  annotations: KeyValuePair[];
}

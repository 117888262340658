<div class="sub-header">
    <div class="container" *ngIf="providerInstances && providerInstances.length > 1 && defaultInstances && defaultInstances.length == 0">
        <div class="row">
            <ul class="navigation">
                <!-- <li><a href="1-home.html"><i class="far fa-home"></i><span>Home</span></a></li> -->
                <li [ngClass]="{active: providerInstanceId == providerInstance.providerInstanceId}" *ngFor="let providerInstance of providerInstances">
                    <a [routerLink]="['/tenants', tenantCode, 'user', userId, 'profile', providerInstance.providerId, providerInstance.providerInstanceId]">
                        <img *ngIf="isMicrosoft(providerInstance)" src="assets/pictos/providers/o365.svg">
                        <img *ngIf="isZimbra(providerInstance)" src="assets/pictos/providers/Zimbra.svg">
                        <span>{{providerInstance.name}}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
import { Component, OnInit, Input, AfterViewInit, ViewChild, ViewContainerRef, Inject } from '@angular/core';
import { ContainerDirective } from '../../../../directives/container-directive';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StringHelper } from '../../../../helpers/string-helper';
import { Constants, ReportProfileResourceBase } from '@i2a/web-api-client';
import { ProviderHelper } from 'projects/web-user-profile/src/app/helpers/provider-helper';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss']
})
export class InformationModalComponent implements OnInit, AfterViewInit {
  @Input() public title: string;
  @Input() bodyTemplate;
  @Input() buttonsTemplate;
  @Input() public ok: string;
  @Input() public buttonOkClass: string;
  @Input() public activateFullScreen: boolean
  @Input() public displayWarnClick: boolean = true;
  @Input() public buttonOpenAlertModalKey: string;
  @Input() public resourceProfile: ReportProfileResourceBase<any>;

  @Input() public headerTitle: string;

  public btnOKClasses: string;

  @ViewChild(ContainerDirective, { static: true }) public bodyContainer: ContainerDirective;

  @ViewChild('buttons', { static: true, read: ContainerDirective }) public buttonsContainer: ContainerDirective;
  public providerTypeLabel: string;
  public providerPhotoUrl: string;

  constructor(
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService,
    public modal: NgbActiveModal,
    private viewContainerRef: ViewContainerRef,
    private session: SessionService
  ) { 
    this.buttonOpenAlertModalKey = 'button.openAlertModal';
  }

  ngOnInit() {
    this.btnOKClasses = 'btn ml-2 ';
    if (!StringHelper.isNullOrWhitespace(this.buttonOkClass)) {
      this.btnOKClasses = this.btnOKClasses + this.buttonOkClass
    } else
    {
      this.btnOKClasses = this.btnOKClasses + 'btn-dark';
    }
    if (this.bodyTemplate && this.bodyContainer) {
      this.bodyContainer.viewContainerRef.createEmbeddedView(this.bodyTemplate);
    }
    if (this.buttonsTemplate && this.buttonsContainer) {
      this.buttonsContainer.viewContainerRef.createEmbeddedView(this.buttonsTemplate);
    }    
    
    if (this.resourceProfile) {
      if (this.headerTitle) {
        this.providerTypeLabel = this.headerTitle;
      } else {
        if (this.resourceProfile.providerInstanceResource.providerId === Constants.PROVIDER_ID_MICROSOFT && this.resourceProfile.providerInstanceResource.providerTypeId === Constants.PROVIDER_TYPE_INTRANET) {
          this.providerTypeLabel = `${this.i18Next.t(ProviderHelper.GetProviderTypeLabel(this.resourceProfile.providerInstanceResource.providerId, this.resourceProfile.providerInstanceResource.providerTypeId))} ${this.resourceProfile.providerInstanceResource.name}`;
        } else {
          this.providerTypeLabel = this.i18Next.t(ProviderHelper.GetProviderTypeLabel(this.resourceProfile.providerInstanceResource.providerId, this.resourceProfile.providerInstanceResource.providerTypeId));
        }
      }
      this.providerPhotoUrl = ProviderHelper.GetProviderImage(this.resourceProfile.providerInstanceResource.providerId, this.resourceProfile.providerInstanceResource.providerTypeId, this.resourceProfile);
    }
  }

  ngAfterViewInit() {
  }

}

import { ExchangeReportProfilePermissions } from '../../../microsoft/exchange/profile/exchange-report-profile-permissions';
import { ReportProfileAlertResourceBaseModel } from '../../../profile/report-profile-alert-resource-base-model';

export class ExchangeReportProfileAlertPermissionsModel extends ReportProfileAlertResourceBaseModel {
  permissions: ExchangeReportProfilePermissions;
  hasFullAccessRightsIsValid: boolean;
  hasSendAsRightsIsValid: boolean;
  hasCalendarRightsIsValid: boolean;
  hasFoldersRightsIsValid: boolean;
  hasOtherRightsIsValid: boolean;
  invalidFolders: string[];
  invalidCalendars: string[];
}

<i2a-profile-alert-base-modal
  [manualDescriptionKey]="'applyCorrection.providers.zimbra.mailing.permission.manual.description'"
  [manualLinkKey]="'applyCorrection.providers.zimbra.mailing.permission.manual.link'"
  [automaticDescriptionKey]="'applyCorrection.providers.zimbra.mailing.permission.automatic.description'"
  [ignoreCommentToSend]="session.IgnoreCommentToSend" [displayComment]="session.DisplayComment"
  [validation]="validation" [canSend]="canSend" [modalTitle]="'profile.confirmationOfInformationTitle' | i18next "
  [resourceProfile]="resourceProfile">

  <ng-container header-picto>
    <i2a-resource-photo [providerInstanceResource]="permissions.providerInstanceResource" [size]="50">
    </i2a-resource-photo>
  </ng-container>

  <ng-container header-title>
    {{title}}<br>
  </ng-container>

  <ng-container body>
    <div class="container-fluid">
      <div class="row pt-3" *ngIf="permissions">

        <div class="form-group col-6 col-sm-4" *ngIf="permissions.hasSendAsRights">
          <div class="permission">
            <div class="permission-type">
              {{"profile.provider.zimbra.mailing.rights.sendAs" | i18next }}
            </div>
            <div class="permission-checkbox ml-1">
              <i2a-input-checkbox [(model)]="validation.hasSendAsRightsIsValid"
                [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="false"
                [valueUnChecked]="true" (modelChange)="setCanSend()"> </i2a-input-checkbox>
            </div>
          </div>
        </div>

        <div class="form-group col-6 col-sm-4" *ngIf="permissions.hasSendOnBehalfRights">
          <div class="permission">
            <div class="permission-type">
              {{"profile.provider.zimbra.mailing.rights.sendOnBehalf" | i18next }}
            </div>
            <div class="permission-checkbox ml-1">
              <i2a-input-checkbox [(model)]="validation.hasSendOnBehalfRightsIsValid"
                [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="false"
                [valueUnChecked]="true" (modelChange)="setCanSend()"> </i2a-input-checkbox>
            </div>
          </div>
        </div>

        <div class="form-group col-6 col-sm-4" *ngIf="permissions.hasContactsRights">
          <div class="permission">
            <div class="permission-type">
              {{"profile.provider.zimbra.mailing.rights.contacts" | i18next }}
            </div>
            <div class="permission-checkbox ml-1">
              <i2a-input-checkbox [(model)]="validation.hasContactsIsValid"
                [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="false"
                [valueUnChecked]="true" (modelChange)="setCanSend()"> </i2a-input-checkbox>
            </div>
          </div>
        </div>


        <div class="form-group col-6 col-sm-4" *ngIf="permissions.hasCalendarRights">
          <div class="permission">
            <div class="permission-type">
              {{"profile.provider.zimbra.mailing.rights.calendar" | i18next }}
            </div>
            <div class="permission-checkbox ml-1">
              <i2a-input-checkbox [(model)]="validation.hasCalendarRightsIsValid"
                [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="false"
                [valueUnChecked]="true" (modelChange)="setCanSend()"> </i2a-input-checkbox>
            </div>
          </div>
        </div>

        <div class="form-group col-6 col-sm-4" *ngIf="permissions.hasFoldersRights">
          <div class="permission">
            <div class="permission-type">
              {{"profile.provider.zimbra.mailing.rights.folders" | i18next }}
            </div>
            <div class="permission-checkbox ml-1">
              <i2a-input-checkbox [(model)]="validation.hasFoldersRightsIsValid"
                [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="false"
                [valueUnChecked]="true" (modelChange)="setCanSend()"> </i2a-input-checkbox>
            </div>
          </div>
        </div>

        <div class="form-group col-6 col-sm-4" *ngIf="permissions.hasInboxRights">
          <div class="permission">
            <div class="permission-type">
              {{"profile.provider.zimbra.mailing.rights.inbox" | i18next }}
            </div>
            <div class="permission-checkbox ml-1">
              <i2a-input-checkbox [(model)]="validation.hasInboxRightsIsValid" [classNameChecked]="'btn-outline-danger'"
                [className]="'btn-outline-dark'" [valueChecked]="false" [valueUnChecked]="true"
                (modelChange)="setCanSend()"> </i2a-input-checkbox>
            </div>
          </div>
        </div>


      </div>
    </div>
  </ng-container>

</i2a-profile-alert-base-modal>
import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { SharePointReportProfilePermissions, SharePointReportProfileAlertPermissionsModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-share-point-permission-modal',
  templateUrl: './share-point-permission-modal.component.html',
  styleUrls: ['./share-point-permission-modal.component.scss']
})
export class SharePointPermissionModalComponent implements OnInit {
  @Input() public permissions: SharePointReportProfilePermissions;
  @Input() public resourceProfile: ReportProfileResourceBase<any>;

  public validation: SharePointReportProfileAlertPermissionsModel;
  public canSend: boolean;
  public title: string;
  public driveItemsPermissions: any[];//TODO CRADO
  public hasSpecificLink: boolean = false;
  public informationKey: string;

  constructor(
    private modalService: NgbModal,
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService,
    public session: SessionService) {
    this.canSend = false;
  }

  ngOnInit() {
    this.validation = new SharePointReportProfileAlertPermissionsModel();
    this.driveItemsPermissions = [];

    this.informationKey = Object.keys(this.permissions.driveItems).length > 1 ? 'modal.reportAnomaly.checkItems' : 'modal.reportAnomaly.checkItem';

    Object.keys(this.permissions.driveItems).forEach(path => {
      this.driveItemsPermissions.push({
        "path": path,
        "hasSensitivityLabel": this.permissions.driveItems[path].hasSensitivityLabel,
        "sensitivityLabel": this.permissions.driveItems[path].sensitivityLabel,
        "roles": this.permissions.driveItems[path].rights,
        "checked": false
      });
    });

    this.validation.invalidPaths = [];
    this.validation.allPaths = this.driveItemsPermissions.length > 0;

    if (this.permissions) {
      if (!this.permissions.isNameKey) {
        this.title = this.permissions.name;
      }
      else {
        this.title = this.i18Next.t(this.permissions.name);
      }
    }
    this.setCanSend();
  }

  private setCanSend() {
    setTimeout(() => {
      this.canSend = this.validation.invalidPaths.length > 0 || this.validation.allPaths;

      this.hasSpecificLink = false;
      if (this.validation.allPaths) {
        var perms = Object.values(this.permissions.driveItems);
        this.hasSpecificLink = perms.findIndex(p => p.isSpecificLink) >= 0;
      } else if (this.validation.invalidPaths.length > 0) {
        for (let pathIndex = 0; pathIndex < this.validation.invalidPaths.length; pathIndex++) {
          const path = this.validation.invalidPaths[pathIndex];
          var perm = this.permissions.driveItems[path];
          this.hasSpecificLink = perm.isSpecificLink;
          if (this.hasSpecificLink) {
            break;
          }
        }
      }
    });
  }
}

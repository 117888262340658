<div class="collapse-container">
  <div class="id-button btn btn-light" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
    aria-controls="collapseDetails">

    <ng-container *ngIf="isCollapsed">
      <span>{{ collapseText }}</span>
    </ng-container>

    <ng-container *ngIf="!isCollapsed">
      <span>{{ expandText }}</span>
    </ng-container>
  </div>

  <div id="collapseDetails" [ngbCollapse]="isCollapsed" class="pt-2">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
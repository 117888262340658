import { Component, OnInit, Input } from '@angular/core';
import { Criticality } from '@i2a/web-api-client';

@Component({
  selector: 'i2a-criticality-to-icon',
  templateUrl: './criticality-to-icon.component.html',
  styleUrls: ['./criticality-to-icon.component.scss']
})
export class CriticalityToIconComponent implements OnInit {

  public CriticalityEnum = Criticality;
  @Input() public criticality: Criticality;

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, Input, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'i2a-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss']
})
export class BaseModalComponent implements OnInit {
  @Input() public modalTitle: string;
  @Input() public activateFullScreen: boolean;
  @Input() public isFullScreen: boolean;
  @Input() public noOverflow: boolean = false;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.isFullScreen) {
      event.preventDefault();
      this.toggleFullScreen();
    }
  }
  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen
  }

  constructor(
    public activeModal: NgbActiveModal
  ) { 
    this.activateFullScreen = false;
    this.isFullScreen = false;
  }

  ngOnInit() {
  }

}

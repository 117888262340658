import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { ExchangeReportProfilePermissions, ExchangeReportProfileAlertPermissionsModel, ProviderAuditResourceKind, ReportProfileResourceBase } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { EnumHelper } from 'projects/web-user-profile/src/app/helpers/enum-helper';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-exchange-permission-modal',
  templateUrl: './exchange-permission-modal.component.html',
  styleUrls: ['./exchange-permission-modal.component.scss']
})
export class ExchangePermissionModalComponent implements OnInit {
  @Input() public permissions: ExchangeReportProfilePermissions;

  @Input() public resourceProfile: ReportProfileResourceBase<any>;


  public validation: ExchangeReportProfileAlertPermissionsModel;
  public canSend: boolean;
  public title: string;

  constructor(
    private modalService: NgbModal,
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService,
    public session: SessionService) {
    this.canSend = false;
  }

  ngOnInit() {
    this.validation = new ExchangeReportProfileAlertPermissionsModel();
    this.validation.invalidCalendars = [];
    this.validation.hasCalendarRightsIsValid = this.permissions.hasFoldersRights ||
      this.permissions.hasFullAccessRights ||
      this.permissions.hasSendAsRights ||
      this.permissions.hasOtherRights;
      Object.keys(this.permissions.foldersRights).forEach(path => {
        if (EnumHelper.HasFlag(this.permissions.foldersRights[path], ProviderAuditResourceKind.Calendar)) {
          this.validation.invalidCalendars.push(this.permissions.foldersIdentity[path]);
        }
      })

    this.validation.hasFoldersRightsIsValid = this.permissions.hasCalendarRights ||
      this.permissions.hasFullAccessRights ||
      this.permissions.hasSendAsRights ||
      this.permissions.hasOtherRights;

    this.validation.invalidFolders = [];
    Object.keys(this.permissions.foldersRights).forEach(path => {
      if (!EnumHelper.HasFlag(this.permissions.foldersRights[path], ProviderAuditResourceKind.Calendar)) {
        this.validation.invalidFolders.push(this.permissions.foldersIdentity[path]);
      }
    })

    this.validation.hasFullAccessRightsIsValid = this.permissions.hasFoldersRights ||
      this.permissions.hasCalendarRights ||
      this.permissions.hasSendAsRights ||
      this.permissions.hasOtherRights;

    this.validation.hasOtherRightsIsValid = this.permissions.hasFoldersRights ||
      this.permissions.hasFullAccessRights ||
      this.permissions.hasSendAsRights ||
      this.permissions.hasCalendarRights;

    this.validation.hasSendAsRightsIsValid = this.permissions.hasFoldersRights ||
      this.permissions.hasFullAccessRights ||
      this.permissions.hasCalendarRights ||
      this.permissions.hasOtherRights;

    if (this.permissions) {
      if (!this.permissions.isNameKey) {
        this.title = this.permissions.name;
      }
      else {
        this.title = this.i18Next.t(this.permissions.name);
      }
    }
    this.setCanSend();
  }



  private setCanSend() {
    setTimeout(() => {
      this.canSend = !this.validation.hasCalendarRightsIsValid ||
        !this.validation.hasFoldersRightsIsValid ||
        !this.validation.hasFullAccessRightsIsValid ||
        !this.validation.hasOtherRightsIsValid ||
        !this.validation.hasSendAsRightsIsValid;
    });
  }


}

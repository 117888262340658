/*
 * Public API Surface of web-api-client
 */


export * from './lib/data/datasource.service';
export * from './lib/data/localization-datasource.service';
export * from './lib/data/users-datasource-url.service'
export * from './lib/data/provider-instance-resources-datasource-url.service'
export * from './lib/data/provider-instance-users-datasource-url.service'

export * from './lib/web-api-client.module';
export * from './lib/models/constants';
export * from './lib/models/alert-constants';
export * from './lib/models/icon-constants';
export * from './lib/models/microsoft-constants';
export * from './lib/models/key-value-pair';
export * from './lib/models/role';

/** GENERATED CODE **/


//#region AuthController

export * from './lib/generated/models/oauth2-response'
export * from './lib/generated/models/change-password-model'
export * from './lib/generated/models/forgot-password-model'
export * from './lib/generated/models/reset-password-model'
export * from './lib/generated/models/connected-account-model'
export * from './lib/generated/models/local-user-authentication-create-model'
export * from './lib/generated/models/tenant-summary-model'
export * from './lib/generated/models/user-authentication-model'

export * from './lib/generated/data/auth-datasource.service'

//#endregion

//#region ProviderInstanceApplicationsController

export * from './lib/generated/models/base-model'
export * from './lib/generated/models/statistics-granularity'
export * from './lib/generated/models/device-type'
export * from './lib/generated/models/alert-reason-category'
export * from './lib/generated/models/alert-states-access-counters-model'
export * from './lib/generated/models/alert-states-base-counters-model'
export * from './lib/generated/models/alert-states-base-global-configuration-counters-model'
export * from './lib/generated/models/alert-states-configuration-counters-model'
export * from './lib/generated/models/alert-states-invalid-state-report-counters-model'
export * from './lib/generated/models/alert-states-model'
export * from './lib/generated/models/alert-states-reason-counters-model'
export * from './lib/generated/models/audit-collect-delay-model'
export * from './lib/generated/models/base-provider-instance-model'
export * from './lib/generated/models/base-provider-instance-resource-model'
export * from './lib/generated/models/collected-resource-base-counters-model'
export * from './lib/generated/models/device-mapped-model'
export * from './lib/generated/models/geo-position-model'
export * from './lib/generated/models/global-resources-state-model'
export * from './lib/generated/models/page-result-model'
export * from './lib/generated/models/page-result-offset-model'
export * from './lib/generated/models/provider-admin-object-history-summary-model'
export * from './lib/generated/models/provider-admin-object-model'
export * from './lib/generated/models/provider-audit-usage-statistics-model'
export * from './lib/generated/models/provider-instance-base-model'
export * from './lib/generated/models/provider-instance-global-resource-states-model'
export * from './lib/generated/models/provider-instance-statistics-model'
export * from './lib/generated/models/resource-activity-model'

export * from './lib/generated/data/provider-instance-applications-datasource.service'

//#endregion

//#region ProviderInstancesController

export * from './lib/generated/models/criticality'
export * from './lib/generated/models/alert-answer'
export * from './lib/generated/models/alert-answer-origin'
export * from './lib/generated/models/alert-comment-type'
export * from './lib/generated/models/alert-dismiss-explanation'
export * from './lib/generated/models/alert-comment-base-model'
export * from './lib/generated/models/alert-comment-model'
export * from './lib/generated/models/alert-invalid-state-featured-model'
export * from './lib/generated/models/alert-summary-model'
export * from './lib/generated/models/connected-user-model'
export * from './lib/generated/models/profile/report-profile-alert-base-model'
export * from './lib/generated/models/profile/report-profile-alert-delete-resource-model'
export * from './lib/generated/models/profile/report-profile-alert-resource-not-belong-to-me-model'
export * from './lib/generated/models/protection-alert-summary-model'
export * from './lib/generated/models/provider-admin-object-threat-reason-model'
export * from './lib/generated/models/provider-instance-model'
export * from './lib/generated/models/report-profile-alert-resources-base-model'
export * from './lib/generated/models/undertaken-action-model'
export * from './lib/generated/models/user-activity-model'

export * from './lib/generated/data/provider-instances-datasource.service'

//#endregion

//#region ProviderInstanceResourcesController

export * from './lib/generated/models/rule-domain'
export * from './lib/generated/models/rule-group'
export * from './lib/generated/models/rule-inheritance'
export * from './lib/generated/models/rule-status'
export * from './lib/generated/models/user-status'
export * from './lib/generated/models/provider-instance-resource-list-set-is-premium-model'
export * from './lib/generated/models/provider-instance-resource-model'
export * from './lib/generated/models/provider-instance-resource-summary-model'
export * from './lib/generated/models/register-device-model'
export * from './lib/generated/models/rule-create-model'
export * from './lib/generated/models/rule-create-model-with-user-id'
export * from './lib/generated/models/rule-summary-model'
export * from './lib/generated/models/user-model'
export * from './lib/generated/models/user-summary-model'

export * from './lib/generated/data/provider-instance-resources-datasource.service'

//#endregion

//#region ProviderController

export * from './lib/generated/models/provider-instance-couple-collected-model'

export * from './lib/generated/data/provider-datasource.service'

//#endregion

//#region UsersController

export * from './lib/generated/models/authentication-federation-type'
export * from './lib/generated/models/user-report-information'
export * from './lib/generated/models/address-model'
export * from './lib/generated/models/auth-session-info-model'
export * from './lib/generated/models/device-model'
export * from './lib/generated/models/federation-model'
export * from './lib/generated/models/ipaddress-range-model'
export * from './lib/generated/models/iprange-create-or-update-model'
export * from './lib/generated/models/iprange-model'
export * from './lib/generated/models/notification-push-registration-info-model'
export * from './lib/generated/models/notification-push-registration-model'
export * from './lib/generated/models/profile-country-create-model'
export * from './lib/generated/models/profile-country-delete-model'
export * from './lib/generated/models/profile-country-model'
export * from './lib/generated/models/profile-country-rebuild-model'
export * from './lib/generated/models/provider-instance-profile-country-model'
export * from './lib/generated/models/role-group-user-model'
export * from './lib/generated/models/rule-create-model-with-domain-model'
export * from './lib/generated/models/security-infos-model'
export * from './lib/generated/models/update-devie-model'
export * from './lib/generated/models/user-create-model'
export * from './lib/generated/models/user-detailed-model'
export * from './lib/generated/models/user-kind'
export * from './lib/generated/models/user-link-model'
export * from './lib/generated/models/user-link-type'
export * from './lib/generated/models/user-states-model'
export * from './lib/generated/models/user-update-model'

export * from './lib/generated/data/users-datasource.service'

//#endregion

//#region MicrosoftOfficeProfileProviderController

export * from './lib/generated/microsoft/models/o365/sharepoint/well-known-group-type'
export * from './lib/generated/models/base-provider-instance-user'
export * from './lib/generated/models/change-result'
export * from './lib/generated/models/change-result-item'
export * from './lib/generated/models/origin-level'
export * from './lib/generated/models/provider-audit-resource-kind'
export * from './lib/generated/models/audit-report-model'
export * from './lib/generated/models/base-provider-instance-user-model'
export * from './lib/generated/models/profile/microsoft-office-report-profile-alert-rules-computed-delegate-model'
export * from './lib/generated/models/profile/report-profile-access-mode-model'
export * from './lib/generated/models/profile/report-profile-alert-resource-base-model'
export * from './lib/generated/models/profile/report-profile-alert-rules-computed-delegate-model'
export * from './lib/generated/models/profile/report-profile-base-model'
export * from './lib/generated/models/profile/report-profile-delegate'
export * from './lib/generated/models/profile/report-profile-delegate-access'
export * from './lib/generated/models/profile/report-profile-device-model'
export * from './lib/generated/models/profile/report-profile-device-sync-model'
export * from './lib/generated/models/profile/report-profile-loaded-model'
export * from './lib/generated/models/profile/report-profile-origin-by-proder-type-model'
export * from './lib/generated/models/profile/report-profile-origin-model'
export * from './lib/generated/models/profile/report-profile-resource'
export * from './lib/generated/models/profile/report-profile-resource-base'
export * from './lib/generated/models/profile/report-profile-rules-computed-delegate'
export * from './lib/generated/models/profile/report-profile-user'
export * from './lib/generated/models/profile/report-profile-user-changes-model'
export * from './lib/generated/models/profile/report-profile-usual-countries-model'
export * from './lib/generated/models/profile/report-profile-validate-model'
export * from './lib/generated/models/profile/user-report-profile-alert-access-modes-model'
export * from './lib/generated/models/profile/user-report-profile-alert-devices-model'
export * from './lib/generated/models/profile/user-report-profile-alert-origins-model'
export * from './lib/generated/models/microsoft/azuread/profile/app-permission-type-model'
export * from './lib/generated/models/microsoft/azuread/profile/azure-adapp-permission-grant-model'
export * from './lib/generated/models/microsoft/azuread/profile/azure-adapp-permission-scope-model'
export * from './lib/generated/models/microsoft/azuread/profile/azure-adapp-permissions-model'
export * from './lib/generated/models/microsoft/azuread/profile/azure-adreport-profile'
export * from './lib/generated/models/microsoft/azuread/profile/azure-adreport-profile-alert-grants-model'
export * from './lib/generated/models/microsoft/azuread/profile/azure-adreport-profile-changes-model'
export * from './lib/generated/models/microsoft/azuread/profile/azure-adservice-principal-summary-model'
export * from './lib/generated/models/microsoft/azuread/profile/azure-adservice-principal-summary-with-scopes-model'
export * from './lib/generated/models/microsoft/azuread/profile/azure-aduser-summary-model'
export * from './lib/generated/models/microsoft/exchange/profile/exchange-report-profile'
export * from './lib/generated/models/microsoft/exchange/profile/exchange-report-profile-alert-permissions-model'
export * from './lib/generated/models/microsoft/exchange/profile/exchange-report-profile-changes-model'
export * from './lib/generated/models/microsoft/exchange/profile/exchange-report-profile-delegate'
export * from './lib/generated/models/microsoft/exchange/profile/exchange-report-profile-folder-right'
export * from './lib/generated/models/microsoft/exchange/profile/exchange-report-profile-inbox-rule'
export * from './lib/generated/models/microsoft/exchange/profile/exchange-report-profile-permissions'
export * from './lib/generated/models/microsoft/exchange/profile/exchange-report-profile-rules'
export * from './lib/generated/models/microsoft/exchange/profile/exchange-report-profile-rules-changes-model'
export * from './lib/generated/models/microsoft/fileshare/profile/file-share-report-profile'
export * from './lib/generated/models/microsoft/fileshare/profile/file-share-report-profile-alert-delegate-model'
export * from './lib/generated/models/microsoft/fileshare/profile/file-share-report-profile-alert-permissions-model'
export * from './lib/generated/models/microsoft/fileshare/profile/file-share-report-profile-changes-model'
export * from './lib/generated/models/microsoft/fileshare/profile/file-share-report-profile-delegate'
export * from './lib/generated/models/microsoft/fileshare/profile/file-share-report-profile-permission-rights'
export * from './lib/generated/models/microsoft/fileshare/profile/file-share-report-profile-permissions'
export * from './lib/generated/models/microsoft/onedrive/profile/one-drive-report-profile'
export * from './lib/generated/models/microsoft/onedrive/profile/one-drive-report-profile-alert-delegate-model'
export * from './lib/generated/models/microsoft/onedrive/profile/one-drive-report-profile-alert-external-delegates-model'
export * from './lib/generated/models/microsoft/onedrive/profile/one-drive-report-profile-changes-model'
export * from './lib/generated/models/microsoft/onedrive/profile/one-drive-report-profile-delegate'
export * from './lib/generated/models/microsoft/onedrive/profile/one-drive-report-profile-rules-computed-delegate'
export * from './lib/generated/models/microsoft/onedrive/profile/share-point-site-report-profile'
export * from './lib/generated/models/microsoft/profile/exchange-report-profile-alert-delegate-model'
export * from './lib/generated/models/microsoft/profile/exchange-report-profile-alert-forward-mailboxes-model'
export * from './lib/generated/models/microsoft/profile/exchange-report-profile-alert-inbox-rule-model'
export * from './lib/generated/models/microsoft/profile/microsoft-office-report-profile-model'
export * from './lib/generated/models/microsoft/profile/microsoft-report-profile-delegate'
export * from './lib/generated/models/microsoft/sharepoint/base-share-point-graph-group-model'
export * from './lib/generated/models/microsoft/sharepoint/profile/share-point-report-profile'
export * from './lib/generated/models/microsoft/sharepoint/profile/share-point-report-profile-alert-administrators-model'
export * from './lib/generated/models/microsoft/sharepoint/profile/share-point-report-profile-alert-delegate-model'
export * from './lib/generated/models/microsoft/sharepoint/profile/share-point-report-profile-alert-delete-resource-model'
export * from './lib/generated/models/microsoft/sharepoint/profile/share-point-report-profile-alert-external-delegates-model'
export * from './lib/generated/models/microsoft/sharepoint/profile/share-point-report-profile-alert-external-permissions-model'
export * from './lib/generated/models/microsoft/sharepoint/profile/share-point-report-profile-alert-group-model'
export * from './lib/generated/models/microsoft/sharepoint/profile/share-point-report-profile-alert-o365-group-model'
export * from './lib/generated/models/microsoft/sharepoint/profile/share-point-report-profile-alert-permissions-model'
export * from './lib/generated/models/microsoft/sharepoint/profile/share-point-report-profile-alert-visibility-model'
export * from './lib/generated/models/microsoft/sharepoint/profile/share-point-report-profile-changes-model'
export * from './lib/generated/models/microsoft/sharepoint/profile/share-point-report-profile-delegate'
export * from './lib/generated/models/microsoft/sharepoint/profile/share-point-report-profile-drive-item-role'
export * from './lib/generated/models/microsoft/sharepoint/profile/share-point-report-profile-permission-detail'
export * from './lib/generated/models/microsoft/sharepoint/profile/share-point-report-profile-permissions'
export * from './lib/generated/models/microsoft/sharepoint/share-point-graph-group-model'
export * from './lib/generated/models/microsoft/sharepoint/share-point-group-member-model'
export * from './lib/generated/models/microsoft/sharepoint/share-point-group-model'
export * from './lib/generated/models/microsoft/sharepoint/share-point-group-principal-model'
export * from './lib/generated/models/microsoft/sharepoint/share-point-user-model'
export * from './lib/generated/models/reports/profile/report-profile-resource-changes-base-model'

export * from './lib/generated/data/microsoft/microsoft-office-profile-provider-datasource.service'

//#endregion

//#region SharePointController

export * from './lib/generated/models/functional-exception-model'
export * from './lib/generated/models/page-result'
export * from './lib/generated/models/operation-status'
export * from './lib/generated/models/microsoft/sharepoint/drive-item-sharing-type'
export * from './lib/generated/models/microsoft/sharepoint/list-identifier'
export * from './lib/generated/orchestration/microsoft/reports/models/base-one-page-microsoft-model'
export * from './lib/generated/orchestration/microsoft/reports/models/microsoft-one-page-model'
export * from './lib/generated/orchestration/microsoft/sharepoint/reports/models/share-point-one-page-model'
export * from './lib/generated/orchestration/reports/models/onepage/one-page-model-base'
export * from './lib/generated/models/base-operation-model'
export * from './lib/generated/models/operation-audit-model'
export * from './lib/generated/models/operation-import-context-model'
export * from './lib/generated/models/operation-item-model'
export * from './lib/generated/models/operation-items-base-model'
export * from './lib/generated/models/operation-item-status'
export * from './lib/generated/models/microsoft/microsoft-office-import-users-model'
export * from './lib/generated/models/microsoft/microsoft-office-operation-audit-context-model'
export * from './lib/generated/models/microsoft/microsoft-office-operation-learning-protection-context-model'
export * from './lib/generated/models/microsoft/microsoft-office-operation-permanent-audit-context-model'
export * from './lib/generated/models/microsoft/microsoft-office-operation-protection-context-base-model'
export * from './lib/generated/models/microsoft/microsoft-office-operation-protection-context-model'
export * from './lib/generated/models/microsoft/microsoft-office-operation-stop-audit-context-model'
export * from './lib/generated/models/microsoft/microsoft-office-operation-stop-permanent-audit-context-model'
export * from './lib/generated/models/microsoft/microsoft-office-operation-update-protection-context-model'
export * from './lib/generated/models/microsoft/sharepoint/drive-item-permission-removal-detail-model'
export * from './lib/generated/models/microsoft/sharepoint/drive-item-permission-removal-model'
export * from './lib/generated/models/microsoft/sharepoint/drive-item-resync-model'
export * from './lib/generated/models/microsoft/sharepoint/list-summary-model'
export * from './lib/generated/models/microsoft/sharepoint/profile/share-point-report-profile-share'
export * from './lib/generated/models/microsoft/sharepoint/profile/share-point-report-profile-share-detail'
export * from './lib/generated/models/microsoft/sharepoint/profile/share-point-report-profile-shares'
export * from './lib/generated/models/microsoft/sharepoint/share-point-audit-resources-model'
export * from './lib/generated/models/microsoft/sharepoint/share-point-learning-protect-resources-model'
export * from './lib/generated/models/microsoft/sharepoint/share-point-permanent-audit-resources-model'
export * from './lib/generated/models/microsoft/sharepoint/share-point-protect-resources-model'
export * from './lib/generated/models/microsoft/sharepoint/share-point-resources-base-model'
export * from './lib/generated/models/microsoft/sharepoint/share-point-resource-update-model'
export * from './lib/generated/models/microsoft/sharepoint/share-point-site'
export * from './lib/generated/models/microsoft/sharepoint/share-point-site-information'
export * from './lib/generated/models/microsoft/sharepoint/share-point-stop-audit-resources-model'
export * from './lib/generated/models/microsoft/sharepoint/share-point-stop-permanent-audit-resources-model'
export * from './lib/generated/models/microsoft/sharepoint/share-point-un-protect-resources-model'
export * from './lib/generated/models/microsoft/sharepoint/share-point-update-protect-resources-model'
export * from './lib/generated/models/microsoft/sharepoint/site-summary-model'
export * from './lib/generated/models/microsoft/sharepoint/web-application-summary-model'
export * from './lib/generated/models/microsoft/sharepoint/web-summary-model'
export * from './lib/generated/models/provider-policies-model'

export * from './lib/generated/data/microsoft/share-point-datasource.service'

//#endregion

//#region FileShareController

export * from './lib/generated/serialization/object-serialization'
export * from './lib/generated/serialization/serialization-mode'
export * from './lib/generated/models/base-provider-instance'
export * from './lib/generated/models/microsoft/fileshare/datacollect/file-share-collected-object-create-entity'
export * from './lib/generated/models/provider-instance-application'
export * from './lib/generated/orchestration/microsoft/fileshare/reports/models/file-share-one-page-model'
export * from './lib/generated/models/microsoft/fileshare/file-share-create-entity'
export * from './lib/generated/models/microsoft/fileshare/file-share-discoveries-model'
export * from './lib/generated/models/microsoft/fileshare/file-share-discoveries-request-model'
export * from './lib/generated/models/microsoft/fileshare/file-share-discovery-range-model'
export * from './lib/generated/models/microsoft/fileshare/file-share-discovery-range-request-model'
export * from './lib/generated/models/microsoft/fileshare/file-share-item-resync-model'
export * from './lib/generated/models/microsoft/fileshare/file-share-mass-affectation-item-model'
export * from './lib/generated/models/microsoft/fileshare/file-share-mass-affectation-model'
export * from './lib/generated/models/microsoft/fileshare/file-share-update-entity'
export * from './lib/generated/models/microsoft/fileshare/file-share-worker-range-model'
export * from './lib/generated/models/microsoft/fileshare/file-share-worker-ranges-model'

export * from './lib/generated/data/microsoft/fileshare/file-share-datasource.service'

//#endregion

//#region OneDriveController

export * from './lib/generated/orchestration/microsoft/onedrive/reports/models/one-drive-one-page-model'
export * from './lib/generated/models/microsoft/microsoft-office-audit-resources-model'
export * from './lib/generated/models/microsoft/microsoft-office-learning-protect-resources-model'
export * from './lib/generated/models/microsoft/microsoft-office-operation-un-protection-context-model'
export * from './lib/generated/models/microsoft/microsoft-office-permanent-audit-resources-model'
export * from './lib/generated/models/microsoft/microsoft-office-protect-resources-model'
export * from './lib/generated/models/microsoft/microsoft-office-stop-audit-resources-model'
export * from './lib/generated/models/microsoft/microsoft-office-stop-permanent-audit-resources-model'
export * from './lib/generated/models/microsoft/microsoft-office-un-protect-resources-model'
export * from './lib/generated/models/microsoft/microsoft-office-update-protect-resources-model'

export * from './lib/generated/data/microsoft/one-drive-datasource.service'

//#endregion

//#region ZimbraProfileProviderController

export * from './lib/generated/models/profile/zimbra-report-profile-alert-rules-computed-delegate-model'
export * from './lib/generated/models/zimbra/mailing/profile/zimbra-mailing-report-profile'
export * from './lib/generated/models/zimbra/mailing/profile/zimbra-mailing-report-profile-alert-delegate-model'
export * from './lib/generated/models/zimbra/mailing/profile/zimbra-mailing-report-profile-alert-filter-rule-model'
export * from './lib/generated/models/zimbra/mailing/profile/zimbra-mailing-report-profile-alert-permissions-model'
export * from './lib/generated/models/zimbra/mailing/profile/zimbra-mailing-report-profile-changes-model'
export * from './lib/generated/models/zimbra/mailing/profile/zimbra-mailing-report-profile-delegate'
export * from './lib/generated/models/zimbra/mailing/profile/zimbra-mailing-report-profile-filter-rule'
export * from './lib/generated/models/zimbra/mailing/profile/zimbra-mailing-report-profile-permissions'
export * from './lib/generated/models/zimbra/zimbra-report-profile-model'

export * from './lib/generated/data/zimbra/zimbra-profile-provider-datasource.service'

//#endregion

//#region GuidedToursController

export * from './lib/generated/models/guided-tour-model'
export * from './lib/generated/models/guided-tour-stop-model'

export * from './lib/generated/data/guided-tours-datasource.service'

//#endregion

//#region RulesController

export * from './lib/generated/models/access-evaluated-actual-rules-model'
export * from './lib/generated/models/rule-evaluation-result-model'
export * from './lib/generated/models/rule-human-expression'
export * from './lib/generated/models/rule-management-access-operation-model'
export * from './lib/generated/models/rule-management-usage-operation-model'
export * from './lib/generated/models/rule-model'
export * from './lib/generated/models/rule-predicate-configuration-model'
export * from './lib/generated/models/rule-predicate-evaluation-result-model'
export * from './lib/generated/models/rule-predicates-human-expression'
export * from './lib/generated/models/rules-template-model'
export * from './lib/generated/models/rule-update-model'

export * from './lib/generated/data/rules-datasource.service'

//#endregion

import { AlertInvalidStateFeaturedModel } from '../../models/alert-invalid-state-featured-model';
import { ReportProfileLoadedModel } from '../../models/profile/report-profile-loaded-model';
import { ReportProfileValidateModel } from '../../models/profile/report-profile-validate-model';
import { UserReportProfileAlertAccessModesModel } from '../../models/profile/user-report-profile-alert-access-modes-model';
import { UserReportProfileAlertDevicesModel } from '../../models/profile/user-report-profile-alert-devices-model';
import { UserReportProfileAlertOriginsModel } from '../../models/profile/user-report-profile-alert-origins-model';
import { ZimbraMailingReportProfileAlertDelegateModel } from '../../models/zimbra/mailing/profile/zimbra-mailing-report-profile-alert-delegate-model';
import { ZimbraMailingReportProfileAlertFilterRuleModel } from '../../models/zimbra/mailing/profile/zimbra-mailing-report-profile-alert-filter-rule-model';
import { ZimbraMailingReportProfileAlertPermissionsModel } from '../../models/zimbra/mailing/profile/zimbra-mailing-report-profile-alert-permissions-model';
import { ZimbraReportProfileAlertRulesComputedDelegateModel } from '../../models/profile/zimbra-report-profile-alert-rules-computed-delegate-model';
import { ZimbraReportProfileModel } from '../../models/zimbra/zimbra-report-profile-model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatasourceService } from '../../../data/datasource.service';

@Injectable({
	providedIn: 'root'
})
export class ZimbraProfileProviderDatasourceService {

private route: string = "providers/zimbra";

	constructor(private datasource: DatasourceService) {
		console.log("Init ZimbraProfileProviderDatasourceService");
	}

	public loadProfile(providerInstanceId: string, userId: string, useCache: boolean = false, throwError: boolean = true): Observable<ZimbraReportProfileModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}`;

		return this.datasource.get<ZimbraReportProfileModel>(partialUrl, { }, useCache, throwError);
	}

	public profileLoaded(providerInstanceId: string, userId: string, model: ReportProfileLoadedModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/loaded`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public validateProfile(providerInstanceId: string, userId: string, model: ReportProfileValidateModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/validate`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertUserAccessModes(providerInstanceId: string, userId: string, model: UserReportProfileAlertAccessModesModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/access-modes`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertUserOrigins(providerInstanceId: string, userId: string, model: UserReportProfileAlertOriginsModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel[]> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/origins`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertUserDevices(providerInstanceId: string, userId: string, model: UserReportProfileAlertDevicesModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel[]> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/devices`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertRulesComputedDelegate(providerInstanceId: string, userId: string, model: ZimbraReportProfileAlertRulesComputedDelegateModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/rules-computed-delegate`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertMailingDelegate(providerInstanceId: string, userId: string, model: ZimbraMailingReportProfileAlertDelegateModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel[]> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/mailing/delegate`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertMailingPermissions(providerInstanceId: string, userId: string, model: ZimbraMailingReportProfileAlertPermissionsModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/mailing/permissions`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public alertZimbraMailingFilterRule(providerInstanceId: string, userId: string, model: ZimbraMailingReportProfileAlertFilterRuleModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/profile/${userId}/alert/mailing/filter-rule`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

}

import { Injectable, Inject } from '@angular/core';
import { StringHelper } from './string-helper';
import { BaseProviderInstanceResourceModel, Constants, MicrosoftOfficeReportProfileModel, ReportProfileBaseModel, ReportProfileDelegate, ReportProfileResourceBase, ZimbraReportProfileModel } from '@i2a/web-api-client';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';

@Injectable({
    providedIn: 'root'
})
export class ProfileHelper {

    public static names: { [key: string]: string; };

    constructor() {
    }

    public static compare(a: ReportProfileDelegate, b: ReportProfileDelegate, i18Next: ITranslationService): number {
        if (a.isNameKey != b.isNameKey) {
            return 1;
        }
        else {
            let aName = a.isNameKey ? ProfileHelper.getName(a.name, i18Next) : a.name;
            let bName = b.isNameKey ? ProfileHelper.getName(b.name, i18Next) : b.name;
            return aName.localeCompare(bName, undefined, { sensitivity: 'base' });
        }
    }

    public static getName(nameKey: string, i18Next: ITranslationService): string {
        if (!ProfileHelper.names) {
            ProfileHelper.names = {};
        }
        let name = ProfileHelper.names[nameKey];
        if (!name) {
            name = i18Next.t(nameKey);
            ProfileHelper.names[nameKey] = name;
        }
        return name;
    }

    public static isMicrosoft(profile: ReportProfileBaseModel): boolean {
        return (profile as MicrosoftOfficeReportProfileModel).exchangeProfiles !== undefined;
    }

    public static isZimbra(profile: ReportProfileBaseModel): boolean {
        return (profile as ZimbraReportProfileModel).mailingProfiles !== undefined;
    }


    public static getResourceProfile(profile: ReportProfileBaseModel, resource: BaseProviderInstanceResourceModel): ReportProfileResourceBase<any> {
        if (this.isMicrosoft(profile)) {
            switch (resource.providerTypeId) {
                case Constants.PROVIDER_TYPE_FILE_SHARE:
                    return (<MicrosoftOfficeReportProfileModel>profile).fileShareProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
                case Constants.PROVIDER_TYPE_MAILING:
                    return (<MicrosoftOfficeReportProfileModel>profile).exchangeProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
                case Constants.PROVIDER_TYPE_INTRANET:
                    return (<MicrosoftOfficeReportProfileModel>profile).sharePointProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
                case Constants.PROVIDER_TYPE_PERSONAL_STORAGE:
                    return (<MicrosoftOfficeReportProfileModel>profile).oneDriveProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
                case Constants.PROVIDER_TYPE_GENERIC:
                    return (<MicrosoftOfficeReportProfileModel>profile).azureADProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
            }
        }
        else if (this.isZimbra(profile)) {
            switch (resource.providerTypeId) {
                case Constants.PROVIDER_TYPE_MAILING:
                    return (<ZimbraReportProfileModel>profile).mailingProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
            }
        }
        throw new Error("Profile type not found !!!");
        
    }
}

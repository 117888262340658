<div class="module">
  <div class="container-fluid module-container"
    *ngIf="delegatesWithAccessAndPermissions != null && delegatesWithAccessAndPermissions.length > 0">
    <div class="row no-gutters">
      <i2a-sub-title class="col-12" [subTitle]="'profile.provider.microsoft.exchange.title.delegateAccess' | i18next"
        [description]="'profile.provider.microsoft.exchange.description.delegateAccess' | i18next"></i2a-sub-title>
    </div>
    <div class="row no-gutters">

        <!-- DEVICES -->
        <div  *ngIf="configuration.displayUnregisteredDevices && resourceProfile.unRegisteredDevices.length > 0" class="col-sm-12 col-md-6 col-lg-4 align-items-start">

          <i2a-exchange-user-device [resourceProfile]="resourceProfile" [componentId]="componentId" [profile]="profile" [user]="user" [alerts]="alerts"
            [isAlert]="isAlert" [providerInstanceResource]="resourceProfile.providerInstanceResource"
            [devices]="resourceProfile.unRegisteredDevices" (onAlertSent)="alertSent($event)" (onHasWarnings)="hasWarnings($event)">
          </i2a-exchange-user-device>

        </div>
          
        <!-- DELEGATES -->
        <div  *ngFor="let delegate of delegatesWithAccessAndPermissions | slice:0:maxShowMore" class="col-sm-12 col-md-6 col-lg-4 align-items-start">
          
          <i2a-exchange-delegate [configuration]="configuration" [resourceProfile]="resourceProfile" [componentId]="componentId" [isAlert]="isAlert"
              [alerts]="alerts" [providerInstanceResource]="resourceProfile.providerInstanceResource" [profile]="profile"
              [user]="user" [delegate]="delegate" (onAlertSent)="alertSent($event)" (onHasWarnings)="hasWarnings($event)">
          </i2a-exchange-delegate>

        </div>

        <div *ngIf="isVisibleButtonsShowMore" class="col-12 p-2 text-center">
          <button class="btn btn-outline-primary" (click)="onShowMore()" *ngIf="!showMore">{{ 'button.showMore' | i18next }}</button>
          <button class="btn btn-outline-primary" (click)="onShowMore()" *ngIf="showMore">{{ 'button.showLess' | i18next }}</button>
        </div>

    </div>
  </div>
</div>

import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfigService, I2A_CONFIG_SERVICE, IConfigService, IWebConfig } from '../../infrastructure/config.service';

@Component({
  selector: 'i2a-probes-status',
  templateUrl: './probes-status.component.html',
  styleUrls: ['./probes-status.component.scss']
})
export class ProbesStatusComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  public status: any;

  constructor(
    @Inject(I2A_CONFIG_SERVICE) private configService: IConfigService<IWebConfig>,
    @Inject('env') environment:any) { 
      this.status = {
        "settings": null,
        "environment" : environment
      }
    }

  ngOnInit() {
    this.subscriptions.add(
      this.configService.Config$.subscribe(settings => {
        this.status.settings = settings;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}

import { Component, OnInit, Input, Inject } from '@angular/core';
import { OriginConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/origin-configuration';
import { ReportProfileBaseModel } from '@i2a/web-api-client';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

@Component({
  selector: 'i2a-usual-countries',
  templateUrl: './usual-countries.component.html',
  styleUrls: ['./usual-countries.component.scss']
})
export class UsualCountriesComponent implements OnInit {
  @Input() configuration: OriginConfiguration;
  @Input() profile: ReportProfileBaseModel;
  public items: any[];

  constructor(
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService) { }

  ngOnInit() {
    if (this.profile && this.profile.userProfile.usualCountries) {
      this.items = this.profile.userProfile.usualCountries.map(usualCountry => {
        let item = {
          image: `/assets/pictos/countries/${usualCountry.countryCode}.png`,
          name: usualCountry.countryCode == null ? this.i18Next.t("country.undeterminable") : this.i18Next.t(`country.${usualCountry.countryCode}`),
          countryCode: usualCountry.countryCode,
          expiration: usualCountry.expiration,
          hasAlert: false
        };
        return item;
      }).filter(c=> !this.configuration.usualCountries.displayNotLocated ? c.countryCode != null : true ).sort((a, b) => {
        if (a.countryCode == null) {
          return -1;
        }
        else {
          return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
        }
      });

    }
  }

}

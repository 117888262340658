import { Component, Input, OnInit } from '@angular/core';
import { MenuApplication } from 'projects/web-user-profile/src/app/models/menu-application';
import { MenuItem } from 'projects/web-user-profile/src/app/models/menu-item';
import { WarningInfo } from 'projects/web-user-profile/src/app/models/warnings/warning-info';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-nav-bar-warning-counter',
  templateUrl: './nav-bar-warning-counter.component.html',
  styleUrls: ['./nav-bar-warning-counter.component.scss']
})
export class NavBarWarningCounterComponent implements OnInit {

  @Input() public warningshighlighting: WarningInfo[];
  @Input() public application: MenuApplication;
  @Input() public item?: MenuItem;

  public isCounterAvailableToShow: boolean;
  public warningCount: string;

  constructor(public session: SessionService) { }

  ngOnInit(): void {
    if (this.application?.isApplication) {
      let cpt: number = 0;
      if (this.item != null) {
        if (this.item.providerInstanceResourceIds?.length > 0) {
          cpt = this.warningshighlighting.filter(w => w.resourceProfile.providerInstanceResource.providerTypeId.toLocaleLowerCase() == this.item.providerTypeId.toLocaleLowerCase() 
                                                   && this.item.providerInstanceResourceIds.includes(w.resourceProfile.providerInstanceResource.providerInstanceResourceId.toLocaleLowerCase()))?.length;
        } else {
          cpt = this.warningshighlighting.filter(w => w.resourceProfile.providerInstanceResource.providerTypeId.toLocaleLowerCase() == this.item.providerTypeId.toLocaleLowerCase() && 
                                                      w.resourceProfile.providerInstanceResource.providerInstanceResourceId.toLowerCase() == this.item.providerInstanceResourceId.toLocaleLowerCase())?.length;
        }
      } else {
        cpt = this.warningshighlighting.filter(w => w.resourceProfile.providerInstanceResource.providerTypeId.toLocaleLowerCase() == this.application?.providerTypeId.toLocaleLowerCase())?.length;
      }
      this.setWarningCount(cpt);
      this.isCounterAvailableToShow = this.session.WarningConfiguration && !this.session.WarningConfiguration.hideMenuCounters && <boolean>(this.warningCount && this.warningCount.length > 0);
    }
  }

  private setWarningCount(cpt: number) {
    this.warningCount = cpt > 9
      ? '9+'
      : cpt > 0
        ? cpt.toString()
        : '';
  }
}

<i2a-profile-card [title]="'profile.provider.microsoft.oneDrive.external' | i18next" [subTitle]="subTitle"
  [viewMoreDetails]="true" [viewMoreDetailsKey]="'button.viewExternalAccounts'"
  (onViewMoreDetails)="openDetails($event)" (onWarnClick)="warnClick($event)" [hasAlert]="hasAlert"
  [hasAlertRecentlyClosed]="hasAlertRecentlyClosed" [warningLevel]="warningLevel">

  <ng-container picto>
    <i2a-badge-icon>
      <ng-container icon>
        <fa-icon [icon]="['fas', 'users']" size="lg"></fa-icon>
      </ng-container>
    </i2a-badge-icon>
  </ng-container>

  <ng-container content>
    <div class="container p-0">
      <div class="row no-gutters sharepoint-external-permissions">
        <div class="col ml-2 mr-2 mt-2">
          <table class="table-sm id-table">
            <tbody>
              <tr>
                <td scope="row" class="text-break">
                  <p>
                    <fa-icon [icon]="['fal', 'id-card-alt']" size="lg"></fa-icon>
                    {{'profile.provider.microsoft.oneDrive.externalAccountsDetail' | i18next : {totalAccounts : delegates.length} }}
                  </p>
                  <p class="warn-sensitivity-label mb-0" *ngIf="hasSensitivityLabel && warnSensitivityLabel">
                    <fa-icon [icon]="['far', 'lock-alt']" size="lg"></fa-icon>
                    {{ ("profile.provider.microsoft.sharePoint.warnSensitivityLabel")  | i18next  }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-container>

</i2a-profile-card>

<ng-template #viewDetailBodyTemplate>
  <i2a-modal-profile-header-title>
    <ng-container picto>
      <i2a-badge-icon>
        <ng-container icon>
          <fa-icon [icon]="['fas', 'users']" size="lg"></fa-icon>
        </ng-container>
      </i2a-badge-icon>
    </ng-container>
    <ng-container title>
      <span
        [title]="'profile.provider.microsoft.oneDrive.external' | i18next">{{'profile.provider.microsoft.oneDrive.external' | i18next}}</span>
    </ng-container>
  </i2a-modal-profile-header-title>

  <div class="container p-0 delegate-content">
    <div class="row no-gutters">
      <div class="col m-2">
        <ng-container *ngFor="let delegate of delegates">
          <i2a-short-title-bar [className]="'delegate-title'" [isCollapsed]="isCollapsed"
            [hasAlert]="delegate.hasAlert" [hasAlertRecentlyClosed]="delegate.hasAlertRecentlyClosed">
            <ng-container title>
              {{delegate.name}}
              <ng-container><span *ngIf="delegate.hasSensitivityLabel"
                  [title]="'profile.provider.microsoft.sharePoint.delegate.sensitivityLabel' | i18next  ">
                  <fa-icon [icon]="['far', 'lock-alt']"></fa-icon>
                </span></ng-container>
            </ng-container>
            <ng-container body>
              <table class="table-sm id-table">
                <tbody>
                  <tr>
                    <td scope="row" class="text-break font-italic">
                      {{ maxPermission(delegate) }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <i2a-share-point-permission-detail [displayLegend]="false" [driveItems]="delegate.driveItems">
                      </i2a-share-point-permission-detail>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
          </i2a-short-title-bar>
        </ng-container>
        <div class="mt-4">
          <i2a-share-point-permission-legend [displayReadLegend]="true" [displayUpdateLegend]="true"
            [displayOwnerLegend]="true" [displayUnknownLegend]="true" [isCollapsed]="true"
            [hasSensitivityLabel]="hasSensitivityLabel">
          </i2a-share-point-permission-legend>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #viewDetailButtonsTemplate>
  <button type="button" class="btn btn-outline-dark" (click)="expandAllAccounts()">
    {{'profile.provider.microsoft.oneDrive.expandCollapseFolders' | i18next}}
  </button>
</ng-template>
import { FileShareMassAffectationItemModel } from '../../microsoft/fileshare/file-share-mass-affectation-item-model';

export class FileShareMassAffectationModel {
  providerInstanceId: string;
  server: string;
  share: string;
  itemId: string;
  items: FileShareMassAffectationItemModel[];
  startProtection: boolean;
  realTimeLearningDuration: string|null;
}

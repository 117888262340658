import { Component, OnInit, Input, OnDestroy, Inject, HostBinding } from '@angular/core';
import { ProfileBaseClassComponent } from '../../shared/profile-base-class-component';
import { UserModel, UserReportProfileAlertAccessModesModel, MicrosoftOfficeProfileProviderDatasourceService, ZimbraProfileProviderDatasourceService, Constants, ReportProfileBaseModel, AlertInvalidStateFeaturedModel, AlertConstants, ReportProfileResourceBase } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccessModeModalComponent } from '../access-mode-modal/access-mode-modal.component';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { AccessModeConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/access-mode-configuration';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { AccessModeWithAlert } from 'projects/web-user-profile/src/app/models/users/access-mode-with-alert';
import { ReportProfileAccessModeModel } from '@i2a/web-api-client';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';

@RegisterMyProfileComponent(TemplateConstants.MODULE_USER_ACCESSMODE)
@Component({
  selector: 'i2a-access-mode',
  host: { "class": "col-sm-12 col-md-6 col-lg-4 align-items-start" },
  templateUrl: './access-mode.component.html',
  styleUrls: ['./access-mode.component.scss']
})
export class AccessModeComponent extends ProfileBaseClassComponent<AccessModeConfiguration, ReportProfileResourceBase<any>> implements OnInit, OnDestroy, IModule<AccessModeConfiguration> {

  @HostBinding('class.d-none')
  public isHide: Boolean = false;

  @Input() public accessModes: ReportProfileAccessModeModel[];
  @Input() public user: UserModel;

  public accessModesSorted: AccessModeWithAlert[];

  constructor(
    modalService: NgbModal,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    private zimbraDatasource: ZimbraProfileProviderDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: AccessModeConfiguration, alerts: AlertInvalidStateFeaturedModel[]): void {
    super.baseInitialize(user, profile, configuration, alerts);
    this.configuration = <AccessModeConfiguration>configuration;
    this.accessModes = profile.userProfile.accessModes.filter(ac => this.configuration.accessModeToFilter.indexOf(ac.accessMode) == -1);
  }

  setItems() {
    if (this.accessModes && this.alerts) {
      super.setItems();

      this.accessModes = this.configuration.displayIsSucceeded ? this.accessModes : this.accessModes.filter(o => o.isSucceeded);

      if (this.accessModes.length == 0) {
        this.isHide = true
      }

      this.accessModesSorted = [];


      let accessModesInAlertNotClosed = this.getHasAlertByAlertId(false);
      this.hasAlert = Object.keys(accessModesInAlertNotClosed).length > 0;

      let accessModesInAlertClosed =  this.getHasAlertByAlertId(true);
      this.hasAlertRecentlyClosed = Object.keys(accessModesInAlertClosed).length > 0;

      this.accessModes.forEach(accessMode => {
        this.accessModesSorted.push({
          accessMode: this.i18Next.t(`common.accessMode.${accessMode.accessMode}`),
          lastActionDate: accessMode.lastActionDate,
          hasAlert: accessModesInAlertNotClosed[accessMode.accessMode],
          hasAlertRecentlyClosed: accessModesInAlertClosed[accessMode.accessMode],
          isSucceeded: accessMode.isSucceeded
        });
      });
      this.accessModesSorted.sort((a, b) => a.accessMode.localeCompare(b.accessMode, undefined, { sensitivity: 'base' }));

      this.id = `${this.componentId}-${this.user.id}-AccessMode`;
    }

    this.hasNoData = this.accessModes.length == 0;
    this.onHasNoData.emit(this.hasNoData);
    this.isHide = this.hasNoData;
  }

  private getHasAlertByAlertId(done: boolean): { [id: string]: boolean; } {
    return AlertReasonHelper.GetIds(this.alerts, AlertConstants.ALERT_INVALID_STATE_CONTEXT_USER_ACCESS_MODE, done);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(AccessModeModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.disableManualRemediation = true;
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.accessModes = this.accessModes;
    modalRef.result.then((model: UserReportProfileAlertAccessModesModel) => {
      this.initAlertModel(model);
      let result$: Observable<any> = null;
      if (this.profile.providerId == Constants.PROVIDER_ID_MICROSOFT) {
        result$ = this.microsoftDatasource.alertUserAccessModes(this.profile.providerInstanceId, this.profile.userId, model);
      } else if (this.profile.providerId == Constants.PROVIDER_ID_ZIMBRA) {
        result$ = this.zimbraDatasource.alertUserAccessModes(this.profile.providerInstanceId, this.profile.userId, model);
      }
      if (result$) {
        this.subscriptions.add(result$.subscribe(model => {
          this.setAlertProcessed(model);
        }));
      }

    }).catch((error) => { console.error(error); });
  }
}

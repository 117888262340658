<!-- PERMISSIONS -->
<div class="module">
  <div  class="container-fluid module-container"
        *ngIf="delegates != null && delegates.length > 0">
    <div class="row no-gutters">
      <i2a-sub-title class="col-12" [subTitle]="'profile.provider.microsoft.exchange.title.permissions' | i18next "
        [description]="'profile.provider.microsoft.exchange.description.permissions' | i18next "></i2a-sub-title>
    </div>
    <div class="row no-gutters">

        <div *ngFor="let permissions of delegates | slice:0:maxShowMore" class="col-sm-12 col-md-6 col-lg-4 align-items-start">
          
            <i2a-exchange-permission [resourceProfile]="resourceProfile" [componentId]="componentId" [isAlert]="isAlert" [alerts]="alerts"
              [providerInstanceResource]="resourceProfile.providerInstanceResource" [profile]="profile" [user]="user"
              [permissionsWithWarnings]="permissions" (onAlertSent)="alertSent($event)" (onHasWarnings)="hasWarnings($event)">
            </i2a-exchange-permission>

        </div>

        <div *ngIf="isVisibleButtonsShowMore" class="col-12 p-2 text-center">
          <button class="btn btn-outline-primary" (click)="onShowMore()" *ngIf="!showMore">{{ 'button.showMore' | i18next }}</button>
          <button class="btn btn-outline-primary" (click)="onShowMore()" *ngIf="showMore">{{ 'button.showLess' | i18next }}</button>
        </div>

    </div>
  </div>
</div>

import { SharePointGroupPrincipalModel } from '../../microsoft/sharepoint/share-point-group-principal-model';

export class SharePointGroupMemberModel extends SharePointGroupPrincipalModel {
  email: string;
  userPrincipalName: string;
  isGroup: boolean;
  isO365Group: boolean;
  isEveryone: boolean;
  isEveryoneExceptExternalUsers: boolean;
}

<a [id]="'application' + template.providerTypeId"></a>

<template #application i2a-container></template>
<template #resources i2a-container></template>

<ng-container *ngIf="configuration.groupBySite && !displayResources">
	<ng-container *ngFor="let site of profile.sharePointSites">
		<i2a-share-point-site-container [alerts]="alerts" 
                                    [componentId]="componentId" 
                                    [configuration]="configuration"
                                    [isAlert]="isAlert"                                    
                                    [profile]="profile"
                                    [site]="site"
                                    [template]="template" 
                                    [user]="user"
                                    (onAlertSent)="alertSent($event)" 
                                    (onHasWarnings)="hasWarnings($event)"
                                    (onResourceToValidate)="resourceToValidate($event)">
		</i2a-share-point-site-container>
	</ng-container>
</ng-container>
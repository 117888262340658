import { FileShareReportProfileDelegate } from '../../../microsoft/fileshare/profile/file-share-report-profile-delegate';
import { FileShareReportProfilePermissions } from '../../../microsoft/fileshare/profile/file-share-report-profile-permissions';
import { ReportProfileResourceBase } from '../../../profile/report-profile-resource-base';
import { ReportProfileRulesComputedDelegate } from '../../../profile/report-profile-rules-computed-delegate';

export class FileShareReportProfile extends ReportProfileResourceBase<ReportProfileRulesComputedDelegate> {
  delegates: FileShareReportProfileDelegate[];
  permissions: FileShareReportProfilePermissions[];
  hasSensitivityLabel: boolean;
}

import { KeyValuePair } from '../../models/key-value-pair';
import { UserStatus } from './user-status';
import { UserSummaryModel } from './user-summary-model';

export class UserModel extends UserSummaryModel {
  orgUnitId: string;
  firstName: string;
  lastName: string;
  providerEmails: string[];
  phone: string;
  timeZoneId: string;
  culture: string;
  status: UserStatus;
  annotations: KeyValuePair[];
}

import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from 'rxjs';
import { AuthDatasourceService } from '@i2a/web-api-client';
import { ContextService, CultureManagerService } from '@i2a/web-core';
import { SessionService } from '../services/session-service';
import { map, take, last, first } from 'rxjs/operators';

@Injectable()
export class ConnectedAccountResolver implements Resolve<Observable<any>> {
  constructor(
    private context: ContextService,
    private session: SessionService,
    private authDataSource: AuthDatasourceService,
    private cultureManager: CultureManagerService
  ) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Observable<any> | Promise<any> | any> {
    console.log(`set connectedAccount`);
    let account = await this.authDataSource.me().toPromise();
    this.session.Account = account;
    var observer = this.cultureManager.resourcesReloaded$.pipe(
      map(() => {
        return account;
      }),
      take(1));
    this.context.Culture = account.culture;
    return observer.toPromise()
      .then(() => {
        console.log(`ConnectedAccountResolver - resources loaded`);
        return account;})
      .catch(() => {return account;});
  }
}
<i2a-profile-card [id]="id" [title]="title" [viewMoreDetails]="showDetails && grants && grants.resources.length > 0"
  (onViewMoreDetails)="openDetails($event)" [subTitle]="subTitle" (onWarnClick)="warnClick($event)"
  [hasAlert]="hasAlert" [hasAlertRecentlyClosed]="hasAlertRecentlyClosed" [warningLevel]="warningLevel">

  <ng-container picto>
    <i2a-badge-icon>
      <ng-container icon>
        <fa-icon [icon]="['fal', 'lock']" size="lg"></fa-icon>
      </ng-container>
    </i2a-badge-icon>
  </ng-container>
  <ng-container content>
    <div class="container p-0">
      <div class="row no-gutters">
        <i2a-resource-nodata *ngIf="grants && grants.resources.length == 0"></i2a-resource-nodata>
      </div>
    </div>
  </ng-container>
</i2a-profile-card>

<ng-template #viewDetailTemplate>
  <i2a-modal-profile-header-title>
    <ng-container picto>
      <i2a-badge-icon>
        <ng-container icon>
          <fa-icon [icon]="['fal', 'lock']" size="lg"></fa-icon>
        </ng-container>
      </i2a-badge-icon>
    </ng-container>
    <ng-container title>
      <span [title]="title">{{title}}</span>
    </ng-container>
  </i2a-modal-profile-header-title>

  <table *ngIf="scopes.length > 0" class="table table-sm table-bordered id-table">
    <tbody>
      <tr *ngFor="let scope of scopes">
        <td scope="row">
          {{scope}}
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoadingHelper {

    constructor() {
    }

    private static getUsersLocale(defaultValue: string): string {
        if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
            return defaultValue;
        }
        const wn = window.navigator as any;
        let lang = wn.languages ? wn.languages[0] : defaultValue;
        lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
        return lang;
    }

    public static getLoadingText() {
        let culture = LoadingHelper.getUsersLocale("en").toLocaleLowerCase();

        let loading = "Loading in progress, please wait...";
        if (culture.startsWith("fr")) {
            loading = "Chargement en cours, veuillez patienter...";
        } else if (culture.startsWith("de")) {
            loading = "Ladevorgang läuft, bitte warten Sie...";
        }
        console.log(`${culture} => ${loading}`)
        return loading;
    }
}

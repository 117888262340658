<i2a-profile-alert-base-modal
  [manualDescriptionKey]="'applyCorrection.providers.microsoft.oneDrive.delegate.default.manual.description'"
  [manualLinkKey]="'applyCorrection.providers.microsoft.oneDrive.delegate.default.manual.link'"
  [automaticDescriptionKey]="'applyCorrection.providers.microsoft.oneDrive.delegate.default.automatic.description'"
  [ignoreCommentToSend]="session.IgnoreCommentToSend" [displayComment]="session.DisplayComment"
  [validation]="validation" [canSend]="canSend" [modalTitle]="'profile.confirmationOfInformationTitle' | i18next "
  [resourceProfile]="resourceProfile">

  <ng-container header-picto>
    <i2a-delegate-photo [isAnonymous]="delegate.accesses.isAnonymousUser"
      [isDefault]="delegate.accesses.logonUserId == 'Default'" [isApplication]="delegate.accesses.isApplication"
      [isExternalUser]="delegate.accesses.isExternalUser" [pir]="delegate.accesses.providerInstanceResource"
      [piu]="delegate.accesses.providerInstanceUser"></i2a-delegate-photo>
  </ng-container>

  <ng-container header-title>
    {{name}}<br>
    <ng-container *ngIf="!delegate.accesses.isAnonymousUser">
      {{delegate.accesses.lastAccess | amDateFormat: 'LLL'}}
    </ng-container>
  </ng-container>

  <ng-container body>
    <div class="container-fluid">
      <i2a-share-point-access-permission-form [(validation)]="validation" [folders]="folders"
        [driveItemsPermissions]="driveItemsPermissions" (setCanSend)="setCanSend()"
        [allAccessAndPermissionsTitle]="'profile.provider.microsoft.sharePoint.allAccessesAndPermissions' | i18next">
      </i2a-share-point-access-permission-form>
    </div>
  </ng-container>

</i2a-profile-alert-base-modal>
import { AzureADUserSummaryModel } from '../../microsoft/azuread/profile/azure-aduser-summary-model';
import { BaseSharePointGraphGroupModel } from '../../microsoft/sharepoint/base-share-point-graph-group-model';

export class SharePointGraphGroupModel extends BaseSharePointGraphGroupModel {
  members: AzureADUserSummaryModel[];
  guests: AzureADUserSummaryModel[];
  owners: AzureADUserSummaryModel[];
  membersFiltered: boolean;
  guestsFiltered: boolean;
  ownersFiltered: boolean;
  hasTeam: boolean|null;
  hasYammer: boolean|null;
  teamWebUrl: string;
  yammerWebUrl: string;
}

<div class="d-flex justify-content-start flex-wrap">
  <div *ngIf="deleteResource" class="p-1">
    <button (click)="onDeleteResource()" id="deleteResource" class="btn btn-outline-light btn-outline-alert-dark">
      {{"button.deleteResource" | i18next}}
    </button>
  </div>
  <ng-content select="[customDelete]"></ng-content>

  <div *ngIf="resourceNotBelongToMe" class="p-1">
    <button (click)="onResourceNotBelongToMe()" id="resourceNotBelongToMe"
      class="btn btn-outline-light btn-outline-alert-dark">
      {{"button.resourceNotBelongToMe" | i18next}}
    </button>
  </div>
  <ng-content select="[customNotBelongToMe]"></ng-content>
  
  <div *ngIf="commentResource" class="p-1">
    <button (click)="onCommentResource()" id="commentResource" class="btn btn-outline-primary">
      {{"button.commentResource" | i18next}}
    </button>
  </div>
  <ng-content select="[customComment]"></ng-content>

  <div *ngIf="openSite" class="p-1">
    <a class="btn btn-outline-primary" target="_blank" [href]="siteUrl">
      {{openSiteKey | i18next}}
      <fa-icon [icon]="['fal', 'external-link']"></fa-icon>
    </a>
  </div>
  <ng-content select="[customOpenSite]"></ng-content>
  
  </div>


  <ng-template #deleteConfirmationTemplateHeader>
    <div class="container-fluid profile-alert-base-modal p-0">
      <div id="modal-header" class="row id-header p-3">
        <table class="table-sm id-table" *ngIf="resourceProfile">
          <tbody>
            <tr>
              <td scope="row text-center" class="p-0 id-header-picto">
                <img class="id-provider-picture" *ngIf="providerPhotoUrl" [src]="providerPhotoUrl" width="28"
                  height="28">
              </td>
              <td scope="row" class="text-break w-100 align-self-center" #headertitle>
                <span class="provider-type" [title]="providerTypeLabel">{{ providerTypeLabel }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-template>
  
  
  <ng-template #deleteConfirmationTemplate>
  <div class="container-fluid profile-resource">
    <div class="row pt-3 no-gutters" id="modal-body">
      <div class="col-12 pb-2">
        <p><strong>{{"modal.deleteConfirmation.question" | i18next : { providerTypeResource : providerTypeLabel } }}</strong></p>
        <ul class="id-warning mt-4 mb-0">
          <li *ngFor="let key of deleteWarningKeys">
            <fa-icon class="pr-2" [icon]="['fal', 'exclamation-triangle']" size="lg"></fa-icon>
            {{key | i18next}}
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-12" id="modal-comment">
        <label for="assignIssue">{{'profile.comments' | i18next }}</label>
        <div>
          <textarea [(ngModel)]='deleteComments' rows="5" class="comments">
          </textarea>
        </div>
      </div>
    </div>
  </div>

</ng-template>

<ng-template #commentConfirmationTemplateHeader>
  <div class="container-fluid profile-alert-base-modal p-0">
    <div id="modal-header" class="row id-header p-3">
      <table class="table-sm id-table" *ngIf="resourceProfile">
        <tbody>
          <tr>
            <td scope="row text-center" class="p-0 id-header-picto">
              <img class="id-provider-picture" *ngIf="providerPhotoUrl" [src]="providerPhotoUrl" width="28"
                height="28">
            </td>
            <td scope="row" class="text-break w-100 align-self-center" #headertitle>
              <span class="provider-type" [title]="providerTypeLabel">{{ providerTypeLabel }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #commentConfirmationTemplate>
  <div class="container-fluid profile-resource">
    <div class="row pt-3 no-gutters" id="modal-body">
      <div class="col-12 pb-2">
        <p><strong>{{"modal.commentConfirmation.question" | i18next : { providerTypeResource : providerTypeLabel } }}</strong></p>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-12" id="modal-comment">
        <label for="assignIssue">{{'profile.comments' | i18next }}</label>
        <div>
          <textarea [(ngModel)]='contactComments' rows="5" class="comments">
          </textarea>
        </div>
      </div>
    </div>
  </div>

</ng-template>
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FolderHelper {

    constructor() {
    }

    public static _FOLDERS_SEPARATOR = '/';

    public static RemoveFolderPartsAboveMaxDepthLevel(folders: string[], maxDepth: number): string[] {
        var newFolders = [];
        folders.forEach(folder => {
            var folderParts = folder.split(FolderHelper._FOLDERS_SEPARATOR);
            var newFolderParts = [];
            var separatorsMetCount = 0;
            for (let i = 0; i < folderParts.length; i++) {
                if (separatorsMetCount > maxDepth) {
                    break;
                }
                newFolderParts.push(folderParts[i]);
                separatorsMetCount++;
            }
            newFolders.push(newFolderParts.join(FolderHelper._FOLDERS_SEPARATOR));
        });

        var acc = newFolders.reduce((acc, folder) => {
            acc[folder] = acc[folder] ? acc[folder] + 1 : 1;
            return acc;
        }, {});
        var sortable = [];
        for (var folder in acc) {
            sortable.push([folder, acc[folder]]);
        }
        sortable.sort(function (a, b) {
            return b[1] - a[1];
        });
        
        return sortable.map((k) => k[0]);

    }

    public static CompleteFolders(folders: string[], maxElements: number): string[] {
        var newFolders = folders.slice(0, maxElements);
        for (let index = newFolders.length; index < maxElements; index++) {
            newFolders.push(`\u00A0`);
        }
        return newFolders;
    }

}

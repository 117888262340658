import { Injectable } from '@angular/core';
import { DatasourceService } from './datasource.service';

@Injectable({
	providedIn: 'root'
})
export class ProviderInstanceUsersDatasourceUrlService {

private route: string = "provider-instance-users";

	constructor(private datasource: DatasourceService) {
		console.log("Init ProviderInstanceUsersDatasourceUrlService");
	}

	public PhotoUrl(providerId: string, providerInstanceId: string, providerInstanceResourceId: string, defaultImageifNotFound: boolean): string {
        let partialUrl = `${this.route}/${providerId}/${providerInstanceId}/${providerInstanceResourceId}/photo?defaultImageifNotFound=${defaultImageifNotFound}`;

        return this.datasource.getUrl(partialUrl);
	}
}

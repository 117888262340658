import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
import { ReportProfileRulesComputedDelegate, MicrosoftOfficeProfileProviderDatasourceService, ProviderAuditResourceKind, ReportProfileAlertRulesComputedDelegateModel, ZimbraProfileProviderDatasourceService, OneDriveReportProfileRulesComputedDelegate, Constants, MicrosoftOfficeReportProfileAlertRulesComputedDelegateModel, ZimbraReportProfileAlertRulesComputedDelegateModel, ReportProfileDeviceModel, ExchangeReportProfile } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ProfileItemBaseClassComponent } from '../../shared/modals/profile-item-base-class-component';
import { RulesComputedDelegateDetailsModalComponent } from '../rules-computed-delegate-details-modal/rules-computed-delegate-details-modal.component';
import { RulesComputedDelegateConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/rules-computed-delegate-configuration';
import { RulesComputedDelegateModalComponent } from '../rules-computed-delegate-modal/rules-computed-delegate-modal.component';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';

@Component({
  selector: 'i2a-rules-computed-delegate',
  templateUrl: './rules-computed-delegate.component.html',
  styleUrls: ['./rules-computed-delegate.component.scss']
})
export class RulesComputedDelegateComponent extends ProfileItemBaseClassComponent<ExchangeReportProfile> implements OnInit, OnDestroy {
  @Input() public delegate: ReportProfileRulesComputedDelegate;
  @Input() public configuration: RulesComputedDelegateConfiguration;

  public title: string;
  public IsInstantMessaging: boolean;
  public IsInbox: boolean;
  public IsFolders: boolean;
  public IsCalendar: boolean;

  public displayIsInbox: boolean;
  public displayIsFolders: boolean;
  public displayIsCalendar: boolean;
  public displayIsInstantMessaging: boolean;

  public devices: ReportProfileDeviceModel[];

  constructor(
    modalService: NgbModal,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    private zimbraDatasource: ZimbraProfileProviderDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
    this.devices = [];
  }

  public get isMicrosoft(): boolean {
    return this.providerInstanceResource.providerId == Constants.PROVIDER_ID_MICROSOFT;
  }

  public get isZimbra(): boolean {
    return this.providerInstanceResource.providerId == Constants.PROVIDER_ID_ZIMBRA;
  }

  public get isOneDrive(): boolean {
    return this.isMicrosoft && this.providerInstanceResource.providerTypeId == Constants.PROVIDER_TYPE_PERSONAL_STORAGE;
  }


  setItems() {
    if (this.delegate && this.alerts) {
      super.setItems();
      if (!this.delegate.isNameKey) {
        this.title = this.delegate.name;
      }
      else {
        this.title = this.i18Next.t(this.delegate.name);
      }

      this.displayIsFolders = true;

      if (this.isOneDrive) {
        this.displayIsCalendar = false;
        this.displayIsInstantMessaging = true;
        this.displayIsInbox = false;

        this.IsInstantMessaging = (this.delegate as OneDriveReportProfileRulesComputedDelegate).hasInstantMessagingShare;
        this.IsFolders = this.hasFlag(this.delegate.resourceKind, ProviderAuditResourceKind.ShareableContent)
          || this.hasFlag(this.delegate.resourceKind, ProviderAuditResourceKind.Technical)
          || this.hasFlag(this.delegate.resourceKind, ProviderAuditResourceKind.Unknown)
          || this.hasFlag(this.delegate.resourceKind, ProviderAuditResourceKind.NULL)
          || this.hasFlag(this.delegate.resourceKind, ProviderAuditResourceKind.Content);

      }
      else {
        this.IsInbox = this.hasFlag(this.delegate.resourceKind, ProviderAuditResourceKind.Inbox)
          || this.hasFlag(this.delegate.resourceKind, ProviderAuditResourceKind.Outbox);

        this.IsCalendar = this.hasFlag(this.delegate.resourceKind, ProviderAuditResourceKind.Calendar)
          || this.hasFlag(this.delegate.resourceKind, ProviderAuditResourceKind.Trash);

        this.IsFolders = this.hasFlag(this.delegate.resourceKind, ProviderAuditResourceKind.ShareableContent)
          || this.hasFlag(this.delegate.resourceKind, ProviderAuditResourceKind.Technical)
          || this.hasFlag(this.delegate.resourceKind, ProviderAuditResourceKind.Contact)
          || this.hasFlag(this.delegate.resourceKind, ProviderAuditResourceKind.Unknown)
          || this.hasFlag(this.delegate.resourceKind, ProviderAuditResourceKind.NULL);

        this.displayIsCalendar = true;
        this.displayIsInstantMessaging = false;
        this.displayIsInbox = true;

        this.IsInstantMessaging = false;
      }

      this.hasAlert = this.hasAlertInAlerts(false);
      this.hasAlertRecentlyClosed = this.hasAlertInAlerts(true);

      this.devices = this.delegate.registeredDevices.filter(d => !this.configuration.filterIsActiveDevice || this.configuration.filterIsActiveDevice && d.isActive);

      this.id = `${this.componentId}-${this.providerInstanceResource.providerTypeId}-${this.providerInstanceResource.providerInstanceResourceId}-${this.title.replaceAll(' ', '-')}-RulesComputedDelegate`;
    }
  }

  private hasAlertInAlerts(done: boolean): boolean {
    return AlertReasonHelper.alertsContainsDelegate(this.alerts, this.delegate, done);
  }

  private hasFlag(resourceKind: ProviderAuditResourceKind, flag: ProviderAuditResourceKind): boolean {
    return (resourceKind & flag) == flag;
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(RulesComputedDelegateModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.providerInstanceResource = this.providerInstanceResource;
    modalRef.componentInstance.delegate = this.delegate;
    modalRef.componentInstance.configuration = this.configuration;
    modalRef.componentInstance.IsInstantMessaging = this.IsInstantMessaging;
    modalRef.componentInstance.IsInbox = this.IsInbox;
    modalRef.componentInstance.IsFolders = this.IsFolders;
    modalRef.componentInstance.IsCalendar = this.IsCalendar;
    modalRef.componentInstance.displayIsCalendar = this.displayIsCalendar;
    modalRef.componentInstance.displayIsFolders = this.displayIsFolders;
    modalRef.componentInstance.displayIsInstantMessaging = this.displayIsInstantMessaging;
    modalRef.componentInstance.displayIsInbox = this.displayIsInbox;

    modalRef.result.then((model: ReportProfileAlertRulesComputedDelegateModel) => {
      this.initAlertModel(model);
      model.delegate = this.delegate;
      model.providerInstanceResource = this.providerInstanceResource;
      if (this.isMicrosoft) {
        this.subscriptions.add(this.microsoftDatasource.alertRulesComputedDelegate(this.profile.providerInstanceId, this.profile.userId, model as MicrosoftOfficeReportProfileAlertRulesComputedDelegateModel).subscribe(model => {
          this.setAlertProcessed(model);
        }));
      }
      else if (this.isZimbra) {
        this.subscriptions.add(this.zimbraDatasource.alertRulesComputedDelegate(this.profile.providerInstanceId, this.profile.userId, model as ZimbraReportProfileAlertRulesComputedDelegateModel).subscribe(model => {
          this.setAlertProcessed(model);
        }));
      }
    }).catch((error) => {
      console.log(error);
    });

  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  openDetails(event): void {
    const modalRef = this.modalService.open(RulesComputedDelegateDetailsModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.providerInstanceResource = this.providerInstanceResource;
    modalRef.componentInstance.configuration = this.configuration;
    modalRef.componentInstance.delegate = this.delegate;
    modalRef.result.then((model: any) => {
      if (model == true) {
        this.warnClick(null);
      }
    }, (reason) => {
      //Dismiss
    });

  }
}

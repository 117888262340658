import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UserModel, AlertInvalidStateFeaturedModel, MicrosoftOfficeReportProfileModel, SharePointReportProfile } from '@i2a/web-api-client';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ToastrService } from 'ngx-toastr';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { SharePointApplicationConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/share-point-application-configuration';
import { IModuleApplicationContainer } from 'projects/web-user-profile/src/app/models/templates/modules/i-module-application-container';
import { TemplateApplication } from 'projects/web-user-profile/src/app/models/templates/template-application';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { TemplateService } from 'projects/web-user-profile/src/app/services/template.service';
import { ApplicationContainerComponent } from '../../../../shared/application-container/application-container.component';

@RegisterMyProfileComponent(TemplateConstants.MODULE_SHAREPOINT_APPLICATION_CONTAINER)
@Component({
  selector: 'i2a-share-point-application-container',
  templateUrl: './share-point-application-container.component.html',
  styleUrls: ['./share-point-application-container.component.scss']
})
export class SharePointApplicationContainerComponent extends ApplicationContainerComponent
                                                     implements OnInit, OnDestroy, IModuleApplicationContainer<SharePointApplicationConfiguration> {

  public configuration: SharePointApplicationConfiguration;
  public profile: MicrosoftOfficeReportProfileModel;

  constructor(toastr: ToastrService
            , @Inject(I18NEXT_SERVICE) i18Next: ITranslationService
            , templateService: TemplateService) {
    super(toastr, i18Next, templateService);
    this.resourceComponents = [];
    this.modules = [];
  }

  initialize(user: UserModel
           , profile: MicrosoftOfficeReportProfileModel
           , configuration: SharePointApplicationConfiguration
           , alerts: AlertInvalidStateFeaturedModel[]
           , template: TemplateApplication): void {
    super.initialize(user, profile, configuration, alerts, template);
    this.profile = profile;
    this.configuration = <SharePointApplicationConfiguration>configuration;
    this.template = template;  
    this.displayResources = !(this.profile.sharePointSites && this.profile.sharePointSites.length > 0 && this.configuration.groupBySite);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateAlerts(alerts: AlertInvalidStateFeaturedModel[]) {
    super.updateAlerts(alerts);
  }
}
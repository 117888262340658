<i2a-profile-alert-base-modal
  [manualDescriptionKey]="'applyCorrection.providers.microsoft.azureAD.permissionGrants.manual.description'"
  [manualLinkKey]="'applyCorrection.providers.microsoft.azureAD.permissionGrants.manual.link'"
  [automaticDescriptionKey]="'applyCorrection.providers.microsoft.azureAD.permissionGrants.automatic.description'"
  [validation]="validation" [canSend]="canSend" [ignoreCommentToSend]="session.IgnoreCommentToSend"
  [modalTitle]="'profile.confirmationOfInformationTitle' | i18next " [resourceProfile]="resourceProfile">

  <ng-container header-picto>
    <i2a-badge-icon>
      <ng-container icon>
        <fa-layers>
          <fa-icon [icon]="['fal', 'lock']" size="lg"></fa-icon>
        </fa-layers>
      </ng-container>
    </i2a-badge-icon>
  </ng-container>

  <ng-container header-title>
    {{this.grants.client.appDisplayName}}<br>
    {{this.grants.client.appPublisherName}}
  </ng-container>
                            
  <ng-container body>

  </ng-container>

</i2a-profile-alert-base-modal>
import { Component, OnInit, Input } from '@angular/core';
import { ProviderInstanceResourcesDatasourceUrlService, BaseProviderInstanceResourceModel } from '@i2a/web-api-client';

@Component({
  selector: 'i2a-resource-photo',
  templateUrl: './resource-photo.component.html',
  styleUrls: ['./resource-photo.component.scss']
})
export class ResourcePhotoComponent implements OnInit {
  @Input() public size: number;
  @Input() public providerId: string;
  @Input() public providerTypeId: string;
  @Input() public providerInstanceId: string;
  @Input() public providerInstanceResourceId: string;
  @Input() public providerInstanceResource: BaseProviderInstanceResourceModel;
  public resourcePhotoUrl:string;
  
  constructor(
    private providerInstanceResourcesDatasourceUrl: ProviderInstanceResourcesDatasourceUrlService
  ) { }

  ngOnInit() {
    if (this.providerId && this.providerTypeId && this.providerInstanceId && this.providerInstanceResourceId) {
      this.initPhotoUrl(this.providerId, this.providerTypeId, this.providerInstanceId, this.providerInstanceResourceId);
    }
    else if (this.providerInstanceResource)
    {
      this.initPhotoUrl(this.providerInstanceResource.providerId, this.providerInstanceResource.providerTypeId, this.providerInstanceResource.providerInstanceId, this.providerInstanceResource.providerInstanceResourceId);
    }
    else {
      this.resourcePhotoUrl = "/assets/pictos/resource.jpg";
    }
  }

  private initPhotoUrl(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string)
  {
    this.resourcePhotoUrl = this.providerInstanceResourcesDatasourceUrl.PhotoUrl(providerId, providerTypeId, providerInstanceId, providerInstanceResourceId, false, true);
  }
}
import { ChangePasswordModel } from '../models/change-password-model';
import { ConnectedAccountModel } from '../models/connected-account-model';
import { ForgotPasswordModel } from '../models/forgot-password-model';
import { LocalUserAuthenticationCreateModel } from '../models/local-user-authentication-create-model';
import { OAUTH2Response } from '../models/oauth2-response';
import { ResetPasswordModel } from '../models/reset-password-model';
import { UserAuthenticationModel } from '../models/user-authentication-model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatasourceService } from '../../data/datasource.service';

@Injectable({
	providedIn: 'root'
})
export class AuthDatasourceService {

private route: string = "auth";

	constructor(private datasource: DatasourceService) {
		console.log("Init AuthDatasourceService");
	}

	public signOut(throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/sign-out`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public remove(userAuthenticationId: string, throwError: boolean = true): Observable<boolean> {
		let partialUrl = `${this.route}/${userAuthenticationId}`;

		return this.datasource.delete(partialUrl, null, throwError);
	}

	public me(useCache: boolean = false, throwError: boolean = true): Observable<ConnectedAccountModel> {
		let partialUrl = `${this.route}/me`;

		return this.datasource.get<ConnectedAccountModel>(partialUrl, { }, useCache, throwError);
	}

	public authTokenBis(authType: string, grant_type: string, username: string = null, password: string = null, refresh_token: string = null, throwError: boolean = true): Observable<OAUTH2Response> {
		let partialUrl = `${this.route}/provider/${authType}/oauth/token`;

		return this.datasource.post(partialUrl, null, { params: {grant_type: String(grant_type),username: String(username),password: String(password),refresh_token: String(refresh_token) } }, throwError);
	}

	public addLocal(model: LocalUserAuthenticationCreateModel, throwError: boolean = true): Observable<UserAuthenticationModel> {
		let partialUrl = `${this.route}/providers/local/add`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public changePassword(model: ChangePasswordModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/providers/local/change-password`;

		return this.datasource.put(partialUrl, model, { params: { } }, throwError);
	}

	public forgotPassword(model: ForgotPasswordModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/providers/local/forgot-password`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public resetPassword(model: ResetPasswordModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/providers/local/reset-password`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

}

import { Component, OnInit, Input, Inject, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { SharePointReportProfilePermissions, SharePointReportProfileAlertPermissionsModel, MicrosoftOfficeProfileProviderDatasourceService, ProviderInstanceResourceSummaryModel, SharePointReportProfile, AlertInvalidStateFeaturedModel, BaseProviderInstanceResourceModel, OneDriveReportProfile, Constants } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharePointPermissionModalComponent } from '../share-point-permission-modal/share-point-permission-modal.component';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { InformationModalComponent } from 'projects/web-user-profile/src/app/components/shared/modal/information-modal/information-modal.component';
import { SharePointExternalPermissionsDetailComponent } from '../share-point-external-permissions-detail/share-point-external-permissions-detail.component';
import { ActivatedRoute, Router } from '@angular/router';
import { WarningHelper } from 'projects/web-user-profile/src/app/helpers/warning-helper';
import { ProviderHelper } from 'projects/web-user-profile/src/app/helpers/provider-helper';
import { WarningInfoType } from 'projects/web-user-profile/src/app/models/warnings/warning-info-type';
import { SharePointReportProfilePermissionsWithWarnings } from 'projects/web-user-profile/src/app/models/providers/microsoft/share-point/share-point-report-profile-permissions-with-warnings';

@Component({
  selector: 'i2a-share-point-permission',
  templateUrl: './share-point-permission.component.html',
  styleUrls: ['./share-point-permission.component.scss']
})
export class SharePointPermissionComponent extends ProfileItemBaseClassComponent<SharePointReportProfile> implements OnInit, OnDestroy {

  @Input() public permissionsWithWarnings: SharePointReportProfilePermissionsWithWarnings;
  @Input() public warnSensitivityLabel: boolean;

  public permissions: SharePointReportProfilePermissions;

  public title: string;
  public viewMoreDetails: boolean;
  public maxPermission: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    modalService: NgbModal,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
    this.viewMoreDetails = false;
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.permissionsWithWarnings) {
      this.permissions = this.permissionsWithWarnings.model;
      this.warnings = this.permissionsWithWarnings.warnings;
      this.warningLevel = this.permissionsWithWarnings.warnings ? this.permissionsWithWarnings.warnings[0].warningLevel : null;
      this.title = this.permissionsWithWarnings.title;
      this.id = this.permissionsWithWarnings.id;
      this.viewMoreDetails = this.permissions != null && Object.keys(this.permissions.driveItems).length > 0;
      this.subscriptions.add(WarningHelper.openModalOnRoute(this.router, this.route, this.id, this.openDetails.bind(this), this.warnClick.bind(this)));
      this.maxPermission = SharePointExternalPermissionsDetailComponent.GetMaxPermissionLabel(this.permissions.driveItems, this.i18Next);
    }
  }

  setItems() {
    if (this.permissionsWithWarnings.model && this.alerts) {
      super.setItems();
      this.hasAlert = AlertReasonHelper.alertsContainsDelegate(this.alerts, this.permissionsWithWarnings.model, false);
      this.hasAlertRecentlyClosed = AlertReasonHelper.alertsContainsDelegate(this.alerts, this.permissionsWithWarnings.model, true);
    }
  }

  public static getId(componentId: string, providerInstanceResource: BaseProviderInstanceResourceModel, title: string): string {
    return `${componentId}-${providerInstanceResource.providerTypeId}-${providerInstanceResource.providerInstanceResourceId}-${title.replaceAll(' ', '-')}-SharePointPermission`;
  }

  private static getTitle(i18Next: ITranslationService, permissions: SharePointReportProfilePermissions) {
    if (!permissions.isNameKey) {
      return permissions.name;
    }
    else {
      return i18Next.t(permissions.name);
    }
  }

  public static getPermissionsWithWarning(i18Next: ITranslationService, componentId: string, resourceProfile: SharePointReportProfile | OneDriveReportProfile, permissions: SharePointReportProfilePermissions, alerts: AlertInvalidStateFeaturedModel[], warnSensitivityLabel: boolean): SharePointReportProfilePermissionsWithWarnings {
    let title = this.getTitle(i18Next, permissions);
    let permissionsWithWarnings: SharePointReportProfilePermissionsWithWarnings = {
      model: permissions,
      title: title,
      hasAlert: AlertReasonHelper.alertsContainsDelegate(alerts, permissions, false),
      hasAlertRecentlyClosed: AlertReasonHelper.alertsContainsDelegate(alerts, permissions, true),
      id: SharePointPermissionComponent.getId(componentId, resourceProfile.providerInstanceResource, title),
      warnings: null
    }

    if (permissions.isNameKey) {
      let warningInfoType = WarningHelper.GetWarningInfoTypeCaseAccount(permissions.logonUserId);
      let warningLevel = WarningHelper.GetWarningLevel(warningInfoType);
      if (permissions.hasSensitivityLabel && warnSensitivityLabel) {
        if (permissions.isAnonymousUser) {
          warningInfoType = WarningInfoType.anonymousSensitivityLabel;
        } else if (permissions.isTheWholeCompany) {
          warningInfoType = WarningInfoType.companySensitivityLabel;
        } else {
          warningInfoType = WarningInfoType.sensitivityLabel;
        }
        warningLevel = WarningHelper.GetWarningLevel(warningInfoType);
      }
      var providerTypeLabel;
      if (resourceProfile.providerInstanceResource.providerTypeId == Constants.PROVIDER_TYPE_INTRANET) {
        providerTypeLabel = ProviderHelper.GetSharePointProviderTypeLabel(<SharePointReportProfile>resourceProfile, false)
      } else {
        providerTypeLabel = ProviderHelper.GetProviderTypeLabel(resourceProfile.providerInstanceResource.providerId, resourceProfile.providerInstanceResource.providerTypeId);
      }
      permissionsWithWarnings.warnings = [{
        id: permissionsWithWarnings.id,
        resourceId: 'application' + resourceProfile.providerInstanceResource.providerTypeId.toLocaleLowerCase() + resourceProfile.providerInstanceResource.providerInstanceResourceId.toLocaleLowerCase(),
        resourceProfile: resourceProfile,
        warningInfoType: warningInfoType,
        warningLevel: warningLevel,
        label: `${i18Next.t(providerTypeLabel)} > ${(<ProviderInstanceResourceSummaryModel>resourceProfile.providerInstanceResource).name}`,
        hasAlert: permissionsWithWarnings.hasAlert,
        hasAlertRecentlyClosed: permissionsWithWarnings.hasAlertRecentlyClosed,
        warningInfo: SharePointPermissionComponent.GetWarningInfoTypeLabelByType(warningInfoType),
        viewMoreDetails: true,
        viewDissmiss: true,
        viewResource: false
      }];
    }

    return permissionsWithWarnings;
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(SharePointPermissionModalComponent, { windowClass: 'id-modal', centered: true, size: 'lg' });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.permissions = this.permissions;

    modalRef.result.then((model: SharePointReportProfileAlertPermissionsModel) => {
      this.initAlertModel(model);
      model.permissions = this.permissions;
      model.providerInstanceResource = this.providerInstanceResource;
      this.subscriptions.add(this.microsoftDatasource.alertSharePointPermissions(this.profile.providerInstanceId, this.profile.userId, model).subscribe(model => {
        this.setAlertProcessed(model);
      }));
    }).catch((error) => {
      // console.log(error);
    });
  }

  @ViewChild('viewDetailTemplate') viewDetailTemplate: TemplateRef<any>;

  openDetails(event): void {
    const modalRef = this.modalService.open(InformationModalComponent, { windowClass: 'id-modal', centered: true, size: 'lg' });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.title = this.i18Next.t("modal.detail.title");
    modalRef.componentInstance.bodyTemplate = this.viewDetailTemplate;
    modalRef.componentInstance.ok = this.i18Next.t("button.close");
    modalRef.result.then((model: any) => {
      if (model == true) {
        this.warnClick(null);
      }
    }, (reason) => {
      //Dismiss
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public static GetWarningInfoTypeLabelByType(warningInfoType: WarningInfoType): string {
    switch (warningInfoType) {
      case WarningInfoType.anonymousSensitivityLabel:
        return 'warning.info.type.provider.microsoft.sharePoint.anonymousSensitivityLabel';
      case WarningInfoType.companySensitivityLabel:
        return 'warning.info.type.provider.microsoft.sharePoint.companySensitivityLabel';
      case WarningInfoType.sensitivityLabel:
        return 'warning.info.type.provider.microsoft.sharePoint.sensitivityLabel';
      case WarningInfoType.Anonymous:
        return 'warning.info.type.provider.microsoft.sharePoint.anonymousLink';
      case WarningInfoType.Company:
        return 'warning.info.type.provider.microsoft.sharePoint.companyLink';
      case WarningInfoType.CompanyExceptExternal:
        return 'warning.info.type.provider.microsoft.sharePoint.wholeCompanyPermissionExceptExternal';
      case WarningInfoType.ExternalShare:
        return 'warning.info.type.provider.microsoft.sharePoint.externalShare';
      case WarningInfoType.Unknown:
      default:
        return 'warning.info.type.default.unknown';
    }
  }
}

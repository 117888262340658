import { Component, OnInit, Input, Output, EventEmitter, Inject, ViewChild, TemplateRef, SimpleChanges, OnChanges } from '@angular/core';
import { GuidedTourService } from 'projects/web-user-profile/src/app/services/guided-tour.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { TemplateConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/template-configuration';
import { ReportProfileResource, Constants } from '@i2a/web-api-client';
import { ProviderHelper } from 'projects/web-user-profile/src/app/helpers/provider-helper';
import { ConfirmModalComponent } from '../../../shared/modal/confirm-modal/confirm-modal.component';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-profile-validation',
  templateUrl: './profile-validation.component.html',
  styleUrls: ['./profile-validation.component.scss']
})
export class ProfileValidationComponent implements OnInit {
  @Input() public endDate: Date;
  @Input() public beginDate: Date;
  @Input() public alertIsSent: boolean;
  @Input() public configuration: TemplateConfiguration;
  @Input() public questionKey: string;
  @Input() public resources: ReportProfileResource[];
  @Input() public userActivityIsValid: boolean;
  @Input() public displayValidationButton: boolean;

  @Output() onValidate = new EventEmitter();

  @ViewChild('confirmationTemplate') confirmationTemplate: TemplateRef<any>;

  public get invalidResources(): any[] {
    if (this.resources) {
      return this.resources.filter(r => !r.isValid);
    }
    else {
      return [];
    }
  }

  constructor(
    private modalService: NgbModal,
    private guidedTour: GuidedTourService,
    public session: SessionService,
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService) {
    this.alertIsSent = false;
  }

  ngOnInit() {

  }

  getProviderTypeLabel(resource: ReportProfileResource): string {
    return ProviderHelper.GetProviderTypeLabel(resource.providerInstanceResource.providerId, resource.providerInstanceResource.providerTypeId);
  }

  send() {
    const modalRef = this.modalService.open(ConfirmModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.title = this.i18Next.t("modal.profileValidation.title");
    modalRef.componentInstance.bodyTemplate = this.confirmationTemplate;
    modalRef.componentInstance.cancel = this.i18Next.t("button.cancel");
    modalRef.componentInstance.ok = this.i18Next.t("button.ok");
    modalRef.componentInstance.buttonOkClass = !this.userActivityIsValid || this.invalidResources.length > 0 ? "btn-warn" : "btn-success";
    modalRef.result.then((model: any) => {
      this.onValidate.emit();
    }, (reason) => {
      //Dismiss
    });
  }

  getProviderTypeName(providerTypeId: string): string {
    switch (providerTypeId) {
      case Constants.PROVIDER_TYPE_MAILING:
        return "mailing";
      case Constants.PROVIDER_TYPE_PERSONAL_STORAGE:
        return "personalStorage";
      case Constants.PROVIDER_TYPE_INTRANET:
        return "intranet";
      case Constants.PROVIDER_TYPE_GENERIC:
        return "generic";

      case Constants.PROVIDER_TYPE_FILE_SHARE:
        return "fileShare";

      default:
        break;
    }
  }

  help() {
    this.guidedTour.startProfileTour(false);
  }

  collapseAll() {
    this.session.IsCollapsedAll = !this.session.IsCollapsedAll;
  }
}

<div class="module">
  
  <div  *ngIf="isVisible" class="container-fluid module-container">

    <div class="row no-gutters">
      <i2a-sub-title 
        class="col-12"
        [subTitle]="subTitle"
        [description]="description"
        [customClass]="customClass">
      </i2a-sub-title>
    </div>
    
    <div class="row no-gutters">
    
      <ng-container *ngIf="configuration.displayVisibility && ((!session.SharePointGroupBySite) || (site && session.SharePointGroupBySite))">
        
        <div class="col-sm-12 col-md-6 col-lg-4 align-items-start"  *ngFor="let o365Group of resourceProfile.o365Groups">
        
          <i2a-share-point-visibility 
            [alerts]="alerts" 
            [componentId]="componentId" 
            [configuration]="configuration" 
            [group]="o365Group" 
            [isAlert]="isAlert"
            [isGroupBySite]="site && session.SharePointGroupBySite"
            [resources]="resources"
            [profile]="profile" 
            [providerInstanceResource]="resourceProfile.providerInstanceResource" 
            [relatedFarmSummaryItemId]="resourceProfile.relatedFarmSummaryItemId" 
            [resourceProfile]="resourceProfile" 
            [user]="user"
            (onAlertSent)="alertSent($event)" 
            (onHasWarnings)="hasWarnings($event)">
          </i2a-share-point-visibility>
        
        </div>
      
      </ng-container>

      <ng-container *ngIf="(!session.SharePointGroupBySite) || (site && session.SharePointGroupBySite)">
        
        <div *ngFor="let o365Group of resourceProfile.o365Groups" class="col-sm-12 col-md-6 col-lg-4 align-items-start">
          
          <i2a-share-point-o365-group 
            [alerts]="alerts" 
            [componentId]="componentId" 
            [deduplicateOwnerMembers]="configuration.deduplicateOwnerMembers"
            [disableCheckAllOwnersWithOwnersFiltered]="configuration.disableCheckAllOwnersWithOwnersFiltered"
            [displaySecurity]="configuration.displaySecurity"
            [doNotDeleteCurrentUserFromSiteOwnerGroup] ="configuration.doNotDeleteCurrentUserFromSiteOwnerGroup"
            [group]="o365Group" 
            [isAlert]="isAlert"
            [isGroupBySite]="site && session.SharePointGroupBySite"
            [resources]="resources"
            [permissions]="resourceProfile.permissions" 
            [profile]="profile" 
            [providerInstanceResource]="resourceProfile.providerInstanceResource" 
            [resourceProfile]="resourceProfile" 
            [user]="user"
            (onAlertSent)="alertSent($event)" 
            (onHasWarnings)="hasWarnings($event)">
          </i2a-share-point-o365-group>
          
        </div>
        
      </ng-container>
      
      <ng-container *ngIf="((!session.SharePointGroupBySite) || (!site && session.SharePointGroupBySite))">
        
        <div *ngFor="let group of SPGroups" class="col-sm-12 col-md-6 col-lg-4 align-items-start">
          
          <i2a-share-point-group 
            [alerts]="alerts"
            [componentId]="componentId"
            [disableCheckAllWithOwnersFiltered]="configuration.disableCheckAllOwnersWithOwnersFiltered"
            [displaySharepPointGroupOwner]="configuration.displaySharepPointGroupOwner" 
            [doNotDeleteCurrentUserFromSiteOwnerGroup] ="configuration.doNotDeleteCurrentUserFromSiteOwnerGroup"
            [group]="group" 
            [isAlert]="isAlert" 
            [profile]="profile" 
            [permissions]="resourceProfile.permissions"
            [providerInstanceResource]="resourceProfile.providerInstanceResource"
            [resourceProfile]="resourceProfile" 
            [user]="user" 
            (onAlertSent)="alertSent($event)" 
            (onHasWarnings)="hasWarnings($event)">
          </i2a-share-point-group>
          
        </div>
        
      </ng-container>
      
      <div class="col-sm-12 col-md-6 col-lg-4 align-items-start" *ngIf="configuration.displayAdministrators && ((!session.SharePointGroupBySite) || (site && session.SharePointGroupBySite))">
        
        <i2a-share-point-administrators 
          *ngIf="resourceProfile.administrators.length > 0" 
          [administrators]="resourceProfile.administrators"
          [alerts]="alerts" 
          [componentId]="componentId"
          [isAlert]="isAlert" 
          [isGroupBySite]="site && session.SharePointGroupBySite"
          [resources]="resources"
          [profile]="profile" 
          [providerInstanceResource]="resourceProfile.providerInstanceResource"
          [resourceProfile]="resourceProfile" 
          [user]="user" 
          (onAlertSent)="alertSent($event)" 
          (onHasWarnings)="hasWarnings($event)">
        </i2a-share-point-administrators>
        
      </div>
      
    </div>

  </div>

</div>
<i2a-sub-title *ngIf="delegates && delegates.length > 0"
  [subTitle]="'profile.provider.microsoft.fileShare.title.delegateAccess' | i18next "></i2a-sub-title>
<div class="container-fluid p-0">
  <div class="row no-gutters">
    <ng-container *ngIf="delegates && delegates.length > 0">
      <div *ngFor="let delegate of delegates" class="col-sm-12 col-md-6 col-lg-4 align-items-start">
        <!-- DELEGATES -->
        <i2a-file-share-delegate [resourceProfile]="resourceProfile" [componentId]="componentId" [isAlert]="isAlert"
          [providerInstanceResource]="resourceProfile.providerInstanceResource" [profile]="profile" [user]="user"
          [delegate]="delegate" (onAlertSent)="alertSent($event)" [alerts]="alerts" (onHasWarnings)="hasWarnings($event)">
        </i2a-file-share-delegate>
      </div>
    </ng-container>
  </div>
</div>

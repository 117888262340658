import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { ReportProfileDeviceSyncModel, ExchangeReportProfile } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { DeviceWithAlert } from 'projects/web-user-profile/src/app/models/users/device-with-alert';
import { DeviceService } from 'projects/web-user-profile/src/app/services/device.service';

@Component({
  selector: 'i2a-exchange-user-device',
  templateUrl: './exchange-user-device.component.html',
  styleUrls: ['./exchange-user-device.component.scss']
})
export class ExchangeUserDeviceComponent extends ProfileItemBaseClassComponent<ExchangeReportProfile> implements OnInit, OnDestroy {
  @Input() public devices: ReportProfileDeviceSyncModel[];

  public itemsNotRegistered: DeviceWithAlert[];

  constructor(
    modalService: NgbModal,
    private deviceService: DeviceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  setItems() {
    if (this.devices && this.alerts) {
      super.setItems();

      this.itemsNotRegistered = this.deviceService.mapUnRegisteredDevices(this.alerts, this.devices);

      this.hasAlert = false;
      this.hasAlertRecentlyClosed = false;
      this.itemsNotRegistered.forEach(item => {
        this.hasAlert = this.hasAlert || item.hasAlert;
        this.hasAlertRecentlyClosed = this.hasAlertRecentlyClosed || item.hasAlertRecentlyClosed;
      });

      this.id = `${this.componentId}-${this.providerInstanceResource.providerTypeId}-${this.providerInstanceResource.providerInstanceResourceId}-ExchangeUserDevice`;
    }
  }

  warnClick(event): void {
    this.deviceService.warnClickUserDevices(this.resourceProfile, null, this.profile, null, this.itemsNotRegistered, this.initAlertModel.bind(this), this.setAlertsProcessed.bind(this));
  }
}

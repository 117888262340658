<a *ngIf="resource" [id]="'application' + resource.providerTypeId + resource.providerInstanceResourceId"></a>
<div [ngClass]="{'resource-container': true, 'd-none': hideResource }" class="resource-container">
  <i2a-title-bar 
    [className]="className" 
    [guidedTourKey]="guidedTourKey" 
    [id]="id" 
    [isCollapsed]="isCollapsed"
    [linked]="true" 
    [rounded]="false">
    <ng-container picture>
      <img  *ngIf="providerPhotoUrl && !isSharePointGroupBySiteView" 
            [ngClass]="{'id-provider-picture': true, 'pending-resource-action': hasDeleteAlert || hasNotBelongToMe}" 
            [src]="providerPhotoUrl" 
            width="28" 
            height="28">            
      <i2a-badge-icon *ngIf="isSharePointGroupBySiteView" [customClass]="'id-badge-share-point-list groupby-site-list-icon-size ' + providerClass">
        <ng-container icon>
          <fa-icon [icon]="groupBySiteicon" size="lg"></fa-icon>
        </ng-container>
      </i2a-badge-icon>
    </ng-container>

    <ng-container title>
      <i2a-spinner *ngIf="!resource" [light]="true"></i2a-spinner>
      <ng-container *ngIf="resource && providerTypeLabel">
        <span [ngClass]="{'provider-type': !isSharePointGroupBySiteView, 'provider-type-groupby-site': isSharePointGroupBySiteView}" [title]="hint">{{ providerTypeLabel }}</span>
      </ng-container>
    </ng-container>

    <ng-container description>
      <div *ngIf="resource && notSharepoint" [title]="resource.name">
        {{resource.name}}
      </div>
    </ng-container>

    <ng-container buttons>
      <div *ngIf="configuration.viewAlerts && alerts && alerts.length > 0"
        class="float-right pr-2 id-collapse my-auto id-button" [title]="'profile.openAlertHint' | i18next "
        (click)="showOpenAlerts()">
        <fa-icon [icon]="['far', 'shield-alt']" style="font-size: 1.5rem"></fa-icon>
      </div>

      <div *ngIf="debug && connectedAccount.isSupport && configuration.downloadOnePage && !hasDeleteAlert && !hasNotBelongToMe && !hasDeleteAlertRecentlyClosed && !hasNotBelongToMeRecentlyClosed"
        class="float-right pr-2 id-collapse my-1 id-button" [title]="'profile.downloadOnePage' | i18next "
        (click)="downloadOnePage()">
        <fa-icon [icon]="['fal', 'file-pdf']" size="2x"></fa-icon>
      </div>

      <div *ngIf="debug && connectedAccount.isSupport" class="float-right pr-2 id-collapse my-1 id-button"
        [title]="'profile.downloadAudit' | i18next " (click)="downloadAudit()">
        <fa-icon [icon]="['fal', 'file-excel']" size="2x"></fa-icon>
      </div>
    </ng-container>

    <ng-container body>
      <ng-content select="[content]"></ng-content>
      <template i2a-container></template>
      <i2a-resource-actions *ngIf="(hasDeleteAlert || hasNotBelongToMe || hasDeleteAlertRecentlyClosed || hasNotBelongToMeRecentlyClosed) && resourceProfile" 
                            [alerts]="alerts"
                            [commentResource]="commentResource" 
                            [componentId]="resourceActionComponentId"
                            [deleteResource]="false"
                            [profile]="profile" 
                            [openSite]="openSite" 
                            [resource]="resource"
                            [resourceNotBelongToMe]="false" 
                            [resourceProfile]="resourceProfile"
                            [siteUrl]="resourceProfile.siteUrl" 
                            [user]="user" 
                            (onAlertSent)="alertSent($event)" 
                            (onHasWarnings)="hasWarnings($event)">
      </i2a-resource-actions>

      <div class="container no-gutters delete-information">
        <div class="row">
          <div class="col pt-3" *ngIf="hasDeleteAlert || hasDeleteAlertRecentlyClosed">
            <ul class="id-information">
              <li>
                <fa-layers [fixedWidth]="true">
                  <fa-icon [icon]="['fal', 'trash-alt']" size="lg" [fixedWidth]="true"></fa-icon>
                </fa-layers>
                <span
                  class="id-information-label">{{ 'deleteResource.informationTitle' | i18next : { creationDate : deleteAlertDate } }}</span>
              </li>
              <li *ngIf="!hasDeleteAlertFailed">
                <fa-layers [fixedWidth]="true">
                  <fa-icon class="pr-2" [icon]="['fal', 'info-square']" size="lg" [fixedWidth]="true"></fa-icon>
                </fa-layers>
                <span *ngIf="hasDeleteAlert" class="id-information-label">{{ 'deleteResource.pending' | i18next }}</span>
                <span *ngIf="hasDeleteAlertRecentlyClosed" class="id-information-label">{{ 'deleteResource.done' | i18next }}</span>
              </li>
              <li *ngIf="hasDeleteAlertFailed" class="id-warning">
                <fa-layers [fixedWidth]="true">
                  <fa-icon class="pr-2" [icon]="['fal', 'exclamation-triangle']" size="lg" [fixedWidth]="true">
                  </fa-icon>
                </fa-layers>
                <span class="id-information-label">{{ 'deleteResource.failed' | i18next }}</span>
              </li>
            </ul>
          </div>
          <div class="col pt-3" *ngIf="hasNotBelongToMe || hasNotBelongToMeRecentlyClosed">
            <ul class="id-information">
              <li>
                <fa-layers [fixedWidth]="true">
                  <fa-icon [icon]="['fal', 'user-cog']" size="lg" [fixedWidth]="true"></fa-icon>
                </fa-layers>
                <span
                  class="id-information-label">{{ 'resourceNotBelongToMe.informationTitle' | i18next : { creationDate :  hasNotBelongToMeDate } }}</span>
              </li>
              <li *ngIf="!hasDeleteAlertFailed">
                <fa-layers [fixedWidth]="true">
                  <fa-icon class="pr-2" [icon]="['fal', 'info-square']" size="lg" [fixedWidth]="true"></fa-icon>
                </fa-layers>
                <span *ngIf="hasNotBelongToMe" class="id-information-label">{{ 'resourceNotBelongToMe.pending' | i18next }}</span>
                <span *ngIf="hasNotBelongToMeRecentlyClosed" class="id-information-label">{{ 'resourceNotBelongToMe.done' | i18next }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>
  </i2a-title-bar>
</div>
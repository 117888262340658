import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { ReportProfileResource } from '@i2a/web-api-client';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ProviderHelper } from 'projects/web-user-profile/src/app/helpers/provider-helper';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'i2a-profile-validated',
  templateUrl: './profile-validated.component.html',
  styleUrls: ['./profile-validated.component.scss']
})
export class ProfileValidatedComponent implements OnInit {
  @Input() public resources: ReportProfileResource[];
  @Input() public userActivityIsValid: boolean;
  
  public get invalidResources(): any[] { 
    if (this.resources) { 
      return this.resources.filter(r => !r.isValid); 
    } 
    else { 
      return []; 
    } 
  } 

  constructor(
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService) {
  }

  ngOnInit() {
  }

  public back() {
    window.location.reload(); //Change userActivityIsValid to false the subscribe to event failed after validation!! so we use for now window.location.reload()
  }

  getProviderTypeLabel(resource: ReportProfileResource): string {
    return ProviderHelper.GetProviderTypeLabel(resource.providerInstanceResource.providerId, resource.providerInstanceResource.providerTypeId);
  }

}

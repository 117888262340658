import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'i2a-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss']
})
export class InputCheckboxComponent implements OnInit, OnChanges {
  @Input() public model: any;
  @Input() public valueChecked: any;
  @Input() public valueUnChecked: any;
  @Output() modelChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public className: string;
  @Input() public classNameChecked: string;

  @Input() public disabled: boolean;

  public checkboxClasses: string;

  public get isChecked(): boolean {
    return typeof this.model != 'undefined' && this.model == this.valueChecked;
  }
  public set isChecked(value: boolean) {
    this.model = value ? this.valueChecked : this.valueUnChecked;
    this.modelChange.emit(this.model);

    this.setClassName();
  }


  private setClassName() {
    if (this.className) {
      this.checkboxClasses = `${this.isChecked ? this.classNameChecked : this.className} btn-checkbox`;
    }
    else {
      this.checkboxClasses = "btn-checkbox";
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setClassName();
  }


  constructor() {
    this.disabled = false;
  }

  ngOnInit() {
    if (this.model !== undefined && this.valueChecked !== undefined) {
      this.isChecked = this.model == this.valueChecked;
    }
  }


}

<div class="module">
  <div class="container-fluid module-container" *ngIf="delegates && delegates.length > 0">
    <div class="row no-gutters">
      <i2a-sub-title class="col-12" *ngIf="delegates && delegates.length > 0 && configuration.displayTitle"
        [subTitle]="'profile.title.rulesComptedDelegates' | i18next "
        [description]="'profile.description.rulesComptedDelegates' | i18next "></i2a-sub-title>
    </div>
    <div class="row no-gutters">
      <!-- DELEGATES -->
      <div *ngFor="let delegate of delegates" class="col-sm-12 col-md-6 col-lg-4 align-items-start">
        <i2a-rules-computed-delegate [resourceProfile]="resourceProfile" [componentId]="componentId" [configuration]="configuration" [isAlert]="isAlert"
          [alerts]="alerts" [providerInstanceResource]="resourceProfile.providerInstanceResource" [profile]="profile"
          [user]="user" [delegate]="delegate" (onAlertSent)="alertSent($event)" (onHasWarnings)="hasWarnings($event)">
        </i2a-rules-computed-delegate>
      </div>

      <!-- DEVICES -->
      <div *ngIf="configuration.displayUserRegisteredDevices && devices.length > 0"
        class="col-sm-12 col-md-6 col-lg-4 align-items-start">
        <i2a-rules-computed-delegate-user-device [resourceProfile]="resourceProfile" [componentId]="componentId" [profile]="profile" [user]="user"
          [alerts]="alerts" [isAlert]="isAlert" [providerInstanceResource]="resourceProfile.providerInstanceResource"
          [devices]="profile.userProfile.devices" (onAlertSent)="alertSent($event)" (onHasWarnings)="hasWarnings($event)">
        </i2a-rules-computed-delegate-user-device>
      </div>

    </div>
  </div>
</div>
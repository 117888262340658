<i2a-page-content [hasPadding]="false" [headerStyle]="headerStyle">
    <ng-container navbar>
        <i2a-nav-bar></i2a-nav-bar>
    </ng-container>
    <ng-container content-body>
        <div class="container-fuild p-1">
            <div class="row id-content justify-content-center no-gutters">
                <div class="col-12 id-profile">
                    <i2a-instance-switcher [userId]="userId" [providerInstanceId]="providerInstanceId" ></i2a-instance-switcher>
                    <div *ngIf="providerInstance && user">
                        <div *ngIf="hasAdminRole()" class="col-12 text-center viewAsUser">
                            <span *ngIf="userView">
                                <fa-icon [icon]="['fas', 'eye']" size="2x" style=" vertical-align: middle;"></fa-icon>
                                {{'profile.viewAsUserTitle' | i18next}}
                            </span>
                        </div>
                        <i2a-microsoft-profile *ngIf="isMicrosoft" 
                                                [user]="user" 
                                                [providerInstance]="providerInstance"
                                                [userView]=userView>
                        </i2a-microsoft-profile>
                        <i2a-zimbra-profile *ngIf="isZimbra" 
                                            [user]="user" 
                                            [providerInstance]="providerInstance"
                                            [userView]=userView>
                        </i2a-zimbra-profile>
                    </div>
                    <i2a-spinner *ngIf="!providerInstance"></i2a-spinner>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </ng-container>
</i2a-page-content>
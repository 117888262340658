import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SharePointReportProfileAlertPermissionsModel } from '@i2a/web-api-client';

@Component({
  selector: 'i2a-share-point-permission-form',
  templateUrl: './share-point-permission-form.component.html',
  styleUrls: ['./share-point-permission-form.component.scss']
})
export class SharePointPermissionFormComponent implements OnInit {
  @Input() public allPathsTitle: string;
  @Input() public validation: SharePointReportProfileAlertPermissionsModel;
  @Input() public driveItemsPermissions: any[];//TODO CRADO
  @Input() public pathCollapsed: boolean = false;

  @Output() public setCanSend: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  constructor() { }

  ngOnInit() {
  }

  //#region paths

  checkedPath(isChecked: boolean, driveItem: any) {
    const index: number = this.validation.invalidPaths.indexOf(driveItem.path);
    if (isChecked && index === -1) {
      this.validation.invalidPaths.push(driveItem.path);
    }
    else if (!isChecked && index !== -1) {
      this.validation.invalidPaths.splice(index, 1);
    }
    if (this.driveItemsPermissions.length == this.validation.invalidPaths.length) {
      this.allPathsChecked(true);
      this.validation.allPaths = true;
    }
    this.setCanSend.emit();
  }

  allPathsChecked(isChecked: boolean) {
    if (isChecked) {
      this.driveItemsPermissions.forEach(f => f.checked = false);
      this.validation.invalidPaths = [];
    }
    this.setCanSend.emit();
  }

  //#endregion

}

import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, ViewChild, Inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { InformationModalComponent } from 'projects/web-user-profile/src/app/components/shared/modal/information-modal/information-modal.component';
import { SharePointReportProfilePermissionsWithAlert } from 'projects/web-user-profile/src/app/models/providers/microsoft/share-point/share-point-report-profile-permissions-with-alert';
import { SharePointReportProfilePermissionDetail, SharePointReportProfileDriveItemRole, ReportProfileResourceBase } from '@i2a/web-api-client';
import { WarningLevel } from 'projects/web-user-profile/src/app/models/warnings/warning-level';
import { WarningInfoType } from 'projects/web-user-profile/src/app/models/warnings/warning-info-type';
import { WarningHelper } from 'projects/web-user-profile/src/app/helpers/warning-helper';

@Component({
  selector: 'i2a-share-point-external-permissions-detail',
  templateUrl: './share-point-external-permissions-detail.component.html',
  styleUrls: ['./share-point-external-permissions-detail.component.scss']
})
export class SharePointExternalPermissionsDetailComponent implements OnInit {
  @Input() public title: string;
  @Input() public subTitle: string;
  @Input() public hasAlert: boolean;
  @Input() public hasAlertRecentlyClosed: boolean;
  @Input() public isAlert: boolean;
  @Input() public delegates: SharePointReportProfilePermissionsWithAlert[];
  @Input() public resourceProfile: ReportProfileResourceBase<any>;
  @Input() public warningLevel: WarningLevel;
  @Input() public warnSensitivityLabel: boolean

  @Output() public onWarnClick = new EventEmitter();

  public isCollapsed: boolean;
  public hasSensitivityLabel: boolean;
  
  constructor(
    private modalService: NgbModal,
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService) {
    this.isCollapsed = true;
  }

  ngOnInit() {
    if (this.delegates) {
      this.hasSensitivityLabel = this.delegates.some(d=> d.hasSensitivityLabel);
      if (this.hasSensitivityLabel && this.warnSensitivityLabel) {
        let warningInfoType = WarningInfoType.sensitivityLabel;
        this.warningLevel = WarningHelper.GetWarningLevel(warningInfoType);
      }
  
    }
  }

  public static GetMaxPermissionLabel(driveItems: { [key: string]: SharePointReportProfilePermissionDetail }, i18Next: ITranslationService): string {
    var result = SharePointReportProfileDriveItemRole.Unknown;
    if (driveItems) {
      Object.keys(driveItems).forEach(key => {
        if ((driveItems[key].rights & SharePointReportProfileDriveItemRole.Owner) > result) {
          result = SharePointReportProfileDriveItemRole.Owner;
        }
        else if ((driveItems[key].rights & SharePointReportProfileDriveItemRole.Write) > result) {
          result = SharePointReportProfileDriveItemRole.Write;
        }
        else if ((driveItems[key].rights & SharePointReportProfileDriveItemRole.Read) > result) {
          result = SharePointReportProfileDriveItemRole.Read;
        }
      });
    }
    return i18Next.t("profile.provider.microsoft.sharePoint.maxPermission", {"permission": `SharePointReportProfileDriveItemRole.${SharePointReportProfileDriveItemRole[result]}`}) ;
  }

  public maxPermission(delegate: SharePointReportProfilePermissionsWithAlert): string {
    return SharePointExternalPermissionsDetailComponent.GetMaxPermissionLabel(delegate.driveItems, this.i18Next);
  }


  @ViewChild('viewDetailBodyTemplate') viewDetailBodyTemplate: TemplateRef<any>;

  @ViewChild('viewDetailButtonsTemplate') viewDetailButtonsTemplate: TemplateRef<any>;

  openDetails(event): void {
    const modalRef = this.modalService.open(InformationModalComponent, { windowClass: 'id-modal', centered: true, size: 'lg' });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.title = this.i18Next.t("profile.provider.microsoft.oneDrive.external");
    modalRef.componentInstance.activateFullScreen = true;
    modalRef.componentInstance.bodyTemplate = this.viewDetailBodyTemplate;
    modalRef.componentInstance.buttonsTemplate = this.viewDetailButtonsTemplate;
    modalRef.componentInstance.ok = this.i18Next.t("button.close");
    modalRef.result.then((model: any) => {
      if (model == true) {
        this.warnClick(null);
      }
    }, (reason) => {
      //Dismiss
    });
  }

  expandAllAccounts(event): void {
    this.isCollapsed = !this.isCollapsed;
  }

  warnClick(event): void {
    this.onWarnClick.emit(event);
  }

}

<i2a-share-point-actions  [alerts]="alerts" 
                          [componentId]="componentId"
                          [configuration]="configuration" 
                          [profile]="profile" 
                          [resource]="resource" 
                          [resourceProfile]="resourceProfile" 
                          [user]="user"
                          (onAlertSent)="alertSent($event)" 
                          (onHasWarnings)="hasWarnings($event)">
</i2a-share-point-actions>

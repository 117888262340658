<div *ngIf="profile && !hasNoData" class="origin">
    <i2a-profile-card [id]="id" [viewMoreDetails]="false" [title]="'profile.user.origin.title' | i18next"
        (onWarnClick)="warnClick($event)" [hasAlertRecentlyClosed]="hasAlertRecentlyClosed"
        [warningLevel]="warningLevel">

        <ng-container picto>
            <i2a-badge-icon [customClass]="'id-bagde-origins'">
                <ng-container icon>
                    <fa-icon [icon]="['fal', 'map-marker-alt']" size="lg"></fa-icon>
                </ng-container>
            </i2a-badge-icon>
        </ng-container>
        <ng-container content>
            <span *ngIf="items && items.length > 0"
                class="location-name ml-2">{{ 'profile.user.origin.recent' | i18next}}</span>
            <div *ngIf="items && items.length > 0" class="container p-0">
                <div class="row no-gutters">
                    <div class="col ml-2 mr-2">
                        <table *ngIf="items" class="table table-sm id-table table-orgiin">
                            <tbody>
                                <tr *ngFor="let item of items">
                                    <td class="align-text-top isSucceeded" *ngIf="configuration.displayIsSucceeded">
                                        <fa-icon class="failed" [icon]="['fas', 'times-circle']"
                                            [title]="'profile.user.origin.failed' | i18next" size="lg"
                                            *ngIf="!item.isSucceeded">
                                        </fa-icon>
                                        <fa-icon class="succeeded" [icon]="['fas', 'check-circle']"
                                            [title]="'profile.user.origin.succeeded' | i18next" size="lg"
                                            *ngIf="item.isSucceeded">
                                        </fa-icon>
                                    </td>

                                    <td scope="row" [ngClass]="{'has-alert': item.hasAlert, 'has-alert-recently-closed': item.hasAlertRecentlyClosed}">
                                        <div class="id-item-background align-text-bottom mr-1"
                                            [ngStyle]="{'background-image': 'url(' + item.image + ')'}">
                                        </div>
                                        {{item.name}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div *ngIf="usualItems && usualItems.length > 0" class="container p-0">
                <div class="row no-gutters">
                    <div class="col ml-2 mr-2">
                        <span class="location-name float-left">{{ 'profile.user.origin.usual' | i18next}}</span>
                        <span *ngIf="displayExpirationDateTitle"
                            class="last-action-date float-right">{{ 'profile.user.origin.expirationDate' | i18next}}</span>
                        <table *ngIf="items" class="table table-sm id-table table-origin mb-1">
                            <tbody>
                                <tr *ngFor="let item of usualItems">
                                    <td scope="row" [ngClass]="{'has-alert': item.hasAlert, 'has-alert-recently-closed': item.hasAlertRecentlyClosed}">
                                        <div class="id-item-background align-text-bottom mr-1"
                                            [ngStyle]="{'background-image': 'url(' + item.image + ')'}">
                                        </div>
                                        {{item.name}} <span class="float-right">{{item.expiration}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-container>
    </i2a-profile-card>
</div>
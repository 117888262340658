import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Inject, InjectionToken } from "@angular/core"
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { I2A_DISPLAY_ERROR_MESSAGE } from '../../web-core.module';


@Injectable()
export class ToasterHttpErrorInterceptor implements HttpInterceptor {
    constructor(
        public toastr: ToastrService,
        @Inject(I18NEXT_SERVICE) private translateI18Next: ITranslationService,
        @Inject(I2A_DISPLAY_ERROR_MESSAGE) private displayErrorMessage: boolean ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    try {
                        this.showMessage(err);
                    } catch (e) {
                        this.toastr.error('An error occured', '');
                    }
                }
                return  throwError(err);
            }));
    }

    public showMessage(error: HttpErrorResponse) {
        let errorMessage = "";
        let displayError = true;

        if (error.status === 404) {
            displayError = false;
            console.log("Ajax error 404 not displayed ");
            return;
        }
        else if (error.status === 0) {
            errorMessage = "You are offline! Please check your network.";
            console.log("Ajax error => " + errorMessage);
            this.toastr.error(errorMessage, "Error");
            return;
        }
        else if (error.status === 401) {
            errorMessage = this.translateI18Next.t('apiErrors.UNAUTHORIZED');
        }
        else if (error.status === 403) {
            errorMessage = this.translateI18Next.t('apiErrors.FORBIDDEN');
        }
        else {
            errorMessage = this.getErrorResponseTranslatedMsg(error);
        }

        if (displayError) {
            console.log("Ajax error => " + errorMessage);
            this.toastr.error(errorMessage, this.translateI18Next.t('notifications.ERROR'));
        }
        else {
            console.log("Ajax error not displayed=> " + errorMessage);
        }
    }

    private getErrorResponseTranslatedMsg(error) {
        let errorMessage = this.translateI18Next.t('apiErrors.GENERIC');

        if (error.error && error.error.ExceptionMessage) {
            if (this.displayErrorMessage){
                errorMessage += ` ( ${error.error.ExceptionMessage} )`;
            }
            if (error.error.FuncData && error.error.FuncData.FuncError_Code) {
                let translationKey = `apiErrors.${error.error.FuncData.FuncError_Code}`;
                let translation = this.translateI18Next.t(translationKey);

                if (translation !== translationKey) { //key is the fallback in case the translation do not exists.

                    if (error.error.FuncData.FuncError_Parameters) {
                        let array = JSON.parse(error.error.FuncData.FuncError_Parameters);
                        let translationVars = {};
                        for (var i = 0; i < array.length; i++) {
                            translationVars[i] = array[i];
                        }
                        translation = this.translateI18Next.t(translationKey, translationVars);
                    }

                    errorMessage = translation;
                }
            }
        }

        return errorMessage;
    };

}
import { FileShareReportProfilePermissionRights } from '../../../microsoft/fileshare/profile/file-share-report-profile-permission-rights';
import { ReportProfileDelegateAccess } from '../../../profile/report-profile-delegate-access';

export class FileShareReportProfilePermissions extends ReportProfileDelegateAccess {
  fileShareGroupOrUserId: string;
  isDomain: boolean|null;
  serverOrDomain: string;
  isGroup: boolean|null;
  rights: FileShareReportProfilePermissionRights[];
  hasSensitivityLabel: boolean;
}

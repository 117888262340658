import { ChangeResult } from '../change-result';
import { ReportProfileAccessModeModel } from '../profile/report-profile-access-mode-model';
import { ReportProfileDeviceModel } from '../profile/report-profile-device-model';
import { ReportProfileDeviceSyncModel } from '../profile/report-profile-device-sync-model';
import { ReportProfileOriginByProderTypeModel } from '../profile/report-profile-origin-by-proder-type-model';
import { ReportProfileUsualCountriesModel } from '../profile/report-profile-usual-countries-model';

export class ReportProfileUserChangesModel {
  accessModes: ChangeResult<ReportProfileAccessModeModel>;
  origins: ChangeResult<ReportProfileOriginByProderTypeModel>;
  usualCountries: ChangeResult<ReportProfileUsualCountriesModel>;
  devices: ChangeResult<ReportProfileDeviceModel>;
  notRegisteredDevices: ChangeResult<ReportProfileDeviceSyncModel>;
  isEqual: boolean;
}


export class FunctionalExceptionModel {
  exceptionType: string;
  exceptionMessage: string;
  stackTrace: string;
  funcData: { [key: string]: string };
  innerException: FunctionalExceptionModel;
  message: string;
  data: { [key: string]: string };
}

<div class="module">
  <div class="container-fluid module-container" *ngIf="delegatesWithoutAccess.length > 0">
    <div class="row no-gutters">
      <i2a-sub-title class="col-12" [subTitle]="'profile.provider.zimbra.mailing.title.permissions' | i18next "
        [description]="'profile.provider.zimbra.mailing.description.permissions' | i18next "></i2a-sub-title>
    </div>
    <div class="row no-gutters">

      <div *ngFor="let permissions of delegatesWithoutAccess | slice:0:maxShowMore" class="col-sm-12 col-md-6 col-lg-4 align-items-start">

        <i2a-zimbra-mailing-permission [resourceProfile]="resourceProfile" [componentId]="componentId"
          [isAlert]="isAlert" [alerts]="alerts" [providerInstanceResource]="resourceProfile.providerInstanceResource"
          [profile]="profile" [user]="user" [permissionsWithWarnings]="permissions" (onAlertSent)="alertSent($event)"
          (onHasWarnings)="hasWarnings($event)">
        </i2a-zimbra-mailing-permission>

      </div>

      <div class="col-12 p-2 text-center" *ngIf="delegatesWithoutAccess?.length > showMoreAfter">
        <button class="btn btn-outline-primary" (click)="onShowMore()" *ngIf="!showMore">{{ 'button.showMore' | i18next }}</button>
        <button class="btn btn-outline-primary" (click)="onShowMore()" *ngIf="showMore">{{ 'button.showLess' | i18next }}</button>
      </div>

    </div>
  </div>
</div>
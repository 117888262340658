import { Component, OnInit, Input, Inject } from '@angular/core';
import { UserReportProfileAlertAccessModesModel, ReportProfileAccessModeModel, UserModel } from '@i2a/web-api-client';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-access-mode-modal',
  templateUrl: './access-mode-modal.component.html',
  styleUrls: ['./access-mode-modal.component.scss']
})
export class AccessModeModalComponent implements OnInit {
  @Input() public accessModes: ReportProfileAccessModeModel[];
  @Input() public user: UserModel;
  @Input() public disableManualRemediation:boolean;
  
  public validation: UserReportProfileAlertAccessModesModel;
  public canSend: boolean;

  public items: any[];

  constructor(
    @Inject(I18NEXT_SERVICE) private translateI18Next: ITranslationService,
    public session: SessionService
  ) {
    this.canSend = false;
  }

  ngOnInit() {
    this.validation = new UserReportProfileAlertAccessModesModel();
    this.validation.invalidAccessModes = [];
    this.items = [];
    if (this.accessModes) {
      this.accessModes.forEach(accessMode => {
        this.items.push({
          "name": accessMode.accessMode,
          "label": this.translateI18Next.t(`common.accessMode.${accessMode.accessMode}`),
          "checked": this.accessModes.length == 1
        });
      });
      this.items.sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }));
      this.setCanSend();
    }
  }

  private setCanSend() {
    setTimeout(() => {
      this.canSend = this.validation.invalidAccessModes.length > 0;
    });
  }

  checked(isChecked: boolean, accessMode: any) {
    const index: number = this.validation.invalidAccessModes.indexOf(accessMode.name);
    if (isChecked && index === -1) {
      this.validation.invalidAccessModes.push(accessMode.name);
    }
    else if (!isChecked && index !== -1) {
      this.validation.invalidAccessModes.splice(index, 1);
    }

    this.setCanSend();
  }
}

<div class="profile-page">

  <div class="id-validation-header">

    <div class="id-photo">
      <i2a-user-photo *ngIf="profile && profile.userProfile" [userId]="profile.userProfile.userId" [size]="120">
      </i2a-user-photo>
    </div>

    <div class="col-12 id-title">
      <span class="id-validation-title" *ngIf="user && connectedAccount">
        {{connectedAccount.userId == user.id ? ('profile.title.myProfile' | i18next ) : ('profile.title.userProfile' | i18next : { user: user.fullName} )}}
      </span>
    </div>

    <div class="col-12 text-center id-sub-title">
      <span class="id-validation-subtitle">
        {{template.configuration.subTitleKey | i18next : { beginDate: beginDate , endDate: endDate } }}
      </span>
    </div>

  </div>

  <div class="sub-header" *ngIf="applications && applications.length > 0">
    <div class="container no-gutters">
      <div *ngIf="template">
        <ul class="navigation">
          
          <ng-container *ngFor="let application of applications">

            <li *ngIf="application.hideOnTop && !isSticky || !application.hideOnTop">
              <a class="btn" *ngIf="!application.isDropdown" [routerLink]="['.']" [fragment]="application.id" [queryParams]="session && session.UserView?{userView:session.UserView}:{}" queryParamsHandling="merge">
                <img *ngIf="application.url" height="28" width="28" [src]="application.url">
                <fa-icon [class]="application.iconClasses" *ngIf="application.icon" size="lg" [icon]="application.icon"></fa-icon>
                <span class="text-nowrap">{{application.label | i18next}}</span>
                <i2a-nav-bar-warning-counter *ngIf="!application.hideOnTop && session.WarningConfiguration && !session.WarningConfiguration.hideMenuCounters && warningshighlighting" [warningshighlighting]="warningshighlighting" [application]="application"></i2a-nav-bar-warning-counter>
              </a>

              <a class="btn menu-dropdown-mobile" *ngIf="application.isDropdown" [routerLink]="['.']" [fragment]="application.id" [queryParams]="session && session.UserView?{userView:session.UserView}:{}" queryParamsHandling="merge">
                <img *ngIf="application.url" height="28" width="28" [src]="application.url">
                <fa-icon [class]="application.iconClasses" *ngIf="application.icon" size="lg" [icon]="application.icon"></fa-icon>
                <span class="text-nowrap">{{application.label | i18next}}</span>
                <i2a-nav-bar-warning-counter *ngIf="session.WarningConfiguration && !session.WarningConfiguration.hideMenuCounters  && warningshighlighting" [warningshighlighting]="warningshighlighting" [application]="application"></i2a-nav-bar-warning-counter>
              </a>

              <div *ngIf="application.isDropdown" ngbDropdown class="d-inline-block menu-dropdown">
                <a class="btn" id="dropdownMenu" ngbDropdownToggle><img *ngIf="application.url" height="28" width="28" [src]="application.url">
                  <fa-icon [class]="application.iconClasses" *ngIf="application.icon" size="lg" [icon]="application.icon"></fa-icon>
                  {{application.label | i18next: { count: application.items.length } }}
                  <i2a-nav-bar-warning-counter *ngIf="session.WarningConfiguration && !session.WarningConfiguration.hideMenuCounters  && warningshighlighting" [warningshighlighting]="warningshighlighting" [application]="application"></i2a-nav-bar-warning-counter>
                </a>
                <div ngbDropdownMenu aria-labelledby="dropdownMenu" class="menu-dropdowm-items">
                  <a *ngFor="let item of application.items" ngbDropdownItem [routerLink]="['.']" [fragment]="item.id" [queryParams]="session && session.UserView?{userView:session.UserView}:{}" queryParamsHandling="merge">
                    <img *ngIf="item.url" height="28" width="28" [src]="item.url">
                    <fa-icon [class]="item.iconClasses" *ngIf="item.icon" size="lg" [icon]="item.icon"></fa-icon>
                    <span>{{item.label}}</span>
                    <i2a-nav-bar-warning-counter *ngIf="session.WarningConfiguration && !session.WarningConfiguration.hideMenuCounters  && warningshighlighting" [warningshighlighting]="warningshighlighting" [application]="application" [item]="item"></i2a-nav-bar-warning-counter>
                  </a>
                </div>
              </div>
            </li>
            
          </ng-container>

          <li *ngIf="debug && connectedAccount.isSupport">
            <a class="btn" (click)="downloadAudit()">
              <fa-icon class="mr-1" size="lg" [icon]="['fal', 'download']"></fa-icon>
              <span class="text-nowrap">{{'profile.export' | i18next}}</span>
            </a>
          </li>

          <li *ngIf="template.configuration.helpMenuEnabled" class="align-to-the-right">
            <a class="btn" [ngStyle]="getHelpMenuStyle()" [href]="template.configuration.helpMenuHRef" target="_blank">
              <img [src]="helpMenuIcon" height="28px" width="28px" *ngIf="!isDefaultIcon" />
              <fa-icon class="mr-1" size="lg" [icon]="['fas', 'question']" *ngIf="isDefaultIcon"></fa-icon>
              <span class="text-nowrap">{{template.configuration.helpMenuLabelKey | i18next}}</span>
            </a>
          </li>

        </ul>
      </div>
    </div>
  </div>

  <ng-container *ngIf="profileIsValidated">

    <i2a-profile-information [lastValidationDate]="lastValidationDate" *ngIf="lastValidationDate">
    </i2a-profile-information>

    <i2a-profile-validated [resources]="resources" [userActivityIsValid]="userActivityIsValid">
    </i2a-profile-validated>

  </ng-container>

  <ng-container *ngIf="!profileIsValidated">

    <i2a-profile-validation [questionKey]="'profile.validationQuestionTop'" [displayValidationButton]="false"
      [beginDate]="profile.beginDate" [endDate]="profile.endDate" [alertIsSent]="alertIsSent"
      [configuration]="configuration" [resources]="resources" [userActivityIsValid]="userActivityIsValid">
    </i2a-profile-validation>

    <i2a-profile-information [lastValidationDate]="lastValidationDate" *ngIf="lastValidationDate">
    </i2a-profile-information>

    <div class="id-profile-content" id="profileContent" [attr.data-guidedTourKey]="configuration.informationGuidedTourKey">
      <ng-content select="[profile]"></ng-content>
    </div>

    <i2a-profile-information [lastValidationDate]="lastValidationDate" *ngIf="lastValidationDate">
    </i2a-profile-information>

    <div class="id-profile-end">
      <i2a-profile-validation [questionKey]="'profile.validationQuestionBottom'" [displayValidationButton]="true"
        [beginDate]="profile.beginDate" [endDate]="profile.endDate" [alertIsSent]="alertIsSent"
        [configuration]="configuration" [resources]="resources" [userActivityIsValid]="userActivityIsValid">
      </i2a-profile-validation>
    </div>
    
  </ng-container>
</div>

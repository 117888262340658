import { AlertInvalidStateFeaturedModel } from '../alert-invalid-state-featured-model';
import { ProviderInstanceResourceModel } from '../provider-instance-resource-model';
import { ReportProfileUser } from '../profile/report-profile-user';
import { ReportProfileUserChangesModel } from '../profile/report-profile-user-changes-model';

export class ReportProfileBaseModel {
  adminObjectId: string;
  profileIsUpdated: boolean;
  lastValidationDate: Date|null;
  providerAdminObjectId: string;
  userId: string;
  userChanges: ReportProfileUserChangesModel;
  userProfile: ReportProfileUser;
  providerId: string;
  providerInstanceId: string;
  providerInstanceResources: ProviderInstanceResourceModel[];
  beginDate: Date;
  endDate: Date;
  alerts: AlertInvalidStateFeaturedModel[];
}

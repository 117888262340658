import { Component, OnInit, Input, OnDestroy, Inject, HostBinding } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserModel, ReportProfileDeviceModel, ReportProfileDeviceSyncModel, ReportProfileBaseModel, AlertInvalidStateFeaturedModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { ProfileBaseClassComponent } from '../../shared/profile-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { DeviceWithAlert } from 'projects/web-user-profile/src/app/models/users/device-with-alert';
import { DeviceConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/device-configuration';
import { DeviceService } from 'projects/web-user-profile/src/app/services/device.service';

@RegisterMyProfileComponent(TemplateConstants.MODULE_USER_DEVICE)
@Component({
  selector: 'i2a-device',
  host: { "class": "col-sm-12 col-md-6 col-lg-4 align-items-start" },
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent extends ProfileBaseClassComponent<DeviceConfiguration, ReportProfileResourceBase<any>> implements OnInit, OnDestroy, IModule<DeviceConfiguration> {
  @Input() public devices: ReportProfileDeviceModel[];
  @Input() public notRegisteredDevices: ReportProfileDeviceSyncModel[];
  @Input() public user: UserModel;

  @HostBinding('class.d-none')
  public isHide: Boolean = false;

  public items: DeviceWithAlert[];
  public itemsNotRegistered: DeviceWithAlert[];

  constructor(
    private deviceService: DeviceService,
    modalService: NgbModal,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: DeviceConfiguration, alerts: AlertInvalidStateFeaturedModel[]): void {
    super.baseInitialize(user, profile, configuration, alerts);
    this.configuration = configuration;
    this.devices = profile.userProfile.devices;
    this.notRegisteredDevices = profile.userProfile.notRegisteredDevices;
  }

  setIsAlert(isAlert: boolean) {
    this.isAlert = isAlert;
  }

  setItems() {
    if (this.devices && this.alerts) {
      super.setItems();

      this.items = this.deviceService.mapRegisteredDevices(this.alerts, this.devices.filter(d => !this.configuration.filterIsActive || this.configuration.filterIsActive && d.isActive));

      this.items.forEach(item => {
        this.hasAlert = this.hasAlert || item.hasAlert;
      })

      if (this.notRegisteredDevices && this.configuration.displayUnregisteredDevices) {
        this.itemsNotRegistered = this.deviceService.mapUnRegisteredDevices(this.alerts, this.notRegisteredDevices);
        this.items.forEach(item => {
          this.hasAlert = this.hasAlert || item.hasAlert;
          this.hasAlertRecentlyClosed = this.hasAlertRecentlyClosed || item.hasAlertRecentlyClosed;
        })
      }
      else {
        this.itemsNotRegistered = [];
      }

      this.hasNoData = this.itemsNotRegistered.length == 0 && this.items.length == 0;
      this.onHasNoData.emit(this.hasNoData);
      this.isHide = this.hasNoData;

      this.id = `${this.componentId}-${this.user.id}-Device`;
    }

  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  warnClick(event): void {
    this.deviceService.warnClickUserDevices(this.resourceProfile, this.user, this.profile, this.items, this.itemsNotRegistered, this.initAlertModel.bind(this), this.setAlertsProcessed.bind(this));
  }
}
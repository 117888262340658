<table class="table table-sm table-bordered id-table" *ngIf="paths.length > 0">
  <thead>
    <tr>
      <th scope="col" class="w-100">
        {{ "profile.provider.microsoft.sharePoint.driveItem.item" | i18next }}
      </th>
      <th scope="col">
        {{ "profile.provider.microsoft.sharePoint.driveItem.permissions" | i18next }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let path of paths" [ngClass]="{'sensitivity-label': driveItems[path].hasSensitivityLabel}">
      <td scope="row">

        <ng-container *ngIf="path === rootPathValue">
          {{ "profile.provider.microsoft.sharePoint.driveItem.rootPathLabel" | i18next }}
        </ng-container>

        <ng-container *ngIf="path !== rootPathValue">
          {{path}}
        </ng-container>
        
        <span *ngIf="driveItems[path].hasSensitivityLabel"
          class="pl-1" 
          [title]="'profile.provider.microsoft.sharePoint.driveItem.sensitivityLabel' | i18next : { label: driveItems[path].sensitivityLabel} ">
          <fa-icon [icon]="['far', 'lock-alt']"></fa-icon>
        </span>
      
      </td>
      <td class="text-nowrap">
        <i2a-share-point-permissions-roles [roles]="driveItems[path].rights"></i2a-share-point-permissions-roles>
      </td>
    </tr>
  </tbody>
</table>
<i2a-share-point-permission-legend *ngIf="paths.length > 0 && displayLegend" 
  [displayReadLegend]="displayReadLegend"
  [displayUpdateLegend]="displayUpdateLegend" 
  [displayOwnerLegend]="displayOwnerLegend"
  [displayUnknownLegend]="displayUnknownLegend" 
  [hasSensitivityLabel]="hasSensitivityLabel">
</i2a-share-point-permission-legend>
import { Component, OnInit, Input, OnDestroy, Inject, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExchangeDelegateModalComponent } from '../exchange-delegate-modal/exchange-delegate-modal.component';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { MicrosoftOfficeProfileProviderDatasourceService, ProviderAuditResourceKind, ExchangeReportProfileAlertDelegateModel, ExchangeReportProfile } from '@i2a/web-api-client';
import { ExchangeDelegateConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/exchange-delegate-configuration';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { InformationModalComponent } from 'projects/web-user-profile/src/app/components/shared/modal/information-modal/information-modal.component';
import { ExchangeDelegate } from 'projects/web-user-profile/src/app/models/providers/microsoft/exchange/exchange-delegate';

@Component({
  selector: 'i2a-exchange-delegate',
  templateUrl: './exchange-delegate.component.html',
  styleUrls: ['./exchange-delegate.component.scss']
})
export class ExchangeDelegateComponent extends ProfileItemBaseClassComponent<ExchangeReportProfile> implements OnInit, OnDestroy {
  @Input() public configuration: ExchangeDelegateConfiguration;
  @Input() public delegate: ExchangeDelegate;

  public IsMailboxAccessed: boolean;
  public IsFoldersAccessed: boolean;
  public IsCalendarAccessed: boolean;
  public viewMoreDetails: boolean;

  constructor(
    modalService: NgbModal,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
    this.viewMoreDetails = false;
  }

  setItems() {
    if (this.delegate && this.alerts) {
      super.setItems();
      this.IsMailboxAccessed = this.hasFlag(this.delegate.accesses.resourceKind, ProviderAuditResourceKind.Inbox)
        || this.hasFlag(this.delegate.accesses.resourceKind, ProviderAuditResourceKind.Outbox)
        || this.hasFlag(this.delegate.accesses.resourceKind, ProviderAuditResourceKind.Trash);

      this.IsCalendarAccessed = this.hasFlag(this.delegate.accesses.resourceKind, ProviderAuditResourceKind.Calendar);

      this.IsFoldersAccessed = this.hasFlag(this.delegate.accesses.resourceKind, ProviderAuditResourceKind.ShareableContent)
        || this.hasFlag(this.delegate.accesses.resourceKind, ProviderAuditResourceKind.Technical)
        || this.hasFlag(this.delegate.accesses.resourceKind, ProviderAuditResourceKind.Contact)
        || this.hasFlag(this.delegate.accesses.resourceKind, ProviderAuditResourceKind.Unknown)
        || this.hasFlag(this.delegate.accesses.resourceKind, ProviderAuditResourceKind.NULL);

      this.hasAlert = this.hasAlertInAlerts(false);
      this.hasAlertRecentlyClosed = this.hasAlertInAlerts(true);

      this.viewMoreDetails = this.delegate.permissions != null && Object.keys(this.delegate.permissions.foldersRights).length > 0

      this.id = `${this.componentId}-${this.providerInstanceResource.providerTypeId}-${this.providerInstanceResource.providerInstanceResourceId}-${this.delegate.accesses.name.replaceAll(' ', '-')}-ExchangeDelegate`;
    }
  }

  private hasAlertInAlerts(done: boolean): boolean {
    return AlertReasonHelper.alertsContainsDelegate(this.alerts, this.delegate.accesses, done);
  }

  private hasFlag(resourceKind: ProviderAuditResourceKind, flag: ProviderAuditResourceKind): boolean {
    return (resourceKind & flag) == flag;
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(ExchangeDelegateModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.delegate = this.delegate;
    modalRef.componentInstance.IsCalendarAccessed = this.IsCalendarAccessed;
    modalRef.componentInstance.IsFoldersAccessed = this.IsFoldersAccessed;
    modalRef.componentInstance.IsMailboxAccessed = this.IsMailboxAccessed;
    modalRef.result.then((model: ExchangeReportProfileAlertDelegateModel) => {
      this.initAlertModel(model);
      model.providerInstanceResource = this.providerInstanceResource;
      if (model.permissions != null) {
        model.permissions.endDate = this.profile.endDate;
        model.permissions.providerInstanceResource = this.providerInstanceResource;
        model.permissions.comments = model.comments;
      }
      this.subscriptions.add(this.microsoftDatasource.alertExchangeDelegate(this.profile.providerInstanceId, this.profile.userId, model).subscribe(model => {
        this.setAlertsProcessed(model);
      }));
    }).catch((error) => {
      // console.log(error);
    });

  }


  @ViewChild('viewDetailTemplate') viewDetailTemplate: TemplateRef<any>;

  openDetails(event): void {
    const modalRef = this.modalService.open(InformationModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.title = this.i18Next.t("modal.detail.title");
    modalRef.componentInstance.bodyTemplate = this.viewDetailTemplate;
    modalRef.componentInstance.ok = this.i18Next.t("button.close");
    modalRef.result.then((model: any) => {
      if (model == true) {
        this.warnClick(null);
      }
    }, (reason) => {
      //Dismiss
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

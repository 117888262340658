import { Component, OnInit, ViewContainerRef, ViewChild, OnDestroy, ComponentRef, Inject, Input } from '@angular/core';
import { TemplateService } from 'projects/web-user-profile/src/app/services/template.service';
import { ReportProfileBaseModel, UserModel, ConnectedAccountModel, Constants, AlertInvalidStateFeaturedModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { IBaseModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-base-module';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { ProfileBaseClassComponent } from '../../shared/profile-base-class-component';
import { IModuleUserContainer } from 'projects/web-user-profile/src/app/models/templates/modules/i-module-user-container';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/user-configuration';
import { ContainerDirective } from 'projects/web-user-profile/src/app/directives/container-directive';

@RegisterMyProfileComponent(TemplateConstants.MODULE_USER_CONTAINER)
@Component({
  selector: 'i2a-user-container-container',
  templateUrl: './user-container.component.html',
  styleUrls: ['./user-container.component.scss']
})
export class UserContainerComponent extends ProfileBaseClassComponent<UserConfiguration, ReportProfileResourceBase<any>> implements OnInit, OnDestroy, IModuleUserContainer<UserConfiguration> {
  private components: ComponentRef<any>[];
  private modules: IBaseModule[];

  public template: BaseTemplate;
  public guidedTourKey: string;
  public alertIsSent: boolean;
  public hasNoData: boolean;
  private childHasNoDataNumber: number;
  private childHasNoData: boolean;

  public isCollapsed: boolean = false;

  @ViewChild(ContainerDirective, { static: true }) container: ContainerDirective;

  public displayUser: boolean;

  constructor(
    modalService: NgbModal,
    private session: SessionService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService,
    private templateService: TemplateService) {
    super(toastr, i18Next, modalService);
    this.components = [];
    this.modules = [];
    this.alertReasons.push("alert.invalidState.profile.common.reasons.device");
    this.alertReasons.push("alert.invalidState.profile.user.reasons.devices");
    this.alertReasons.push("alert.invalidState.profile.user.reasons.usualCountries");
    this.alertReasons.push("alert.invalidState.profile.user.reasons.accessModes");
    this.alertReasons.push("alert.invalidState.profile.user.reasons.origins");
    this.childHasNoDataNumber = 0;
    this.childHasNoData = true;
    this.hasNoData = false;
  }

  public connectedAccount: ConnectedAccountModel;

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: UserConfiguration, alerts: AlertInvalidStateFeaturedModel[], template: BaseTemplate): void {
    super.baseInitialize(user, profile, configuration, alerts);
    this.template = template;
    this.guidedTourKey = configuration.guidedTourKey;
    if (configuration.isCollapsed !== null && configuration.isCollapsed !== undefined) {
      this.isCollapsed = configuration.isCollapsed;
    }
  }

  setIsAlert(isAlert: boolean) {
    super.setIsAlert(isAlert);

    this.modules.forEach(m => {
      m.setIsAlert(isAlert);
    })
  }

  ngOnInit() {
    super.ngOnInit();

    this.addSubscriptionConnectedAccount();
    
    if (this.user && this.profile && this.template) {
      this.template.modules.forEach(module => {
        if (module.isEnabled) {
          var component = this.templateService.loadComponent<IModule<any>>(this.container.viewContainerRef, module.id);
          if (component) {
            this.components.push(<ComponentRef<any>>component);
            this.modules.push(component.instance);
            component.instance.initialize(this.user, this.profile, module.configuration, this.alerts);
            this.subscriptions.add(component.instance.onAlertSent.subscribe(e => this.alertSent(e)));
            this.subscriptions.add(component.instance.onHasWarnings.subscribe(e => this.hasWarnings(e)));
            this.subscriptions.add(component.instance.onHasNoData.subscribe(e => this.childHasNoDataEvent(e)));
          }
        }
      });

      this.addSubsciptionCollapsedAll();
    }
  }

  private addSubscriptionConnectedAccount(): void {
    this.subscriptions.add(this.session.Account$.subscribe((account) => {
        this.connectedAccount = account;
    }));
  }

  private addSubsciptionCollapsedAll(): void {
    this.subscriptions.add(
      this.session.isCollapsedAll$.subscribe(isCollapsedAll => {
        if (isCollapsedAll !== null && isCollapsedAll !== undefined) {
          this.isCollapsed = isCollapsedAll;
        }
      })
    );
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }

  hasWarnings(event): void {
    setTimeout(() => {
      this.isCollapsed = false; 
    });
    this.onHasWarnings.emit(event);    
  }

  childHasNoDataEvent(hasNoData): void {
    this.childHasNoDataNumber++;
    this.childHasNoData = this.childHasNoData && hasNoData;
    if (this.childHasNoDataNumber == this.template.modules.filter(m => m.isEnabled).length) {
      setTimeout(() => {
        this.hasNoData = this.childHasNoData;
      });
    }
  }

  updateAlerts(alerts: AlertInvalidStateFeaturedModel[]) {
    super.updateAlerts(alerts);
    this.components.forEach(c => c.instance.updateAlerts(alerts));
  }
}

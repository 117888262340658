import { Component, OnInit, Input, Inject, OnDestroy, HostBinding } from '@angular/core';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';
import { ProfileBaseClassComponent } from '../../shared/profile-base-class-component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportProfileOriginModel, UserModel, Constants, MicrosoftOfficeProfileProviderDatasourceService, ZimbraProfileProviderDatasourceService, ReportProfileBaseModel, AlertInvalidStateFeaturedModel, UserReportProfileAlertOriginsModel, ReportProfileOriginByProderTypeModel, AlertConstants, AddressModel, ReportProfileUsualCountriesModel, ReportProfileResourceBase, ProviderAdminObjectThreatReasonModel } from '@i2a/web-api-client';
import { OriginModalComponent } from '../origin-modal/origin-modal.component';
import { Observable } from 'rxjs';
import { StringHelper } from 'projects/web-user-profile/src/app/helpers/string-helper';
import { ToastrService } from 'ngx-toastr';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { OriginWithAlert } from 'projects/web-user-profile/src/app/models/users/origin-with-alert';
import { OriginConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/origin-configuration';
import * as _ from "lodash";
import { OriginLevel } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/origin-level';
import { UsualCountryWithAlert } from 'projects/web-user-profile/src/app/models/users/usual-country-with-alert';
import dayJS from 'dayjs';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { ReportProfileOriginWithIsSucceededModel } from 'projects/web-user-profile/src/app/models/users/report-profile-origin-with-issucceeded-model';

@RegisterMyProfileComponent(TemplateConstants.MODULE_USER_ORIGIN)
@Component({
  selector: 'i2a-origin',
  host: { "class": "col-sm-12 col-md-6 col-lg-4 align-items-start" },
  templateUrl: './origin.component.html',
  styleUrls: ['./origin.component.scss']
})
export class OriginComponent extends ProfileBaseClassComponent<OriginConfiguration, ReportProfileResourceBase<any>> implements OnInit, OnDestroy, IModule<OriginConfiguration> {
  @Input() public origins: ReportProfileOriginByProderTypeModel[];
  @Input() public user: UserModel;

  @HostBinding('class.d-none')
  public isHide: Boolean = false;

  public items: OriginWithAlert[];
  public usualItems: UsualCountryWithAlert[];
  public displayExpirationDateTitle: boolean;


  constructor(
    modalService: NgbModal,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    private zimbraDatasource: ZimbraProfileProviderDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
    this.displayExpirationDateTitle = true;
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: OriginConfiguration, alerts: AlertInvalidStateFeaturedModel[]): void {
    super.baseInitialize(user, profile, configuration, alerts)
    this.origins = profile.userProfile.origins;
    this.configuration = configuration;
  }

  setItems() {
    if (this.origins && this.alerts && this.configuration) {

      super.setItems();
      let originsUsualCountryInAlert: ReportProfileUsualCountriesModel[] = [];
      let originsCountryInAlert: AddressModel[] = [];
      let originsCStateInAlert: AddressModel[] = [];
      let originsCityInAlert: AddressModel[] = [];
      let originsOthersInAlert: ReportProfileOriginModel[] = [];

      let originsUsualCountryInAlertClosed: ReportProfileUsualCountriesModel[] = [];
      let originsCountryInAlertClosed: AddressModel[] = [];
      let originsCStateInAlertClosed: AddressModel[] = [];
      let originsCityInAlertClosed: AddressModel[] = [];
      let originsOthersInAlertClosed: ReportProfileOriginModel[] = [];

      this.alerts.forEach(a => {
        var reasonsCountry = AlertReasonHelper.findReasons(a, AlertConstants.ALERT_INVALID_STATE_CONTEXT_USER_RECENT_ORIGINS_LEVEL, OriginLevel.Country.toString());
        reasonsCountry.forEach(r => {
          let addresses = AlertReasonHelper.GetObject<AddressModel[]>(r.reasonContext, AlertConstants.ALERT_INVALID_STATE_CONTEXT_USER_RECENT_ORIGINS_ADDRESSES);
          if (addresses != null) {
            addresses.forEach(address => {
              if (a.end == null) {
                originsCountryInAlert.push(address);
              } else {
                originsCountryInAlertClosed.push(address);
              }
            });
          }
        });

        var reasonsState = AlertReasonHelper.findReasons(a, AlertConstants.ALERT_INVALID_STATE_CONTEXT_USER_RECENT_ORIGINS_LEVEL, OriginLevel.State.toString());
        reasonsState.forEach(r => {
          let address = AlertReasonHelper.GetObject<AddressModel>(r.reasonContext, AlertConstants.ALERT_INVALID_STATE_CONTEXT_USER_RECENT_ORIGINS_ADDRESSES);
          if (address != null) {
            if (a.end == null) {
              originsCStateInAlert.push(address);
            } else {
              originsCStateInAlertClosed.push(address);
            }
          }
        });

        var reasonsCity = AlertReasonHelper.findReasons(a, AlertConstants.ALERT_INVALID_STATE_CONTEXT_USER_RECENT_ORIGINS_LEVEL, OriginLevel.City.toString());
        reasonsCity.forEach(r => {
          let address = AlertReasonHelper.GetObject<AddressModel>(r.reasonContext, AlertConstants.ALERT_INVALID_STATE_CONTEXT_USER_RECENT_ORIGINS_ADDRESSES);
          if (address != null) {
            if (a.end == null) {
              originsCityInAlert.push(address);
            } else {
              originsCityInAlertClosed.push(address);
            }
          }
        });

        a.reasons.forEach(r => {
          let index = r.reasonContext.findIndex((c) => c.key == AlertConstants.ALERT_INVALID_STATE_CONTEXT_USER_RECENT_ORIGINS_OTHERS);
          if (index >= 0) {
            let addresses = AlertReasonHelper.GetObject<ReportProfileOriginModel[]>(r.reasonContext, AlertConstants.ALERT_INVALID_STATE_CONTEXT_USER_RECENT_ORIGINS_OTHERS);
            if (addresses != null) {
              addresses.forEach(address => {
                if (a.end == null) {
                  originsOthersInAlert.push(address)
                } else {
                  originsOthersInAlertClosed.push(address)
                }
              });
            }
          }
        });

        a.reasons.forEach(r => {
          let index = r.reasonContext.findIndex((c) => c.key == AlertConstants.ALERT_INVALID_STATE_CONTEXT_USER_USUAL_ORIGINS_ADDRESSES);
          if (index >= 0) {
            let addresses = AlertReasonHelper.GetObject<ReportProfileUsualCountriesModel[]>(r.reasonContext, AlertConstants.ALERT_INVALID_STATE_CONTEXT_USER_USUAL_ORIGINS_ADDRESSES);
            if (addresses != null) {
              addresses.forEach(address => {
                if (address.expiration == null || dayJS(address.expiration).isAfter(dayJS())) {
                  if (a.end == null) {
                    originsUsualCountryInAlert.push(address)
                  } else {
                    originsUsualCountryInAlertClosed.push(address)
                  }
                }
              });
            }
          }
        });

      });

      this.origins = this.configuration.displayIsSucceeded ? this.origins : this.origins.filter(o => o.isSucceeded);

      let originsByProviderTypeFiltered = this.origins;
      if (this.configuration.providerTypeToHide && this.configuration.providerTypeToHide.length > 0) {
        originsByProviderTypeFiltered = this.origins.filter(p1 => this.configuration.providerTypeToHide && this.configuration.providerTypeToHide.length > 0
          && this.configuration.providerTypeToHide.find(p2 => p1.providerTypeId.localeCompare(p2, undefined, { sensitivity: 'base' }) == 0) == undefined);
      }

      let originsFiltered = _.flatMap(originsByProviderTypeFiltered.map(o => {
        var origins = <ReportProfileOriginWithIsSucceededModel[]>_.flatMap(o.origins);
        origins.forEach(origin => {
          origin.isSucceeded = o.isSucceeded;
        });
        return origins;
      }));
      originsFiltered = originsFiltered.filter(
        o => {
          return this.configuration.providerToHide.find(p2 => o.providerName.localeCompare(p2, undefined, { sensitivity: 'base' }) == 0) == undefined &&
            (!this.configuration.usualCountries.isEnabled ||
              this.configuration.usualCountries.isEnabled &&
              this.profile.userProfile.usualCountries.find(p2 => o.address != null && p2.countryCode != null
                && o.address.country != null && o.address.country.localeCompare(p2.countryCode, undefined, { sensitivity: 'base' }) == 0) == undefined)
        }
      );

      this.items = _.uniqBy(originsFiltered.map(origin => {
        let item = {
          image: `/assets/pictos/countries/${(origin.address == null || origin.address.country == null ? "unknown" : origin.address.country)}.png`,
          name: this.getName(origin),
          model: origin,
          hasAlert: this.getHasAlert(origin, originsOthersInAlert, originsCountryInAlert, originsUsualCountryInAlert, originsCStateInAlert, originsCityInAlert),
          hasAlertRecentlyClosed: this.getHasAlert(origin, originsOthersInAlertClosed, originsCountryInAlertClosed, originsUsualCountryInAlertClosed, originsCStateInAlertClosed, originsCityInAlertClosed),
          isSucceeded: origin.isSucceeded
        };
        this.hasAlert = this.hasAlert || item.hasAlert;
        this.hasAlertRecentlyClosed = this.hasAlertRecentlyClosed || item.hasAlertRecentlyClosed;
        return item;
      }), o => `${o.name}${o.isSucceeded}`);

      this.items.sort((a, b) => {
        var countryA = a.model.address == null || a.model.address.country == null ? "" : a.model.address.country;
        var countryB = b.model.address == null || b.model.address.country == null ? "" : b.model.address.country;
        var result = countryA.localeCompare(countryB, undefined, { sensitivity: 'base' });
        if (result == 0) {
          var cityA = a.model.originLabel == null ? "" : a.model.originLabel;
          var cityB = b.model.originLabel == null ? "" : b.model.originLabel;
          result = cityA.localeCompare(cityB, undefined, { sensitivity: 'base' });
        }
        return result;
      });

      if (this.configuration && this.configuration.usualCountries.isEnabled && this.profile && this.profile.userProfile.usualCountries) {
        this.usualItems = this.profile.userProfile.usualCountries.map(usualCountry => {
          let item = {
            image: `/assets/pictos/countries/${usualCountry.countryCode}.png`,
            name: usualCountry.countryCode == null ? this.i18Next.t("country.undeterminable") : this.i18Next.t(`country.${usualCountry.countryCode}`),
            model: usualCountry,
            hasAlert: originsCountryInAlert.findIndex((a) => a.country == usualCountry.countryCode) >= 0
              || originsUsualCountryInAlert.findIndex((a) => a.countryCode == usualCountry.countryCode) >= 0,
            hasAlertRecentlyClosed: originsCountryInAlertClosed.findIndex((a) => a.country == usualCountry.countryCode) >= 0
              || originsUsualCountryInAlertClosed.findIndex((a) => a.countryCode == usualCountry.countryCode) >= 0,
            expiration: usualCountry.expiration ? dayJS(usualCountry.expiration).format('L') : ""
          };
          this.hasAlert = this.hasAlert || item.hasAlert;
          this.hasAlertRecentlyClosed = this.hasAlertRecentlyClosed || item.hasAlertRecentlyClosed;
          return item;
        }).filter(c => !this.configuration.usualCountries.displayNotLocated ? c.model.countryCode != null : true).sort((a, b) => {
          if (a.model.countryCode == null) {
            return -1;
          }
          else {
            return a.name.localeCompare(b.name, undefined, { sensitivity: 'base' });
          }
        });

        this.displayExpirationDateTitle = this.usualItems.findIndex(u => !StringHelper.isNullOrWhitespace(u.model.expiration)) >= 0;
      }

      this.id = `${this.componentId}-${this.user.id}-Origin`;
    }
    this.hasNoData = this.items.length == 0 && this.usualItems.length == 0;
    this.onHasNoData.emit(this.hasNoData);
    this.isHide = this.hasNoData;
  }

  getHasAlert(origin: ReportProfileOriginModel, originsOthersInAlert: ReportProfileOriginModel[], originsCountryInAlert: AddressModel[], originsUsualCountryInAlert: ReportProfileUsualCountriesModel[], originsCStateInAlert: AddressModel[], originsCityInAlert: AddressModel[]): boolean {
    if (origin.address == null) {
      return originsOthersInAlert.findIndex(c => c.displayName == origin.displayName) >= 0;
    } else if (originsCountryInAlert.findIndex(c => c.country == origin.address.country) >= 0) {
      return true;
    } else if (originsUsualCountryInAlert.findIndex(c => c.countryCode == origin.address.country) >= 0) {
      return true;
    }
    else if ((this.configuration.level == OriginLevel.State || this.configuration.level == OriginLevel.City) &&
      originsCStateInAlert.findIndex(s => s.country == origin.address.country && s.state == origin.address.state) >= 0) {
      return true;
    } else if (this.configuration.level == OriginLevel.City) {
      if (originsCStateInAlert.findIndex(c => c.country == origin.address.country && c.state == origin.address.state) >= 0) {
        return true;
      }
      return originsCityInAlert.findIndex(c => c.country == origin.address.country
        && c.state == origin.address.state
        && c.locality == origin.address.locality) >= 0;
    }


    return false;
  }

  getName(origin: ReportProfileOriginModel) {
    if (origin.address == null || origin.address.country == null) {
      return origin.displayName;
    }
    var country = this.i18Next.t(`country.${origin.address.country}`);
    if (this.configuration.level == OriginLevel.Country) {
      return country;
    }
    else if (this.configuration.level == OriginLevel.State) {
      if (StringHelper.isNullOrWhitespace(origin.address.state)) {
        return country;
      } else {
        return `${origin.address.state}, ${country}`;
      }
    }
    else if (this.configuration.level == OriginLevel.City) {
      if (StringHelper.isNullOrWhitespace(origin.address.locality)) {
        return country;
      } else {
        return `${origin.address.locality}, ${country}`;
      }
    } else {
      return country;
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  computeAnnotations(origin: string, countries: string[], states: string[], cities: string[], others: string[]) {
    var invalidOrigin = this.items.find(o => o.name == origin);
    if (invalidOrigin.model.address == null) {
      others.push(invalidOrigin.model.displayName);
    } else {
      var country = invalidOrigin.model.address.country == null ? "" : invalidOrigin.model.address.country;
      var state = invalidOrigin.model.address.state == null ? "" : invalidOrigin.model.address.state;
      var city = invalidOrigin.model.address.locality == null ? "" : invalidOrigin.model.address.locality;

      if (this.configuration.level == OriginLevel.Country) {
        countries.push(country);
      }
      else if (this.configuration.level == OriginLevel.State) {
        states.push(`${country}_${state}`);
      }
      else if (this.configuration.level == OriginLevel.City) {
        cities.push(`${country}_${state}_${city}`);
      }
    }
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(OriginModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.disableManualRemediation = true;
    modalRef.componentInstance.origins = this.items;
    modalRef.componentInstance.usualCountries = this.usualItems;
    modalRef.componentInstance.user = this.user;
    modalRef.result.then((model: UserReportProfileAlertOriginsModel) => {
      this.initAlertModel(model);
      model.originLevel = this.configuration.level;
      let result$: Observable<any> = null;
      if (this.profile.providerId == Constants.PROVIDER_ID_MICROSOFT) {
        result$ = this.microsoftDatasource.alertUserOrigins(this.profile.providerInstanceId, this.profile.userId, model);
      } else if (this.profile.providerId == Constants.PROVIDER_ID_ZIMBRA) {
        result$ = this.zimbraDatasource.alertUserOrigins(this.profile.providerInstanceId, this.profile.userId, model);
      }
      if (result$) {
        this.subscriptions.add(result$.subscribe(model => {
          this.setAlertsProcessed(model);
        }));
      }

    }).catch((error) => {
      // console.log(error); 
    });
  }
}

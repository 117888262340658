import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersDatasourceService, UserModel, UserUpdateModel } from '@i2a/web-api-client';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ContextService } from '@i2a/web-core';

@Component({
  selector: 'i2a-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.scss']
})
export class EditUserModalComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  public myForm: FormGroup;

  @Input()
  public userId: string;
  public user: UserModel;

  public timeZones: string[];
  public cultures: string[];

  public submiting: boolean;

  private firstNameInput = new FormControl('', Validators.required);
  private lastNameInput = new FormControl('', Validators.required);
  private eMailInput = new FormControl('', Validators.required);
  private phoneInput = new FormControl('');
  private timeZoneIdInput = new FormControl('', Validators.required);
  private cultureInput = new FormControl('', Validators.required);

  constructor(
    public context: ContextService,
    public toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private usersDatasource: UsersDatasourceService,
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService
  ) {
    this.createForm();
  }

  private createForm() {
    this.timeZones = [];
    this.myForm = this.formBuilder.group({
      firstName: this.firstNameInput,
      lastName: this.lastNameInput,
      eMail: this.eMailInput,
      phone: this.phoneInput,
      timeZoneId: this.timeZoneIdInput,
      culture: this.cultureInput,
    });
  }

  ngOnInit() {
    if (this.userId) {
      this.submiting = false;
      this.subscriptions.add(this.usersDatasource.load(this.userId, false).subscribe(user => {
        this.user = user;
        this.firstNameInput.setValue(user.firstName);
        this.lastNameInput.setValue(user.lastName);
        this.eMailInput.setValue(user.email);
        this.phoneInput.setValue(user.phone);
        this.timeZoneIdInput.setValue(user.timeZoneId);
        this.cultureInput.setValue(user.culture);
      }));
      this.subscriptions.add(this.usersDatasource.loadTimeZones(true).subscribe(timeZones => {
        this.timeZones = timeZones;
      }));
      this.subscriptions.add(this.usersDatasource.loadCultures(true).subscribe(cultures => {
        this.cultures = cultures;
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  submitForm() {
    if (this.myForm.valid) {
      this.submiting = true;
      let updateModel = new UserUpdateModel();
      updateModel.firstName = this.myForm.get('firstName').value as string;
      updateModel.lastName = this.myForm.get('lastName').value as string;
      updateModel.email = this.myForm.get('eMail').value as string;
      updateModel.phone = this.myForm.get('phone').value as string;
      updateModel.timeZoneId = this.myForm.get('timeZoneId').value as string;
      updateModel.culture = this.myForm.get('culture').value as string;
      updateModel.orgUnitId = this.user.orgUnitId;

      this.subscriptions.add(this.usersDatasource.update(this.userId, updateModel, true).subscribe(
        result => {
          this.toastr.success(this.i18Next.t("myAccount.edit.successMessage"));
          this.context.Culture = updateModel.culture;
          this.activeModal.close();
        },
        error => {
          console.log("Ajax error => " + error);
          this.toastr.error(error, this.i18Next.t('notifications.ERROR'));
          this.submiting = false;
        }
      ));
    }
  }

}

<div [ngClass]="{'full-screen': isFullScreen }">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      <ng-container *ngIf="modalTitle">
        {{modalTitle}}
      </ng-container>
    </h4>
    <!-- [attr.ngbAutofocus]="isFullScreen" -->
    <button *ngIf="activateFullScreen" type="button" class="close" (click)="toggleFullScreen()" 
      escape-press="toggleFullScreen">
      <fa-icon *ngIf="isFullScreen" [icon]="['fal', 'compress-alt']" size="2"></fa-icon>
      <fa-icon *ngIf="!isFullScreen" [icon]="['fal', 'expand-alt']" size="2"></fa-icon>
    </button>
    <button type="button" ngbAutofocus class="close close-margin-left" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <svg xmlns="http://www.w3.org/2000/svg" width="17.688" height="17.688" viewBox="0 0 17.688 17.688">
        <path
          d="M72.833,291.958l-0.791-.791L64,299.2l-8.042-8.033-0.791.791L63.2,300l-8.033,8.042,0.791,0.791L64,300.8l8.042,8.033,0.791-.791L64.8,300Z"
          fill="#FFFFFF" transform="translate(-55.156 -291.156)" />
      </svg>
    </button>
  </div>
  <div [ngClass]="{'modal-body': true, 'no-overflow': noOverflow }" >
    <ng-content select="[body]"></ng-content>
  </div>
  <div class="modal-footer">
    <div class="container-fluid">
      <ng-content select="[message]"></ng-content>
      <div class="row no-gutters mr-auto modal-footer-buttons">
        <ng-content select="[footer]"></ng-content>
      </div>
    </div>
  </div>
</div>
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { I2A_DISPLAY_ERROR_MESSAGE } from '@i2a/web-core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

@Component({
  selector: 'i2a-error-pending-collect',
  templateUrl: './error-pending-collect.component.html',
  styleUrls: ['./error-pending-collect.component.scss']
})
export class ErrorPendingCollectComponent implements OnInit {

  @Input() public errorPendingCollectObject: HttpErrorResponse;

  public errorMessageToShow: string;

  constructor(@Inject(I18NEXT_SERVICE) private translateI18Next: ITranslationService,
              @Inject(I2A_DISPLAY_ERROR_MESSAGE) private displayErrorMessage: boolean) { }

  ngOnInit() {
    this.showMessage(this.errorPendingCollectObject);
  }

  public showMessage(error: HttpErrorResponse) {
    let defaultErrorMessage = "A problem has occurred. Please try again. If the problem persists, please contact your support.";
    let errorMessage = "";
    let errorMessageKey = 'apiErrors.GENERIC';

    if (error.status === 404) {
        errorMessageKey = 'apiErrors.NOTFOUND';
    }
    else if (error.status === 0) {
        errorMessageKey = 'apiErrors.GENERIC';
    }
    else if (error.status === 401) {
        errorMessageKey = 'apiErrors.UNAUTHORIZED';
    }
    else if (error.status === 403) {
        errorMessageKey = 'apiErrors.FORBIDDEN';
    }
    else {
        errorMessageKey = 'apiErrors.GENERIC';
        errorMessage = this.getErrorResponseTranslatedMsg(error);
    }

    if (errorMessage == "")
    {
        errorMessage = this.translateI18Next.t(errorMessageKey);
    }
    if (errorMessage == "" || errorMessage == errorMessageKey)
    {
        errorMessage = defaultErrorMessage;
    }
    this.errorMessageToShow = errorMessage;
}

private getErrorResponseTranslatedMsg(error: HttpErrorResponse) {
    let errorMessage = "";

    if (error.error && error.error.ExceptionMessage) {
        if (this.displayErrorMessage){
            errorMessage = this.translateI18Next.t('apiErrors.GENERIC') + ` ( ${error.error.ExceptionMessage} )`;
        }
        if (error.error.FuncData && error.error.FuncData.FuncError_Code) {
            let translationKey = `apiErrors.${error.error.FuncData.FuncError_Code}`;
            let translation = this.translateI18Next.t(translationKey);

            if (translation !== translationKey) { //key is the fallback in case the translation do not exists.

                if (error.error.FuncData.FuncError_Parameters) {
                    let array = JSON.parse(error.error.FuncData.FuncError_Parameters);
                    let translationVars = {};
                    for (var i = 0; i < array.length; i++) {
                        translationVars[i] = array[i];
                    }
                    translation = this.translateI18Next.t(translationKey, translationVars);
                }

                errorMessage = translation;
            }
        }
    }

    return errorMessage;
};

}

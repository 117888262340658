<div class="container p-0 delegate-configuration-box">
  <div class="row no-gutters delegate-details">
    <div *ngIf="displayIsInbox"
      [ngClass]="{'col': true, 'p-1': true, 'delegate-mailbox': true, 'hide-info': !displayInfo}">
      <div class="col-centered">
        <div [title]="isInboxEnabled ? inboxInfo : ''">
          <fa-icon [icon]="['fal', 'envelope']" size="lg"
            [ngClass]="{'enabled': isInboxEnabled, 'not-enabled': !isInboxEnabled}"></fa-icon>
        </div>
        <div class="delegate-enabled" *ngIf="isInboxEnabled && displayInfo">
          <span> {{ inboxInfo }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="displayIsCalendar"
      [ngClass]="{'col': true, 'p-1': true, 'delegate-calendar': true, 'hide-info': !displayInfo}">
      <div class="col-centered">
        <div [title]="isCalendarEnabled ? calendarInfo : ''">
          <fa-icon [icon]="['fal', 'calendar-alt']"
            [ngClass]="{'enabled': isCalendarEnabled, 'not-enabled': !isCalendarEnabled}" size="lg"></fa-icon>
        </div>
        <div class="delegate-enabled" *ngIf="isCalendarEnabled && displayInfo">
          <span> {{ calendarInfo }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="displayIsInstantMessaging"
      [ngClass]="{'col': true, 'p-1': true, 'delegate-instant-messaging': true, 'hide-info': !displayInfo}">
      <div class="col-centered">
        <div [title]="isInstantMessagingEnabled ? instantMessagingInfo : ''">
          <fa-layers [fixedWidth]="true" size="lg" [ngClass]="{'enabled': isInstantMessagingEnabled, 'not-enabled': !isInstantMessagingEnabled}" >
            <fa-icon [icon]="['fal', 'comment-alt']"></fa-icon>
            <fa-icon [icon]="['fas', 'share-alt']" transform="shrink-7 up-1"></fa-icon>
          </fa-layers>

        </div>
        <div class="delegate-enabled" *ngIf="isInstantMessagingEnabled && displayInfo">
          <span> {{ instantMessagingInfo }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="displayIsFolders"
      [ngClass]="{'col': true, 'p-1': true, 'delegate-folder': true, 'hide-info': !displayInfo}">
      <div class="col-centered">
        <div [title]="isFoldersEnabled ? foldersInfo : ''">
          <fa-icon [icon]="['fal', 'folder']"
            [ngClass]="{'enabled': isFoldersEnabled, 'not-enabled': !isFoldersEnabled}" size="lg"></fa-icon>
        </div>
        <div class="delegate-enabled" *ngIf="isFoldersEnabled && displayInfo">
          <span> {{ foldersInfo }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
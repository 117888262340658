import { Component, OnInit, EventEmitter, Inject, Input } from '@angular/core';
import { ReportProfileBaseModel, UserModel, BaseProviderInstanceResourceModel, ZimbraMailingReportProfile, ZimbraReportProfileModel, AlertInvalidStateFeaturedModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ZimbraMailingDelegate } from 'projects/web-user-profile/src/app/models/providers/zimbra/mailing/zimbra-mailing-delegate';
import { ZimbraMailingNodataConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/zimbra-mailing-nodata-configuration';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';

@RegisterMyProfileComponent(TemplateConstants.MODULE_ZIMBRA_MAILING_NODATA)
@Component({
  selector: 'i2a-zimbra-mailing-nodata-module',
  templateUrl: './zimbra-mailing-nodata-module.component.html',
  styleUrls: ['./zimbra-mailing-nodata-module.component.scss']
})
export class ZimbraMailingNodataModuleComponent extends ProfileBaseClassComponent<ZimbraMailingNodataConfiguration,ZimbraMailingReportProfile> implements OnInit, IModule<ZimbraMailingNodataConfiguration> {
  private resource: BaseProviderInstanceResourceModel;
  
  @Input() 
  public delegatesWithAccessAndPermissions: ZimbraMailingDelegate[];
  
  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, null);
  }

  ngOnInit() {
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: ZimbraMailingNodataConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel, resourceTemplate?: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.resource = resource;
    this.resourceProfile = (<ZimbraReportProfileModel>profile).mailingProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }

}

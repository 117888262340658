import { AzureADReportProfile } from '../../microsoft/azuread/profile/azure-adreport-profile';
import { AzureADReportProfileChangesModel } from '../../microsoft/azuread/profile/azure-adreport-profile-changes-model';
import { BaseProviderInstanceResourceModel } from '../../base-provider-instance-resource-model';
import { ChangeResult } from '../../change-result';
import { ExchangeReportProfile } from '../../microsoft/exchange/profile/exchange-report-profile';
import { ExchangeReportProfileChangesModel } from '../../microsoft/exchange/profile/exchange-report-profile-changes-model';
import { FileShareReportProfile } from '../../microsoft/fileshare/profile/file-share-report-profile';
import { FileShareReportProfileChangesModel } from '../../microsoft/fileshare/profile/file-share-report-profile-changes-model';
import { OneDriveReportProfile } from '../../microsoft/onedrive/profile/one-drive-report-profile';
import { OneDriveReportProfileChangesModel } from '../../microsoft/onedrive/profile/one-drive-report-profile-changes-model';
import { OneDriveReportProfileRulesComputedDelegate } from '../../microsoft/onedrive/profile/one-drive-report-profile-rules-computed-delegate';
import { ReportProfileBaseModel } from '../../profile/report-profile-base-model';
import { ReportProfileRulesComputedDelegate } from '../../profile/report-profile-rules-computed-delegate';
import { SharePointReportProfile } from '../../microsoft/sharepoint/profile/share-point-report-profile';
import { SharePointReportProfileChangesModel } from '../../microsoft/sharepoint/profile/share-point-report-profile-changes-model';
import { SharePointSiteReportProfile } from '../../microsoft/onedrive/profile/share-point-site-report-profile';

export class MicrosoftOfficeReportProfileModel extends ReportProfileBaseModel {
  exchangeProfiles: ExchangeReportProfile[];
  exchangeResourceChanges: ChangeResult<BaseProviderInstanceResourceModel>;
  exchangeProfileChanges: ExchangeReportProfileChangesModel[];
  oneDriveProfiles: OneDriveReportProfile[];
  oneDriveResourceChanges: ChangeResult<BaseProviderInstanceResourceModel>;
  oneDriveProfileChanges: OneDriveReportProfileChangesModel[];
  sharePointProfiles: SharePointReportProfile[];
  sharePointResourceChanges: ChangeResult<BaseProviderInstanceResourceModel>;
  sharePointProfileChanges: SharePointReportProfileChangesModel[];
  sharePointSites: SharePointSiteReportProfile[];
  azureADProfiles: AzureADReportProfile[];
  azureADResourceChanges: ChangeResult<BaseProviderInstanceResourceModel>;
  azureADProfileChanges: AzureADReportProfileChangesModel[];
  fileShareProfiles: FileShareReportProfile[];
  fileShareResourceChanges: ChangeResult<BaseProviderInstanceResourceModel>;
  fileShareProfileChanges: FileShareReportProfileChangesModel[];
}

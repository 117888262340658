<div class="card profile-information id-information-card linked mt-3" id="information">
    <div class="container-fluid card-body p-0">
        <div class="row no-gutters">
            <div class="col-12 p-2">
                <div *ngIf="!userActivityIsValid || invalidResources.length > 0">
                    <span>{{'modal.profileValidation.anomaliesTitle' | i18next }}<br></span>
                    <ul>
                        <li *ngIf="!userActivityIsValid"> {{'profile.title.myActivity' | i18next}}</li>
                        <li *ngFor="let invalidResource of invalidResources">
                            <i2a-resource-name [providerInstanceResource]="invalidResource.providerInstanceResource">
                            </i2a-resource-name>
                            ({{getProviderTypeLabel(invalidResource) | i18next}})
                        </li>
                    </ul>
                    <p class="text-justify">{{"modal.profileValidation.informationWithAlert" | i18next}}</p>
                </div>
                <p class="text-justify" *ngIf="userActivityIsValid && invalidResources.length == 0">
                    {{"modal.profileValidation.informationValidated" | i18next}}
                </p>

                <div class="text-center">
                    <button (click)="back()" class="btn btn-outline-dark">
                        {{'profile.reloadProfile' | i18next}} </button>
                </div>

            </div>
        </div>
    </div>
</div>
import { OneDriveReportProfileDelegate } from '../../../microsoft/onedrive/profile/one-drive-report-profile-delegate';
import { ReportProfileAlertResourceBaseModel } from '../../../profile/report-profile-alert-resource-base-model';
import { SharePointReportProfileAlertPermissionsModel } from '../../../microsoft/sharepoint/profile/share-point-report-profile-alert-permissions-model';

export class OneDriveReportProfileAlertDelegateModel extends ReportProfileAlertResourceBaseModel {
  delegate: OneDriveReportProfileDelegate;
  permissions: SharePointReportProfileAlertPermissionsModel;
  allFolders: boolean;
  folders: string[];
}

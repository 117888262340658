import { Component, OnInit, EventEmitter, Inject, Input } from '@angular/core';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { ReportProfileBaseModel, UserModel, BaseProviderInstanceResourceModel, MicrosoftOfficeReportProfileModel, OneDriveReportProfileDelegate, OneDriveReportProfile, AlertInvalidStateFeaturedModel, SharePointReportProfilePermissions, ReportProfileResourceBase } from '@i2a/web-api-client';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ProfileHelper } from 'projects/web-user-profile/src/app/helpers/profile-helper';
import { OneDriveInstantMessagingConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/one-drive-instant-messaging-configuration';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';
import { OneDriveDelegate } from 'projects/web-user-profile/src/app/models/providers/microsoft/one-drive/one-drive-delegate';
import { OneDriveDelegates } from 'projects/web-user-profile/src/app/models/providers/microsoft/one-drive/one-drive-delegates';
import { OneDriveDelegateModuleComponent } from '../one-drive-delegate-module/one-drive-delegate-module.component';

@RegisterMyProfileComponent(TemplateConstants.MODULE_ONEDRIVE_INSTANT_MESSAGING)
@Component({
  selector: 'i2a-one-drive-delegate-module',
  templateUrl: './one-drive-instant-messaging-module.component.html',
  styleUrls: ['./one-drive-instant-messaging-module.component.scss']
})
export class OneDriveInstantMessagingModuleComponent extends ProfileBaseClassComponent<OneDriveInstantMessagingConfiguration,ReportProfileResourceBase<any>> implements OnInit, IModule<OneDriveInstantMessagingConfiguration> {
  private resource: BaseProviderInstanceResourceModel;
  public oneDriveProfile: OneDriveReportProfile;

  public instantMessagingDelegates: OneDriveDelegate[];

  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, null);
  }

  ngOnInit() {
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: OneDriveInstantMessagingConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel, resourceTemplate?: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.resource = resource;
    this.oneDriveProfile = (<MicrosoftOfficeReportProfileModel>profile).oneDriveProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
    this.instantMessagingDelegates = OneDriveInstantMessagingModuleComponent.SetDelegates(configuration.displayOnlyAccesses, this.oneDriveProfile.delegates, this.oneDriveProfile.permissions, this.i18Next);
  }

  public static SetDelegates(displayOnlyAccesses: boolean, oneDriveProfileDelegates: OneDriveReportProfileDelegate[], sharePointProfilePermissions: SharePointReportProfilePermissions[], i18Next: ITranslationService): OneDriveDelegate[] {
    if (oneDriveProfileDelegates) {
        var alldelegates = OneDriveDelegateModuleComponent.JoinDelegateAndPermissions(displayOnlyAccesses, oneDriveProfileDelegates, sharePointProfilePermissions);
  
      var instantMessagingDelegates = alldelegates.filter(d => d.accesses.hasInstantMessagingShare);
      let IManonymousUsers = alldelegates.filter(d =>d.accesses.isAnonymousUser && d.accesses.hasInstantMessagingShare);
      OneDriveInstantMessagingModuleComponent.processDelegates(IManonymousUsers, instantMessagingDelegates);
      let IMexternalUsers = alldelegates.filter(d => d.accesses.isExternalUser && d.accesses.hasInstantMessagingShare);
      OneDriveInstantMessagingModuleComponent.processDelegates(IMexternalUsers, instantMessagingDelegates);
      instantMessagingDelegates.sort((a, b) => { return ProfileHelper.compare(a.accesses, b.accesses, i18Next); });

      return instantMessagingDelegates;
    }

  }

  private static processDelegates(delegateToAdd: OneDriveDelegate[], dest: OneDriveDelegate[]) {
    if (delegateToAdd.length > 0) {
      let delegate = delegateToAdd.shift();
      delegateToAdd.forEach(user => {
        user.accesses.foldersAccessed.forEach(f => {
          if (delegate.accesses.foldersAccessed.indexOf(f) == -1) {
            delegate.accesses.foldersAccessed.push(f);
          }
        })
      });
      delegate.accesses.foldersAccessed.sort();
      dest.push(delegate);
    }

  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }

}

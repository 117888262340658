<div class="module">
  <div class="container-fluid module-container"
    *ngIf="delegates && delegates.length > 0 || externalDelegates && externalDelegates.length > 0">
    <div class="row no-gutters">
      <i2a-sub-title class="col-12" [subTitle]="'profile.provider.microsoft.oneDrive.title.delegateAccess' | i18next "
        [description]="'profile.provider.microsoft.oneDrive.description.delegateAccess' | i18next "></i2a-sub-title>
    </div>
    <div class="row no-gutters">

        <!-- EXTERNAL DELEGATES -->
        <div *ngIf="externalDelegates?.length > 0" class="col-sm-12 col-md-6 col-lg-4 align-items-start">

          <i2a-one-drive-external-delegates [resourceProfile]="resourceProfile" [componentId]="componentId" [isAlert]="isAlert"
            [providerInstanceResource]="resourceProfile.providerInstanceResource" [profile]="profile" [user]="user"
            [delegates]="externalDelegates" (onAlertSent)="alertSent($event)" [alerts]="alerts" (onHasWarnings)="hasWarnings($event)">
          </i2a-one-drive-external-delegates>
        
        </div>

        <!-- DELEGATES -->
        <div *ngFor="let delegate of delegates | slice:0:maxShowMore" class="col-sm-12 col-md-6 col-lg-4 align-items-start">

          <i2a-one-drive-delegate [resourceProfile]="resourceProfile" [componentId]="componentId" [isAlert]="isAlert"
            [providerInstanceResource]="resourceProfile.providerInstanceResource" [profile]="profile" [user]="user"
            [delegate]="delegate" (onAlertSent)="alertSent($event)" [alerts]="alerts" (onHasWarnings)="hasWarnings($event)">
          </i2a-one-drive-delegate>

        </div>

        <div *ngIf="isVisibleButtonsShowMore" class="col-12 p-2 text-center">
          <button class="btn btn-outline-primary" (click)="onShowMore()" *ngIf="!showMore">{{ 'button.showMore' | i18next }}</button>
          <button class="btn btn-outline-primary" (click)="onShowMore()" *ngIf="showMore">{{ 'button.showLess' | i18next }}</button>
        </div>        

    </div>
  </div>
</div>

<div *ngIf="!hasNoData" class="device">
  <i2a-profile-card [id]="id" [viewMoreDetails]="false" [title]="'profile.user.device.title' | i18next"
    (onWarnClick)="warnClick($event)" [hasAlert]="hasAlert" [hasAlertRecentlyClosed]="hasAlertRecentlyClosed"
    [warningLevel]="warningLevel">


    <ng-container picto>
      <i2a-badge-icon [customClass]="'id-bagde-devices'">
        <ng-container icon>
          <fa-icon [icon]="['fal', 'mobile']" size="lg"></fa-icon>
        </ng-container>
      </i2a-badge-icon>
    </ng-container>

    <ng-container content>
      <div class="container p-0">
        <div class="row no-gutters">
          <div class="col ml-2 mr-2">

            <span *ngIf="itemsNotRegistered && itemsNotRegistered.length > 0"
              class="device-name float-left">{{ 'profile.user.device.toBeConfirmed' | i18next}}</span>

            <table *ngIf="itemsNotRegistered && itemsNotRegistered.length > 0" class="table-sm id-table mb-1 w-100">
              <tbody>
                <tr *ngFor="let itemNotRegistered of itemsNotRegistered">
                  <td class="recent-device align-text-top">
                    <fa-icon [icon]="['fas', 'question-circle']" size="lg"></fa-icon>
                  </td>
                  <td scope="row" [ngClass]="{'has-alert': itemNotRegistered.hasAlert, 'has-alert-recently-closed': itemNotRegistered.hasAlertRecentlyClosed}">
                    <img [src]="itemNotRegistered.image" width="24" height="24" />
                    {{itemNotRegistered.name}}<br>
                    <span class="device-action-date"
                      *ngIf="itemNotRegistered.lastActionDate">{{itemNotRegistered.lastActionDate | amDateFormat: 'LLL'
                      }}</span>
                  </td>
                </tr>
              </tbody>
            </table>

            <span *ngIf="items && items.length > 0"
              class="device-name float-left">{{ 'profile.user.device.usual' | i18next}}</span>

            <table *ngIf="items && items.length > 0" class="table-sm id-table mb-1 w-100">
              <tbody>
                <tr *ngFor="let item of items">
                  <td class="registered-device align-text-top">
                    <fa-icon [icon]="['fas', 'check-circle']" size="lg"></fa-icon>
                  </td>
                  <td scope="row" [ngClass]="{'has-alert': item.hasAlert, 'has-alert-recently-closed': item.hasAlertRecentlyClosed}">
                    <img [src]="item.image" width="24" height="24" />
                    {{item.name}}<br>
                    <span class="device-action-date" *ngIf="item.lastActionDate">{{item.lastActionDate | amDateFormat:
                      'LLL' }}</span>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </ng-container>

  </i2a-profile-card>
</div>
<div class="container p-0 delegate-devices">
    <div class="row no-gutters">
        <div class="col-12 delegate-device p-1">
            <table class="table table-sm id-table device-table" *ngIf="devices.length == 0">
                <tbody>
                    <tr>
                        <td class="no-device text-center" scope="row">
                            {{"profile.provider.microsoft.exchange.devices.noDevices" | i18next }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="table table-sm id-table device-table" *ngIf="devices.length > 0">
                <tbody>
                    <tr *ngFor="let device of devices">
                        <td scope="row">
                            <img [src]="'/assets/pictos/devices/'+ device.deviceType +'.png'" width="24" height="24" />
                            {{device.name}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
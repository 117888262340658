import { FunctionalExceptionModel } from './functional-exception-model';
import { OperationItemStatus } from './operation-item-status';

export class OperationItemModel {
  requestName: string;
  providerInstanceResourceId: string|null;
  userId: string|null;
  exception: FunctionalExceptionModel;
  status: OperationItemStatus;
  notNecessary: boolean;
}

import { ReportProfileAlertResourcesBaseModel } from '../../../report-profile-alert-resources-base-model';
import { SharePointGraphGroupModel } from '../../../microsoft/sharepoint/share-point-graph-group-model';

export class SharePointReportProfileAlertO365GroupModel extends ReportProfileAlertResourcesBaseModel {
  group: SharePointGraphGroupModel;
  allMembers: boolean;
  invalidMemberList: string[];
  invalidOwnerList: string[];
  allGuests: boolean;
  invalidGuestList: string[];
}

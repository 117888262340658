import { Component, OnInit, Inject } from '@angular/core';
import { ReportProfileBaseModel, UserModel, BaseProviderInstanceResourceModel, MicrosoftOfficeReportProfileModel, ExchangeReportProfile, AlertInvalidStateFeaturedModel, ExchangeReportProfilePermissions, ExchangeReportProfileDelegate } from '@i2a/web-api-client';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ExchangePermissionConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/exchange-permission-configuration';
import { ProfileHelper } from 'projects/web-user-profile/src/app/helpers/profile-helper';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';
import { ExchangeReportProfilePermissionsWithWarnings } from 'projects/web-user-profile/src/app/models/providers/microsoft/exchange/exchange-report-profile-permissions-with-warnings';
import { ExchangePermissionComponent } from '../exchange-permission/exchange-permission.component';
import { WarningInfo } from 'projects/web-user-profile/src/app/models/warnings/warning-info';

@RegisterMyProfileComponent(TemplateConstants.MODULE_EXCHANGE_PERMISSION)
@Component({
  selector: 'i2a-exchange-permission-module',
  templateUrl: './exchange-permission-module.component.html',
  styleUrls: ['./exchange-permission-module.component.scss']
})
export class ExchangePermissionModuleComponent extends ProfileBaseClassComponent<ExchangePermissionConfiguration, ExchangeReportProfile> implements OnInit, IModule<ExchangePermissionConfiguration> {
  public delegates: ExchangeReportProfilePermissionsWithWarnings[] = [];
  
  public showMore: boolean;
  public showMoreAfter: number;
  public maxShowMore: number;
  public isVisibleButtonsShowMore: boolean;
  private isActiveShowMoreOption: boolean;

  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, null);
  }

  ngOnInit() {
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: ExchangePermissionConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel, resourceTemplate?: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.resourceProfile = (<MicrosoftOfficeReportProfileModel>profile).exchangeProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
    this.resourceProfile.permissions.sort((a, b) => { return ProfileHelper.compare(a, b, this.i18Next); });

    let warnings: Array<WarningInfo> = [];

    this.setDelegates(configuration, warnings);

    if (warnings.length > 0) {
      this.hasWarnings(warnings);
    }

  }

  private setDelegates(configuration: ExchangePermissionConfiguration, warnings: Array<WarningInfo>) {
    let delegatesWithoutAccess: ExchangeReportProfilePermissions[] = ExchangePermissionModuleComponent.SetDelegates(this.resourceProfile.permissions, this.resourceProfile.delegates, this.configuration);
    let delegatesWithoutAccessWithWarnings = delegatesWithoutAccess.map(permissions => {
      return ExchangePermissionComponent.getPermissionsWithWarning(this.i18Next, this.componentId, this.resourceProfile, permissions, this.alerts);
    });

    delegatesWithoutAccessWithWarnings?.forEach((d: ExchangeReportProfilePermissionsWithWarnings) => {
      if (d.model.isNameKey || (d.warnings != null && d.warnings != undefined)) {
        this.delegates.unshift(d);
        if ((d.warnings != null && d.warnings != undefined)) {
          warnings.push(...d.warnings);
        }
      } else {
        this.delegates.push(d);
      }
    });

    this.showMoreAfter = configuration.showMoreAfter ?? 0;

    let minShowMore = this.delegates.filter(d => d.model.isNameKey || d.warnings?.length > 0).length;
    if (this.showMoreAfter != 0 && this.showMoreAfter < minShowMore) {
      this.showMoreAfter = minShowMore;
    }

    this.isActiveShowMoreOption = this.showMoreAfter > 0;
    this.isVisibleButtonsShowMore = this.isActiveShowMoreOption && (this.delegates?.length > this.showMoreAfter);
    this.setShowMoreValue();
  }

  public static SetDelegates(permissions: ExchangeReportProfilePermissions[], exchangeProfileDelegates: ExchangeReportProfileDelegate[], configuration: ExchangePermissionConfiguration): ExchangeReportProfilePermissions[] {
    var delegates = [];
    if (configuration.displayAllDelegates) {
      delegates = permissions;
    } else {
      permissions.forEach(item => {
        if (item.userId != null) {
          if (exchangeProfileDelegates.findIndex(d => d.userId != null && d.userId == item.userId) < 0) {
            delegates.push(item);
          }
        }
        else if (item.providerInstanceUser != null) {
          if (exchangeProfileDelegates.findIndex(d => d.providerInstanceUser != null && d.providerInstanceUser.providerInstanceResourceId == item.providerInstanceUser.providerInstanceResourceId) < 0) {
            delegates.push(item);
          }
        }
        else {
          if (exchangeProfileDelegates.findIndex(p => p.logonUserId == item.logonUserId) < 0) {
            delegates.push(item);
          }
        }
      });
    }
    return delegates;
  }

  public onShowMore() {
    this.showMore = !this.showMore;
    this.setShowMoreValue();
  }

  private setShowMoreValue() {
    this.maxShowMore = (!this.showMore && this.isActiveShowMoreOption) ? this.showMoreAfter : this.delegates?.length;
  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }
}

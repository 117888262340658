import { Component, OnInit, ViewContainerRef, ViewChild, OnDestroy, ComponentRef, Inject, Output, EventEmitter } from '@angular/core';
import { TemplateService } from 'projects/web-user-profile/src/app/services/template.service';
import { ReportProfileBaseModel, UserModel, ProviderInstanceModel, ConnectedAccountModel, Constants, AlertInvalidStateFeaturedModel, BaseProviderInstanceResourceModel, ReportProfileResource, ReportProfileResourceBase } from '@i2a/web-api-client';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { IBaseModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-base-module';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { ProfileBaseClassComponent } from '../profile-base-class-component';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { IModuleProviderContainer } from 'projects/web-user-profile/src/app/models/templates/modules/i-module-provider-container';
import { TemplateProvider } from 'projects/web-user-profile/src/app/models/templates/template-provider';
import { IModuleApplicationContainer } from 'projects/web-user-profile/src/app/models/templates/modules/i-module-application-container';
import { ProviderConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/provider-configuration';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@RegisterMyProfileComponent(TemplateConstants.MODULE_PROVIDER_CONTAINER)
@Component({
  selector: 'i2a-provider-container-container',
  templateUrl: './provider-container.component.html',
  styleUrls: ['./provider-container.component.scss']
})
export class ProviderContainerComponent extends ProfileBaseClassComponent<ProviderConfiguration,ReportProfileResourceBase<any>> implements OnInit, OnDestroy, IModuleProviderContainer<ProviderConfiguration> {
  private components: ComponentRef<IBaseModule>[];
  private modules: IBaseModule[];

  public providerInstance: ProviderInstanceModel;
  public template: TemplateProvider;
  public connectedAccount: ConnectedAccountModel;

  public alertIsSent: boolean;

  @ViewChild("container", { read: ViewContainerRef, static: true }) container;

  @Output() public onResourceToValidate: EventEmitter<ReportProfileResource> = new EventEmitter<ReportProfileResource>();

  public displayUser: boolean;

  constructor(
    private session: SessionService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService,
    private templateService: TemplateService) {
    super(toastr, i18Next, null);
    this.components = [];
    this.modules = [];
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: ProviderConfiguration, alerts: AlertInvalidStateFeaturedModel[], template: TemplateProvider): void {
    super.baseInitialize(user, profile, configuration, alerts);
    this.template = template;
  }

  setIsAlert(isAlert: boolean) {
    super.setIsAlert(isAlert);

    this.modules.forEach(m => {
      m.setIsAlert(isAlert);
    })
  }

  ngOnInit() {
    if (this.user && this.profile && this.template) {

      //MODULES
      this.template.modules.forEach(module => {
        if (module.isEnabled) {
          var component = this.templateService.loadComponent<IModule<any>>(this.container, module.id);
          if (component) {
            this.components.push(<ComponentRef<any>>component);
            this.modules.push(component.instance);
            component.instance.initialize(this.user, this.profile, module.configuration, this.profile.alerts);
            this.subscriptions.add(component.instance.onAlertSent.subscribe(e => this.alertSent(e)));
            this.subscriptions.add(component.instance.onHasWarnings.subscribe(e => this.hasWarnings(e)));
          }
        }
      });

      //APPLICATION CONTAINER
      this.template.providerApplicationTemplates.forEach(application => {
        if (application.containerModule.isEnabled) {
          var resourceIndex = this.profile.providerInstanceResources.findIndex(resource => resource.providerTypeId.localeCompare(application.providerTypeId, undefined, { sensitivity: 'base' }) == 0);
          if (resourceIndex >= 0) {
            var appComponent = this.templateService.loadComponent<IModuleApplicationContainer<any>>(this.container, application.containerModule.id);
            if (appComponent) {
              this.components.push(<ComponentRef<any>>appComponent);
              this.modules.push(appComponent.instance);
              this.subscriptions.add(appComponent.instance.onResourceToValidate.subscribe(e => this.resourceToValidate(e)));
              appComponent.instance.initialize(this.user, this.profile, application.containerModule.configuration, this.alerts, application);
              this.subscriptions.add(appComponent.instance.onAlertSent.subscribe(e => this.alertSent(e)));
              this.subscriptions.add(appComponent.instance.onHasWarnings.subscribe(e => this.hasWarnings(e)));
            }
          }
        }
      });
    }
  }

  ngAfterViewInit() {

  }

  resourceToValidate(e: ReportProfileResource) {
    this.onResourceToValidate.emit(e);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  alertSent(event): void {
    this.onAlertSent.emit(event);
  }

  updateAlerts(alerts: AlertInvalidStateFeaturedModel[]) {
    super.updateAlerts(alerts);
    this.components.forEach(c => c.instance.updateAlerts(alerts));
  }
}

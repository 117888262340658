import { Component, OnInit, OnDestroy, Input, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { ProviderInstanceModel, UserModel, ZimbraReportProfileModel, ZimbraProfileProviderDatasourceService, ReportProfileValidateModel, ConnectedAccountModel, ProviderInstancesDatasourceService, ProviderInstanceResourceModel, UsersDatasourceService } from '@i2a/web-api-client';
import { ProfilePageComponent } from '../../../shared/profile-page/profile-page.component';
import { Subscription, Observable, from, of, timer } from 'rxjs';
import { NgbCarousel, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';
import { Template } from 'projects/web-user-profile/src/app/models/templates/template';
import { mergeMap, delay, concatMap } from 'rxjs/operators';
import { ProviderHelper } from 'projects/web-user-profile/src/app/helpers/provider-helper';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'i2a-zimbra-profile',
  templateUrl: './zimbra-profile.component.html',
  styleUrls: ['./zimbra-profile.component.scss']
})
export class ZimbraProfileComponent implements OnInit, OnDestroy {
  @Input() public providerInstance: ProviderInstanceModel;
  @Input() public user: UserModel;
  @Input() public userView: boolean;
  @ViewChildren(ProfilePageComponent) profilePageComponent: QueryList<ProfilePageComponent>;

  private subscriptions = new Subscription();
  public profile: ZimbraReportProfileModel;
  public template: Template;
  public collectedResources: ProviderInstanceResourceModel[];
  public collectedResourcesLoaded: ProviderInstanceResourceModel[];
  public loadingRes: ProviderInstanceResourceModel;
  public collectedResourcesIndex: number;
  public collectedResourcesCount: number;
  public isAlert?: boolean;
  public alertIsSent: boolean;
  public connectedAccount: ConnectedAccountModel;
  public noCollectedResources: boolean;
  public errorPendingCollect:boolean;
  public errorPendingCollectObject: HttpErrorResponse;

  public value: number;
  public progressValue: any;
  public ressourceLoadingObserver: any;

  public loading: boolean = true;
  public start: boolean = false;

  constructor(
    private modalService: NgbModal,
    private zimbraDatasource: ZimbraProfileProviderDatasourceService,
    private providerInstancesDatasource: ProviderInstancesDatasourceService,
    private usersDataSource: UsersDatasourceService,
    private session: SessionService
  ) {
    this.isAlert = true;
    this.alertIsSent = false;
    this.noCollectedResources = false;
  }

  ngOnInit() {
    if (this.user && this.providerInstance) {
      this.progressValue = timer(0, 250).subscribe(t => {
        this.value = this.increment();
      }, 
      error => { return this.myErrorAction(error); });
      this.subscriptions.add(this.usersDataSource.loadCollectedResources(this.user.id).subscribe(
        receivedCollectedResources => {
          var providerInstanceResources = receivedCollectedResources.find(a => a.providerInstance.providerId == this.providerInstance.providerId
            && a.providerInstance.providerInstanceId == this.providerInstance.providerInstanceId).providerInstanceResources;
          this.collectedResources = providerInstanceResources.filter(a => a.isRealTimeProcessing || a.isPermanentAuditing).sort((a, b) => a.providerTypeId.localeCompare(b.providerTypeId));

          this.collectedResourcesCount = this.collectedResources.length;
          if (this.collectedResources.length > 0) {
            var index = 0;
            this.loadingRes = this.collectedResources[index];
            this.collectedResourcesLoaded = new Array<ProviderInstanceResourceModel>();
            this.ressourceLoadingObserver = new Observable(observer => {
              observer.next(this.collectedResources);
              observer.complete();
            })
              .pipe(
                // make observable to emit each element of the array (not the whole array)
                mergeMap((x: any) => from(x)),
                // delay each element by 2.5 sec
                concatMap(x => of(x).pipe(delay(2500)))
              )
              .subscribe((x: any) => {
                if (this.collectedResources.length > index) {
                  this.loadingRes = this.collectedResources[index + 1];
                }
                this.collectedResourcesLoaded.push(this.collectedResources[index]);
                this.collectedResourcesIndex = index;
                index = index + 1;
              }, 
              error => { return this.myErrorAction(error); });
          }
          this.subscriptions.add(this.zimbraDatasource.loadProfile(this.providerInstance.providerInstanceId, this.user.id)
            .subscribe(profile => {
              this.profile = profile;
              this.noCollectedResources = profile == null;
              this.subscriptions.add(this.providerInstancesDatasource.loadMyProfileTemplate(this.providerInstance.providerId, this.providerInstance.providerInstanceId, this.user.id, this.userView)
                .subscribe(template => {
                  this.template = <Template>template;
                  this.session.ManualRemediation = this.template.configuration?.manualRemediation ?? false;
                  this.session.IgnoreCommentToSend = this.template.configuration?.ignoreCommentToSend ?? true;
                  this.session.IsEnableEscalationDirectUser = this.template.configuration?.isEnableEscalationDirectUser ?? true;
                  this.progressValue.unsubscribe();
                  if (this.ressourceLoadingObserver) {
                    this.ressourceLoadingObserver.unsubscribe();
                    for (let index = this.collectedResourcesLoaded.length; index < this.collectedResources.length; index++) {
                      delay(200);
                      this.collectedResourcesLoaded.push(this.collectedResources[index]);
                    }
                  }
                  this.loadingRes = null;
                  this.loading = false;
                }, 
                error => { return this.myErrorAction(error); }));

            }, 
            error => { return this.myErrorAction(error); }));
          this.subscriptions.add(this.session.Account$.subscribe((account) => {
            this.connectedAccount = account;
          }, 
          error => { return this.myErrorAction(error); }));
        }, 
        error => { return this.myErrorAction(error); })
      );
    }
  }

  private increment(rnd = 0) {
    const stat = this.value;
    if (stat >= 99) {
      rnd = 0;
    }

    if (rnd === 0) {
      if (stat >= 0 && stat < 25) {
        // Start out between 1 - 2% increments
        rnd = (Math.random() + 1);
      } else if (stat >= 25 && stat < 65) {
        // increment between 0 - 2%
        rnd = (Math.random() * 2);
      } else if (stat >= 65 && stat < 90) {
        // increment between 0 - 1%
        rnd = (Math.random() * 1);
      } else if (stat >= 90 && stat < 99) {
        // finally, increment it .5 %
        rnd = 0.25;
      } else {
        // after 99%, don't increment:
        rnd = 0;
      }
    }

    return rnd + stat;
  }

  providerTypeLabel(resource: ProviderInstanceResourceModel): string {
    return ProviderHelper.GetProviderTypeLabel(resource.providerId, resource.providerTypeId);
  }

  providerPhotoUrl(resource: ProviderInstanceResourceModel): string {
    return ProviderHelper.GetProviderImage(resource.providerId, resource.providerTypeId, null);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @ViewChild('carousel') carousel: NgbCarousel;


  public next() {
    this.carousel.next();
  }

  public prev() {
    this.carousel.prev();
  }

  public displayProfile() {
    this.start = true;
  }

  private myErrorAction(error: any): void {
    this.errorPendingCollect = true
    this.errorPendingCollectObject = error;
    this.loading = false;
    this.value = 0;
    this.progressValue?.unsubscribe();
    this.ressourceLoadingObserver?.unsubscribe();
  }
}

<div class="row m-0">

  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 warning-notification">

    <div class="warning-notification-icon">
      <img height="28" width="28" [src]="providerPhotoUrl">
    </div>

    <div class="warning-notification-content">
      <div class="text-default-medium text-ellipsis">
        <span [title]="warning.label">
          <a [routerLink]="['.']" [fragment]="warning.resourceId" queryParamsHandling="merge">
            {{ warning.label }}
          </a>
        </span>
      </div>
    </div>
  </div>

  <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 warning-notification">

    <div class="row warning-notification-action">

      <span class="text-default-medium text-ellipsis" [title]="'warning.warningInfo' | i18next">
        {{ warning.warningInfo | i18next }}
      </span>

      <ng-container *ngIf="warning.viewMoreDetails">
        <a class="btn btn-outline-light btn-outline-gray btn-outline-details mr-1" [routerLink]="['.']"
          [fragment]="warning.id" [queryParams]="{action:'openDetails'}" queryParamsHandling="merge">{{
          buttonVviewMoreDetailbuttonKey }}</a>
      </ng-container>

      <ng-container *ngIf="warning.viewDissmiss">
        <a class="btn btn-outline-light btn-outline-alert" [routerLink]="['.']" [fragment]="warning.id"
        [queryParams]="{action:'warnClick'}" queryParamsHandling="merge">{{ buttonOpenAlertModalKey }}</a>
      </ng-container>

      <ng-container *ngIf="warning.viewResource">
        <a class="btn btn-outline-light btn-outline-gray btn-outline-details mr-1" [routerLink]="['.']" [fragment]="warning.resourceId" queryParamsHandling="merge">
          {{ buttonVviewMoreDetailbuttonKey }}
        </a>
      </ng-container>
    </div>

  </div>

</div>
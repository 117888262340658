<div  class="card profile-validation id-validation-card mt-3" 
      [id]="displayValidationButton ? 'validation' : 'validationEnd'" 
      [attr.data-guidedTourKey]="displayValidationButton ?configuration.validationGuidedTourKey : configuration.validationEndGuidedTourKey">
  <div class="container-fluid card-body p-0">
    <div class="row no-gutters">
      <div class="col-12 col-sm-6 my-auto pl-2">
        <span class="id-validation-title"> {{questionKey | i18next  }} </span><br>
        <span class="id-validation-subtitle" [innerHtml]="'profile.validationInformation' | i18next | SafeHtml"></span>
      </div>
      <div class="col-12 col-xl-6 container h100">
        <div class="row no-gutters align-items-center h-100">
          <div class="buttons-container">
            <button *ngIf="session.IsCollapsedAll" (click)="collapseAll()" class="btn id-validation-collapse-all m-2">
              <fa-icon [icon]="['fas', 'chevron-down']" size="lg"></fa-icon>
              {{'profile.expandAll' | i18next}}
            </button>
            <button *ngIf="!session.IsCollapsedAll" (click)="collapseAll()" class="btn id-validation-collapse-all m-2">
              <fa-icon [icon]="['fas', 'chevron-up']" size="lg"></fa-icon>
              {{'profile.collapseAll' | i18next}}
            </button>
            <button *ngIf="!displayValidationButton" (click)="help()" class="btn id-validation-help m-2">
              <fa-icon [icon]="['fas', 'question']"></fa-icon> {{'profile.guidedTourButton' | i18next}}
            </button>
            <button *ngIf="displayValidationButton" (click)="send()"
              [ngClass]="{'btn': true, 'm-2': true, 'id-validation-ok': true, 'with-alert': alertIsSent }">
              <fa-icon [icon]="['fal', 'check']" size="lg"></fa-icon> {{'profile.validationButton' | i18next}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #confirmationTemplate>
  <p><strong>{{"modal.profileValidation.question" | i18next}}</strong></p>
  <div *ngIf="!userActivityIsValid || invalidResources.length > 0">
    <span>{{'modal.profileValidation.anomaliesTitle' | i18next }}<br></span>
    <ul>
      <li *ngIf="!userActivityIsValid"> {{'profile.title.myActivity' | i18next}}</li>
      <li *ngFor="let invalidResource of invalidResources">
        <i2a-resource-name [providerInstanceResource]="invalidResource.providerInstanceResource"></i2a-resource-name>
        ({{getProviderTypeLabel(invalidResource) | i18next}})
      </li>
    </ul>
    <p class="text-justify">{{"modal.profileValidation.informationWithAlert" | i18next}}</p>
  </div>
  <p class="text-justify" *ngIf="userActivityIsValid && invalidResources.length == 0">
    {{"modal.profileValidation.information" | i18next}}</p>
</ng-template>

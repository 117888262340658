import { Component, OnInit, Input } from '@angular/core';
import { ExchangeReportProfileRules, ExchangeReportProfileAlertForwardMailboxesModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-exchange-rule-config-modal',
  templateUrl: './exchange-rule-config-modal.component.html',
  styleUrls: ['./exchange-rule-config-modal.component.scss']
})
export class ExchangeRuleConfigModalComponent implements OnInit {
  @Input() public rules: ExchangeReportProfileRules;

  @Input() public resourceProfile: ReportProfileResourceBase<any>;
  
  public validation: ExchangeReportProfileAlertForwardMailboxesModel;
  public canSend: boolean;
  public emails: any[];

  constructor(
    private modalService: NgbModal,
    public session: SessionService
  ) {
    this.canSend = false;
  }

  ngOnInit() {
    this.validation = new ExchangeReportProfileAlertForwardMailboxesModel();
    this.validation.forwardMailboxes = [];
    this.emails = [];
    if (this.rules) {
      this.rules.forwardMailBoxes.forEach(email => {
        this.emails.push({
          "name": email,
          "checked": this.rules.forwardMailBoxes.length == 1
        });
      });
      this.setCanSend();
    }
  }

  private setCanSend() {
    setTimeout(() => {
      this.canSend = this.rules.forwardMailBoxes.length > 0 && this.validation.forwardMailboxes.length > 0;
    });
  }

  checked(isChecked: boolean, email: any) {
    const index: number = this.validation.forwardMailboxes.indexOf(email.name);
    if (isChecked && index === -1) {
      this.validation.forwardMailboxes.push(email.name);
    }
    else if (!isChecked && index !== -1) {
      this.validation.forwardMailboxes.splice(index, 1);
    }
    this.setCanSend();
  }

}

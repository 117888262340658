<div class="id-subsite-item" *ngIf="node && node.isList"> {{ node.name }} </div>
<div class="id-subsite" *ngIf="node && subSites && !node.isList && !node.isList">
  <i2a-short-title-bar [isCollapsed]="node.parentId != null && (subSites.length > 0 || lists.length > 0)" [right]="false" [noBackground]="true" >

    <ng-container title> {{ node.name }} </ng-container>

    <ng-container body>
      <ng-container *ngFor="let list of lists">
        <i2a-share-point-subsite-node [node]="list" [allSites]="allSites">
        </i2a-share-point-subsite-node>
      </ng-container>
      <ng-container *ngFor="let subSite of subSites">
        <i2a-share-point-subsite-node [node]="subSite" [allSites]="allSites">
        </i2a-share-point-subsite-node>
      </ng-container>
    </ng-container>
  </i2a-short-title-bar>
</div>
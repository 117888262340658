import { Component, OnInit, Input, Inject } from '@angular/core';
import { ExchangeReportProfilePermissions } from '@i2a/web-api-client';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

@Component({
  selector: 'i2a-exchange-permission-rights',
  templateUrl: './exchange-permission-rights.component.html',
  styleUrls: ['./exchange-permission-rights.component.scss']
})
export class ExchangePermissionRightsComponent implements OnInit {
  @Input() public permissions: ExchangeReportProfilePermissions;
  @Input() public displayTitle: boolean;

  public rights: string;

  constructor(
    @Inject(I18NEXT_SERVICE) public i18Next: ITranslationService) {
    this.displayTitle = true;
    this.rights = "";
  }


  ngOnInit() {
    if (this.permissions) {
      this.rights = this.GetRights();
    }
  }

  private GetRights(): string {
    let rights: string[] = [];

    if (this.permissions != null) {
      if (this.permissions.hasFullAccessRights) {
        rights.push(this.i18Next.t("profile.provider.microsoft.exchange.rights.fullAccess"));
      }

      if (this.permissions.hasSendAsRights) {
        rights.push(this.i18Next.t("profile.provider.microsoft.exchange.rights.sendAs"));
      }

      if (this.permissions.hasCalendarRights) {
        rights.push(this.i18Next.t("profile.provider.microsoft.exchange.rights.calendar"));
      }

      if (this.permissions.hasFoldersRights) {
        rights.push(this.i18Next.t("profile.provider.microsoft.exchange.rights.folders"));
      }
    }
    return rights.join(" | ");
  }

}

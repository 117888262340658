import { Component, OnInit, Inject } from '@angular/core';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { ReportProfileBaseModel, UserModel, BaseProviderInstanceResourceModel, MicrosoftOfficeReportProfileModel, SharePointReportProfileDelegate, SharePointReportProfile, AlertInvalidStateFeaturedModel, SharePointReportProfilePermissions } from '@i2a/web-api-client';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ProfileHelper } from 'projects/web-user-profile/src/app/helpers/profile-helper';
import { SharePointDelegateConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/share-point-delegate-configuration';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import dayJS from 'dayjs';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';
import { SharePointDelegate } from 'projects/web-user-profile/src/app/models/providers/microsoft/share-point/share-point-delegate';
import { SharePointDelegates } from 'projects/web-user-profile/src/app/models/providers/microsoft/share-point/share-point-delegates';

@RegisterMyProfileComponent(TemplateConstants.MODULE_SHAREPOINT_DELEGATE)
@Component({
  selector: 'i2a-share-point-delegate-module',
  templateUrl: './share-point-delegate-module.component.html',
  styleUrls: ['./share-point-delegate-module.component.scss']
})
export class SharePointDelegateModuleComponent extends ProfileBaseClassComponent<SharePointDelegateConfiguration,SharePointReportProfile> implements OnInit, IModule<SharePointDelegateConfiguration> {
  private resource: BaseProviderInstanceResourceModel;

  public delegates: SharePointDelegate[] = [];
  public externalDelegates: SharePointDelegate[] = [];
  
  public showMore: boolean;
  public showMoreAfter: number;
  public maxShowMore: number;
  public isVisibleButtonsShowMore: boolean;
  private isActiveShowMoreOption: boolean;

  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, null);
  }

  ngOnInit() {
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: SharePointDelegateConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel, resourceTemplate?: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.resource = resource;
    
    this.resourceProfile = (<MicrosoftOfficeReportProfileModel>profile).sharePointProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);

    let allDelegates: SharePointDelegates = SharePointDelegateModuleComponent.SetDelegates(this.resourceProfile?.delegates, this.resourceProfile?.permissions, this.configuration, this.i18Next);
    if (allDelegates) {
      allDelegates.delegates?.forEach((d: SharePointDelegate) => {
        if (d.permissions?.isAnonymousUser || d.permissions?.isTheWholeCompany) {
          this.delegates.unshift(d);
        } else {
          this.delegates.push(d);
        }
      });    
      this.externalDelegates = allDelegates.externalDelegates;
    }

    this.showMoreAfter = configuration.showMoreAfter ?? 0;
    this.isActiveShowMoreOption = this.showMoreAfter > 0;
    this.isVisibleButtonsShowMore = this.isActiveShowMoreOption && (this.delegates?.length + (this.externalDelegates?.length > 0 ? 1 : 0)) > this.showMoreAfter;
    this.setShowMoreValue();
  }

  public static SetDelegates(resourceProfileDelegates: SharePointReportProfileDelegate[]
                           , resourceProfilePermissions: SharePointReportProfilePermissions[]
                           , configuration: SharePointDelegateConfiguration
                           , i18Next: ITranslationService): SharePointDelegates {
    var delegates: SharePointDelegate[] = [];
    var externalDelegates: SharePointDelegate[] = [];
    var alldelegates = resourceProfileDelegates?.map(delegate => {
      var result = { accesses: delegate, permissions: null };
      if (!configuration.displayOnlyAccesses) {
        var idx = -1;
        if (delegate.userId != null) {
          idx = resourceProfilePermissions?.findIndex(d => d.userId != null && d.userId == delegate.userId);
        }
        else if (delegate.providerInstanceUser != null) {
          idx = resourceProfilePermissions?.findIndex(d => d.providerInstanceUser != null && d.providerInstanceUser.providerInstanceResourceId == delegate.providerInstanceUser.providerInstanceResourceId);
        }
        else {
          idx = resourceProfilePermissions?.findIndex(p => p.logonUserId == delegate.logonUserId);
        }
        if (idx >= 0) {
          result.permissions = resourceProfilePermissions[idx];
        }
      }
      return result;
    });

    delegates = alldelegates?.filter(d => d.accesses.foldersAccessed.length > 0 
                                       && !d.accesses.isAnonymousUser
                                       && ((d.accesses.isExternalUser && !configuration.groupExternalDelegates) || !d.accesses.isExternalUser));
    let anonymousUsers = alldelegates?.filter(d => d.accesses.foldersAccessed.length > 0 && d.accesses.isAnonymousUser);
    SharePointDelegateModuleComponent.processDelegates(delegates, anonymousUsers);
    if (configuration.groupExternalDelegates) {
      externalDelegates = alldelegates?.filter(d => d.accesses.foldersAccessed.length > 0 && d.accesses.isExternalUser);
    }
    if (externalDelegates.length < configuration.groupExternalDelegatesAt) {
      externalDelegates.forEach(d => {
        delegates.push(d);
      });
      externalDelegates = [];
    }
    delegates.sort((a, b) => { return ProfileHelper.compare(a.accesses, b.accesses, i18Next); });
    return {
      delegates: delegates,
      externalDelegates: externalDelegates
    }
  }

  public static processDelegates(delegates: SharePointDelegate[], delegateToAdd: SharePointDelegate[]) {
    if (delegateToAdd.length > 0) {
      let delegate = delegateToAdd.shift();
      delegateToAdd.forEach(user => {
        user.accesses.foldersAccessed.forEach(f => {
          if (delegate.accesses.foldersAccessed.indexOf(f) == -1) {
            delegate.accesses.foldersAccessed.push(f);
          }
          if (dayJS(user.accesses.lastAccess).isAfter(dayJS(delegate.accesses.lastAccess))) {
            delegate.accesses.lastAccess = user.accesses.lastAccess;
          }
        })
      });
      delegate.accesses.foldersAccessed.sort();
      delegates.push(delegate);
    }

  }
  
  public onShowMore() {
    this.showMore = !this.showMore;
    this.setShowMoreValue();
  }

  private setShowMoreValue() {
    this.maxShowMore = (!this.showMore && this.isActiveShowMoreOption) ? (this.showMoreAfter - (this.externalDelegates?.length > 0 ? 1 : 0)) : this.delegates?.length;
  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }
}

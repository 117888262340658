import { BaseModel } from './base-model';

export class GeoPositionModel extends BaseModel {
  latitude: number;
  longitude: number;
  altitude: number|null;
  horizontalAccuracy: number|null;
  verticalAccuracy: number|null;
  speed: number|null;
  course: number|null;
}

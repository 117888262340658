import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'i2a-delegate-configuration-box',
  templateUrl: './delegate-configuration-box.component.html',
  styleUrls: ['./delegate-configuration-box.component.scss']
})
export class DelegateConfigurationBoxComponent implements OnInit {
  @Input() public displayIsInbox: boolean;
  @Input() public isInboxEnabled: boolean;
  @Input() public inboxInfo: boolean;

  @Input() public displayIsFolders: boolean;
  @Input() public isFoldersEnabled: boolean;
  @Input() public foldersInfo: boolean;

  @Input() public displayIsCalendar: boolean;
  @Input() public isCalendarEnabled: boolean;
  @Input() public calendarInfo: boolean;

  @Input() public displayIsInstantMessaging: boolean;
  @Input() public isInstantMessagingEnabled: boolean;
  @Input() public instantMessagingInfo: boolean;
  
  @Input() public displayInfo: boolean;
  
  constructor() { }

  ngOnInit() {
  }

}

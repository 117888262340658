import { AzureADAppPermissionGrantModel } from '../../../microsoft/azuread/profile/azure-adapp-permission-grant-model';
import { BaseProviderInstanceResourceModel } from '../../../base-provider-instance-resource-model';
import { ChangeResult } from '../../../change-result';
import { ReportProfileResourceChangesBaseModel } from '../../../reports/profile/report-profile-resource-changes-base-model';
import { ReportProfileRulesComputedDelegate } from '../../../profile/report-profile-rules-computed-delegate';

export class AzureADReportProfileChangesModel extends ReportProfileResourceChangesBaseModel<ReportProfileRulesComputedDelegate> {
  providerInstanceResource: BaseProviderInstanceResourceModel;
  azureADAppPermissionGrants: ChangeResult<AzureADAppPermissionGrantModel>;
  isEqual: boolean;
}

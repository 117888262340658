import { Component, OnInit, Input, Inject } from '@angular/core';
import { ReportProfileResourceBase, SharePointGroupMemberModel, SharePointReportProfileAlertAdministratorsModel } from '@i2a/web-api-client';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-share-point-administrators-modal',
  templateUrl: './share-point-administrators-modal.component.html',
  styleUrls: ['./share-point-administrators-modal.component.scss']
})
export class SharePointAdministratorsModalComponent implements OnInit {
  @Input() administrators: SharePointGroupMemberModel[];
  @Input() public resourceProfile: ReportProfileResourceBase<any>;
  @Input() public headerTitle: string; 

  public validation: SharePointReportProfileAlertAdministratorsModel;
  public canSend: boolean;
  public members: any[];
  public informationKey: string;


  constructor(
    public toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService,
    public session: SessionService
  ) {
    this.canSend = false;
  }

  private setCanSend() {
    setTimeout(() => {
      this.canSend = this.validation.invalidAdministratorList.length > 0
    });
  }

  ngOnInit() {
    this.validation = new SharePointReportProfileAlertAdministratorsModel();
    this.validation.invalidAdministratorList = [];
    this.validation.administrators = this.administrators;
    this.members = [];

    if (this.administrators) {
      this.administrators.filter(admin => !admin.isO365Group).forEach(administrator => {
        this.members.push({
          "id": administrator.id,
          "name": administrator.title,
          "checked": false
        });
      });
      if (this.members.length > 1) {
        this.informationKey = this.members.length > 1 ? 'modal.reportAnomaly.checkMembers' : 'modal.reportAnomaly.checkMember';
      } else if (this.members.length == 1 && this.administrators.length > 1) {
        this.informationKey = this.members.length > 1 ? 'modal.reportAnomaly.checkMembers' : 'modal.reportAnomaly.checkMember';
      } else {
        this.informationKey = null;
      }
      this.setCanSend();
    }
  }

  //#region members

  checkedMember(isChecked: boolean, member: any) {
    const index: number = this.validation.invalidAdministratorList.indexOf(member.id);
    if (isChecked && index === -1) {
      this.validation.invalidAdministratorList.push(member.id);
    }
    else if (!isChecked && index !== -1) {
      this.validation.invalidAdministratorList.splice(index, 1);
    }
    if (this.validation.invalidAdministratorList.length == this.administrators.length) {
      this.toastr.error(this.i18NextService.t("profile.provider.microsoft.sharePoint.administrators.alertCannotRemoveAllAdministrators"));
      setTimeout(() => {
        member.checked = false;
        this.checkedMember(false, member);
      });
    }
    this.setCanSend();
  }

  //#endregion
}

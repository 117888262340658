import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
import { ProfileItemBaseClassComponent } from '../../shared/modals/profile-item-base-class-component';
import { Constants, ReportProfileDeviceModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { DeviceWithAlert } from 'projects/web-user-profile/src/app/models/users/device-with-alert';
import { DeviceService } from 'projects/web-user-profile/src/app/services/device.service';

@Component({
  selector: 'i2a-rules-computed-delegate-user-device',
  templateUrl: './rules-computed-delegate-user-device.component.html',
  styleUrls: ['./rules-computed-delegate-user-device.component.scss']
})
export class RulesComputedDelegateUserDeviceComponent extends ProfileItemBaseClassComponent<ReportProfileResourceBase<any>> implements OnInit, OnDestroy {
  @Input() public devices: ReportProfileDeviceModel[];

  public itemsRegistered: DeviceWithAlert[];

  public get isMicrosoft(): boolean {
    return this.providerInstanceResource.providerId == Constants.PROVIDER_ID_MICROSOFT;
  }

  public get isZimbra(): boolean {
    return this.providerInstanceResource.providerId == Constants.PROVIDER_ID_ZIMBRA;
  }

  constructor(
    private deviceService: DeviceService,
    modalService: NgbModal,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  setItems() {
    if (this.devices && this.alerts) {
      super.setItems();

      this.itemsRegistered = this.deviceService.mapRegisteredDevices(this.alerts, this.devices);
      this.itemsRegistered.forEach(item => {
        this.hasAlert = this.hasAlert || item.hasAlert;
        this.hasAlertRecentlyClosed = this.hasAlertRecentlyClosed || item.hasAlertRecentlyClosed;
      });

      this.id = `${this.componentId}-${this.providerInstanceResource.providerTypeId}-${this.providerInstanceResource.providerInstanceResourceId}-RulesComputedDelegateUserDevice`;
    }
  }

  warnClick(event): void {
    this.deviceService.warnClickUserDevices(this.resourceProfile, null, this.profile, this.itemsRegistered, null, this.initAlertModel.bind(this), this.setAlertsProcessed.bind(this));
  }
}

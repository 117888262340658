import { Component, OnInit, Input, Inject } from '@angular/core';
import { SharePointReportProfileAlertExternalPermissionsModel, SharePointReportProfileAlertDelegateModel, SharePointReportProfilePermissions, SharePointReportProfileAlertPermissionsModel, ReportProfileResourceBase, SharePointReportProfile } from '@i2a/web-api-client';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-share-point-external-permissions-modal',
  templateUrl: './share-point-external-permissions-modal.component.html',
  styleUrls: ['./share-point-external-permissions-modal.component.scss']
})
export class SharePointExternalPermissionsModalComponent implements OnInit {
  @Input() public permissions: SharePointReportProfilePermissions[];
  @Input() public resourceProfile: SharePointReportProfile;

  public validation: SharePointReportProfileAlertExternalPermissionsModel;
  public canSend: boolean;
  public delegatePaths: any[];//TODO CRADO
  public name: string;
  public allAccounts: boolean;
  public hasSpecificLink: boolean = false;
  public informationKey: string;

  constructor(
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService,
    public session: SessionService
  ) {
    this.canSend = false;
    this.allAccounts = false;
  }

  private setCanSend() {
    setTimeout(() => {
      this.hasSpecificLink = false;
      let allAccountsChecked = 0;
      for (let index = 0; index < this.validation.permissions.length; index++) {
        const delegate = this.validation.permissions[index];
        this.canSend = this.canSend || delegate.invalidPaths.length > 0 || delegate.allPaths;

        if (delegate.allPaths) {
          allAccountsChecked++;
        }

        if (!this.hasSpecificLink) {
          if (delegate.allPaths) {
            var perms = Object.values(delegate.permissions.driveItems);
            this.hasSpecificLink = perms.findIndex(p => p.isSpecificLink) >= 0;
          } else if (delegate.invalidPaths.length > 0) {
            for (let pathIndex = 0; pathIndex < delegate.invalidPaths.length; pathIndex++) {
              const path = delegate.invalidPaths[pathIndex];
              var perm = delegate.permissions.driveItems[path];
              this.hasSpecificLink = perm.isSpecificLink;
              if (this.hasSpecificLink) {
                break;
              }
            }
          }
        }
      }
      this.allAccounts = allAccountsChecked == this.validation.permissions.length;
    });
  }

  ngOnInit() {
    this.validation = new SharePointReportProfileAlertExternalPermissionsModel();
    this.validation.permissions = [];

    if (this.permissions) {
      this.informationKey = this.permissions.length > 1 ? 'modal.reportAnomaly.checkMembers' : 'modal.reportAnomaly.checkMember';
      this.delegatePaths = [];
      for (let index = 0; index < this.permissions.length; index++) {
        const permission = this.permissions[index];
        let delegateValidation = new SharePointReportProfileAlertPermissionsModel;
        delegateValidation.invalidPaths = [];
        delegateValidation.allPaths = true;
        delegateValidation.permissions = permission;
        var delegatePath = {
          logonUserId: permission.logonUserId,
          paths: []
        }
        Object.keys(permission.driveItems).forEach(key => {
          delegatePath.paths.push({
            "path": key,
            "checked": false,
            "hasSensitivityLabel": permission.driveItems[key].hasSensitivityLabel,
            "sensitivityLabel": permission.driveItems[key].sensitivityLabel,
            "roles": permission.driveItems[key].rights
          });
        });
        this.delegatePaths.push(delegatePath);
        this.validation.permissions.push(delegateValidation);
      }
      this.setCanSend();
    }
  }

  getPaths(logonUserId: string): string {
    return this.delegatePaths.find(d => d.logonUserId == logonUserId).paths;
  }

  allAccountsChecked(isChecked: boolean) {
    this.validation.permissions.forEach(d => {
      d.allPaths = isChecked;
    })
    this.setCanSend();
  }
}

import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
import { MicrosoftOfficeProfileProviderDatasourceService, SharePointReportProfileAlertDelegateModel, SharePointReportProfile } from '@i2a/web-api-client';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { SharePointDelegateModalComponent } from '../share-point-delegate-modal/share-point-delegate-modal.component';
import { ToastrService } from 'ngx-toastr';
import { DelegateFolders } from 'projects/web-user-profile/src/app/models/delegate-folders';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { SharePointDelegate } from 'projects/web-user-profile/src/app/models/providers/microsoft/share-point/share-point-delegate';

@Component({
  selector: 'i2a-share-point-delegate',
  templateUrl: './share-point-delegate.component.html',
  styleUrls: ['./share-point-delegate.component.scss']
})
export class SharePointDelegateComponent extends ProfileItemBaseClassComponent<SharePointReportProfile> implements OnInit, OnDestroy {

  @Input() public delegate: SharePointDelegate;
  @Input() public viewWarnButton: boolean;

  public name: string;

  public delegateFolders: DelegateFolders;

  constructor(
    modalService: NgbModal,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  setItems() {
    if (this.delegate && this.alerts) {
      super.setItems();
      if (this.delegate.accesses.isAnonymousUser) {
        this.name = this.i18Next.t("profile.provider.microsoft.sharePoint.anonymous");
      }
      else {
        this.name = this.delegate.accesses.name;
      }
      this.hasAlert = this.hasAlertInAlerts(false);
      this.hasAlertRecentlyClosed = this.hasAlertInAlerts(true);

      this.delegateFolders = new DelegateFolders({
        folders: this.delegate.accesses.foldersAccessed,
        driveItems: this.delegate.permissions != null ? this.delegate.permissions.driveItems : null,
        isAnonymous: this.delegate.accesses.isAnonymousUser,
        isTheWholeCompany: false,
        isApplication: this.delegate.accesses.isApplication,
        isExternalUser: this.delegate.accesses.isExternalUser,
        logonUserId: this.delegate.accesses.logonUserId,
        name: this.delegate.accesses.name,
        providerInstanceResource: this.delegate.accesses.providerInstanceResource,
        providerInstanceUser: this.delegate.accesses.providerInstanceUser,
        userId: this.delegate.accesses.userId
      });
    }
  }

  private hasAlertInAlerts(done: boolean): boolean {
    return AlertReasonHelper.alertsContainsDelegate(this.alerts, this.delegate.accesses, done);
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(SharePointDelegateModalComponent, { windowClass: 'id-modal', centered: true, size: 'lg' });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.delegate = this.delegate;
    modalRef.result.then((model: SharePointReportProfileAlertDelegateModel) => {
      this.initAlertModel(model);
      model.providerInstanceResource = this.providerInstanceResource;
      model.permissions.providerInstanceResource = this.providerInstanceResource;
      if (model.permissions != null) {
        model.permissions.endDate = this.profile.endDate;
        model.permissions.providerInstanceResource = this.providerInstanceResource;
        model.permissions.comments = model.comments;
      }
      this.subscriptions.add(this.microsoftDatasource.alertSharePointDelegate(this.profile.providerInstanceId, this.profile.userId, model).subscribe(model => {
        this.setAlertsProcessed(model);
      }));
    }).catch((error) => {
      // console.log(error);
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
import { Component, OnInit, ViewChild, OnDestroy, ComponentRef, Inject, Output, EventEmitter } from '@angular/core';
import { TemplateService } from 'projects/web-user-profile/src/app/services/template.service';
import { ReportProfileBaseModel, UserModel, ConnectedAccountModel, Constants, BaseProviderInstanceResourceModel, AlertInvalidStateFeaturedModel, ReportProfileResource, AlertConstants, ReportProfileResourceBase, ProviderInstanceResourceModel } from '@i2a/web-api-client';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { IBaseModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-base-module';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { ProfileBaseClassComponent } from '../profile-base-class-component';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { IModuleApplicationContainer } from 'projects/web-user-profile/src/app/models/templates/modules/i-module-application-container';
import { TemplateApplication } from 'projects/web-user-profile/src/app/models/templates/template-application';
import { IModuleResourcesContainer } from 'projects/web-user-profile/src/app/models/templates/modules/i-module-resources-container';
import { ApplicationConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/application-configuration';
import { ContainerDirective } from 'projects/web-user-profile/src/app/directives/container-directive';
import { WarningInfo } from 'projects/web-user-profile/src/app/models/warnings/warning-info';
import { Subscription } from 'rxjs';

@RegisterMyProfileComponent(TemplateConstants.MODULE_APPLICATION_CONTAINER)
@Component({
  selector: 'i2a-application-container-container',
  templateUrl: './application-container.component.html',
  styleUrls: ['./application-container.component.scss']
})
export class ApplicationContainerComponent extends ProfileBaseClassComponent<ApplicationConfiguration, ReportProfileResourceBase<any>>
  implements OnInit, OnDestroy, IModuleApplicationContainer<ApplicationConfiguration> {

  private applicationComponents: ComponentRef<IBaseModule>[];
  protected resourceComponents: ComponentRef<IModuleResourcesContainer<any>>[];
  protected modules: IBaseModule[];

  public template: TemplateApplication;
  public connectedAccount: ConnectedAccountModel;

  public alertIsSent: boolean;

  @ViewChild('application', { static: true, read: ContainerDirective }) container: ContainerDirective;
  @ViewChild('resources', { static: true, read: ContainerDirective }) resourcesContainer: ContainerDirective;

  @Output() public onResourceToValidate: EventEmitter<ReportProfileResource> = new EventEmitter<ReportProfileResource>();

  public displayUser: boolean;

  public displayResources: boolean = true;

  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService,
    protected templateService: TemplateService) {
    super(toastr, i18Next, null);
    this.applicationComponents = [];
    this.resourceComponents = [];
    this.modules = [];
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: ApplicationConfiguration, alerts: AlertInvalidStateFeaturedModel[], template: TemplateApplication): void {
    super.baseInitialize(user, profile, configuration, alerts);
    this.template = template;
  }

  setIsAlert(isAlert: boolean) {
    super.setIsAlert(isAlert);
    this.modules.forEach(m => {
      m.setIsAlert(isAlert);
    })
  }

  ngOnInit() {
    if (this.user && this.profile && this.template && this.alerts) {
      //APPLICATION MODULES
      this.template.modules.forEach(module => {
        if (module.isEnabled) {
          var component = this.templateService.loadComponent<IModule<any>>(this.container.viewContainerRef, module.id);
          if (component) {
            this.applicationComponents.push(<ComponentRef<any>>component);
            this.modules.push(component.instance);
            this.subscriptions.add(component.instance.onAlertSent.subscribe(e => this.alertSent(e)));
            this.subscriptions.add(component.instance.onHasWarnings.subscribe(e => this.hasWarning(e)));
            component.instance.initialize(this.user, this.profile, module.configuration, this.profile.alerts);
          }
        }
      });

      if (this.displayResources) {
        var resources = this.profile.providerInstanceResources.filter(resource => resource.providerTypeId.localeCompare(this.template.providerTypeId, undefined, { sensitivity: 'base' }) == 0);
        //RESOURCE CONTAINER
        ApplicationContainerComponent.loadResources(this.template,
          this.profile,
          resources,
          this.user,
          this.alerts,
          this.templateService,
          this.resourcesContainer,
          this.resourceComponents,
          this.modules,
          this.subscriptions,
          this.resourceToValidate.bind(this),
          this.alertSent.bind(this),
          this.warnings,
          this.hasWarnings.bind(this));
      }
    }
  }
  
  resourceToValidate(report: ReportProfileResource) {
    this.onResourceToValidate.emit(report);
  }

  public static loadResources(template: TemplateApplication,
    profile: ReportProfileBaseModel,
    resources: ProviderInstanceResourceModel[],
    user: UserModel,
    alerts: AlertInvalidStateFeaturedModel[],
    templateService: TemplateService,
    resourcesContainer: ContainerDirective,
    resourceComponents: ComponentRef<IModuleResourcesContainer<any>>[],
    modules: IBaseModule[],
    subscriptions: Subscription,
    resourceToValidate: (any),
    onAlertSent: (any),
    warnings: WarningInfo[],
    hasWarnings: (any)): void {
    if (template.resourceTemplate.containerModule.isEnabled) {
      resources.forEach(resource => {
        if (this.showResourceIfNodData(resource)) {
          var component = templateService.loadComponent<IModuleResourcesContainer<any>>(resourcesContainer.viewContainerRef, template.resourceTemplate.containerModule.id);
          if (component) {
            resourceComponents.push(<ComponentRef<any>>component);
            modules.push(component.instance);
            let resourceAlerts = ApplicationContainerComponent.getResourceAlert(resource, alerts, resourceToValidate);
            subscriptions.add(component.instance.onAlertSent.subscribe(e => onAlertSent(e)));
            subscriptions.add(component.instance.onHasWarnings.subscribe(e => hasWarnings(e, warnings)));
            component.instance.initialize(user, profile, template.resourceTemplate.containerModule.configuration, resourceAlerts, resource, template.resourceTemplate);
          }
        }
      });
    }
  }

  protected static showResourceIfNodData(resource: BaseProviderInstanceResourceModel): boolean {
    return true;
  }

  public static getResourceAlert(resource: BaseProviderInstanceResourceModel, alerts: AlertInvalidStateFeaturedModel[], resourceToValidate: (any)): AlertInvalidStateFeaturedModel[] {
    var resourceAlerts = alerts.filter(a =>
      (a.resources.findIndex(r => r.providerInstanceResourceId == resource.providerInstanceResourceId && r.providerTypeId == resource.providerTypeId) >= 0
        &&
        a.annotations.findIndex((annotation) => annotation.key.localeCompare(AlertConstants.ALERT_ANNOTATION_SUBTYPE, undefined, { sensitivity: 'base' }) == 0 &&
          (annotation.value.localeCompare(AlertConstants.ALERT_ANNOTATION_SUBTYPE_CONFIGURATION, undefined, { sensitivity: 'base' }) == 0 ||
            annotation.value.localeCompare(AlertConstants.ALERT_ANNOTATION_SUBTYPE_DELEGATION, undefined, { sensitivity: 'base' }) == 0)) >= 0
      ) || (resource.providerId == Constants.PROVIDER_ID_MICROSOFT && resource.providerTypeId == Constants.PROVIDER_TYPE_MAILING &&
        a.annotations.findIndex((annotation) => annotation.key.localeCompare(AlertConstants.ALERT_ANNOTATION_SUBTYPE, undefined, { sensitivity: 'base' }) == 0 &&
          annotation.value.localeCompare(AlertConstants.ALERT_ANNOTATION_INVALID_STATE_SUBTYPE_PROFILE_USER_ACTIVITY_DEVICES, undefined, { sensitivity: 'base' }) == 0) >= 0)
    );
    let resourceToEmit = new ReportProfileResource();
    resourceToEmit.providerInstanceResource = resource;
    resourceToEmit.isValid = resourceAlerts.length == 0;
    resourceToValidate(resourceToEmit);

    return resourceAlerts;
  }

  ngAfterViewInit() {

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  alertSent(e: AlertInvalidStateFeaturedModel): void {
    this.onAlertSent.emit(e);
  }

  hasWarning(e: Array<WarningInfo>): void {
    this.warnings = e;
    this.onHasWarnings.emit(e);
  }

  updateAlerts(alerts: AlertInvalidStateFeaturedModel[]) {
    super.updateAlerts(alerts);

    this.applicationComponents.forEach(c => {
      if (c.instance.onAlertSent)
        c.instance.updateAlerts(alerts)
    });
    if (this.displayResources) {
      this.resourceComponents.forEach(c => {
        if (c.instance.onAlertSent)
          c.instance.updateAlerts(ApplicationContainerComponent.getResourceAlert(c.instance.providerInstanceResource, alerts, this.resourceToValidate.bind(this)));
      });
    }
  }
}
<div class="mb-1 short-title-bar">
  <div [ngClass]="{'card' : true, 'id-title-bar-card': true, 'has-alert': hasAlert, 'has-alert-recently-closed': hasAlertRecentlyClosed, 'id-title-bar-card-unset': noBackground}" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseDetails">
    <div class="card-body p-1">
      
      <div class="container-fluid no-gutters p-0">
        <div class="row id-title-bar no-gutters" (click)="isCollapsed = !isCollapsed">
          <div class="id-col-collapse" *ngIf="!right">
            <div class="id-collapse px-2">
              <ng-container *ngIf="isCollapsed">
                <fa-icon [icon]="['fal', 'chevron-right']"></fa-icon>
              </ng-container>
              <ng-container *ngIf="!isCollapsed">
                <fa-icon [icon]="['fal', 'chevron-down']"></fa-icon>
              </ng-container>
            </div>
          </div>

          <div [ngClass]="{'id-col-title': colbuttons.childNodes.length == 0, 'id-col-title-buttons':colbuttons.childNodes.length > 0}">
            <div class="id-title-bar-title">
              <ng-content select="[title]"></ng-content>
            </div>
          </div>
          <div #colbuttons
            [ngClass]="{'id-col-buttons-none': colbuttons.childNodes.length == 0, 'id-col-buttons':colbuttons.childNodes.length > 0}">
            <ng-content select="[buttons]"></ng-content>
          </div>
          <div class="id-col-collapse" *ngIf="right">
            <div class="id-collapse px-2">
              <ng-container *ngIf="isCollapsed">
                <fa-icon [icon]="['fal', 'chevron-down']" size="lg"></fa-icon>
              </ng-container>
              <ng-container *ngIf="!isCollapsed">
                <fa-icon [icon]="['fal', 'chevron-up']" size="lg"></fa-icon>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="id-title-bar-content" id="collapseDetails" [ngbCollapse]="isCollapsed">
    <ng-content select="[body]"></ng-content>
  </div>
</div>
<i2a-profile-alert-base-modal [manualDescriptionKey]="'applyCorrection.resourceNotBelongToMe.manual.description'"
  [manualLinkKey]="'applyCorrection.resourceNotBelongToMe.manual.link'"
  [automaticDescriptionKey]="'applyCorrection.resourceNotBelongToMe.automatic.description'" [displayHelp]="false"
  [validation]="validation" [canSend]="canSend" [modalTitle]="'modal.notBelongToMe.title' | i18next "
  [loading]="!resource || !owner || !owners"
  [ignoreCommentToSend]="!(hideI2AOwners && owners && owners.length == 0) && session.IgnoreCommentToSend"
  [noOverflow]="!(session.DisplayComment || hideI2AOwners && owners && owners.length == 0)"
  [displayComment]="session.DisplayComment" [displaySend]="!(owners && owners.length == 0 && hideI2AOwners)"
  [resourceProfile]="resourceProfile" [headerTitle]="headerTitle">

  <ng-container body>
    <div class="container-fluid mb-3">
      <div class="row mt-3" *ngIf="owners && owners.length == 0">
        <div class="col">
          {{ "profile.resourceNotBelongToMe.noOwnerFound" | i18next }}
          <span *ngIf="hideI2AOwners && session.DisplayComment">
            {{ "profile.resourceNotBelongToMe.sendComment" | i18next }}
          </span>
          <span *ngIf="hideI2AOwners && !session.DisplayComment">
            {{ "profile.resourceNotBelongToMe.youCannotChangeTheManager" | i18next }}
          </span>
        </div>
      </div>
      <div class="row" *ngIf="owners && owners.length > 0">
        <div class="col">
          <div>
            <label for="select-owners">
                            <input type="radio" id="select-owners" name="2" [(ngModel)]="withOwner"
                                (change)="withOwnerChange($event)" [value]="true"
                                [ngClass]="{'with-gap': true, 'd-none': hideI2AOwners }">
              {{'profile.resourceNotBelongToMe.selectOwner' | i18next }}
            </label>

            <input type="text" class="form-control" [(ngModel)]="ownerUser" [ngbTypeahead]="searchOwnerUser"
              [inputFormatter]="formatterOwnerUser" [resultFormatter]="formatterOwnerUser"
                            #ownerListInstance="ngbTypeahead" [editable]='false'
                            [placeholder]="'form.clickToGetTheList' | i18next"
              (focus)="ownerFocus$.next($any($event).target.value)"
              (click)="ownerClick$.next($any($event).target.value)" />


          </div>
        </div>
      </div>
      <div class="row mt-3" *ngIf="!hideI2AOwners">
        <div class="col">
          <div>
            <label for="typeahead-users">
              <input type="radio" name="2" id="2"
                                [ngClass]="{'with-gap': true, 'd-none': !(resource && owner && owners && owners.length > 0) }"
                                class="" [(ngModel)]="withOwner" (change)="withI2AUserChange($event)"
                                id="typeahead-users" [value]="false">
              {{'profile.resourceNotBelongToMe.searchUser' | i18next }}
            </label>
            <input type="text" class="form-control" [(ngModel)]="user" [ngbTypeahead]="search"
              #userListInstance="ngbTypeahead" [inputFormatter]="formatter" [resultFormatter]="formatter"
              [editable]='false' [placeholder]="'form.clickToGetTheList' | i18next"
              (focus)="userFocus$.next($any($event).target.value)"
              (click)="userClick$.next($any($event).target.value)" />
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col mt-3 mb-3" *ngIf="!hideI2AOwners && owners && owners.length > 0">
                    <span
                        *ngIf="withOwner && ownerUser">{{'profile.resourceNotBelongToMe.selectedUser' | i18next : {'userFullName' : ownerUser.fullName} }}</span>
                    <span
                        *ngIf="!withOwner && user">{{'profile.resourceNotBelongToMe.selectedUser' | i18next : {'userFullName' : user.fullName} }}</span>
        </div>
      </div>
    </div>
  </ng-container>

</i2a-profile-alert-base-modal>
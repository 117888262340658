import { Injectable, Inject } from '@angular/core';
import { StringHelper } from './string-helper';
import { ReportProfileDelegate, AlertInvalidStateFeaturedModel, KeyValuePair, AlertConstants, ProviderAdminObjectThreatReasonModel } from '@i2a/web-api-client';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';

@Injectable({
    providedIn: 'root'
})
export class AlertReasonHelper {

    constructor() {
    }


    public static findReasons(alert: AlertInvalidStateFeaturedModel, key: string, value: string): ProviderAdminObjectThreatReasonModel[] {
        var reasons: ProviderAdminObjectThreatReasonModel[] = [];
        if (alert) {
            alert.reasons.forEach((r) => {
                let keyValuePair = r.reasonContext.find(c => c.key == key);
                if (keyValuePair != null && keyValuePair.value == value) {
                    reasons.push(r);
                }
            });
        }
        return reasons;
    }

    public static GetObject<T>(reasonContext: KeyValuePair[], key: string): T | null {
        var keyValuePair = reasonContext.find(r => r.key == key);

        if (keyValuePair != null) {
            return JSON.parse(keyValuePair.value);
        } else {
            return null;
        }
    }

    public static GetObjects<T>(reasonContext: KeyValuePair[], key: string): T[] | null {
        var keyValuePairs = reasonContext.filter(r => r.key == key);

        if (keyValuePairs != null) {
            return keyValuePairs.map(kv => JSON.parse(kv.value));
        } else {
            return null;
        }
    }

    public static GetStrings(reasonContext: KeyValuePair[], key: string): string[] {
        return reasonContext.filter(r => r.key == key).map(kv => kv.value);
    }

    public static GetString(reasonContext: KeyValuePair[], key: string): string {
        var kv = reasonContext.find(r => r.key == key);
        if (kv != null) {
            return kv.value;
        }
        else {
            return null;
        }
    }

    public static GetIds(alerts: AlertInvalidStateFeaturedModel[], key: string, done: boolean): { [id: string]: boolean; } {
        let idInAlert = {};
        if (alerts) {
            let filteredAlerts = alerts.filter(alert => (done && alert.end != null) || (!done && alert.end == null));
            filteredAlerts.forEach(a => {
                a.reasons.forEach(r => {
                    let ids = AlertReasonHelper.GetStrings(r.reasonContext, key);
                    if (ids != null) {
                        ids.forEach(id => {
                            idInAlert[id] = true;
                        });
                    }
                });
            });
        }
        return idInAlert;
    }

    public static containsValue(reasonContext: KeyValuePair[], key: string, value: string): boolean {
        let reasonContextValue = AlertReasonHelper.GetString(reasonContext, key);
        return reasonContextValue != null && reasonContextValue == value;
    }

    public static alertsContainsDelegate(alerts: AlertInvalidStateFeaturedModel[], delegate: ReportProfileDelegate, done: boolean): boolean {
        let result = false;
        if (alerts) {
            let filteredAlerts = alerts.filter(alert => (done && alert.end != null) || (!done && alert.end == null));
            for (let idx = 0; idx < filteredAlerts.length; idx++) {
                let alert = filteredAlerts[idx];
                for (let index = 0; index < alert.reasons.length; index++) {
                    const element = alert.reasons[index];
                    if (!StringHelper.isNullOrWhitespace(delegate.userId) &&
                        AlertReasonHelper.containsValue(element.reasonContext, AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_USER_ID, delegate.userId)) {
                        result = true;
                        break;
                    } else if (delegate.providerInstanceUser != null &&
                        AlertReasonHelper.containsValue(element.reasonContext, AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_PROVIDERINSTANCE_USER, delegate.providerInstanceUser.providerInstanceResourceId)) {
                        result = true;
                        break;
                    } else if (!StringHelper.isNullOrWhitespace(delegate.logonUserId) &&
                        AlertReasonHelper.containsValue(element.reasonContext, AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_LOGON_USERID, delegate.logonUserId)) {
                        result = true;
                        break;
                    }
                }
                if (result) {
                    break;
                }
            };
        }
        return result;
    }

    public static alertsContainsKey(alerts: AlertInvalidStateFeaturedModel[], key: string): boolean {
        let result = false;
        if (alerts) {
            for (let idx = 0; idx < alerts.length; idx++) {
                let alert = alerts[idx];
                for (let index = 0; index < alert.reasons.length; index++) {
                    const element = alert.reasons[index];
                    if (element.reasonContext.find(c => c.key == key)) {
                        result = true;
                        break;
                    }
                };
                if (result) {
                    break;
                }
            };
        }
        return result;
    }

    public static alertsContainsReason(alerts: AlertInvalidStateFeaturedModel[], reasonKey: string): boolean {
        let result = false;
        if (alerts) {
            for (let idx = 0; idx < alerts.length; idx++) {
                let alert = alerts[idx];
                for (let index = 0; index < alert.reasons.length; index++) {
                    const reason = alert.reasons[index];
                    if (reason.key == reasonKey) {
                        result = true;
                        break;
                    }
                };
                if (result) {
                    break;
                }
            };
        }
        return result;
    }

    public static GetalertsContainsReason(alerts: AlertInvalidStateFeaturedModel[], reasonKey: string, done: boolean): AlertInvalidStateFeaturedModel[] {
        let result: AlertInvalidStateFeaturedModel[] = [];
        if (alerts) {
            let filteredAlerts = alerts.filter(alert => (done && alert.end != null) || (!done && alert.end == null));
            for (let idx = 0; idx < filteredAlerts.length; idx++) {
                let alert = filteredAlerts[idx];
                for (let index = 0; index < alert.reasons.length; index++) {
                    const reason = alert.reasons[index];
                    if (reason.key == reasonKey) {
                        result.push(alert);
                        break;
                    }
                };
            };
        }
        return result;
    }


    public static alertsContainsKeyValuePair(alerts: AlertInvalidStateFeaturedModel[], key: string, value: string, done: boolean): boolean {
        let result = false;
        let filteredAlerts = alerts.filter(alert => (done && alert.end != null) || (!done && alert.end == null));
        for (let idx = 0; idx < filteredAlerts.length; idx++) {
            let alert = filteredAlerts[idx];
            for (let index = 0; index < alert.reasons.length; index++) {
                const element = alert.reasons[index];
                result = AlertReasonHelper.containsValue(element.reasonContext, key, value);
                if (result) {
                    break;
                }
            };
            if (result) {
                break;
            }
        };
        return result;
    }

}


export class ProfileCountryModel {
  id: string;
  providerId: string;
  providerInstanceId: string;
  userId: string;
  country: string;
  expirationDate: Date|null;
  creationDate: Date;
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'i2a-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss']
})
export class PageContainerComponent implements OnInit {
  @Input() public title: string;
  @Input() public withPadding: boolean;
  
  constructor() { }

  ngOnInit() {
  }

}

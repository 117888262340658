<i2a-profile-alert-base-modal [manualDescriptionKey]="'applyCorrection.user.origin.manual.description'"
  [manualLinkKey]="'applyCorrection.user.origin.manual.link'"
  [automaticDescriptionKey]="'applyCorrection.user.origin.automatic.description'" [validation]="validation"
  [ignoreCommentToSend]="session.IgnoreCommentToSend" [displayComment]="session.DisplayComment" [canSend]="canSend"
  [modalTitle]="'profile.confirmationOfInformationTitle' | i18next " [informationKey]="informationKey" [user]="user"
  [disableManualRemediation]="disableManualRemediation">

  <ng-container header-picto>
    <i2a-badge-icon>
      <ng-container icon>
        <fa-icon [icon]="['fal', 'map-marker-alt']" size="lg"></fa-icon>
      </ng-container>
    </i2a-badge-icon>
  </ng-container>

  <ng-container header-title>
    {{'profile.user.origin.title' | i18next }}<br>
  </ng-container>

  <ng-container body>
    <div class="container-fluid">
      <div class="row pt-3">
        <div class="col">
          <span *ngIf="selectedOrigins && selectedOrigins.length > 0" class="location-name">{{'profile.user.origin.reportRecentOrigins' | i18next}}</span>

          <table *ngIf="selectedOrigins && selectedOrigins.length > 0" class="table table-sm id-table">
            <tbody>
              <tr *ngFor="let selectedOrigin of selectedOrigins">
                <td scope="row">
                  <i2a-input-checkbox [(model)]="selectedOrigin.checked"
                    (modelChange)="checked($event, selectedOrigin, false)" [classNameChecked]="'btn-outline-danger'"
                    [className]="'btn-outline-dark'" [valueChecked]="true" [valueUnChecked]="false">
                  </i2a-input-checkbox>
                </td>
                <td scope="row" class="text-break w-100">
                  <div class="id-item-background align-bottom mr-1"
                    [ngStyle]="{'background-image': 'url(' + selectedOrigin.image + ')'}">
                  </div>
                  {{selectedOrigin.name}}
                </td>
              </tr>
            </tbody>
          </table>

          <span *ngIf="selectedUsualCountries && selectedUsualCountries.length > 0" class="location-name">{{'profile.user.origin.reportUsualCountries' | i18next}}</span>

          <table *ngIf="selectedUsualCountries && selectedUsualCountries.length > 0" class="table table-sm id-table">
            <tbody>
              <tr *ngFor="let selectedUsualCountry of selectedUsualCountries">
                <td scope="row">
                  <i2a-input-checkbox [(model)]="selectedUsualCountry.checked"
                    (modelChange)="checked($event, selectedUsualCountry, true)"
                    [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                    [valueUnChecked]="false"> </i2a-input-checkbox>
                </td>
                <td scope="row" class="text-break w-100">
                  <div class="id-item-background align-bottom mr-1"
                    [ngStyle]="{'background-image': 'url(' + selectedUsualCountry.image + ')'}">
                  </div>
                  {{selectedUsualCountry.name}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-container>

</i2a-profile-alert-base-modal>
import { Component, OnInit, Input, Inject } from '@angular/core';
import { SharePointReportProfileDelegate, SharePointReportProfileAlertDelegateModel, SharePointReportProfileAlertPermissionsModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';
import { SharePointDelegate } from 'projects/web-user-profile/src/app/models/providers/microsoft/share-point/share-point-delegate';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';
import { SharePointExternalDelegatesModalComponent } from '../share-point-external-delegates-modal/share-point-external-delegates-modal.component';

@Component({
  selector: 'i2a-share-point-delegate-modal',
  templateUrl: './share-point-delegate-modal.component.html',
  styleUrls: ['./share-point-delegate-modal.component.scss']
})
export class SharePointDelegateModalComponent implements OnInit {
  @Input() public delegate: SharePointDelegate;
  @Input() public resourceProfile: ReportProfileResourceBase<any>;

  public validation: SharePointReportProfileAlertDelegateModel;
  public canSend: boolean;
  public folders: any[];//TODO CRADO
  public name: string;

  public driveItemsPermissions: any[];//TODO CRADO
  public hasSpecificLink: boolean = false;
  public informationKey: string;
  public titleKey: string;

  constructor(
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService,
    public session: SessionService
  ) {
    this.canSend = false;
  }

  public setCanSend() {
    setTimeout(() => {
      this.canSend = this.validation.folders.length > 0 || this.validation.allFolders || this.validation.permissions.invalidPaths.length > 0 || this.validation.permissions.allPaths;

      this.hasSpecificLink = false;
      if (this.validation.permissions.allPaths) {
        var perms = Object.values(this.validation.permissions.permissions.driveItems);
        this.hasSpecificLink = perms.findIndex(p => p.isSpecificLink) >= 0;
      } else if (this.validation.permissions.invalidPaths.length > 0) {
        for (let pathIndex = 0; pathIndex < this.validation.permissions.invalidPaths.length; pathIndex++) {
          const path = this.validation.permissions.invalidPaths[pathIndex];
          var perm = this.validation.permissions.permissions.driveItems[path];
          this.hasSpecificLink = perm.isSpecificLink;
          if (this.hasSpecificLink) {
            break;
          }
        }
      }
    });
  }

  ngOnInit() {
    this.validation = new SharePointReportProfileAlertDelegateModel();
    this.validation.folders = [];
    this.validation.delegate = this.delegate.accesses;
    this.folders = [];
    let allFolders = false;
    this.driveItemsPermissions = [];
    if (this.delegate) {
      var items = this.delegate.accesses.foldersAccessed.length;
      items += this.delegate.permissions && Object.keys(this.delegate.permissions.driveItems).length > 0 ? Object.keys(this.delegate.permissions.driveItems).length : 0;
      this.informationKey = items > 1 ? 'modal.reportAnomaly.checkItems' : 'modal.reportAnomaly.checkItem';

      SharePointExternalDelegatesModalComponent.FoldersAccessedWithoutPermissions(this.delegate).forEach(folder => {
        this.folders.push({
          "name": folder,
          "checked": false
        });
        allFolders = true;
      });
      this.validation.allFolders = allFolders;
      if (this.delegate.accesses.isAnonymousUser) {
        this.name = this.i18Next.t("profile.provider.microsoft.sharePoint.anonymous");
      }
      else {
        this.name = this.delegate.accesses.name;
      }
      this.validation.permissions = new SharePointReportProfileAlertPermissionsModel();
      this.validation.permissions.invalidPaths = [];
      this.validation.permissions.permissions = this.delegate.permissions;
      if (this.delegate.permissions != null) {
        Object.keys(this.delegate.permissions.driveItems).forEach(driveItemKey => {
          this.driveItemsPermissions.push({
            "name": driveItemKey,
            "checked": false,
            "hasSensitivityLabel": this.delegate.permissions.driveItems[driveItemKey].hasSensitivityLabel,
            "sensitivityLabel": this.delegate.permissions.driveItems[driveItemKey].sensitivityLabel,
            "roles": this.delegate.permissions.driveItems[driveItemKey].rights
          });
        });
        this.validation.permissions.allPaths = true;
      }

      this.titleKey = this.driveItemsPermissions.length > 0 ? 'profile.provider.microsoft.sharePoint.allAccessesAndPermissions' : 'profile.provider.microsoft.sharePoint.allAccesses'

      this.setCanSend();
    }
  }
}
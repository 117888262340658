<a *ngIf="site" [id]="'sharepoint-site-' + site.id"></a>
<div [ngClass]="{'share-point-site-container': true, 'd-none': false }" class="share-point-site-container">
  <i2a-title-bar 
    [className]="className" 
    [guidedTourKey]="guidedTourKey" 
    [id]="id" 
    [isCollapsed]="isCollapsed"
    [linked]="true"
    [rounded]="false">

    <ng-container picture>
      <img  *ngIf="providerPhotoUrl"
            [ngClass]="{'id-provider-picture': true, 'pending-resource-action': hasDeleteAlert || hasNotBelongToMe || hasDeleteAlertRecentlyClosed || hasNotBelongToMeRecentlyClosed}"
            [src]="providerPhotoUrl" 
            width="28" 
            height="28">
    </ng-container>

    <ng-container title>
      <i2a-spinner *ngIf="!site" [light]="true"></i2a-spinner>
      <ng-container *ngIf="site">
        <span class="provider-type" [title]="site.title">{{ 'profile.provider.microsoft.sharePoint.title.site.siteTitle' | i18next : { site: site.title } }}</span>
      </ng-container>
    </ng-container>

    <ng-container buttons>
      <div *ngIf="configuration.viewAlerts && alerts && alerts.length > 0" class="float-right pr-2 id-collapse my-auto id-button" [title]="'profile.openAlertHint' | i18next " (click)="showOpenAlerts()">
        <fa-icon [icon]="['far', 'shield-alt']" style="font-size: 1.5rem"></fa-icon>
      </div>

      <div *ngIf="debug && connectedAccount.isSupport && !hasDeleteAlert && !hasNotBelongToMe && !hasDeleteAlertRecentlyClosed && !hasNotBelongToMeRecentlyClosed" class="float-right pr-2 id-collapse my-1 id-button" [title]="'profile.downloadOnePage' | i18next " (click)="downloadOnePage()">
        <fa-icon [icon]="['fal', 'file-pdf']" size="2x"></fa-icon>
      </div>

      <div *ngIf="debug && connectedAccount.isSupport" class="float-right pr-2 id-collapse my-1 id-button" [title]="'profile.downloadAudit' | i18next " (click)="downloadAudit()">
        <fa-icon [icon]="['fal', 'file-excel']" size="2x"></fa-icon>
      </div>
    </ng-container>

    <ng-container body>

      <ng-content select="[content]"></ng-content>

      <i2a-share-point-actions *ngIf="providerInstanceResources && resourceProfiles"
        [alerts]="alerts" 
        [componentId]="componentId"
        [configuration]="moduleActionConfiguration" 
        [profile]="profile"
        [site]="site"
        [resources]="providerInstanceResources"
        [resourceProfiles]="resourceProfiles"
        [user]="user" 
        (onAlertSent)="alertSent($event)" 
        (onHasWarnings)="hasWarnings($event)"
        [hasDeleteAlertOrHasNotBelongToMe]="hasDeleteAlert || hasNotBelongToMe || hasDeleteAlertRecentlyClosed || hasNotBelongToMeRecentlyClosed">
      </i2a-share-point-actions>

      <div class="col-sm-12 col-md-4 alerts" *ngIf="alerts.length > 0">
        <span>
          <fa-icon [icon]="['far', 'shield-alt']" size="lg"></fa-icon>
        </span>
        <span>
          {{"profile.alertsReported" | i18next : {"alertNumber": alerts.length } }}
        </span>
      </div>

      <i2a-share-point-o365-group-module *ngIf="!hasDeleteAlert && !hasNotBelongToMe && !hasDeleteAlertRecentlyClosed && !hasNotBelongToMeRecentlyClosed"
        [alerts]="alerts"
        [profile]="profile"
        [resource]="firstResource"
        [configuration]="moduleSharePointO365GroupConfiguration"
        [resourceTemplate]="template.resourceTemplate"
        [site]="true"
        [user]="user"
        [resources]="providerInstanceResources" 
        (onAlertSent)="alertSent($event)" 
        (onHasWarnings)="hasWarnings($event)">
      </i2a-share-point-o365-group-module>

      <div class="container no-gutters delete-information">
        <div class="row">
          <div class="col pt-3" *ngIf="hasDeleteAlert || hasDeleteAlertRecentlyClosed">
            <ul class="id-information">
              <li>
                <fa-layers [fixedWidth]="true">
                  <fa-icon [icon]="['fal', 'trash-alt']" size="lg" [fixedWidth]="true"></fa-icon>
                </fa-layers>
                <span class="id-information-label">{{ 'deleteResource.informationTitle' | i18next : { creationDate : deleteAlertDate } }}</span>
              </li>
              <li *ngIf="!hasDeleteAlertFailed">
                <fa-layers [fixedWidth]="true">
                  <fa-icon class="pr-2" [icon]="['fal', 'info-square']" size="lg" [fixedWidth]="true"></fa-icon>
                </fa-layers>
                <span *ngIf="hasDeleteAlert" class="id-information-label">{{ 'deleteResource.pending' | i18next }}</span>
                <span *ngIf="hasDeleteAlertRecentlyClosed" class="id-information-label">{{ 'deleteResource.done' | i18next }}</span>
              </li>
              <li *ngIf="hasDeleteAlertFailed" class="id-warning">
                <fa-layers [fixedWidth]="true">
                  <fa-icon class="pr-2" [icon]="['fal', 'exclamation-triangle']" size="lg" [fixedWidth]="true">
                  </fa-icon>
                </fa-layers>
                <span class="id-information-label">{{ 'deleteResource.failed' | i18next }}</span>
              </li>
            </ul>
          </div>
          <div class="col pt-3" *ngIf="hasNotBelongToMe || hasNotBelongToMeRecentlyClosed">
            <ul class="id-information">
              <li>
                <fa-layers [fixedWidth]="true">
                  <fa-icon [icon]="['fal', 'user-cog']" size="lg" [fixedWidth]="true"></fa-icon>
                </fa-layers>
                <span class="id-information-label">{{ 'resourceNotBelongToMe.informationTitle' | i18next : { creationDate : hasNotBelongToMeDate } }}</span>
              </li>
              <li *ngIf="!hasDeleteAlertFailed">
                <fa-layers [fixedWidth]="true">
                  <fa-icon class="pr-2" [icon]="['fal', 'info-square']" size="lg" [fixedWidth]="true"></fa-icon>
                </fa-layers>
                <span *ngIf="hasNotBelongToMe" class="id-information-label">{{ 'resourceNotBelongToMe.pending' | i18next }}</span>
                <span *ngIf="hasNotBelongToMeRecentlyClosed" class="id-information-label">{{ 'resourceNotBelongToMe.done' | i18next }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="module row " [style.display]="hasDeleteAlert || hasNotBelongToMe || hasDeleteAlertRecentlyClosed || hasNotBelongToMeRecentlyClosed? 'none' : 'block'" >
        <div class="module-container col-12">
          <div class="row p-1">
            <div class="col-12">

              <div class="row">
                <div class="col-12 id-col-title">
                  <span class="provider-type">{{ 'profile.provider.microsoft.sharePoint.title.site.lists' | i18next }}</span>
                </div>
              </div>

              <div class="row">
                <div class="col-12 id-col-description">
                  <span>{{ 'profile.provider.microsoft.sharePoint.subTitle.site.lists' | i18next }}</span>
                </div>
              </div>

              <div class="row pl-5">
                <div class="col-12 id-col-resources">
                  <template #resources i2a-container></template>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </i2a-title-bar>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { AzureADAppPermissionGrantModel, AzureADReportProfileAlertGrantsModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StringHelper } from 'projects/web-user-profile/src/app/helpers/string-helper';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-azure-ad-permission-grants-modal',
  templateUrl: './azure-ad-permission-grants-modal.component.html',
  styleUrls: ['./azure-ad-permission-grants-modal.component.scss']
})
export class AzureAdPermissionGrantsModalComponent implements OnInit {
  @Input() public grants: AzureADAppPermissionGrantModel;

  @Input() public resourceProfile: ReportProfileResourceBase<any>;
  
  public validation: AzureADReportProfileAlertGrantsModel;
  public canSend: boolean;

  public buttonSendIsDisabled: boolean;
  public setCanSend() {
    this.buttonSendIsDisabled = !this.canSend && StringHelper.isNullOrWhitespace(this.validation.comments);
  }


  constructor(
    public activeModal: NgbActiveModal, 
    public session: SessionService
  ) {
    this.canSend = false;
    this.buttonSendIsDisabled = false;
  }

  ngOnInit() {
    this.validation = new AzureADReportProfileAlertGrantsModel();
    this.validation.comments = "";
    this.setCanSend();
  }
}

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EnumHelper {

    constructor() {
    }

    public static HasFlag<T extends any>(variable: T, value: T): boolean {
        if (variable == null)
            return false;

        if (value == null) {
            throw new Error("value must have a value");
        }
        var num = <any>value;
        var varNumber = <any>variable;
        return (varNumber & num) == num;

    }


}


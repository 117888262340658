import { ExchangeReportProfileDelegate } from '../../../microsoft/exchange/profile/exchange-report-profile-delegate';
import { ExchangeReportProfileFolderRight } from '../../../microsoft/exchange/profile/exchange-report-profile-folder-right';
import { ProviderAuditResourceKind } from '../../../provider-audit-resource-kind';

export class ExchangeReportProfilePermissions extends ExchangeReportProfileDelegate {
  hasFullAccessRights: boolean;
  hasSendAsRights: boolean;
  hasCalendarRights: boolean;
  hasFoldersRights: boolean;
  hasOtherRights: boolean;
  foldersRights: { [key: string]: ProviderAuditResourceKind };
  exchangeFoldersRights: { [key: string]: ExchangeReportProfileFolderRight };
  foldersIdentity: { [key: string]: string };
  hasAnyRight: boolean;
  hasNoRight: boolean;
  calendarIsFolderVisible: boolean;
}

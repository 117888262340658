import { OnInit, Input, OnDestroy, Inject, Component, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { Observable, of } from 'rxjs';
import { BaseOneDriveDelegateModalComponent } from '../base-one-drive-delegate-modal/base-one-drive-delegate-modal.component';
import { FolderHelper } from 'projects/web-user-profile/src/app/helpers/folder-helper';
import { ToastrService } from 'ngx-toastr';
import { OneDriveReportProfileAlertDelegateModel, OneDriveReportProfile } from '@i2a/web-api-client';
import { DelegateFolders } from 'projects/web-user-profile/src/app/models/delegate-folders';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { InformationModalComponent } from 'projects/web-user-profile/src/app/components/shared/modal/information-modal/information-modal.component';
import { OneDriveDelegate } from 'projects/web-user-profile/src/app/models/providers/microsoft/one-drive/one-drive-delegate';

@Component({
  selector: 'i2a-base-one-drive-delegate',
  template: ''
})
export class BaseOneDriveDelegateComponent extends ProfileItemBaseClassComponent<OneDriveReportProfile> implements OnInit, OnDestroy {
  @Input() public delegate: OneDriveDelegate;

  public name: string;
  public baseFolders: string[];

  public delegateFolders: DelegateFolders;


  constructor(
    modalService: NgbModal,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
  }

  setItems() {
    if (this.delegate && this.alerts) {
      super.setItems();
      if (this.delegate.accesses.isAnonymousUser) {
        this.name = this.i18Next.t("profile.provider.microsoft.oneDrive.anonymous");
      }
      else {
        this.name = this.delegate.accesses.name;
      }
      this.baseFolders = FolderHelper.RemoveFolderPartsAboveMaxDepthLevel(this.delegate.accesses.foldersAccessed, 2);
      this.baseFolders = FolderHelper.CompleteFolders(this.baseFolders, 1);
      
      this.hasAlert = this.hasAlertInAlerts(false);
      this.hasAlertRecentlyClosed = this.hasAlertInAlerts(true);

      this.delegate.accesses.foldersAccessed = this.delegate.accesses.foldersAccessed.sort(((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' })));
      
      this.delegateFolders = new DelegateFolders({
        folders: this.delegate.accesses.foldersAccessed,
        driveItems: this.delegate.permissions != null ? this.delegate.permissions.driveItems : null,
        isAnonymous: this.delegate.accesses.isAnonymousUser,
        isApplication: this.delegate.accesses.isApplication,
        isExternalUser: this.delegate.accesses.isExternalUser,
        isTheWholeCompany: false,
        logonUserId: this.delegate.accesses.logonUserId,
        name: this.delegate.accesses.name,
        providerInstanceResource: this.delegate.accesses.providerInstanceResource,
        providerInstanceUser: this.delegate.accesses.providerInstanceUser,
        userId: this.delegate.accesses.userId
      });     
    }
  }

  private hasAlertInAlerts(done: boolean): boolean {
    return AlertReasonHelper.alertsContainsDelegate(this.alerts, this.delegate.accesses, done);
  }


  protected AlertOneDriveDelegate(model: OneDriveReportProfileAlertDelegateModel): Observable<any> {
    return of();
  }

  protected AlertOneDriveDelegates(model: OneDriveReportProfileAlertDelegateModel): Observable<any[]> {
    return of();
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(BaseOneDriveDelegateModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.delegate = this.delegate;
    modalRef.result.then((model: OneDriveReportProfileAlertDelegateModel) => {
      this.initAlertModel(model);
      model.providerInstanceResource = this.providerInstanceResource;
      model.permissions.providerInstanceResource = this.providerInstanceResource;
      if (model.permissions != null) {
        model.permissions.endDate = this.profile.endDate;
        model.permissions.providerInstanceResource = this.providerInstanceResource;
        model.permissions.comments = model.comments;
      }
      this.subscriptions.add(this.AlertOneDriveDelegate(model).subscribe(model => {
        this.setAlertProcessed(model);
      }));
      this.subscriptions.add(this.AlertOneDriveDelegates(model).subscribe(model => {
        this.setAlertsProcessed(model);
      }));
    }).catch((error) => {
      // console.log(error);
    });
  }

  @ViewChild('viewDetailTemplate') viewDetailTemplate: TemplateRef<any>;

  openDetails(event): void {
    const modalRef = this.modalService.open(InformationModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.title = this.i18Next.t("modal.detail.title");
    modalRef.componentInstance.bodyTemplate = this.viewDetailTemplate;
    modalRef.componentInstance.ok = this.i18Next.t("button.close");
    modalRef.result.then((model: any) => {
      if (model == true) {
        this.warnClick(null);
      }
    }, (reason) => {
      //Dismiss
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
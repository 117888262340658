export class AlertConstants {
    public static readonly ALERT_INVALID_STATE_CONTEXT_USER_RECENT_ORIGINS_LEVEL: string = "recentLevel";
    public static readonly ALERT_INVALID_STATE_CONTEXT_USER_RECENT_ORIGINS_ADDRESSES: string = "recentCountries";
    public static readonly ALERT_INVALID_STATE_CONTEXT_USER_RECENT_ORIGINS_OTHERS: string = "others";

    public static readonly ALERT_INVALID_STATE_CONTEXT_USER_USUAL_ORIGINS_ADDRESSES: string = "usualCountries";

    public static readonly ALERT_INVALID_STATE_CONTEXT_USER_DEVICE_ID: string = "deviceId";

    public static readonly ALERT_INVALID_STATE_CONTEXT_USER_ACCESS_MODE: string = "accessMode";

    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DEVICE_SYNC_ID: string = "deviceSyncId";
    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_LOGON_USERID: string = "logonUserId";
    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_USER_ID: string = "userId";
    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_PROVIDERINSTANCE_USER: string = "providerInstanceUser";

    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_PROVIDER_RESOURCE_AUDIT_KINDS: string = "providerAuditResourceKind";
    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_DEVICE_ID: string = "deviceId";
    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_ALL_FOLDER: string = "allFolder";
    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_FOLDER: string = "folder";
    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_MODEL: string = "model";
    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_O365_GROUP_ID: string = "o365GroupId";
    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_O365_GROUP_VISIBILITY: string = "o365GroupVisibility";
    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_ADMIN_ID: string = "AdminId";
    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_GROUP_ID: string = "GroupId";
    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_AD_GRANTS_CLIENT_ID: string = "clientId";
    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_ALL_MEMBERS: string = "allMembers";
    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_MEMBER: string = "member";
    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_ALL_GUESTS: string = "allGuests";
    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_GUEST: string = "guest";
    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_ALL_OWNERS: string = "allOwners";
    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_OWNER: string = "owner";
    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_FORWARD_EMAIL: string = "eMail";
    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_INBOX_RULE_IDENTITY: string = "inboxRuleIdentity";
    public static readonly ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_FILTER_RULE_NAME: string = "name";
    public static readonly ALERT_INVALID_STATE_CONTEXT_PROVIDERINSTANCE_RESOURCE_ID = "ProviderInstanceResourceId";
    public static readonly ALERT_INVALID_STATE_CONTEXT_SHAREPOINT_DELETE_SITE = "DeleteSite";
        
    public static readonly ALERT_ANNOTATION_SUBTYPE = "i2a.alert.subType";
    public static readonly ALERT_ANNOTATION_INVALID_STATE_SUBTYPE_PROFILE_USER_ACTIVITY_ACCESS_MODES = "alert.subType.invalidState.profile.userActivity.accessModes";
    public static readonly ALERT_ANNOTATION_INVALID_STATE_SUBTYPE_PROFILE_USER_ACTIVITY_DEVICES = "alert.subType.invalidState.profile.userActivity.devices";
    public static readonly ALERT_ANNOTATION_INVALID_STATE_SUBTYPE_PROFILE_USER_ACTIVITY_RECENT_COUNTRIES = "alert.subType.invalidState.profile.userActivity.recentCountries";
    public static readonly ALERT_ANNOTATION_INVALID_STATE_SUBTYPE_PROFILE_USER_ACTIVITY_USUAL_COUNTRIES = "alert.subType.invalidState.profile.userActivity.usualCountries";
    public static readonly ALERT_ANNOTATION_SUBTYPE_CONFIGURATION = "alert.subType.invalidState.profile.configuration";
    public static readonly ALERT_ANNOTATION_SUBTYPE_DELEGATION = "alert.subType.invalidState.profile.delegation";


    //#region Reasons

    public static readonly ALERT_INVALID_STATE_REASON_USER_ACCESS_MODES = "alert.invalidState.profile.user.reasons.accessModes";
    public static readonly ALERT_INVALID_STATE_REASON_USER_ORIGINS = "alert.invalidState.profile.user.reasons.origins";
    public static readonly ALERT_INVALID_STATE_REASON_USER_USUAL_COUNTRIES = "alert.invalidState.profile.user.reasons.usualCountries";
    public static readonly ALERT_INVALID_STATE_REASON_USER_REGISTERED_DEVICES = "alert.invalidState.profile.user.reasons.devices";
    public static readonly ALERT_INVALID_STATE_REASON_RESOURCE_NOT_REGISTERED_DEVICE = "alert.invalidState.profile.common.reasons.device";
    
    public static readonly ALERT_INVALID_STATE_REASON_RULES_COMPUTED_ALL_FOLDERS = "alert.invalidState.profile.rulesComputedDelegate.reasons.allFolders";
    public static readonly ALERT_INVALID_STATE_REASON_RULES_COMPUTED_FOLDERS = "alert.invalidState.profile.rulesComputedDelegate.reasons.folders";
    public static readonly ALERT_INVALID_STATE_REASON_RULES_COMPUTED_FOLDER = "alert.invalidState.profile.rulesComputedDelegate.reasons.folder";
    public static readonly ALERT_INVALID_STATE_REASON_RULES_COMPUTED_KINDS = "alert.invalidState.profile.rulesComputedDelegate.reasons.kinds";
    public static readonly ALERT_INVALID_STATE_REASON_RULES_COMPUTED_DEVICES = "alert.invalidState.profile.rulesComputedDelegate.reasons.devices";
    public static readonly ALERT_INVALID_STATE_REASON_RULES_COMPUTED_COMMENTS = "alert.invalidState.profile.rulesComputedDelegate.reasons.comments";
    
    public static readonly ALERT_INVALID_STATE_REASON_RESOURCE_DELETE = "alert.invalidState.profile.resource.reasons.delete";
    public static readonly ALERT_INVALID_STATE_REASON_RESOURCE_NOT_BELONG_TO_ME = "alert.invalidState.profile.resource.reasons.notBelongToMe";

    public static readonly ALERT_INVALID_STATE_REASON_SHAREPOINT_O365_GROUP: string = "alert.invalidState.profile.provider.microsoft.sharePoint.reasons.invalidGroup";
    public static readonly ALERT_INVALID_STATE_REASON_SHAREPOINT_VISIBILITY: string = "alert.invalidState.profile.provider.microsoft.sharePoint.reasons.invalidVisibility";


    //#endregion

}

import { Injectable, OnDestroy, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ConfigService, IWebConfig, IConfigService, I2A_CONFIG_SERVICE } from './config.service';
import { User } from 'oidc-client';

@Injectable({
  providedIn: 'root'
})
export class ContextService implements OnDestroy {
  private subscriptions = new Subscription();
  public DefaultCulture: string;

  //#region TenantCode
  private tenantCodeSubject?: BehaviorSubject<string>;
  public TenantCode$: Observable<string>

  get TenantCode(): string {
    return this.tenantCodeSubject.value;
  }

  set TenantCode(tenantCode: string) {
    this.tenantCodeSubject.next(tenantCode);
  }

  //#endregion

  //#region Culture
  private cultureSubject?: BehaviorSubject<string>;
  public Culture$: Observable<string>;

  get Culture(): string {
    return this.cultureSubject.value;
  }

  set Culture(culture: string) {
    this.cultureSubject.next(culture);
  }
  //#endregion

  constructor(
    @Inject(I2A_CONFIG_SERVICE) configService: IConfigService<IWebConfig>,
  ) {
    console.log("Init context");

    this.tenantCodeSubject = new BehaviorSubject<string>("");
    this.TenantCode$ = this.tenantCodeSubject.asObservable();

    this.cultureSubject = new BehaviorSubject<string>(configService.Config.defaultCulture);
    this.Culture$ = this.cultureSubject.asObservable();

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


}

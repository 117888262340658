import { AccessEvaluatedActualRulesModel } from '../models/access-evaluated-actual-rules-model';
import { PageResultModel } from '../models/page-result-model';
import { RuleHumanExpression } from '../models/rule-human-expression';
import { RuleManagementAccessOperationModel } from '../models/rule-management-access-operation-model';
import { RuleManagementUsageOperationModel } from '../models/rule-management-usage-operation-model';
import { RuleModel } from '../models/rule-model';
import { RulePredicateConfigurationModel } from '../models/rule-predicate-configuration-model';
import { RulePredicatesHumanExpression } from '../models/rule-predicates-human-expression';
import { RulesTemplateModel } from '../models/rules-template-model';
import { RuleSummaryModel } from '../models/rule-summary-model';
import { RuleUpdateModel } from '../models/rule-update-model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatasourceService } from '../../data/datasource.service';

@Injectable({
	providedIn: 'root'
})
export class RulesDatasourceService {

private route: string = "rules";

	constructor(private datasource: DatasourceService) {
		console.log("Init RulesDatasourceService");
	}

	public load(ruleId: string, useCache: boolean = false, throwError: boolean = true): Observable<RuleModel> {
		let partialUrl = `${this.route}/${ruleId}`;

		return this.datasource.get<RuleModel>(partialUrl, { }, useCache, throwError);
	}

	public getHumanExpression(configuration: RulePredicateConfigurationModel, throwError: boolean = true): Observable<RuleHumanExpression> {
		let partialUrl = `${this.route}/human-expression`;

		return this.datasource.put(partialUrl, configuration, { params: { } }, throwError);
	}

	public getPredicatesHumanExpression(configuration: RulePredicateConfigurationModel, throwError: boolean = true): Observable<RulePredicatesHumanExpression> {
		let partialUrl = `${this.route}/predicates-human-expression`;

		return this.datasource.put(partialUrl, configuration, { params: { } }, throwError);
	}

	public updateRule(ruleId: string, rule: RuleUpdateModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${ruleId}`;

		return this.datasource.put(partialUrl, rule, { params: { } }, throwError);
	}

	public deleteRule(ruleId: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${ruleId}`;

		return this.datasource.delete(partialUrl, null, throwError);
	}

	public deleteRules(ruleIds: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}`;

		return this.datasource.delete(partialUrl, null, throwError);
	}

	public enableRule(ruleId: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${ruleId}/enable`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public disableRule(ruleId: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${ruleId}/disable`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public markAsNotRebuildable(ruleId: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${ruleId}/mark-as-not-rebuildable`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public markAsRebuildable(ruleId: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${ruleId}/mark-as-rebuildable`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public createUsageOperation(operation: RuleManagementUsageOperationModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/usage-operation`;

		return this.datasource.post(partialUrl, operation, { params: { } }, throwError);
	}

	public createAccessOperation(operation: RuleManagementAccessOperationModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/access-operation`;

		return this.datasource.post(partialUrl, operation, { params: { } }, throwError);
	}

	public loadAppliedRules(providerAuditId: string, withTestMode: boolean = null, throwError: boolean = true): Observable<AccessEvaluatedActualRulesModel> {
		let partialUrl = `${this.route}/applied-rules`;

		return this.datasource.post(partialUrl, providerAuditId, { params: {withTestMode: String(withTestMode) } }, throwError);
	}

	public clearCache(throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/clear-cache`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public search(queryContext: string, offset: string = null, limit: number = null, useCache: boolean = false, throwError: boolean = true): Observable<PageResultModel<RuleSummaryModel>> {
		let partialUrl = `${this.route}/search-flexible`;

		return this.datasource.get<PageResultModel<RuleSummaryModel>>(partialUrl, {queryContext: String(queryContext),offset: String(offset),limit: String(limit) }, useCache, throwError);
	}

	public propagateRules(rulesTemplateModel: RulesTemplateModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/propagate-rules`;

		return this.datasource.post(partialUrl, rulesTemplateModel, { params: { } }, throwError);
	}

}

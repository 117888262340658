import { Component, OnInit, OnDestroy, Input, Inject, ViewChild, ElementRef } from '@angular/core';
import { ContextService, AuthenticationService, ConfigService, IWebConfig } from '@i2a/web-core';
import { ConnectedAccountModel, TenantSummaryModel, LocalizationDatasourceService, IconConstants } from '@i2a/web-api-client';
import { SessionService } from '../../../../services/session-service';
import { SafeHtml, DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NavBarLogoPosition } from 'projects/web-user-profile/src/app/models/nav-bar-logo-position';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditUserModalComponent } from '../../../users/edit-user-modal/edit-user-modal.component';
import { IConfigService, I2A_CONFIG_SERVICE } from '@i2a/web-core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'i2a-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, OnDestroy {
  @Input() public displayMenu: boolean;
  @Input() public logoPosition: NavBarLogoPosition;
  public logo: SafeUrl;
  public logoStyle: any;
  public logoContainerStyle: any;

  private subscriptions = new Subscription();
  public tenantCode: string;
  public connectedAccount: ConnectedAccountModel;
  public isCollapsed: boolean;
  public tenant: TenantSummaryModel;
  public isMultiTenants: boolean;
  public expertUrl: string;
  public monitoringUrl: string;
  public prepositionArgs: any;
  public isIE: boolean;

  @ViewChild('navbarTitle', { static: true }) navbarTitle: ElementRef;
  @ViewChild('navbarSubTitle', { static: true }) navbarSubTitle: ElementRef;
  
  constructor(
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    @Inject(I18NEXT_SERVICE) private translateI18Next: ITranslationService,
    private session: SessionService,
    private context: ContextService,
    @Inject(I2A_CONFIG_SERVICE) private configService: IConfigService<IWebConfig>,
    public authenticationService: AuthenticationService,
    private deviceService: DeviceDetectorService) {
    this.isCollapsed = true;
    this.isMultiTenants = false;
    this.displayMenu = true;
    this.logoPosition = NavBarLogoPosition.Left;
    //https://www.geeksforgeeks.org/how-to-detect-the-user-browser-safari-chrome-ie-firefox-and-opera-using-javascript/
    this.isIE = this.deviceService.userAgent.indexOf("MSIE") > -1 || this.deviceService.userAgent.indexOf("rv:") > -1; 
  }

  ngOnInit(): void {
    this.subscriptions.add(this.context.TenantCode$.subscribe(tenantCode => {
      if (this.session.Account != null) {
        this.tenant = this.session.Account.tenants.find(t => t.code == tenantCode);
        if (this.tenant) {
          console.log(`tenant :${JSON.stringify(this.tenant)}`)
          this.prepositionArgs = { tenantCode: this.tenant.name, interpolation: { escapeValue: false } };
        }
      }
    }));

    this.subscriptions.add(
      this.session.Account$.subscribe(account => {
        if (account != null) {
          this.connectedAccount = account;
          this.isMultiTenants = account.tenants.length > 1;
        }
      })
    );

    this.logo = this.sanitizeLogo(this.translateI18Next.t('images.logo.png'));
    let logostr = this.translateI18Next.t('style.logo');
    this.logoStyle = this.sanitizer.bypassSecurityTrustStyle(logostr);
    this.logoContainerStyle = this.sanitizer.bypassSecurityTrustStyle(this.translateI18Next.t('style.logoContainer'));

    if (this.isIE) {
      const color = this.translateI18Next.t('style.ie.color');
      this.navbarTitle.nativeElement.style.color = color;
      this.navbarSubTitle.nativeElement.style.color = color;
    }
  }

  sanitizeLogo(base64: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(`${IconConstants.typePNG},${base64}`);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  Logout() {
    this.authenticationService.logout();
  }

  UpdateAccount() {
    const modalRef = this.modalService.open(EditUserModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.userId = this.connectedAccount.userId;
    modalRef.result.then(() => {
    }).catch((error) => {
      
    });
  }

  ChangePassword() {
    this.authenticationService.changePassword();
  }

  //#region IE
  onLeave(): void {
    if (this.isIE) {
      const color = this.translateI18Next.t('style.ie.color');
      this.navbarTitle.nativeElement.style.color = color;
      this.navbarSubTitle.nativeElement.style.color = color;
    }
  }

  onEnter(): void {
    if (this.isIE) {
      const colorHover = this.translateI18Next.t('style.ie.colorHover');
      this.navbarTitle.nativeElement.style.color = colorHover;
      this.navbarSubTitle.nativeElement.style.color = colorHover;
    }
  }
  //#endregion

}

import { BaseProviderInstanceResourceModel } from './base-provider-instance-resource-model';
import { KeyValuePair } from '../../models/key-value-pair';

export class UserActivityModel extends BaseProviderInstanceResourceModel {
  isRelevant: boolean;
  isSucceeded: boolean;
  accessMode: string;
  resourceName: string;
  accessKind: number;
  deviceId: string;
  deviceName: string;
  deviceModel: string;
  clientIP: string;
  isClientIPRelevant: boolean;
  clientIPOrigin: string;
  alertId: string|null;
  advancedProperties: KeyValuePair[];
  lastActionDate: Date;
}

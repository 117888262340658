<i2a-profile-alert-base-modal
  [manualDescriptionKey]="'applyCorrection.providers.microsoft.exchange.delegate.manual.description'"
  [manualLinkKey]="'applyCorrection.providers.microsoft.exchange.delegate.manual.link'"
  [automaticDescriptionKey]="'applyCorrection.providers.microsoft.exchange.delegate.automatic.description'"
  [ignoreCommentToSend]="session.IgnoreCommentToSend" [displayComment]="session.DisplayComment"
  [validation]="validation" [canSend]="canSend" [modalTitle]="'profile.confirmationOfInformationTitle' | i18next "
  [resourceProfile]="resourceProfile">

  <ng-container header-picto>
    <i2a-resource-photo [providerInstanceResource]="delegate.accesses.providerInstanceResource" [size]="50">
    </i2a-resource-photo>
  </ng-container>

  <ng-container header-title>
    {{delegate.accesses.name}}<br>
    {{delegate.accesses.lastAccess | amDateFormat: 'LLL'}}
  </ng-container>

  <ng-container body>
    <div class="container-fluid">
      <div class="row pt-2" *ngIf="IsMailboxAccessed || IsCalendarAccessed || IsFoldersAccessed" >

          <div class="col-12 pb-1 id-detail">{{ "profile.provider.microsoft.exchange.accesses" | i18next  }} </div>
          <div class="form-group col-6 col-sm-4" *ngIf="IsMailboxAccessed">
          <div class="access">
            <div class="access-type">
              <fa-icon [icon]="['fal', 'envelope']" size="2x" style="vertical-align: -webkit-baseline-middle"></fa-icon>
            </div>
            <div class="access-checkbox ml-1">
              <i2a-input-checkbox [(model)]="validation.inboxAccessIsValid" [classNameChecked]="'btn-outline-danger'"
                [className]="'btn-outline-dark'" [valueChecked]="false" [valueUnChecked]="true"
                (modelChange)="setCanSend()"> </i2a-input-checkbox>
            </div>
          </div>
        </div>

        <div class="form-group col-6 col-sm-4" *ngIf="IsCalendarAccessed">
          <div class="access">
            <div class="access-type">
              <fa-icon [icon]="['fal', 'calendar-alt']" size="2x" style="vertical-align: -webkit-baseline-middle">
              </fa-icon>
            </div>
            <div class="access-checkbox ml-1">
              <i2a-input-checkbox [(model)]="validation.calendarAccessIsValid" [classNameChecked]="'btn-outline-danger'"
                [className]="'btn-outline-dark'" [valueChecked]="false" [valueUnChecked]="true"
                (modelChange)="setCanSend()"> </i2a-input-checkbox>
            </div>
          </div>
        </div>

        <div class="form-group col-6 col-sm-4" *ngIf="IsFoldersAccessed">
          <div class="access">
            <div class="access-type">
              <fa-icon [icon]="['fal', 'folder']" size="2x" style="vertical-align: -webkit-baseline-middle"></fa-icon>
            </div>
            <div class="access-checkbox ml-1">
              <i2a-input-checkbox [(model)]="validation.folderAccessIsValid" [classNameChecked]="'btn-outline-danger'"
                [className]="'btn-outline-dark'" [valueChecked]="false" [valueUnChecked]="true"
                (modelChange)="setCanSend()"> </i2a-input-checkbox>
            </div>
          </div>
        </div>

      </div>

      <div class="row pt-1" *ngIf="devices.length > 0">
          <div class="col-12 pb-1 id-detail">{{ "profile.provider.microsoft.exchange.devices.deviceList" | i18next  }} </div>
          <div class="form-group col">
          <table class="table-sm id-table">
            <tbody>
              <tr *ngFor="let device of devices">
                <td scope="row">
                  <i2a-input-checkbox [(model)]="device.checked" (modelChange)="checked($event, device)"
                    [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                    [valueUnChecked]="false"> </i2a-input-checkbox>
                </td>
                <td scope="row" class="text-break">
                  {{device.name}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
    <div class="container-fluid">
      <div class="row pt-2" *ngIf="delegate.permissions">

          <div class="col-12 pb-1 id-detail">{{ "profile.provider.microsoft.exchange.grantedRights" | i18next  }} </div>
          <div class="form-group col-6 col-sm-4" *ngIf="delegate.permissions.hasFullAccessRights">
          <div class="permission">
            <div class="permission-type">
              {{"profile.provider.microsoft.exchange.rights.fullAccess" | i18next }}
            </div>
            <div class="permission-checkbox ml-1">
              <i2a-input-checkbox [(model)]="validation.permissions.hasFullAccessRightsIsValid"
                [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="false"
                [valueUnChecked]="true" (modelChange)="setCanSend()"> </i2a-input-checkbox>
            </div>
          </div>
        </div>

        <div class="form-group col-6 col-sm-4" *ngIf="delegate.permissions.hasSendAsRights">
          <div class="permission">
            <div class="permission-type">
              {{"profile.provider.microsoft.exchange.rights.sendAs" | i18next }}
            </div>
            <div class="permission-checkbox ml-1">
              <i2a-input-checkbox [(model)]="validation.permissions.hasSendAsRightsIsValid"
                [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="false"
                [valueUnChecked]="true" (modelChange)="setCanSend()"> </i2a-input-checkbox>
            </div>
          </div>
        </div>

        <div class="form-group col-6 col-sm-4" *ngIf="delegate.permissions.hasCalendarRights">
          <div class="permission">
            <div class="permission-type">
              {{"profile.provider.microsoft.exchange.rights.calendar" | i18next }}
            </div>
            <div class="permission-checkbox ml-1">
              <i2a-input-checkbox [(model)]="validation.permissions.hasCalendarRightsIsValid"
                [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="false"
                [valueUnChecked]="true" (modelChange)="setCanSend()"> </i2a-input-checkbox>
            </div>
          </div>
        </div>

        <div class="form-group col-6 col-sm-4" *ngIf="delegate.permissions.hasFoldersRights">
          <div class="permission">
            <div class="permission-type">
              {{"profile.provider.microsoft.exchange.rights.folders" | i18next }}
            </div>
            <div class="permission-checkbox ml-1">
              <i2a-input-checkbox [(model)]="validation.permissions.hasFoldersRightsIsValid"
                [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="false"
                [valueUnChecked]="true" (modelChange)="setCanSend()"> </i2a-input-checkbox>
            </div>
          </div>
        </div>

      </div>
    </div>
  </ng-container>

</i2a-profile-alert-base-modal>
import { Component, OnInit, EventEmitter, Inject, Input } from '@angular/core';
import { ReportProfileBaseModel, UserModel, BaseProviderInstanceResourceModel, MicrosoftOfficeReportProfileModel, ExchangeReportProfile, AlertInvalidStateFeaturedModel } from '@i2a/web-api-client';
import { ExchangeDelegate } from 'projects/web-user-profile/src/app/models/providers/microsoft/exchange/exchange-delegate';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ExchangeNodataConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/exchange-nodata-configuration';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';

@RegisterMyProfileComponent(TemplateConstants.MODULE_EXCHANGE_NODATA)
@Component({
  selector: 'i2a-exchange-nodata-module',
  templateUrl: './exchange-nodata-module.component.html',
  styleUrls: ['./exchange-nodata-module.component.scss']
})
export class ExchangeNodataModuleComponent extends ProfileBaseClassComponent<ExchangeNodataConfiguration, ExchangeReportProfile> implements OnInit, IModule<ExchangeNodataConfiguration> {
  private resource: BaseProviderInstanceResourceModel;

  @Input()
  public delegatesWithAccessAndPermissions: ExchangeDelegate[];
  public displayNoData: boolean;

  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, null);
  }

  ngOnInit() {
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: ExchangeNodataConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel, resourceTemplate?: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.resource = resource;
    let resourceProfile = (<MicrosoftOfficeReportProfileModel>profile).exchangeProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
    this.displayNoData = (configuration.accesses && resourceProfile.delegates.length == 0 || !configuration.accesses)
      &&
      (configuration.rulesComputedDelegates && resourceProfile.rulesComputedDelegates.length == 0 || !configuration.rulesComputedDelegates)
      && (configuration.permissions && resourceProfile.permissions.length == 0 || !configuration.permissions)
      && (configuration.rules
        && !resourceProfile.rules.hasTransportRules
        && !resourceProfile.rules.hasJournalRules
        && resourceProfile.rules.inboxRules.length == 0
        && resourceProfile.rules.forwardMailBoxes.length == 0
        || !configuration.rules)
    this.resourceProfile = resourceProfile;
  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }

}

import { BaseProviderInstanceResourceModel } from '../../../base-provider-instance-resource-model';
import { ChangeResult } from '../../../change-result';
import { ReportProfileResourceChangesBaseModel } from '../../../reports/profile/report-profile-resource-changes-base-model';
import { ReportProfileRulesComputedDelegate } from '../../../profile/report-profile-rules-computed-delegate';
import { SharePointGraphGroupModel } from '../../../microsoft/sharepoint/share-point-graph-group-model';
import { SharePointGroupMemberModel } from '../../../microsoft/sharepoint/share-point-group-member-model';
import { SharePointGroupModel } from '../../../microsoft/sharepoint/share-point-group-model';
import { SharePointReportProfileDelegate } from '../../../microsoft/sharepoint/profile/share-point-report-profile-delegate';
import { SharePointReportProfilePermissions } from '../../../microsoft/sharepoint/profile/share-point-report-profile-permissions';

export class SharePointReportProfileChangesModel extends ReportProfileResourceChangesBaseModel<ReportProfileRulesComputedDelegate> {
  delegates: ChangeResult<SharePointReportProfileDelegate>;
  o365Groups: ChangeResult<SharePointGraphGroupModel>;
  isEqual: boolean;
  providerInstanceResource: BaseProviderInstanceResourceModel;
  permissions: ChangeResult<SharePointReportProfilePermissions>;
  groups: ChangeResult<SharePointGroupModel>;
  administrators: ChangeResult<SharePointGroupMemberModel>;
}

import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { ProviderInstancesDatasourceService, UsersDatasourceService, ProviderInstanceModel, Constants, UserModel, UserKind } from '@i2a/web-api-client';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../../services/session-service';
import { ContextService } from '@i2a/web-core';
import { StringHelper } from '../../helpers/string-helper';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

@Component({
  selector: 'i2a-provider-instance-profile',
  templateUrl: './provider-instance-profile.component.html',
  styleUrls: ['./provider-instance-profile.component.scss']
})
export class ProviderInstanceProfileComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  public providerInstance: ProviderInstanceModel;
  public user: UserModel;
  public userId: string;
  public providerInstanceId: string;
  public userView: boolean;
  public headerStyle: any;

  get isMicrosoft(): boolean {
    return Constants.PROVIDER_ID_MICROSOFT == this.providerInstance.providerId;
  }

  get isZimbra(): boolean {
    return Constants.PROVIDER_ID_ZIMBRA == this.providerInstance.providerId;
  }

  constructor(
    private providerInstanceDatasource: ProviderInstancesDatasourceService,
    private userDatasource: UsersDatasourceService,
    private route: ActivatedRoute,
    public context: ContextService,
    public session: SessionService,
    private router: Router,
    private sanitizer: DomSanitizer,
    @Inject(I18NEXT_SERVICE) private translateI18Next: ITranslationService
  ) {
    this.userView = false;
  }

  ngOnInit() {

    this.session.resetConfiguration()
    
    this.setHeaderStyle();
    
    this.subscriptions.add(combineLatest([this.route.params, this.route.queryParams])
      .pipe(map(results => ({ params: results[0], query: results[1] })))
      .subscribe(results => {
        let providerId: string = results.params["providerId"];
        let providerInstanceId = results.params["providerInstanceId"];
        let userId = results.params["userId"];
        if (userId != this.userId || (this.providerInstance?.providerInstanceId != providerInstanceId ?? true))
        {
          this.providerInstance = null;
          this.providerInstanceId = providerInstanceId;
          this.userId = userId;
          this.subscriptions.add(this.providerInstanceDatasource.loadProviderInstance(providerId, this.providerInstanceId)
            .subscribe(providerInstance => {
              this.providerInstance = providerInstance;
            })
          );
          
          this.subscriptions.add(this.userDatasource.load(this.userId)
            .subscribe(user => {
              this.user = user;
            })
          );
          
          let userView = results.query["userView"];
          if (!StringHelper.isNullOrWhitespace(userView)) {
            this.userView = JSON.parse(results.query["userView"]);
          }
          else {
            this.userView = false;
          }
        }
      }));
  }

  /**
   * @summary load resource's value of header if exsit
   */
  private setHeaderStyle() {
    if (this.translateI18Next.exists('style.header', {})) {
      this.headerStyle = this.sanitizer.bypassSecurityTrustStyle(this.translateI18Next.t('style.header'));
    }
    else {
      this.headerStyle = null;
    }
  }

  /**
   * @summary check session's security infos if current user has admin role 
   * @returns boolean : true if current user has admin role else false
   */
  hasAdminRole(): boolean {
    if (this.session.SecurityInfos.userKind != null) {
      return (this.session.SecurityInfos.userKind & UserKind.Admin) == UserKind.Admin;
    }
    else {
      return this.session.SecurityInfos.isSupport;
    }
  }

  // TODO : Check if still used
  // viewAsNormal(): void {
  //   this.router.navigate(["."], { relativeTo: this.route, replaceUrl: true, queryParams: {} });
  // }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
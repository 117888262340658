import { ReportProfileResourceBase } from '../../../profile/report-profile-resource-base';
import { ReportProfileRulesComputedDelegate } from '../../../profile/report-profile-rules-computed-delegate';
import { SharePointGraphGroupModel } from '../../../microsoft/sharepoint/share-point-graph-group-model';
import { SharePointGroupMemberModel } from '../../../microsoft/sharepoint/share-point-group-member-model';
import { SharePointGroupModel } from '../../../microsoft/sharepoint/share-point-group-model';
import { SharePointReportProfileDelegate } from '../../../microsoft/sharepoint/profile/share-point-report-profile-delegate';
import { SharePointReportProfilePermissions } from '../../../microsoft/sharepoint/profile/share-point-report-profile-permissions';
import { SharePointUserModel } from '../../../microsoft/sharepoint/share-point-user-model';

export class SharePointReportProfile extends ReportProfileResourceBase<ReportProfileRulesComputedDelegate> {
  delegates: SharePointReportProfileDelegate[];
  o365Groups: SharePointGraphGroupModel[];
  groups: SharePointGroupModel[];
  users: SharePointUserModel[];
  permissions: SharePointReportProfilePermissions[];
  administrators: SharePointGroupMemberModel[];
  relatedFarmSummaryItemId: string;
  isHubSite: boolean;
  isAssociatedToHubSite: boolean;
  hasSensitivityLabel: boolean;
  path: string[];
  driveId: string;
  webUrl: string;
  siteId: string|null;
  webId: string|null;
}

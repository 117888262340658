import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseProviderInstanceResourceModel, SharePointDatasourceService, SharePointGraphGroupModel, SharePointReportProfileAlertVisibilityModel, SharePointSite } from '@i2a/web-api-client';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { SharePointO365GroupConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/sharepoint-o365-group-configuration';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'i2a-share-point-visibility-modal',
  templateUrl: './share-point-visibility-modal.component.html',
  styleUrls: ['./share-point-visibility-modal.component.scss']
})
export class SharePointVisibilityModalComponent implements OnInit, OnDestroy {

  subscriptions = new Subscription();

  @Input() public providerInstanceResource: BaseProviderInstanceResourceModel;
  @Input() public group: SharePointGraphGroupModel;
  @Input() public isVisible: boolean;
  @Input() public configuration: SharePointO365GroupConfiguration;
  @Input() public relatedFarmSummaryItemId: string;
  @Input() public headerTitle: string;

  public site: { id: any; name: any; parentId: any; };
  public farmSummaryItems: SharePointSite[];

  public informationKey: string;

  public validation: SharePointReportProfileAlertVisibilityModel;
  public loading: boolean;

  public canChangeVisibility: any;

  
  constructor(
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService,
    private sharepointDatasource: SharePointDatasourceService,
    public session: SessionService
  ) { 
    this.loading = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.validation = new SharePointReportProfileAlertVisibilityModel();

    this.subscriptions.add(this.sharepointDatasource.getSharePointSiteInformation(this.providerInstanceResource.providerInstanceId, this.providerInstanceResource.providerInstanceResourceId, true)
    .subscribe((infos) => {
      this.canChangeVisibility = this.configuration.checkOwnerFullControlToChangeVisibility ? infos.ownerHasFullControl : true;
      this.site = {
        id: this.relatedFarmSummaryItemId,
        name: this.i18Next.t("modal.deleteConfirmation.siteChildren"),
        parentId: null
      };
      this.farmSummaryItems = infos.farmSummaryItems;
      this.loading = false;
    }));
  }
}

<i2a-base-modal [modalTitle]="modalTitle">
  <ng-container body>
    <div class="container-fluid display-alerts-reasons-modal">
      <div class="row pt-3 no-gutters" id="modal-body">
        <ul *ngIf="alerts">
          <li *ngFor="let reason of reasons" class="col-12 id-reason">
            <span class="id-date-begin">{{ reason.reason.creationDate | amDateFormat: 'LLL' }}
              <fa-icon *ngIf="reason.alertClosed" [icon]="['fal', 'check']" size="lg"></fa-icon>
              <fa-icon  *ngIf="!reason.alertClosed" [icon]="['fal', 'exclamation-triangle']" size="lg"></fa-icon>
            </span><br>
            {{ reason.reason.reasonResourceKey | i18next : reason.reason.reasonResourceArguments}} <br>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
  <ng-container footer>
    <div class="col-centered">
      <button (click)="close()" ngbAutofocus type="submit" class="btn btn-red ml-2">{{'button.ok' | i18next }}</button>
    </div>
  </ng-container>
</i2a-base-modal>
import { Component, OnInit, Input } from '@angular/core';
import { SharePointReportProfileDriveItemRole } from '@i2a/web-api-client';
import { EnumHelper } from 'projects/web-user-profile/src/app/helpers/enum-helper';

@Component({
  selector: 'i2a-share-point-permissions-roles',
  templateUrl: './share-point-permissions-roles.component.html',
  styleUrls: ['./share-point-permissions-roles.component.scss']
})
export class SharePointPermissionsRolesComponent implements OnInit {

  @Input() public roles: SharePointReportProfileDriveItemRole = null;
  public hasRead: boolean;
  public hasUpdate: boolean;
  public hasOwner: boolean;
  public hasUnknown: boolean;

  constructor() { }

  ngOnInit() {
    if (this.roles != null) {
      this.hasRead = EnumHelper.HasFlag(this.roles, SharePointReportProfileDriveItemRole.Read);
      this.hasUpdate = EnumHelper.HasFlag(this.roles, SharePointReportProfileDriveItemRole.Write);
      this.hasOwner = EnumHelper.HasFlag(this.roles, SharePointReportProfileDriveItemRole.Owner);
      this.hasUnknown = this.roles == SharePointReportProfileDriveItemRole.Unknown;
    }
  }
}

import { Component, OnInit, Input, Inject } from '@angular/core';
import { OneDriveReportProfileAlertDelegateModel, ReportProfileResourceBase, SharePointReportProfileAlertPermissionsModel } from '@i2a/web-api-client';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { OneDriveDelegate } from 'projects/web-user-profile/src/app/models/providers/microsoft/one-drive/one-drive-delegate';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-base-one-drive-delegate-modal',
  templateUrl: './base-one-drive-delegate-modal.component.html',
  styleUrls: ['./base-one-drive-delegate-modal.component.scss']
})
export class BaseOneDriveDelegateModalComponent implements OnInit {
  @Input() public delegate: OneDriveDelegate;
  
  @Input() public resourceProfile: ReportProfileResourceBase<any>;

  public validation: OneDriveReportProfileAlertDelegateModel;
  public canSend: boolean;
  public folders: any[];//TODO CRADO
  public name: string;

  public driveItemsPermissions: any[];//TODO CRADO

  constructor(
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService,
    public session: SessionService
  ) {
    this.canSend = false;
  }

  public setCanSend() {
    setTimeout(() => {
      this.canSend = this.validation.folders.length > 0 || this.validation.allFolders || this.validation.permissions.invalidPaths.length > 0 || this.validation.permissions.allPaths;
    });
  }

  ngOnInit() {
    this.validation = new OneDriveReportProfileAlertDelegateModel();
    this.validation.folders = [];
    this.validation.allFolders = true;
    this.validation.delegate = this.delegate.accesses;
    this.folders = [];
    this.driveItemsPermissions = [];
    if (this.delegate) {
      this.delegate.accesses.foldersAccessed.forEach(folder => {
        this.folders.push({
          "name": folder,
          "checked": false
        });
      });
      if (this.delegate.accesses.isAnonymousUser) {
        this.name = this.i18Next.t("profile.provider.microsoft.oneDrive.anonymous");
      }
      else {
        this.name = this.delegate.accesses.name;
      }
      this.validation.permissions = new SharePointReportProfileAlertPermissionsModel();
      this.validation.permissions.invalidPaths = [];
      this.validation.permissions.allPaths = true;
      this.validation.permissions.permissions = this.delegate.permissions;
      if (this.delegate.permissions != null) {
        Object.keys(this.delegate.permissions.driveItems).forEach(driveItemKey => {
          this.driveItemsPermissions.push({
            "name": driveItemKey,
            "checked": false,
            "hasSensitivityLabel": this.delegate.permissions.driveItems[driveItemKey].hasSensitivityLabel,
            "sensitivityLabel": this.delegate.permissions.driveItems[driveItemKey].sensitivityLabel,
            "roles": this.delegate.permissions.driveItems[driveItemKey].rights
          });
        });
      }
      this.setCanSend();
    }
  }
}

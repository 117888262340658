import { BaseProviderInstanceResourceModel } from '../models/base-provider-instance-resource-model';
import { DeviceMappedModel } from '../models/device-mapped-model';
import { PageResultModel } from '../models/page-result-model';
import { ProviderAdminObjectHistorySummaryModel } from '../models/provider-admin-object-history-summary-model';
import { ProviderAdminObjectModel } from '../models/provider-admin-object-model';
import { ProviderAuditUsageStatisticsModel } from '../models/provider-audit-usage-statistics-model';
import { ProviderInstanceResourceListSetIsPremiumModel } from '../models/provider-instance-resource-list-set-is-premium-model';
import { ProviderInstanceResourceModel } from '../models/provider-instance-resource-model';
import { ProviderInstanceResourceSummaryModel } from '../models/provider-instance-resource-summary-model';
import { RegisterDeviceModel } from '../models/register-device-model';
import { ResourceActivityModel } from '../models/resource-activity-model';
import { RuleCreateModelWithUserId } from '../models/rule-create-model-with-user-id';
import { RuleSummaryModel } from '../models/rule-summary-model';
import { StatisticsGranularity } from '../models/statistics-granularity';
import { UserModel } from '../models/user-model';
import { UserSummaryModel } from '../models/user-summary-model';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatasourceService } from '../../data/datasource.service';

@Injectable({
	providedIn: 'root'
})
export class ProviderInstanceResourcesDatasourceService {

private route: string = "provider-instance-resources";

	constructor(private datasource: DatasourceService) {
		console.log("Init ProviderInstanceResourcesDatasourceService");
	}

	public search(name: string, providerId: string = null, providerTypeId: string = null, providerInstanceId: string = null, isRealTimeProcessing: boolean = null, isInLearning: boolean = null, isAuditing: boolean = null, isPermanentAuditing: boolean = null, isEndOfAudit: boolean = null, isEndOfLearning: boolean = null, isPremium: boolean = null, beginCreationDate: Date = null, endCreationDate: Date = null, hasOwner: boolean = null, limit: number = null, offset: number = null, useCache: boolean = false, throwError: boolean = true): Observable<PageResultModel<ProviderInstanceResourceModel>> {
		let partialUrl = `${this.route}`;

		return this.datasource.get<PageResultModel<ProviderInstanceResourceModel>>(partialUrl, {name: String(name),providerId: String(providerId),providerTypeId: String(providerTypeId),providerInstanceId: String(providerInstanceId),isRealTimeProcessing: String(isRealTimeProcessing),isInLearning: String(isInLearning),isAuditing: String(isAuditing),isPermanentAuditing: String(isPermanentAuditing),isEndOfAudit: String(isEndOfAudit),isEndOfLearning: String(isEndOfLearning),isPremium: String(isPremium),beginCreationDate: String(beginCreationDate),endCreationDate: String(endCreationDate),hasOwner: String(hasOwner),limit: String(limit),offset: String(offset) }, useCache, throwError);
	}

	public exportProviderInstanceResources(name: string, providerId: string = null, providerTypeId: string = null, providerInstanceId: string = null, isRealTimeProcessing: boolean = null, isInLearning: boolean = null, isAuditing: boolean = null, isPermanentAuditing: boolean = null, isEndOfAudit: boolean = null, isEndOfLearning: boolean = null, isPremium: boolean = null, beginCreationDate: Date = null, endCreationDate: Date = null, hasOwner: boolean = null, useCache: boolean = false, throwError: boolean = true): Observable<HttpResponse<Blob>> {
		let partialUrl = `${this.route}/export`;

		return this.datasource.get<HttpResponse<Blob>>(partialUrl, {name: String(name),providerId: String(providerId),providerTypeId: String(providerTypeId),providerInstanceId: String(providerInstanceId),isRealTimeProcessing: String(isRealTimeProcessing),isInLearning: String(isInLearning),isAuditing: String(isAuditing),isPermanentAuditing: String(isPermanentAuditing),isEndOfAudit: String(isEndOfAudit),isEndOfLearning: String(isEndOfLearning),isPremium: String(isPremium),beginCreationDate: String(beginCreationDate),endCreationDate: String(endCreationDate),hasOwner: String(hasOwner) }, useCache, throwError, 'blob','response');
	}

	public setIsPremiumResources(model: ProviderInstanceResourceListSetIsPremiumModel, throwError: boolean = true): Observable<BaseProviderInstanceResourceModel[]> {
		let partialUrl = `${this.route}/setIsPremium`;

		return this.datasource.put(partialUrl, model, { params: { } }, throwError);
	}

	public loadCollectedResource(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, useCache: boolean = false, throwError: boolean = true): Observable<ProviderInstanceResourceModel> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}`;

		return this.datasource.get<ProviderInstanceResourceModel>(partialUrl, { }, useCache, throwError);
	}

	public updateResourceName(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, resourceName: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}`;

		return this.datasource.put(partialUrl, null, { params: {resourceName: String(resourceName) } }, throwError);
	}

	public loadCollectedResourceSummary(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, useCache: boolean = false, throwError: boolean = true): Observable<ProviderInstanceResourceSummaryModel> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/summary`;

		return this.datasource.get<ProviderInstanceResourceSummaryModel>(partialUrl, { }, useCache, throwError);
	}

	public loadOwners(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, useCache: boolean = false, throwError: boolean = true): Observable<UserSummaryModel[]> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/owners`;

		return this.datasource.get<UserSummaryModel[]>(partialUrl, { }, useCache, throwError);
	}

	public onePageReport(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, beginDate: Date = null, endDate: Date = null, useCache: boolean = false, throwError: boolean = true): Observable<HttpResponse<Blob>> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/report/one-page`;

		return this.datasource.get<HttpResponse<Blob>>(partialUrl, {beginDate: String(beginDate),endDate: String(endDate) }, useCache, throwError, 'blob','response');
	}

	public promoteAsUser(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, orgUnitId: string = null, sendEmailToUser: boolean = null, throwError: boolean = true): Observable<UserModel> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/promote-user`;

		return this.datasource.put(partialUrl, null, { params: {orgUnitId: String(orgUnitId),sendEmailToUser: String(sendEmailToUser) } }, throwError);
	}

	public attachToUser(providerId: string, providerInstanceId: string, providerInstanceResourceId: string, userId: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerId}/${providerInstanceId}/${providerInstanceResourceId}/attach-to-user/${userId}`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public protect(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, learningDuration: string = null, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/protect`;

		return this.datasource.put(partialUrl, null, { params: {learningDuration: String(learningDuration) } }, throwError);
	}

	public unprotect(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/unprotect`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public startLearning(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, learningDuration: string, startDate: Date, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/start-learning`;

		return this.datasource.put(partialUrl, null, { params: {learningDuration: String(learningDuration),startDate: String(startDate) } }, throwError);
	}

	public stopLearning(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/stop-learning`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public startPermanentAudit(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/start-permanent-audit`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public stopPermanentAudit(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/stop-permanent-audit`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public stopAudit(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/stop-audit`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public getResourceSyncedDevices(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, isActiveBeginDate: Date = null, isActiveEndDate: Date = null, useCache: boolean = false, throwError: boolean = true): Observable<DeviceMappedModel[]> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/synced-devices`;

		return this.datasource.get<DeviceMappedModel[]>(partialUrl, {isActiveBeginDate: String(isActiveBeginDate),isActiveEndDate: String(isActiveEndDate) }, useCache, throwError);
	}

	public getResourceSyncedDevice(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, syncId: string, isActiveBeginDate: Date = null, isActiveEndDate: Date = null, useCache: boolean = false, throwError: boolean = true): Observable<DeviceMappedModel> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/synced-devices/${syncId}`;

		return this.datasource.get<DeviceMappedModel>(partialUrl, {isActiveBeginDate: String(isActiveBeginDate),isActiveEndDate: String(isActiveEndDate) }, useCache, throwError);
	}

	public registerDevice(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, syncId: string, registerDeviceModel: RegisterDeviceModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/synced-devices/${syncId}/register`;

		return this.datasource.put(partialUrl, registerDeviceModel, { params: { } }, throwError);
	}

	public unregisterDevice(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, syncId: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/synced-devices/${syncId}/unregister`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public loadProvideInstanceResourceAdminObject(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceItemId: string, providerInstanceResourceId: string, type: number, useCache: boolean = false, throwError: boolean = true): Observable<ProviderAdminObjectModel> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/admin-objects/${type}`;

		return this.datasource.get<ProviderAdminObjectModel>(partialUrl, {providerInstanceItemId: String(providerInstanceItemId) }, useCache, throwError);
	}

	public loadProvideInstanceResourceAdminObjectHistory(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, providerInstanceItemId: string, type: number, begin: Date = null, end: Date = null, limit: number = null, offset: number = null, useCache: boolean = false, throwError: boolean = true): Observable<PageResultModel<ProviderAdminObjectModel>> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/admin-objects/${type}/history`;

		return this.datasource.get<PageResultModel<ProviderAdminObjectModel>>(partialUrl, {providerInstanceItemId: String(providerInstanceItemId),begin: String(begin),end: String(end),limit: String(limit),offset: String(offset) }, useCache, throwError);
	}

	public loadProvideInstanceResourceAdminObjectHistoryInterval(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, providerInstanceItemId: string, type: number, refDate: Date = null, useCache: boolean = false, throwError: boolean = true): Observable<ProviderAdminObjectHistorySummaryModel[]> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/admin-objects/${type}/history-interval`;

		return this.datasource.get<ProviderAdminObjectHistorySummaryModel[]>(partialUrl, {providerInstanceItemId: String(providerInstanceItemId),refDate: String(refDate) }, useCache, throwError);
	}

	public loadResourceUsage(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, begin: Date, end: Date, granularity: StatisticsGranularity, useCache: boolean = false, throwError: boolean = true): Observable<ProviderAuditUsageStatisticsModel[]> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/provider-audits/usage-stats`;

		return this.datasource.get<ProviderAuditUsageStatisticsModel[]>(partialUrl, {begin: String(begin),end: String(end),granularity: String(granularity) }, useCache, throwError);
	}

	public loadResourceActivity(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, begin: Date, end: Date, useCache: boolean = false, throwError: boolean = true): Observable<ResourceActivityModel[]> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/provider-audits/resource-activities`;

		return this.datasource.get<ResourceActivityModel[]>(partialUrl, {begin: String(begin),end: String(end) }, useCache, throwError);
	}

	public loadByResource(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, restrictToUserId: string = null, includeInherited: boolean = false, withTestMode: boolean = null, useCache: boolean = false, throwError: boolean = true): Observable<RuleSummaryModel[]> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/rules`;

		return this.datasource.get<RuleSummaryModel[]>(partialUrl, {restrictToUserId: String(restrictToUserId),includeInherited: String(includeInherited),withTestMode: String(withTestMode) }, useCache, throwError);
	}

	public createResourceRule(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, rule: RuleCreateModelWithUserId, throwError: boolean = true): Observable<RuleSummaryModel> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/rules`;

		return this.datasource.post(partialUrl, rule, { params: { } }, throwError);
	}

	public rebuildPermissions(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, rebuildFromConfig: boolean = false, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/rebuild-permissions`;

		return this.datasource.post(partialUrl, null, { params: {rebuildFromConfig: String(rebuildFromConfig) } }, throwError);
	}

	public getPhoto(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceResourceId: string, includeProviderImage: boolean = true, defaultImageifNotFound: boolean = true, useCache: boolean = false, throwError: boolean = true): Observable<HttpResponse<Blob>> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/${providerInstanceResourceId}/photo`;

		return this.datasource.get<HttpResponse<Blob>>(partialUrl, {includeProviderImage: String(includeProviderImage),defaultImageifNotFound: String(defaultImageifNotFound) }, useCache, throwError, 'blob','response');
	}

	public clearCache(throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/clear-cache`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

}

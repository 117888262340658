import { ExchangeReportProfileDelegate } from '../../../microsoft/exchange/profile/exchange-report-profile-delegate';
import { ExchangeReportProfilePermissions } from '../../../microsoft/exchange/profile/exchange-report-profile-permissions';
import { ExchangeReportProfileRules } from '../../../microsoft/exchange/profile/exchange-report-profile-rules';
import { ReportProfileDeviceSyncModel } from '../../../profile/report-profile-device-sync-model';
import { ReportProfileResourceBase } from '../../../profile/report-profile-resource-base';
import { ReportProfileRulesComputedDelegate } from '../../../profile/report-profile-rules-computed-delegate';

export class ExchangeReportProfile extends ReportProfileResourceBase<ReportProfileRulesComputedDelegate> {
  delegates: ExchangeReportProfileDelegate[];
  rules: ExchangeReportProfileRules;
  permissions: ExchangeReportProfilePermissions[];
  unRegisteredDevices: ReportProfileDeviceSyncModel[];
}

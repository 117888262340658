<i2a-short-title-bar [isCollapsed]="isCollapsed" [hasAlert]="false">
  <ng-container title>{{ "profile.legend" | i18next}}</ng-container>
  <ng-container body>
    <table class="table table-sm id-table legend">
      <tbody>
        <tr>
          <td scope="row" class="text-nowrap" *ngIf="displayReadLegend">
            <span class="share-point-right" [title]="'SharePointReportProfileDriveItemRole.Read' | i18next ">
              <fa-icon [icon]="['fas', 'eye' ]"></fa-icon>
            </span>
            {{'SharePointReportProfileDriveItemRole.Read' | i18next }}
          </td>
          <td scope="row" class="text-nowrap" *ngIf="displayUpdateLegend">

            <span class="share-point-right" [title]="'SharePointReportProfileDriveItemRole.Write' | i18next ">
              <fa-icon [icon]="['fal', 'pen']"></fa-icon>
            </span>
            {{'SharePointReportProfileDriveItemRole.Write' | i18next }}
          </td>
        </tr>
        <tr>
          <td scope="row" class="text-nowrap" *ngIf="displayOwnerLegend">

            <span class="share-point-right" [title]="'SharePointReportProfileDriveItemRole.Owner' | i18next ">
              <fa-icon [icon]="['fal', 'user']"></fa-icon>
            </span>
            {{'SharePointReportProfileDriveItemRole.Owner' | i18next }}
          </td>
          <td scope="row" class="text-nowrap" *ngIf="displayUnknownLegend">

            <span class="share-point-right" [title]="'SharePointReportProfileDriveItemRole.Unknown' | i18next ">
              <fa-icon [icon]="['fas', 'question']"></fa-icon>
            </span>
            {{'SharePointReportProfileDriveItemRole.Unknown' | i18next }}
          </td>
        </tr>
        <tr>
          <td scope="row" class="text-nowrap">

            <span class="share-point-right" [title]="'profile.provider.microsoft.sharePoint.permission.group' | i18next ">
              <fa-icon [icon]="['fas', 'users']"></fa-icon>
            </span>
            {{'profile.provider.microsoft.sharePoint.permission.group' | i18next }}
          </td>
          <td scope="row" class="text-nowrap" >

            <span class="share-point-right" [title]="'profile.provider.microsoft.sharePoint.permission.individual' | i18next ">
              <fa-icon [icon]="['fas', 'user']"></fa-icon>
            </span>
            {{'profile.provider.microsoft.sharePoint.permission.individual' | i18next }}
          </td>
        </tr>
        <tr>
          <td *ngIf="hasSensitivityLabel" scope="row" class="text-nowrap">

            <span class="share-point-right" [title]="'profile.provider.microsoft.sharePoint.hasSensitivityLabel' | i18next ">
              <fa-icon [icon]="['far', 'lock-alt']"></fa-icon>
            </span>
            {{'profile.provider.microsoft.sharePoint.hasSensitivityLabel' | i18next }}
          </td>
          <td>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</i2a-short-title-bar>
<div id="authLoading" #authloading
    style="width:300px; height: 300px; position: fixed; margin-left: -150px; margin-top: -150px; top: 50%; left: 50%; text-align: center;">
    <span id="authLoadingText" #authloadingText>
     {{ loadingText }}
    </span>
    <br />
    <div class="loading-spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
    </div>
</div>
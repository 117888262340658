import { ConnectedUserModel } from './connected-user-model';
import { FunctionalExceptionModel } from './functional-exception-model';
import { OperationItemModel } from './operation-item-model';
import { OperationStatus } from './operation-status';

export class OperationItemsBaseModel {
  operationId: string;
  providerId: string;
  providerTypeId: string|null;
  providerInstanceId: string;
  connectedUser: ConnectedUserModel;
  affectedItems: OperationItemModel[];
  status: OperationStatus;
  exception: FunctionalExceptionModel;
  creationDate: Date;
  modificationDate: Date;
  type: number;
  typeResourceKey: string;
}

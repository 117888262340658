import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { TenantSummaryModel } from '@i2a/web-api-client';
import { SessionService } from '../../services/session-service';
import { Subscription } from 'rxjs';
import { TenantsComponent } from '../tenants/tenants.component';
import { Router, ActivatedRoute, Navigation, PRIMARY_OUTLET } from '@angular/router';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'i2a-tenant-list',
  templateUrl: './tenant-list.component.html',
  styleUrls: ['./tenant-list.component.scss']
})
export class TenantListComponent implements OnInit {
  public tenants: TenantSummaryModel[];
  public displayTenants: boolean;
  public currentNavigation: Navigation;
  public headerStyle: any;

  constructor(
    public context: SessionService,
    public session: SessionService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    @Inject(I18NEXT_SERVICE) private translateI18Next: ITranslationService
    ) {
    this.displayTenants = false;
    this.currentNavigation = this.router.getCurrentNavigation();
  }

  ngOnInit() {
    if (this.translateI18Next.exists('style.header', {})) {
      this.headerStyle = this.sanitizer.bypassSecurityTrustStyle(this.translateI18Next.t('style.header'));
    }
    else { 
      this.headerStyle = null;
    }
    var g = this.currentNavigation.finalUrl.root.children[PRIMARY_OUTLET];
    if (g.segments[g.segments.length -1].path == "tenants") {
      if (this.session.Account.tenants.length == 1) {
        this.router.navigate([this.session.Account.tenants[0].code], { relativeTo: this.activatedRoute });
      }
      else {
        this.displayTenants = true;
      }
    }
    this.tenants = this.session.Account.tenants;
  }

}
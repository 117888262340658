import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import { MicrosoftOfficeProfileProviderDatasourceService, OneDriveReportProfileAlertExternalDelegatesModel, OneDriveReportProfile } from '@i2a/web-api-client';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { OneDriveExternalDelegatesModalComponent } from '../one-drive-external-delegates-modal/one-drive-external-delegates-modal.component';
import { OneDriveReportProfileDelegateWithAlert } from 'projects/web-user-profile/src/app/models/providers/microsoft/one-drive/one-drive-report-profile-delegate-with-alert';
import dayJS from 'dayjs';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { OneDriveDelegate } from 'projects/web-user-profile/src/app/models/providers/microsoft/one-drive/one-drive-delegate';

@Component({
  selector: 'i2a-one-drive-external-delegates',
  templateUrl: './one-drive-external-delegates.component.html',
  styleUrls: ['./one-drive-external-delegates.component.scss']
})
export class OneDriveExternalDelegatesComponent extends ProfileItemBaseClassComponent<OneDriveReportProfile> implements OnInit, OnDestroy {
  @Input() public delegates: OneDriveDelegate[];


  public name: string;
  public delegatesWithAlerts: OneDriveReportProfileDelegateWithAlert[];
  public lastAccess: Date;
  public isCollapsed: any;

  constructor(
    modalService: NgbModal,
    toastr: ToastrService,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
  }

  setItems() {
    if (this.delegates && this.alerts) {
      this.lastAccess = null;
      super.setItems();
      this.hasAlert = false;
      this.delegatesWithAlerts = this.delegates.map(d => {
        var dWithAlert = {
          accesses: d.accesses, permissions: d.permissions,
          hasAlert: AlertReasonHelper.alertsContainsDelegate(this.alerts, d.accesses, false),
          hasAlertRecentlyClosed:  AlertReasonHelper.alertsContainsDelegate(this.alerts, d.accesses, true),
        };
        this.hasAlert = this.hasAlert || dWithAlert.hasAlert;
        this.hasAlertRecentlyClosed = this.hasAlertRecentlyClosed || dWithAlert.hasAlertRecentlyClosed;
        if (this.lastAccess == null || dayJS(d.accesses.lastAccess).isAfter(dayJS(this.lastAccess))) {
          this.lastAccess = d.accesses.lastAccess;
        }

        return dWithAlert;
      })
    }
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(OneDriveExternalDelegatesModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.delegates = this.delegates;
    modalRef.result.then((model: OneDriveReportProfileAlertExternalDelegatesModel) => {
      this.initAlertModel(model);
      model.providerInstanceResource = this.providerInstanceResource;
      this.subscriptions.add(this.microsoftDatasource.alertOneDriveExternalDelegates(this.profile.providerInstanceId, this.profile.userId, model).subscribe(model => {
        this.setAlertsProcessed(model);
      }));
    }).catch((error) => {
      // console.log(error);
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
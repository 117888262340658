
export class ProviderInstanceStatisticsModel {
  resourcesCount: number;
  permanentAuditingCount: number;
  realTimeProcessingCount: number;
  inLearningCount: number;
  auditingCount: number;
  premiumCount: number;
  closestEndOfAuditDate: Date|null;
  closestEndOfLearningDate: Date|null;
}

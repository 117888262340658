import { Injectable, OnDestroy, Inject } from '@angular/core';
import { ContextService } from './context.service';
import { Subscription, Observable, from, of, BehaviorSubject, switchMap, catchError, concatMap } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';
import dayJS from 'dayjs';
import { IConfigService, I2A_CONFIG_SERVICE, IWebConfig } from './config.service';
import { LocalizationUrlService } from '../data/localization-url.service';
import { StringHelper } from '../helpers/string-helper';
import localizedFormat from 'dayjs/plugin/localizedFormat.js';
import localeData from 'dayjs/plugin/localeData.js';

@Injectable({
  providedIn: 'root'
})
export class CultureManagerService implements OnDestroy {
  private subscriptions = new Subscription();

  //#region resourcesReloaded
  private resourcesReloadedSubject?: BehaviorSubject<string>;
  public resourcesReloaded$: Observable<string>;
  private resourcesReloaded: string;
  //#endregion

  public tenantCode: string;

  public availableCultures: string[] = [];

  constructor(
    @Inject(I2A_CONFIG_SERVICE) private configService: IConfigService<IWebConfig>,
    private context: ContextService,
    private localizationUrlService: LocalizationUrlService,
    @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService) {
    console.log("Init CultureManager");
    this.tenantCode = "";

    this.resourcesReloaded = "";
    this.resourcesReloadedSubject = new BehaviorSubject<string>(this.resourcesReloaded);
    this.resourcesReloaded$ = this.resourcesReloadedSubject.asObservable();

    this.subscriptions.add(this.getAvailableCultures().subscribe((cultures) => {
      this.availableCultures = cultures;
    }));

    this.subscriptions.add(this.context.Culture$.subscribe((value) => {
      if (!!value) {
        if (this.i18NextService.language === value) {
          return of();
        }
        else {
          return this.Use(value);
        }
      }
      else {
        return this.Use(this.configService.Config.defaultCulture);
      }
    }));

    this.subscriptions.add(this.i18NextService.events.languageChanged.subscribe((e: any) => {
      console.log(`CultureManagerService language changed ${JSON.stringify(e)}`);
    }));
    dayJS.extend(localizedFormat);
    dayJS.extend(localeData);
  }

  public changeNamespace(tenantCode: string) {
    // console.log(`reset translations tenantcode change from ${this.tenantCode} => ${tenantCode} [${this.context.Culture}]`);
    this.tenantCode = tenantCode;
    this.i18NextService.options.backend.loadPath = this.localizationUrlService.getResourcesUrl(this.configService.Config.localization, this.configService, this.tenantCode);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public async Use(culture: string) {
    var namespace = StringHelper.isNullOrWhitespace(this.tenantCode) ? "WebUserProfile" : this.tenantCode;
    await this.i18NextService.reloadResources(this.context.Culture, [namespace], () => {
      console.log(`Translations => ${namespace}: reloaded`);
      this.i18NextService.setDefaultNamespace(namespace);
      this.resourcesReloaded = this.tenantCode;
      this.resourcesReloadedSubject.next(this.resourcesReloaded);
    });

    let language = this.getLanguageFromCulture(culture);
    dayJS.locale(language);
    await this.i18NextService.changeLanguage(culture);
  }

  private getLanguageFromCulture(culture: string): string {
    let language = culture.toLocaleLowerCase();
    if (language.indexOf('-') >= 0) {
      return language.split('-')[0];
    }
    return language;
  }

  private getAvailableCultures(): Observable<string[]> {
    // default cultures loaded in case of any issue when trying to retrieve available cultures from server
    let defaultCultures = ["en-US", "fr-FR", "de-DE"];
    const culturesUrl = this.localizationUrlService.getAvailableCulturesUrl(this.configService.Config.localization, this.configService, this.tenantCode);
    return fromFetch(culturesUrl).pipe(
      switchMap(response => {
        if (response.ok) {
          return response.json();
        } else {
          return of(defaultCultures);
        }
      }),
      catchError(err => {
        // Network or other error, handle appropriately
        return of(defaultCultures);
      })
    );
  }
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}




export enum UserReportInformation {
  Rules = 1,
  Notifications = 2,
  Permissions = 4,
  Devices = 8,
  IPRanges = 16,
  Resources = 32,
  Alerts = 64,
  ProfileCountries = 128
}

import { Component, OnInit, Input } from '@angular/core';
import { ProviderInstanceUsersDatasourceUrlService, BaseProviderInstanceUserModel } from '@i2a/web-api-client';

@Component({
  selector: 'i2a-piu-photo',
  templateUrl: './piu-photo.component.html',
  styleUrls: ['./piu-photo.component.scss']
})
export class PiuPhotoComponent implements OnInit {
  @Input() public size: number;
  @Input() public providerId: string;
  @Input() public providerInstanceId: string;
  @Input() public providerInstanceResourceId: string;
  @Input() public providerInstanceUser: BaseProviderInstanceUserModel;
  public piuPhotoUrl:string;
  
  constructor(
    private providerInstanceUsersDatasourceUrl: ProviderInstanceUsersDatasourceUrlService
  ) { }

  ngOnInit() {
    if (this.providerId && this.providerInstanceId && this.providerInstanceResourceId) {
      this.initPhotoUrl(this.providerId,this.providerInstanceId, this.providerInstanceResourceId);
    }
    else if (this.providerInstanceUser)
    {
      this.initPhotoUrl(this.providerInstanceUser.providerId, this.providerInstanceUser.providerInstanceId, this.providerInstanceUser.providerInstanceResourceId);
    }
    else {
      this.piuPhotoUrl = "/assets/pictos/resource.jpg";
    }
  }

  private initPhotoUrl(providerId: string, providerInstanceId: string, providerInstanceResourceId: string)
  {
    this.piuPhotoUrl = this.providerInstanceUsersDatasourceUrl.PhotoUrl(providerId, providerInstanceId, providerInstanceResourceId, true);
  }
}
import { AlertReasonCategory } from './alert-reason-category';
import { BaseModel } from './base-model';
import { RuleEvaluationResultModel } from './rule-evaluation-result-model';

export class AccessEvaluatedActualRulesModel extends BaseModel {
  actualEvaluatedResourceRules: RuleEvaluationResultModel[];
  actualEvaluatedUserRules: RuleEvaluationResultModel[];
  reasonCategory: AlertReasonCategory|null;
  actualEvaluationResult: boolean;
}

<i2a-profile-card *ngIf="group" (onViewMoreDetails)="openDetails($event)"
  [title]="'profile.provider.microsoft.sharePoint.group.visibility.title'| i18next : { visibility: group.visibility }"
  [subTitle]="'profile.provider.microsoft.sharePoint.group.visibility.subTitle' | i18next"
  (onWarnClick)="warnClick($event)" [hasAlert]="hasAlert" [hasAlertRecentlyClosed]="hasAlertRecentlyClosed"
  [warningLevel]="warningLevel" [loading]="loading">

  <ng-container picto>
    <i2a-badge-icon>
      <ng-container icon>
        <fa-icon [icon]="['fas', 'eye']" size="lg"></fa-icon>
      </ng-container>
    </i2a-badge-icon>
  </ng-container>
  <ng-container content>
    <div class="container p-0 sharepoint-visibility">
      <div class="row no-gutters">
        <div class="col m-2">
          <p class="warn-visibility mb-0" *ngIf="isVisible">
            {{ "profile.provider.microsoft.sharePoint.visibility.publicInformation"  | i18next  }}
          </p>
          <p class="mb-0" *ngIf="!isVisible">
            {{ "profile.provider.microsoft.sharePoint.visibility.privateInformation"  | i18next  }}
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</i2a-profile-card>
import { Component, OnInit, Input, ViewChild, OnDestroy, ComponentRef } from '@angular/core';
import { TemplateService } from 'projects/web-user-profile/src/app/services/template.service';
import { ReportProfileBaseModel, UserModel, ProviderInstanceModel, ConnectedAccountModel } from '@i2a/web-api-client';
import { Subscription } from 'rxjs';
import { IModuleTemplateContainer } from 'projects/web-user-profile/src/app/models/templates/modules/i-module-template-container';
import { Template } from 'projects/web-user-profile/src/app/models/templates/template';
import { IBaseModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-base-module';
import { ContainerDirective } from 'projects/web-user-profile/src/app/directives/container-directive';
import { WarningInfo } from 'projects/web-user-profile/src/app/models/warnings/warning-info';

@Component({
  selector: 'i2a-my-profile-template',
  templateUrl: './my-profile-template.component.html',
  styleUrls: ['./my-profile-template.component.scss']
})
export class MyProfileTemplateComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  private components: ComponentRef<IBaseModule>[];

  @Input() public providerInstance: ProviderInstanceModel;
  @Input() public user: UserModel;
  @Input() public profile: ReportProfileBaseModel;
  @Input() public template: Template;
  @Input() public isAlert: boolean;
  @Input() public connectedAccount: ConnectedAccountModel;

  @ViewChild(ContainerDirective, { static: true }) container: ContainerDirective;

  constructor(private templateService: TemplateService) {
    this.components = [];
  }

  ngOnInit() {
    if (this.user && this.providerInstance && this.profile && this.template && this.connectedAccount && this.container) {
      if (this.template.containerModule.isEnabled) {
        var component = this.templateService.loadComponent<IModuleTemplateContainer>(this.container.viewContainerRef, this.template.containerModule.id);
        if (component) {
          this.components.push(<ComponentRef<any>>component);
          component.instance.initialize(this.user, this.profile, this.profile.alerts, this.template);
          this.subscriptions.add(component.instance.onAlertSent.subscribe(e => this.alertSent(e)));
          this.subscriptions.add(component.instance.onHasWarnings.subscribe(e => this.updateWarnings(e)));
        }
      }
    }
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.components.forEach(c => c.destroy());
  }

  alertSent(alert): void {
    let alerts = [...this.profile.alerts];//force onChange detection
    let id = alerts.findIndex(a => a.id == alert.id);
    if (id >= 0) {
      alerts[id] = alert;
    } else {
      alerts.push(alert);
    }
    this.profile.alerts = alerts;
    this.components.forEach(c => c.instance.updateAlerts(this.profile.alerts));
  }

  updateWarnings(warnings: Array<WarningInfo>): void {
    this.components.forEach(c => c.instance.updateWarnings(warnings));
  }
}

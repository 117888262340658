import { ReportProfileAlertResourceBaseModel } from '../../../profile/report-profile-alert-resource-base-model';
import { SharePointReportProfileAlertPermissionsModel } from '../../../microsoft/sharepoint/profile/share-point-report-profile-alert-permissions-model';
import { SharePointReportProfileDelegate } from '../../../microsoft/sharepoint/profile/share-point-report-profile-delegate';

export class SharePointReportProfileAlertDelegateModel extends ReportProfileAlertResourceBaseModel {
  delegate: SharePointReportProfileDelegate;
  permissions: SharePointReportProfileAlertPermissionsModel;
  allFolders: boolean;
  folders: string[];
}

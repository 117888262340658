import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
import { MicrosoftOfficeProfileProviderDatasourceService, ExchangeReportProfileRules, ExchangeReportProfileAlertForwardMailboxesModel, AlertConstants, ExchangeReportProfile, AlertInvalidStateFeaturedModel } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { ExchangeRuleConfigModalComponent } from '../exchange-rule-config-modal/exchange-rule-config-modal.component';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ExchangeForwarMailboxWithAlert } from 'projects/web-user-profile/src/app/models/providers/microsoft/exchange/exchange-forwar-mailbox-with-alert';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';

@Component({
  selector: 'i2a-exchange-rule-config',
  templateUrl: './exchange-rule-config.component.html',
  styleUrls: ['./exchange-rule-config.component.scss']
})
export class ExchangeRuleConfigComponent extends ProfileItemBaseClassComponent<ExchangeReportProfile> implements OnInit, OnDestroy {
  @Input() public rules: ExchangeReportProfileRules;


  public description: string[];
  private forwarMailboxesInAlert: { [id: string]: boolean; };
  public forwardMailBoxes: ExchangeForwarMailboxWithAlert[];

  constructor(
    modalService: NgbModal,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
  }

  setItems() {
    if (this.rules && this.alerts) {
      super.setItems();
      this.hasAlert = this.hasAlertInAlerts(false);
      this.hasAlertRecentlyClosed = this.hasAlertInAlerts(true);
      this.forwardMailBoxes = this.rules.forwardMailBoxes.map(forwardMailBox => {
        let item = {
          forwardMailBox: forwardMailBox,
          hasAlert: this.forwarMailboxesInAlert[forwardMailBox]
        };
        return item;
      });
      this.id = `${this.componentId}-${this.providerInstanceResource.providerTypeId}-${this.providerInstanceResource.providerInstanceResourceId}-ExchangeRuleConfig`;
    }
  }

  private hasAlertInAlerts(done: boolean): boolean {
    this.forwarMailboxesInAlert = AlertReasonHelper.GetIds(this.alerts, AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_FORWARD_EMAIL, done);
    return Object.keys(this.forwarMailboxesInAlert).length > 0;
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(ExchangeRuleConfigModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.rules = this.rules;
    modalRef.result.then((model: ExchangeReportProfileAlertForwardMailboxesModel) => {
      this.initAlertModel(model);
      model.providerInstanceResource = this.providerInstanceResource;
      this.subscriptions.add(this.microsoftDatasource.alertExchangeForwardMailboxes(this.profile.providerInstanceId, this.profile.userId, model).subscribe(model => {
        this.setAlertProcessed(model);
      }));
    }).catch((error) => {
      // console.log(error);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}

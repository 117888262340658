<table class="table-sm id-table mb-3">
    <tbody>
        <tr>
            <td scope="row text-center" class="p-0 id-header-picto">
                <ng-content select="[picto]"></ng-content>
            </td>
            <td scope="row" class="text-break w-100 align-self-center" #headertitle>
                <ng-content select="[title]"></ng-content>
            </td>
        </tr>
    </tbody>
</table>
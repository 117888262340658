import { TenantSummaryModel } from './tenant-summary-model';

export class ConnectedAccountModel {
  userId: string|null;
  email: string;
  fullName: string;
  authType: string;
  accountType: string;
  culture: string;
  tenants: TenantSummaryModel[];
  isSupport: boolean;
  isFederated: boolean;
}

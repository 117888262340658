import { AlertCommentModel } from './alert-comment-model';
import { ConnectedUserModel } from './connected-user-model';
import { KeyValuePair } from '../../models/key-value-pair';
import { ProtectionAlertSummaryModel } from './protection-alert-summary-model';
import { ProviderAdminObjectThreatReasonModel } from './provider-admin-object-threat-reason-model';
import { UndertakenActionModel } from './undertaken-action-model';

export class AlertInvalidStateFeaturedModel extends ProtectionAlertSummaryModel {
  comments: AlertCommentModel[];
  loggedUser: ConnectedUserModel;
  reasons: ProviderAdminObjectThreatReasonModel[];
  annotations: KeyValuePair[];
  undertakenActions: UndertakenActionModel[];
}

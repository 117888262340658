<i2a-resource-actions [alerts]="alerts" (onAlertSent)="alertSent($event)" (onHasWarnings)="hasWarnings($event)"
[resourceNotBelongToMe]="configuration.resourceNotBelongToMe" 
[deleteResource]="configuration.deleteResource"
[commentResource]="configuration.commentResource"
[hideI2AOwners]="configuration.hideI2AOwners"
[profile]="profile"
[user]="user"
[alerts]="alerts"
[resource]="resource"
[openSite]="configuration.openSite"
[siteUrl]="resourceProfile.siteUrl"
[componentId]="componentId" 
[resourceProfile]="resourceProfile"
></i2a-resource-actions>

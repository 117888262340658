
export class UserUpdateModel {
  orgUnitId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  timeZoneId: string;
  culture: string;
}

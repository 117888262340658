import { Component, OnInit, EventEmitter, Inject, Input } from '@angular/core';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { ReportProfileBaseModel, UserModel, BaseProviderInstanceResourceModel, MicrosoftOfficeReportProfileModel, OneDriveReportProfile, AlertInvalidStateFeaturedModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { OneDriveNodataConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/one-drive-nodata-configuration';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';
import { OneDriveDelegate } from 'projects/web-user-profile/src/app/models/providers/microsoft/one-drive/one-drive-delegate';

@RegisterMyProfileComponent(TemplateConstants.MODULE_ONEDRIVE_NODATA)
@Component({
  selector: 'i2a-one-drive-nodata-module',
  templateUrl: './one-drive-nodata-module.component.html',
  styleUrls: ['./one-drive-nodata-module.component.scss']
})
export class OneDriveNodataModuleComponent extends ProfileBaseClassComponent<OneDriveNodataConfiguration,OneDriveReportProfile> implements OnInit, IModule<OneDriveNodataConfiguration> {
  private resource: BaseProviderInstanceResourceModel;
  public OneDriveProfile: OneDriveReportProfile;
  
  @Input() 
  public delegatesWithAccessAndPermissions: OneDriveDelegate[];
  
  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, null);
  }

  ngOnInit() {
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: OneDriveNodataConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel, resourceTemplate?: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.resource = resource;
    this.OneDriveProfile = (<MicrosoftOfficeReportProfileModel>profile).oneDriveProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { ZimbraMailingReportProfileFilterRule, ZimbraMailingReportProfileAlertFilterRuleModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-zimbra-filter-rule-modal',
  templateUrl: './zimbra-filter-rule-modal.component.html',
  styleUrls: ['./zimbra-filter-rule-modal.component.scss']
})
export class ZimbraFilterRuleModalComponent implements OnInit {
  @Input() public filterRule: ZimbraMailingReportProfileFilterRule;
  @Input() public resourceProfile: ReportProfileResourceBase<any>;

  public validation: ZimbraMailingReportProfileAlertFilterRuleModel;
  public canSend: boolean;
  public title: string;

  constructor(
    private modalService: NgbModal, 
    public session: SessionService
  ) {
    this.canSend = false || this.validation.comments.length > 0 || this.session.IgnoreCommentToSend;
  }

  ngOnInit() {
    this.validation = new ZimbraMailingReportProfileAlertFilterRuleModel();
  }
}

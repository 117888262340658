<i2a-page-content [hasPadding]="true" [headerStyle]="headerStyle">
  <ng-container navbar>
    <i2a-nav-bar></i2a-nav-bar>
  </ng-container>
  <ng-container content-body>
    <ng-container *ngIf="displayTenants">
      <h1>
        {{ 'tenants.title' | i18next }}
      </h1>
      <div *ngIf="tenants">
        <table class="table table-striped table-sm ">
          <thead>
            <tr>
              <th scope="col">{{ 'tenants.code' | i18next }}</th>
              <th scope="col">{{ 'tenants.name' | i18next }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let tenant of tenants">
              <td scope="row">
                <a [routerLink]="['/tenants',tenant.code,'dashboard']">
                  {{tenant.code}}
                </a>
              </td>
              <td scope="row">
                <a [routerLink]="['/tenants',tenant.code,'dashboard']">
                  {{tenant.name}}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
    <router-outlet></router-outlet>
  </ng-container>
</i2a-page-content>
<i2a-get-started *ngIf="template" [(start)]="start" [loading]="loading" [noCollectedResources]="noCollectedResources"
  [collectedResourcesCount]="collectedResourcesCount" [progressValue]="progressValue"
  [collectedResources]="collectedResources" [collectedResourcesLoaded]="collectedResourcesLoaded"
  [loadingRes]="loadingRes" [configuration]="template.configuration" 
  [errorPendingCollect]="errorPendingCollect" [errorPendingCollectObject]="errorPendingCollectObject"></i2a-get-started>

<i2a-my-profile-template *ngIf="profile && template && start" [providerInstance]="providerInstance" [user]="user"
  [profile]="profile" [template]="template" [connectedAccount]="connectedAccount" [isAlert]="isAlert">
</i2a-my-profile-template>

<i2a-start-tour *ngIf="profile && template && start" [tour]="'profile'"></i2a-start-tour>
<i2a-profile-card [id]="id" [title]="title"
  (onWarnClick)="warnClick($event)" [hasAlert]="hasAlert" [hasAlertRecentlyClosed]="hasAlertRecentlyClosed" [warningLevel]="warningLevel">
  <ng-container picto>
    <i2a-delegate-photo [isAnonymous]="false" [isDefault]="false" [isApplication]="false"
      [pir]="permissions.providerInstanceResource" [piu]="permissions.providerInstanceUser" [isExternalUser]="false"
      [customIcon]="permissions.isGroup ? ['fas', 'users']: null">
    </i2a-delegate-photo>
  </ng-container>

  <ng-container subTitle>
    <span>{{ maxPermission }}</span>
  </ng-container>

</i2a-profile-card>

<ng-template #viewDetailTemplate>
  <i2a-modal-profile-header-title>
    <ng-container picto>
      <i2a-delegate-photo [isAnonymous]="false" [isDefault]="false" [isApplication]="false"
        [pir]="permissions.providerInstanceResource" [piu]="permissions.providerInstanceUser" [isExternalUser]="false"
        [customIcon]="permissions.isGroup ? ['fas', 'users']: null">
      </i2a-delegate-photo>
    </ng-container>
    <ng-container title>
      <span [title]="title">{{title}}</span>
    </ng-container>
  </i2a-modal-profile-header-title>

  <div class="ml-2 mb-3">
    <span>{{ maxPermission }}</span>
  </div>

  <i2a-file-share-permission-detail [permissions]="permissions"></i2a-file-share-permission-detail>
</ng-template>
<i2a-sub-title class="col-12" [title]="'profile.title.myProfile' | i18next">
</i2a-sub-title>

<div class="container-fluid">
  <div class="row no-gutters">
    <div class="col-12 id-nodata">
      <i2a-message [danger]="true">
        <ng-container message-content>
          {{ errorMessageToShow }}
        </ng-container>
      </i2a-message>
    </div>
  </div>
</div>
import { Injectable } from "@angular/core";
@Injectable()
export class Constants {

    public static readonly SUSPICIOUS_ACCESS: string = "SuspiciousAccess";
    public static readonly SUSPICIOUS_CONFIGURATION_CHANGE: string = "SuspiciousConfigurationChange";
    public static readonly SUSPICIOUS_GLOBAL_CONFIGURATION_CHANGE: string = "SuspiciousGlobalConfigurationChange";
  
    //#region Providers
  
    public static readonly PROVIDER_ID_IDECSI: string = "00000000-0000-0000-0000-001111111111";
    public static readonly PROVIDER_ID_BOX: string = "4a830c8f-c44b-4612-84f1-07a33edb8941";
    public static readonly PROVIDER_ID_DROPBOX: string = "f99dc8fe-8d97-4b71-9c9e-15a42b628e55";
    public static readonly PROVIDER_ID_MICROSOFT: string = "d94f0e1a-e3b9-4bab-85f2-b7bf0c27ea97";
    public static readonly PROVIDER_ID_OPENTEXT: string = "f99dc8fe-8d97-4b71-9c9e-15a42b628e55";
    public static readonly PROVIDER_ID_SLACK: string = "730894d1-cf01-4d40-97ef-898702e599d0";
    public static readonly PROVIDER_ID_INWEBO: string = "f10e2c25-0b7a-4828-977b-0ea65f2c4c72";
    public static readonly PROVIDER_ID_ZIMBRA: string = "6f2203c5-edaf-4d6a-b68d-e128a0218f2b";
    public static readonly PROVIDER_ID_SAP: string = "bd517f18-8445-4616-b85d-a747a7ba223c";
  
    //#endregion
  
    //#region Provider types
  
    public static readonly PROVIDER_TYPE_INTRANET: string = "b6a4a668-ee88-4de4-8f92-893a753e64ad";
    public static readonly PROVIDER_TYPE_MAILING: string = "5dde9bcf-2ba2-41e9-9b7f-9dfabb6ffeb1";
    public static readonly PROVIDER_TYPE_PERSONAL_STORAGE: string = "19a94010-621b-47a4-85a3-137c79cb9aba";
    public static readonly PROVIDER_TYPE_GENERIC: string = "d6ee2fee-3451-4b97-96b5-7b442eebea73";
    public static readonly PROVIDER_TYPE_FILE_SHARE: string = "f029ace8-41ce-4d13-8c5d-c9bab19fd8c4";
  
    //#endregion
  
    //#region providerInstance

    public static readonly ANNOTATION_DEFAULT_PROVIDERINSTANCE: string = "i2a.providerInstance.default";
  
    //#endregion

  //#region undertakenActions

  public static readonly UNDERTAKEN_ACTION_ALERT_REMEDIATION_CREATED: string = "RemediationCreatedAction";
  public static readonly UNDERTAKEN_ACTION_ALERT_REMEDIATION_TERMINATED: string = "remediationTerminatedAction";

    //#endregion
  
  }
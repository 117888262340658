import { Component, OnInit, Input, Inject } from '@angular/core';
import { StringHelper } from 'projects/web-user-profile/src/app/helpers/string-helper';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ProfileBaseClassComponent } from '../profile-base-class-component';
import { ResourceLastAccessConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/resource-last-access-configuration';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { UserModel, ReportProfileBaseModel, BaseProviderInstanceResourceModel, AlertInvalidStateFeaturedModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { ProviderHelper } from 'projects/web-user-profile/src/app/helpers/provider-helper';

@RegisterMyProfileComponent(TemplateConstants.MODULE_DELEGATE_LAST_ACCESS)
@Component({
  selector: 'i2a-resource-last-access',
  templateUrl: './resource-last-access.component.html',
  styleUrls: ['./resource-last-access.component.scss']
})
export class ResourceLastAccessComponent extends ProfileBaseClassComponent<ResourceLastAccessConfiguration,ReportProfileResourceBase<any>> implements OnInit, IModule<ResourceLastAccessConfiguration> {
  public visible: boolean;

  public alertsNotClosed: AlertInvalidStateFeaturedModel[] = [];
  public alertsClosed: AlertInvalidStateFeaturedModel[] = [];

  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, null);
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: ResourceLastAccessConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.resourceProfile = ProviderHelper.GetResource(profile, resource);
    this.visible = !StringHelper.isNullOrWhitespace(this.resourceProfile.lastAccess);
    if (this.alerts?.length > 0) {
      this.alertsNotClosed = this.alerts.filter(a => a.end === null)
      this.alertsClosed = this.alerts.filter(a => a.end !== null);
    }
  }

  ngOnInit() {
  }

}

<i2a-profile-alert-base-modal
  [manualDescriptionKey]="'applyCorrection.providers.microsoft.sharePoint.administrators.manual.description'"
  [manualLinkKey]="'applyCorrection.providers.microsoft.sharePoint.administrators.manual.link'"
  [automaticDescriptionKey]="'applyCorrection.providers.microsoft.sharePoint.administrators.automatic.description'"
  [ignoreCommentToSend]="session.IgnoreCommentToSend" [displayComment]="session.DisplayComment" [displayHelp]="false"
  [validation]="validation" [canSend]="canSend"
  [modalTitle]="'profile.provider.microsoft.sharePoint.administrators.confirmationOfInformationTitle' | i18next "
  [informationKey]="informationKey" [resourceProfile]="resourceProfile" [headerTitle]="headerTitle">

  <ng-container header-picto>
    <i2a-badge-icon>
      <ng-container icon>
        <fa-icon [icon]="['fas', 'users-cog' ]" size="lg"></fa-icon>
      </ng-container>
    </i2a-badge-icon>
  </ng-container>

  <ng-container header-title>
    {{"profile.provider.microsoft.sharePoint.administrators.title" | i18next }}
  </ng-container>

  <ng-container body>
    <div class="container-fluid">

      <!-- MEMBERS -->
      <div class="row pt-3" *ngIf="informationKey">
      
        <div class="form-group col-12">
          <h4>{{"profile.provider.microsoft.sharePoint.administrators.members" | i18next }}</h4>
          <table class="table-sm id-table">
            <tbody>
              <tr *ngFor="let member of members">
                <td scope="row">
                  <i2a-input-checkbox [(model)]="member.checked" (modelChange)="checkedMember($event, member)"
                    [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                    [valueUnChecked]="false"> </i2a-input-checkbox>
                </td>
                <td scope="row" class="text-break w-100">
                  {{member.name}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- TODO Invalid owner -->
    </div>
  </ng-container>

</i2a-profile-alert-base-modal>
<i2a-delegate-folders [title]="name" [subTitle]="delegate.accesses.lastAccess | amDateFormat: 'LLL'"
  [delegate]="delegateFolders" [isAlert]="isAlert" [warningLevel]="warningLevel" [hasAlert]="hasAlert"
  [hasAlertRecentlyClosed]="hasAlertRecentlyClosed" (onWarnClick)="warnClick($event)"
  [buttonOpenAlertModalKey]="delegate.permissions != null ? 'button.openAlertModal' : 'button.openAlertModalAnomaly' "
  [viewWarnButton]="viewWarnButton">

  <ng-container picto>
    <i2a-delegate-photo [isAnonymous]="delegate.accesses.isAnonymousUser"
      [isDefault]="delegate.accesses.logonUserId == 'Default'" [isApplication]="delegate.accesses.isApplication"
      [isExternalUser]="delegate.accesses.isExternalUser" [pir]="delegate.accesses.providerInstanceResource"
      [piu]="delegate.accesses.providerInstanceUser"></i2a-delegate-photo>
  </ng-container>
</i2a-delegate-folders>
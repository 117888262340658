import { Injectable } from "@angular/core";
import { Routes } from '@angular/router';
import { ProbesStatusComponent } from '../components/probes-status/probes-status.component';
import { AuthCallbackComponent } from '../components/auth-callback/auth-callback.component';
import { SignOutComponent } from '../components/sign-out/sign-out.component';
import { AuthCallbackCentralComponent } from '../components/auth-callback-central/auth-callback-central.component';

@Injectable()
export class WebCoreRoutesService {
    constructor() {
    }

    public static GetRoutes(): Routes {
        return [
            { path: 'probes/status', component: ProbesStatusComponent },
            { path: 'auth-callback', component: AuthCallbackComponent },
            { path: 'auth-callback-central', component: AuthCallbackCentralComponent },
            { path: 'sign-out', component: SignOutComponent }
          ];
    }

}
import { AlertInvalidStateFeaturedModel } from '../models/alert-invalid-state-featured-model';
import { ProviderAuditUsageStatisticsModel } from '../models/provider-audit-usage-statistics-model';
import { ProviderInstanceModel } from '../models/provider-instance-model';
import { ReportProfileAlertDeleteResourceModel } from '../models/profile/report-profile-alert-delete-resource-model';
import { ReportProfileAlertResourceNotBelongToMeModel } from '../models/profile/report-profile-alert-resource-not-belong-to-me-model';
import { ReportProfileAlertResourcesBaseModel } from '../models/report-profile-alert-resources-base-model';
import { StatisticsGranularity } from '../models/statistics-granularity';
import { UserActivityModel } from '../models/user-activity-model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatasourceService } from '../../data/datasource.service';

@Injectable({
	providedIn: 'root'
})
export class ProviderInstancesDatasourceService {

private route: string = "provider-instances";

	constructor(private datasource: DatasourceService) {
		console.log("Init ProviderInstancesDatasourceService");
	}

	public loadAllProviders(includeIdecsiProvider: boolean = null, useCache: boolean = false, throwError: boolean = true): Observable<ProviderInstanceModel[]> {
		let partialUrl = `${this.route}`;

		return this.datasource.get<ProviderInstanceModel[]>(partialUrl, {includeIdecsiProvider: String(includeIdecsiProvider) }, useCache, throwError);
	}

	public loadProviderInstance(providerId: string, providerInstanceId: string, useCache: boolean = false, throwError: boolean = true): Observable<ProviderInstanceModel> {
		let partialUrl = `${this.route}/${providerId}/${providerInstanceId}`;

		return this.datasource.get<ProviderInstanceModel>(partialUrl, { }, useCache, throwError);
	}

	public loadDetails(providerId: string, providerInstanceId: string, useCache: boolean = false, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerId}/${providerInstanceId}/configuration`;

		return this.datasource.get<any>(partialUrl, { }, useCache, throwError);
	}

	public save(providerId: string, providerInstanceId: string, configuration: any, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerId}/${providerInstanceId}/configuration`;

		return this.datasource.put(partialUrl, configuration, { params: { } }, throwError);
	}

	public publish(providerId: string, providerInstanceId: string, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerId}/${providerInstanceId}/publish`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public loadUserUsage(providerId: string, providerInstanceId: string, userId: string, begin: Date, end: Date, granularity: StatisticsGranularity, useCache: boolean = false, throwError: boolean = true): Observable<ProviderAuditUsageStatisticsModel[]> {
		let partialUrl = `${this.route}/${providerId}/${providerInstanceId}/provider-audits/usage-stats`;

		return this.datasource.get<ProviderAuditUsageStatisticsModel[]>(partialUrl, {userId: String(userId),begin: String(begin),end: String(end),granularity: String(granularity) }, useCache, throwError);
	}

	public loadUserActivity(providerId: string, providerInstanceId: string, userId: string, begin: Date, end: Date, useCache: boolean = false, throwError: boolean = true): Observable<UserActivityModel[]> {
		let partialUrl = `${this.route}/${providerId}/${providerInstanceId}/provider-audits/user-activities`;

		return this.datasource.get<UserActivityModel[]>(partialUrl, {userId: String(userId),begin: String(begin),end: String(end) }, useCache, throwError);
	}

	public clearCache(throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/clear-cache`;

		return this.datasource.put(partialUrl, null, { params: { } }, throwError);
	}

	public loadMyProfileTemplate(providerId: string, providerInstanceId: string, userId: string, userView: boolean, useCache: boolean = false, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerId}/${providerInstanceId}/profile/${userId}/template`;

		return this.datasource.get<any>(partialUrl, {userView: String(userView) }, useCache, throwError);
	}

	public invalidStateReportDeleteResource(userId: string, model: ReportProfileAlertDeleteResourceModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/profile/${userId}/invalid-state-report/delete-provider-instance-resource`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public invalidStateReportCommentResource(userId: string, model: ReportProfileAlertResourcesBaseModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/profile/${userId}/invalid-state-report/comment-provider-instance-resource`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public invalidStateReportResourceNotBelongToMe(userId: string, model: ReportProfileAlertResourceNotBelongToMeModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/profile/${userId}/invalid-state-report/resource-not-belong-to-me`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'i2a-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  @Input() public danger: boolean;
  @Input() public warning: boolean;

  public type: string
  
  constructor() { }

  ngOnInit() {
    if (this.warning) {
      this.type = 'warning';
    } else if (this.danger) {
      this.type = 'warning';
    } else {
      this.type = 'info';
    }
  }

}

import { FileShareCollectedObjectCreateEntity } from '../../../models/microsoft/fileshare/datacollect/file-share-collected-object-create-entity';
import { FileShareCreateEntity } from '../../../models/microsoft/fileshare/file-share-create-entity';
import { FileShareDiscoveriesModel } from '../../../models/microsoft/fileshare/file-share-discoveries-model';
import { FileShareDiscoveriesRequestModel } from '../../../models/microsoft/fileshare/file-share-discoveries-request-model';
import { FileShareItemResyncModel } from '../../../models/microsoft/fileshare/file-share-item-resync-model';
import { FileShareMassAffectationModel } from '../../../models/microsoft/fileshare/file-share-mass-affectation-model';
import { FileShareOnePageModel } from '../../../orchestration/microsoft/fileshare/reports/models/file-share-one-page-model';
import { FileShareUpdateEntity } from '../../../models/microsoft/fileshare/file-share-update-entity';
import { FileShareWorkerRangesModel } from '../../../models/microsoft/fileshare/file-share-worker-ranges-model';
import { ProviderInstanceResourceModel } from '../../../models/provider-instance-resource-model';
import { ProviderPoliciesModel } from '../../../models/provider-policies-model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatasourceService } from '../../../../data/datasource.service';

@Injectable({
	providedIn: 'root'
})
export class FileShareDatasourceService {

private route: string = "providers/microsoft/fileshare";

	constructor(private datasource: DatasourceService) {
		console.log("Init FileShareDatasourceService");
	}

	public onePage(providerInstanceId: string, providerInstanceResourceId: string, beginDate: Date = null, endDate: Date = null, useCache: boolean = false, throwError: boolean = true): Observable<FileShareOnePageModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/${providerInstanceResourceId}/one-page`;

		return this.datasource.get<FileShareOnePageModel>(partialUrl, {beginDate: String(beginDate),endDate: String(endDate) }, useCache, throwError);
	}

	public enqueue(createEntities: FileShareCollectedObjectCreateEntity, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/remote-data-collects`;

		return this.datasource.post(partialUrl, createEntities, { params: { } }, throwError);
	}

	public getProviderPolicies(providerInstanceId: string, useCache: boolean = false, throwError: boolean = true): Observable<ProviderPoliciesModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/provider-policies`;

		return this.datasource.get<ProviderPoliciesModel>(partialUrl, { }, useCache, throwError);
	}

	public massAffectation(model: FileShareMassAffectationModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/provider-instance-resources/mass-affectation`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public createResource(entity: FileShareCreateEntity, throwError: boolean = true): Observable<ProviderInstanceResourceModel> {
		let partialUrl = `${this.route}/provider-instance-resources`;

		return this.datasource.post(partialUrl, entity, { params: { } }, throwError);
	}

	public updateResource(providerInstanceId: string, providerInstanceResourceId: string, entity: FileShareUpdateEntity, throwError: boolean = true): Observable<ProviderInstanceResourceModel> {
		let partialUrl = `${this.route}/provider-instance-resources/${providerInstanceId}/${providerInstanceResourceId}`;

		return this.datasource.put(partialUrl, entity, { params: { } }, throwError);
	}

	public resyncFileShareItem(providerInstanceId: string, model: FileShareItemResyncModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/resync-file-share-item`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public requestDiscoveries(providerInstanceId: string, model: FileShareDiscoveriesRequestModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/request-discoveries`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public getDiscoveries(providerInstanceId: string, useCache: boolean = false, throwError: boolean = true): Observable<FileShareDiscoveriesModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/discoveries`;

		return this.datasource.get<FileShareDiscoveriesModel>(partialUrl, { }, useCache, throwError);
	}

	public removeDiscoveries(providerInstanceId: string, model: FileShareDiscoveriesModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/remove-discoveries`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public getWorkerConfiguration(providerInstanceId: string, machineId: string, useCache: boolean = false, throwError: boolean = true): Observable<FileShareWorkerRangesModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/${machineId}/worker-configuration`;

		return this.datasource.get<FileShareWorkerRangesModel>(partialUrl, { }, useCache, throwError);
	}

	public updateWorkerConfiguration(providerInstanceId: string, machineId: string, model: FileShareWorkerRangesModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/${machineId}/worker-configuration`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

}

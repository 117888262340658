import { Component, OnInit, Input, Inject, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ZimbraMailingReportProfilePermissions, ZimbraProfileProviderDatasourceService, ZimbraMailingReportProfileAlertPermissionsModel, ZimbraMailingReportProfile, BaseProviderInstanceResourceModel, AlertInvalidStateFeaturedModel } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ZimbraMailingPermissionModalComponent } from '../zimbra-mailing-permission-modal/zimbra-mailing-permission-modal.component';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { WarningHelper } from 'projects/web-user-profile/src/app/helpers/warning-helper';
import { ProviderHelper } from 'projects/web-user-profile/src/app/helpers/provider-helper';
import { ActivatedRoute, Router } from '@angular/router';
import { InformationModalComponent } from 'projects/web-user-profile/src/app/components/shared/modal/information-modal/information-modal.component';
import { WarningLevel } from 'projects/web-user-profile/src/app/models/warnings/warning-level';
import { WarningInfoType } from 'projects/web-user-profile/src/app/models/warnings/warning-info-type';
import { ZimbraMailingReportProfilePermissionsWithWarnings } from 'projects/web-user-profile/src/app/models/providers/zimbra/mailing/zimbra-mailing-report-profile-permissions-with-warnings';

@Component({
  selector: 'i2a-zimbra-mailing-permission',
  templateUrl: './zimbra-mailing-permission.component.html',
  styleUrls: ['./zimbra-mailing-permission.component.scss']
})
export class ZimbraMailingPermissionComponent extends ProfileItemBaseClassComponent<ZimbraMailingReportProfile> implements OnInit, OnDestroy {
  @Input() public permissionsWithWarnings: ZimbraMailingReportProfilePermissionsWithWarnings;
  public permissions: ZimbraMailingReportProfilePermissions;

  public title: string;
  public subTitle: string;
  public warningLevel?: WarningLevel;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    modalService: NgbModal,
    private microsoftDatasource: ZimbraProfileProviderDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.permissionsWithWarnings) {
      this.permissions = this.permissionsWithWarnings.model;
      this.warnings = this.permissionsWithWarnings.warnings;
      this.warningLevel = this.permissionsWithWarnings.warnings ? this.permissionsWithWarnings.warnings[0].warningLevel : null;
      this.title = this.permissionsWithWarnings.title;
      this.id = this.permissionsWithWarnings.id;
      this.subscriptions.add(WarningHelper.openModalOnRoute(this.router, this.route, this.id, this.openDetails.bind(this), this.warnClick.bind(this)));
    }
  }

  setItems() {
    if (this.permissionsWithWarnings.model && this.alerts) {
      super.setItems();
      this.hasAlert = this.hasAlertInAlerts(false);
      this.hasAlertRecentlyClosed = this.hasAlertInAlerts(true);
    }
  }

  private hasAlertInAlerts(done: boolean): boolean {
    return AlertReasonHelper.alertsContainsDelegate(this.alerts, this.permissionsWithWarnings.model, done);
  }

  public static getId(componentId: string, providerInstanceResource: BaseProviderInstanceResourceModel, title: string): string {
    return `${componentId}-${providerInstanceResource.providerTypeId}-${providerInstanceResource.providerInstanceResourceId}-${title.replaceAll(' ', '-')}-ZimbraMailingPermission`;
  }

  private static getTitle(i18Next: ITranslationService, permissions: ZimbraMailingReportProfilePermissions): string {
    if (!permissions.isNameKey) {
      return permissions.name;
    }
    else {
      return i18Next.t(permissions.name);
    }
  }

  public static getPermissionsWithWarning(i18Next: ITranslationService, componentId: string, resourceProfile: ZimbraMailingReportProfile, permissions: ZimbraMailingReportProfilePermissions, alerts: AlertInvalidStateFeaturedModel[]): ZimbraMailingReportProfilePermissionsWithWarnings {
    let title = this.getTitle(i18Next, permissions);
    let permissionsWithWarnings: ZimbraMailingReportProfilePermissionsWithWarnings = {
      model: permissions,
      title: title,
      hasAlert: AlertReasonHelper.alertsContainsDelegate(alerts, permissions, false),
      hasAlertRecentlyClosed: AlertReasonHelper.alertsContainsDelegate(alerts, permissions, true),
      id: ZimbraMailingPermissionComponent.getId(componentId, resourceProfile.providerInstanceResource, title),
      warnings: null
    };
    if (permissions.isNameKey) {
      let warningInfoType = WarningHelper.GetWarningInfoTypeCaseAccount(permissions.logonUserId);
      //let warningLevel = WarningHelper.GetWarningLevel(warningInfoType);
      permissionsWithWarnings.warnings = [{
        id: permissionsWithWarnings.id,
        resourceId: 'application' + resourceProfile.providerInstanceResource.providerTypeId.toLocaleLowerCase() + resourceProfile.providerInstanceResource.providerInstanceResourceId.toLocaleLowerCase(),
        resourceProfile: resourceProfile,
        warningInfoType: warningInfoType,
        warningLevel: WarningHelper.GetWarningLevel(warningInfoType),
        label: `${i18Next.t(ProviderHelper.GetProviderTypeLabel(resourceProfile.providerInstanceResource.providerId, resourceProfile.providerInstanceResource.providerTypeId))}`,
        hasAlert: permissionsWithWarnings.hasAlert,
        hasAlertRecentlyClosed: permissionsWithWarnings.hasAlertRecentlyClosed,
        warningInfo: ZimbraMailingPermissionComponent.GetWarningInfoTypeLabelByType(warningInfoType),
        viewMoreDetails: true,
        viewDissmiss: true,
        viewResource: false
      }];
    }
    return permissionsWithWarnings;
  }

  public static GetWarningInfoTypeLabelByType(warningInfoType: WarningInfoType): string {
    switch (warningInfoType) {
      case WarningInfoType.Anonymous:
        return 'warning.info.type.provider.zimbra.mailing.anonymousPermission';
      case WarningInfoType.Company:
        return 'warning.info.type.provider.zimbra.mailing.defaultPermission';
      case WarningInfoType.Unknown:
      default:
        return 'warning.info.type.default.unknown';
    }
  }


  private GetRights(): string {
    let rights: string[] = [];

    if (this.permissions != null) {
      if (this.permissions.hasContactsRights) {
        rights.push(this.i18Next.t("profile.provider.zimbra.mailing.rights.contacts"));
      }

      if (this.permissions.hasSendAsRights) {
        rights.push(this.i18Next.t("profile.provider.zimbra.mailing.rights.sendAs"));
      }

      if (this.permissions.hasCalendarRights) {
        rights.push(this.i18Next.t("profile.provider.zimbra.mailing.rights.calendar"));
      }

      if (this.permissions.hasFoldersRights) {
        rights.push(this.i18Next.t("profile.provider.zimbra.mailing.rights.folders"));
      }

      if (this.permissions.hasInboxRights) {
        rights.push(this.i18Next.t("profile.provider.zimbra.mailing.rights.inbox"));
      }

      if (this.permissions.hasSendOnBehalfRights) {
        rights.push(this.i18Next.t("profile.provider.zimbra.mailing.rights.sendOnBehalf"));
      }
    }
    return rights.join(" | ");
  }

  @ViewChild('viewDetailTemplate') viewDetailTemplate: TemplateRef<any>;

  openDetails(event): void {
    const modalRef = this.modalService.open(InformationModalComponent, { windowClass: 'id-modal', centered: true, size: 'lg' });
    modalRef.componentInstance.title = this.i18Next.t("modal.detail.title");
    modalRef.componentInstance.bodyTemplate = this.viewDetailTemplate;
    modalRef.componentInstance.ok = this.i18Next.t("button.close");
    modalRef.result.then((model: any) => {
      if (model == true) {
        this.warnClick(null);
      }
    }, (reason) => {
      //Dismiss
    });
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(ZimbraMailingPermissionModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.permissions = this.permissions;

    modalRef.result.then((model: ZimbraMailingReportProfileAlertPermissionsModel) => {
      this.initAlertModel(model);
      model.permissions = this.permissions;
      model.providerInstanceResource = this.providerInstanceResource;
      this.subscriptions.add(this.microsoftDatasource.alertMailingPermissions(this.profile.providerInstanceId, this.profile.userId, model).subscribe(model => {
        this.setAlertProcessed(model);
      }));
    }).catch((error) => {
      // console.log(error);
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

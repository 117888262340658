import { Component, OnInit, Inject, Input } from '@angular/core';
import { ReportProfileBaseModel, UserModel, BaseProviderInstanceResourceModel, MicrosoftOfficeReportProfileModel, SharePointReportProfile, AlertInvalidStateFeaturedModel, SharePointReportProfilePermissions, SharePointReportProfileDelegate, OneDriveReportProfile, OneDriveReportProfileDelegate, Constants, ReportProfileResourceBase } from '@i2a/web-api-client';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ProfileHelper } from 'projects/web-user-profile/src/app/helpers/profile-helper';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';
import { SharePointPermissionConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/share-point-permission-configuration';
import { WarningInfo } from 'projects/web-user-profile/src/app/models/warnings/warning-info';
import { SharePointReportProfilePermissionsWithWarnings } from 'projects/web-user-profile/src/app/models/providers/microsoft/share-point/share-point-report-profile-permissions-with-warnings';
import { SharePointPermissionComponent } from '../share-point-permission/share-point-permission.component';

@RegisterMyProfileComponent(TemplateConstants.MODULE_SHAREPOINT_PERMISSION)
@Component({
  selector: 'i2a-share-point-permission-module',
  templateUrl: './share-point-permission-module.component.html',
  styleUrls: ['./share-point-permission-module.component.scss']
})
export class SharePointPermissionModuleComponent extends ProfileBaseClassComponent<SharePointPermissionConfiguration, SharePointReportProfile | OneDriveReportProfile> implements OnInit, IModule<SharePointPermissionConfiguration> {
  public titleKey: string;
  public descriptionKey: string;

  public externalDelegates: SharePointReportProfilePermissionsWithWarnings[];
  public delegates: SharePointReportProfilePermissionsWithWarnings[];

  public showMore: boolean;
  public showMoreAfter: number;
  public maxShowMore: number;
  public isVisibleButtonsShowMore: boolean;
  private isActiveShowMoreOption: boolean;

  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, null);
  }

  ngOnInit() {
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: SharePointPermissionConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel, resourceTemplate?: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    if (resource.providerTypeId == Constants.PROVIDER_TYPE_INTRANET) {
      this.resourceProfile = (<MicrosoftOfficeReportProfileModel>profile).sharePointProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
    } else {
      this.resourceProfile = (<MicrosoftOfficeReportProfileModel>profile).oneDriveProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
    }
    this.titleKey = this.resourceProfile.providerInstanceResource.providerTypeId == Constants.PROVIDER_TYPE_INTRANET ? 'profile.provider.microsoft.sharePoint.title.permissions' : 'profile.provider.microsoft.oneDrive.title.permissions';
    this.descriptionKey = this.resourceProfile.providerInstanceResource.providerTypeId == Constants.PROVIDER_TYPE_INTRANET ? 'profile.provider.microsoft.sharePoint.description.permissions' : 'profile.provider.microsoft.oneDrive.description.permissions';

    this.resourceProfile.permissions.sort((a, b) => { return ProfileHelper.compare(a, b, this.i18Next); });

    let warnings: Array<WarningInfo> = [];

    this.setDelegates(configuration, warnings);

    if (warnings.length > 0) {
      this.hasWarnings(warnings);
    }
  }

  private setDelegates(configuration: SharePointPermissionConfiguration, warnings: Array<WarningInfo>) {
    let delegatesWithoutAccess = SharePointPermissionModuleComponent.SetDelegates(this.resourceProfile.permissions, this.resourceProfile.delegates, this.configuration);
    let delegatesWithoutAccessWithPerm = delegatesWithoutAccess.map(permissions => {
      let perm = SharePointPermissionComponent.getPermissionsWithWarning(this.i18Next, this.componentId, this.resourceProfile, permissions, this.alerts, this.configuration.warnSensitivityLabel);
      if ((perm.warnings != null && perm.warnings != undefined)) {
        warnings.push(...perm.warnings);
      }
      return perm;
    });

    delegatesWithoutAccessWithPerm.sort((a, b) => { return ProfileHelper.compare(a.model, b.model, this.i18Next); });
    let externalDelegates = delegatesWithoutAccessWithPerm.filter(d => d.model.isExternalUser);

    let delegates = [];
    delegatesWithoutAccessWithPerm.filter(d => ((!this.configuration.groupExternalDelegates || !d.model.isExternalUser || externalDelegates.length < configuration.groupExternalDelegatesAt) && !d.model.isSPGroup && !d.model.isO365Group)).forEach(d => {
      if (d.model.isNameKey) {
        delegates.unshift(d);
      } else {
        delegates.push(d);
      }
    });

    //External delegates
    if (this.configuration.groupExternalDelegates) {
      if (externalDelegates.length < configuration.groupExternalDelegatesAt) {
        this.externalDelegates = [];
      } else {
        this.externalDelegates = externalDelegates;
      }
    }

    this.delegates = delegates;

    this.showMoreAfter = configuration.showMoreAfter ?? 0;

    let minShowMore = this.delegates.filter(d => d.model.isNameKey || d.warnings?.length > 0).length + this.externalDelegates?.length > 0 ? 1 : 0;
    if (this.showMoreAfter != 0 && this.showMoreAfter < minShowMore) {
      this.showMoreAfter = minShowMore;
    }

    this.isActiveShowMoreOption = this.showMoreAfter > 0;
    this.isVisibleButtonsShowMore = this.isActiveShowMoreOption && (this.delegates?.length + (this.externalDelegates?.length > 0 ? 1 : 0)) > this.showMoreAfter;

    this.setShowMoreValue();
  }

  public static SetDelegates(permissions: SharePointReportProfilePermissions[], sharePointProfileDelegates: (SharePointReportProfileDelegate | OneDriveReportProfileDelegate)[], configuration: SharePointPermissionConfiguration): SharePointReportProfilePermissions[] {
    var delegates = [];

    if (configuration.displayAllDelegates) {
      delegates = permissions;
    } else {
      permissions.forEach(item => {
        if (item.userId != null) {
          if (sharePointProfileDelegates.findIndex(d => d.userId != null && d.userId == item.userId) < 0) {
            delegates.push(item);
          }
        }
        else if (item.providerInstanceUser != null) {
          if (sharePointProfileDelegates.findIndex(d => d.providerInstanceUser != null && d.providerInstanceUser.providerInstanceResourceId == item.providerInstanceUser.providerInstanceResourceId) < 0) {
            delegates.push(item);
          }
        }
        else {
          if (sharePointProfileDelegates.findIndex(p => p.logonUserId == item.logonUserId) < 0) {
            delegates.push(item);
          }
        }
      });
    }
    return delegates;
  }

  public onShowMore() {
    this.showMore = !this.showMore;
    this.setShowMoreValue();
  }

  private setShowMoreValue() {
    this.maxShowMore = (!this.showMore && this.isActiveShowMoreOption) ? (this.showMoreAfter - (this.externalDelegates?.length > 0 ? 1 : 0)) : this.delegates?.length;
  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }
}

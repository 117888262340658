export enum Role
    {

        None = 0,

        AlertUser_Read = 1,
        //Alert_ = 2,
        //Alert_ = 4,
        AlertUser = 7,//1 + 2 + 4

        AlertGlobal_Read = 8,
        //GlobalAlert_ = 16,
        //GlobalAlert_ = 32,
        AlertGlobal = 56,

        Audit_read = 64,
        //Audit_ = 128,
        //Audit_ = 256,
        Audit = 448,

        Protection_read = 512,
        //Protection_ = 1024,
        //Protection_ = 2048,
        Protection = 3584,

        Security_read = 4096,
        //Security_ = 8192,
        //Security_ = 16384,
        Security = 28672,

        ExportCollectedDataUser = 32768,
        ExportCollectedDataGlobal = 65536,
        
        //other_ = 131072,
        
        //Monitoring_ = 262144,
        //Monitoring_ = 524288,
        //Monitoring_ = 1048576,
        Monitoring = 1835008,

        AdminUser_read = 2097152,
        //Admin_ = 4194304,
        //Admin_ = 8388608,
        //Admin_ = 16777216,
        AdminUser = 31457280,

        AdminGlobal_Read = 33554432,
        //AdminGlobal_ = 67108864,
        //Admin_Global = 134217728
        AdminGlobal = 234881024,

        //268435456
        //536870912

        //2147483648 > 2147483647 = int.MaxValue
    }

<i2a-profile-card [id]="id" [title]="'profile.provider.microsoft.exchange.rules.configuration' | i18next "
  (onWarnClick)="warnClick($event)" [hasAlert]="hasAlert" [hasAlertRecentlyClosed]="hasAlertRecentlyClosed"
  [warningLevel]="warningLevel">

  <ng-container picto>
    <i2a-badge-icon>
      <ng-container icon>
        <fa-icon [icon]="['fal', 'cogs']" size="lg"></fa-icon>
      </ng-container>
    </i2a-badge-icon>
  </ng-container>

  <ng-container content>
    <div class="container p-0 exchange-rule-config">
      <div class="row no-gutters" *ngIf="rules">
        <div class="col m-2" *ngIf="rules.hasJournalRules || rules.hasTransportRules">
          <span>{{'profile.provider.microsoft.exchange.rules.globalRulesTitle' | i18next }}<br></span>
        </div>
        <div class="col m-2" *ngIf="forwardMailBoxes && forwardMailBoxes.length > 0">
          <span>{{'profile.provider.microsoft.exchange.rules.forwardMailBoxes' | i18next }}<br></span>
          <table class="table table-sm table-bordered table-forward-mailboxes">
            <tbody>
              <tr *ngFor="let forwardMailBox of forwardMailBoxes">
                <td scope="row" [ngClass]="{'has-alert': forwardMailBox.hasAlert, 'has-alert-recently-closed': forwardMailBox.hasAlertRecentlyClosed}">
                  {{forwardMailBox.forwardMailBox}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </ng-container>

</i2a-profile-card>
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from './services/session-service';
import { CultureManagerService } from '@i2a/web-core';
import { PlatformLocation } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseAppComponent } from './components/base-app-component';
import { AzureADNodataContainerModuleComponent } from './components/profile/providers/microsoft/azure-ad/azure-ad-nodata-container-module/azure-ad-nodata-container-module.component';
import { AzureAdPermissionGrantsModuleComponent } from './components/profile/providers/microsoft/azure-ad/azure-ad-permission-grants-module/azure-ad-permission-grants-module.component';
import { AzureADRulesComputedDelegateModuleComponent } from './components/profile/providers/microsoft/azure-ad/azure-ad-rules-computed-delegate-module/azure-ad-rules-computed-delegate-module.component';
import { ExchangeDelegateModuleComponent } from './components/profile/providers/microsoft/exchange/exchange-delegate-module/exchange-delegate-module.component';
import { ExchangeNodataModuleComponent } from './components/profile/providers/microsoft/exchange/exchange-nodata-module/exchange-nodata-module.component';
import { ExchangePermissionModuleComponent } from './components/profile/providers/microsoft/exchange/exchange-permission-module/exchange-permission-module.component';
import { ExchangeRuleModuleComponent } from './components/profile/providers/microsoft/exchange/exchange-rule-module/exchange-rule-module.component';
import { ExchangeRulesComputedDelegateModuleComponent } from './components/profile/providers/microsoft/exchange/exchange-rules-computed-delegate-module/exchange-rules-computed-delegate-module.component';
import { OneDriveDelegateModuleComponent } from './components/profile/providers/microsoft/one-drive/one-drive-delegate-module/one-drive-delegate-module.component';
import { OneDriveInstantMessagingModuleComponent } from './components/profile/providers/microsoft/one-drive/one-drive-instant-messaging-module/one-drive-instant-messaging-module.component';
import { OneDriveInstantMessagingComponent } from './components/profile/providers/microsoft/one-drive/one-drive-instant-messaging/one-drive-instant-messaging.component';
import { OneDriveNodataModuleComponent } from './components/profile/providers/microsoft/one-drive/one-drive-nodata-module/one-drive-nodata-module.component';
import { OneDriveRulesComputedDelegateModuleComponent } from './components/profile/providers/microsoft/one-drive/one-drive-rules-computed-delegate-module/one-drive-rules-computed-delegate-module.component';
import { SharePointActionsModuleComponent } from './components/profile/providers/microsoft/share-point/share-point-actions-module/share-point-actions-module.component';
import { SharePointDelegateModuleComponent } from './components/profile/providers/microsoft/share-point/share-point-delegate-module/share-point-delegate-module.component';
import { SharePointNodataModuleComponent } from './components/profile/providers/microsoft/share-point/share-point-nodata-module/share-point-nodata-module.component';
import { SharePointO365GroupModuleComponent } from './components/profile/providers/microsoft/share-point/share-point-o365-group-module/share-point-o365-group-module.component';
import { SharePointPermissionModuleComponent } from './components/profile/providers/microsoft/share-point/share-point-permission-module/share-point-permission-module.component';
import { SharePointRulesComputedDelegateModuleComponent } from './components/profile/providers/microsoft/share-point/share-point-rules-computed-delegate-module/share-point-rules-computed-delegate-module.component';
import { SharePointSharingsComponent } from './components/profile/providers/microsoft/share-point/share-point-sharings/share-point-sharings.component';
import { ZimbraMailingDelegateModuleComponent } from './components/profile/providers/zimbra/mailing/zimbra-mailing-delegate-module/zimbra-mailing-delegate-module.component';
import { ZimbraMailingFilterRuleModuleComponent } from './components/profile/providers/zimbra/mailing/zimbra-mailing-filter-rule-module/zimbra-mailing-filter-rule-module.component';
import { ZimbraMailingNodataModuleComponent } from './components/profile/providers/zimbra/mailing/zimbra-mailing-nodata-module/zimbra-mailing-nodata-module.component';
import { ZimbraMailingPermissionModuleComponent } from './components/profile/providers/zimbra/mailing/zimbra-mailing-permission-module/zimbra-mailing-permission-module.component';
import { ZimbraMailingRulesComputedDelegateModuleComponent } from './components/profile/providers/zimbra/mailing/zimbra-mailing-rules-computed-delegate-module/zimbra-mailing-rules-computed-delegate-module.component';
import { RulesComputedDelegateModuleComponent } from './components/profile/rules-computed-delegate/rules-computed-delegate-module/rules-computed-delegate-module.component';
import { ApplicationContainerComponent } from './components/profile/shared/application-container/application-container.component';
import { SharePointApplicationContainerComponent } from './components/profile/providers/microsoft/share-point/share-point-application-container/share-point-application-container.component';
import { ProviderContainerComponent } from './components/profile/shared/provider-container/provider-container.component';
import { ResourceActionsModuleComponent } from './components/profile/shared/resource-actions-module/resource-actions-module.component';
import { ResourceContainerComponent } from './components/profile/shared/resource-container/resource-container.component';
import { ResourceLastAccessComponent } from './components/profile/shared/resource-last-access/resource-last-access.component';
import { TemplateContainerComponent } from './components/profile/shared/template-container/template-container.component';
import { AccessModeComponent } from './components/profile/user/access-mode/access-mode.component';
import { DeviceComponent } from './components/profile/user/device/device.component';
import { OriginComponent } from './components/profile/user/origin/origin.component';
import { UserContainerComponent } from './components/profile/user/user-container/user-container.component';
import { FileSharePermissionModuleComponent } from './components/profile/providers/microsoft/file-share/file-share-permission-module/file-share-permission-module.component';
import { FileShareDelegateModuleComponent } from './components/profile/providers/microsoft/file-share/file-share-delegate-module/file-share-delegate-module.component';
import { FileShareNodataModuleComponent } from './components/profile/providers/microsoft/file-share/file-share-nodata-module/file-share-nodata-module.component';
import { WarningContainerComponent } from './components/profile/shared/warning-container/warning-container.component';
import { SharePointResourceHasSensitivityLabelComponent } from './components/profile/providers/microsoft/share-point/share-point-resource-has-sensitivity-label/share-point-resource-has-sensitivity-label.component';

@Component({
  selector: 'i2a-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseAppComponent implements OnInit {
  title = 'web-profile-validation';
  components: any[];

  constructor(
    private platformLocation: PlatformLocation,
    private modalService: NgbModal,
    router: Router,
    cultureManager: CultureManagerService,
    session: SessionService) {
    super(router);
    this.components = [
      OneDriveInstantMessagingComponent,
      AccessModeComponent,
      OriginComponent,
      DeviceComponent,
      ExchangeDelegateModuleComponent,
      ExchangePermissionModuleComponent,
      ExchangeRuleModuleComponent,
      ExchangeNodataModuleComponent,
      ExchangeRulesComputedDelegateModuleComponent,
      RulesComputedDelegateModuleComponent,
      AzureAdPermissionGrantsModuleComponent,
      AzureADNodataContainerModuleComponent,
      AzureADRulesComputedDelegateModuleComponent,
      SharePointDelegateModuleComponent,
      SharePointNodataModuleComponent,
      SharePointO365GroupModuleComponent,
      SharePointRulesComputedDelegateModuleComponent,
      OneDriveDelegateModuleComponent,
      OneDriveNodataModuleComponent,
      OneDriveInstantMessagingModuleComponent,
      OneDriveRulesComputedDelegateModuleComponent,
      ZimbraMailingDelegateModuleComponent,
      ZimbraMailingNodataModuleComponent,
      ZimbraMailingPermissionModuleComponent,
      ZimbraMailingFilterRuleModuleComponent,
      ZimbraMailingRulesComputedDelegateModuleComponent,
      TemplateContainerComponent,
      ResourceContainerComponent,
      UserContainerComponent,
      ApplicationContainerComponent,
      SharePointApplicationContainerComponent,
      ProviderContainerComponent,
      ResourceLastAccessComponent,
      SharePointResourceHasSensitivityLabelComponent,
      SharePointSharingsComponent,
      SharePointPermissionModuleComponent,
      ResourceActionsModuleComponent,
      SharePointActionsModuleComponent,
      FileSharePermissionModuleComponent,
      FileShareDelegateModuleComponent,
      FileShareNodataModuleComponent,
      WarningContainerComponent
    ];
    platformLocation.onPopState(() => this.modalService.dismissAll());
  }

  ngOnInit() {
  }
}
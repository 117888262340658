import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserModel, ReportProfileBaseModel, AlertInvalidStateFeaturedModel, BaseProviderInstanceResourceModel, ConnectedAccountModel } from '@i2a/web-api-client';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ToastrService } from 'ngx-toastr';
import { WarningInfoType } from 'projects/web-user-profile/src/app/models/warnings/warning-info-type';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ContainerDirective } from 'projects/web-user-profile/src/app/directives/container-directive';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { Template } from 'projects/web-user-profile/src/app/models/templates/template';
import { TemplateApplication } from 'projects/web-user-profile/src/app/models/templates/template-application';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { TemplateProvider } from 'projects/web-user-profile/src/app/models/templates/template-provider';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';
import { ProfileBaseClassComponent } from '../profile-base-class-component';
import { WarningInfo } from 'projects/web-user-profile/src/app/models/warnings/warning-info';
import { WarningContainerConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/warning-container-configuration';

@RegisterMyProfileComponent(TemplateConstants.MODULE_TEMPLATE_WARNING_CONTAINER)
@Component({
  selector: 'i2a-warning-container',
  templateUrl: './warning-container.component.html',
  styleUrls: ['./warning-container.component.scss']
})
export class WarningContainerComponent extends ProfileBaseClassComponent<WarningContainerConfiguration, any> implements OnInit, OnDestroy, IModule<any> {

  @Input() public id: string;
  @Input() public guidedTourKey: string;

  public warningContainerId: string = "warnings";
  public template: Template;
  public connectedAccount: ConnectedAccountModel;
  public hideResource: boolean;
  public isCollapsed: boolean;
  public profile: ReportProfileBaseModel;

  @ViewChild(ContainerDirective, { static: true }) public container: ContainerDirective;

  public providerTypes: string[] = [];
  public sortedWarnings: WarningInfo[];
  public sortedWarningsWithRecentlyClosed: WarningInfo[];
  public sortedWarningsWithoutAlerts: any[];

  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService,
    public session: SessionService) {
    super(toastr, i18Next, null);
    this.isCollapsed = true;
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: any, alerts: AlertInvalidStateFeaturedModel[], resource?: BaseProviderInstanceResourceModel, template?: BaseTemplate): void {
    super.baseInitialize(user, profile, configuration, alerts);
    this.template = <Template>template;
    this.addSubscriptionConnectedAccount();
    this.addSubscriptionCollapsedAll();
    this.initProviderTypesFromTemplateOrder();
  }

  initProviderTypesFromTemplateOrder() {

    this.template.providerTemplates.forEach((providerTemplate: TemplateProvider) => {
      providerTemplate.providerApplicationTemplates.forEach((providerApplicationTemplate: TemplateApplication) => {

        if (providerApplicationTemplate.containerModule.isEnabled &&
          providerApplicationTemplate.resourceTemplate.modules.filter(module => module.isEnabled).length > 0 &&
          this.profile.providerInstanceResources.findIndex((pir) => {
            return pir.providerTypeId.toLocaleLowerCase() == providerApplicationTemplate.providerTypeId.toLocaleLowerCase() &&
              pir.providerId.toLocaleLowerCase() == providerTemplate.providerId.toLocaleLowerCase();
          }) >= 0
        ) {

          let resources = this.profile.providerInstanceResources.filter(pir =>
            pir.providerTypeId.toLocaleLowerCase() == providerApplicationTemplate.providerTypeId.toLocaleLowerCase()
            && pir.providerId.toLocaleLowerCase() == providerTemplate.providerId.toLocaleLowerCase()
          );

          if (resources.length >= 1) {
            this.providerTypes.push(providerApplicationTemplate.providerTypeId.toLocaleLowerCase());
          }
        }
      })
    });
  }

  private addSubscriptionCollapsedAll(): void {
    this.subscriptions.add(
      this.session.isCollapsedAll$.subscribe(isCollapsedAll => {
        if (isCollapsedAll !== null && isCollapsedAll !== undefined) {
          this.isCollapsed = isCollapsedAll;
        }
      })
    );
  }

  private addSubscriptionConnectedAccount(): void {
    this.subscriptions.add(
      this.session.Account$.subscribe(account => {
        if (account != null) {
          this.connectedAccount = account;
        }
      })
    );
  }

  setItems() {
    super.setItems();
    this.setWarnings();
  }


  public updateWarnings(warnings: Array<WarningInfo>): void {
    super.updateWarnings(warnings);
    this.setWarnings();
  }


  private setWarnings() {
    setTimeout(() => {
      let sortedWarnings = [];
      let sortedWarningsWithoutAlerts = [];
      let sortedWarningsWithRecentlyClosed= [];
      this.providerTypes.forEach(providerTypeId => {
        this.getWarningsFiltered(providerTypeId, false).forEach(w => {
          if (w.hasAlert) {
            sortedWarnings.push(w);
          } else if (w.hasAlertRecentlyClosed) {
            sortedWarningsWithRecentlyClosed.push(w);
          } else {
            sortedWarningsWithoutAlerts.push(w);
          }
        });
      });
      this.sortedWarnings = sortedWarnings;
      this.sortedWarningsWithRecentlyClosed = sortedWarningsWithRecentlyClosed;
      this.sortedWarningsWithoutAlerts = sortedWarningsWithoutAlerts;
    });
  }

  public getWarningsFiltered(providerTypeId: string, hasAlert: boolean): Array<WarningInfo> {
    var filteredWarnings = this.warnings.filter(w => w.resourceProfile.providerInstanceResource.providerTypeId.toLocaleLowerCase() == providerTypeId);
    let sortedWarnings = filteredWarnings.sort(this.compare);

    return sortedWarnings;
  }

  public compare(a: WarningInfo, b: WarningInfo): number {
    if (a.warningLevel < b.warningLevel) {
        return 1;
    } else if (a.warningLevel > b.warningLevel) {
        return -1;
    } else {
      let result = a.warningInfo.localeCompare(b.warningInfo, undefined, { sensitivity: 'base' })
      if (result == 0) {
        return a.label.localeCompare(b.label, undefined, { sensitivity: 'base' })
      } else {
        return result;
      }
    }
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

import { SharePointReportProfileShareDetail } from '../../../microsoft/sharepoint/profile/share-point-report-profile-share-detail';

export class SharePointReportProfileShare {
  absoluteItemPath: string;
  listItemId: string;
  isDirectory: boolean;
  isEveryone: boolean;
  theWholeCompany: boolean;
  detail: SharePointReportProfileShareDetail[];
}

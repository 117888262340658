import { BaseProviderInstanceResourceModel, SharePointReportProfilePermissionDetail } from '@i2a/web-api-client';
import { BaseProviderInstanceUserModel } from '@i2a/web-api-client';
import { BaseModel } from '@i2a/web-core';

export class DelegateFolders extends BaseModel<DelegateFolders> {
    isAnonymous: boolean;
    isTheWholeCompany: boolean;
    isApplication: boolean;
    isExternalUser: boolean;
    logonUserId: string;
    providerInstanceUser: BaseProviderInstanceUserModel;
    userId: string;
    providerInstanceResource: BaseProviderInstanceResourceModel;
    name: string;
    folders: string[];
    driveItems: { [key: string]: SharePointReportProfilePermissionDetail };
}
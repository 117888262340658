import { Component, OnInit, Input, Inject } from '@angular/core';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { BaseProviderInstanceResourceModel, FileShareReportProfile, UserModel, ReportProfileBaseModel, AlertInvalidStateFeaturedModel, MicrosoftOfficeReportProfileModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';
import { FileShareNodataConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/file-share-nodata-configuration';

@RegisterMyProfileComponent(TemplateConstants.MODULE_FILESHARE_NODATA)
@Component({
  selector: 'i2a-file-share-nodata-module',
  templateUrl: './file-share-nodata-module.component.html',
  styleUrls: ['./file-share-nodata-module.component.css']
})
export class FileShareNodataModuleComponent extends ProfileBaseClassComponent<FileShareNodataConfiguration,FileShareReportProfile> implements OnInit, IModule<FileShareNodataConfiguration> {
  private resource: BaseProviderInstanceResourceModel;
  
  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, null);
  }

  ngOnInit() {
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: FileShareNodataConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel, resourceTemplate?: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.resource = resource;
    this.resourceProfile = (<MicrosoftOfficeReportProfileModel>profile).fileShareProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }

}

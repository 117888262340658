<i2a-profile-card [id]="id" *ngIf="delegate" [title]="delegate.accesses.name"
  [subTitle]="delegate.accesses.lastAccess | amDateFormat: 'LLL'" (onWarnClick)="warnClick($event)"
  [hasAlert]="hasAlert" [hasAlertRecentlyClosed]="hasAlertRecentlyClosed" [warningLevel]="warningLevel">

  <ng-container picto>
    <i2a-resource-photo [providerInstanceResource]="delegate.accesses.providerInstanceResource" [size]="40">
    </i2a-resource-photo>
  </ng-container>

  <ng-container content>
    <div class="container p-0">
      <div class="row no-gutters">
        <div class="col p-1">
          <div class="col-12 pb-1 id-detail">{{ "profile.provider.zimbra.mailing.grantedRights" | i18next }}
          </div>
          <div class="col-12 ">
            <i2a-mailing-permission-rights [permissions]="delegate.permissions"></i2a-mailing-permission-rights>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

</i2a-profile-card>
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'i2a-profile-information',
  templateUrl: './profile-information.component.html',
  styleUrls: ['./profile-information.component.scss']
})
export class ProfileInformationComponent implements OnInit {
  @Input() public lastValidationDate: Date|null;
  
  constructor() { }

  ngOnInit() {
  }

}

export class MemberFilterService {

  searchText:string = "";

  constructor() { }

  setSearchTerm(term:string): void {
    this.searchText = term;
  }
  
  getSearchTerm(): string {
    return this.searchText;
  }

  clearFilter(): void {

    this.searchText = "";
  }
}
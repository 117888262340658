import { Component, OnInit, OnDestroy, Input, Inject, ViewChild, TemplateRef } from '@angular/core';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { ZimbraMailingReportProfileFilterRule, ZimbraProfileProviderDatasourceService, ZimbraMailingReportProfileAlertFilterRuleModel, AlertConstants, ZimbraMailingReportProfile } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StringHelper } from 'projects/web-user-profile/src/app/helpers/string-helper';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';
import { ZimbraFilterRuleModalComponent } from '../zimbra-filter-rule-modal/zimbra-filter-rule-modal.component';
import { ToastrService } from 'ngx-toastr';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { InformationModalComponent } from 'projects/web-user-profile/src/app/components/shared/modal/information-modal/information-modal.component';

@Component({
  selector: 'i2a-zimbra-filter-rule',
  templateUrl: './zimbra-filter-rule.component.html',
  styleUrls: ['./zimbra-filter-rule.component.scss']
})
export class ZimbraFilterRuleComponent extends ProfileItemBaseClassComponent<ZimbraMailingReportProfile> implements OnInit, OnDestroy {
  @Input() public filterRule: ZimbraMailingReportProfileFilterRule;

  public title: string;
  public description: string[];

  constructor(
    modalService: NgbModal,
    private zimbraDatasource: ZimbraProfileProviderDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
  }


  setItems() {
    if (this.filterRule && this.alerts) {
      super.setItems();
      this.title = this.filterRule.name;
      this.description = [];
      //TODO Update this code when Description is developed => do not uses the reasons and put the description instead
      //this.filterRule.description.split("\n").forEach(line => this.description.push(line));
      this.filterRule.reasonObjects.forEach(reason => {
        if (!StringHelper.isNullOrWhitespace(reason.reasonResourceKey)) {
          this.description.push(this.i18Next.t(reason.reasonResourceKey, reason.reasonResourceArguments));
        }
      });

      this.hasAlert = this.hasAlertInAlerts(false);
      this.hasAlertRecentlyClosed = this.hasAlertInAlerts(true);

      this.id = `${this.componentId}-${this.providerInstanceResource.providerTypeId}-${this.providerInstanceResource.providerInstanceResourceId}-${this.title.replaceAll(' ', '-')}-ZimbraFilterRule`;
    }
  }

  private hasAlertInAlerts(done: boolean): boolean {
    return AlertReasonHelper.alertsContainsKeyValuePair(this.alerts, AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_FILTER_RULE_NAME, this.filterRule.name, done);
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(ZimbraFilterRuleModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.filterRule = this.filterRule;
    modalRef.result.then((model: ZimbraMailingReportProfileAlertFilterRuleModel) => {
      this.initAlertModel(model);
      model.providerInstanceResource = this.providerInstanceResource;
      model.filterRule = this.filterRule;
      this.subscriptions.add(this.zimbraDatasource.alertZimbraMailingFilterRule(this.profile.providerInstanceId, this.profile.userId, model).subscribe(model => {
        this.setAlertProcessed(model);
      }));
    }).catch((error) => {
      // console.log(error);
    });
  }

  @ViewChild('viewDetailTemplate') viewDetailTemplate: TemplateRef<any>;

  openDetails(event): void {
    const modalRef = this.modalService.open(InformationModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.title = this.i18Next.t("modal.detail.title");
    modalRef.componentInstance.bodyTemplate = this.viewDetailTemplate;
    modalRef.componentInstance.ok = this.i18Next.t("button.close");
    modalRef.result.then((model: any) => {
      if (model == true) {
        this.warnClick(null);
      }
    }, (reason) => {
      //Dismiss
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}

import { Component, OnInit, OnDestroy, Input, Inject, ViewChild, TemplateRef } from '@angular/core';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { MicrosoftOfficeProfileProviderDatasourceService, AlertConstants, SharePointGroupModel, SharePointGroupMemberModel, SharePointReportProfileAlertGroupModel, SharePointReportProfilePermissionDetail, SharePointReportProfilePermissions, SharePointReportProfileDriveItemRole, ProviderInstanceResourceSummaryModel, SharePointReportProfile } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SharePointGroupDelegateWithAlert } from 'projects/web-user-profile/src/app/models/providers/microsoft/share-point/share-point-group-delegate-with-alert';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { InformationModalComponent } from 'projects/web-user-profile/src/app/components/shared/modal/information-modal/information-modal.component';
import { SharePointGroupModalComponent } from '../share-point-group-modal/share-point-group-modal.component';
import { EnumHelper } from 'projects/web-user-profile/src/app/helpers/enum-helper';
import { WarningHelper } from 'projects/web-user-profile/src/app/helpers/warning-helper';
import { ProviderHelper } from 'projects/web-user-profile/src/app/helpers/provider-helper';
import { ActivatedRoute, Router } from '@angular/router';
import { SharePointPermissionComponent } from '../share-point-permission/share-point-permission.component';

@Component({
  selector: 'i2a-share-point-group',
  templateUrl: './share-point-group.component.html',
  styleUrls: ['./share-point-group.component.scss']
})
export class SharePointGroupComponent extends ProfileItemBaseClassComponent<SharePointReportProfile> implements OnInit, OnDestroy {
  @Input() public group: SharePointGroupModel;
  @Input() public permissions: SharePointReportProfilePermissions[];
  @Input() public displaySharepPointGroupOwner: boolean;
  @Input() public disableCheckAllWithOwnersFiltered: boolean;
  @Input() public doNotDeleteCurrentUserFromSiteOwnerGroup: boolean;

  public name: string;
  public members: SharePointGroupDelegateWithAlert[];
  public owner: SharePointGroupDelegateWithAlert;
  public memberDriveItems: { [key: string]: SharePointReportProfilePermissionDetail };
  public hasMemberDriveItems: boolean = false;
  public totalUsers: number = 0;
  public totalGroups: number = 0;
  public isEveryone: boolean = false;
  public isEveryoneExceptExternalUsers: boolean = false;
  public rootPathPermissionKey: string = 'profile.provider.microsoft.sharePoint.driveItem.rootPathPermission.undefined';

  public membersMinusProfileUser: SharePointGroupMemberModel[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    modalService: NgbModal,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.setHasWarning();
  }

  setItems() {
    if (this.group && this.alerts) {
      super.setItems();
      this.name = this.group.title;
      this.hasAlert = this.hasAlertInAlerts(false);
      this.hasAlertRecentlyClosed = this.hasAlertInAlerts(true);
      this.members = [];

      if (this.doNotDeleteCurrentUserFromSiteOwnerGroup) {
        this.membersMinusProfileUser = this.group.members.filter(o => !this.user.providerEmails.includes(o.email) && !this.user.providerEmails.includes(o.userPrincipalName));
      }

      this.setDelegates(this.members, this.group.members, AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_MEMBER);
      this.totalGroups = this.members.filter(m => m.isGroup).length;
      this.totalUsers = this.members.length - this.totalGroups;
      this.isEveryone = this.members.some((m) => m.isEveryone);
      this.isEveryoneExceptExternalUsers = this.members.some((m) => m.isEveryoneExceptExternalUsers);
      let groupPermissions = this.permissions.find(permission => permission.isSPGroup && this.group.title == permission.name);
      if (groupPermissions != null) {
        if (Object.keys(groupPermissions.driveItems).indexOf('/') > -1) {
          let rootPathPermission = groupPermissions.driveItems["/"];
          if (EnumHelper.HasFlag(rootPathPermission.rights, SharePointReportProfileDriveItemRole.Read)) {
            this.rootPathPermissionKey = 'profile.provider.microsoft.sharePoint.driveItem.rootPathPermission.read';
          } else if (EnumHelper.HasFlag(rootPathPermission.rights, SharePointReportProfileDriveItemRole.Write)) {
            this.rootPathPermissionKey = 'profile.provider.microsoft.sharePoint.driveItem.rootPathPermission.update';
          } else if (EnumHelper.HasFlag(rootPathPermission.rights, SharePointReportProfileDriveItemRole.Owner)) {
            this.rootPathPermissionKey = 'profile.provider.microsoft.sharePoint.driveItem.rootPathPermission.owner';
          } else {
            this.rootPathPermissionKey = 'profile.provider.microsoft.sharePoint.driveItem.rootPathPermission.unknown';
          }
          delete groupPermissions.driveItems["/"];
        }
        this.memberDriveItems = groupPermissions.driveItems;
        this.hasMemberDriveItems = Object.keys(this.memberDriveItems).length > 0;
      }

      this.id = `${this.componentId}-${this.providerInstanceResource.providerTypeId}-${this.providerInstanceResource.providerInstanceResourceId}-${this.group.title.replaceAll(' ', '-')}-SharePointGroup`;
    }
  }

  private hasAlertInAlerts(done: boolean): boolean {
    return AlertReasonHelper.alertsContainsKeyValuePair(this.alerts, AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_GROUP_ID, this.group.id.toString(), done);
  }

  private setHasWarning(): void {
    if (this.isEveryone || this.isEveryoneExceptExternalUsers) {
      let warningInfoType = WarningHelper.GetWarningInfoTypeCaseGroupMember(this.isEveryone, this.isEveryoneExceptExternalUsers);
      this.warningLevel = WarningHelper.GetWarningLevel(warningInfoType);
      this.hasWarnings([{
        id: this.id,
        resourceId: 'application' + this.resourceProfile.providerInstanceResource.providerTypeId.toLocaleLowerCase() + this.resourceProfile.providerInstanceResource.providerInstanceResourceId.toLocaleLowerCase(),
        resourceProfile: this.resourceProfile,
        warningInfoType: warningInfoType,
        warningLevel: this.warningLevel,
        label: `${this.i18Next.t(ProviderHelper.GetSharePointProviderTypeLabel(this.resourceProfile, false))} > ${(<ProviderInstanceResourceSummaryModel>this.providerInstanceResource).name}`,
        hasAlert: this.hasAlert,
        hasAlertRecentlyClosed: this.hasAlertRecentlyClosed,
        warningInfo: SharePointPermissionComponent.GetWarningInfoTypeLabelByType(warningInfoType),
        viewMoreDetails: true,
        viewDissmiss: true,
        viewResource: false
      }]);

      this.subscriptions.add(WarningHelper.openModalOnRoute(this.router, this.route, this.id, this.openDetails.bind(this), this.warnClick.bind(this)));

    }
  }

  setDelegates(dest: SharePointGroupDelegateWithAlert[], source: SharePointGroupMemberModel[], key: string) {
    let delegatesWithAlerts = AlertReasonHelper.GetIds(this.alerts, key, false);
    let delegatesWithAlertsRecentlyClosed = AlertReasonHelper.GetIds(this.alerts, key, true);

    source.forEach(d => {
      var dWithAlert = {
        "id": d.id,
        "title": d.title,
        "userPrincipalName": d.userPrincipalName,
        "email": d.email,
        "loginName": d.loginName,
        "isGroup": d.isGroup,
        "isO365Group": d.isO365Group,
        "hasAlert": delegatesWithAlerts[d.id],
        "hasAlertRecentlyClosed": delegatesWithAlertsRecentlyClosed[d.id],
        "isEveryone": d.isEveryone,
        "isEveryoneExceptExternalUsers": d.isEveryoneExceptExternalUsers
      };
      dest.push(dWithAlert);
    });
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(SharePointGroupModalComponent, { windowClass: 'id-modal', centered: true, size: 'lg' });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.disableCheckAllWithOwnersFiltered = this.disableCheckAllWithOwnersFiltered;
    modalRef.componentInstance.group = this.group;
    if (this.doNotDeleteCurrentUserFromSiteOwnerGroup) {
      modalRef.componentInstance.membersMinusProfileUser = this.membersMinusProfileUser;
    }
    modalRef.componentInstance.doNotDeleteCurrentUserFromSiteOwnerGroup = this.doNotDeleteCurrentUserFromSiteOwnerGroup;
    modalRef.result.then((model: SharePointReportProfileAlertGroupModel) => {
      this.initAlertModel(model);
      model.providerInstanceResource = this.providerInstanceResource;
      this.subscriptions.add(this.microsoftDatasource.alertSharePointGroup(this.profile.providerInstanceId, this.profile.userId, model).subscribe(model => {
        this.setAlertProcessed(model);
      }));
    }).catch((error) => {
      // console.log(error);
    });
  }

  @ViewChild('viewDetailTemplate') viewDetailTemplate: TemplateRef<any>;

  openDetails(event): void {
    const modalRef = this.modalService.open(InformationModalComponent, { windowClass: 'id-modal', centered: true, size: 'lg' });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.title = this.i18Next.t("modal.detail.title");
    modalRef.componentInstance.bodyTemplate = this.viewDetailTemplate;
    modalRef.componentInstance.ok = this.i18Next.t("button.close");
    modalRef.result.then((model: any) => {
      if (model == true) {
        this.warnClick(null);
      }
    }, (reason) => {
      //Dismiss
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

<ng-container *ngIf="session && session.WarningConfiguration && !session.WarningConfiguration.hideContainer">
  <a *ngIf="warnings" [id]="warningContainerId"></a>
  <div class="warning-container">

    <i2a-title-bar [rounded]="false" [className]="'title-warning'" [id]="id" [linked]="true"
      [guidedTourKey]="guidedTourKey" [isCollapsed]="isCollapsed">

      <ng-container picture>
        <i2a-badge-icon [customClass]="'id-badge-warning'">
          <ng-container icon>
            <fa-icon [icon]="['far', 'siren-on']" size="lg"></fa-icon>
          </ng-container>
        </i2a-badge-icon>
      </ng-container>

      <ng-container title>
        <span class="id-warning-activity" *ngIf="warnings && connectedAccount && sortedWarningsWithoutAlerts && sortedWarnings">
          {{sortedWarningsWithoutAlerts.length + sortedWarnings.length + sortedWarningsWithRecentlyClosed.length}} {{ 'profile.title.warning' | i18next }}
        </span>
      </ng-container>

      <ng-container body>

        <i2a-user-nodata *ngIf="hasNoData"></i2a-user-nodata>

        <div class="module">
          <div class="container-fluid p-0">
            <div class="row no-gutters">

              <template i2a-container></template>

              <div class="col-12">

                <ng-container *ngFor="let warning of sortedWarningsWithoutAlerts">
                  <i2a-warning-notification [warning]="warning"></i2a-warning-notification>
                </ng-container>

              </div>

              <div class="col-12 mt-5" *ngIf="sortedWarnings && sortedWarnings.length > 0">

                <i2a-short-title-bar [isCollapsed]="true">
                  <ng-container title>
                    <fa-icon [title]="'profile.alertReportedHint' | i18next " class="pl-2"
                      [icon]="['far', 'shield-alt']" size="lg"></fa-icon>
                    {{"profile.alertsReported" | i18next : {"alertNumber": sortedWarnings.length }  }}
                  </ng-container>
                  <ng-container body>
                    <ng-container *ngFor="let warning of sortedWarnings">
                      <i2a-warning-notification [warning]="warning"></i2a-warning-notification>
                    </ng-container>
                  </ng-container>
                </i2a-short-title-bar>

              </div>

              <div class="col-12 mt-5" *ngIf="sortedWarningsWithRecentlyClosed && sortedWarningsWithRecentlyClosed.length > 0">

                <i2a-short-title-bar [isCollapsed]="true">
                  <ng-container title>
                    <fa-icon [title]="'profile.alertReportedDoneHint' | i18next " class="pl-2"
                      [icon]="['far', 'shield-alt']" size="lg"></fa-icon>
                    {{"profile.alertsDone" | i18next : {"alertNumber": sortedWarningsWithRecentlyClosed.length }  }}
                  </ng-container>
                  <ng-container body>
                    <ng-container *ngFor="let warning of sortedWarningsWithRecentlyClosed">
                      <i2a-warning-notification [warning]="warning"></i2a-warning-notification>
                    </ng-container>
                  </ng-container>
                </i2a-short-title-bar>

              </div>

            </div>
          </div>
        </div>

      </ng-container>

    </i2a-title-bar>

  </div>
</ng-container>
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from "@angular/core"
import { Observable } from "rxjs";
import { AuthenticationService } from '../../authentication/authentication.service';

@Injectable()
export class AuthenticationHttpInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let request = req.clone({
            setHeaders: {
                Authorization: this.authenticationService.getAuthorizationHeaderValue()
            }
        });

        return next.handle(request);

    }

}
import { ProviderAuditResourceKind } from '../provider-audit-resource-kind';
import { ReportProfileDelegate } from '../profile/report-profile-delegate';
import { ReportProfileDeviceModel } from '../profile/report-profile-device-model';

export class ReportProfileRulesComputedDelegate extends ReportProfileDelegate {
  resourceKind: ProviderAuditResourceKind;
  resourceFolders: string[];
  ruleIds: string[];
  registeredDevices: ReportProfileDeviceModel[];
}

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StringHelper {

    constructor() {
    }

    public static isNullOrWhitespace(input) {

        if (typeof input === 'undefined' || input == null) return true;

        return input.replace(/\s/g, '').length < 1;
    }
}

export enum WarningInfoType {
  Unknown = 0,
  Anonymous = 1,
  Company = 2,
  ExternalShare = 3,
  CompanyExceptExternal = 4,
  sensitivityLabel = 5,
  anonymousSensitivityLabel = 6,
  companySensitivityLabel = 7,
  ExternalForward = 8
}
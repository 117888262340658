import { ReportProfileDelegate } from '../../../profile/report-profile-delegate';

export class ZimbraMailingReportProfilePermissions extends ReportProfileDelegate {
  hasSendAsRights: boolean;
  hasSendOnBehalfRights: boolean;
  hasCalendarRights: boolean;
  hasContactsRights: boolean;
  hasInboxRights: boolean;
  hasFoldersRights: boolean;
  hasAnyRight: boolean;
  hasNoRight: boolean;
}

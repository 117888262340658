import { Component, OnInit, Input } from '@angular/core';
import { ReportProfileResourceBase, ZimbraMailingReportProfileAlertDelegateModel, ZimbraMailingReportProfileAlertPermissionsModel } from '@i2a/web-api-client';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';
import { ZimbraMailingDelegate } from '../../../../../../models/providers/zimbra/mailing/zimbra-mailing-delegate';

@Component({
  selector: 'i2a-zimbra-mailing-delegate-modal',
  templateUrl: './zimbra-mailing-delegate-modal.component.html',
  styleUrls: ['./zimbra-mailing-delegate-modal.component.scss']
})
export class ZimbraMailingDelegateModalComponent implements OnInit {
  @Input() public delegate: ZimbraMailingDelegate;

  @Input() public resourceProfile: ReportProfileResourceBase<any>;
  
  public validation: ZimbraMailingReportProfileAlertDelegateModel;
  public canSend: boolean;
  public devices: any[];

  constructor(
    public session: SessionService
  ) {
    this.canSend = false;
  }

  ngOnInit() {
    this.validation = new ZimbraMailingReportProfileAlertDelegateModel();
    if (typeof this.delegate != 'undefined') {
      this.validation.delegate = this.delegate.accesses;

      if (this.delegate.permissions != null) {
        this.validation.permissions = new ZimbraMailingReportProfileAlertPermissionsModel();
        this.validation.permissions.permissions = this.delegate.permissions;
        this.validation.permissions.hasSendAsRightsIsValid = this.delegate.permissions.hasSendAsRights ||
          this.delegate.permissions.hasCalendarRights ||
          this.delegate.permissions.hasContactsRights ||
          this.delegate.permissions.hasFoldersRights ||
          this.delegate.permissions.hasInboxRights;

        this.validation.permissions.hasCalendarRightsIsValid = this.delegate.permissions.hasSendAsRights ||
          this.delegate.permissions.hasSendOnBehalfRights ||
          this.delegate.permissions.hasContactsRights ||
          this.delegate.permissions.hasFoldersRights ||
          this.delegate.permissions.hasInboxRights;

        this.validation.permissions.hasFoldersRightsIsValid = this.delegate.permissions.hasSendAsRights ||
          this.delegate.permissions.hasSendOnBehalfRights ||
          this.delegate.permissions.hasCalendarRights ||
          this.delegate.permissions.hasContactsRights ||
          this.delegate.permissions.hasInboxRights;

        this.validation.permissions.hasSendOnBehalfRightsIsValid = this.delegate.permissions.hasSendAsRights ||
          this.delegate.permissions.hasCalendarRights ||
          this.delegate.permissions.hasContactsRights ||
          this.delegate.permissions.hasFoldersRights ||
          this.delegate.permissions.hasInboxRights;

        this.validation.permissions.hasContactsRightsIsValid = this.delegate.permissions.hasSendAsRights ||
          this.delegate.permissions.hasSendOnBehalfRights ||
          this.delegate.permissions.hasCalendarRights ||
          this.delegate.permissions.hasFoldersRights ||
          this.delegate.permissions.hasInboxRights;

        this.validation.permissions.hasInboxRightsIsValid = this.delegate.permissions.hasSendAsRights ||
          this.delegate.permissions.hasSendOnBehalfRights ||
          this.delegate.permissions.hasCalendarRights ||
          this.delegate.permissions.hasContactsRights ||
          this.delegate.permissions.hasFoldersRights;

        this.setCanSend();
      }
    }
  }

  private setCanSend() {
    setTimeout(() => {
      this.canSend = (
        this.validation.permissions != null && !this.validation.permissions.hasSendAsRightsIsValid ||
        !this.validation.permissions.hasSendOnBehalfRightsIsValid ||
        !this.validation.permissions.hasCalendarRightsIsValid ||
        !this.validation.permissions.hasContactsRightsIsValid ||
        !this.validation.permissions.hasFoldersRightsIsValid ||
        !this.validation.permissions.hasInboxRightsIsValid);
    });
  }
}  

<i2a-profile-card [id]="id" *ngIf="administrators" [viewMoreDetails]="true" (onViewMoreDetails)="openDetails($event)"
  [title]="'profile.provider.microsoft.sharePoint.administrators.title' | i18next" (onWarnClick)="warnClick($event)"
  [hasAlert]="hasAlert" [hasAlertRecentlyClosed]="hasAlertRecentlyClosed" [viewWarnButton]="false"
  [warningLevel]="warningLevel">


  <ng-container picto>
    <i2a-badge-icon>
      <ng-container icon>
        <fa-icon [icon]="['fas', 'users-cog' ]" size="lg"></fa-icon>
      </ng-container>
    </i2a-badge-icon>
  </ng-container>

  <ng-container content>
    <div class="p-0 share-point-administrators">
      <div class="row no-gutters">
        <div class="col m-2">
          <div class="row no-gutters">
            <div class="col">
              <span class="id-title">{{"profile.provider.microsoft.sharePoint.administrators.total" | i18next }}</span>
              <span class="id-value"> {{ administrators.length }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters" *ngIf="isEveryone || isEveryoneExceptExternals">
        <div class="col m-2">
          <p class="warn-group-member" *ngIf="isEveryone">
            {{ "profile.provider.microsoft.sharePoint.warnGroupMember.everyone" | i18next }}
          </p>
          <p class="warn-group-member" *ngIf="isEveryoneExceptExternals">
            {{ "profile.provider.microsoft.sharePoint.warnGroupMember.everyoneExceptExternals" | i18next }}
          </p>
        </div>
      </div>
    </div>
  </ng-container>

</i2a-profile-card>

<ng-template #viewDetailTemplate>
  <i2a-modal-profile-header-title>
    <ng-container picto>
      <i2a-badge-icon>
        <ng-container icon>
          <fa-icon [icon]="['fas', 'users-cog' ]" size="lg"></fa-icon>
        </ng-container>
      </i2a-badge-icon>
    </ng-container>
    <ng-container title>
      <span
        [title]="'profile.provider.microsoft.sharePoint.administrators.title' | i18next">{{'profile.provider.microsoft.sharePoint.administrators.title' | i18next}}</span>
    </ng-container>
  </i2a-modal-profile-header-title>

  <div class="row no-gutters">
    <div class="col ml-2 mr-2">
      <ng-container *ngIf="members && members.length > 0">
        <span class="resource-name">{{"profile.provider.microsoft.sharePoint.administrators.members" | i18next }}</span>
        <table class="table table-sm table-bordered id-table">
          <tbody>
            <tr *ngFor="let member of members">
              <td scope="row" [ngClass]="{'text-break': true, 'has-alert': member.hasAlert, 'has-alert-recently-closed': member.hasAlertRecentlyClosed, 'icons': true}">
                <fa-icon *ngIf="member.isGroup" [icon]="['fas', 'users']"></fa-icon>
                <img *ngIf="member.isO365Group" src="assets/pictos/providers/o365.svg">
                <fa-icon *ngIf="!member.isGroup" [icon]="['fas', 'user']"></fa-icon>
              </td>
              <td scope="row" [ngClass]="{'text-break': true, 'has-alert': member.hasAlert, 'has-alert-recently-closed': member.hasAlertRecentlyClosed}">
                {{member.title}}
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
  </div>
</ng-template>
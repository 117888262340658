import { Component, OnInit, Input, Inject } from '@angular/core';
import { OneDriveReportProfileDelegate, OneDriveReportProfileAlertExternalDelegatesModel, OneDriveReportProfileAlertDelegateModel, SharePointReportProfileAlertPermissionsModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';
import { OneDriveDelegate } from 'projects/web-user-profile/src/app/models/providers/microsoft/one-drive/one-drive-delegate';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-one-drive-external-delegates-modal',
  templateUrl: './one-drive-external-delegates-modal.component.html',
  styleUrls: ['./one-drive-external-delegates-modal.component.scss']
})
export class OneDriveExternalDelegatesModalComponent implements OnInit {
  @Input() public delegates: OneDriveDelegate[];
  @Input() public resourceProfile: ReportProfileResourceBase<any>;

  public validation: OneDriveReportProfileAlertExternalDelegatesModel;
  public canSend: boolean;
  public delegateFolders: any[];//TODO CRADO
  public driveItemsPermissions: any[];//TODO CRADO
  public name: string;
  public allDelegates: boolean;


  public allChecked(delegateValidation: OneDriveReportProfileAlertDelegateModel): boolean {
    var hasAccesses = this.hasAccesses(delegateValidation.delegate.logonUserId);
    var hasPermissions = this.hasPermissions(delegateValidation.delegate.logonUserId);
    return (!hasAccesses || hasAccesses && delegateValidation.allFolders)
      && (!hasPermissions || hasPermissions && delegateValidation.permissions.allPaths);
  }

  hasAccesses(logonUserId: string): boolean {
    return this.delegateFolders.find(d => d.logonUserId == logonUserId).folders.length > 0;
  }

  hasPermissions(logonUserId: string): boolean {
    return this.driveItemsPermissions.find(d => d.logonUserId == logonUserId).driveItems.length > 0;
  }


  getFolders(logonUserId: string): string {
    return this.delegateFolders.find(d => d.logonUserId == logonUserId).folders;
  }

  getPermissions(logonUserId: string): string {
    return this.driveItemsPermissions.find(d => d.logonUserId == logonUserId).driveItems;
  }

  allDelegatesChecked(isChecked: boolean) {
    this.validation.delegates.forEach(d => {
      if (this.hasAccesses(d.delegate.logonUserId) ) {
        d.allFolders = isChecked;
      }
      if (this.hasPermissions(d.delegate.logonUserId)) {
        d.permissions.allPaths = isChecked;
      }
    })
  }

  constructor(
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService,
    public session: SessionService
  ) {
    this.canSend = false;
    this.allDelegates = false;
  }

  private setCanSend() {
    setTimeout(() => {
      for (let index = 0; index < this.validation.delegates.length; index++) {
        const delegate = this.validation.delegates[index];
        this.canSend = delegate.folders.length > 0 || delegate.allFolders;
        if (this.canSend) {
          break;
        }
      }
    });
  }

  ngOnInit() {
    this.validation = new OneDriveReportProfileAlertExternalDelegatesModel();
    this.validation.delegates = [];

    if (this.delegates) {
      this.delegateFolders = [];
      this.driveItemsPermissions = [];
      for (let index = 0; index < this.delegates.length; index++) {
        const delegate = this.delegates[index];
        let delegateValidation = new OneDriveReportProfileAlertDelegateModel;
        delegateValidation.folders = [];
        delegateValidation.delegate = delegate.accesses;
        var foldersDelegate = {
          logonUserId: delegate.accesses.logonUserId,
          folders: []
        }
        let allFolders = false;
        OneDriveExternalDelegatesModalComponent.FoldersAccessedWithoutPermissions(delegate).forEach(folder => {
          foldersDelegate.folders.push({
            "name": folder,
            "checked": false
          });
          allFolders = true;
        });
        delegateValidation.allFolders = allFolders;
        delegateValidation.allFolders = allFolders;
        delegateValidation.permissions = new SharePointReportProfileAlertPermissionsModel();
        delegateValidation.permissions.invalidPaths = [];
        delegateValidation.permissions.permissions = delegate.permissions;
        var driveItemsPermissions = {
          logonUserId: delegate.accesses.logonUserId,
          driveItems: []
        }
        if (delegate.permissions != null) {
          Object.keys(delegate.permissions.driveItems).forEach(driveItemKey => {
            driveItemsPermissions.driveItems.push({
              "name": driveItemKey,
              "checked": false,
              "hasSensitivityLabel": delegate.permissions.driveItems[driveItemKey].hasSensitivityLabel,
              "sensitivityLabel": delegate.permissions.driveItems[driveItemKey].sensitivityLabel,
              "roles": delegate.permissions.driveItems[driveItemKey].rights
            });
          });
          delegateValidation.permissions.allPaths = true;
        }


        this.delegateFolders.push(foldersDelegate);
        this.driveItemsPermissions.push(driveItemsPermissions);

        this.validation.delegates.push(delegateValidation);
      }
      this.setCanSend();
    }
  }

  public static FoldersAccessedWithoutPermissions(delegate: OneDriveDelegate): string[] {
    var folders = new Array<string>();

    if (delegate.permissions != null) {
      var permissionFolders = Object.keys(delegate.permissions.driveItems);
      delegate.accesses.foldersAccessed.forEach(a => {
        if (permissionFolders.findIndex(p => a.startsWith(p)) == -1) {
          folders.push(a);
        }
      });
    } else {
      folders = delegate.accesses.foldersAccessed;
    }

    return folders;
  }
}

import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ZimbraMailingDelegateModalComponent } from '../zimbra-mailing-delegate-modal/zimbra-mailing-delegate-modal.component';
import { ProfileItemBaseClassComponent } from '../../../../shared/modals/profile-item-base-class-component';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ToastrService } from 'ngx-toastr';
import { ZimbraMailingDelegate } from '../../../../../../models/providers/zimbra/mailing/zimbra-mailing-delegate';
import { ZimbraProfileProviderDatasourceService, ZimbraMailingReportProfileAlertDelegateModel, ZimbraMailingReportProfile } from '@i2a/web-api-client';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';

@Component({
  selector: 'i2a-zimbra-mailing-delegate',
  templateUrl: './zimbra-mailing-delegate.component.html',
  styleUrls: ['./zimbra-mailing-delegate.component.scss']
})
export class ZimbraMailingDelegateComponent extends ProfileItemBaseClassComponent<ZimbraMailingReportProfile> implements OnInit, OnDestroy {
  @Input() public delegate: ZimbraMailingDelegate;

  constructor(
    modalService: NgbModal,
    private zimbraDatasource: ZimbraProfileProviderDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, modalService);
  }

  setItems() {
    if (this.delegate && this.alerts) {
      super.setItems();
      this.hasAlert = this.hasAlertInAlerts(false);
      this.hasAlertRecentlyClosed = this.hasAlertInAlerts(true);
      this.id = `${this.componentId}-${this.providerInstanceResource.providerTypeId}-${this.providerInstanceResource.providerInstanceResourceId}-${this.delegate.accesses.name.replaceAll(' ', '-')}-ZimbraMailingDelegate`;
    }
  }

  private hasAlertInAlerts(done: boolean): boolean {
    return AlertReasonHelper.alertsContainsDelegate(this.alerts, this.delegate.accesses, done);
  }

  warnClick(event): void {
    const modalRef = this.modalService.open(ZimbraMailingDelegateModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.delegate = this.delegate;
    modalRef.result.then((model: ZimbraMailingReportProfileAlertDelegateModel) => {
      this.initAlertModel(model);
      model.providerInstanceResource = this.providerInstanceResource;
      if (model.permissions != null) {
        model.permissions.endDate = this.profile.endDate;
        model.permissions.providerInstanceResource = this.providerInstanceResource;
        model.permissions.comments = model.comments;
      }
      this.subscriptions.add(this.zimbraDatasource.alertMailingDelegate(this.profile.providerInstanceId, this.profile.userId, model).subscribe(model => {
        this.setAlertsProcessed(model);
      }));
    }).catch((error) => {
      // console.log(error);
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

<i2a-profile-alert-base-modal
  [manualDescriptionKey]="'applyCorrection.providers.microsoft.sharePoint.delegate.default.manual.description'"
  [manualLinkKey]="'applyCorrection.providers.microsoft.sharePoint.delegate.default.manual.link'"
  [automaticDescriptionKey]="'applyCorrection.providers.microsoft.sharePoint.delegate.default.automatic.description'"
  [ignoreCommentToSend]="session.IgnoreCommentToSend" [displayComment]="session.DisplayComment"
  [validation]="validation" [canSend]="canSend" [modalTitle]="'profile.confirmationOfInformationTitle' | i18next "
  [informationKey]="informationKey" [resourceProfile]="resourceProfile">

  <ng-container header-picto>
    <i2a-resource-photo [providerInstanceResource]="delegate.accesses.providerInstanceResource" [size]="50">
    </i2a-resource-photo>
  </ng-container>

  <ng-container header-title>
    {{name}}<br>
    <ng-container *ngIf="!delegate.accesses.isAnonymousUser && !delegate.accesses.isExternalUser">
      {{delegate.lastAccess | amDateFormat: 'LLL'}}
    </ng-container>
  </ng-container>

  <ng-container body>
    <div class="container-fluid">
      <div class="row pt-3">
        <div class="col-12">
          <i2a-share-point-access-permission-form [(validation)]="validation" [folders]="folders"
            [driveItemsPermissions]="driveItemsPermissions" (setCanSend)="setCanSend()"
            [allAccessAndPermissionsTitle]="titleKey | i18next">
          </i2a-share-point-access-permission-form>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container message>
    <div class="row pt-3" *ngIf="hasSpecificLink">
      <div class="col-12">
        <i2a-message>
          <ng-container message-content>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 13.125 13.125"
            style="vertical-align: sub;margin-right: 5px;">
            <path
              d="M363.563,108.875L357,122h13.125Zm0.437,5.25V118.5h-0.875v-4.375H364Zm-0.875,5.25H364v0.875h-0.875v-0.875Z"
              fill="#ffcf64" transform="translate(-357 -108.875)" /></svg>

        {{'profile.provider.microsoft.sharePoint.specificLinkWarning' | i18next}}
          </ng-container>
        </i2a-message>
      </div>
    </div>
  </ng-container>

</i2a-profile-alert-base-modal>
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ProviderInstanceResourceModel } from '@i2a/web-api-client';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { ProviderHelper } from 'projects/web-user-profile/src/app/helpers/provider-helper';
import { TemplateConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/template-configuration';
import { timer } from 'rxjs';

@Component({
  selector: 'i2a-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent implements OnInit, OnChanges {
  
  @Input() public collectedResources: ProviderInstanceResourceModel[];
  @Input() public collectedResourcesCount: number;
  @Input() public collectedResourcesLoaded: ProviderInstanceResourceModel[];
  @Input() public configuration: TemplateConfiguration;
  @Input() public errorPendingCollect: boolean;
  @Input() public errorPendingCollectObject: HttpErrorResponse;
  @Input() public loading: boolean;
  @Input() public loadingRes: ProviderInstanceResourceModel;
  @Input() public noCollectedResources: boolean;
  @Input() public progressValue: number;
  @Input() public start: boolean;
  
  public progressValueDisplayProfile: number;
  public progressDisplayProfile: any;
  
  @Output() public startChange = new EventEmitter<boolean>();

  constructor() { }
  
  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'loading': {
            if (!this.loading && !this.errorPendingCollect) {
              this.progressValueDisplayProfile = 0;
              this.progressDisplayProfile = timer(0, 100).subscribe(t => {
                this.progressValueDisplayProfile += 1.8;
              });
              setTimeout(() => {
                this.progressDisplayProfile.unsubscribe();

                this.displayProfile()
              }, 6000);
            } else if (this.errorPendingCollect) {
            }
          }
        }
      }
    }
  }

  ngOnInit() {
  }


  @ViewChild('carousel') carousel: NgbCarousel;


  public next() {
    this.carousel.next();
  }

  public prev() {
    this.carousel.prev();
  }

  public displayProfile() {
    this.startChange.emit(true);
  }

  
  providerTypeLabel(resource: ProviderInstanceResourceModel): string {
    return ProviderHelper.GetProviderTypeLabel(resource.providerId, resource.providerTypeId);
  }

  providerPhotoUrl(resource: ProviderInstanceResourceModel): string {
    return ProviderHelper.GetProviderImage(resource.providerId, resource.providerTypeId, null);
  }
}

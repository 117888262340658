import { Component, OnInit, Inject } from '@angular/core';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { RulesComputedDelegateConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/rules-computed-delegate-configuration';
import { BaseProviderInstanceResourceModel, UserModel, ReportProfileBaseModel, AlertInvalidStateFeaturedModel, MicrosoftOfficeReportProfileModel, ReportProfileRulesComputedDelegate, ReportProfileResourceBase, Constants, ZimbraReportProfileModel, ReportProfileDeviceModel, ExchangeReportProfile, ExchangeReportProfilePermissions, AzureADReportProfile, AzureADAppPermissionsModel, AzureADAppPermissionGrantModel } from '@i2a/web-api-client';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ProfileHelper } from 'projects/web-user-profile/src/app/helpers/profile-helper';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';
import { AzureADPermissionGrantsConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/azure-ad-permission-grants-configuration';
import { AzureAdPermissionGrantsModuleComponent } from '../azure-ad-permission-grants-module/azure-ad-permission-grants-module.component';

@RegisterMyProfileComponent(TemplateConstants.MODULE_AZUREAD_RULES_COMPUTED_DELEGATE_I2A)
@Component({
  selector: 'i2a-azure-ad-rules-computed-delegate-module',
  templateUrl: './azure-ad-rules-computed-delegate-module.component.html',
  styleUrls: ['./azure-ad-rules-computed-delegate-module.component.scss']
})
export class AzureADRulesComputedDelegateModuleComponent extends ProfileBaseClassComponent<RulesComputedDelegateConfiguration,AzureADReportProfile> implements OnInit, IModule<RulesComputedDelegateConfiguration> {
  private resource: BaseProviderInstanceResourceModel;
  public delegates: ReportProfileRulesComputedDelegate[];
  public devices: ReportProfileDeviceModel[];
  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, null);
    this.devices = [];
  }

  ngOnInit() {
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: RulesComputedDelegateConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel, resourceTemplate?: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.resource = resource;
    this.resourceProfile = (<MicrosoftOfficeReportProfileModel>profile).azureADProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);

    var allDelegates = this.resourceProfile.rulesComputedDelegates.filter(d=>
      {
       return ( !this.configuration.filterUnreconcileAccounts || !(this.configuration.filterUnreconcileAccounts && d.unreconcileAccount));
      }).sort((a, b) => { return ProfileHelper.compare(a, b, this.i18Next); });
    
    this.delegates = allDelegates;

    var permissionModuleDelegates = new Array<AzureADAppPermissionGrantModel>();
    var permissionModule = resourceTemplate.modules.find(m => m.id == TemplateConstants.MODULE_AZUREAD_PERMISSION_GRANTS);    
    if (permissionModule != null && permissionModule.isEnabled)
    {
      var permissionModuleConfiguration = permissionModule.configuration as AzureADPermissionGrantsConfiguration;
      permissionModuleDelegates = AzureAdPermissionGrantsModuleComponent.SetGrants(this.resourceProfile.azureADAppPermissions);
    }

    this.delegates = this.delegates.filter(d=>
      {
        if (d.userId != null)
        {
          var permissionModuleIndex = permissionModuleDelegates.findIndex(dmd => dmd.user != null && dmd.user.id == d.userId);
          if ((permissionModuleIndex >=0)) { return false; }
        }
        return true;
      }).sort((a, b) => { return ProfileHelper.compare(a, b, this.i18Next); });
    this.devices = this.profile.userProfile.devices.filter(d=>
      {
       return ( !this.configuration.filterIsActiveDevice || this.configuration.filterIsActiveDevice && d.isActive) &&  d.syncDevices.findIndex(s=> s.providerInstanceResourceId == this.resource.providerInstanceResourceId && s.providerTypeId == this.resource.providerTypeId) >= 0;
      });
  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { UserModel, UserReportProfileAlertDevicesModel } from '@i2a/web-api-client';
import { DeviceWithAlert } from 'projects/web-user-profile/src/app/models/users/device-with-alert';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-device-modal',
  templateUrl: './device-modal.component.html',
  styleUrls: ['./device-modal.component.scss']
})
export class DeviceModalComponent implements OnInit {
  @Input() public devices: DeviceWithAlert[];
  @Input() public notRegisteredDevices: DeviceWithAlert[];
  @Input() public user: UserModel;
  @Input() public disableManualRemediation:boolean;

  public selectedDevices: any[];
  public selectednotRegisteredDevices: any[];

  public validation: UserReportProfileAlertDevicesModel;
  public canSend: boolean;

  constructor(
    public session: SessionService
  ) {
    this.devices = [];
    this.notRegisteredDevices = [];
  }

  private setCanSend() {
    setTimeout(() => {
      this.canSend = this.validation.invalidDevices.length > 0 ||
        this.validation.invalidNotRegisteredDevices.length > 0;
    });
  }

  ngOnInit() {
    this.validation = new UserReportProfileAlertDevicesModel();
    this.validation.invalidNotRegisteredDevices = [];
    this.validation.invalidDevices = [];
    this.selectedDevices = [];
    this.selectednotRegisteredDevices = [];
    if (this.devices) {
      this.devices.forEach(device => {
        this.selectedDevices.push({
          "name": device.name,
          "checked": this.devices.length + this.devices.length == 1,
          "image": device.image,
          "model": device
        });
      });
    }
    if (this.notRegisteredDevices) {
      if (this.devices == null) {
        this.devices = [];
      }
      this.notRegisteredDevices.forEach(device => {
        this.selectednotRegisteredDevices.push({
          "name": device.name,
          "checked": this.devices.length + this.notRegisteredDevices.length == 1,
          "image": device.image,
          "model": device
        });
      });
    }
  }

  checked(isChecked: boolean, device: any) {
    const index: number = this.validation.invalidDevices.findIndex(d => d.id == device.model.model.id);
    if (isChecked && index === -1) {
      this.validation.invalidDevices.push(device.model.model);
    } 
    else if (!isChecked && index !== -1) {
      this.validation.invalidDevices.splice(index, 1);
    }
    this.setCanSend();
  }

  notRegisteredChecked(isChecked: boolean, device: any) {
    const index: number = this.validation.invalidNotRegisteredDevices.findIndex(d => d.syncId == device.model.model.syncId);
    if (isChecked && index === -1) {
      this.validation.invalidNotRegisteredDevices.push(device.model.model);
    }
    else if (!isChecked && index !== -1) {
      this.validation.invalidNotRegisteredDevices.splice(index, 1);
    }
    this.setCanSend();
  }

}

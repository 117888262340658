<i2a-profile-alert-base-modal
  [manualDescriptionKey]="'applyCorrection.providers.microsoft.sharePoint.group.default.manual.description'"
  [manualLinkKey]="'applyCorrection.providers.microsoft.sharePoint.group.default.manual.link'"
  [automaticDescriptionKey]="'applyCorrection.providers.microsoft.sharePoint.group.default.automatic.description'"
  [ignoreCommentToSend]="session.IgnoreCommentToSend" [displayComment]="session.DisplayComment" [displayHelp]="false"
  [validation]="validation" [canSend]="canSend"
  [modalTitle]="'profile.provider.microsoft.sharePoint.confirmationOfInformationTitle.members' | i18next "
  [informationKey]="informationKey" [resourceProfile]="resourceProfile">

  <ng-container header-picto>
    <i2a-badge-icon>
      <ng-container icon>
        <img width="24px" height="24px" class="align-img" src="assets/pictos/providers/sharepoint.svg">
      </ng-container>
    </i2a-badge-icon>
  </ng-container>

  <ng-container header-title>
    {{group.title}}<br>
    {{group.description}}
  </ng-container>

  <ng-container message>
    <div class="row pt-3" *ngIf="displayMessageWarning">
      <div class="col-12">
        <i2a-message>
          <ng-container message-content>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 13.125 13.125"
              style="vertical-align: sub;margin-right: 5px;">
              <path
                d="M363.563,108.875L357,122h13.125Zm0.437,5.25V118.5h-0.875v-4.375H364Zm-0.875,5.25H364v0.875h-0.875v-0.875Z"
                fill="#ffcf64" transform="translate(-357 -108.875)" /></svg>

            {{'profile.provider.microsoft.sharePoint.group.allOwnersWarning' | i18next}}
          </ng-container>
        </i2a-message>
      </div>
    </div>
  </ng-container>

  <ng-container body>
    <div class="container-fluid">

      <!-- MEMBERS -->
      <div class="row pt-3" *ngIf="displayMembers">
        <div class="col-12 pl-3">

          <div class="form-group delegate-container">
            <table class="table-sm id-table mt-2">
              <tbody>
                <tr>
                  <td scope="row text-center" class="cell-checkbox">
                    <i2a-input-checkbox [(model)]="validation.allMembers" (modelChange)="allMembersChecked($event)"
                      [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                      [valueUnChecked]="false">
                    </i2a-input-checkbox>
                  </td>
                  <td scope="row" class="text-break w-100 delegate-title">
                    {{'profile.provider.microsoft.sharePoint.group.allMembers' | i18next }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
        <div class="form-group col-12 pl-4" *ngIf="members && !validation.allMembers">
          <h4>{{'profile.provider.microsoft.sharePoint.group.members' | i18next }}</h4>
          <table class=" table-sm id-table">
            <tbody>
              <tr *ngFor="let member of members">
                <td scope="row">
                  <i2a-input-checkbox [(model)]="member.checked" (modelChange)="checkedMember($event, member)"
                    [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                    [valueUnChecked]="false"> </i2a-input-checkbox>
                </td>
                <td scope="row" class="text-break w-100">
                  {{member.name}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </ng-container>

</i2a-profile-alert-base-modal>
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ReportProfileResourceBase, SharePointGroupModel, SharePointReportProfileAlertGroupModel, WellKnownGroupType } from '@i2a/web-api-client';
import { SharePointGroupDelegateWithAlert } from 'projects/web-user-profile/src/app/models/providers/microsoft/share-point/share-point-group-delegate-with-alert';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-share-point-group-modal',
  templateUrl: './share-point-group-modal.component.html',
  styleUrls: ['./share-point-group-modal.component.scss']
})
export class SharePointGroupModalComponent implements OnInit, OnChanges {
  @Input() public group: SharePointGroupModel;
  @Input() public disableCheckAllWithOwnersFiltered: boolean;
  @Input() public resourceProfile: ReportProfileResourceBase<any>;
  @Input() public membersMinusProfileUser: SharePointGroupDelegateWithAlert[] | null;
  @Input() public doNotDeleteCurrentUserFromSiteOwnerGroup: boolean;

  public validation: SharePointReportProfileAlertGroupModel;
  public canSend: boolean;
  public members: any[];
  public informationKey: string;
  public displayMessageWarning: boolean;

  public displayMembers: boolean;

  public isOwnerGroup: boolean = false;

  constructor(
    public session: SessionService
  ) {
    this.canSend = false;
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.validation || changes.membersMinusProfileUser || changes.allMembers) {
      this.displayMessageWarning = this.setDisplayMessageWarning();
    }
  }

  private setCanSend() {
    setTimeout(() => {
      this.canSend = this.validation.invalidMemberList.length > 0
        ||
        (
          this.isOwnerGroup &&
          (!this.disableCheckAllWithOwnersFiltered && this.group.membersFiltered && this.validation.allMembers)
          ||
          (this.doNotDeleteCurrentUserFromSiteOwnerGroup && !this.disableCheckAllWithOwnersFiltered && (this.group.membersFiltered || this.membersMinusProfileUser?.length > 0) && this.validation.allMembers)
          ||
          (this.disableCheckAllWithOwnersFiltered && (!this.group.membersFiltered && this.membersMinusProfileUser?.length == 0) && !this.validation.allMembers)
        )
        ||
        (this.group.o365GroupMemberFiltered && this.validation.allMembers)
        ||
        (!this.isOwnerGroup && this.validation.allMembers);
    });
  }

  private setDisplayMessageWarning(): boolean {
    if (this.group.o365GroupMemberFiltered) {
      return false;
    } else if (this.disableCheckAllWithOwnersFiltered) {
      return this.isOwnerGroup && this.validation.allMembers;
    } else {
      return this.isOwnerGroup && !this.group.membersFiltered && this.validation.allMembers
    }
  }

  ngOnInit() {
    this.validation = new SharePointReportProfileAlertGroupModel();
    this.validation.invalidMemberList = [];
    this.validation.allMembers = false;
    this.validation.group = this.group;
    this.members = [];
    if (this.group) {
      this.isOwnerGroup = this.group.wellKnownGroupType == WellKnownGroupType.Owner;
      this.setMembers();
      this.informationKey = this.members?.length > 1 ? 'modal.reportAnomaly.checkMembers' : 'modal.reportAnomaly.checkMember';
      
      this.displayMembers = this.members && this.members.length > 0;
      
      this.setCanSend();
    }
  }

  //#region members
  
  private setMembers(): void {
    if (this.membersMinusProfileUser?.length > 0) {
      this.membersMinusProfileUser.forEach((member) => {
        this.members.push({
          "id": member.id,
          "name": member.title,
          "checked": false
        });
      });
    } else {
      this.group.members.forEach(member => {
        this.members.push({
          "id": member.id,
          "name": member.title,
          "checked": false
        });
      });
    }
  }

  checkedMember(isChecked: boolean, member: any) {
    const index: number = this.validation.invalidMemberList.indexOf(member.id);
    if (isChecked && index === -1) {
      this.validation.invalidMemberList.push(member.id);
    }
    else if (!isChecked && index !== -1) {
      this.validation.invalidMemberList.splice(index, 1);
    }
    if (this.members.length == this.validation.invalidMemberList.length) {
      this.allMembersChecked(true);
      setTimeout(() => {
        this.validation.allMembers = true;
      });
    }
    this.setCanSend();
  }

  allMembersChecked(isChecked: boolean) {
    if (this.members.length == this.validation.invalidMemberList.length) {
      if (isChecked) {
        this.members.forEach(f => f.checked = false);
        this.validation.invalidMemberList = [];
      }
    } else {
      this.members.forEach(f => {
        f.checked = isChecked;
        this.checkedMember(isChecked, f);
      });
    }
    this.setCanSend();
  }

  //#endregion
}

import { BaseProviderInstanceModel } from './base-provider-instance-model';

export class ProviderAdminObjectModel extends BaseProviderInstanceModel {
  id: string;
  providerInstanceResourceId: string|null;
  providerInstanceItemId: string;
  type: number;
  object: string;
  name: string;
  dataCreationDate: Date;
  dataCollectDate: Date;
  dataProcessingDate: Date|null;
  creationDate: Date;
  modificationDate: Date;
}

import { ExchangeReportProfileAlertPermissionsModel } from '../../microsoft/exchange/profile/exchange-report-profile-alert-permissions-model';
import { ExchangeReportProfileDelegate } from '../../microsoft/exchange/profile/exchange-report-profile-delegate';
import { ReportProfileAlertResourceBaseModel } from '../../profile/report-profile-alert-resource-base-model';

export class ExchangeReportProfileAlertDelegateModel extends ReportProfileAlertResourceBaseModel {
  delegate: ExchangeReportProfileDelegate;
  permissions: ExchangeReportProfileAlertPermissionsModel;
  inboxAccessIsValid: boolean;
  calendarAccessIsValid: boolean;
  folderAccessIsValid: boolean;
  deviceSyncIds: string[];
}

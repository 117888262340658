import { Component, OnInit, Inject, Input } from '@angular/core';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { ReportProfileBaseModel, UserModel, BaseProviderInstanceResourceModel, ZimbraMailingReportProfile, ZimbraReportProfileModel, ZimbraMailingReportProfilePermissions, AlertInvalidStateFeaturedModel, ZimbraMailingReportProfileDelegate, ReportProfileResourceBase } from '@i2a/web-api-client';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ProfileHelper } from 'projects/web-user-profile/src/app/helpers/profile-helper';
import { ZimbraMailingPermissionConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/zimbra-mailing-permission-configuration';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';
import { WarningInfo } from 'projects/web-user-profile/src/app/models/warnings/warning-info';
import { ZimbraMailingPermissionComponent } from '../zimbra-mailing-permission/zimbra-mailing-permission.component';
import { ZimbraMailingReportProfilePermissionsWithWarnings } from 'projects/web-user-profile/src/app/models/providers/zimbra/mailing/zimbra-mailing-report-profile-permissions-with-warnings';

@RegisterMyProfileComponent(TemplateConstants.MODULE_ZIMBRA_MAILING_PERMISSION)
@Component({
  selector: 'i2a-zimbra-mailing-permission-module',
  templateUrl: './zimbra-mailing-permission-module.component.html',
  styleUrls: ['./zimbra-mailing-permission-module.component.scss']
})
export class ZimbraMailingPermissionModuleComponent extends ProfileBaseClassComponent<ZimbraMailingPermissionConfiguration,ZimbraMailingReportProfile> implements OnInit, IModule<ZimbraMailingPermissionConfiguration> {
  private resource: BaseProviderInstanceResourceModel;
  public mailingProfile: ZimbraMailingReportProfile;

  @Input()
  public delegatesWithoutAccess: ZimbraMailingReportProfilePermissionsWithWarnings[] = [];
  
  public showMore: boolean;
  public showMoreAfter: number;
  public maxShowMore: number;
  public isVisibleButtonsShowMore: boolean;
  private isActiveShowMoreOption: boolean;

  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, null);
  }

  ngOnInit() {
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: ZimbraMailingPermissionConfiguration,  alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel, resourceTemplate?: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.resource = resource;
    this.mailingProfile = (<ZimbraReportProfileModel>profile).mailingProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
    this.mailingProfile.delegates.sort((a, b) => { return ProfileHelper.compare(a, b, this.i18Next); });

    let warnings: Array<WarningInfo> = [];

    this.setDelegates(configuration, warnings);

    if (warnings.length > 0) {
      this.hasWarnings(warnings);
    }

  }

  private setDelegates(configuration: ZimbraMailingPermissionConfiguration, warnings: Array<WarningInfo>) {
    let delegatesWithoutAccess = ZimbraMailingPermissionModuleComponent.SetDelegates(this.mailingProfile.permissions, this.mailingProfile.delegates);
    let delegatesWithoutAccessWithWarnings = delegatesWithoutAccess?.map(permissions => {
      return ZimbraMailingPermissionComponent.getPermissionsWithWarning(this.i18Next, this.componentId, this.resourceProfile, permissions, this.alerts);
    });

    delegatesWithoutAccessWithWarnings?.forEach((d: ZimbraMailingReportProfilePermissionsWithWarnings) => {
      if (d.model.isNameKey || (d.warnings != null && d.warnings != undefined)) {
        this.delegatesWithoutAccess.unshift(d);
        if ((d.warnings != null && d.warnings != undefined)) {
          warnings.push(...d.warnings);
        }
      } else {
        this.delegatesWithoutAccess.push(d);
      }
    });

    this.showMoreAfter = configuration.showMoreAfter ?? 0;

    let minShowMore = this.delegatesWithoutAccess.filter(d => d.model.isNameKey || d.warnings?.length > 0).length;
    if (this.showMoreAfter != 0 && this.showMoreAfter < minShowMore) {
      this.showMoreAfter = minShowMore;
    }

    this.isActiveShowMoreOption = this.showMoreAfter > 0;
    this.isVisibleButtonsShowMore = this.isActiveShowMoreOption && this.delegatesWithoutAccess?.length > this.showMoreAfter;
    this.setShowMoreValue();
  }

  public static SetDelegates(permissions: ZimbraMailingReportProfilePermissions[], zimbraMailingProfileDelegates: ZimbraMailingReportProfileDelegate[]) :ZimbraMailingReportProfilePermissions[] {
    var delegates = [];
    permissions.forEach(item => {
      if (item.userId != null) {
        if (zimbraMailingProfileDelegates.findIndex(d => d.userId != null && d.userId == item.userId) < 0) {
            delegates.push(item);
        }
      }
      else if (item.providerInstanceUser != null) {
        if (zimbraMailingProfileDelegates.findIndex(d => d.providerInstanceUser != null && d.providerInstanceUser.providerInstanceResourceId == item.providerInstanceUser.providerInstanceResourceId) < 0) {
            delegates.push(item);
        }
      }
      else {
        if (zimbraMailingProfileDelegates.findIndex(p => p.logonUserId == item.logonUserId) < 0) {
          delegates.push(item);
        }
      }
    });
    return delegates;
  }
  
  public onShowMore() {
    this.showMore = !this.showMore;
    this.setShowMoreValue();
  }

  private setShowMoreValue() {
    this.maxShowMore = (!this.showMore && this.isActiveShowMoreOption) ? this.showMoreAfter : this.delegatesWithoutAccess?.length;
  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }
}
import { Component, OnInit, Input, OnDestroy, ViewChild, Inject, ComponentRef } from '@angular/core';
import { ProviderInstanceResourcesDatasourceService, ProviderInstanceResourceSummaryModel, BaseProviderInstanceResourceModel, ProviderDatasourceService, AuditReportModel, ConnectedAccountModel, UserModel, ReportProfileBaseModel, AlertInvalidStateFeaturedModel, AlertConstants, Constants, ReportProfileResourceBase, MicrosoftOfficeReportProfileModel, SharePointReportProfile, MicrosoftOfficeProfileProviderDatasourceService } from '@i2a/web-api-client';
import { ProviderHelper } from '../../../../helpers/provider-helper';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from '../../../../services/session-service';
import { HttpResponse } from '@angular/common/http';
import { RegisterMyProfileComponent } from '../../../../decorators/my-profile-component.decorator';
import { TemplateService } from '../../../../services/template.service';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ProfileBaseClassComponent } from '../../../profile/shared/profile-base-class-component';
import { ResourceContainerConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/resource-container-configuration';
import { IModuleResourcesContainer } from 'projects/web-user-profile/src/app/models/templates/modules/i-module-resources-container';
import { IBaseModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-base-module';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DisplayAlertsReasonsModalComponent } from '../../../alerts/display-alerts-reasons-modal/display-alerts-reasons-modal.component';
import { ContainerDirective } from 'projects/web-user-profile/src/app/directives/container-directive';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { AlertRemediationTerminatedActionStatus } from 'projects/web-user-profile/src/app/models/alert-remediation-terminated-action-status';
import { ResourceActionsConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/resource-actions-configuration';
import { ProfileHelper } from 'projects/web-user-profile/src/app/helpers/profile-helper';
import dayJS from 'dayjs';

@RegisterMyProfileComponent(TemplateConstants.MODULE_RESOURCE_CONTAINER)
@Component({
  selector: 'i2a-resource-container',
  templateUrl: './resource-container.component.html',
  styleUrls: ['./resource-container.component.scss']
})
export class ResourceContainerComponent extends ProfileBaseClassComponent<ResourceContainerConfiguration,ReportProfileResourceBase<any>> implements OnInit, OnDestroy, IModuleResourcesContainer<ResourceContainerConfiguration> {
  private components: ComponentRef<IBaseModule>[];
  private modules: IBaseModule[];
  private resourceTemplate: BaseTemplate;

  @Input() public profile: ReportProfileBaseModel;
  @Input() public user: UserModel;
  @Input() public providerId: string;
  @Input() public providerTypeId: string;
  @Input() public providerInstanceId: string;
  @Input() public providerInstanceResourceId: string;
  @Input() public providerInstanceResource: BaseProviderInstanceResourceModel;
  @Input() public beginDate: Date;
  @Input() public endDate: Date;
  @Input() public id: string;
  @Input() public guidedTourKey: string;
  @Input() public groupBySiteicon: string[];

  public isCollapsed: boolean;
  
  @ViewChild(ContainerDirective, { static: true }) public container: ContainerDirective;

  public providerPhotoUrl: string;
  public providerTypeLabel: string;
  public resource: ProviderInstanceResourceSummaryModel;
  public connectedAccount: ConnectedAccountModel;
  public debug: boolean;
  public className: string;
  public hideResource: boolean;
  public hasDeleteAlert: boolean;
  public hasDeleteAlertRecentlyClosed: boolean;
  public deleteAlertDate: Date;
  public hasNotBelongToMe: boolean;
  public hasNotBelongToMeRecentlyClosed: boolean;
  public hasNotBelongToMeDate: Date;
  public hasDeleteAlertFailed: boolean;

  public commentResource: boolean = false;
  public openSite: boolean = false;
  public resourceProfile: ReportProfileResourceBase<any>;
  public resourceActionComponentId;

  public notSharepoint: boolean = true;
  public isSharePointGroupBySiteView: boolean = false;
  
  public hint: string;
  public providerClass: string;

  constructor(
    modalService: NgbModal,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService,
    private providerInstanceResourcesDatasource: ProviderInstanceResourcesDatasourceService,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    private activatedRoute: ActivatedRoute,
    private session: SessionService,
    private templateService: TemplateService
  ) {
    super(toastr, i18Next, modalService);
    this.components = [];
    this.modules = [];
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: ResourceContainerConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel, template: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.providerId = profile.providerId;
    this.providerInstanceId = profile.providerInstanceId;
    this.providerTypeId = resource.providerTypeId;
    this.providerInstanceResourceId = resource.providerInstanceResourceId;
    this.providerInstanceResource = resource;
    this.endDate = profile.endDate;
    this.beginDate = profile.beginDate;
    this.resourceTemplate = template;
    this.id = `resource_${profile.providerId}_${resource.providerTypeId}`
    this.guidedTourKey = configuration.guidedTourKey;
    this.setDeleteOrHasNotBelongToMe();
    this.resourceProfile = ProfileHelper.getResourceProfile(this.profile, this.providerInstanceResource);

    if (this.hasDeleteAlert || this.hasNotBelongToMe || this.hasDeleteAlertRecentlyClosed || this.hasNotBelongToMeRecentlyClosed) {
      this.className = "title-deleted"
    } else {
      this.className = ProviderHelper.GetProviderClassName(resource.providerId, resource.providerTypeId, this.resourceProfile);
    }
    
    if (configuration.isCollapsed !== null && configuration.isCollapsed !== undefined) {
      this.isCollapsed = configuration.isCollapsed
    } 
  }

  setIsAlert(isAlert: boolean) {
    super.setIsAlert(isAlert);

    this.modules.forEach(m => {
      m.setIsAlert(this.isAlert);
    });

  }

  setItems() {
    super.setItems();

    this.setDeleteOrHasNotBelongToMe();
    
    if (this.hasDeleteAlert || this.hasNotBelongToMe || this.hasDeleteAlertRecentlyClosed || this.hasNotBelongToMeRecentlyClosed) {
      this.container.viewContainerRef.clear();
    }
  }

  private setDeleteOrHasNotBelongToMe() {
    var resourceAlerts = this.alerts.filter(a => a.resources.findIndex(r => r.providerInstanceResourceId == this.providerInstanceResource.providerInstanceResourceId && r.providerTypeId == this.providerInstanceResource.providerTypeId) >= 0);
    if (resourceAlerts.length > 0) {
      var alerts = AlertReasonHelper.GetalertsContainsReason(resourceAlerts, AlertConstants.ALERT_INVALID_STATE_REASON_RESOURCE_DELETE, false);
      var alertsRecentlyClosed = AlertReasonHelper.GetalertsContainsReason(resourceAlerts, AlertConstants.ALERT_INVALID_STATE_REASON_RESOURCE_DELETE, true);
      if (alerts.length > 0 || alertsRecentlyClosed.length > 0) {
        this.hasDeleteAlert = alerts.length > 0;
        this.hasDeleteAlertRecentlyClosed = alertsRecentlyClosed.length > 0;
        alerts.push(...alertsRecentlyClosed);
        this.deleteAlertDate = dayJS(alerts[0].reasons[0].creationDate).toDate();
        let terminatedUnderTakenActions = alerts[0].undertakenActions.filter(action => action.kind == Constants.UNDERTAKEN_ACTION_ALERT_REMEDIATION_TERMINATED);
        let failedCount = 0;
        terminatedUnderTakenActions.forEach(terminatedUnderTakenAction => {
          let deserializedAction = JSON.parse(terminatedUnderTakenAction.action);
          if (deserializedAction.Status != AlertRemediationTerminatedActionStatus.Succeed) {
            failedCount += 1;
          }
        });
        this.hasDeleteAlertFailed = failedCount > 0;
      }
      alerts = AlertReasonHelper.GetalertsContainsReason(resourceAlerts, AlertConstants.ALERT_INVALID_STATE_REASON_RESOURCE_NOT_BELONG_TO_ME, false);
      alertsRecentlyClosed = AlertReasonHelper.GetalertsContainsReason(resourceAlerts, AlertConstants.ALERT_INVALID_STATE_REASON_RESOURCE_NOT_BELONG_TO_ME, true);
      if (alerts.length > 0 || alertsRecentlyClosed.length > 0) {
        this.hasNotBelongToMeDate = dayJS(alerts[0].reasons[0].creationDate).toDate();
        this.hasNotBelongToMe = alerts.length > 0;
        this.hasNotBelongToMe = alertsRecentlyClosed.length > 0;
      } else {
        this.hasNotBelongToMe = false;
      }
    } else {
      this.hasDeleteAlert = false;
      this.hasDeleteAlertRecentlyClosed = false;
      this.hasNotBelongToMe = false;
      this.hasNotBelongToMeRecentlyClosed = false;
    }
    if (this.hasDeleteAlert || this.hasNotBelongToMe || this.hasDeleteAlertRecentlyClosed || this.hasNotBelongToMeRecentlyClosed) {
      this.className = "title-deleted";
      this.id = `resource_${this.profile.providerId}_${this.providerInstanceResource.providerTypeId}Disabled`
    }
  }

  ngOnInit() {
    if (this.resourceProfile) {
      this.resource = this.resourceProfile.providerInstanceResource;
      this.providerPhotoUrl = ProviderHelper.GetProviderImage(this.resource.providerId, this.resource.providerTypeId, this.resourceProfile);

      this.getProviderTypeLabel();

    }

    if (this.activatedRoute && this.activatedRoute.queryParams) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.debug = params['debug'];
      });
    }

    this.subscriptions.add(
      this.session.Account$.subscribe(account => {
        if (account != null) {
          this.connectedAccount = account;
        }
      })
    );

    if (this.resourceTemplate) {
      let moduleAction = this.resourceTemplate.modules.find(m => m.id == TemplateConstants.MODULE_DELEGATE_RESOURCE_ACTIONS
        || m.id == TemplateConstants.MODULE_SHAREPOINT_ACTIONS);
      if (moduleAction) {
        this.resourceActionComponentId = moduleAction.id;
        this.commentResource = (<ResourceActionsConfiguration>moduleAction.configuration).commentResource;
        this.openSite = (<ResourceActionsConfiguration>moduleAction.configuration).openSite;
      }
      
      this.resourceTemplate.modules.forEach(module => {
        if (module.isEnabled && !this.hasDeleteAlert && !this.hasNotBelongToMe && !this.hasDeleteAlertRecentlyClosed && !this.hasNotBelongToMeRecentlyClosed) {
          var component = this.templateService.loadComponent<IModule<any>>(this.container.viewContainerRef, module.id);
          if (component) {
            this.components.push(<ComponentRef<any>>component);
            this.modules.push(component.instance);
            this.subscriptions.add(component.instance.onAlertSent.subscribe(e => this.alertSent(e)));
            this.subscriptions.add(component.instance.onHasWarnings.subscribe(e => this.hasWarnings(e)));
            component.instance.initialize(this.user, this.profile, module.configuration, this.alerts, this.providerInstanceResource, this.resourceTemplate);
          }
        }
      });
      
      this.subscriptions.add(
        this.session.isCollapsedAll$.subscribe(isCollapsedAll => {
          if (isCollapsedAll !== null && isCollapsedAll !== undefined) {
            this.isCollapsed = isCollapsedAll;
          }
        })
      );
    }
  }
 
  private getProviderTypeLabel() {
    if (this.resource.providerId === Constants.PROVIDER_ID_MICROSOFT && this.resource.providerTypeId === Constants.PROVIDER_TYPE_INTRANET) {
      
      this.notSharepoint = false;
      
      this.providerTypeLabel = `${this.i18Next.t(ProviderHelper.GetProviderTypeLabel(this.resource.providerId, this.resource.providerTypeId))} ${this.resource.name}`;
      this.hint = this.providerTypeLabel;
      if (this.session.SharePointGroupBySite) {

        this.isSharePointGroupBySiteView = true;
        this.className = this.className +  ' id-site-list';

        let spProfile = (<MicrosoftOfficeReportProfileModel>this.profile)?.sharePointProfiles?.find(r => r.providerInstanceResource.id === this.resource.id);
        var length = spProfile.path?.length ?? 0;
        if (spProfile && length > 0) {
          if (length <= 2) {
            this.providerTypeLabel = spProfile.path[length - 1];
          } else {
            this.providerTypeLabel = `${spProfile.path[length - 2]} - ${spProfile.path[length - 1]}`
          }
          this.hint = spProfile.path.join(" - ");
          this.groupBySiteicon = spProfile.driveId ? ['fad', 'books'] : ['fad', 'list'] 
          this.providerClass = ProviderHelper.GetProviderClassName(this.resource.providerId, this.resource.providerTypeId, this.resourceProfile) + "-icon";
        }
      } 
    } else {
      this.providerTypeLabel = this.i18Next.t(ProviderHelper.GetProviderTypeLabel(this.resource.providerId, this.resource.providerTypeId));
      this.hint = this.providerTypeLabel;
    }
  }


  ngAfterViewInit() {

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  downloadAudit() {
    let filename = `AuditReport_${this.resource.name.replace(/[/\\?%*:|"<>]/g, '-')}_${Date.now()}.xlsx`;
    let model = new AuditReportModel();
    model.beginDate = this.beginDate;
    model.endDate = this.endDate;
    model.resources = [];
    let resource = new BaseProviderInstanceResourceModel();
    resource.providerId = this.resource.providerId;
    resource.providerTypeId = this.resource.providerTypeId;
    resource.providerInstanceId = this.resource.providerInstanceId;
    resource.providerInstanceResourceId = this.resource.providerInstanceResourceId;
    model.resources.push(resource);
    this.subscriptions.add(this.microsoftDatasource.auditReport(model).subscribe(data => { ResourceContainerComponent.downLoadFile(data, "application/ms-excel", filename); }));
  }

  downloadOnePage() {
    let filename = `OnePage_${this.resource.name.replace(/[/\\?%*:|"<>]/g, '-')}_${Date.now()}.pdf`;
    this.subscriptions.add(this.providerInstanceResourcesDatasource.onePageReport(this.providerInstanceResource.providerId, this.providerInstanceResource.providerTypeId, this.providerInstanceResource.providerInstanceId, this.providerInstanceResource.providerInstanceResourceId)
      .subscribe(data => { ResourceContainerComponent.downLoadFile(data, "application/pdf", filename); }));
  }

  public static downLoadFile(response: HttpResponse<Blob>, type: string, filename: string) {
    var blob = new Blob([response.body], { type: type });
    var url = window.URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }

  hasWarnings(event): void {
    setTimeout(() => {
      this.isCollapsed = false; 
    });
    this.onHasWarnings.emit(event);    
  }

  showOpenAlerts() {
    const modalRef = this.modalService.open(DisplayAlertsReasonsModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.alerts = this.alerts;
  }
  
  updateAlerts(alerts: AlertInvalidStateFeaturedModel[]) {
    super.updateAlerts(alerts);
    this.components.forEach(c => {
      c.instance.updateAlerts(alerts);
    });
  }
  
}

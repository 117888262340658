<div class="form-group delegate-container">
  <div class="no-gutters">
    <table class="table-sm id-table mt-2">
      <tbody>
        <tr>
          <td scope="row text-center" class="cell-checkbox">
            <i2a-input-checkbox [model]="allChecked()" (modelChange)="allCheckedChange($event) "
              [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
              [valueUnChecked]="false">
            </i2a-input-checkbox>
          </td>
          <td scope="row" class="text-break w-100 delegate-title">
            <div>{{ allAccessAndPermissionsTitle}}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="form-group col-12 pl-4" *ngIf="folders && hasAccesses() && !allChecked()">
  <i2a-short-title-bar [isCollapsed]="permissionsAndAccessesCollapsed" [hasAlert]="false">
    <ng-container title>{{'profile.accesses' | i18next }}</ng-container>
    <ng-container body>
      <table class="table table-sm id-table mb-0">
        <tbody>
          <tr *ngIf="validation.allFolders">
            <td scope="row text-center" class="cell-checkbox">
              <i2a-input-checkbox [model]="validation.allFolders" (modelChange)="allFoldersChecked($event) "
                [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                [valueUnChecked]="false">
              </i2a-input-checkbox>
            </td>
            <td scope="row">
              {{'profile.provider.microsoft.sharePoint.allFolders' | i18next }}
            </td>
          </tr>
          <ng-container *ngIf="!validation.allFolders">
            <tr *ngFor="let folder of folders">
              <td scope="row text-center" class="cell-checkbox">
                <i2a-input-checkbox [model]="folder.checked" (modelChange)="folderChecked($event, folder)"
                  [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                  [valueUnChecked]="false"> </i2a-input-checkbox>
              </td>
              <td scope="row" class="text-break">
                {{folder.name}}
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ng-container>
  </i2a-short-title-bar>
</div>

<div class="form-group col-12 pl-4" *ngIf="driveItemsPermissions && hasPermissions() && !allChecked()">
  <i2a-short-title-bar [isCollapsed]="permissionsAndAccessesCollapsed" [hasAlert]="false">
    <ng-container title>{{'profile.permissions' | i18next }}</ng-container>
    <ng-container body>
      <table class="table table-sm id-table mb-0">
        <tbody>
          <tr *ngIf="validation.permissions.allPaths">
            <td scope="row text-center" class="cell-checkbox">
              <i2a-input-checkbox [model]="validation.permissions.allPaths"
                (modelChange)="allPermissionsChecked($event) " [classNameChecked]="'btn-outline-danger'"
                [className]="'btn-outline-dark'" [valueChecked]="true" [valueUnChecked]="false">
              </i2a-input-checkbox>
            </td>
            <td scope="row w-100">
              {{'profile.provider.microsoft.sharePoint.allPermissions' | i18next }}
            </td>
          </tr>
          <ng-container *ngIf="!validation.permissions.allPaths">
            <tr *ngFor="let permission of driveItemsPermissions">
              <td scope="row text-center" class="cell-checkbox">
                <i2a-input-checkbox [model]="permission.checked" (modelChange)="permissionChecked($event, permission)"
                  [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                  [valueUnChecked]="false">
                </i2a-input-checkbox>
              </td>
              <td scope="row" class="text-break">
                {{permission.name}}
                <i2a-share-point-permissions-roles [roles]="permission.roles"></i2a-share-point-permissions-roles>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ng-container>
  </i2a-short-title-bar>
</div>
<i2a-profile-card [id]="id" [title]="'profile.provider.microsoft.exchange.devices.deviceList' | i18next"
  (onWarnClick)="warnClick($event)" [hasAlert]="hasAlert" [hasAlertRecentlyClosed]="hasAlertRecentlyClosed"
  [warningLevel]="warningLevel">

  <ng-container picto>
    <i2a-badge-icon>
      <ng-container icon>
        <fa-icon [icon]="['fal', 'mobile']" size="lg"></fa-icon>
      </ng-container>
    </i2a-badge-icon>
  </ng-container>

  <ng-container content>
    <div class="container p-0 delegate-devices" *ngIf="itemsNotRegistered">
      <div class="row no-gutters">
        <div class="col-12 delegate-device p-1">
          <table class="table table-sm table-bordered id-table device-table">
            <tbody>
              <tr *ngFor="let device of itemsNotRegistered">
                <td scope="row" [ngClass]="{'has-alert': device.hasAlert, 'has-alert-recently-closed': device.hasAlertRecentlyClosed}">
                  <img [src]="device.image" width="24" height="24" />
                  {{device.name}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-container>

</i2a-profile-card>
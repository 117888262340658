import { DriveItemResyncModel } from '../../models/microsoft/sharepoint/drive-item-resync-model';
import { MicrosoftOfficeAuditResourcesModel } from '../../models/microsoft/microsoft-office-audit-resources-model';
import { MicrosoftOfficeImportUsersModel } from '../../models/microsoft/microsoft-office-import-users-model';
import { MicrosoftOfficeLearningProtectResourcesModel } from '../../models/microsoft/microsoft-office-learning-protect-resources-model';
import { MicrosoftOfficeOperationLearningProtectionContextModel } from '../../models/microsoft/microsoft-office-operation-learning-protection-context-model';
import { MicrosoftOfficeOperationPermanentAuditContextModel } from '../../models/microsoft/microsoft-office-operation-permanent-audit-context-model';
import { MicrosoftOfficeOperationProtectionContextModel } from '../../models/microsoft/microsoft-office-operation-protection-context-model';
import { MicrosoftOfficeOperationStopPermanentAuditContextModel } from '../../models/microsoft/microsoft-office-operation-stop-permanent-audit-context-model';
import { MicrosoftOfficeOperationUnProtectionContextModel } from '../../models/microsoft/microsoft-office-operation-un-protection-context-model';
import { MicrosoftOfficeOperationUpdateProtectionContextModel } from '../../models/microsoft/microsoft-office-operation-update-protection-context-model';
import { MicrosoftOfficePermanentAuditResourcesModel } from '../../models/microsoft/microsoft-office-permanent-audit-resources-model';
import { MicrosoftOfficeProtectResourcesModel } from '../../models/microsoft/microsoft-office-protect-resources-model';
import { MicrosoftOfficeStopAuditResourcesModel } from '../../models/microsoft/microsoft-office-stop-audit-resources-model';
import { MicrosoftOfficeStopPermanentAuditResourcesModel } from '../../models/microsoft/microsoft-office-stop-permanent-audit-resources-model';
import { MicrosoftOfficeUnProtectResourcesModel } from '../../models/microsoft/microsoft-office-un-protect-resources-model';
import { MicrosoftOfficeUpdateProtectResourcesModel } from '../../models/microsoft/microsoft-office-update-protect-resources-model';
import { OneDriveOnePageModel } from '../../orchestration/microsoft/onedrive/reports/models/one-drive-one-page-model';
import { ProviderPoliciesModel } from '../../models/provider-policies-model';
import { SharePointReportProfileShares } from '../../models/microsoft/sharepoint/profile/share-point-report-profile-shares';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatasourceService } from '../../../data/datasource.service';

@Injectable({
	providedIn: 'root'
})
export class OneDriveDatasourceService {

private route: string = "providers/microsoft/oneDrive";

	constructor(private datasource: DatasourceService) {
		console.log("Init OneDriveDatasourceService");
	}

	public getProviderPolicies(providerInstanceId: string, useCache: boolean = false, throwError: boolean = true): Observable<ProviderPoliciesModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/provider-policies`;

		return this.datasource.get<ProviderPoliciesModel>(partialUrl, { }, useCache, throwError);
	}

	public protectOneDrive(providerInstanceId: string, model: MicrosoftOfficeProtectResourcesModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/protect`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public unProtect(providerInstanceId: string, model: MicrosoftOfficeUnProtectResourcesModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/un-protect`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public updateProtect(providerInstanceId: string, model: MicrosoftOfficeUpdateProtectResourcesModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/update-protect`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public startLearningProtect(providerInstanceId: string, model: MicrosoftOfficeLearningProtectResourcesModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/start-learning-protect`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public stopLearningProtect(providerInstanceId: string, model: MicrosoftOfficeLearningProtectResourcesModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/stop-learning-protect`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public getOneDrivePermanentAuditOperation(providerInstanceId: string, operationId: string, useCache: boolean = false, throwError: boolean = true): Observable<MicrosoftOfficeOperationPermanentAuditContextModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/permanent-audit/${operationId}`;

		return this.datasource.get<MicrosoftOfficeOperationPermanentAuditContextModel>(partialUrl, { }, useCache, throwError);
	}

	public getStopOneDrivePermanentAuditOperation(providerInstanceId: string, operationId: string, useCache: boolean = false, throwError: boolean = true): Observable<MicrosoftOfficeOperationStopPermanentAuditContextModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/stop-permanent-audit/${operationId}`;

		return this.datasource.get<MicrosoftOfficeOperationStopPermanentAuditContextModel>(partialUrl, { }, useCache, throwError);
	}

	public stopPermanentAudit(providerInstanceId: string, model: MicrosoftOfficeStopPermanentAuditResourcesModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/stop-permanent-audit`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public premanentAuditOneDrive(providerInstanceId: string, model: MicrosoftOfficePermanentAuditResourcesModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/permanent-audit`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public getOneDriveProtectOperation(providerInstanceId: string, operationId: string, useCache: boolean = false, throwError: boolean = true): Observable<MicrosoftOfficeOperationProtectionContextModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/protect/${operationId}`;

		return this.datasource.get<MicrosoftOfficeOperationProtectionContextModel>(partialUrl, { }, useCache, throwError);
	}

	public getOneDriveUnProtectOperation(providerInstanceId: string, operationId: string, useCache: boolean = false, throwError: boolean = true): Observable<MicrosoftOfficeOperationUnProtectionContextModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/un-protect/${operationId}`;

		return this.datasource.get<MicrosoftOfficeOperationUnProtectionContextModel>(partialUrl, { }, useCache, throwError);
	}

	public getOneDriveUpdateProtectOperation(providerInstanceId: string, operationId: string, useCache: boolean = false, throwError: boolean = true): Observable<MicrosoftOfficeOperationUpdateProtectionContextModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/update-protect/${operationId}`;

		return this.datasource.get<MicrosoftOfficeOperationUpdateProtectionContextModel>(partialUrl, { }, useCache, throwError);
	}

	public getLearningProtectOperation(providerInstanceId: string, operationId: string, useCache: boolean = false, throwError: boolean = true): Observable<MicrosoftOfficeOperationLearningProtectionContextModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/learning-protect/${operationId}`;

		return this.datasource.get<MicrosoftOfficeOperationLearningProtectionContextModel>(partialUrl, { }, useCache, throwError);
	}

	public importUsers(providerInstanceId: string, model: MicrosoftOfficeImportUsersModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/import-users`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public audit(providerInstanceId: string, model: MicrosoftOfficeAuditResourcesModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/audit`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public stopAudit(providerInstanceId: string, model: MicrosoftOfficeStopAuditResourcesModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/stop-audit`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public onePage(providerInstanceId: string, providerInstanceResourceId: string, beginDate: Date = null, endDate: Date = null, useCache: boolean = false, throwError: boolean = true): Observable<OneDriveOnePageModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/${providerInstanceResourceId}/one-page`;

		return this.datasource.get<OneDriveOnePageModel>(partialUrl, {beginDate: String(beginDate),endDate: String(endDate) }, useCache, throwError);
	}

	public profileShares(providerInstanceId: string, providerInstanceResourceId: string, useCache: boolean = false, throwError: boolean = true): Observable<SharePointReportProfileShares> {
		let partialUrl = `${this.route}/${providerInstanceId}/${providerInstanceResourceId}/profile-shares`;

		return this.datasource.get<SharePointReportProfileShares>(partialUrl, { }, useCache, throwError);
	}

	public resyncDriveItem(providerInstanceId: string, providerInstanceResourceId: string, model: DriveItemResyncModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/${providerInstanceResourceId}/resync-drive-item`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

}

import { Injectable } from '@angular/core';
import { Constants, ReportProfileResourceBase, ReportProfileBaseModel, BaseProviderInstanceResourceModel, MicrosoftOfficeReportProfileModel, ZimbraReportProfileModel } from '@i2a/web-api-client';
import { SharePointReportProfile } from '@i2a/web-api-client';
import { MenuItem } from '../models/menu-item';

@Injectable({
  providedIn: 'root'
})
export class ProviderHelper {

  constructor() {
  }

  public static GetResource(profile: ReportProfileBaseModel, resource: BaseProviderInstanceResourceModel): ReportProfileResourceBase<any> {

    if (profile.providerId === Constants.PROVIDER_ID_MICROSOFT) {
      var msProfile = (<MicrosoftOfficeReportProfileModel>profile);
      if (resource.providerTypeId === Constants.PROVIDER_TYPE_MAILING) {
        return msProfile.exchangeProfiles.find(r => r.providerInstanceResource.providerInstanceId == resource.providerInstanceId && r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
      } else if (resource.providerTypeId === Constants.PROVIDER_TYPE_PERSONAL_STORAGE) {
        return msProfile.oneDriveProfiles.find(r => r.providerInstanceResource.providerInstanceId == resource.providerInstanceId && r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
      } else if (resource.providerTypeId === Constants.PROVIDER_TYPE_INTRANET) {
        return msProfile.sharePointProfiles.find(r => r.providerInstanceResource.providerInstanceId == resource.providerInstanceId && r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
      } else if (resource.providerTypeId === Constants.PROVIDER_TYPE_GENERIC) {
        return msProfile.azureADProfiles.find(r => r.providerInstanceResource.providerInstanceId == resource.providerInstanceId && r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
      } else if (resource.providerTypeId === Constants.PROVIDER_TYPE_FILE_SHARE) {
        return msProfile.fileShareProfiles.find(r => r.providerInstanceResource.providerInstanceId == resource.providerInstanceId && r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
      }
  }
    else if (profile.providerId === Constants.PROVIDER_ID_ZIMBRA) {
      var zimbraProfile = (<ZimbraReportProfileModel>profile);
      if (resource.providerTypeId === Constants.PROVIDER_TYPE_MAILING) {
        return zimbraProfile.mailingProfiles.find(r => r.providerInstanceResource.providerInstanceId == resource.providerInstanceId && r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);
      }
    }
  }


  public static GetProviderImage(providerId: string, providerTypeId: string, resourceProfile: ReportProfileResourceBase<any>): string {
    let pId = providerId.toLowerCase();
    let ptId = providerTypeId.toLocaleLowerCase();
    if (pId === Constants.PROVIDER_ID_MICROSOFT) {
      if (ptId === Constants.PROVIDER_TYPE_MAILING) {
        return "assets/pictos/providers/MSexchange.svg";
      } else if (ptId === Constants.PROVIDER_TYPE_PERSONAL_STORAGE) {
        return "assets/pictos/providers/onedrive.svg";
      } else if (ptId === Constants.PROVIDER_TYPE_INTRANET) {
        var profile = <SharePointReportProfile>resourceProfile;
        var hasTeam = profile?.o365Groups?.length > 0 ? profile.o365Groups[0].hasTeam : false;
        var hasYammer = profile?.o365Groups?.length > 0 ? profile.o365Groups[0].hasYammer : false;
        if (resourceProfile == null) {
          return "assets/pictos/providers/sharepoint-teams.svg";
        } else if (!hasTeam && !hasYammer) {
          return "assets/pictos/providers/sharepoint.svg";
        } else if (hasTeam) {
          return "assets/pictos/providers/teams.svg";
        } else if (hasYammer) {
          return "assets/pictos/providers/yammer.svg";
        }
      } else if (ptId === Constants.PROVIDER_TYPE_GENERIC) {
        return "assets/pictos/providers/adfs.svg";
      } else if (ptId === Constants.PROVIDER_TYPE_FILE_SHARE) {
        return "assets/pictos/providers/fileshare.svg";
      }
    } else if (pId === Constants.PROVIDER_ID_DROPBOX) {
      return "assets/pictos/providers/dropbox.svg";
    } else if (pId === Constants.PROVIDER_ID_BOX) {
      return "assets/pictos/providers/dropbox.svg";
    } else if (pId === Constants.PROVIDER_ID_SLACK) {
      return "assets/pictos/providers/slack.svg";
    } else if (pId === Constants.PROVIDER_ID_OPENTEXT) {
      return "assets/pictos/providers/dropbox.svg";
    } else if (pId === Constants.PROVIDER_ID_INWEBO) {
      return "assets/pictos/providers/inwebo.png";
    } else if (pId === Constants.PROVIDER_ID_ZIMBRA) {
      return "assets/pictos/providers/Zimbra.svg";
    } else if (pId === Constants.PROVIDER_ID_SAP) {
      return "assets/pictos/providers/SAP2.svg";
    } else if (pId === Constants.PROVIDER_ID_IDECSI) {
      return "assets/logos/logo_black.svg";
    } else {
      return "assets/logos/default.svg";
    }
  }

  public static GetProviderClassName(providerId: string, providerTypeId: string, resourceProfile: ReportProfileResourceBase<any>): string {
    let pId = providerId.toLowerCase();
    let ptId = providerTypeId.toLocaleLowerCase();

    if (pId === Constants.PROVIDER_ID_MICROSOFT) {
      if (ptId === Constants.PROVIDER_TYPE_MAILING) {
        return "title-exchange";
      } else if (ptId === Constants.PROVIDER_TYPE_PERSONAL_STORAGE) {
        return "title-one-drive";
      } else if (ptId === Constants.PROVIDER_TYPE_INTRANET) {
        var profile = <SharePointReportProfile>resourceProfile;
        var hasTeam = profile?.o365Groups?.length > 0 ? profile.o365Groups[0].hasTeam : null;
        var hasYammer = profile?.o365Groups?.length > 0 ? profile.o365Groups[0].hasYammer : null;
        if (hasTeam == null && hasYammer == null) {
          return "title-share-point";
        } else if (!hasTeam && !hasYammer) {
          return "title-share-point";
        } else if (hasTeam) {
          return "title-teams";
        } else if (hasYammer) {
          return "title-yammer";
        }
      } else if (ptId === Constants.PROVIDER_TYPE_GENERIC) {
        return "title-azure-ad";
      } else if (providerTypeId === Constants.PROVIDER_TYPE_FILE_SHARE) {
        return "title-file-share";
      }
    } else if (pId === Constants.PROVIDER_ID_DROPBOX) {
      return "title-drop-box";
    } else if (pId === Constants.PROVIDER_ID_BOX) {
      return "title-box";
    } else if (pId === Constants.PROVIDER_ID_SLACK) {
      return "title-slack";
    } else if (pId === Constants.PROVIDER_ID_OPENTEXT) {
      return "title-open-text";
    } else if (pId === Constants.PROVIDER_ID_INWEBO) {
      return "title-n-webo";
    } else if (pId === Constants.PROVIDER_ID_ZIMBRA) {
      return "title-zimbra-mailing";
    } else if (pId === Constants.PROVIDER_ID_SAP) {
      return "title-sap";
    } else if (pId === Constants.PROVIDER_ID_IDECSI) {
      return "title-idecsi";
    } else {
      return "title-provider";
    }
  }

  public static GetProviderTypeLabel(providerId: string, providerTypeId: string): string {
    let pId = providerId.toLowerCase();
    let ptId = providerTypeId.toLocaleLowerCase();

    if (pId === Constants.PROVIDER_ID_MICROSOFT) {
      if (ptId === Constants.PROVIDER_TYPE_MAILING) {
        return "provider.source.microsoft.type.mailing.resourceName";
      } else if (ptId === Constants.PROVIDER_TYPE_PERSONAL_STORAGE) {
        return "provider.source.microsoft.type.personalStorage.resourceName";
      } else if (ptId === Constants.PROVIDER_TYPE_INTRANET) {
        return "provider.source.microsoft.type.intranet.resourceName";
      } else if (ptId === Constants.PROVIDER_TYPE_GENERIC) {
        return "provider.source.microsoft.type.generic.resourceName";
      } else if (ptId === Constants.PROVIDER_TYPE_FILE_SHARE) {
        return "provider.source.microsoft.type.fileShare.resourceName";
      }
    } else if (pId === Constants.PROVIDER_ID_ZIMBRA) {
      return "provider.source.zimbra.type.mailing.resourceName";
    } else if (pId === Constants.PROVIDER_ID_SAP) {
      return "provider.source.sap.type.generic.resourceName";
    }
    else {
      if (ptId === Constants.PROVIDER_TYPE_MAILING) {
        return "provider.type.mailing";
      } else if (ptId === Constants.PROVIDER_TYPE_PERSONAL_STORAGE) {
        return "provider.type.personalStorage";
      } else if (ptId === Constants.PROVIDER_TYPE_INTRANET) {
        return "provider.type.intranet";
      } else if (ptId === Constants.PROVIDER_TYPE_GENERIC) {
        return "provider.type.generic";
      }
    }
  }

  public static GetProviderTypeMenuLabel(providerId: string, providerTypeId: string, items: MenuItem[]): string {
    let pId = providerId.toLowerCase();
    let ptId = providerTypeId.toLocaleLowerCase();

    if (pId === Constants.PROVIDER_ID_MICROSOFT) {
      if (ptId === Constants.PROVIDER_TYPE_MAILING) {
        return "provider.source.microsoft.type.mailing.menuName";
      } else if (ptId === Constants.PROVIDER_TYPE_PERSONAL_STORAGE) {
        return "provider.source.microsoft.type.personalStorage.menuName";
      } else if (ptId === Constants.PROVIDER_TYPE_INTRANET) {
        return items?.some((item: MenuItem) => item.providerInstanceResourceIds?.length > 0) ? "provider.source.microsoft.type.intranet.menuNameSite" : "provider.source.microsoft.type.intranet.menuName";
      } else if (ptId === Constants.PROVIDER_TYPE_GENERIC) {
        return "provider.source.microsoft.type.generic.menuName";
      } else if (ptId === Constants.PROVIDER_TYPE_FILE_SHARE) {
        return "provider.source.microsoft.type.fileShare.menuName";
      }
    } else if (pId === Constants.PROVIDER_ID_ZIMBRA) {
      return "provider.source.zimbra.type.mailing.menuName";
    } else if (pId === Constants.PROVIDER_ID_SAP) {
      return "provider.source.sap.type.generic.menuName";
    }
    else {
      if (ptId === Constants.PROVIDER_TYPE_MAILING) {
        return "provider.type.mailing";
      } else if (ptId === Constants.PROVIDER_TYPE_PERSONAL_STORAGE) {
        return "provider.type.personalStorage";
      } else if (ptId === Constants.PROVIDER_TYPE_INTRANET) {
        return "provider.type.intranet";
      } else if (ptId === Constants.PROVIDER_TYPE_GENERIC) {
        return "provider.type.generic";
      }
    }
  }

  public static GetSharePointProviderTypeLabel(profile: SharePointReportProfile, isSite: boolean): string {
    if (isSite) {
      if (profile.o365Groups?.length > 0 ? profile.o365Groups[0].hasTeam : false) {
        return "profile.provider.microsoft.sharePoint.warning.teams";
      } else if (profile.o365Groups?.length > 0 ? profile.o365Groups[0].hasYammer : false)
      {
        return "profile.provider.microsoft.sharePoint.warning.yammer";
      } else
      {
        return "profile.provider.microsoft.sharePoint.warning.site";
      }
    } else if (profile.driveId) {
      return "profile.provider.microsoft.sharePoint.warning.library";
    } else {
      return "profile.provider.microsoft.sharePoint.warning.list";
    }
  }

}


<i2a-base-modal [modalTitle]="title" [activateFullScreen]="activateFullScreen">
    <ng-container body>
        <div class="container-fluid">
            <div class="row">
                <template #header i2a-container></template>
                <ng-content select="[header]"></ng-content>
                <div class="col-12 mt-3 mb-3">
                    <template #body i2a-container></template>
                    <ng-content select="[body]"></ng-content>
                    <p *ngIf="question != ''"><strong>{{question}}</strong></p>
                    <p *ngIf="information != ''">{{information}}</p>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container footer>
        <div class="m-auto">
            <button type="button" class="btn btn-outline-dark" [disabled]="disableButtons"
                (click)="modal.dismiss('cancel click')">{{cancel}}</button>
            <button type="button" ngbAutofocus [disabled]="disableButtons" [ngClass]="btnOKClasses" (click)="modal.close('Ok click')">{{ok}}</button>
        </div>
    </ng-container>
</i2a-base-modal>
import { IConfigService, IWebConfig } from './config.service';
import { ITranslationService, I18NextLoadResult} from 'angular-i18next';
import HttpApi from "i18next-http-backend";


export function appInit(configService: IConfigService<IWebConfig>, i18next: ITranslationService) {
  return async () => {
    console.log("Init i18Next");
    let result = await configService.loadConfigFile();
    if (result) {
      let promise: Promise<I18NextLoadResult> = i18next.use(HttpApi)
        .init(configService.getI18NextConfig());
      console.log("i18Next return promise");
      return promise;
    }
    else {
      return new Promise((resolve, reject) => {
        reject();
      });
    }
  };
}
<i2a-profile-alert-base-modal [manualDescriptionKey]="'applyCorrection.rulesComputedDelegates.manual.description'"
                              [manualLinkKey]="'applyCorrection.rulesComputedDelegates.manual.link'"
                              [automaticDescriptionKey]="'applyCorrection.rulesComputedDelegates.automatic.description'"
                              [ignoreCommentToSend]="session.IgnoreCommentToSend"
                              [validation]="validation" [canSend]="canSend"
                              [modalTitle]="'profile.confirmationOfInformationTitle' | i18next "
                              [resourceProfile]="resourceProfile">

  <ng-container header-picto>
    <i2a-delegate-photo [isAnonymous]="delegate.logonUserId == 'Anonymous'"
      [isDefault]="delegate.logonUserId == 'Default'" [isApplication]="delegate.logonUserId == 'Application'"
      [pir]="delegate.providerInstanceResource" [piu]="delegate.providerInstanceUser" [isExternalUser]="false">
    </i2a-delegate-photo>
  </ng-container>

  <ng-container header-title>
    {{name}}<br>
  </ng-container>

  <ng-container body>
    <div class="container-fluid" *ngIf="configuration.viewDetailFolders && folders.length > 0">
      <div class="row pt-3">
        <div class="col-12 pl-3">

          <div class="form-group delegate-container">
            <table class="table-sm id-table mt-2">
              <tbody>
                <tr>
                  <td scope="row text-center" class="cell-checkbox">
                    <i2a-input-checkbox [(model)]="validation.allFolders" (modelChange)="allFoldersChecked($event)"
                    [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                    [valueUnChecked]="false">
                  </i2a-input-checkbox>
                      </td>
                  <td scope="row" class="text-break w-100 delegate-title">
                    {{'profile.rulesComputedDelegates.allFolders' | i18next }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>

        <div class="form-group col-12 pl-4" *ngIf="folders && !validation.allFolders">
          <h4>{{'profile.rulesComputedDelegates.specificFolders' | i18next }}</h4>
          <table class="table-sm id-table">
            <tbody>
              <tr *ngFor="let folder of folders">
                <td scope="row">
                  <i2a-input-checkbox [(model)]="folder.checked" (modelChange)="folderChecked($event, folder)"
                    [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                    [valueUnChecked]="false"> </i2a-input-checkbox>
                </td>
                <td scope="row" class="text-break">
                  {{folder.name}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="container-fluid" *ngIf="configuration.viewDetailKinds">
      <div class="row pt-3">
        <div class="form-group col-12" *ngIf="kinds">
          <h4>{{'profile.rulesComputedDelegates.providerAuditResourceKind' | i18next }}</h4>
          <table class="table-sm id-table">
            <tbody>
              <tr *ngFor="let kind of kinds">
                <td scope="row">
                  <i2a-input-checkbox [(model)]="kind.checked" (modelChange)="kindChecked($event, kind)"
                    [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                    [valueUnChecked]="false"> </i2a-input-checkbox>
                </td>
                <td scope="row" class="text-break">
                  {{kind.name}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="container-fluid"
      *ngIf="!configuration.viewDetailKinds && !configuration.viewDetailFolders && configuration.viewIconBox">
      <div class="row pt-2" *ngIf="IsInbox || IsCalendar || IsFolders || IsInstantMessaging">

        <!-- <div class="col-12 pb-1 id-detail">{{ "profile.provider.microsoft.exchange.accesses" | i18next  }} </div> -->
        <div class="form-group col-6 col-sm-4" *ngIf="IsInbox && isMicrosoft && !isOnedrive">
          <div class="permission">
            <div class="permission-type">
              <fa-icon [icon]="['fal', 'envelope']" size="2x" style="vertical-align: -webkit-baseline-middle"></fa-icon>
            </div>
            <div class="permission-checkbox ml-1">
              <i2a-input-checkbox [(model)]="validation.inboxIsValid" [classNameChecked]="'btn-outline-danger'"
                [className]="'btn-outline-dark'" [valueChecked]="false" [valueUnChecked]="true"
                (modelChange)="setCanSend()"> </i2a-input-checkbox>
            </div>
          </div>
        </div>

        <div class="form-group col-6 col-sm-4" *ngIf="IsCalendar && isMicrosoft && !isOnedrive">
          <div class="permission">
            <div class="permission-type">
              <fa-icon [icon]="['fal', 'calendar-alt']" size="2x" style="vertical-align: -webkit-baseline-middle">
              </fa-icon>
            </div>
            <div class="permission-checkbox ml-1">
              <i2a-input-checkbox [(model)]="validation.calendarIsValid" [classNameChecked]="'btn-outline-danger'"
                [className]="'btn-outline-dark'" [valueChecked]="false" [valueUnChecked]="true"
                (modelChange)="setCanSend()"> </i2a-input-checkbox>
            </div>
          </div>
        </div>

        <div class="form-group col-6 col-sm-4" *ngIf="IsInstantMessaging && isOneDrive">
          <div class="permission">
            <div class="permission-type">
              <fa-icon [icon]="['fal', 'comment-alt']" size="2x" style="vertical-align: -webkit-baseline-middle">
              </fa-icon>
            </div>
            <div class="permission-checkbox ml-1">
              <i2a-input-checkbox [(model)]="validation.instantMessagingShareIsValid"
                [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="false"
                [valueUnChecked]="true" (modelChange)="setCanSend()"> </i2a-input-checkbox>
            </div>
          </div>
        </div>

        <div class="form-group col-6 col-sm-4" *ngIf="IsFolders && isMicrosoft">
          <div class="permission">
            <div class="permission-type">
              <fa-icon [icon]="['fal', 'folder']" size="2x" style="vertical-align: -webkit-baseline-middle"></fa-icon>
            </div>
            <div class="permission-checkbox ml-1">
              <i2a-input-checkbox [(model)]="validation.folderIsValid" [classNameChecked]="'btn-outline-danger'"
                [className]="'btn-outline-dark'" [valueChecked]="false" [valueUnChecked]="true"
                (modelChange)="setCanSend()"> </i2a-input-checkbox>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="container-fluid" *ngIf="configuration && configuration.displayRegisteredDevices && devices.length > 0">
      <div class="row pt-1">
        <div class="form-group col-12 pb-1 id-detail">{{ "profile.rulesComputedDelegates.deviceList" | i18next  }}
        </div>
        <div class="form-group col">
          <table class="table-sm id-table">
            <tbody>
              <tr *ngFor="let device of devices">
                <td scope="row">
                  <i2a-input-checkbox [(model)]="device.checked" (modelChange)="checked($event, device)"
                    [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                    [valueUnChecked]="false"> </i2a-input-checkbox>
                </td>
                <td scope="row" class="text-break">
                  {{device.name}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </ng-container>

</i2a-profile-alert-base-modal>

export enum ExchangeReportProfileFolderRight {
  None = 0,
  LimitedRead = 1,
  FolderVisible = 2,
  Read = 7,
  Create = 32,
  LimitedUpdate = 64,
  Update = 448,
  LimitedDelete = 512,
  Delete = 3584
}

import { Component, OnInit, Input, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProviderAuditResourceKind, ReportProfileRulesComputedDelegate, Constants, OneDriveReportProfileRulesComputedDelegate, BaseProviderInstanceResourceModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { RulesComputedDelegateConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/rules-computed-delegate-configuration';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ProviderHelper } from 'projects/web-user-profile/src/app/helpers/provider-helper';

@Component({
  selector: 'i2a-rules-computed-delegate-details-modal',
  templateUrl: './rules-computed-delegate-details-modal.component.html',
  styleUrls: ['./rules-computed-delegate-details-modal.component.scss']
})
export class RulesComputedDelegateDetailsModalComponent implements OnInit {
  @Input() public providerInstanceResource: BaseProviderInstanceResourceModel;
  @Input() public delegate: ReportProfileRulesComputedDelegate;
  @Input() public configuration: RulesComputedDelegateConfiguration;
  
  @Input() public resourceProfile: ReportProfileResourceBase<any>;
  
  
  public name: string;
  public folders: string[];
  public kindNames: string[];
  public kindIds: number[];
  public providerTypeLabel: string;
  public providerPhotoUrl: any;

  public get isMicrosoft(): boolean {
    return this.providerInstanceResource.providerId == Constants.PROVIDER_ID_MICROSOFT;
  }

  public get isZimbra(): boolean {
    return this.providerInstanceResource.providerId == Constants.PROVIDER_ID_ZIMBRA;
  }

  public get isOneDrive(): boolean {
    return this.isMicrosoft && this.providerInstanceResource.providerTypeId == Constants.PROVIDER_TYPE_PERSONAL_STORAGE;
  }

  constructor(
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService,
    public activeModal: NgbActiveModal
  ) {
    this.kindNames = [];
    this.kindIds = [];
    this.folders = [];
  }

  ngOnInit() {
    if (this.delegate) {
      for (var enumMember in ProviderAuditResourceKind) {
        var enumMemberInt = parseInt(enumMember, 10);
        if (enumMemberInt >= 0) {
          if (this.configuration.kindToFilter && this.configuration.kindToFilter.indexOf(ProviderAuditResourceKind[enumMember]) == -1) {
            this.kindNames.push(ProviderAuditResourceKind[enumMember]);
            this.kindIds.push(enumMemberInt);
          }
        }
      }
      if (this.isOneDrive) {
        (this.delegate as OneDriveReportProfileRulesComputedDelegate).instantMessagingFolders.forEach(d=> this.folders.push(d));
      }
      this.delegate.resourceFolders.forEach(d=> this.folders.push(d));
      this.folders.sort();
      if (!this.delegate.isNameKey) {
        this.name = this.delegate.name;
      }
      else {
        this.name = this.i18Next.t(this.delegate.name);
      }
      
    if (this.resourceProfile) {
      if (this.resourceProfile.providerInstanceResource.providerId === Constants.PROVIDER_ID_MICROSOFT && this.resourceProfile.providerInstanceResource.providerTypeId === Constants.PROVIDER_TYPE_INTRANET) {
        this.providerTypeLabel = `${this.i18Next.t(ProviderHelper.GetProviderTypeLabel(this.resourceProfile.providerInstanceResource.providerId, this.resourceProfile.providerInstanceResource.providerTypeId))} ${this.resourceProfile.providerInstanceResource.name}`;
      } else {
        this.providerTypeLabel = this.i18Next.t(ProviderHelper.GetProviderTypeLabel(this.resourceProfile.providerInstanceResource.providerId, this.resourceProfile.providerInstanceResource.providerTypeId));
      }
      this.providerPhotoUrl = ProviderHelper.GetProviderImage(this.resourceProfile.providerInstanceResource.providerId, this.resourceProfile.providerInstanceResource.providerTypeId, this.resourceProfile);
    }
    }
  }

  public hasFlag(resourceKind: ProviderAuditResourceKind, flag: ProviderAuditResourceKind): boolean {
    return (resourceKind & flag) == flag;
  }

  close(event) {
    this.activeModal.close(event);
  }
}

import { BaseProviderInstanceResourceModel } from '../base-provider-instance-resource-model';
import { ChangeResult } from '../change-result';
import { ReportProfileBaseModel } from '../profile/report-profile-base-model';
import { ReportProfileRulesComputedDelegate } from '../profile/report-profile-rules-computed-delegate';
import { ZimbraMailingReportProfile } from '../zimbra/mailing/profile/zimbra-mailing-report-profile';
import { ZimbraMailingReportProfileChangesModel } from '../zimbra/mailing/profile/zimbra-mailing-report-profile-changes-model';

export class ZimbraReportProfileModel extends ReportProfileBaseModel {
  mailingProfiles: ZimbraMailingReportProfile[];
  mailingResourceChanges: ChangeResult<BaseProviderInstanceResourceModel>;
  mailingProfileChanges: ZimbraMailingReportProfileChangesModel[];
}

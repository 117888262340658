<i2a-profile-card [id]="id" *ngIf="group"
  [viewMoreDetails]="group && group.members.length > 0 || memberDriveItems && memberDriveItems.length > 0 "
  (onViewMoreDetails)="openDetails($event)" [title]="group.title"
  [subTitle]="'profile.provider.microsoft.sharePoint.subTitle.spGroup' | i18next" (onWarnClick)="warnClick($event)"
  [hasAlert]="hasAlert" [hasAlertRecentlyClosed]="hasAlertRecentlyClosed" [viewWarnButton]="false"
  [warningLevel]="warningLevel">
  
  <ng-container picto>
    <i2a-badge-icon>
      <ng-container icon>
        <img width="24px" height="24px" class="align-img" src="assets/pictos/providers/sharepoint.svg">
      </ng-container>
    </i2a-badge-icon>
  </ng-container>

  <ng-container content>
    <div class="p-0 share-point-o365-group">
      <div *ngIf="hasAlerts">
      </div>
      <div class="row no-gutters">
        <div class="col-6 p-2">
          <div class="col pl-4">
            <span class="id-title">{{"profile.provider.microsoft.sharePoint.group.users" | i18next }}</span>
            <span class="id-value"> {{ totalUsers }}</span>
          </div>
        </div>
        <div class="col-6 p-2">
          <div class="col pl-4">
            <span class="id-title">{{"profile.provider.microsoft.sharePoint.group.groups" | i18next }}</span>
            <span class="id-value"> {{ totalGroups }}</span>
          </div>
        </div>
      </div>
      <div class="row no-gutters sharepoint-permission" *ngIf="isEveryone || isEveryoneExceptExternalUsers">
        <div class="col m-2">
          <p class="warn-group-member" *ngIf="isEveryone">
            {{ "profile.provider.microsoft.sharePoint.warnGroupMember.everyone" | i18next }}
          </p>
          <p class="warn-group-member" *ngIf="isEveryoneExceptExternalUsers">
            {{ "profile.provider.microsoft.sharePoint.warnGroupMember.everyoneExceptExternals" | i18next }}
          </p>
        </div>
      </div>
    </div>
  </ng-container>

</i2a-profile-card>

<ng-template #viewDetailTemplate>
  <i2a-modal-profile-header-title>
    <ng-container picto>
      <i2a-badge-icon>
        <ng-container icon>
          <img width="24px" height="24px" class="align-img" src="assets/pictos/providers/sharepoint.svg">
        </ng-container>
      </i2a-badge-icon>
    </ng-container>
    <ng-container title>
      <span [title]="group.title">{{group.title}}</span>
    </ng-container>
  </i2a-modal-profile-header-title>

  <div class="row no-gutters" *ngIf="displaySharepPointGroupOwner">
    <div class="col">
      <span class="id-title">{{"profile.provider.microsoft.sharePoint.group.owner" | i18next }}</span>
      <span class="id-value"> {{ group.owner.title }}</span>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="col pl-2 pr-2">
      <p *ngIf="group">{{group.description}}</p>
      <ng-container *ngIf="group.members.length > 0">
        <p>
          {{rootPathPermissionKey | i18next }}
        </p>
        <table class="table table-sm table-bordered id-table">
          <thead>
            <tr>
              <td>{{ "profile.provider.microsoft.sharePoint.group.memberType" | i18next }}</td>
              <td>{{ "profile.provider.microsoft.sharePoint.group.memberName" | i18next }}</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let member of members">
              <td scope="row" [ngClass]="{'text-break': true, 'has-alert': member.hasAlert, 'has-alert-recently-closed': member.hasAlertRecentlyClosed, 'icons': true}">
                <fa-icon *ngIf="member.isGroup" [icon]="['fas', 'users']"></fa-icon>
                <img *ngIf="member.isO365Group" src="assets/pictos/providers/o365.svg">
                <fa-icon *ngIf="!member.isGroup" [icon]="['fas', 'user']"></fa-icon>
              </td>
              <td scope="row" [ngClass]="{'text-break': true, 'has-alert': member.hasAlert, 'has-alert-recently-closed': member.hasAlertRecentlyClosed}">
                {{member.title}}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Permissions -->
        <i2a-short-title-bar [isCollapsed]="false" [hasAlert]="false" *ngIf="hasMemberDriveItems">
          <ng-container title>{{'profile.provider.microsoft.sharePoint.driveItem.specificPaths' | i18next }}
          </ng-container>
          <ng-container body>
            <i2a-share-point-permission-detail [driveItems]="memberDriveItems" [displayLegend]="false">
            </i2a-share-point-permission-detail>
          </ng-container>
        </i2a-short-title-bar>

        <i2a-share-point-permission-legend [displayReadLegend]="true" [displayUpdateLegend]="true"
          [displayOwnerLegend]="true" [displayUnknownLegend]="true">
        </i2a-share-point-permission-legend>

      </ng-container>
    </div>
  </div>
</ng-template>
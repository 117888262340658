import { OnInit, Input, OnDestroy, Component, Inject, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';
import { DisplayAlertsReasonsModalComponent } from '../../alerts/display-alerts-reasons-modal/display-alerts-reasons-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserModel, ReportProfileBaseModel, AlertInvalidStateFeaturedModel, ReportProfileAlertBaseModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { WarningInfo } from '../../../models/warnings/warning-info';
import { WarningLevel } from '../../../models/warnings/warning-level';
import { Subscription } from 'rxjs';

@Component({
  selector: 'i2a-profile-item-base',
  template: ''
})
export class ProfileBaseClassComponent<CONFIGURATION, PROFILE_RESOURCE extends ReportProfileResourceBase<any>> implements OnInit, OnChanges, OnDestroy {
  @Input() public user: UserModel;
  @Input() public profile: ReportProfileBaseModel;
  @Input() public alerts: AlertInvalidStateFeaturedModel[];
  @Input() public warnings: Array<WarningInfo>;
  @Input() public alertReasons: string[];
  @Input() public resourceProfile: PROFILE_RESOURCE;
  @Input() public componentId: string;
  @Input() public isAlert: boolean;

  public id: string;
  public hasAlert: boolean;
  public hasAlertRecentlyClosed: boolean;
  public warningLevel?: WarningLevel;

  @Output() public onAlertSent = new EventEmitter();

  @Output() public onHasNoData = new EventEmitter<boolean>();
  public hasNoData: boolean;

  @Output() public onHasWarnings = new EventEmitter<Array<WarningInfo>>();

  public configuration: CONFIGURATION;

  protected subscriptions = new Subscription();

  constructor(
    public toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) public i18Next: ITranslationService,
    protected modalService: NgbModal
  ) {
    this.isAlert = true;
    this.alertReasons = [];
    this.warnings = [];
  }

  ngOnInit() {
    if (this.alerts) {
      this.setItems();
    }
  }

  protected baseInitialize(user: UserModel, profile: ReportProfileBaseModel, configuration: CONFIGURATION, alerts: AlertInvalidStateFeaturedModel[]): void {
    this.user = user;
    this.profile = profile;
    this.isAlert = true;
    this.configuration = <CONFIGURATION>configuration;
    this.alerts = alerts;
  }

  protected initAlertModel(model: ReportProfileAlertBaseModel) {
    model.providerId = this.profile.providerId;
    model.providerInstanceId = this.profile.providerInstanceId;
    model.adminObjectId = this.profile.adminObjectId;

    model.endDate = this.profile.endDate;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  showOpenAlerts() {
    const modalRef = this.modalService.open(DisplayAlertsReasonsModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.resourceProfile = this.resourceProfile;
    modalRef.componentInstance.alerts = this.alerts;
    modalRef.componentInstance.alertReasons = this.alertReasons;
  }

  private alertProcessed(alert: AlertInvalidStateFeaturedModel) {
    this.onAlertSent.emit(alert);
    setTimeout(() => this.toastr.info(this.i18Next.t("profile.alertReported")));
  }

  setAlertsProcessed(alerts: AlertInvalidStateFeaturedModel[]) {
    alerts.forEach(alert => {
      this.alertProcessed(alert);
    });
  }

  setAlertProcessed(alert: AlertInvalidStateFeaturedModel) {
    setTimeout(() => {
      this.warnings.filter(w => w.id == this.id).forEach(w => w.hasAlert = true);
    });
    this.alertProcessed(alert);
  }

  updateAlerts(alerts: AlertInvalidStateFeaturedModel[]) {
    this.alerts = alerts;
    this.setItems();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setItems();
  }

  setItems() {

  }

  setIsAlert(isAlert: boolean) {
    this.isAlert = isAlert;
  }

  public hasWarnings(warnings: Array<WarningInfo>) {
    this.warnings = warnings;
    this.onHasWarnings.emit(warnings);
  }

  public updateWarnings(warnings: Array<WarningInfo>): void {
    warnings.forEach(w => {
      if (!this.warnings.some(nw => nw.id == w.id)) {
        this.warnings.push(w);
      }
    })
  }
}
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../authentication/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { LoadingHelper } from '../../helpers/loading-helper';

@Component({
  selector: 'i2a-auth-callback-central',
  templateUrl: '../auth-loading.component.html'
})
export class AuthCallbackCentralComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute
  ) { }


  public loadingText: string;

  async ngOnInit() {
    try {
      this.loadingText = LoadingHelper.getLoadingText();
    } catch (error) {
      this.loadingText = "Loading in progress, please wait...";
    }

    this.authService.completeCentralAuthentication(this.activatedRoute.snapshot);
  }


}

import { Injectable, Type, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ComponentRegistry {

    protected static components: { [id: string]: Type<any>; } = {};

    constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    }

    public static registerComponent(componentType: Type<any>, componentId: string) {
        this.components[componentId] = componentType;
    }

    public static getComponent(componentId: string): Type<any> {
        return ComponentRegistry.components[componentId];
    }

}

import { Component, OnInit, Inject } from '@angular/core';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { RulesComputedDelegateConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/rules-computed-delegate-configuration';
import { BaseProviderInstanceResourceModel, UserModel, ReportProfileBaseModel, AlertInvalidStateFeaturedModel, MicrosoftOfficeReportProfileModel, ReportProfileRulesComputedDelegate, ReportProfileResourceBase, Constants, ZimbraReportProfileModel, ReportProfileDeviceModel, ExchangeReportProfile, ExchangeReportProfilePermissions, ZimbraMailingReportProfile, ZimbraMailingReportProfilePermissions } from '@i2a/web-api-client';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ProfileHelper } from 'projects/web-user-profile/src/app/helpers/profile-helper';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { ProfileBaseClassComponent } from '../../../../shared/profile-base-class-component';
import { BaseTemplate } from 'projects/web-user-profile/src/app/models/templates/base-template';
import { ZimbraMailingDelegateModuleComponent } from '../zimbra-mailing-delegate-module/zimbra-mailing-delegate-module.component';
import { ZimbraMailingDelegate } from 'projects/web-user-profile/src/app/models/providers/zimbra/mailing/zimbra-mailing-delegate';
import { ZimbraMailingDelegateConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/zimbra-mailing-delegate-configuration';
import { ZimbraMailingPermissionConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/zimbra-mailing-permission-configuration';
import { ZimbraMailingPermissionModuleComponent } from '../zimbra-mailing-permission-module/zimbra-mailing-permission-module.component';

@RegisterMyProfileComponent(TemplateConstants.MODULE_ZIMBRA_RULES_COMPUTED_DELEGATE_I2A)
@Component({
  selector: 'i2a-zimbra-mailing-rules-computed-delegate-module',
  templateUrl: './zimbra-mailing-rules-computed-delegate-module.component.html',
  styleUrls: ['./zimbra-mailing-rules-computed-delegate-module.component.scss']
})
export class ZimbraMailingRulesComputedDelegateModuleComponent extends ProfileBaseClassComponent<RulesComputedDelegateConfiguration,ZimbraMailingReportProfile> implements OnInit, IModule<RulesComputedDelegateConfiguration> {
  private resource: BaseProviderInstanceResourceModel;
  public delegates: ReportProfileRulesComputedDelegate[];
  public devices: ReportProfileDeviceModel[];
  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(toastr, i18Next, null);
    this.devices = [];
  }

  ngOnInit() {
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, configuration: RulesComputedDelegateConfiguration, alerts: AlertInvalidStateFeaturedModel[], resource: BaseProviderInstanceResourceModel, resourceTemplate?: BaseTemplate): void {
    this.baseInitialize(user, profile, configuration, alerts);
    this.resource = resource;
    this.resourceProfile = (<ZimbraReportProfileModel>profile).mailingProfiles.find(r => r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResourceId);

    var allDelegates = this.resourceProfile.rulesComputedDelegates.filter(d=>
      {
       return ( !this.configuration.filterUnreconcileAccounts || !(this.configuration.filterUnreconcileAccounts && d.unreconcileAccount));
      }).sort((a, b) => { return ProfileHelper.compare(a, b, this.i18Next); });
    
    this.delegates = allDelegates;

    var delegateModuleDelegates = new Array<ZimbraMailingDelegate>();
    var delegateModule = resourceTemplate.modules.find(m => m.id == TemplateConstants.MODULE_EXCHANGE_DELEGATE);
    if (delegateModule != null && delegateModule.isEnabled)
    {
      var delegateModuleConfiguration = delegateModule.configuration as ZimbraMailingDelegateConfiguration;
      delegateModuleDelegates = ZimbraMailingDelegateModuleComponent.SetDelegates(this.resourceProfile.delegates, this.resourceProfile.permissions);
    }
    
    var permissionModuleDelegates = new Array<ZimbraMailingReportProfilePermissions>();
    var permissionModule = resourceTemplate.modules.find(m => m.id == TemplateConstants.MODULE_EXCHANGE_PERMISSION);    
    if (permissionModule != null && permissionModule.isEnabled)
    {
      var permissionModuleConfiguration = permissionModule.configuration as ZimbraMailingPermissionConfiguration;
      permissionModuleDelegates = ZimbraMailingPermissionModuleComponent.SetDelegates(this.resourceProfile.permissions, this.resourceProfile.delegates);
    }

    this.delegates = this.delegates.filter(d=>
      {
        if (d.userId != null)
        {
          var delegateModuleIndex = delegateModuleDelegates.findIndex(dmd => dmd.permissions != null && dmd.permissions.userId != null && dmd.permissions.userId == d.userId);
          var permissionModuleIndex = permissionModuleDelegates.findIndex(dmd => dmd.userId != null && dmd.userId == d.userId);
          if ((delegateModuleIndex >= 0) || (permissionModuleIndex >=0)) { return false; }
        } else if (d.providerInstanceUser != null)
        {
          var delegateModuleIndex = delegateModuleDelegates.findIndex(dmd => dmd.permissions != null && dmd.permissions.providerInstanceUser != null && dmd.permissions.providerInstanceUser.providerInstanceResourceId == d.providerInstanceUser.providerInstanceResourceId);
          var permissionModuleIndex = permissionModuleDelegates.findIndex(dmd => dmd.providerInstanceUser != null && dmd.providerInstanceUser.providerInstanceResourceId == d.providerInstanceUser.providerInstanceResourceId);
          if ((delegateModuleIndex >= 0) || (permissionModuleIndex >=0)) { return false; }
        } else {
          var delegateModuleIndex = delegateModuleDelegates.findIndex(dmd => dmd.permissions != null && dmd.permissions.logonUserId != null && dmd.permissions.logonUserId == d.logonUserId);
          var permissionModuleIndex = permissionModuleDelegates.findIndex(dmd => dmd.logonUserId != null && dmd.logonUserId == d.logonUserId);
          if ((delegateModuleIndex >= 0) || (permissionModuleIndex >=0)) { return false; }
        }
        return true;
      }).sort((a, b) => { return ProfileHelper.compare(a, b, this.i18Next); });
    this.devices = this.profile.userProfile.devices.filter(d=>
      {
       return ( !this.configuration.filterIsActiveDevice || this.configuration.filterIsActiveDevice && d.isActive) &&  d.syncDevices.findIndex(s=> s.providerInstanceResourceId == this.resource.providerInstanceResourceId && s.providerTypeId == this.resource.providerTypeId) >= 0;
      });
  }

  alertSent(event): void {
    this.onAlertSent.emit(event);
  }

}

import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { WarningInfoType } from "projects/web-user-profile/src/app/models/warnings/warning-info-type";
import { Subscription } from "rxjs";
import { filter, map } from "rxjs/operators";
import { WarningLevel } from "../models/warnings/warning-level";

@Injectable({
  providedIn: 'root'
})
export class WarningHelper {

  constructor() {
  }

  public static GetWarningInfoTypeCaseAccount(logonUserId: string): WarningInfoType {
    switch (logonUserId) {
      case "Default":
      case "LinkCompanyUser": 
        return WarningInfoType.Company;
      case "isAnonymous":
      case "LinkEveryoneUser": 
        return WarningInfoType.Anonymous;
      default:
        return WarningInfoType.Unknown;
    }
  }

  public static GetWarningInfoTypeCaseGroupMember(isEveryone: boolean, isEveryoneExceptExternalUsers: boolean): WarningInfoType {
    if (isEveryoneExceptExternalUsers)
      return WarningInfoType.CompanyExceptExternal;
    else if (isEveryone)
      return WarningInfoType.ExternalShare;
    else 
      return WarningInfoType.Unknown;
  }

  public static GetWarningLevel(warningInfoType: WarningInfoType) : WarningLevel {
    switch (<WarningInfoType>warningInfoType) {
      case WarningInfoType.Anonymous:
        return WarningLevel.High;
      case WarningInfoType.Company:
      case WarningInfoType.ExternalShare:
      case WarningInfoType.CompanyExceptExternal:
      case WarningInfoType.ExternalForward:
          return WarningLevel.Medium;
      case WarningInfoType.sensitivityLabel:
      case WarningInfoType.anonymousSensitivityLabel:
      case WarningInfoType.companySensitivityLabel:
            return WarningLevel.Low;
      case WarningInfoType.Unknown:
          default:
        return WarningLevel.Unknown;
    }
  }

  
  public static openModalOnRoute(router: Router, route: ActivatedRoute, id: string, openDetails:(event) => void, warnClick:(event) => void) : Subscription {
    return router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => WarningHelper.rootRoute(route)),
      filter((route: ActivatedRoute) => route.outlet === 'primary')
    ).subscribe((route: ActivatedRoute) => {
      let anchor = route.snapshot.fragment;
      let action = route.snapshot.queryParamMap.get('action');
      if (anchor == id && action == "openDetails") {
        openDetails(null);
      } else if (anchor == id && action == "warnClick") {
        warnClick(null);
      }
    });
  }

  private static rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

}
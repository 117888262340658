import { AlertStatesModel } from '../models/alert-states-model';
import { AuditCollectDelayModel } from '../models/audit-collect-delay-model';
import { PageResultModel } from '../models/page-result-model';
import { ProviderAdminObjectHistorySummaryModel } from '../models/provider-admin-object-history-summary-model';
import { ProviderAdminObjectModel } from '../models/provider-admin-object-model';
import { ProviderAuditUsageStatisticsModel } from '../models/provider-audit-usage-statistics-model';
import { ProviderInstanceGlobalResourceStatesModel } from '../models/provider-instance-global-resource-states-model';
import { ProviderInstanceStatisticsModel } from '../models/provider-instance-statistics-model';
import { ResourceActivityModel } from '../models/resource-activity-model';
import { StatisticsGranularity } from '../models/statistics-granularity';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatasourceService } from '../../data/datasource.service';

@Injectable({
	providedIn: 'root'
})
export class ProviderInstanceApplicationsDatasourceService {

private route: string = "provider-instance-applications";

	constructor(private datasource: DatasourceService) {
		console.log("Init ProviderInstanceApplicationsDatasourceService");
	}

	public loadInstanceUsage(providerId: string, providerTypeId: string, providerInstanceId: string, begin: Date, end: Date, granularity: StatisticsGranularity, useCache: boolean = false, throwError: boolean = true): Observable<ProviderAuditUsageStatisticsModel[]> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/provider-audits/usage-stats`;

		return this.datasource.get<ProviderAuditUsageStatisticsModel[]>(partialUrl, {begin: String(begin),end: String(end),granularity: String(granularity) }, useCache, throwError);
	}

	public loadProvideInstanceApplicationAdminObject(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceItemId: string, type: number, useCache: boolean = false, throwError: boolean = true): Observable<ProviderAdminObjectModel> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/admin-objects/${type}`;

		return this.datasource.get<ProviderAdminObjectModel>(partialUrl, {providerInstanceItemId: String(providerInstanceItemId) }, useCache, throwError);
	}

	public loadProvideInstanceApplicationAdminObjectHistory(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceItemId: string, type: number, begin: Date = null, end: Date = null, limit: number = null, offset: number = null, useCache: boolean = false, throwError: boolean = true): Observable<PageResultModel<ProviderAdminObjectModel>> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/admin-objects/${type}/history`;

		return this.datasource.get<PageResultModel<ProviderAdminObjectModel>>(partialUrl, {providerInstanceItemId: String(providerInstanceItemId),begin: String(begin),end: String(end),limit: String(limit),offset: String(offset) }, useCache, throwError);
	}

	public loadProvideInstanceApplicationAdminObjectHistoryInterval(providerId: string, providerTypeId: string, providerInstanceId: string, providerInstanceItemId: string, type: number, refDate: Date = null, useCache: boolean = false, throwError: boolean = true): Observable<ProviderAdminObjectHistorySummaryModel[]> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/admin-objects/${type}/history-interval`;

		return this.datasource.get<ProviderAdminObjectHistorySummaryModel[]>(partialUrl, {providerInstanceItemId: String(providerInstanceItemId),refDate: String(refDate) }, useCache, throwError);
	}

	public photo(providerId: string, providerTypeId: string, providerInstanceId: string, useCache: boolean = false, throwError: boolean = true): Observable<HttpResponse<Blob>> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/photo`;

		return this.datasource.get<HttpResponse<Blob>>(partialUrl, { }, useCache, throwError, 'blob','response');
	}

	public loadInstanceApplicationStatistics(providerId: string, providerTypeId: string, providerInstanceId: string, useCache: boolean = false, throwError: boolean = true): Observable<ProviderInstanceStatisticsModel> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/provider-instance-resource-statistics`;

		return this.datasource.get<ProviderInstanceStatisticsModel>(partialUrl, { }, useCache, throwError);
	}

	public getProvideInstanceResourcesState(providerId: string, providerTypeId: string, providerInstanceId: string, begin: Date, end: Date, useCache: boolean = false, throwError: boolean = true): Observable<ProviderInstanceGlobalResourceStatesModel> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/stats/provider-instance-resources-state`;

		return this.datasource.get<ProviderInstanceGlobalResourceStatesModel>(partialUrl, {begin: String(begin),end: String(end) }, useCache, throwError);
	}

	public getAlertStates(providerId: string, providerTypeId: string, providerInstanceId: string, begin: Date, end: Date, isPremium: boolean = null, useCache: boolean = false, throwError: boolean = true): Observable<AlertStatesModel> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/stats/alert-states`;

		return this.datasource.get<AlertStatesModel>(partialUrl, {begin: String(begin),end: String(end),isPremium: String(isPremium) }, useCache, throwError);
	}

	public getAuditCollectDelay(providerId: string, providerTypeId: string, providerInstanceId: string, begin: Date, end: Date, granularity: StatisticsGranularity, providerInstanceResourceId: string = null, useCache: boolean = false, throwError: boolean = true): Observable<AuditCollectDelayModel> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/provider-audits/processing-stats`;

		return this.datasource.get<AuditCollectDelayModel>(partialUrl, {begin: String(begin),end: String(end),granularity: String(granularity),providerInstanceResourceId: String(providerInstanceResourceId) }, useCache, throwError);
	}

	public loadResourceActivity(providerId: string, providerTypeId: string, providerInstanceId: string, begin: Date, end: Date, useCache: boolean = false, throwError: boolean = true): Observable<ResourceActivityModel[]> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/provider-audits/resource-activities`;

		return this.datasource.get<ResourceActivityModel[]>(partialUrl, {begin: String(begin),end: String(end) }, useCache, throwError);
	}

	public protectedResourcesReport(providerId: string, providerTypeId: string, providerInstanceId: string, toPDF: boolean = true, isPremium: boolean = null, orgUnitId: string = null, culture: string = null, useCache: boolean = false, throwError: boolean = true): Observable<HttpResponse<Blob>> {
		let partialUrl = `${this.route}/${providerId}/${providerTypeId}/${providerInstanceId}/report/protected-resources`;

		return this.datasource.get<HttpResponse<Blob>>(partialUrl, {toPDF: String(toPDF),isPremium: String(isPremium),orgUnitId: String(orgUnitId),culture: String(culture) }, useCache, throwError, 'blob','response');
	}

}

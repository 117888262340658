import { DeviceType } from '../device-type';
import { ReportProfileDeviceSyncModel } from '../profile/report-profile-device-sync-model';

export class ReportProfileDeviceModel {
  id: string;
  userId: string;
  name: string;
  isActive: boolean;
  syncDevices: ReportProfileDeviceSyncModel[];
  deviceType: DeviceType;
}

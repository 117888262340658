import { Component, OnInit, Input, OnDestroy, AfterViewInit, Output, EventEmitter, ViewChild, Inject, ViewChildren, QueryList, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import { ProfileValidationComponent } from '../profile-validation/profile-validation.component';
import { Subscription } from 'rxjs';
import { UsersDatasourceService, Criticality, UserModel, ConnectedAccountModel, ReportProfileBaseModel, ReportProfileResource, AuditReportModel, ProviderDatasourceService, ProviderInstanceResourceModel, Constants, IconConstants, MicrosoftOfficeReportProfileModel, MicrosoftOfficeProfileProviderDatasourceService } from '@i2a/web-api-client';
import { SessionService } from '../../../../services/session-service';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { Template } from 'projects/web-user-profile/src/app/models/templates/template';
import { TemplateConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/template-configuration';
import { TemplateProvider } from 'projects/web-user-profile/src/app/models/templates/template-provider';
import { TemplateApplication } from 'projects/web-user-profile/src/app/models/templates/template-application';
import { ProviderHelper } from 'projects/web-user-profile/src/app/helpers/provider-helper';
import { ResourceContainerComponent } from '../resource-container/resource-container.component';
import { ActivatedRoute } from '@angular/router';
import dayJS from 'dayjs';
import { StringHelper } from 'projects/web-user-profile/src/app/helpers/string-helper';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ProfileHelper } from 'projects/web-user-profile/src/app/helpers/profile-helper';
import { WarningInfo } from 'projects/web-user-profile/src/app/models/warnings/warning-info';
import { SharePointApplicationConfiguration } from 'projects/web-user-profile/src/app/models/templates/modules/configuration/share-point-application-configuration';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { isTemplateMiddle } from 'typescript';
import { MenuApplication } from 'projects/web-user-profile/src/app/models/menu-application';
import { MenuItem } from 'projects/web-user-profile/src/app/models/menu-item';

@Component({
  selector: 'i2a-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public profile: ReportProfileBaseModel;
  @Input() public template: Template;
  @Input() public alertIsSent: boolean;
  @Input() public configuration: TemplateConfiguration;
  @Input() public resources: ReportProfileResource;
  @Input() public userActivityIsValid: boolean;
  @Input() public profileIsValidated: boolean;
  @Input() public warningshighlighting: WarningInfo[];

  @Output() public onValidate = new EventEmitter();

  @ViewChildren(ProfileValidationComponent) validations: QueryList<ProfileValidationComponent>;

  public isSticky: boolean = true;
  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    this.isSticky = windowScroll == 0;
  }
  
  public lastValidationDate: Date | null;
  public user: UserModel;
  public connectedAccount: ConnectedAccountModel;
  public isAlert: boolean;
  public applications: MenuApplication[];
  public beginDate: Date;
  public endDate: Date;
  
  private subscriptions = new Subscription();
  private warning: Criticality = Criticality.Warning;
  
  private helpMenuIcon: SafeUrl;
  private isDefaultIcon: boolean = true;
  
  public debug: boolean;

  constructor(
    private usersDatasource: UsersDatasourceService,
    private microsoftDataSource: MicrosoftOfficeProfileProviderDatasourceService,
    public session: SessionService,
    public toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService
  ) {
    this.isAlert = true;
    this.alertIsSent = false;
    this.lastValidationDate = null;
  }

  ngOnInit() {

    this.subscriptions.add(this.session.Account$.subscribe((account) => {
      this.connectedAccount = account;
    }));

    if (this.profile) {
      this.lastValidationDate = this.profile.lastValidationDate;
      this.beginDate = dayJS(this.profile.beginDate).toDate();
      this.endDate = dayJS(this.profile.endDate).toDate();
      this.subscriptions.add(this.usersDatasource.load(this.profile.userId).subscribe((user) => {
        this.user = user;
      }));

      if (this.profile.profileIsUpdated) {
        setTimeout(() => this.toastr.info(this.i18Next.t("profile.profileIsUpdated")));
      }
    }

    if (this.template) {
      this.createMenu();
    }

    if (this.activatedRoute && this.activatedRoute.queryParams) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.debug = params['debug'];
      });
    }
  }
  
  private createMenu() {
    this.applications = [];
    this.applications.push({
      id: 'home',
      url: null,
      icon: this.template.configuration.homeIcon == null ? ["fal", "home"] : this.template.configuration.homeIcon,
      iconClasses: "mr-1",
      label: StringHelper.isNullOrWhitespace(this.template.configuration.homeKey) ? "profile.menu.home" : this.template.configuration.homeKey,
      isDropdown: false,
      hideOnTop: true,
      isApplication: false,
      items: null,
      providerInstanceResourceId: null,
      providerTypeId: null
    });

    if (this.session.WarningConfiguration && !this.session.WarningConfiguration.hideContainer && this.warningshighlighting) {
      this.applications.push({
        id: "warnings",
        url: null,
        icon: ["far", "siren-on"],
        iconClasses: "mr-1 id-warning-activity",
        label: "profile.menu.warning",
        isDropdown: false,
        hideOnTop: false,
        isApplication: false,
        items: null,
        providerInstanceResourceId: null,
        providerTypeId: null
      });
    }

    if (this.template.userTemplate.containerModule.isEnabled) {
      this.applications.push({
        id: 'user' + this.profile.userId,
        url: null,
        icon: ['fas', 'user'],
        iconClasses: "mr-1",
        label: 'profile.menu.myActivity',
        isDropdown: false,
        hideOnTop: false,
        isApplication: false,
        items: null,
        providerInstanceResourceId: null,
        providerTypeId: null
      });
    }

    this.template.providerTemplates.forEach((providerTemplate: TemplateProvider) => {

      providerTemplate.providerApplicationTemplates.forEach((providerApplicationTemplate: TemplateApplication) => {

        if (this.isProviderApplicationTemplateAvalaible(providerApplicationTemplate, providerTemplate)) {

          let resources = this.profile.providerInstanceResources.filter(pir => pir.providerTypeId.toLocaleLowerCase() == providerApplicationTemplate.providerTypeId.toLocaleLowerCase()
            && pir.providerId.toLocaleLowerCase() == providerTemplate.providerId.toLocaleLowerCase()
          );

          this.createMenuForResources(resources, providerApplicationTemplate, providerTemplate);
        }

      });

    });

    if (this.template.configuration.helpMenuIcon && this.template.configuration.helpMenuIcon.length > 0) {
      this.isDefaultIcon = false;
      this.helpMenuIcon = this.sanitizeIcon(this.template.configuration.helpMenuIcon);
    }
  }

  private createMenuForResources(resources: ProviderInstanceResourceModel[], providerApplicationTemplate: TemplateApplication, providerTemplate: TemplateProvider) {
    if (resources.length == 1) {

      if (this.canPushResource(resources[0])) {

        this.applications.push({
          id: 'application' + providerApplicationTemplate.providerTypeId,
          url: ProviderHelper.GetProviderImage(providerTemplate.providerId, providerApplicationTemplate.providerTypeId, ProfileHelper.getResourceProfile(this.profile, resources[0])),
          providerTypeId: resources[0].providerTypeId,
          providerInstanceResourceId: resources[0].providerInstanceResourceId,
          label: ProviderHelper.GetProviderTypeMenuLabel(providerTemplate.providerId, providerApplicationTemplate.providerTypeId, null),
          isDropdown: false,
          hideOnTop: false,
          isApplication: true,
          icon: null,
          iconClasses: null,
          items: null
        });
      }
    } else {

      let items : MenuItem[] = [];

      resources.forEach(resource => {
        if (this.canPushResource(resource)) {

          if (this.isSharePointGroupBySite(resource, providerApplicationTemplate)) {

            let sharePointReportProfile = (<MicrosoftOfficeReportProfileModel>this.profile).sharePointProfiles.find(pirf => pirf.providerInstanceResource.providerId.toLocaleLowerCase() === resource.providerId.toLocaleLowerCase()
              && pirf.providerInstanceResource.providerTypeId.toLocaleLowerCase() === resource.providerTypeId.toLocaleLowerCase()
              && pirf.providerInstanceResource.providerInstanceId.toLocaleLowerCase() === resource.providerInstanceId.toLocaleLowerCase()
              && pirf.providerInstanceResource.providerInstanceResourceId.toLocaleLowerCase() === resource.providerInstanceResourceId.toLocaleLowerCase()
            );

            if (!items.find(i => i.id === 'sharepoint-site-' + sharePointReportProfile?.siteId)) {
              items.push({
                id: 'sharepoint-site-' + sharePointReportProfile?.siteId,
                url: ProviderHelper.GetProviderImage(providerTemplate.providerId, providerApplicationTemplate.providerTypeId, ProfileHelper.getResourceProfile(this.profile, resource)),
                providerTypeId: resource.providerTypeId,
                providerInstanceResourceId: resource.providerInstanceResourceId,
                providerInstanceResourceIds: [resource.providerInstanceResourceId],
                label: (<MicrosoftOfficeReportProfileModel>this.profile).sharePointSites.find(s => s.id.toLocaleLowerCase() === sharePointReportProfile.siteId.toLocaleLowerCase())?.title ?? '[default sharepoint site title]',
                isApplication: true
              });
            } else {
              items.find(i => i.id === 'sharepoint-site-' + sharePointReportProfile?.siteId).providerInstanceResourceIds.push(resource.providerInstanceResourceId.toLocaleLowerCase());
            }
          } else {
            items.push({
              id: 'application' + providerApplicationTemplate.providerTypeId.toLocaleLowerCase() + resource.providerInstanceResourceId.toLocaleLowerCase(),
              url: ProviderHelper.GetProviderImage(providerTemplate.providerId, providerApplicationTemplate.providerTypeId, ProfileHelper.getResourceProfile(this.profile, resource)),
              providerTypeId: resource.providerTypeId,
              providerInstanceResourceId: resource.providerInstanceResourceId,
              providerInstanceResourceIds: null,
              label: resource.name,
              isApplication: true
            });
          }
        };
      });

      if (items.length > 0) {
        this.applications.push({
          id: 'application' + providerApplicationTemplate.providerTypeId,
          url: ProviderHelper.GetProviderImage(providerTemplate.providerId, providerApplicationTemplate.providerTypeId, null),
          providerTypeId: providerApplicationTemplate.providerTypeId,
          providerInstanceResourceId: null,
          label: ProviderHelper.GetProviderTypeMenuLabel(providerTemplate.providerId, providerApplicationTemplate.providerTypeId, items),
          isDropdown: true,
          items: items,
          hideOnTop: false,
          isApplication: true,
          icon: null,
          iconClasses: null
        });
      }
    }
  }

  private isSharePointGroupBySite(resource: ProviderInstanceResourceModel, providerApplicationTemplate: TemplateApplication): boolean {
    let result: boolean = false;

    if (resource.providerId.toLowerCase() == Constants.PROVIDER_ID_MICROSOFT.toLowerCase() &&
      resource.providerTypeId.toLowerCase() == Constants.PROVIDER_TYPE_INTRANET.toLowerCase()) {
      
        let sharePointApplicationConfiguration = providerApplicationTemplate.containerModule.id.toLocaleLowerCase() === TemplateConstants.MODULE_SHAREPOINT_APPLICATION_CONTAINER.toLocaleLowerCase() ? <SharePointApplicationConfiguration>providerApplicationTemplate.containerModule.configuration : null; 

        result = sharePointApplicationConfiguration?.groupBySite && (<MicrosoftOfficeReportProfileModel>this.profile).sharePointSites.length > 0;
    }
    
    return result;
  }

  private isProviderApplicationTemplateAvalaible(providerApplicationTemplate: TemplateApplication, providerTemplate: TemplateProvider):boolean {
    let isProviderApplicationTemplateResourceTemplateHasAnyModulesEnabled = providerApplicationTemplate.resourceTemplate.modules.filter(module => module.isEnabled).length > 0;
    let isIndexOfProviderInstanceResource = this.profile.providerInstanceResources.findIndex(pir => pir.providerTypeId.toLocaleLowerCase() == providerApplicationTemplate.providerTypeId.toLocaleLowerCase()
                                                                                                 && pir.providerId.toLocaleLowerCase() == providerTemplate.providerId.toLocaleLowerCase()) >= 0;

    return providerApplicationTemplate.containerModule.isEnabled && isProviderApplicationTemplateResourceTemplateHasAnyModulesEnabled && isIndexOfProviderInstanceResource;      
  }

  sanitizeIcon(base64: string): SafeUrl {
    let result: SafeUrl;

    const base64Tab = base64.split(',');

    if (base64Tab.length > 1) {
      switch (base64Tab[0]) {
        case IconConstants.typeSVG:
          result = this.sanitizer.bypassSecurityTrustUrl(`${IconConstants.typeSVG},${base64Tab[1]}`);
          break;
        case IconConstants.typePNG:
        default:
          result = this.sanitizer.bypassSecurityTrustUrl(`${IconConstants.typePNG},${base64Tab[1]}`);
          break;
      }
    } else {
      result = this.sanitizer.bypassSecurityTrustUrl(`${IconConstants.typePNG},${base64Tab[1]}`);
    }

    return result;
  }

  canPushResource(resource: ProviderInstanceResourceModel): any {
    if (resource.providerId.toLowerCase() == Constants.PROVIDER_ID_MICROSOFT.toLowerCase() &&
        resource.providerTypeId.toLowerCase() == Constants.PROVIDER_TYPE_GENERIC.toLowerCase()) {
      var azureADResource = (<MicrosoftOfficeReportProfileModel>this.profile).azureADProfiles
        .find(r => r.providerInstanceResource.providerInstanceResourceId.toLowerCase() == resource.providerInstanceResourceId.toLocaleLowerCase())
      return azureADResource.azureADAppPermissions.azureADAppPermissionGrants.length > 0
    }
    else {
      return true;
    }
  }

  ngAfterViewInit() {
    this.subscriptions.add(this.validations.forEach(validation => {
      validation.onValidate.subscribe(() => {
        this.onValidate.emit();
        this.lastValidationDate = new Date();
        setTimeout(() => this.toastr.info(this.i18Next.t("profile.okMessage")));
      });
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getHelpMenuStyle() {
    const color = this.template.configuration.helpMenuColor !== null ? this.template.configuration.helpMenuColor : 'inherit';
    const fontWeight = this.template.configuration.helpMenuFontWeight !== null ? this.template.configuration.helpMenuFontWeight : 'inherit';
    const fontSize = this.template.configuration.helpMenuFontSize !== null ? this.template.configuration.helpMenuFontSize : 'inherit';
    return {
      'color': color,
      'font-weight': fontWeight,
      'font-size': fontSize
    };
  }

  downloadAudit() {
    let filename = `AuditReport_${this.user.fullName.replace(/[/\\?%*:|"<>]/g, '-')}_${Date.now()}.xlsx`;
    let model = new AuditReportModel();
    model.beginDate = this.profile.beginDate;
    model.endDate = this.profile.endDate;
    model.resources = [];

    this.template.providerTemplates.forEach((providerTemplate: TemplateProvider) => {
      providerTemplate.providerApplicationTemplates.forEach((providerApplicationTemplate: TemplateApplication) => {
        if (providerApplicationTemplate.containerModule.isEnabled &&
          providerApplicationTemplate.resourceTemplate.modules.filter(module => module.isEnabled).length > 0 &&
          this.profile.providerInstanceResources.findIndex(pir =>
            pir.providerTypeId.toLocaleLowerCase() == providerApplicationTemplate.providerTypeId.toLocaleLowerCase()
            && pir.providerId.toLocaleLowerCase() == providerTemplate.providerId.toLocaleLowerCase()
          ) >= 0) {
          let resources = this.profile.providerInstanceResources.filter(pir =>
            pir.providerTypeId.toLocaleLowerCase() == providerApplicationTemplate.providerTypeId.toLocaleLowerCase()
            && pir.providerId.toLocaleLowerCase() == providerTemplate.providerId.toLocaleLowerCase()
          );
          resources.forEach(resource => {
            model.resources.push({
              providerId: resource.providerId,
              providerInstanceId: resource.providerInstanceId,
              providerInstanceResourceId: resource.providerInstanceResourceId,
              providerTypeId: resource.providerTypeId
            });
          });
        }
      })
    });

    this.subscriptions.add(this.microsoftDataSource.auditReport(model).subscribe(data => { ResourceContainerComponent.downLoadFile(data, "application/ms-excel", filename); }));
  }
}

import { OnInit, Input, OnDestroy, Component, Inject, Output } from '@angular/core';
import { ProfileBaseClassComponent } from '../profile-base-class-component';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseProviderInstanceResourceModel, ReportProfileResourceBase } from '@i2a/web-api-client';

@Component({
  selector: 'i2a-profile-item-base',
  template: ''
})
export class ProfileItemBaseClassComponent<PROFILE_RESOURCE extends ReportProfileResourceBase<any>> extends ProfileBaseClassComponent<any,PROFILE_RESOURCE> implements OnInit, OnDestroy {
  @Input() public providerInstanceResource: BaseProviderInstanceResourceModel;

  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService,
    modalService: NgbModal
    ) {
    super(toastr, i18Next, modalService);
  }

  ngOnInit() {
    super.ngOnInit();
  }
  
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

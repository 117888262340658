import { AddressModel } from './address-model';
import { GeoPositionModel } from './geo-position-model';
import { IPAddressRangeModel } from './ipaddress-range-model';

export class IPRangeCreateOrUpdateModel {
  name: string;
  ranges: IPAddressRangeModel[];
  position: GeoPositionModel;
  address: AddressModel;
  ignoreIP: boolean;
  ignoreGeocoding: boolean;
  locationLabel: string;
}

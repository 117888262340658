<i2a-profile-card [id]="id" [title]="title" [viewMoreDetails]="viewMoreDetails"
  (onViewMoreDetails)="openDetails($event)" (onWarnClick)="warnClick($event)" [hasAlert]="hasAlert"
  [hasAlertRecentlyClosed]="hasAlertRecentlyClosed" [warningLevel]="warningLevel">

  <ng-container picto>
    <i2a-delegate-photo [isAnonymous]="permissions.isAnonymousUser" [isDefault]="permissions.isTheWholeCompany"
      [isApplication]="false" [pir]="permissions.providerInstanceResource" [piu]="permissions.providerInstanceUser"
      [isExternalUser]="permissions.isExternalUser" [isUnknown]="permissions.isUnknown"
      [customIcon]="permissions.isGroup ? ['fas', 'users']: null">
    </i2a-delegate-photo>
  </ng-container>
  <ng-container subTitle>
    <span>{{ maxPermission }}</span>
  </ng-container>
  <ng-container content>
    <div class="container p-0 sharepoint-permission">
      <div class="row no-gutters">
        <div class="col m-2">
          <p class="warn-account mb-0" *ngIf="permissions.isNameKey">
            {{ ("profile.provider.microsoft.sharePoint.warnAccount." + permissions.name)  | i18next  }}
          </p>
          <p class="warn-sensitivity-label mb-0" *ngIf="permissions.hasSensitivityLabel && warnSensitivityLabel">
            <fa-icon [icon]="['far', 'lock-alt']" size="lg"></fa-icon>
            {{ ("profile.provider.microsoft.sharePoint.warnSensitivityLabel")  | i18next  }}
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</i2a-profile-card>

<ng-template #viewDetailTemplate>
  <i2a-modal-profile-header-title>
    <ng-container picto>
      <i2a-delegate-photo [isAnonymous]="permissions.isAnonymousUser" [isDefault]="permissions.isTheWholeCompany"
        [isApplication]="false" [pir]="permissions.providerInstanceResource" [piu]="permissions.providerInstanceUser"
        [isExternalUser]="permissions.isExternalUser" [isUnknown]="permissions.isUnknown"
        [customIcon]="permissions.isGroup ? ['fas', 'users']: null">
      </i2a-delegate-photo>
    </ng-container>
    <ng-container title>
      <span [title]="title">{{title}}</span>
    </ng-container>
  </i2a-modal-profile-header-title>

  <div class="ml-2 mb-3">
    <span>{{ maxPermission }}</span>
  </div>
  <i2a-share-point-permission-detail [driveItems]="permissions.driveItems"></i2a-share-point-permission-detail>
</ng-template>
import { AlertInvalidStateFeaturedModel } from '../../models/alert-invalid-state-featured-model';
import { DriveItemPermissionRemovalModel } from '../../models/microsoft/sharepoint/drive-item-permission-removal-model';
import { DriveItemResyncModel } from '../../models/microsoft/sharepoint/drive-item-resync-model';
import { MicrosoftOfficeImportUsersModel } from '../../models/microsoft/microsoft-office-import-users-model';
import { MicrosoftOfficeOperationAuditContextModel } from '../../models/microsoft/microsoft-office-operation-audit-context-model';
import { MicrosoftOfficeOperationLearningProtectionContextModel } from '../../models/microsoft/microsoft-office-operation-learning-protection-context-model';
import { MicrosoftOfficeOperationPermanentAuditContextModel } from '../../models/microsoft/microsoft-office-operation-permanent-audit-context-model';
import { MicrosoftOfficeOperationProtectionContextModel } from '../../models/microsoft/microsoft-office-operation-protection-context-model';
import { MicrosoftOfficeOperationStopAuditContextModel } from '../../models/microsoft/microsoft-office-operation-stop-audit-context-model';
import { MicrosoftOfficeOperationStopPermanentAuditContextModel } from '../../models/microsoft/microsoft-office-operation-stop-permanent-audit-context-model';
import { MicrosoftOfficeOperationUpdateProtectionContextModel } from '../../models/microsoft/microsoft-office-operation-update-protection-context-model';
import { PageResult } from '../../models/page-result';
import { ProviderPoliciesModel } from '../../models/provider-policies-model';
import { SharePointAuditResourcesModel } from '../../models/microsoft/sharepoint/share-point-audit-resources-model';
import { SharePointLearningProtectResourcesModel } from '../../models/microsoft/sharepoint/share-point-learning-protect-resources-model';
import { SharePointOnePageModel } from '../../orchestration/microsoft/sharepoint/reports/models/share-point-one-page-model';
import { SharePointPermanentAuditResourcesModel } from '../../models/microsoft/sharepoint/share-point-permanent-audit-resources-model';
import { SharePointProtectResourcesModel } from '../../models/microsoft/sharepoint/share-point-protect-resources-model';
import { SharePointReportProfileShares } from '../../models/microsoft/sharepoint/profile/share-point-report-profile-shares';
import { SharePointResourceUpdateModel } from '../../models/microsoft/sharepoint/share-point-resource-update-model';
import { SharePointSiteInformation } from '../../models/microsoft/sharepoint/share-point-site-information';
import { SharePointStopAuditResourcesModel } from '../../models/microsoft/sharepoint/share-point-stop-audit-resources-model';
import { SharePointStopPermanentAuditResourcesModel } from '../../models/microsoft/sharepoint/share-point-stop-permanent-audit-resources-model';
import { SharePointUnProtectResourcesModel } from '../../models/microsoft/sharepoint/share-point-un-protect-resources-model';
import { SharePointUpdateProtectResourcesModel } from '../../models/microsoft/sharepoint/share-point-update-protect-resources-model';
import { WebApplicationSummaryModel } from '../../models/microsoft/sharepoint/web-application-summary-model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatasourceService } from '../../../data/datasource.service';

@Injectable({
	providedIn: 'root'
})
export class SharePointDatasourceService {

private route: string = "providers/microsoft/sharePoint";

	constructor(private datasource: DatasourceService) {
		console.log("Init SharePointDatasourceService");
	}

	public getProviderPolicies(providerInstanceId: string, useCache: boolean = false, throwError: boolean = true): Observable<ProviderPoliciesModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/provider-policies`;

		return this.datasource.get<ProviderPoliciesModel>(partialUrl, { }, useCache, throwError);
	}

	public loadFarmSummary(providerInstanceId: string, siteName: string, listName: string, isHiddenFiltered: boolean = true, limit: number = null, offset: number = null, useCache: boolean = false, throwError: boolean = true): Observable<PageResult<WebApplicationSummaryModel>> {
		let partialUrl = `${this.route}/${providerInstanceId}/farm-summary`;

		return this.datasource.get<PageResult<WebApplicationSummaryModel>>(partialUrl, {siteName: String(siteName),listName: String(listName),isHiddenFiltered: String(isHiddenFiltered),limit: String(limit),offset: String(offset) }, useCache, throwError);
	}

	public permanentAuditSharePoint(providerInstanceId: string, model: SharePointPermanentAuditResourcesModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/permanent-audit`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public stopPermanentAuditSharePoint(providerInstanceId: string, model: SharePointStopPermanentAuditResourcesModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/stop-permanent-audit`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public updateResource(providerInstanceId: string, providerInstanceResourceId: string, model: SharePointResourceUpdateModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/${providerInstanceResourceId}`;

		return this.datasource.put(partialUrl, model, { params: { } }, throwError);
	}

	public getSharePointPermanentAuditOperation(providerInstanceId: string, operationId: string, useCache: boolean = false, throwError: boolean = true): Observable<MicrosoftOfficeOperationPermanentAuditContextModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/permanent-audit/${operationId}`;

		return this.datasource.get<MicrosoftOfficeOperationPermanentAuditContextModel>(partialUrl, { }, useCache, throwError);
	}

	public getSharePointStopPermanentAuditOperation(providerInstanceId: string, operationId: string, useCache: boolean = false, throwError: boolean = true): Observable<MicrosoftOfficeOperationStopPermanentAuditContextModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/stop-permanent-audit/${operationId}`;

		return this.datasource.get<MicrosoftOfficeOperationStopPermanentAuditContextModel>(partialUrl, { }, useCache, throwError);
	}

	public getAuditOperation(providerInstanceId: string, operationId: string, useCache: boolean = false, throwError: boolean = true): Observable<MicrosoftOfficeOperationAuditContextModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/audit/${operationId}`;

		return this.datasource.get<MicrosoftOfficeOperationAuditContextModel>(partialUrl, { }, useCache, throwError);
	}

	public getStopAuditOperation(providerInstanceId: string, operationId: string, useCache: boolean = false, throwError: boolean = true): Observable<MicrosoftOfficeOperationStopAuditContextModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/stop-audit/${operationId}`;

		return this.datasource.get<MicrosoftOfficeOperationStopAuditContextModel>(partialUrl, { }, useCache, throwError);
	}

	public protectSharePoint(providerInstanceId: string, model: SharePointProtectResourcesModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/protect`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public unProtect(providerInstanceId: string, model: SharePointUnProtectResourcesModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/un-protect`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public updateProtect(providerInstanceId: string, model: SharePointUpdateProtectResourcesModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/update-protect`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public startLearningProtect(providerInstanceId: string, model: SharePointLearningProtectResourcesModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/start-learning-protect`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public stopLearningProtect(providerInstanceId: string, model: SharePointLearningProtectResourcesModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/stop-learning-protect`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public getSharePointProtectOperation(providerInstanceId: string, operationId: string, useCache: boolean = false, throwError: boolean = true): Observable<MicrosoftOfficeOperationProtectionContextModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/protect/${operationId}`;

		return this.datasource.get<MicrosoftOfficeOperationProtectionContextModel>(partialUrl, { }, useCache, throwError);
	}

	public getUpdateProtectOperation(providerInstanceId: string, operationId: string, useCache: boolean = false, throwError: boolean = true): Observable<MicrosoftOfficeOperationUpdateProtectionContextModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/update-protect/${operationId}`;

		return this.datasource.get<MicrosoftOfficeOperationUpdateProtectionContextModel>(partialUrl, { }, useCache, throwError);
	}

	public getLearningProtectOperation(providerInstanceId: string, operationId: string, useCache: boolean = false, throwError: boolean = true): Observable<MicrosoftOfficeOperationLearningProtectionContextModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/learning-protect/${operationId}`;

		return this.datasource.get<MicrosoftOfficeOperationLearningProtectionContextModel>(partialUrl, { }, useCache, throwError);
	}

	public importUsers(providerInstanceId: string, model: MicrosoftOfficeImportUsersModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/import-users`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public audit(providerInstanceId: string, model: SharePointAuditResourcesModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/audit`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public stopAudit(providerInstanceId: string, model: SharePointStopAuditResourcesModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/stop-audit`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public onePage(providerInstanceId: string, providerInstanceResourceId: string, beginDate: Date = null, endDate: Date = null, useCache: boolean = false, throwError: boolean = true): Observable<SharePointOnePageModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/${providerInstanceResourceId}/one-page`;

		return this.datasource.get<SharePointOnePageModel>(partialUrl, {beginDate: String(beginDate),endDate: String(endDate) }, useCache, throwError);
	}

	public profileShares(providerInstanceId: string, providerInstanceResourceId: string, useCache: boolean = false, throwError: boolean = true): Observable<SharePointReportProfileShares> {
		let partialUrl = `${this.route}/${providerInstanceId}/${providerInstanceResourceId}/profile-shares`;

		return this.datasource.get<SharePointReportProfileShares>(partialUrl, { }, useCache, throwError);
	}

	public getSharePointSiteInformation(providerInstanceId: string, providerInstanceResourceId: string, useCache: boolean = false, throwError: boolean = true): Observable<SharePointSiteInformation> {
		let partialUrl = `${this.route}/${providerInstanceId}/${providerInstanceResourceId}/site-information`;

		return this.datasource.get<SharePointSiteInformation>(partialUrl, { }, useCache, throwError);
	}

	public driveItemRemovePermission(providerInstanceId: string, model: DriveItemPermissionRemovalModel, throwError: boolean = true): Observable<AlertInvalidStateFeaturedModel> {
		let partialUrl = `${this.route}/${providerInstanceId}/remove-permission`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

	public resyncDriveItem(providerInstanceId: string, providerInstanceResourceId: string, model: DriveItemResyncModel, throwError: boolean = true): Observable<any> {
		let partialUrl = `${this.route}/${providerInstanceId}/${providerInstanceResourceId}/resync-drive-item`;

		return this.datasource.post(partialUrl, model, { params: { } }, throwError);
	}

}

import { DriveItemSharingType } from '../../../microsoft/sharepoint/drive-item-sharing-type';
import { ReportProfileDelegate } from '../../../profile/report-profile-delegate';

export class SharePointReportProfileShareDetail extends ReportProfileDelegate {
  sharingType: DriveItemSharingType;
  expirationDate: Date|null;
  permissionId: string;
  email: string;
  isGroup: boolean;
  creationDate: Date;
  modificationDate: Date;
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OneDriveReportProfileAlertDelegateModel, SharePointReportProfileAlertDelegateModel } from '@i2a/web-api-client';

@Component({
  selector: 'i2a-share-point-access-permission-form',
  templateUrl: './share-point-access-permission-form.component.html',
  styleUrls: ['./share-point-access-permission-form.component.scss']
})
export class SharePointAccessPermissionFormComponent implements OnInit {

  @Input() public folders: any[];//TODO CRADO
  @Input() public driveItemsPermissions: any[];//TODO CRADO
  @Input() public allAccessAndPermissionsTitle: string;
  @Input() public permissionsAndAccessesCollapsed: boolean = false;

  @Input() public validation: OneDriveReportProfileAlertDelegateModel | SharePointReportProfileAlertDelegateModel;
  @Output() public validationChange: EventEmitter<OneDriveReportProfileAlertDelegateModel | SharePointReportProfileAlertDelegateModel> = new EventEmitter<OneDriveReportProfileAlertDelegateModel | SharePointReportProfileAlertDelegateModel>();

  @Output() public setCanSend: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  
  public allChecked(): boolean {
    var hasAccesses = this.hasAccesses();
    var hasPermissions = this.hasPermissions();
    return (!hasAccesses || hasAccesses && this.validation.allFolders)
      && (!hasPermissions || hasPermissions && this.validation.permissions.allPaths);
  }

  allCheckedChange(isChecked: boolean) {
    if (this.hasAccesses()) {
      this.validation.allFolders = isChecked;
      this.allFoldersChecked(isChecked);
    }
    if (this.hasPermissions()) {
      this.validation.permissions.allPaths = isChecked;
      this.allPermissionsChecked(isChecked);
    }
    this.setCanSend.emit();
  }

  hasAccesses(): boolean {
    return this.folders.length > 0;
  }

  hasPermissions(): boolean {
    return this.driveItemsPermissions.length > 0;
  }

  
  folderChecked(isChecked: boolean, folder: any) {
    const index: number = this.validation.folders.indexOf(folder.name);
    if (isChecked && index === -1) {
      this.validation.folders.push(folder.name);
    }
    else if (!isChecked && index !== -1) {
      this.validation.folders.splice(index, 1);
    }
    if (this.folders.length == this.validation.folders.length) {
      this.allFoldersChecked(true);
      this.validation.allFolders = true;
    }
    this.setCanSend.emit();
  }

  allFoldersChecked(isChecked: boolean) {
    if (isChecked) {
      this.folders.forEach(f => f.checked = false);
      this.validation.folders = [];
    }
    this.validation.allFolders = isChecked;
  }

  permissionChecked(isChecked: boolean, permission: any) {
    const index: number = this.validation.permissions.invalidPaths.indexOf(permission.name);
    if (isChecked && index === -1) {
      this.validation.permissions.invalidPaths.push(permission.name);
    }
    else if (!isChecked && index !== -1) {
      this.validation.permissions.invalidPaths.splice(index, 1);
    }
    if (this.driveItemsPermissions.length == this.validation.permissions.invalidPaths.length) {
      this.allPermissionsChecked(true);
      this.validation.permissions.allPaths = true;
    }
    this.setCanSend.emit();
  }


  allPermissionsChecked(isChecked: boolean) {
    if (this.validation.permissions == null) {
      return true;
    }
    else if (isChecked) {
      this.validation.permissions.invalidPaths = [];
    }
    this.validation.permissions.allPaths = isChecked;
  }



}

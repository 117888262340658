import { Component, OnInit, Input, Inject } from '@angular/core';
import { ZimbraMailingReportProfilePermissions } from '@i2a/web-api-client';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

@Component({
  selector: 'i2a-mailing-permission-rights',
  templateUrl: './mailing-permission-rights.component.html',
  styleUrls: ['./mailing-permission-rights.component.scss']
})
export class MailingPermissionRightsComponent implements OnInit {
  @Input() public permissions: ZimbraMailingReportProfilePermissions;

  public rights: string;

  constructor(
    @Inject(I18NEXT_SERVICE) public i18Next: ITranslationService) { }

  ngOnInit() {
    if (this.permissions) {
      this.rights = this.GetRights();
    }
  }

  private GetRights(): string {
    let rights: string[] = [];

    if (this.permissions != null) {
      if (this.permissions.hasContactsRights) {
        rights.push(this.i18Next.t("profile.provider.zimbra.mailing.rights.contacts"));
      }

      if (this.permissions.hasSendAsRights) {
        rights.push(this.i18Next.t("profile.provider.zimbra.mailing.rights.sendAs"));
      }

      if (this.permissions.hasCalendarRights) {
        rights.push(this.i18Next.t("profile.provider.zimbra.mailing.rights.calendar"));
      }

      if (this.permissions.hasFoldersRights) {
        rights.push(this.i18Next.t("profile.provider.zimbra.mailing.rights.folders"));
      }

      if (this.permissions.hasInboxRights) {
        rights.push(this.i18Next.t("profile.provider.zimbra.mailing.rights.inbox"));
      }

      if (this.permissions.hasSendOnBehalfRights) {
        rights.push(this.i18Next.t("profile.provider.zimbra.mailing.rights.sendOnBehalf"));
      }
    }
    return rights.join(" | ");
  }

}
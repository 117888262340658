import { Component, OnInit, Input, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { NgbActiveModal, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Constants, ReportProfileAlertResourceBaseModel, ReportProfileResourceBase, UserModel } from '@i2a/web-api-client';
import { StringHelper } from 'projects/web-user-profile/src/app/helpers/string-helper';
import { GuidedTourService } from 'projects/web-user-profile/src/app/services/guided-tour.service';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';
import { ProviderHelper } from 'projects/web-user-profile/src/app/helpers/provider-helper';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

@Component({
  selector: 'i2a-profile-alert-base-modal',
  templateUrl: './profile-alert-base-modal.component.html',
  styleUrls: ['./profile-alert-base-modal.component.scss']
})
export class ProfileAlertBaseModalComponent implements OnInit, OnChanges {

  @Input() public validation: ReportProfileAlertResourceBaseModel;
  @Input() public canSend: boolean;
  @Input() public ignoreCommentToSend: boolean;
  @Input() public modalTitle: string;
  @Input() public activateFullScreen: boolean;
  @Input() public informationKey: string;
  @Input() public loading: boolean = false;
  @Input() public displayHelp: boolean = true;
  @Input() public displayComment: boolean = true;
  @Input() public noOverflow: boolean = false;
  @Input() public displaySend: boolean = true;

  @Input() public resourceProfile: ReportProfileResourceBase<any>;
  @Input() public user: UserModel;

  @Input() public headerTitle: string;

  public manualRemediation: boolean;
  @Input() public disableManualRemediation: boolean;
  @Input() public manualDescriptionKey: string = 'applyCorrection.default.manual.description';
  @Input() public manualLinkKey: string = 'applyCorrection.default.manual.link';
  @Input() public automaticDescriptionKey: string = 'applyCorrection.default.automatic.description';
  
  public buttonSendIsDisabled: boolean;
  public providerTypeLabel: string;
  public providerPhotoUrl: string;
  public alertOpened: boolean;
  public remediate: boolean;

  public SetbuttonSendIsDisabled() {
    if (this.ignoreCommentToSend) {
      this.buttonSendIsDisabled = !this.canSend;
    } else {
      this.buttonSendIsDisabled = !this.canSend && StringHelper.isNullOrWhitespace(this.validation.comments);
    }
  }

  constructor(
    @Inject(I18NEXT_SERVICE) private i18Next: ITranslationService,
    public activeModal: NgbActiveModal,
    private guidedTour: GuidedTourService, 
    public session: SessionService    
  ) {
    this.buttonSendIsDisabled = false;    
  }

  ngOnInit() {
    this.manualRemediation = (this.session?.ManualRemediation ?? false) && !this.disableManualRemediation;
    this.remediate = this.manualRemediation;
    this.alertOpened = !this.remediate;
    
    if (this.validation) {
      this.validation.comments = "";
      this.SetbuttonSendIsDisabled();
    }

    if (this.resourceProfile) {
      if (this.headerTitle) {
        this.providerTypeLabel = this.headerTitle;
      } else { 
        if (this.resourceProfile.providerInstanceResource.providerId === Constants.PROVIDER_ID_MICROSOFT && this.resourceProfile.providerInstanceResource.providerTypeId === Constants.PROVIDER_TYPE_INTRANET) {
          
          this.providerTypeLabel = `${this.i18Next.t(ProviderHelper.GetProviderTypeLabel(this.resourceProfile.providerInstanceResource.providerId, this.resourceProfile.providerInstanceResource.providerTypeId))} ${this.resourceProfile.providerInstanceResource.name}`;
          
        } else {
          this.providerTypeLabel = this.i18Next.t(ProviderHelper.GetProviderTypeLabel(this.resourceProfile.providerInstanceResource.providerId, this.resourceProfile.providerInstanceResource.providerTypeId));
        }
      }
      this.providerPhotoUrl = ProviderHelper.GetProviderImage(this.resourceProfile.providerInstanceResource.providerId, this.resourceProfile.providerInstanceResource.providerTypeId, this.resourceProfile);
    }
  }

  onCommentsChange() {
    this.SetbuttonSendIsDisabled();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.SetbuttonSendIsDisabled();
  }

  send() {
    this.activeModal.close(this.validation);
  }

  ok() {
    this.activeModal.close();
  }

  help() {
    this.guidedTour.startModalTour(false);
  }

  close() {
    this.activeModal.close();
  }

  public beforeChange($event: NgbPanelChangeEvent) {
    this.alertOpened = !this.alertOpened;
  }
}
import { Injectable, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MicrosoftOfficeProfileProviderDatasourceService, ZimbraProfileProviderDatasourceService, AlertInvalidStateFeaturedModel, ReportProfileDeviceModel, AlertConstants, ReportProfileDeviceSyncModel, ReportProfileAlertBaseModel, UserReportProfileAlertDevicesModel, ReportProfileBaseModel, Constants, UserModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { DeviceWithAlert } from '../models/users/device-with-alert';
import { AlertReasonHelper } from '../helpers/alert-reason-helper';
import { DeviceModalComponent } from '../components/profile/user/device-modal/device-modal.component';
import { TemplateConstants } from '../models/templates/template-constants';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs';
import dayJS from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceService implements OnDestroy {
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  protected subscriptions = new Subscription();

  constructor(
    private modalService: NgbModal,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    private zimbraDatasource: ZimbraProfileProviderDatasourceService
  ) { }


  public mapRegisteredDevices(alerts: AlertInvalidStateFeaturedModel[], devices: ReportProfileDeviceModel[]): DeviceWithAlert[] {
    let deviceInAlertNotClosed: { [id: string]: boolean; } = AlertReasonHelper.GetIds(alerts, AlertConstants.ALERT_INVALID_STATE_CONTEXT_USER_DEVICE_ID, false);
    let deviceInAlertClosed: { [id: string]: boolean; } = AlertReasonHelper.GetIds(alerts, AlertConstants.ALERT_INVALID_STATE_CONTEXT_USER_DEVICE_ID, true);

    return devices.map(d => {
      let item = new DeviceWithAlert();
      item.image = `/assets/pictos/devices/${d.deviceType}.png`;
      item.name = d.name;
      if (Object.keys(deviceInAlertClosed).indexOf(d.id) >= 0) {
        item.hasAlert = deviceInAlertClosed[d.id];
      } else if (Object.keys(deviceInAlertNotClosed).indexOf(d.id) >= 0) {
        item.hasAlertRecentlyClosed = deviceInAlertNotClosed[d.id];
      }
      item.model = d;
      item.lastActionDate = null;
      d.syncDevices.forEach(syncDevice => {
        if (item.lastActionDate == null || dayJS(syncDevice.lastActionDate).isBefore(dayJS(item.lastActionDate))) {
          item.lastActionDate = syncDevice.lastActionDate;
        }
      });
      return item;
    });
  }

  public mapUnRegisteredDevices(alerts: AlertInvalidStateFeaturedModel[], devices: ReportProfileDeviceSyncModel[]): DeviceWithAlert[] {
    let notRegisteredDeviceInAlertNotClosed: { [id: string]: boolean; } = AlertReasonHelper.GetIds(alerts, AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DEVICE_SYNC_ID, false);
    let notRegisteredDeviceInAlertClosed: { [id: string]: boolean; } = AlertReasonHelper.GetIds(alerts, AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DEVICE_SYNC_ID, true);

    return devices.map(d => {
      let item = new DeviceWithAlert();
      item.image = `/assets/pictos/devices/${d.deviceType}.png`;
      item.name = d.name;
      item.model = d;
      item.lastActionDate = d.lastActionDate;
      if (Object.keys(notRegisteredDeviceInAlertNotClosed).indexOf(d.syncId) >= 0) {
        item.hasAlert = notRegisteredDeviceInAlertNotClosed[d.syncId];
      } else if (Object.keys(notRegisteredDeviceInAlertClosed).indexOf(d.syncId) >= 0) {
        item.hasAlertRecentlyClosed = notRegisteredDeviceInAlertClosed[d.syncId];
      }
      return item;
    });
  }

  public warnClickUserDevices(resourceProfile: ReportProfileResourceBase<any>, user: UserModel, profile: ReportProfileBaseModel, devices: DeviceWithAlert[], notRegisteredDevices: DeviceWithAlert[], 
                              initAlertModel: (model: ReportProfileAlertBaseModel) => void,
                              setAlertsProcessed: (alerts: AlertInvalidStateFeaturedModel[])=> void) {
    const modalRef = this.modalService.open(DeviceModalComponent, { windowClass: 'id-modal', centered: true });
    modalRef.componentInstance.disableManualRemediation = true;
    modalRef.componentInstance.devices = devices;
    modalRef.componentInstance.notRegisteredDevices = notRegisteredDevices;
    modalRef.componentInstance.user = user;
    modalRef.componentInstance.resourceProfile = resourceProfile;
    modalRef.result.then((model: UserReportProfileAlertDevicesModel) => {
      initAlertModel(model);
      let result$: Observable<any> = null;
      if (profile.providerId == Constants.PROVIDER_ID_MICROSOFT) {
        result$ = this.microsoftDatasource.alertUserDevices(profile.providerInstanceId, profile.userId, model);
      } else if (profile.providerId == Constants.PROVIDER_ID_ZIMBRA) {
        result$ = this.zimbraDatasource.alertUserDevices(profile.providerInstanceId, profile.userId, model);
      }
      if (result$) {
        this.subscriptions.add(result$.subscribe(model => {
          setAlertsProcessed(model);
        }));
      }
    }).catch((error) => {
      // console.log(error); 
    });
  }
}

import { ProviderInstanceCoupleCollectedModel } from '../models/provider-instance-couple-collected-model';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatasourceService } from '../../data/datasource.service';

@Injectable({
	providedIn: 'root'
})
export class ProviderDatasourceService {

private route: string = "Provider";

	constructor(private datasource: DatasourceService) {
		console.log("Init ProviderDatasourceService");
	}

	public photo_Obsolete(providerId: string, providerTypeId: string, providerInstanceId: string, useCache: boolean = false, throwError: boolean = true): Observable<HttpResponse<Blob>> {
		let partialUrl = `${this.route}/Photo/${providerId}/${providerTypeId}/${providerInstanceId}`;

		return this.datasource.get<HttpResponse<Blob>>(partialUrl, { }, useCache, throwError, 'blob','response');
	}

	public loadCollectedResources_Obsolete(userId: string, useCache: boolean = false, throwError: boolean = true): Observable<ProviderInstanceCoupleCollectedModel[]> {
		let partialUrl = `${this.route}/CollectedResource/User/${userId}`;

		return this.datasource.get<ProviderInstanceCoupleCollectedModel[]>(partialUrl, { }, useCache, throwError);
	}

	public getAuditReportFile(fileId: string, useCache: boolean = false, throwError: boolean = true): Observable<HttpResponse<Blob>> {
		let partialUrl = `${this.route}/CollectedResource/Audit/${fileId}`;

		return this.datasource.get<HttpResponse<Blob>>(partialUrl, { }, useCache, throwError, 'blob','response');
	}

}

import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../authentication/authentication.service';
import { LoadingHelper } from '../../helpers/loading-helper';

@Component({
  selector: 'i2a-sign-out',
  templateUrl: '../auth-loading.component.html'
})
export class SignOutComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService) { }

  public loadingText: string;

  async ngOnInit() {
    try {
      this.loadingText = LoadingHelper.getLoadingText();
    } catch (error) {
      this.loadingText = "Loading in progress, please wait...";
    }

    this.authenticationService.cleanStates();
    this.authenticationService.startAuthentication();
  }
}

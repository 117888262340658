import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Navigation, PRIMARY_OUTLET } from '@angular/router';
import { SessionService } from '../../services/session-service';
import { TenantSummaryModel } from '@i2a/web-api-client';

@Component({
  selector: 'i2a-tenants',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.scss']
})
export class TenantsComponent  {
  public tenants: TenantSummaryModel[];
  public currentNavigation: Navigation;

  constructor(
    public session: SessionService,
    public router: Router,
    public activatedRoute: ActivatedRoute) {
      this.currentNavigation = this.router.getCurrentNavigation();
  }

  ngOnInit() {
    var g = this.currentNavigation.finalUrl.root.children[PRIMARY_OUTLET];
    if (g.segments[g.segments.length -1].path == "tenants") {
      if (this.session.Account.tenants.length == 1) {
        this.tenants = null;
        this.router.navigate([this.session.Account.tenants[0].code], { relativeTo: this.activatedRoute });
      }
    }
    this.tenants = this.session.Account.tenants;
  }
}

<i2a-profile-card *ngIf="inboxRule" [id]="id" [title]="title" [viewMoreDetails]="true"
  (onViewMoreDetails)="openDetails($event)" (onWarnClick)="warnClick($event)" [hasAlert]="hasAlert"
  [hasAlertRecentlyClosed]="hasAlertRecentlyClosed" [warningLevel]="warningLevel">
  <ng-container picto>
    <i2a-badge-icon>
      <ng-container icon>
        <fa-layers [fixedWidth]="true">
          <fa-icon [icon]="['fal', 'envelope']" transform="left-5"></fa-icon>
          <fa-icon [icon]="['fal', 'arrow-from-left']" transform="right-9"></fa-icon>
        </fa-layers>
      </ng-container>
    </i2a-badge-icon>
  </ng-container>

  <ng-container content>
    <div class="container p-0 exchange-forward">
      <div class="row no-gutters">
        <div class="col m-2">
          <p class="warn-account mb-0" *ngIf="inboxRule && inboxRule.isExternalForward">
            {{ ("profile.provider.microsoft.exchange.warnAccount.reports.externalForward")  | i18next  }}
          </p>
        </div>
      </div>
    </div>
  </ng-container>

</i2a-profile-card>

<ng-template #viewDetailTemplate>
  <i2a-modal-profile-header-title>
    <ng-container picto>
      <i2a-badge-icon>
        <ng-container icon>
          <fa-layers [fixedWidth]="true">
            <fa-icon [icon]="['fal', 'envelope']" transform="left-5"></fa-icon>
            <fa-icon [icon]="['fal', 'arrow-from-left']" transform="right-9"></fa-icon>
          </fa-layers>
        </ng-container>
      </i2a-badge-icon>
    </ng-container>
    <ng-container title>
      <span [title]="title">{{title}}</span>
    </ng-container>
  </i2a-modal-profile-header-title>

  <ul class="list">
    <li *ngFor="let line of description"
      [ngClass]="{'list-title': line.indexOf(':') >0, 'list-info': line.indexOf(':') == -1}">{{line}}<br></li>
  </ul>
</ng-template>
import { SharePointGroupMemberModel } from '../../microsoft/sharepoint/share-point-group-member-model';
import { SharePointGroupPrincipalModel } from '../../microsoft/sharepoint/share-point-group-principal-model';
import { WellKnownGroupType } from '../../../microsoft/models/o365/sharepoint/well-known-group-type';

export class SharePointGroupModel {
  id: number;
  title: string;
  description: string;
  owner: SharePointGroupPrincipalModel;
  members: SharePointGroupMemberModel[];
  wellKnownGroupType: WellKnownGroupType;
  membersFiltered: boolean;
  o365GroupMemberFiltered: boolean;
}

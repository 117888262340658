import { ComponentRegistry } from '../services/component-registry';

const I2A_COMP_ANNOTATIONS = '__i2a_annotations__';

export function RegisterMyProfileComponent(componentId: string): (cls: any) => void {

  return function (componentType: any) {
    componentType[I2A_COMP_ANNOTATIONS] = { componentId: componentId };
    ComponentRegistry.registerComponent(componentType, componentId);
    Reflect.defineProperty(componentType.prototype, "componentId", {value: componentId});
  };
}

export { I2A_COMP_ANNOTATIONS };
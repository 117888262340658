<div *ngIf="!init" class="user-list">
  <i2a-sub-title class="col-12" [title]="'users.title' | i18next  "></i2a-sub-title>

  <div class="id-blocksearch">
    <div class="id-blocksearch-header">
      <div class="row id-list-search">
        <div class="col-xs-3">
          <input type="text" id="userName" [(ngModel)]="userName" (ngModelChange)="search()" />
        </div>
        <div class="col-xs-3">
          <!--  orgUnit filter -->
        </div>
        <div class="col-xs-2 id-header-checkbox">
        </div>
      </div>
    </div>
  </div>
  <div>

    <div class="users" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="loadUsers()">
      <table class="table table-striped table-sm ">
        <thead>
          <tr>
            <th scope="col">{{"users.search.fullname" | i18next  }}</th>
          </tr>
        </thead>
        <tbody *ngIf="users">
          <tr *ngFor="let user of users">
            <td scope="row">
              <a [routerLink]="['..', 'user', user.id,'profile']">
                {{user.fullName}}
              </a>
              <a *ngIf="hasAdminRole()" [routerLink]="['..', 'user', user.id,'profile']" [queryParams]="{userView:true}">
                  <fa-icon [icon]="['fas', 'eye']" [title]="'profile.viewAsUserTitle' | i18next"></fa-icon>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<i2a-spinner *ngIf="!users"></i2a-spinner>
import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { Observable, Subscription, Subject, merge } from 'rxjs';
import { ReportProfileAlertResourceNotBelongToMeModel, UsersDatasourceService, UserModel, BaseProviderInstanceResourceModel, ProviderInstanceResourcesDatasourceService, UserSummaryModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { StringHelper } from 'projects/web-user-profile/src/app/helpers/string-helper';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-resource-not-belong-to-me-modal',
  templateUrl: './resource-not-belong-to-me-modal.component.html',
  styleUrls: ['./resource-not-belong-to-me-modal.component.scss']
})
export class ResourceNotBelongToMeModalComponent implements OnInit, OnDestroy {

  protected subscriptions = new Subscription();

  public validation: ReportProfileAlertResourceNotBelongToMeModel;
  @Input() public resource: BaseProviderInstanceResourceModel;
  @Input() public owner: UserSummaryModel;
  @Input() public hideI2AOwners: boolean;
  @Input() public resourceProfile: ReportProfileResourceBase<any>;
  @Input() public headerTitle: string;

  public canSend: boolean;

  private limit = 5;
  public owners: UserSummaryModel[];
  private _withOwner: boolean;
  public get withOwner(): boolean {
    return this._withOwner;
  }
  public set withOwner(v: boolean) {
    this._withOwner = v;
    this.processModel();
  }

  constructor(
    private resourceDatasource: ProviderInstanceResourcesDatasourceService,
    private usersDatasource: UsersDatasourceService,
    public session: SessionService
  ) {
    this.withOwner = false;
    this._OwnerUser = null;
    this.user = null;
  }

  ngOnInit() {
    this.validation = new ReportProfileAlertResourceNotBelongToMeModel();

    if (!this.session.SharePointGroupBySite || !this.headerTitle) {
      this.headerTitle = null;
    }

    if (this.resource && this.owner) {
      this.subscriptions.add(this.resourceDatasource.loadOwners(this.resource.providerId, this.resource.providerTypeId, this.resource.providerInstanceId, this.resource.providerInstanceResourceId)
        .subscribe(async model => {
          this.owners = model.filter(owner => owner.id != this.owner.id).sort((a, b) => { return a.fullName.localeCompare(b.fullName, undefined, { sensitivity: 'base' }); });
          this.withOwner = this.owners.length > 0;
        }));
    }
  }

  private processModel() {
    this.canSend = (!this.withOwner && this._user != null) || (this.withOwner && this._OwnerUser != null);
  }

  ownerChange(userId: string) {
    if (StringHelper.isNullOrWhitespace(userId)) {
      this._OwnerUser = null;
      this.validation.userId = null;
    }
    else {
      this._OwnerUser = this.owners.find(u => u.id == userId);
      this.validation.userId = this._OwnerUser.id;
    }
    this.processModel();
  }

  withOwnerChange(evt) {
    this.user = null;
  }

  withI2AUserChange(evt) {
    this.user = null;
  }
  // #region typehead users

  private _user: UserSummaryModel;
  public get user(): UserSummaryModel {
    return this._user;
  }
  public set user(v: UserSummaryModel) {
    this._user = v;
    if (v != null) {
      this.validation.userId = this._user.id;
    }
    this.processModel();
  }

  searching = false;
  searchFailed = false;

  formatter(user: UserModel) {
    return user.fullName + " (" + user.email + ")";
  }
  
  
  @ViewChild('userListInstance') userListInstance: NgbTypeahead;
  public userFocus$ = new Subject<string>();
  public userClick$ = new Subject<string>();

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.userClick$.pipe(filter(() => this.userListInstance && !this.userListInstance.isPopupOpen()));
    const inputFocus$ = this.userFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      switchMap(term => {
        var items = this.usersDatasource.searchSummary(term, null, this.limit, 0).pipe(
          map(page => {
            this.searchFailed = false;
            return page.items.filter(user=> user.id != this.owner.id).sort((a, b) => { return a.fullName.localeCompare(b.fullName, undefined, { sensitivity: 'base' }); });
          }));
        return items;
      }));
    }


  @ViewChild('ownerListInstance') ownerListInstance: NgbTypeahead;
  public ownerFocus$ = new Subject<string>();
  public ownerClick$ = new Subject<string>();

  private _OwnerUser: UserSummaryModel;
  public get ownerUser(): UserSummaryModel {
    return this._OwnerUser;
  }
  public set ownerUser(v: UserSummaryModel) {
    this._OwnerUser = v;
    if (v != null) {
      this.validation.userId = this._OwnerUser.id;
    }
    this.processModel();
  }


  formatterOwnerUser = (user: UserSummaryModel) => user.fullName + " (" + user.email + ")";

  searchOwnerUser = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.ownerClick$.pipe(filter(() => this.ownerListInstance && !this.ownerListInstance.isPopupOpen()));
    const inputFocus$ = this.ownerFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => {
        let ownersFound = this.owners.filter(u => u.fullName.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) >= 0);
        this.searchFailed = false;
        return ownersFound.slice(0, 5);
      }));
  }

  // #endregion

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}

import { ProviderInstanceResourceSummaryModel } from './provider-instance-resource-summary-model';

export class ProviderInstanceResourceModel extends ProviderInstanceResourceSummaryModel {
  isPermanentAuditing: boolean;
  isRealTimeProcessing: boolean;
  isInLearning: boolean;
  isAudited: boolean;
  realTimeLearningStop: Date|null;
  auditStop: Date|null;
  auditingPolicy: string;
  creationDate: Date;
}

<div class="module">
  <div *ngIf="delegates && delegates.length > 0 || externalDelegates && externalDelegates.length > 0"
    class="container-fluid module-container">
    <div class="row no-gutters">
      <i2a-sub-title class="col-12" [subTitle]="'profile.provider.microsoft.sharePoint.title.delegateAccess' | i18next "
        [description]="'profile.provider.microsoft.sharePoint.description.delegateAccess' | i18next ">
      </i2a-sub-title>
    </div>
    <div class="row no-gutters">

      <!-- EXTERNAL DELEGATES -->
      <div *ngIf="externalDelegates?.length > 0" class="col-sm-12 col-md-6 col-lg-4 align-items-start">

        <i2a-share-point-external-delegates [alerts]="alerts" [componentId]="componentId"
          [delegates]="externalDelegates" [isAlert]="isAlert" [profile]="profile"
          [providerInstanceResource]="resourceProfile.providerInstanceResource" [resourceProfile]="resourceProfile"
          [user]="user" [viewWarnButton]="!configuration.hideWarnButtonForAccesses" (onAlertSent)="alertSent($event)"
          (onHasWarnings)="hasWarnings($event)">
        </i2a-share-point-external-delegates>
      
      </div>

      <!-- DELEGATES -->
      <div *ngFor="let delegate of delegates | slice:0:maxShowMore" class="col-sm-12 col-md-6 col-lg-4 align-items-start">
        
          <i2a-share-point-delegate [alerts]="alerts" [componentId]="componentId" [delegate]="delegate"
            [isAlert]="isAlert" [profile]="profile"
            [providerInstanceResource]="resourceProfile.providerInstanceResource" [resourceProfile]="resourceProfile"
            [user]="user" [viewWarnButton]="!configuration.hideWarnButtonForAccesses"
            (onAlertSent)="alertSent($event)" (onHasWarnings)="hasWarnings($event)">
          </i2a-share-point-delegate>   

      </div>

      <div *ngIf="isVisibleButtonsShowMore" class="col-12 p-2 text-center">
        <button class="btn btn-outline-primary" (click)="onShowMore()" *ngIf="!showMore">{{ 'button.showMore' | i18next }}</button>
        <button class="btn btn-outline-primary" (click)="onShowMore()" *ngIf="showMore">{{ 'button.showLess' | i18next }}</button>
      </div>     

    </div>
  </div>
</div>
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'i2a-share-point-permission-legend',
  templateUrl: './share-point-permission-legend.component.html',
  styleUrls: ['./share-point-permission-legend.component.scss']
})
export class SharePointPermissionLegendComponent implements OnInit {

  @Input() displayReadLegend: boolean;
  @Input() displayUpdateLegend: boolean;
  @Input() displayOwnerLegend: boolean;
  @Input() displayUnknownLegend: boolean;
  @Input() hasSensitivityLabel: boolean;
  @Input() isCollapsed: boolean;
  
  constructor() { }

  ngOnInit() {
  }

}

import { BaseOperationModel } from '../base-operation-model';

export class MicrosoftOfficePermanentAuditResourcesModel extends BaseOperationModel {
  defaultCulture: string;
  defaultTimeZone: string;
  orgUnitId: string;
  forceOrgUnitIdOnExistingUsers: boolean;
  sendPasswordEmailToUsers: boolean;
  createUsers: boolean|null;
  providerPolicy: string;
}

<i2a-base-modal [modalTitle]="title" [activateFullScreen]="activateFullScreen">
    <ng-container body>
        <div class="information-modal">
            <div id="modal-header" class="row id-header">
                <table class="table-sm id-table">
                    <tbody>
                        <tr>
                            <td scope="row text-center" class="p-0 id-header-picto">
                                <img class="id-provider-picture" *ngIf="providerPhotoUrl" [src]="providerPhotoUrl"
                                    width="28" height="28">
                            </td>
                            <td scope="row" class="text-break w-100 align-self-center" #headertitle>
                                <span class="provider-type" [title]="providerTypeLabel">{{ providerTypeLabel }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 mt-3 mb-3">
                        <template i2a-container></template>
                        <ng-content select="[body]"></ng-content>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container footer>
        <div class="m-auto">
            <template #buttons i2a-container></template>

            <button *ngIf="displayWarnClick" (click)="modal.close(true)" type="button"
                class="btn btn-red ml-2">{{buttonOpenAlertModalKey | i18next }}</button>

            <button type="button" ngbAutofocus [ngClass]="btnOKClasses" (click)="modal.close(false)">{{ ok }}</button>
        </div>
    </ng-container>
</i2a-base-modal>
import { Component, OnInit, Input, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertConstants, AlertInvalidStateFeaturedModel, ProviderAdminObjectThreatReasonModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { AlertReasonHelper } from '../../../helpers/alert-reason-helper';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';

@Component({
  selector: 'i2a-display-alerts-reasons-modal',
  templateUrl: './display-alerts-reasons-modal.component.html',
  styleUrls: ['./display-alerts-reasons-modal.component.scss']
})
export class DisplayAlertsReasonsModalComponent implements OnInit {
  @Input() public alerts: AlertInvalidStateFeaturedModel[];
  @Input() public alertReasons: string[];
  @Input() public resourceProfile: ReportProfileResourceBase<any>;

  public modalTitle: string = '';
  public reasons: { alertClosed: boolean, reason: ProviderAdminObjectThreatReasonModel }[];

  constructor(
    public activeModal: NgbActiveModal,
    @Inject(I18NEXT_SERVICE) public i18Next: ITranslationService
  ) {
    this.reasons = [];
    this.alertReasons = [];
  }

  ngOnInit() {
    if (this.alerts) {
      this.modalTitle = this.alerts.some(a => a.end !== null) ? this.i18Next.t("profile.title.alertDetailAndDone") : this.i18Next.t("profile.title.alertDetail");
      this.alerts.forEach(alert => {
        var alertClosed = alert.end != null;
        if (this.resourceProfile != null) {
          var reasons = AlertReasonHelper.findReasons(alert, AlertConstants.ALERT_INVALID_STATE_CONTEXT_PROVIDERINSTANCE_RESOURCE_ID, this.resourceProfile.providerInstanceResource.providerInstanceResourceId);
          if (reasons.length > 0) {
            this.pushReasons(alertClosed, reasons);
          } else {
            this.pushReasons(alertClosed, alert.reasons);
          }
        } else {
          this.pushReasons(alertClosed, alert.reasons);
        }
      });
    }
  }

  private pushReasons(alertClosed: boolean, reasons: ProviderAdminObjectThreatReasonModel[]) {
    reasons.forEach(reason => {
      Object.assign(reason.reasonResourceArguments, { interpolation: { escapeValue: false } });
      if (this.alertReasons.length == 0 || this.alertReasons.indexOf(reason.reasonResourceKey) >= 0) {
        this.reasons.push({ alertClosed: alertClosed, reason: reason });
      }
    });
  }

  close() {
    this.activeModal.close();
  }
}

import { BaseProviderInstanceResourceModel } from '../../../base-provider-instance-resource-model';
import { ChangeResult } from '../../../change-result';
import { FileShareReportProfileDelegate } from '../../../microsoft/fileshare/profile/file-share-report-profile-delegate';
import { ReportProfileResourceChangesBaseModel } from '../../../reports/profile/report-profile-resource-changes-base-model';
import { ReportProfileRulesComputedDelegate } from '../../../profile/report-profile-rules-computed-delegate';

export class FileShareReportProfileChangesModel extends ReportProfileResourceChangesBaseModel<ReportProfileRulesComputedDelegate> {
  delegates: ChangeResult<FileShareReportProfileDelegate>;
  isEqual: boolean;
  providerInstanceResource: BaseProviderInstanceResourceModel;
}

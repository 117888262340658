<div *ngIf="!hasNoData">
  <i2a-profile-card [id]="id" [viewMoreDetails]="false" [title]="'profile.user.accessMode.title' | i18next"
    (onWarnClick)="warnClick($event)" [hasAlert]="hasAlert" [hasAlertRecentlyClosed]="hasAlertRecentlyClosed"
    [warningLevel]="warningLevel">

    <ng-container picto>
      <i2a-badge-icon [customClass]="'id-bagde-access-modes'">
        <ng-container icon>
          <fa-icon [icon]="['fal', 'globe']" size="lg"></fa-icon>
        </ng-container>
      </i2a-badge-icon>
    </ng-container>

    <ng-container content>
      <div class="container p-0">
        <div class="row no-gutters">
          <div class="col ml-2 mr-2">
            <span class="last-action-date float-right">{{ 'profile.user.accessMode.lastActionDate' | i18next}}</span>
            <table *ngIf="accessModes" class="table table-sm id-table mb-0">
              <tbody>
                <tr *ngFor="let accessMode of accessModesSorted">
                  <td class="align-text-top isSucceeded" *ngIf="configuration.displayIsSucceeded">
                    <fa-icon class="failed" [icon]="['fas', 'times-circle']"
                      [title]="'profile.user.accessMode.failed' | i18next" size="lg" *ngIf="!accessMode.isSucceeded">
                    </fa-icon>
                    <fa-icon class="succeeded" [icon]="['fas', 'check-circle']"
                      [title]="'profile.user.accessMode.succeeded' | i18next" size="lg" *ngIf="accessMode.isSucceeded">
                    </fa-icon>
                  </td>
                  <td scope="row" [ngClass]="{'has-alert': accessMode.hasAlert, 'has-alert-recently-closed': accessMode.hasAlertRecentlyClosed}">
                    {{ accessMode.accessMode }}
                    <span class="last-action-date float-right">{{ accessMode.lastActionDate | amDateFormat: 'L'}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ng-container>

  </i2a-profile-card>
</div>
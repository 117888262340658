<table class="table" *ngIf="permissions">
  <thead>
    <tr>
      <th scope="col">{{ "profile.provider.microsoft.fileShare.path" | i18next }}</th>
      <th scope="col" class="center">{{ "profile.provider.microsoft.fileShare.rights.read" | i18next }}</th>
      <th scope="col" class="center">{{ "profile.provider.microsoft.fileShare.rights.write" | i18next }}</th>
      <th scope="col" class="center">{{ "profile.provider.microsoft.fileShare.rights.execute" | i18next }}</th>
      <th scope="col" class="center">{{ "profile.provider.microsoft.fileShare.rights.modify" | i18next }}</th>
      <th scope="col" class="center">{{ "profile.provider.microsoft.fileShare.rights.fullControl" | i18next }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let rights of permissions.rights">
      <td>
        <span *ngIf="rights.path" >{{rights.path}} </span>
        <span *ngIf="!rights.path">{{"profile.provider.microsoft.fileShare.emptyPath" |i18next}} </span>
      </td>
      <td class="center">
        <fa-icon *ngIf="rights.read" [icon]="['far', 'check-circle']">
        </fa-icon>
      </td>
      <td class="center">
        <fa-icon *ngIf="rights.write" [icon]="['far', 'check-circle']">
        </fa-icon>
      </td>
      <td class="center">
        <fa-icon *ngIf="rights.execute" [icon]="['far', 'check-circle']">
        </fa-icon>
      </td>
      <td class="center">
        <fa-icon *ngIf="rights.modify" [icon]="['far', 'check-circle']">
        </fa-icon>
      </td>
      <td class="center">
        <fa-icon *ngIf="rights.fullControl" [icon]="['far', 'check-circle']">
        </fa-icon>
      </td>
    </tr>
  </tbody>
</table>

import { AlertAnswer } from './alert-answer';
import { AlertAnswerOrigin } from './alert-answer-origin';
import { AlertCommentModel } from './alert-comment-model';
import { BaseModel } from './base-model';
import { BaseProviderInstanceResourceModel } from './base-provider-instance-resource-model';
import { ConnectedUserModel } from './connected-user-model';
import { Criticality } from './criticality';

export class AlertSummaryModel extends BaseModel {
  id: string;
  shortId: string;
  userId: string|null;
  resources: BaseProviderInstanceResourceModel[];
  lastComment: AlertCommentModel;
  commentsCount: number;
  type: string;
  titleResourceKey: string;
  shortDescriptionResourceKey: string;
  longDescriptionResourceKey: string;
  title: string;
  shortDescription: string;
  longDescription: string;
  resourceArguments: { [key: string]: string };
  criticality: Criticality;
  begin: Date;
  end: Date|null;
  endUser: ConnectedUserModel;
  assignee: ConnectedUserModel;
  state: number;
  creationDate: Date;
  modificationDate: Date;
  canDismiss: boolean;
  isPremium: boolean;
  isFalsePositive: boolean|null;
  isTechnical: boolean;
  alertAnswer: AlertAnswer|null;
  alertAnswerOrigin: AlertAnswerOrigin|null;
}

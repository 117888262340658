import { Component, OnInit, Input } from '@angular/core';
import { ExchangeReportProfileInboxRule, ExchangeReportProfileAlertInboxRuleModel, ReportProfileResourceBase } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-exchange-inboxrule-modal',
  templateUrl: './exchange-inboxrule-modal.component.html',
  styleUrls: ['./exchange-inboxrule-modal.component.scss']
})
export class ExchangeInboxruleModalComponent implements OnInit {
  @Input() public inboxRule: ExchangeReportProfileInboxRule;

  
  @Input() public resourceProfile: ReportProfileResourceBase<any>;

  public validation: ExchangeReportProfileAlertInboxRuleModel;
  public canSend: boolean;
  public title: string;

  constructor(
    private modalService: NgbModal, 
    public session: SessionService
    ) { 
      this.canSend = false || this.validation?.comments.length > 0 || session.IgnoreCommentToSend;
    }
  
  ngOnInit() {
    this.validation = new ExchangeReportProfileAlertInboxRuleModel();
  }
}

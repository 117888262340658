import { BaseOperationModel } from '../base-operation-model';

export class MicrosoftOfficeProtectResourcesModel extends BaseOperationModel {
  learningDuration: string|null;
  learningEndDate: Date|null;
  defaultCulture: string;
  defaultTimeZone: string;
  orgUnitId: string;
  orgUnitEndLearningId: string|null;
  forceOrgUnitIdOnExistingUsers: boolean;
  sendPasswordEmailToUsers: boolean;
  isPremium: boolean|null;
  providerPolicy: string;
  countries: string[];
}

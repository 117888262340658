<i2a-profile-card [id]="id" *ngIf="group" [viewMoreDetails]="true" (onViewMoreDetails)="openDetails($event)"
  [title]="group.displayName" [subTitle]="'profile.provider.microsoft.sharePoint.subTitle.o365Group' | i18next"
  (onWarnClick)="warnClick($event)" [hasAlert]="hasAlert" [hasAlertRecentlyClosed]="hasAlertRecentlyClosed"
  [warningLevel]="warningLevel" [viewWarnButton]="false">

  <ng-container picto>
    <i2a-badge-icon>
      <ng-container icon>
        <img width="24px" height="24px" class="align-img align-img-o365" src="assets/pictos/providers/o365.svg">
      </ng-container>
    </i2a-badge-icon>
  </ng-container>

  <ng-container content>
    <div class="p-0 share-point-o365-group">
      <div class="row no-gutters">
        <div class="col-12">
          <div class="row no-gutters p-2">
            <div class="col-4">
              <span class="id-title">{{"profile.provider.microsoft.sharePoint.group.owners" | i18next }}</span>
              <span class="id-value"> {{ totalOwners }}</span>
            </div>
            <div class="col-4">
              <span class="id-title">{{"profile.provider.microsoft.sharePoint.group.members" | i18next }}</span>
              <span class="id-value"> {{ totalMembers }}</span>
            </div>
            <div class="col-4">
              <span class="id-title">{{"profile.provider.microsoft.sharePoint.group.guests" | i18next }}</span>
              <span class="id-value"> {{ totalGuests }}</span>
            </div>
          </div>
        </div>
        <div class="col-6" *ngIf="displaySecurity">
          <div class="m-2">
            <span class="id-title">{{"profile.provider.microsoft.sharePoint.group.security.title" | i18next }}</span>
            <span class="id-value"> {{ security }}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</i2a-profile-card>

<ng-template #viewDetailTemplate>
  <i2a-modal-profile-header-title>
    <ng-container picto>
      <i2a-badge-icon>
        <ng-container icon>
          <img width="24px" height="24px" class="align-img align-img-o365" src="assets/pictos/providers/o365.svg">
        </ng-container>
      </i2a-badge-icon>
    </ng-container>
    <ng-container title>
      <span [title]="group.displayName">{{group.displayName}}</span>
    </ng-container>
  </i2a-modal-profile-header-title>

  <div class="row no-gutters">
    <div class="col-12 font-italic pb-2">
      {{"profile.provider.microsoft.sharePoint.group.createdDateTime" | i18next }}
      {{ group.createdDateTime | amDateFormat: 'LLL'}}
    </div>
  </div>
  <div class="row no-gutters">
    <div class="col ml-2 mr-2">
      <ng-container *ngIf="owners.length > 0">
        <span class="resource-name">{{"profile.provider.microsoft.sharePoint.group.owners" | i18next }}</span>
        <div class="ml-2">
          <table class="table table-sm table-bordered id-table">
            <tbody>
              <tr *ngFor="let owner of owners">
                <td scope="row" [ngClass]="{'text-break': true, 'has-alert': owner.hasAlert, 'has-alert-recently-closed': owner.hasAlertRecentlyClosed}">
                  {{owner.displayName}}
                </td>
              </tr>
            </tbody>
          </table>
          <!-- Permissions -->
          <p>{{'profile.provider.microsoft.sharePoint.driveItem.rootPathPermission.owner' | i18next }}</p>
          <i2a-short-title-bar [isCollapsed]="true" [hasAlert]="false" *ngIf="hasOwnerDriveItems">
            <ng-container title>{{'profile.provider.microsoft.sharePoint.driveItem.specificPaths' | i18next }}
            </ng-container>
            <ng-container body>
              <p></p>
              <i2a-share-point-permission-detail [driveItems]="ownerDriveItems" [displayLegend]="false">
              </i2a-share-point-permission-detail>
            </ng-container>
          </i2a-short-title-bar>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="col ml-2 mr-2">
      <ng-container *ngIf="members.length > 0">
        <hr />
        <span class="resource-name">{{"profile.provider.microsoft.sharePoint.group.members" | i18next }}</span>
        <div class="ml-2">
          <table class="table table-sm table-bordered id-table">
            <tbody>
              <tr *ngFor="let member of members">
                <td scope="row" class="text-break" [ngClass]="{'text-break': true, 'has-alert': member.hasAlert, 'has-alert-recently-closed': member.hasAlertRecentlyClosed}">
                  {{member.displayName}}
                </td>
              </tr>
            </tbody>
          </table>
          <!-- Permissions -->
          <p>{{'profile.provider.microsoft.sharePoint.driveItem.rootPathPermission.member' | i18next }}</p>
          <i2a-short-title-bar [isCollapsed]="true" [hasAlert]="false" *ngIf="hasMemberDriveItems">
            <ng-container title>{{'profile.provider.microsoft.sharePoint.driveItem.specificPaths' | i18next }}
            </ng-container>
            <ng-container body>
              <i2a-share-point-permission-detail [driveItems]="memberDriveItems" [displayLegend]="false">
              </i2a-share-point-permission-detail>
            </ng-container>
          </i2a-short-title-bar>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="col ml-2 mr-2">
      <ng-container *ngIf="guests.length > 0">
        <hr />
        <span class="resource-name">{{"profile.provider.microsoft.sharePoint.group.guests" | i18next }}</span>
        <div class="ml-2">
          <table class="table table-sm table-bordered id-table">
            <tbody>
              <tr *ngFor="let guest of guests">
                <td scope="row" class="text-break" [ngClass]="{'text-break': true, 'has-alert': guest.hasAlert, 'has-alert-recently-closed': guest.hasAlertRecentlyClosed}">
                  {{guest.displayName}}
                </td>
              </tr>
            </tbody>
          </table>
          <!-- Permissions -->
          <i2a-short-title-bar [isCollapsed]="true" [hasAlert]="false" *ngIf="hasGuestDriveItems">
            <ng-container title>{{'profile.provider.microsoft.sharePoint.driveItem.paths' | i18next }}</ng-container>
            <ng-container body>
              <i2a-share-point-permission-detail [driveItems]="guestDriveItems" [displayLegend]="false">
              </i2a-share-point-permission-detail>
            </ng-container>
          </i2a-short-title-bar>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
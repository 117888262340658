import { BaseProviderInstanceResourceModel } from '../base-provider-instance-resource-model';
import { DeviceType } from '../device-type';

export class ReportProfileDeviceSyncModel extends BaseProviderInstanceResourceModel {
  syncId: string;
  name: string;
  model: string;
  deviceType: DeviceType;
  lastActionDate: Date|null;
}

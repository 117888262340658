import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StringHelper } from 'projects/web-user-profile/src/app/helpers/string-helper';
import { WarningLevel } from 'projects/web-user-profile/src/app/models/warnings/warning-level';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';

@Component({
  selector: 'i2a-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardComponent implements OnInit {
  @Input() public title: string;
  @Input() public subTitle: string;
  @Input() public hasAlert: boolean;
  @Input() public hasAlertRecentlyClosed: boolean;
  @Input() public noContent: boolean;
  @Input() public viewMoreDetails: boolean;
  @Input() public viewMoreDetailsKey: string;
  @Input() public buttonOpenAlertModalKey: string;
  @Input() public loading: boolean;
  

  @Input() public warningLevel: WarningLevel;

  public buttonVviewMoreDetailbuttonKey: string;

  public isWarningHigh: boolean = false;
  public isWarningMiddle: boolean = false;
  public isWarningLow: boolean = false;
  
  @Input() public viewWarnButton: boolean;

  @Output()
  public onWarnClick = new EventEmitter();

  @Output()
  public onViewMoreDetails = new EventEmitter();

  constructor(private session: SessionService) {
    this.hasAlert = false;
    this.viewMoreDetails = false;
    this.viewWarnButton = true;
    this.loading = false;
    this.buttonVviewMoreDetailbuttonKey = "button.viewMoreDetails";
    this.buttonOpenAlertModalKey = "button.openAlertModal";
   }

  ngOnInit() {
    if (!StringHelper.isNullOrWhitespace(this.viewMoreDetailsKey)) {
      this.buttonVviewMoreDetailbuttonKey = this.viewMoreDetailsKey;
    }
    
    if (this.session.WarningConfiguration && !this.session.WarningConfiguration.doNotHighlightCard) {
      this.isWarningHigh = this.warningLevel === WarningLevel.High;
      this.isWarningMiddle = this.warningLevel === WarningLevel.Medium;
      this.isWarningLow = this.warningLevel === WarningLevel.Low;
    }    
  }

  warnClick(): void {
    this.onWarnClick.emit({});
  }

  viewMoreDetailsClick(): void {
    this.onViewMoreDetails.emit({});
  }
}

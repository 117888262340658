<div class="module">
  <div class="container-fluid module-container" *ngIf="grants.length > 0">
    <div class="row no-gutters">
      <i2a-sub-title class="col-12" [subTitle]="'profile.provider.microsoft.azureAD.title.grants' | i18next "
        [description]="'profile.provider.microsoft.azureAD.description.grants' | i18next "></i2a-sub-title>
    </div>
    <div class="row no-gutters">
      <div *ngFor="let azureADAppPermissionGrant of grants" class="col-sm-12 col-md-6 col-lg-4 align-items-start">
        <i2a-azure-ad-permission-grants [resourceProfile]="resourceProfile" [componentId]="componentId" [grants]="azureADAppPermissionGrant"
          [isAlert]="isAlert" [alerts]="alerts" [profile]="profile" [user]="user"
          [providerInstanceResource]="resourceProfile.providerInstanceResource" (onAlertSent)="alertSent($event)" (onHasWarnings)="hasWarnings($event)"
          [showDetails]="configuration.showDetails">
        </i2a-azure-ad-permission-grants>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MicrosoftOfficeProfileProviderDatasourceService } from '@i2a/web-api-client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileShareReportProfileAlertDelegateModel } from '@i2a/web-api-client';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { BaseFileShareDelegateComponent } from '../base-file-share-delegate/base-file-share-delegate.component';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'i2a-file-share-delegate',
  templateUrl: '../base-file-share-delegate/base-file-share-delegate.component.html',
  styleUrls: ['../base-file-share-delegate/base-file-share-delegate.component.scss']
})
export class FileShareDelegateComponent extends BaseFileShareDelegateComponent implements OnInit, OnDestroy {

  constructor(
    modalService: NgbModal,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService) {
    super(modalService, toastr, i18Next);
}

  ngOnInit() {
    super.ngOnInit();
  }

  protected AlertFileShareDelegate(model: FileShareReportProfileAlertDelegateModel):Observable<any> {
    return this.microsoftDatasource.alertFileShareDelegate(this.profile.providerInstanceId, this.profile.userId, <any>model);
  }
  
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}

import { ProviderAuditResourceKind } from '../provider-audit-resource-kind';
import { ReportProfileAlertResourceBaseModel } from '../profile/report-profile-alert-resource-base-model';
import { ReportProfileRulesComputedDelegate } from '../profile/report-profile-rules-computed-delegate';

export class ReportProfileAlertRulesComputedDelegateModel extends ReportProfileAlertResourceBaseModel {
  delegate: ReportProfileRulesComputedDelegate;
  allFolders: boolean;
  folders: string[];
  kinds: ProviderAuditResourceKind;
  deviceIds: string[];
}

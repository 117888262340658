<i2a-profile-alert-base-modal
  [manualDescriptionKey]="'applyCorrection.providers.microsoft.sharePoint.group.o365.manual.description'"
  [manualLinkKey]="'applyCorrection.providers.microsoft.sharePoint.group.o365.manual.link'"
  [automaticDescriptionKey]="'applyCorrection.providers.microsoft.sharePoint.group.o365.automatic.description'"
  [ignoreCommentToSend]="session.IgnoreCommentToSend" [displayComment]="session.DisplayComment" [displayHelp]="false"
  [validation]="validation" [canSend]="canSend" [modalTitle]="title | i18next " [informationKey]="informationKey"
  [activateFullScreen]="true" [resourceProfile]="resourceProfile" [headerTitle]="headerTitle">

  <ng-container header-picto>
    <i2a-badge-icon>
      <ng-container icon>
        <img width="24px" height="24px" class="align-img align-img-o365" src="assets/pictos/providers/o365.svg">
      </ng-container>
    </i2a-badge-icon>
  </ng-container>

  <ng-container header-title>
    {{group.displayName}}<br>
    {{description}}
  </ng-container>

  <ng-container message>
    <div class="row pt-3" *ngIf="hasToShowMessage">
      <div class="col-12">
        <i2a-message>
          <ng-container message-content>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 13.125 13.125" style="vertical-align: sub;margin-right: 5px;">
            <path d="M363.563,108.875L357,122h13.125Zm0.437,5.25V118.5h-0.875v-4.375H364Zm-0.875,5.25H364v0.875h-0.875v-0.875Z" fill="#ffcf64" transform="translate(-357 -108.875)" /></svg>
            {{'profile.provider.microsoft.sharePoint.group.allOwnersWarning' | i18next}}
          </ng-container>
        </i2a-message>
      </div>
    </div>
  </ng-container>

  <ng-container body>
    <div class="container-fluid">

      <!-- Search Field -->
      <div>
        <div class="input-group">
          <input [(ngModel)]="searchText" [placeholder]="'form.placeHolder.search' | i18next" class="input-group filter-input" (keyup)="onKeyUpSearchText()" />
          <button type="button" class="btn bg-transparent filter-clear" [title]="'form.tooltip.clearSearchField' | i18next" *ngIf="isSearchTermNotEmpty()" (click)="clearFilter()">
            <fa-icon [icon]="['fal', 'times']" size="sm"></fa-icon>
          </button>
        </div>
      </div>
    
      <!-- OWNERS -->
      <div class="row pt-3">
        <div class="form-group col-12" *ngIf="displayOwners">
          <h4>{{'profile.provider.microsoft.sharePoint.group.owners' | i18next }}</h4>
          <table class="table-sm id-table ml-2">
            <tbody>
              <tr>
                <td scope="row">
                  <i2a-input-checkbox [(model)]="allOwners" (modelChange)="allOwnersChecked($event)"
                    [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                    [valueUnChecked]="false">
                  </i2a-input-checkbox>
                </td>
                <td scope="row" class="text-break all-resource">
                  {{'profile.provider.microsoft.sharePoint.group.allOwners' | i18next }}
                </td>
              </tr>
              <tr *ngFor="let owner of owners | filter : ownersFilterService.getSearchTerm()">
                <td scope="row">
                  <i2a-input-checkbox 
                    [className]="'btn-outline-dark'" 
                    [classNameChecked]="'btn-outline-danger'" 
                    [valueChecked]="true"
                    [valueUnChecked]="false"
                    [(model)]="owner.checked" 
                    (modelChange)="checkedOwner($event, owner)"> 
                  </i2a-input-checkbox>
                </td>
                <td scope="row" class="text-break w-100">
                  {{owner.name}}
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>

      <!-- MEMBERS -->
      <div class="row pt-3" *ngIf="members && members.length > 0">
        <div class="form-group col-12" *ngIf="members">
          <h4>{{'profile.provider.microsoft.sharePoint.group.members' | i18next }}</h4>
          <table class="table-sm id-table ml-2">
            <tbody>
              <tr>
                <td scope="row">
                  <i2a-input-checkbox [(model)]="allMembers" (modelChange)="allMembersChecked($event)"
                    [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                    [valueUnChecked]="false">
                  </i2a-input-checkbox>
                </td>
                <td scope="row" class="text-break all-resource">
                  {{'profile.provider.microsoft.sharePoint.group.allMembers' | i18next }}
                </td>
              </tr>
              <tr *ngFor="let member of members | filter : membersFilterService.getSearchTerm()">
                <td scope="row">
                  <i2a-input-checkbox [(model)]="member.checked" (modelChange)="checkedMember($event, member)"
                    [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                    [valueUnChecked]="false"> </i2a-input-checkbox>
                </td>
                <td scope="row" class="text-break w-100">
                  {{member.name}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- GUESTS -->
      <div class="row pt-3" *ngIf="guests && guests.length > 0">
        <div class="form-group col-12" *ngIf="guests">
          <h4>{{'profile.provider.microsoft.sharePoint.group.guests' | i18next }}</h4>
          <table class="table-sm id-table ml-2">
            <tbody>
              <tr>
                <td scope="row">
                  <i2a-input-checkbox [(model)]="validation.allGuests" (modelChange)="allGuestsChecked($event)"
                    [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                    [valueUnChecked]="false">
                  </i2a-input-checkbox>
                </td>
                <td scope="row" class="text-break all-resource">
                  {{'profile.provider.microsoft.sharePoint.group.allGuests' | i18next }}
                </td>
              </tr>
            <tr *ngFor="let guest of guests | filter : guestsFilterService.getSearchTerm()">
                <td scope="row">
                  <i2a-input-checkbox [(model)]="guest.checked" (modelChange)="checkedGuest($event, guest)"
                    [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                    [valueUnChecked]="false"> </i2a-input-checkbox>
                </td>
                <td scope="row" class="text-break w-100">
                  {{guest.name}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </ng-container>

</i2a-profile-alert-base-modal>
<!-- SITE ACTIONS -->
<i2a-resource-actions 
  *ngIf="viewSiteAction" 
  [alerts]="alerts" 
  [commentResource]="configuration.commentResource" 
  [deleteResource]="false" 
  [hideI2AOwners]="configuration.hideI2AOwners" 
  [headerTitle]="headerTitle"
  [openTitleKey]="'profile.provider.microsoft.sharePoint.openSite'"
  [isSharePointSiteGroupBySite]="isSharePointSiteGroupBySite"
  [openSite]="openSite" 
  [profile]="profile" 
  [resources]="resources" 
  [resource]="resource" 
  [resourceNotBelongToMe]="configuration.resourceNotBelongToMe && !hasDeleteAlertOrHasNotBelongToMe"
  [resourceProfiles]="resourceProfiles"
  [resourceProfile]="resourceProfile"
  [siteUrl]="siteUrl"
  [user]="user"
  (onAlertSent)="alertSent($event)" 
  (onHasWarnings)="hasWarnings($event)">

  <ng-container customDelete *ngIf="canDelete && !hasDeleteAlertOrHasNotBelongToMe">
    <div *ngIf="configuration.deleteResource" class="p-1">
      <button (click)="onDeleteResource(true)" id="deleteResource" class="btn btn-outline-light btn-outline-alert-dark">
        {{"button.deleteSite" | i18next}}
      </button>
    </div>
  </ng-container>

  <ng-container customOpenSite>
    <div *ngIf="configuration.openTeams && teamWebUrl" class="p-1">
      <a class="btn btn-outline-primary" target="_blank" [href]="teamWebUrl">
        {{'profile.provider.microsoft.team.openSite' | i18next}}
        <fa-icon [icon]="['fal', 'external-link']"></fa-icon>
      </a>
    </div>
    <div *ngIf="configuration.openYammer && yammerWebUrl" class="p-1">
      <a class="btn btn-outline-primary" target="_blank" [href]="yammerWebUrl">
        {{'profile.provider.microsoft.yammer.openSite' | i18next}}
        <fa-icon [icon]="['fal', 'external-link']"></fa-icon>
      </a>
    </div>
  </ng-container>

</i2a-resource-actions>

<!-- LIST ACTIONS -->
<i2a-resource-actions 
  *ngIf="viewListAction" 
  [alerts]="alerts" 
  [commentResource]="configuration.commentResource" 
  [deleteResource]="false" 
  [hideI2AOwners]="configuration.hideI2AOwners" 
  [headerTitle]="headerTitle"
  [openSite]="openSite" 
  [profile]="profile" 
  [resources]="null" 
  [resource]="resource" 
  [resourceNotBelongToMe]="configuration.resourceNotBelongToMe && !session.SharePointGroupBySite"
  [resourceProfiles]="null"
  [resourceProfile]="resourceProfile"
  [siteUrl]="siteUrl"
  [user]="user"
  (onAlertSent)="alertSent($event)" 
  (onHasWarnings)="hasWarnings($event)">

  <ng-container customDelete *ngIf="canDelete">
    <div *ngIf="configuration.deleteResource" class="p-1">
      <button (click)="onDeleteResource(false)" id="deleteResource" class="btn btn-outline-light btn-outline-alert-dark">
        {{"button.deleteResource" | i18next}}
      </button>
    </div>
  </ng-container>

  <ng-container customOpenSite>
    <div *ngIf="configuration.openTeams && teamWebUrl" class="p-1">
      <a class="btn btn-outline-primary" target="_blank" [href]="teamWebUrl">
        {{ 'profile.provider.microsoft.team.openSite' | i18next }}
        <fa-icon [icon]="['fal', 'external-link']"></fa-icon>
      </a>
    </div>
    <div *ngIf="configuration.openYammer && yammerWebUrl" class="p-1">
      <a class="btn btn-outline-primary" target="_blank" [href]="yammerWebUrl">
        {{ 'profile.provider.microsoft.yammer.openSite' | i18next }}
        <fa-icon [icon]="['fal', 'external-link']"></fa-icon>
      </a>
    </div>
  </ng-container>

</i2a-resource-actions>

<ng-template #deleteConfirmationTemplateHeader>
  <div class="container-fluid profile-alert-base-modal p-0">
    <div id="modal-header" class="row id-header p-3">
      <table class="table-sm id-table" *ngIf="resourceProfile">
        <tbody>
          <tr>
            <td scope="row text-center" class="p-0 id-header-picto">
              <img class="id-provider-picture" *ngIf="providerPhotoUrl" [src]="providerPhotoUrl" width="28"
                height="28">
            </td>
            <td scope="row" class="text-break w-100 align-self-center" #headertitle>
              <span class="provider-type" [title]="providerTypeLabel">{{ providerTypeLabel }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #deleteConfirmationTemplate>
  
  <ng-container *ngIf="loading">
    <div class="row pt-3 no-gutters" id="modal-body">
      <div class="col-centered">
        <span> {{ 'profile.loading' | i18next }}</span>
        <br />
        <i2a-spinner></i2a-spinner>
      </div>
    </div>
  </ng-container>

  <div class="container-fluid profile-delete-resource profile-alert-base-modal" *ngIf="!loading">
    
    <div class="row pt-3" id="modal-body" *ngIf="canDeleteSite || canDeleteLibrary">
      <div class="col-12 pb-2">

        <p *ngIf="!session.SharePointGroupBySite">
          <strong>{{"modal.deleteConfirmation.question" | i18next : { providerTypeResource : providerTypeLabel } }}</strong>
        </p>

        <table class="table-sm id-table mt-2">
          <tbody>
            <tr *ngIf="canDeleteLibrary">
              <td scope="row text-center" class="cell-checkbox">
                <i2a-input-checkbox [(model)]="deleteLibrary" (modelChange)="deleteLibraryChange($event)"
                  [classNameChecked]="checkboxSiteClassNamesChecked" [className]="checkboxSiteClassNames" [valueChecked]="true"
                  [valueUnChecked]="false">
                </i2a-input-checkbox>
              </td>
              <td *ngIf="!session.SharePointGroupBySite" scope="row" class="text-break w-100 delegate-title">
                {{'modal.deleteConfirmation.deleteLibrary' | i18next }}
              </td>
              <td *ngIf="session.SharePointGroupBySite" scope="row" class="text-break w-100 delegate-title">
                {{'modal.deleteConfirmation.deleteLibraryGroupBySite' | i18next }}
              </td>
            </tr>
            <tr *ngIf="canDeleteSite">
              <td scope="row text-center" class="cell-checkbox">
                <i2a-input-checkbox [(model)]="deleteSite" (modelChange)="deleteSiteChange($event)"
                  [classNameChecked]="checkboxSiteClassNamesChecked"  [className]="checkboxSiteClassNames"  [valueChecked]="true"
                  [valueUnChecked]="false">
                </i2a-input-checkbox>
              </td>
              <td *ngIf="!isSharePointSiteGroupBySite" scope="row" class="text-break w-100 delegate-title">
                {{'modal.deleteConfirmation.deleteSite' | i18next }}
              </td>
              <td *ngIf="isSharePointSiteGroupBySite" scope="row" class="text-break w-100 delegate-title">
                {{'modal.deleteConfirmation.site.deleteSite' | i18next }}
              </td>
            </tr>
          </tbody>
        </table>
        
        <ul class="id-warning mt-4 mb-0" *ngIf="canDeleteSite && deleteSite">
          <li *ngFor="let key of deleteWarningKeys">
            <fa-icon class="pr-2" [icon]="['fal', 'exclamation-triangle']" size="lg"></fa-icon>
            {{key | i18next}}
          </li>
        </ul>

      </div>
    </div>

    <div class="row" *ngIf="canDeleteSite && deleteSite && hasSubItems">
      <div class="col pb-3">
        <i2a-share-point-subsite-node [node]="siteExtended" [allSites]="subItems">
        </i2a-share-point-subsite-node>
      </div>
    </div>

    <div class="row" *ngIf="!canDeleteSite && !canDeleteLibrary">
      <div class="col pb-3">
        {{'modal.deleteConfirmation.deleteImpossibleMessage' | i18next }}
      </div>
    </div>

    <div class="row" *ngIf="session.DisplayComment">
      <div class="form-group col-12" id="modal-comment">
        <label for="assignIssue">{{'profile.comments' | i18next }}</label>
        <div>
          <textarea [(ngModel)]='deleteComments' rows="5" class="comments">
          </textarea>
        </div>
      </div>
    </div>

  </div>

</ng-template>

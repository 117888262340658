import { OperationItemsBaseModel } from './operation-items-base-model';

export class OperationImportContextModel extends OperationItemsBaseModel {
  sendPasswordEmailToUsers: boolean;
  createUsers: boolean|null;
  defaultTimeZone: string;
  defaultCulture: string;
  orgUnitId: string;
  forceOrgUnitIdOnExistingUsers: boolean;
}

import { Component, OnInit, Input, ViewContainerRef, Type, ViewChild, ViewChildren, QueryList, OnDestroy, Renderer2, Injector, ComponentRef, Inject, AfterContentChecked, AfterContentInit, AfterViewChecked, Output, EventEmitter } from '@angular/core';
import { TemplateService } from 'projects/web-user-profile/src/app/services/template.service';
import { ReportProfileBaseModel, UserModel, ProviderInstanceModel, ConnectedAccountModel, ReportProfileValidateModel, ZimbraProfileProviderDatasourceService, MicrosoftOfficeProfileProviderDatasourceService, Constants, AlertInvalidStateFeaturedModel, BaseProviderInstanceResourceModel, ReportProfileLoadedModel, ReportProfileResource, AlertConstants, ReportProfileResourceBase } from '@i2a/web-api-client';
import { Subscription } from 'rxjs';
import { ProfilePageComponent } from '../profile-page/profile-page.component';
import { RegisterMyProfileComponent } from 'projects/web-user-profile/src/app/decorators/my-profile-component.decorator';
import { IModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-module';
import { ProfileBaseClassComponent } from '../profile-base-class-component';
import { IModuleTemplateContainer } from 'projects/web-user-profile/src/app/models/templates/modules/i-module-template-container';
import { Template } from 'projects/web-user-profile/src/app/models/templates/template';
import { ToastrService } from 'ngx-toastr';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { IModuleUserContainer } from 'projects/web-user-profile/src/app/models/templates/modules/i-module-user-container';
import { IBaseModule } from 'projects/web-user-profile/src/app/models/templates/modules/i-base-module';
import { IModuleProviderContainer } from 'projects/web-user-profile/src/app/models/templates/modules/i-module-provider-container';
import { TemplateConstants } from 'projects/web-user-profile/src/app/models/templates/template-constants';
import { AlertReasonHelper } from 'projects/web-user-profile/src/app/helpers/alert-reason-helper';
import { ContainerDirective } from 'projects/web-user-profile/src/app/directives/container-directive';
import { SessionService } from 'projects/web-user-profile/src/app/services/session-service';
import { WarningInfo } from 'projects/web-user-profile/src/app/models/warnings/warning-info';

@RegisterMyProfileComponent(TemplateConstants.MODULE_TEMPLATE_CONTAINER)
@Component({
  selector: 'i2a-template-container',
  templateUrl: './template-container.component.html',
  styleUrls: ['./template-container.component.scss']
})
export class TemplateContainerComponent extends ProfileBaseClassComponent<any,ReportProfileResourceBase<any>> implements OnInit, OnDestroy, IModuleTemplateContainer {

  private components: ComponentRef<IBaseModule>[];
  private userComponent: IModuleUserContainer<any>;
  private modules: IBaseModule[];

  @Input() public template: Template;
  @Input() public id: string;

  public alertIsSent: boolean;
  public profileIsValidated: boolean;
  public firstValidationOnAlertIsSent: boolean;

  @ViewChild(ProfilePageComponent) profilePageComponent: ProfilePageComponent;
  @ViewChild('templateModules', { static: true, read: ContainerDirective }) templateModules: ContainerDirective;
  @ViewChild('user', { static: true, read: ContainerDirective }) userContainer: ContainerDirective;
  @ViewChild('provider', { static: true, read: ContainerDirective }) providerContainer: ContainerDirective;

  public displayUser: boolean;
  public resources: ReportProfileResource[];
  public userActivityIsValid: boolean;

  @Output() public onHasWarnings = new EventEmitter<Array<WarningInfo>>();

  constructor(
    toastr: ToastrService,
    @Inject(I18NEXT_SERVICE) i18Next: ITranslationService,
    private session: SessionService,
    private templateService: TemplateService,
    private microsoftDatasource: MicrosoftOfficeProfileProviderDatasourceService,
    private zimbraDatasource: ZimbraProfileProviderDatasourceService) {
    super(toastr, i18Next, null);
    this.components = [];
    this.modules = [];
    this.alertIsSent = false;
    this.resources = [];
    this.firstValidationOnAlertIsSent = false;
  }

  initialize(user: UserModel, profile: ReportProfileBaseModel, alerts: AlertInvalidStateFeaturedModel[], template: Template): void {
    super.baseInitialize(user, profile, template.configuration, alerts);
    this.template = template;
    this.id = `profile_${profile.providerId}`
    this.alertIsSent = alerts.length > 0;
  }

  setIsAlert(isAlert: boolean) {
    super.setIsAlert(isAlert);

    this.modules.forEach(m => {
      m.setIsAlert(isAlert);
    })
  }

  getUserAlerts(alerts): AlertInvalidStateFeaturedModel[] {
    return this.alerts.filter(a => {
      let isUserAlert = a.annotations.findIndex((a) =>
        a.key.localeCompare(AlertConstants.ALERT_ANNOTATION_SUBTYPE, undefined, { sensitivity: 'base' }) == 0 &&
        (
          a.value.localeCompare(AlertConstants.ALERT_ANNOTATION_INVALID_STATE_SUBTYPE_PROFILE_USER_ACTIVITY_ACCESS_MODES, undefined, { sensitivity: 'base' }) == 0 ||
          a.value.localeCompare(AlertConstants.ALERT_ANNOTATION_INVALID_STATE_SUBTYPE_PROFILE_USER_ACTIVITY_DEVICES, undefined, { sensitivity: 'base' }) == 0 ||
          a.value.localeCompare(AlertConstants.ALERT_ANNOTATION_INVALID_STATE_SUBTYPE_PROFILE_USER_ACTIVITY_RECENT_COUNTRIES, undefined, { sensitivity: 'base' }) == 0 ||
          a.value.localeCompare(AlertConstants.ALERT_ANNOTATION_INVALID_STATE_SUBTYPE_PROFILE_USER_ACTIVITY_USUAL_COUNTRIES, undefined, { sensitivity: 'base' }) == 0
        )
      ) >= 0;
      //device not registered   
      let isDelegateAlert =
        a.annotations.findIndex((a) =>
          a.key.localeCompare(AlertConstants.ALERT_ANNOTATION_SUBTYPE, undefined, { sensitivity: 'base' }) == 0 &&
          a.value.localeCompare(AlertConstants.ALERT_ANNOTATION_SUBTYPE_DELEGATION, undefined, { sensitivity: 'base' }) == 0
        ) >= 0;
      let isDeviceModule = AlertReasonHelper.alertsContainsKey(this.alerts, AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DELEGATE_DEVICE_ID) ||
        AlertReasonHelper.alertsContainsKey(this.alerts, AlertConstants.ALERT_INVALID_STATE_CONTEXT_DELEGATION_DEVICE_SYNC_ID);

      return isUserAlert || isDeviceModule && isDelegateAlert;
    });
  }

  ngOnInit() {
    if (this.user && this.profile && this.template) {
      if (this.template.configuration.hideComments != undefined) {
        this.session.DisplayComment = !this.template.configuration.hideComments;
      }

      // TEMPLATE MODULE CONTAINER 
      this.template.modules?.forEach(
        (module) => {
          if (module.isEnabled) {
            if (module.id === TemplateConstants.MODULE_TEMPLATE_WARNING_CONTAINER && module.isEnabled) {
              this.session.WarningConfiguration = module.configuration;
            }
            let moduleComponent = this.templateService.loadComponent<IModule<any>>(this.templateModules.viewContainerRef, module.id);
            if (moduleComponent) {
              this.components.push(<ComponentRef<any>>moduleComponent);
              moduleComponent.instance.initialize(this.user, this.profile, module.configuration, this.alerts, null, this.template);
            }
          }
        }
      );

      //USER CONTAINER
      if (this.template.userTemplate.containerModule.isEnabled) {
        let component = this.templateService.loadComponent<IModuleUserContainer<any>>(this.userContainer.viewContainerRef, this.template.userTemplate.containerModule.id);
        if (component) {
          this.components.push(<ComponentRef<any>>component);
          this.userComponent = component.instance;
          this.modules.push(this.userComponent);
          let userAlerts = this.getUserAlerts(this.alerts);
          this.userActivityIsValid = userAlerts.length == 0;
          this.userComponent.initialize(this.user, this.profile, this.template.userTemplate.containerModule.configuration, userAlerts, this.template.userTemplate);
          this.subscriptions.add(this.userComponent.onAlertSent.subscribe(e => this.userAlertSent(e)));
          this.subscriptions.add(this.userComponent.onAlertSent.subscribe(e => this.onAlertSent.emit(e)));
          this.subscriptions.add(this.userComponent.onHasWarnings.subscribe(e => this.updateWarnings(e)));
          this.subscriptions.add(this.userComponent.onHasWarnings.subscribe(e => this.onHasWarnings.emit(e)));
        }
      }

      //PROVIDER CONTAINER
      this.template.providerTemplates.forEach(providerTemplate => {
        if (providerTemplate.containerModule.isEnabled && this.profile.providerId.localeCompare(providerTemplate.providerId, undefined, { sensitivity: 'base' }) == 0) {
          var providerComponent = this.templateService.loadComponent<IModuleProviderContainer<any>>(this.providerContainer.viewContainerRef, providerTemplate.containerModule.id);
          if (providerComponent) {
            providerComponent.instance.onResourceToValidate
            this.components.push(<ComponentRef<any>>providerComponent);
            this.modules.push(providerComponent.instance);
            this.subscriptions.add(providerComponent.instance.onResourceToValidate.subscribe(e => this.resourceToValidate(e)));
            providerComponent.instance.initialize(this.user, this.profile, providerTemplate.containerModule.configuration, this.alerts, providerTemplate);
            this.subscriptions.add(providerComponent.instance.onAlertSent.subscribe(e => this.onAlertSent.emit(e)));
            this.subscriptions.add(providerComponent.instance.onHasWarnings.subscribe(e => this.updateWarnings(e)));
            this.subscriptions.add(providerComponent.instance.onHasWarnings.subscribe(e => this.onHasWarnings.emit(e)));
          }
        }
      }
      );
    }
  }

  ngAfterViewInit() {
    let model = new ReportProfileLoadedModel();
    model.adminObjectId = this.profile.adminObjectId;
    model.resources = this.resources
    if (this.profile.providerId == Constants.PROVIDER_ID_MICROSOFT) {
      this.subscriptions.add(this.microsoftDatasource.profileLoaded(this.profile.providerInstanceId, this.profile.userId, model).subscribe((model) => { }));
    }
    else if (this.profile.providerId == Constants.PROVIDER_ID_ZIMBRA) {
      this.subscriptions.add(this.zimbraDatasource.profileLoaded(this.profile.providerInstanceId, this.profile.userId, model).subscribe((model) => { }));
    }

    this.subscriptions.add(this.profilePageComponent.onValidate.subscribe(() => {
      this.validateProfile();
      this.profilePageComponent
    }));

  }

  validateProfile() {
    let model = new ReportProfileValidateModel();
    model.userActivityIsValid = this.userActivityIsValid;
    model.isValid = !this.alertIsSent;
    model.resources = this.resources;
    this.setIsAlert(this.alerts.length > 0);
    if (this.profile.providerId == Constants.PROVIDER_ID_MICROSOFT) {
      this.subscriptions.add(this.microsoftDatasource.validateProfile(this.profile.providerInstanceId, this.profile.userId, model).subscribe((model) => { this.profileIsValidated = true }));
    }
    else if (this.profile.providerId == Constants.PROVIDER_ID_ZIMBRA) {
      this.subscriptions.add(this.zimbraDatasource.validateProfile(this.profile.providerInstanceId, this.profile.userId, model).subscribe((model) => { this.profileIsValidated = true }));
    }
  }

  resourceToValidate(resource: ReportProfileResource) {
    let resourceToValidate = this.resources.find(r => r.providerInstanceResource.providerId == resource.providerInstanceResource.providerId &&
      r.providerInstanceResource.providerInstanceId == resource.providerInstanceResource.providerInstanceId &&
      r.providerInstanceResource.providerTypeId == resource.providerInstanceResource.providerTypeId &&
      r.providerInstanceResource.providerInstanceResourceId == resource.providerInstanceResource.providerInstanceResourceId)
    if (resourceToValidate != null) {
      resourceToValidate.isValid = resource.isValid;
    } else {
      this.resources.push(resource);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  userAlertSent(event): void {
    this.alertIsSent = true;
    this.userActivityIsValid = false;
  }

  updateAlerts(alerts: AlertInvalidStateFeaturedModel[]) {
    this.alertIsSent = true;
    this.alerts = alerts;
    super.updateAlerts(alerts);
    this.components.forEach(c => {

      if (c.instance != this.userComponent) {
        c.instance.updateAlerts(alerts);
      } else {
        this.userComponent.updateAlerts(this.getUserAlerts(alerts));
      }
    });
  }

  updateWarnings(warnings: Array<WarningInfo>): void {
    super.updateWarnings(warnings);

    this.components.forEach(c => {
      c.instance.updateWarnings(this.warnings);
    });
  }
}

<i2a-profile-alert-base-modal
  [manualDescriptionKey]="'applyCorrection.providers.microsoft.exchange.ruleConfig.manual.description'"
  [manualLinkKey]="'applyCorrection.providers.microsoft.exchange.ruleConfig.manual.link'"
  [automaticDescriptionKey]="'applyCorrection.providers.microsoft.exchange.ruleConfig.automatic.description'"
  [ignoreCommentToSend]="session.IgnoreCommentToSend" [displayComment]="session.DisplayComment"
  [validation]="validation" [canSend]="canSend" [modalTitle]="'profile.confirmationOfInformationTitle' | i18next "
  [resourceProfile]="resourceProfile">

  <ng-container header-picto>
    <i2a-badge-icon>
      <ng-container icon>
        <fa-icon [icon]="['fal', 'cogs']" size="lg"></fa-icon>
      </ng-container>
    </i2a-badge-icon>
  </ng-container>

  <ng-container header-title>
    {{'profile.provider.microsoft.exchange.rules.configuration' | i18next }}<br>
  </ng-container>

  <ng-container body>
    <div class="container-fluid">
      <div class="row pt-3" *ngIf="emails">
        <div class="col" *ngIf="rules.forwardMailBoxes.length > 0">
          <h4>{{'profile.provider.microsoft.exchange.rules.forwardMailBoxes' | i18next }}</h4>
          <table class="table table-sm table-forward-mailboxes">
            <tbody>
              <tr *ngFor="let email of emails">
                <td scope="row">
                  <i2a-input-checkbox [(model)]="email.checked" (modelChange)="checked($event, email)"
                    [classNameChecked]="'btn-outline-danger'" [className]="'btn-outline-dark'" [valueChecked]="true"
                    [valueUnChecked]="false" (modelChange)="setCanSend()"> </i2a-input-checkbox>
                </td>
                <td scope="row" class="text-break">
                  {{email.name}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </ng-container>

</i2a-profile-alert-base-modal>